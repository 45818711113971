import companyInfo from './company';

export default {
    forgotPasswordBody: `Please enter your information. When you tap the button below, we’ll send an email to the address associated with your account containing a temporary link to reset your password. The email link will only be active for 30 minutes.`,
    forgotPasswordSuccessMsgLine1:`We just sent you an email. To reset your password, click on the button in the email and then follow instructions. Don’t delay. The email link will only be active for 30 minutes.`,
    forgotPasswordSuccessMsgLine2:`If you need assistance or did not receive an email, please contact Customer Service at ${companyInfo.customerServicePhone}.`,
    forgotPasswordnoAccountFound: `This information is not associated with an existing account. Please check the data you entered and resubmit.`,
    passwordResetting: `We recently processed a password reset request for this account. To reset your password, you must follow the link in the email we sent. Please check your inbox and spam folder for the email. You can request another password reset email by clicking the forgot password link below and following instructions. If you continue to have issues logging in, contact Customer Service at ${companyInfo.customerServicePhone}.`,
    passwordResetTokenExpired: `Your link has expired or is invalid. If you still need to reset your password, please select the forgot password link at login and follow instructions. If you need assistance, please call Customer Service at ${companyInfo.customerServicePhone}.`,
    noAccountFound: `This information is not associated with an existing account. Please check the data you entered and try again.`,
    invalidEmail:`This email address is not associated with an existing account`,
    emptyEmail:`Please enter your email address in this format: name@example.com`,
    accountLocked:`Invalid login. Access temporarily blocked. You can reset your password through the Forgot Password link. For assistance, call Customer Service at ${companyInfo.customerServicePhone}.`,
    invalidLogin:`Invalid login. Please try again.`,
    passwordCreationError:`A server error occurred, and we were unable to create your password. Please refresh and try again.`,
    accountlockedpasswordCreationError:`This information entered is not associated with an existing account. For assistance, please contact Customer Service at ${companyInfo.customerServicePhone}.`,
    accountlockedpasswordforgotError:`This information is not associated with an existing account. For assistance, please contact Customer Service at ${companyInfo.customerServicePhone}.`,
    logout:`You’re logged out. Thanks for visiting XactFlex™.`,
    invalidPassCode:`Invalid Passcode, Please enter 6 digit passcode`,
    passcodeInvalidCodeAttempt :`Invalid passcode. Please try again or choose "Resend Code" to receive a new code.` ,
    esignpasscodeInvalidCodeAttempt :`Invalid passcode. Please try again or choose<br/> "Resend Code" to receive a new code.` ,
    passcodeInvalidCodeSentAttempt : `Invalid code. Please call Customer Service at ${companyInfo.customerServicePhone} for assistance.`,
    passcodeAttemptFailed :`Verification failed.<br class="visible-xs"/> Your application has been withdrawn.` ,
    applicationwithdrawn:`Verification failed.<br/> Your application has been withdrawn.` ,
    newEmail:`This email was not created password, You can create password by clicking the login link below.`,
    invalidUser : `Please contact Customer Service at ${companyInfo.customerServicePhone} to proceed with changing your password.`,
    validateUser : `Please contact Customer Service at ${companyInfo.customerServicePhone} to proceed with creating your password`,
    resendPasscode : `We have received your request and sent your new passcode. If you need further assistance, please contact Customer Service at ${companyInfo.customerServicePhone}. `,
}