<template>
  <main>
    <div class="create-password">
      <SectionTitle>
       Need to access <br>
        your account?
        <template v-slot:subtitle>
          <span style="text-transform:none">HELP US VERIFY YOUR IDENTITY</span>
        </template>
      </SectionTitle>

      <FormField>
        <template v-slot:label>
          EMAIL
        </template>
        <input
            name="email"
            v-model.trim="email"
            v-validate="{ required: true }"
            data-vv-validate-on="blur"
            type="email">
        <template v-slot:error>
          {{ errors.first('email') }}
        </template>
      </FormField>

      <FormField>
        <template v-slot:label>
          LAST 4 OF SSN
        </template>
        <input
            name="SSN"
            v-model.trim="lastFourSocial"
            data-vv-validate-on="blur"
            type="password"
            maxlength="4"
            v-validate="{ required: true, length: 4, numeric }"        
            @keydown="limitNums">
        <template v-slot:error>
          {{ errors.first('SSN') }}
        </template>
      </FormField>

      <FormField>
        <template v-slot:label>
          BIRTHDATE
        </template>
        
        <input :value="date(birthdate)" @change="updateBirthdate"  maxLength="10" id="birthdate"  placeholder="mm/dd/yyyy"  @keypress="allowonlynumbersandbackslash" @blur="validateBirthDate()">
        <!-- <DatePicker
            ref="birthdate"
            name="birthdate"
            v-model="birthdate"           
            format="MM/dd/yyyy"
            calendar-class="datepicker-calendar"
            input-class="datepicker-input"
            wrapper-class="datepicker-input-wrapper"
            :disabled-dates="birthdateDisabledDates"
            :open-date="birthdateStartDate"
            v-validate="{ required: true}"
        /> -->
        <div class="datepicker-icon" @click="openCalendar">
          <i class="fas fa-calendar-alt fa-2x"/>
        </div>
        
        <template v-slot:error>                    
             {{errors.first("birthdate")}}
             <label id="birthdateerror" name="birthdateerror"></label>
          </template>                    
      </FormField>
        <div class="error" v-html="errorMsg"/>
      <div class="bottom">
        <router-link class="no-border green" :to="{ name: 'login' }">
          CANCEL
        </router-link>
        <a class="no-border green next" @click="next" v-if="isFormValid">
          NEXT
          <img
              src="../../assets/icons/login.svg"
              alt="Next"
              draggable="false">
        </a>
      </div>
    </div>
  </main>
</template>

<script>
import { FormField, SectionTitle } from '@/components';
//import DatePicker from 'vuejs-datepicker';
import { limitNums } from "@/utils/plugins/inputs";
//import {Validator } from "vee-validate";
import { birthdateStartDate, birthdateDisabledDates,birthPreviousDate } from "@/config/dates";
import dayjs from 'dayjs';
import { date } from '@/utils/filters/dates';

export default {
  name: 'VerifyIdentity',
  title: 'Verify Identity',
  components:{ FormField, SectionTitle },
  data(){
    return {
      lastFourSocial: "",
      ssnError: "",
      CalledFrom:null,
      birthdateStartDate: birthdateStartDate,
      birthdateDisabledDates: birthdateDisabledDates,
      birthPreviousDate:birthPreviousDate,
      date: date
}
  },
  created(){
    this.$store.commit('updateBirthdate', '');
    this.$store.commit('updateLoginSocial', '');
    this.$store.commit('updateEmailNotFound', false);   
  },
  computed: {
    isFormValid: function() {
      return !Object.values(this.fields).filter(field => !field.valid).length && !this.validateBirthDate()
    },
    email: {
      get() { return this.$store.state.login.email },
      set(value) { this.$store.commit('updateLoginEmail', value) }
    },
    birthdate: {
      get() { return this.$store.state.application.birthdate },
      set(value) { this.$store.commit('updateBirthdate', value) }
    },
      errorMsg: {
      get() { return this.$store.state.login.identityErrorMsg },
    }
  },
  methods:{
    openCalendar() {
      this.$refs.birthdate.showCalendar();
    },
    updateBirthdate(event) {
      this.birthdate = event.target.value;
    },
    async next() {       
      {
        var d = new Date(this.birthdate);        
 if(d <= this.birthdateStartDate && d > this.birthPreviousDate)
 {  
  // console.log(d);
   if(this.$store.state.login.token == undefined)
   {
        const tokenResult = await this.$store.dispatch('createToken');   
        this.$store.state.login.token = tokenResult.token.writtenToken;
   }
   this.$store.state.login.last4SSN = this.lastFourSocial;
    const res = await this.$store.dispatch('validateIdentity',this.$store.state.login.token);              
        if(res.validEmail)
        {
          if(res.validIdentity)
          {
        this.$store.state.login.token=res.tokenToUse;  
        if(res.isRegistered)
        {
           this.$store.state.login.CalledFrom = "ForgotPwd";
        }     
        await this.$store.dispatch('verifyIdentity',this.lastFourSocial);
        if(this.$store.state.login.CalledFrom==='ForgotPwd'){          
        this.$router.push({ name: 'forgot-password' });    
        }  
        }
           else{
        this.$store.commit('updateEmailNotFound',true);
         }
      }
      else{
        this.$store.commit('updateEmailNotFound',true);
      }
      }
      }

    },
    validateBirthDate()
    {
      if(this.birthdate == null || this.birthdate == undefined)
      {
        return true;
      }
      var min_eligible_date = dayjs().subtract(18, 'years').toDate();
      var selectedBirthDate = new Date(this.birthdate);
      if(selectedBirthDate <= min_eligible_date)
      {
         if(document.getElementById("birthdate")!= undefined)
         {
       if(document.getElementById("birthdate").parentElement.classList.contains('field-error'))
       {
          document.getElementById("birthdate").parentElement.classList.remove('field-error'); 
       }
        document.getElementById("birthdateerror").innerHTML="";                        
         return false;
         }
      }
      else
      {
          if(document.getElementById("birthdate")!= undefined)
         {
         document.getElementById("birthdate").parentElement.classList.add('field-error');      
         document.getElementById("birthdateerror").innerHTML="Please enter your birthdate as MM/DD/YYYY.";                           
         return true;
         }
      }
    },   
      allowonlynumbersandbackslash(event)
    {
      var keyCode=event.keyCode;  
      var isShift = false;   
      if (keyCode == 16) {
            isShift = true;
        }
        //Allow only Numeric Keys.
        if (((keyCode >= 47 && keyCode <= 57) || keyCode == 8 || keyCode <= 37 || keyCode <= 39 ) && isShift == false) {  
            if(keyCode == 47)
            {
               if ((event.target.value!= null && (event.target.value.length == 2 
             || event.target.value.length == 5) && keyCode != 8 )) {   
               return true;
             }
             else
             {
               event.preventDefault();
             }

            }
            else
            {
             if ((event.target.value!= null && (event.target.value.length == 2 
             || event.target.value.length == 5) && keyCode != 8 )) {    
                event.target.value = event.target.value +"/";   
             }  
                      
        }}
        else {
           event.preventDefault();
        }
    },        
    
    limitNums
  }
}

// Validator.localize({
//  en: {
//  custom: {
//           email: {        
//                   email: "Please enter your email address in this format: name@example.com"
//                 },
//           SSN: {        
//                 required: "Please enter the last four digits of your social security number",
//                 length: "Please enter the last four digits of your social security number",
//                 numeric: "Please enter the last four digits of your social security number"
//               },
//           birthdate: {        
//               required: " ",
//               date_format: " "
//             },      
//         }
   
//        }
// });
</script>


<style scoped lang="scss">
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input {
  border: solid 1px #dedfdd;
  padding: 3px 5px 3px 5px;
}

main{
  .section-title {
    align-self: flex-start;
  }
  .datepicker-icon{
    position: absolute;
    margin: 4px 284px
  }
   .error {
    margin: 10px 20px;
    color: $error;
  }
  .bottom {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 12px;
    padding: 20px 0;
    height: 34px;

    .next {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;

      img {
        margin-left: 10px;
      }
    }
  }
}
</style>