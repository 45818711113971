<template>
  <main class="portal-wrapper">

    <PortalNav page="transactions"/>

    <div class="portal">

      <PortalMessage class="hide-large" page="transactions"/>
      <PortalAccountSum/>

      <div class="card">

        <SectionTitle>Transactions</SectionTitle>

        <!-- <div class="expander" v-if="pendingTransactionsTableData"> -->
        <div class="expander">
          <div class="title">
            <div class="icons" @click="expandPayments = !expandPayments">
              <i class="fas fa-chevron-down" v-if="expandPayments"/>
              <i class="fas fa-chevron-right" v-else/>
            </div>
            PAYMENTS IN PROCESS & FUTURE ONE-TIME PAYMENTS
            <!-- PENDING PAYMENT: {{ pendingTransactionsTableData | currencyLong }} -->
          </div>
          <div v-show="expandPayments" class="note">
          <div class ="pendingWrapper">
             <div class="pendTrans" v-for="(pend,index) in pendingTransactionsTableData" :key="index">         
              {{currencyLong(pend.pendingAmt) }} {{pend.Pending}} {{date(pend.date) }}
              </div>
            </div>
          This section includes automatic payments that are currently being processed and one-time payments that that you’ve scheduled. If you have questions, please contact Customer Service at 833-793-1150.          
          </div>
        </div>
      </div>

      <PortalTransactionTable
          :columns="allTransactionsColumns"
          :data="filteredTransactionTableData"
          >
        <div class="table-title">
          <a class="all border-hover"
             :class="activeFilter === 'all' ? 'active' : null"
             @click="filterTransactions('all')">
            All Completed Transactions
          </a>
          <div class="filters">
            <a class="filter border-hover"
               :class="activeFilter === 'draw' ? 'active' : null"
               @click="filterTransactions('draw')">
              Draws
            </a>
            <div class="divider"/>
            <a class="filter border-hover"
               :class="activeFilter === 'payment' ? 'active' : null"
               @click="filterTransactions('payment')">
              Payments
            </a>
          </div>
        </div>
      </PortalTransactionTable>

    </div>

  </main>
</template>

<script>
import {
  PortalNav,
  PortalAccountSum,
  PortalMessage,
  SectionTitle,
  PortalTransactionTable
} from '@/components';
import { currencyLong } from "@/utils/filters/currency";
import { date } from '@/utils/filters/dates';

export default {
  name: 'PortalTransactions',
  title: "Make A Payment On Your Line Of Credit",
  components: {
    PortalNav,
    PortalAccountSum,
    PortalMessage,
    SectionTitle,
    PortalTransactionTable },
  async created() {
     
   /* if (!this.allTransactionsTableData.length) {
      await this.$store.dispatch('getAllTransactionsTableData');
    }*/
  },
  computed: {
    allTransactionsTableData: {
      get() { return this.$store.state.account.allTransactionsTableData }
    },
     pendingTransactionsTableData: {
      get() { return this.$store.state.account.pendingTransactionsTableData }
    },
    pendingPaymentType: {
      get() { return this.$store.state.account.pendingPaymentType }
    },
    filteredTransactionTableData: function() {
      return this.activeFilter === 'all'
          ? this.allTransactionsTableData
          : this.allTransactionsTableData.filter(item => item.description.toLowerCase().includes(this.activeFilter));
    }
  },
  data() {
    return {
      expandPayments: false,
      allTransactionsColumns: ['date', 'amount', 'description', 'trans. id'],
      activeFilter: 'all',
      currencyLong : currencyLong,
      date: date
    }
  },
  methods: {
    filterTransactions(filter) {
      this.activeFilter = filter;
    }
  }
}
</script>

<style scoped lang="scss">
.table-title {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .active {
    color: $green;
    border-bottom: 1px solid $green;
    cursor: default;
  }

  .all {
    font-size: 20px;
  }

  .filters {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    .filter {
      margin: 0 10px;
      font-size: 10px;
    }

    .divider {
      height: 20px;
      border-right: 1px solid $border;
    }

  //   .scrollWrapper{
  //   display: block;
  //   height: 450px;
  //   overflow: scroll;
  // }
  }
}

.expander {
  margin-top: 10px;
  font-family: $font-primary;//$font-secondary;
  font-size: 12px;

  .title {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  .icons {
    flex: 0 0 18px;
    color: $orange;
    //float: left;
  }

  .note {
    max-width: 500px;
    margin: 4px 0 0 20px;
    font-family: $font-primary;
  }

  .pendingWrapper{
    //margin-left: 20px;
    font-family:$font-primary;// $font-secondary;

  }

  .pendTrans{
    padding: 0 0 5px 0;
  }

  .scrollWrapper{
    display: block;
    height: 450px;
    overflow: scroll;
  }
  @media (max-width: 1023px) {
    margin-top: 0;
  }
}
</style>