<template>
  <main class="portal-wrapper">

    <PortalNav page="documents"/>

    <div class="portal">

      <PortalMessage class="hide-large" page="documents"/>
      <PortalAccountSum/>

      <PortalTable
        :columns="documentsColumns"
        :data="selectLoca ? lineOfCreditAgreement : documentsTableData">

        <div class="table-title">

          <SectionTitle>Documents</SectionTitle>

          <div class="filters">
            <a class="filter border-hover"
               :class="selectLoca ? 'active' : null"
               @click="selectLoca = true">
              Line Of Credit Agreement
            </a>
            <div class="divider"/>
            <a class="filter border-hover"
               :class="!selectLoca ? 'active' : null"
               @click="selectLoca = false">
              Statements
            </a>
          </div>
        </div>

      </PortalTable>

    </div>

  </main>
</template>

<script>
import {
  PortalNav,
  PortalAccountSum,
  PortalTable,
  PortalMessage,
  SectionTitle
} from '@/components';

export default {
  name: 'PortalDocuments',
  title: 'Documents',
  components: {
    PortalNav,
    PortalAccountSum,
    PortalTable,
    PortalMessage,
    SectionTitle
  },
  async created() {
    // if (!this.documentsTableData.length) {
    //   await this.$store.dispatch('getDocumentsTableData');
    // }
      // await this.$store.dispatch('getDocs');
  },
  computed: {
    documentsColumns: function() {
      return this.selectLoca
          ? ['line of credit agreement', 'download/view']
          : ['billing statements', 'download/view']
    },
    documentsTableData: {
       get() { return this.$store.state.portal.documentsTableData }
    },
    lineOfCreditAgreement: {
       get() { return this.$store.state.portal.lineOfCreditAgreement }
    },
  },
  data() {
    return {
      selectLoca: false,
    }
  }
}
</script>

<style scoped lang="scss">
.table-title {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .active {
    color: $green;
    border-bottom: 1px solid $green;
    cursor: default;
  }

  .filters {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    .filter {
      margin: 0 10px;
      font-size: 10px;
    }

    .divider {
      height: 20px;
      border-right: 1px solid $border;
    }
  }

  @media (max-width: 1023px) {
    flex-flow: row wrap;
  }
}
</style>