<template>
  <div class="bgcontainer">
    <div class="bglayout">
      <PageHeaderApplication>
        Line of Credit Application - Income
        <template v-slot:subtitle>
          <span>Find out if you qualify in these simple steps.</span>
        </template>
      </PageHeaderApplication>
      <main>
        <v-form
          :validation-schema="currentSchema"
          keep-values
          @invalid-submit="onIncompleteSection"
          @submit="completeSection"
          v-slot="{ errors }"
        >
          <section>
            <AppNav :navselected="3" />
            <fieldset class="form">
              <legend class="visually-hidden">
                Line of Credit Application - INCOME
              </legend>
              <h3 class="contentheader" tabindex="-1" id="tittleincome">
                Tell us about your income.
              </h3>
              <p class="headertext">
                This includes other sources of income periodically deposited
                into your bank account. You don’t have to reveal alimony, child
                support, or separate maintenance income unless you want it
                considered as a basis for repaying this obligation.
              </p>
              <div class="inputs incomesource">
                <FormField :errormsg="errors['source']">
                  <template v-slot:label>
                    <label for="incomesource" class="applabel"
                      >Main Income Source</label
                    ></template
                  >
                  <div class="select">
                    <v-field
                      id="incomesource"
                      name="source"
                      v-model="source"
                      as="select"
                      aria-describedby="source-errormsg"
                    >
                      <option value="0">Choose One</option>
                      <option
                        v-for="incomesource in incomeSources"
                        :key="incomesource.value"
                        :value="incomesource.value"
                      >
                        {{ incomesource.text }}
                      </option>
                    </v-field>
                    <i class="fas fa-chevron-down icon"></i>
                  </div>
                  <template v-slot:error>
                    <v-error id="source-errormsg" name="source" />
                  </template>
                </FormField>

                <FormField :errormsg="errors['secondarysource']"  v-if="source === 'W'">
                  <template v-slot:label>
                    <label for="secondarysource" class="applabel"
                      >Select Other Income Type</label
                    ></template
                  >
                  <div class="select">
                    <v-field
                      id="secondarysource"
                      name="secondarysource"
                      v-model="secondarysource"
                      as="select"
                      aria-describedby="secondarysource-errormsg"
                    >
                      <option value="0">Choose One</option>
                      <option
                        v-for="secondarysource in secondarySources"
                        :key="secondarysource.value"
                        :value="secondarysource.value"
                      >
                        {{ secondarysource.text }}
                      </option>
                    </v-field>
                    <i class="fas fa-chevron-down icon"></i>
                  </div>
                  <template v-slot:error>
                    <v-error id="secondarysource-errormsg" name="secondarysource" />
                  </template>
                </FormField>

                <FormField  v-if="source === 'W'" class="inputs hideondevices">
                </FormField>




                <FormField :errormsg="errors['employername']" v-if="(source === 'P') || (source === 'O')">
                  <template v-slot:label>
                    <label for="employername" class="applabel"
                      >Employer Name</label
                    ></template
                  >
                  <v-field
                    name="employername"
                    id="employername"
                    v-model.trim="employerName"
                    aria-describedby="employername-errormsg"
                  />
                  <template v-slot:error>
                    <v-error id="employername-errormsg" name="employername" />
                  </template>
                </FormField>

                <FormField
                  :errormsg="errors['workphone']"
                  v-if="(source === 'P') || (source === 'O')"
                >
                  <template v-slot:label>
                    <div class="workphonewrapper">
                      <label for="workphone" class="applabel"
                        >Work Phone
                      </label>
                      <span class="applabel"
                        >Ext.
                        <HelpIcon
                          :message="help.workPhone"
                          :id="'workphonetooltip'"
                      /></span>
                    </div>
                  </template>
                  <span
                    style="
                      flex-direction: column;
                      width: 100%;
                      margin-top: -10px;
                    "
                  >
                    <span style="padding-left: 5px">###-###-#### x####</span>
                    <v-field
                      v-model.trim="workPhone"
                      name="workphone"
                      id="workphone"
                      v-slot="{ field }"
                    >
                      <input
                        v-bind="field"
                        aria-describedby="workphone-errormsg"                        
                        v-on:keypress="isNumber($event)"
                        style="width: 93%"
                        maxLength="17"
                      />
                    </v-field>
                  </span>
                  <template v-slot:error>
                    <v-error id="workphone-errormsg" name="workphone" />
                  </template>
                </FormField>
              </div>
              <div class="inputs">
                <div
                  class="incomecheck"
                  role="radiogroup"
                  aria-label="How do you receive your income"
                >
                  <h4 class="contentheader">How do you receive your income?</h4>
                  <div class="radiolist">
                    <div class="radio-input">
                      <div class="radio-wrapper" @click="paymentMethod = 'D'">
                        <v-field
                          v-model="paymentMethod"
                          name="paymentMethod"
                          id="D"
                          type="radio"
                          value="D"
                          tabindex="-1"
                          aria-hidden="true"
                          @keydown.space="radiobuttonselection('D', $event)"
                        />
                        <span
                          id="spanDirectDeposit"
                          class="radio-checkmark"
                          aria-label="Direct Deposit"
                          tabindex="0"
                          role="radio"
                          @keydown.space="radiobuttonselection('D', $event)"
                          :aria-checked="paymentMethodyes"
                        />
                        <span class="radio-label">
                          <label id="lblPaymentmethoddirect" for="D">
                            Direct Deposit
                          </label>
                        </span>
                      </div>
                    </div>
                    <div class="radio-input">
                      <div class="radio-wrapper" @click="paymentMethod = 'P'">
                        <v-field
                          v-model="paymentMethod"
                          name="paymentMethod"
                          id="P"
                          type="radio"
                          value="P"
                          tabindex="-1"
                          aria-hidden="true"
                          @keydown.space="radiobuttonselection('P', $event)"
                        />
                        <span
                          id="spanPaperCheck"
                          class="radio-checkmark"
                          aria-label="Paper Check"
                          tabindex="0"
                          role="radio"
                          :aria-checked="paymentMethodNo"
                          @keydown.space="radiobuttonselection('P', $event)"                          
                          aria-describedby="paymentMethod-errormsg"
                        />
                        <span class="radio-label">
                          <label id="lblPaymentmethodpaper" for="P">
                            Paper Check
                          </label></span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="error paymentmethoderror"
                    style="text-align: left"
                  >
                    <v-error id="paymentMethod-errormsg" name="paymentMethod" />
                  </div>
                </div>
                <FormField :errormsg="errors['takehomepay']" class="low-height">
                  <template v-slot:label>
                    <label for="takehomepay" class="applabel"
                      >Take-Home (Net) Pay</label
                    >
                    <div class="subnote">(per paycheck)</div>
                  </template>
                  <i class="fas fa-dollar-sign dollaricon"></i>
                  <v-field
                    v-model.trim="takeHomePay"
                    name="takehomepay"
                    id="takehomepay"
                    v-slot="{ field }"
                  >
                    <input
                      v-bind="field"
                      v-on:keypress="isNumberAmt($event)"
                      @change="handleOtherAmount(takeHomePay)"
                      class="takehomeinput"
                      aria-describedby="takehomepay-errormsg"
                    />
                  </v-field>
                  <template v-slot:error>
                    <v-error id="takehomepay-errormsg" name="takehomepay" />
                  </template>
                </FormField>
              </div>
            </fieldset>
            <div class="upperbottom" role="alert" v-show="showcommonerrormsg">
              {{ help.commonapperror }}
            </div>
            <div class="bottom">
              <button class="button-orange">CONTINUE</button>
            </div>
          </section>
        </v-form>
      </main>
    </div>
  </div>
</template>

<script>
import legal from "@/config/text/legal";
import help from "@/config/text/help";
import { FormField, HelpIcon } from "@/components";
//import { Validator } from "vee-validate";
import AppNav from "@/components/application/AppNav.vue";
import {incomeSources,secondarySources} from "@/config/incomeSources";
import { limitNums, limitAlphabates } from "@/utils/plugins/inputs";
import PageHeaderApplication from "@/components/global/PageHeaderApplication";
import * as Yup from "yup";
export default {
  name: "AppIncome",
  title: "Line of Credit Application",
  components: {
    PageHeaderApplication,
    FormField,
    HelpIcon,
    AppNav,
  },
  data() {
    let sourceErrormsg = "Please select your main income source";
    let secondarysourceErrormsg = "Please select other income type";
    let employerNameErrormsg = "Please enter your employer’s name";
    let workphoneErrormsg = "Please enter your 10-digit work phone number";
    let takehomepayErrormsg =
      "Please enter the dollar amount of your take-home pay";
    let paymentMethodErrormsg = "Please select one of the options";
    const schema = [
      Yup.object().shape({
        source: Yup.string().notOneOf(["Select", "0"], sourceErrormsg),
        takehomepay: Yup.string().required(takehomepayErrormsg),
        paymentMethod: Yup.string().required(paymentMethodErrormsg),
      }),
      Yup.object().shape({
        source: Yup.string().notOneOf(["Select", "0"], sourceErrormsg),
        takehomepay: Yup.string().required(takehomepayErrormsg),
        paymentMethod: Yup.string().required(paymentMethodErrormsg),
        employername: Yup.string().required(employerNameErrormsg),
        workphone: Yup.string()
          .required(workphoneErrormsg)
          .max(17, workphoneErrormsg),
      }),
        Yup.object().shape({
        source: Yup.string().notOneOf(["Select", "0"], sourceErrormsg),
        secondarysource: Yup.string().notOneOf(["Select", "0"], secondarysourceErrormsg),
        takehomepay: Yup.string().required(takehomepayErrormsg),
        paymentMethod: Yup.string().required(paymentMethodErrormsg),
      }),

    ];

    return {
      schema,
      currentStep: 0,
      legal: legal,
      help: help,
      showcommonerrormsg: false,
      incomeSources: incomeSources,
      secondarySources:secondarySources,

};
  },
  created() {
    if (!this.$store.state.application.addressComplete) {
      this.$router.replace({ name: "app-address" });
    }
    if (this.ip == null) {
      this.$store.dispatch("findIPAddress");
    }
    if (
      this.$store.state.application.source == "" ||
      this.$store.state.application.source == null ||
      this.$store.state.application.source == undefined
    ) {
      this.$store.state.application.source = "0";
      this.currentStep = 0;
    } 

        if (
      this.$store.state.application.secondarySource == "" ||
      this.$store.state.application.secondarySource == null ||
      this.$store.state.application.secondarySource == undefined
    ) {
      this.$store.state.application.secondarySource = "0";
    }

    else {
      if ((this.$store.state.application.source == "P") || ((this.$store.state.application.source == "O"))) this.currentStep = 1;
      else if (this.$store.state.application.source == "W") this.currentStep = 2;
      else this.currentStep = 0;
    }
  },
  computed: {
    currentSchema: {
      get() {
        return this.schema[this.currentStep];
      },
    },
    isFormValid: function () {
      const invalidFields = !!Object.values(this.fields).filter(
        (field) => !field.valid
      ).length;

      const invalidAuxFields =
        this.source === null ||
        (this.source === "Employed" ? this.employerName === null : false) ||

        this.paymentMethod === null ||
        this.paymentInterval === null;
      return !invalidFields && !invalidAuxFields;
    },
    source: {
      get() {
        return this.$store.state.application.source;
      },
      set(value) {
        this.$store.commit("updateSource", value);
        this.$store.commit("updateSecondarySource", "0");
        if ((value == "P") ||(value == "O")) {
            this.currentStep = 1;
          }
          else if (value == "W") {
            this.currentStep = 2;
          }
          else {
            this.employerName = "";
            this.workPhone = "";
            this.ext = "";
            this.currentStep = 0;
          }
      },
    },
     secondarysource: {
      get() {
        return this.$store.state.application.secondarySource;
      },
      set(value) {
        this.$store.commit("updateSecondarySource", value);
      },
    },

    employerName: {
      get() {
        return this.$store.state.application.employerName;
      },
      set(value) {
        this.$store.commit("updateEmployerName", value);
      },
    },
    workPhone: {
      get() {
        if (this.ext != "" && this.ext != null)
          return this.$store.state.application.workPhone + "x" + this.ext;
        else return this.$store.state.application.workPhone;
      },
      set(value) {
        this.$store.commit("updateWorkPhone", value);
      },
    },
    ext: {
      get() {
        return this.$store.state.application.ext;
      },
      set(value) {
        this.$store.commit("updateExt", value);
      },
    },
    takeHomePay: {
      get() {
        return this.$store.state.application.takeHomePay;
      },
      set(value) {
        this.$store.commit("updateTakeHomePay", value);
      },
    },
    paymentMethod: {
      get() {
        return this.$store.state.application.paymentMethod;
      },
      set(value) {
        this.$store.commit("updatePaymentMethod", value);
      },
    },
    paymentMethodNo: {
      get() {
        if (this.$store.state.application.paymentMethod == "P") {
          return true;
        } else {
          return false;
        }
      },
    },
    paymentMethodyes: {
      get() {
        if (this.$store.state.application.paymentMethod == "D") {
          return true;
        } else {
          return false;
        }
      },
    },
  },
  mounted() {
    this.focusfirstelement();
    document.getElementById("divincome").setAttribute("aria-current", "step");
  },
  methods: {
    focusfirstelement() {
      document.getElementById("tittleincome").focus();
    },
    radiobuttonselection(val, event) {
      this.paymentMethod = val;
      event.preventDefault();
    },
    completeSection() {
      this.showcommonerrormsg = false;
      this.$store.commit("updatecompleteIncome", true);
      this.$router.push({ name: "app-payfrequency" });
    },
    onIncompleteSection() {
      this.showcommonerrormsg = true;
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    isNumberAmt(evt) {
      const keysAllowed = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        ".",
      ];
      const keyPressed = evt.key;

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    },
    handleOtherAmount(value) {
      if (value != "") {
        this.takeHomePay = this.formatCurrencySpecial(value);
      }
    },
    formatCurrencySpecial(value) {
      return parseFloat(value).toFixed(2);
    },

    limitNums,
    limitAlphabates,
  },
};
// Validator.localize({
//   en: {
//     custom: {
//       source: {
//         selectotherthanfirstvalue: "Please select your main income source",
//       },
//       employername: {
//         required: "Please enter your employer’s name",
//       },
//       workphone: {
//         required: "Please enter your 10-digit work phone number",
//       },
//       paymentMethod: {
//         required: "Please select one of the options",
//       },
//       takehomepay: {
//         required: "Please enter the dollar amount of your take-home pay",
//       },
//     },
//   },
// });
</script>

<style scoped lang="scss">
main {
  width: 100%;


  section {
    display: flex;
    flex-flow: column nowrap;
    max-width: 1023px !important;

    @media (max-width: 767px) {
      margin-left: 15px !important;
      margin-right: 15px !important;
    }
  }

  .headertext {
    max-width: 800px;
    width: 100%;
    @media (min-width: 768px) and (max-width: 1023px) {
      max-width: 750px;
    }
  }

  .radiolist {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
  }

  .radio-input .radio-checkmark {
    width: 15px;
  }

  .paymentmethoderror {
    margin-top: 15px !important;
  }

  .form {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    padding: 30px 100px 30px 100px;
    margin-top: 23px;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid $gray;
    justify-content: space-evenly;
    align-items: left;

    @media (min-width: 768px) and (max-width: 1023px) {
      padding: 30px 20px 30px 20px;
    }
    @media (max-width: 767px) {
      max-width: 720px;
      margin: 5% 0px !important;
      padding: 20px !important;
    }
    .inputs {
      display: flex;
      flex-flow: row nowrap;
      align-content: space-between;
      justify-content: space-between;
      margin-top: 15px;
      margin-bottom: 15px;

      @media (max-width: 767px) {
        flex-flow: column nowrap;
      }
    }
  .hideondevices
{
    @media (max-width: 767px) {
      display: none;
    }
}
    .short {
      width: 200px;
    }
  }

  .contentheader {
    font-weight: 600;
    margin-bottom: 10px;
    @media (max-width: 767px) {
      margin-left: 0px;
      font-size: 20px;
    }

    @media (max-width: 428px) {
      font-size: 18px;
    }

    @media (max-width: 380px) {
      font-size: 15px;
    }
  }

  .incomecheck {
    display: flex;
    flex-flow: column nowrap;
  }

  input {
    border-radius: 0px;
    border: solid 1px $fieldborder-gray;
    padding: 3px 5px 3px 5px;
  }

  .field {
    @media (max-width: 767px) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    .field-label {
      margin-left: 1px;
    }
  }

  .radio-input {
    margin: 15px 120px 0 0;
  }

  .select {
    display: block;
    height: 26px;
    border: 1px solid $fieldborder-gray;
    @media (max-width: 767px) {
      width: 100%;
      max-width: inherit;
    }
  }

  .select:after {
    display: none;
  }

  select {
    color: $gray;
    padding: 0px 15px 0px 15px;
  }

  .takehomeinput {
    padding-left: 30px;
  }

  .icon {
    position: absolute;
    color: $orange;
    font-size: 22px;
    cursor: pointer;
    pointer-events: none;
    margin-left: -22px;
    margin-top: 3px;
  }

  .dollaricon {
    position: absolute;
    font-size: 18px;
    cursor: pointer;
    pointer-events: none;
    margin-left: 15px;
    margin-top: 7px;
    font-style: italic;
  }

  .incomesource {
    .field {
      max-width: 250px;
      @media (max-width: 767px) {
        max-width: inherit;
      }
    }
  }

  .banking-info {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 40px;

    .check-sample {
      height: 131px;
      width: 349px;
      background-image: url("../../assets/backgrounds/check-sample.svg");
      background-repeat: no-repeat;
    }
  }

  .bottom {
    display: block;
    text-align: right;
    margin-top: 15px;
    @media (min-width: 768px) and (max-width: 1023px) {
      margin-left: 18px;
      margin-right: 18px;
    }
    @media (max-width: 767px) {
      margin-top: 0px;
      text-align: center;
    }
  }
  .upperbottom {
    text-align: right;
    color: $error;
    margin: 15px 0 10px;
    @media (max-width: 767px) {
      text-align: center;
      margin-top: 0px;
    }
  }
  .privacy {
    padding-right: 80px;
    a {
      color: $blue;
      border-color: $blue;
    }

    @media (max-width: 767px) {
      flex-flow: column nowrap;
      padding: 0px;
    }
  }

  .subnote {
    font-size: 11px;
    margin-left: 5px;
    margin-top: 2px;
    text-transform: none;
    font-style: italic;
  }

  .error {
    height: 15px;
    max-width: 312px;
    margin: 0 5px;
    font-size: 13px;
    color: #bc403a;
  }

  .applabel {
    font-weight: 600;
  }

  .repaymentMethod {
    position: relative;
    margin-top: 20px;
  }
  .repaymentMethodSpan {
    width: 350px;
    position: absolute;
  }
  @media (max-width: 1023px) {
    .banking-info {
      flex-flow: column nowrap;
      align-items: center;
    }

    .radio-input {
      margin: 20px 20px 0 0;
    }

    .bottom {
      .inputs {
        flex-flow: column-reverse nowrap;
        align-items: center;

        button {
          margin-bottom: 10px;
        }
      }
    }
  }

  .button-orange {
    @media (min-width: 768px) and (max-width: 1023px) {
      width: 30%;
    }
    @media (max-width: 767px) {
      width: 50%;
      max-width: inherit;
    }
    @media (max-width: 420px) {
      padding: 6px 33px;
    }
    @media (max-width: 380px) {
      padding: 6px 15px;
    }
  }
}

.workphonewrapper {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  margin-top: -10px;
}

</style>