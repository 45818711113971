//import companyInfo from './company';
import { newLineCreditAmount, maximumCreditAmount } from "@/config/amounts";
import { currencyShort } from "@/utils/filters/currency";

export default {
  sameDayAvailability: 'Same Day Funding is available on business days where the request for a draw on an existing ' +
    'line of credit has been completed by 11:45 a.m. Eastern Time. Other restrictions may apply. Certain financial ' +
    'institutions do not support same day funded transactions. When Same Day Funding is not available, funding will ' +
    'occur the next banking business day.',
  newCustomers: `New customers are eligible for credit lines up to ${ currencyShort(newLineCreditAmount) }. Once customers establish a good payment history with XactFlex™, they may be eligible for progressive credit line increases up to a maximum of ${ currencyShort(maximumCreditAmount) }.`,
  sovereignEnterprise: 'XactFlex™ is an economic development arm and instrumentality of, and wholly-owned and ' + 
  'controlled by the Mandan, Hidatsa, and Arikara Nation, a federally-recognized sovereign American Indian '+
  'Tribe (the “Tribe”). This means that XactFlex™ is also a sovereign, governmental entity.  '+
  'XactFlex™’s line of credit product is provided by a sovereign government and the proceeds of our '+
  'business fund governmental services for Tribal citizens. This also means that XactFlex™ is not '+
  'subject to suit or service of process. Nothing in this website is intended to waive or otherwise prejudice XactFlex™’s entitlement to these protections. XactFlex™ is regulated by the Tribe. If you do business with '+
  'XactFlex™, your potential forums for dispute resolution will be limited to those available under '+
  'Tribal law and your line of credit agreement. As more specifically set forth in XactFlex™’s contracts, these forums include informal but affordable and efficient Tribal dispute resolution, '+
  'or individual arbitration before a neutral arbitrator. Otherwise, XactFlex™ is not subject to suit or service of process. '+
  'Neither XactFlex™ nor the Tribe has waived its sovereign immunity in connection with any claims relative to use of this website. '+
  'IF YOU ARE NOT COMFORTABLE DOING BUSINESS WITH A SOVEREIGN INSTRUMENTALITY THAT CANNOT BE SUED IN COURT, YOU SHOULD DISCONTINUE USE OF THIS WEBSITE.',
  agreeToPrivacyPolicy: 'By submitting your information, you agree to the Terms and Privacy Policy of this site and ' +
    'are providing express written consent under the Fair Credit Reporting Act for XactFlex™ to conduct a credit ' +
    'check or obtain a consumer report from a consumer reporting agency. ',
  statesNoBusiness: 'We don’t do business in states that are grayed out.',
  noNeedReveal: 'You don’t have to reveal alimony, child support, or separate maintenance income unless you want it ' +
    'considered as a basis for repaying this obligation.',
  marketingCallsYes: 'I hereby expressly consent to recurring contact from XactFlex or its business partners at the number(s) I provided regarding products or services via live, automated or prerecorded telephone call, text message, or email. I understand that my telephone company may impose charges on me for these contacts, and I am not required to enter into this agreement as a condition of receiving a loan. I understand that I can revoke this consent at any time.',
  marketingCallsNo: 'No, I don’t want marketing offers and updates by phone. Only call me to discuss this application ' + 
    'or the servicing of my account(s).',
  marketingTextsYes: 'I hereby expressly consent to recurring contact from XactFlex or its business partners at the number(s) I provided regarding products or services via live, automated or prerecorded telephone call, text message, or email. I understand that my telephone company may impose charges on me for these contacts, and I am not required to enter into this agreement as a condition of receiving a line of credit. I understand that I can revoke this consent at any time.',
  marketingTextsNo: 'No, I don’t want marketing offers and updates by text or phone calls. Only text or call me to discuss this application or the servicing of my account(s).',
  marketingTextAmount: 'The number of text messages you receive will vary based on account activity. Standard ' +
    'message and data rates may apply. You may cancel at any time.',
  marketingConsent: 'By selecting yes for either of these options and continuing, you are providing your electronic ' +
    'signature in agreement to the terms.',
  militaryDisclaimer: 'Federal law provides protections to active-duty members of the armed forces and their dependents.',
  militaryYes: 'a regular or reserve member of the Army, Navy, Marine Corps, Air Force, or Coast Guard, serving on active duty under a call or order that does not specify a period of 30 days or fewer. Or, I AM a dependent of a member of the Armed Forces on active duty as described above, because I am the member’s spouse, the member’s child under the age of eighteen years old, or I am an individual for whom the member provided more than one-half of my financial support for 180 days immediately preceding today’s date.',
  militaryNo: 'a regular or reserve member of the Army, Navy, Marine Corps, Air Force, or Coast Guard, serving on active duty under a call or order that does not specify a period of 30 days or fewer (or a dependent of such a member).',
  signatureTerms: 'By checking the box and electronically signing below, you accept the terms and conditions here and in the Arbitration Agreement, the Consent for Electronic Records and the Credit Agreement. Sign by typing your full name below.',
  abilityToDraw: `While your payment is processing, your available credit is temporarily reduced by the amount of that payment. Draws are not allowed on past-due accounts.`,
  drawSubmitted: '*If you request a draw by 7:45 p.m. Eastern Time on a business day, your funds will typically become available on the next business day.',
  textFrequency: 'The frequency of text messages may vary based on account activity. Message and data rates may apply.'
}