<template>
  <div class="make-payment">
    <div class="payment cardNew">
      <PortalDrawReq/>
   </div>
  </div>
</template>

<script>
  import {
   
  PortalDrawReq
} from '@/components';

export default {
  name: 'PortalDrawReqMobile',
  components: { 
   
   PortalDrawReq,
   },
   async created() {
      this.showPartialSummary = true
      
  },
  computed: {
    
    delinquentOrchargeoff: {
      get() {  
          if (this.$store.state.account.delinquent || this.$store.state.account.chargeoff)
              return true;
          else
              return false;
      }
    },
    showDrawRequest: {
      get() { return this.$store.state.portal.showDrawRequest },
      set(value) { this.$store.commit('updateShowDrawRequest', value) }
    },
    showPartialSummary: {
      get() { return this.$store.state.portal.showPartialSummary },
      set(value) { this.$store.commit('updateShowPartialSummary', value) }
    },
  }
}

</script>

<style scoped lang="scss">
.make-payment{
   @media (min-width: 800px) {
    width: 100%;
  }
}
.cardPortal {
  background: $absolute-white;
}

.portal-wrapper .portal {
   width: 100%;
    margin-top: 20px;
    margin-right: 8%;
}

#content {
  margin-top:20px;
}

nav {
  margin-top:80px;
}


.portal-wrapper{
  max-width: 100%;
}

</style>