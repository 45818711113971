export default `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8">
    <meta name="generator" content="CoffeeCup HTML Editor (www.coffeecup.com)">
    <meta name="dcterms.created" content="Wed, 11 Nov 2020 16:54:11 GMT">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <title>California Consumer Privacy Act (CCPA) Policy</title>
    
    
  </head>
    <body>
        <div class="container">
            <div class="gray">
                <div class="">
                    <h3> California Consumer Privacy Act (CCPA) Policy</h3>
                    The Company makes the following disclosures pursuant to the CCPA. California consumers have the right to request a disclosure of what categories and specific pieces of information were collected on them, the categories of sources from which the information was collected, the business purposes for collecting or selling the information, and the categories of third parties to whom the information was shared. If the Company sells personal information for a business purpose, California consumers have the right to request a disclosure of the categories of personal information collected on them, the categories of personal information sold, the categories of information disclosed for a business purpose, the categories of buyers of the information, and what categories of personal information were sold to which categories of buyers. California Consumers have a right to request deletion of their personal information collected by the Company. California consumers have the right not to be discriminated against by a business on account of their exercise of any of these rights. The Company provides a webpage with information and a pathway for California consumers to exercise these rights under the CCPA at the following link: CCPA Request Form. If you submit a request, the Company will compare the information you provide to Company records and respond to your request accordingly. An authorized agent can submit a request on your behalf by providing additional written information substantiating the agent/principal relationship after using the toll-free number request submission method.
                    <br/><br/>
                    The categories of personal information collected on California consumers in the past 12 months includes: name, address, signature, telephone number, internet protocol address, email address, account name, social security number, birth-date, education information, driver’s license or state identification information, bank information,credit information, employment-related information, employment eligibility information, internet and network information, geo-location data, character and reputation information, Office of Foreign Assets Control records, biographical and personal history information, and inferences drawn from this information. The categories of personal information of California consumers sold in the past 12 months includes: name, address, telephone number, email address, credit information, and inferences drawn from this information. These categories of information may be sold to Company lending partners. The categories of personal information of California consumers disclosed for a business purpose in the past 12 months includes: name, address, signature, telephone number, internet protocol address, email address, account name, social security number, birth date, education information, driver’s license or state identification information, bank information, credit information, employment-related information, employment eligibility information, internet and network information, geo-location data, character and reputation information, Office of Foreign Assets Control records, biographical and personal history information, and inferences drawn from this information. These categories of information may be disclosed to Company lending partners for business purposes. The Company does not sell personal information of minors under 16 years of age.
                    <br/><br/>
                    California consumers have the right to opt out of the sale of their personal information. The Company provides a webpage with information and a pathway for California consumers to exercise this right to opt-out under the CCPA at the following link: CCPA Request Form .
                    <br/><br/>
                    Note that certain information is exempt from the requirements of the CCPA, including information collected, processed, sold, or disclosed pursuant to the federal Gramm-Leach-Bliley Act, and implementing regulations, or the California Financial Information Privacy Act. These exemptions may impact the Company’s actions in response to consumers who exercise the rights set forth above. Despite any actions taken by the Company and inferences drawn from those actions, the Company reserves its rights to act in accordance with these exemptions.
                    <br/><br/><br/><br/>


                    <h3>Policy Updates</h3>
                    This Policy is effective as of April 2021. We will notify you of any changes to this Policy by posting a notice on our homepage.  Any changes will be effective as soon as they are published on our sites.
                </div>
            </div>
        </div>
    </body>
</html>
`