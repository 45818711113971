<template>
  <main>
    <div class="maincontent">
      <div :class="[!passwordCreated ? 'login-box' : 'loginbox-success']">
        <ContentSectionTitle>
          Welcome to <XactFlexBrandedText />
          <template v-slot:subtitle>
            <span style="float: left; text-align: left"
              >Your Line of Credit has been conditionally approved.</span
            >
          </template>
        </ContentSectionTitle>
        <!--<div class="error" v-html="errorMsg" />-->
        <div class="error" v-html="identityErrorMsg" role="alert" />
        <!-- 2FA Start -->
        <div class="maincontentsectiondiv">
          <fieldset>
            <legend>
              <h2 class="visually-hidden">Create Your Password</h2>
            </legend>
            <div class="leftcontentsection" role="alert">
               <h2 class="leftcontentheader">Create Your Password</h2>
              <p class="para1">
                Create your password now so you can log in to eSign and finalize
                your line of credit.
              </p>
              <br class="hidden" />
              <p class="para2">
                After you’ve finalized your line of credit, you’ll be able to
                log in to manage your account, request draws and make payments.
              </p>
            </div>
            <div v-if="step1" class="rightcontentsection">
              <v-form :validation-schema="schemaEmail" keep-values @submit="emailverification" v-slot="{ errors }"> 
              <p class="contentheader">Please enter your email address.</p>
              <FormField :errormsg="errors['email']">
                <template v-slot:label>
                  <label for="emailinput"> EMAIL </label>
                </template>
                <v-field
                      v-model.trim="email"                                        
                      name="email"
                      v-slot="{ field }"
                  >
                <input
                  v-bind="field"
                  id="emailinput"
                  type="email"
                  data-vv-validate-on="blur"
                  aria-describedby="email-errormsg"
                />
                </v-field>
                <template v-slot:error>
                  <v-error id="email-errormsg" name="email" />
                </template>
              </FormField>
              <div role="alert" class="error" v-html="errorMsg" />
              <div class="bottomsection">
                <button
                  id="btnCPemailverification"
                  :disabled="createPasswordClicked ? true : false"
                  class="button button-primary"
                  alt="next"
                  draggable="false"
                >
                  NEXT
                </button>
              </div>
              <div class="loginlink">
                <p class="cardtxt" tabindex="0">
                  Do you already have a password?
                  <router-link :to="{ name: 'login' }" class="login-link"
                    >Log in here</router-link
                  >
                </p>
              </div>
            </v-form>
            </div>
            <!-- Step1 completed-->

            <div v-if="step6" class="rightcontentsection">
              <div class="invalidsecion">
                <!--<p class="contentheading">Create Your Password</p>  -->
                <div role="alert" class="congrats" v-html="errorMsg" />
                <div class="btnbottom">
                  <a
                    class="button-login"
                    :href="'tel:' + companyInfo.contactPhone"
                    >Call Now</a
                  >
                </div>
              </div>
            </div>
            <!-- invalid User completed-->

            <div v-if="step2" class="rightcontentsection">
              <div tabindex="0" id="CPidentityverify">
                <p class="contentheader">Identity Verification</p>
                <p class="contentsub">
                  For your security, we need to confirm it’s you by sending a
                  one-time passcode. Your passcode will be active for 30
                  minutes.
                </p>
              </div>
              <p class="txtemail mt">
                Email <span class="txtspan">{{ email }}</span>
              </p>
              <div tabindex="0" style="margin-top: 20px">
                <p class="contentheader2">How should we send your code?</p>
                <p class="txtblue">Choose one.</p>
              </div>
              <section class="choosediv">
                <div
                  role="radiogroup"
                  aria-label="Identity Verification"
                  v-if="showsendcodeoptions"
                >
                  <div
                    v-for="optiontype in verificationoptionlist"
                    :key="optiontype.id"
                  >
                    <div class="radio-input">
                      <div class="radio-wrapper short">
                        <input
                          :id="'rdo2faText' + optiontype.id"
                          type="radio"
                          name="esignchooseMethod"
                          v-model="choosepasscodeMethod"
                          :value="optiontype"
                          tabindex="-1"
                          aria-hidden="true"
                          @click="Text2faSelectedKeyDown($event, optiontype)"
                        />
                        <span
                          :id="'span2faText' + optiontype.id"
                          class="radio-checkmark"
                          role="radio"
                          name="radiofa"
                          tabindex="0"
                          :aria-describedby="'lbl2faText' + optiontype.id"
                          @keydown.space="
                            Text2faSelectedKeyDown($event, optiontype)
                          "
                        />
                        <label
                          :id="'lbl2faText' + optiontype.id"
                          :for="'rdo2faText' + optiontype.id"
                          class="bold"
                          style="font-size: 14px"
                          >{{ optiontype.CallType }}
                          <span class="txtselection">
                            {{ optiontype.Numbertext }}
                          </span></label
                        >
                      </div>
                    </div>
                  </div>

                  <div class="radio-input" v-if="emailoption">
                    <div class="radio-wrapper short">
                      <input
                        id="rdo2faEmail"
                        type="radio"
                        name="esignchooseMethod"
                        v-model="choosepasscodeMethod"
                        value="email"
                        tabindex="-1"
                        aria-hidden="true"
                      />
                      <span
                        id="span2faEmail"
                        class="radio-checkmark"
                        role="radio"
                        tabindex="0"
                        :aria-checked="
                          choosepasscodeMethod == 'email' ? true : false
                        "
                        aria-label="Email"
                        aria-describedby="errIdntityOption"
                        @keydown.space="Email2faSelectedKeyDown($event)"
                      />
                      <label
                        id="lbl2faEmail"
                        for="rdo2faEmail"
                        class="bold"
                        style="font-size: 14px"
                        >Email</label
                      >
                      <span class="esigntxtselection">
                        {{ maskedemail }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="inputerror">
                  <p
                    v-if="showOptionError"
                    id="optselerror"
                    role="alert"
                    class="error"
                  >
                    Please select an option
                  </p>
                </div>
              </section>
              <div class="bottomsection">
                <button
                  :disabled="createPasswordClicked ? true : false"
                  class="button button-primary"
                  alt="sendcode"
                  draggable="false"
                  @click="sendverificationcode"
                >
                  SEND CODE
                </button>
              </div>
            </div>
            <!-- Step2 completed-->

            <div v-if="step3" class="rightcontentsection">
              <p class="txtemail">
                Email <span class="txtspan">{{ email }}</span>
              </p>
              <p class="contentheader" tabindex="0" id="CPstep3">
                Let's get you verified.
              </p>
              <p class="contentsub" for="otpinput">
                Please enter the passcode we provided and choose "Next."
              </p>

              <div v-if="true" class="otpcontainer">
                <div class="otpimage">
                  <img
                    src="../../assets/logos/2fa_icon_em.png"
                    alt="XactFlex"
                    class="otpemailicon"
                    draggable="false"
                    v-if="imgemail"
                  />
                  <img
                    src="../../assets/logos/2fa_icon.png"
                    alt="XactFlex"
                    class="otptxticon"
                    draggable="false"
                    v-else
                  />
                </div>
                <div class="otptext">
                  <FormField>
                    <input
                      id="otpinput"
                      v-for="(value, index) in otpValues"
                      :key="index"
                      type="text"
                      maxlength="1"
                      class="otp-input"
                      :value="value"
                      @input="handleInput(index, $event.target.value)"
                      @keydown.backspace="handleBackspace(index)"
                      ref="otpFields"
                    />
                    <template v-slot:error>
                      <!-- {{ errors.first("passcodefield") }} -->
                    </template>
                  </FormField>
                </div>
              </div>
              <div class="otpcontainer">
                <div :class="appwithdrawn?'errorslot wdadj':(isResendCode?'errorslot mmwidth':'errorslot')">
                  <div class="error" v-html="errorMsg" role="alert" />
                  <div v-if="isResendCode">
                    <p class="resenderror" role="alert">
                      We have received your request and sent your new passcode.
                      If you need further assistance, please contact Customer
                      Service at
                      <a
                        class="button-login"
                        :href="'tel:' + companyInfo.contactPhone"
                        aria-label=" "
                        >{{ companyInfo.contactPhone }}</a
                      >.
                    </p>
                  </div>
                </div>
              </div>
              <br class="hidden" />

              <div class="btnsection">
                <div class="btnleft">
                  <button
                    class="button-resend"
                    :disabled="resendmaxlimit"
                    alt="ResendCode"
                    draggable="false"
                    @click="reSendVerificatonCode"
                  >
                    RESEND CODE
                  </button>
                </div>
                <div class="btnright">
                  <button
                    class="button-verify"
                    :disabled="!passcodeverified"
                    alt="next"
                    draggable="false"
                    @click="verifyPassCode"
                  >
                    NEXT
                  </button>
                </div>
              </div>
            </div>
            <!-- step3 completed-->

            <div v-if="step4" class="rightcontentsection">
              <div class="passcodesuccess">
                <div class="img">
                  <img
                    src="../../assets/logos/Circle_check.png"
                    alt="XactFlex"
                    class=""
                    draggable="false"
                  />
                </div>
                <div
                  class="txt"
                  role="alert"
                  id="verificationsuccessmsg"
                  tabindex="0"
                >
                  <p class="txtsuccess">
                    THANKS FOR VERIFYING<br />
                    THAT IT'S YOU.
                  </p>
                  <p class="txtsubfont">
                    You'll now be directed to create your password.
                  </p>
                </div>
                <loaderbar />
              </div>
            </div>
            <!-- Step4 Completed -->

            <div v-if="passwordCreated" class="rightcontentsection">
              <v-form
                :validation-schema="schemaCreatePwd"
                  @invalid-submit="createPasswordInvalid"
                  @submit="createPassword"
                  v-slot="{errors}"
                >
              <div class="rowcontainer">
                <p class="txtemail">
                  Email <span class="txtspan">{{ email }}</span>
                </p>
                <div class="rows m-size">
                  <div class="left">
                    <FormField :errormsg="errors['birthdate']">
                      <template v-slot:label
                        ><label for="birthdate" class="applabel"
                          >DATE OF BIRTH<span class="lowercase"
                            >mm/dd/yyyy</span
                          ></label
                        >
                      </template>
                      
                      <b-input-group class="inputgroupdatewrapper">
                        <v-field  v-model="birthdate"   name="birthdate" v-slot="{ field }">
                        <b-form-input
                          v-bind="field"
                          id="birthdate"
                          :value="date(birthdate)"
                          type="text"
                          autocomplete="off"
                          maxLength="10"
                          aria-describedby="dateofbirth-errormsg birthdate-errormsg"
                          aria-required="true"
                          @keypress="allowonlynumbersandbackslash"
                          @blur="validateBirthDate()"                          
                          aria-invalid="false"
                          data-vv-delay="100"
                        ></b-form-input>
                      </v-field>
                      <v-field v-model="birthdatenewformat" name="dobformat" v-slot="{ field }">
                        <b-input-group-append>
                          <b-form-datepicker
                           v-bind="field"
                            button-only
                            right
                            locale="en-US"
                            aria-controls="birthdate"
                            :max="maxDateinput"
                            @context="onContext"
                          ></b-form-datepicker>
                        </b-input-group-append>
                        </v-field>
                      </b-input-group>
                      <template v-slot:error>
                        <v-error id="birthdate-errormsg" name="birthdate" v-if="birthdateerror == ''"/>
                        <span
                          v-else
                          id="dateofbirth-errormsg"
                          v-text="birthdateerror"
                        ></span>
                      </template>
                    </FormField>
                  </div>
                  <div class="right">
                    <FormField :errormsg="errors['SSN']">
                      <template v-slot:label
                        ><label for="socsecurity" class="applabel"
                          >LAST 4 OF SSN
                        </label></template
                      >
                      <v-field v-model.trim="lastFourSocial" name="SSN"
                       v-slot="{ field }">
                        <input
                          v-bind="field"
                          id="socsecurity"
                          data-vv-validate-on="blur"
                          type="text"
                          maxlength="4"
                          @keypress="limitNums"
                          aria-describedby="ssn-errormsg"
                        />
                      </v-field>
                      <template v-slot:error>
                        <v-error id="ssn-errormsg" name="SSN" />
                      </template>
                    </FormField>
                  </div>
                </div>
                <div class="rows m-size">
                  <FormField :errormsg="errors['password']">
                    <template v-slot:label
                      ><label for="password" class="applabel"
                        >CREATE PASSWORD</label
                      ></template
                    >
                    <v-field  v-model="password" name="password"
                       v-slot="{ field }">
                    <input
                     v-bind="field"
                     id="password"
                      maxlength="20"
                      class="password"
                      data-vv-validate-on="blur"
                      :type="showPlainPasswords ? 'text' : 'password'"
                      aria-describedby="passwordcriteria enterpwderrormsg"
                      @keyup="frmPassword_Criteria"
                    />
                    </v-field>
                    <div
                      class="show-icon"
                      @click="showPlainPasswords = !showPlainPasswords"
                    >
                      <i
                        :class="
                          showPlainPasswords
                            ? 'fas fa-eye-slash fa-lg'
                            : 'fa fa-eye fa-lg'
                        "
                      />
                    </div>
                    <div
                      v-show="!errors.password && password"
                      class="input-accept"
                    />
                    <div
                      v-show="errors.password"
                      class="input-reject"
                    />
                    <template v-slot:error>
                      <v-error id="enterpwderrormsg" name="password" />
                    </template>
                  </FormField>
                </div>
                <div class="rows m-size">
                  <FormField :errormsg="errors['confirmpassword']">
                    <template v-slot:label>
                      <label for="confirmpassword" class="applabel"
                        >CONFIRM PASSWORD</label
                      ></template
                    >
                    <v-field   v-model="confirmPassword"  name="confirmpassword"
                       v-slot="{ field }">
                    <input
                      v-bind="field"
                      id="confirmpassword"
                      class="password"
                      aria-describedby="confirmpwd-errormsg"
                      :type="showConfirmPasswords ? 'text' : 'password'"
                      maxlength="20"
                      minlength="8"
                    />
                    </v-field>
                    <div
                      class="show-icon"
                      @click="showConfirmPasswords = !showConfirmPasswords"
                    >
                      <i
                        :class="
                          showConfirmPasswords
                            ? 'fas fa-eye-slash fa-lg'
                            : 'fa fa-eye fa-lg'
                        "
                      />
                    </div>
                    <div
                      v-show="
                        !errors.confirmPassword && confirmPassword
                      "
                      class="input-accept"
                    >
                      <span class="matchicon">match</span>
                    </div>
                    <div
                      v-show="errors.confirmPassword"
                      class="input-reject"
                    />
                    <template v-slot:error>
                      <v-error id="confirmpwd-errormsg" name="confirmpassword" />
                      </template>
                  </FormField>
                </div>
                <div class="rows m-top">
                  <p class="instructions" id="passwordcriteria">
                    <span>Passwords must contain</span>
                    <!-- <span>
                      {{ help.passwordRules }}
                    </span> -->
                    <span ID="revPwd_Reg6" class="go-normal">
                      8 to 20 characters</span
                    ><span> and have at least</span>
                    <span ID="revPwd_Reg1" class="go-normal">
                      one uppercase letter,</span
                    >
                    <span ID="revPwd_Reg2" class="go-normal">
                      one lowercase letter,</span
                    >
                    <span ID="revPwd_Reg3" class="go-normal"> one number,</span>
                    <span ID="revPwd_Reg4" class="go-normal">
                      one special character (ex. !@%$&*)</span
                    ><span> and</span>
                    <span ID="revPwd_Reg5" class="go-normal"> no spaces.</span>
                  </p>
                </div>
                <div class="rows">
                  <div class="bottom">
                    <button
                      :disabled="createPasswordClicked ? true : false"
                      class="button button-primary"
                      alt="Save Password"
                      draggable="false"
                    >
                      SAVE PASSWORD
                    </button>
                  </div>
                </div>
              </div>
              </v-form>
            </div>
            <!-- Step5 Completed -->

            <div
              v-if="successfullyCreated"
              class="rightcontentsection txtalign"
            >
              <div role="alert">
                <div class="successheader">Congratulations!</div>
                <div class="congrats">
                  You’ve successfully created your password.
                </div>
              </div>
              <button
                class="button-login"
                alt="login"
                draggable="false"
                @click="navigate"
              >
                Log In
              </button>
            </div>
            <!-- Step6 Completed -->
          </fieldset>
        </div>
        <!-- 2FA section completed-->
        <!-- 2FA Stop -->
      </div>
    </div>
  </main>
</template>

<script>
import help from "@/config/text/help";
import companyInfo from "@/config/text/company";
import {
  ContentSectionTitle,
  FormField,
  XactFlexBrandedText,
  loaderbar,
} from "@/components";
import { passwordRegex } from "@/config/validations";
// import DatePicker from "vuejs-datepicker";
import { limitNums } from "@/utils/plugins/inputs";
//import { Validator } from "vee-validate";
import { date } from '@/utils/filters/dates';
import {
  formatemail2FA,
  formatcellno2FA,
  generatePasscode,
  GetCurrentDate,
  TimeElapsed,
} from "@/config/commonfunctions";
import pre from "@/config/text/createpassword";
import {
  birthdateStartDate,
  birthdateDisabledDates,
  birthPreviousDate,
} from "@/config/dates";
import moment from "moment";
import { mapState, mapMutations } from "vuex";
import { wrongPwdAttempt } from "@/store/login";
import "../../styles/datepicker_bootstrap.scss";
import "../../styles/datepicker_bootstrapvue.scss";
import * as Yup from "yup";

export default {
  name: "CreatePassword",
  title: "Create Password",
  components: {
    ContentSectionTitle,
    FormField,
    XactFlexBrandedText,
    loaderbar,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);
    maxDate.setFullYear(maxDate.getFullYear() - 18);

    let emailerrormsg = "Please enter your email address in this format: name@example.com";
    const schemaEmail = 
      Yup.object().shape({
        email: Yup.string().email(emailerrormsg).required(emailerrormsg)
      });

    let birthdateReqerrormsg = "Please enter your date of birth";
    let birthdateInValiderrormsg = "Please enter a valid birthdate";
    let SSNerrormsg = "Please enter the last four digits of your social security number";
    let passworderrormsg = "Please enter a password that meets the criteria";
    let confirmpasswordReqerrormsg = "Please confirm your password.";
    let confirmpassworderrormsg = "Passwords must match.";
    const schemaCreatePwd = 
      Yup.object().shape({ 
        birthdate: Yup.string().required(birthdateReqerrormsg)
            .matches(/^\d{2}\/\d{2}\/\d{4}$/ , birthdateInValiderrormsg),

        SSN: Yup.string().required(SSNerrormsg).min(4,SSNerrormsg)
          .test('type', SSNerrormsg, value => !isNaN(value)),

        password: Yup.string().required(passworderrormsg)
                  .min(8,passworderrormsg)
                  .max(20,passworderrormsg)
                  .matches(/(?=.*[a-z])/, passworderrormsg)
                  .matches(/(?=.*[A-Z])/, passworderrormsg)
                  .matches(/(?=.*\d)/, passworderrormsg)
                  .matches(/(?=.*\W)/, passworderrormsg)
                  .matches(/^\S*$/, passworderrormsg),
        confirmpassword: Yup.string().required(confirmpasswordReqerrormsg).oneOf([Yup.ref('password')],confirmpassworderrormsg),
      });

    return {
      schemaEmail,
      schemaCreatePwd,
      help: help,
      password: null,
      confirmPassword: null,
      companyInfo: companyInfo,
      passwordRegex: passwordRegex,
      showPlainPasswords: false,
      showConfirmPasswords: false,
      passwordCreated: false,
      successfullyCreated: false,
      createPasswordClicked: false,
      lastFourSocial: "",
      ssnError: "",
      birthdateerror: "",
      birthdateStartDate: birthdateStartDate,
      birthdateDisabledDates: birthdateDisabledDates,
      birthPreviousDate: birthPreviousDate,
      step1: true,
      step2: false,
      step3: false,
      step4: false,
      step5: false,
      step6: false,
      imgemail: false,
      showOptionError: false,
      formatemail2FA: formatemail2FA,
      formatcellno2FA: formatcellno2FA,
      generatePasscode: generatePasscode,
      GetCurrentDate: GetCurrentDate,
      otpValues: ["", "", "", "", "", ""],
      verificationoptionlist: [],
      showsendcodeoptions: false,
      mobileoption: false,
      emailoption: false,
      passcode: "",
      extravalue: "",
      passcodeverified: false,
      resendmaxlimit: false,
      havemobile: false,
      isResendCode: false,
      maxDateinput: maxDate,
      havelandline: false,
      haveemail: false,
      isMobileNumber: "Call",
      isLandLineNumber: "Call",
      choosePasscodeNumber: null,
      choosePasscodeType: null,
      appwithdrawn: false,
      wrongPwdAttempt: wrongPwdAttempt,
      date: date,
      sentNumber: "",
      pinCheckResponse:""
    };
  },
  created() {
    this.$store.state.login.errorMsg = "";
    if (
      this.$route.params.emailaddress != null ||
      this.$route.params.emailaddress != undefined
    )
      this.$store.commit("updateLoginEmail", this.$route.params.emailaddress);
    this.$store.commit("updateBirthdate", "");
    this.$store.commit("updateLoginSocial", "");
    this.$store.commit("updateEmailNotFound", false);
  },
  computed: {
    ...mapState({
      token: "token",
      errorMsg: (state) => state.login.errorMsg,
    }),
    isFormValid: function () {
      return (
        !Object.values(this.fields).filter((field) => !field.valid).length &&
        !this.validateBirthDate() &&
        this.validatePassword(this.password) &&
        this.birthdateerror == ""
      );
    },
    isVerifyPasscodeValid: function () {
      return !Object.values(this.fields).filter((field) => !field.valid).length;
    },
    email: {
      get() {
        return this.$store.state.login.email;
      },
      set(value) {
        this.$store.commit("updateLoginEmail", value);
      },
    },
    choosepasscodeMethod: {
      get() {
        return this.$store.state.login.chooseMethod;
      },
      set(value) {
        this.$store.commit("updatechooseMethod", value);
      },
    },
    setpasscode: {
      get() {
        return this.$store.state.login.passcode;
      },
      set(value) {
        this.$store.commit("updatepassCode", value);
      },
    },
    custId: {
      get() {
        return this.$store.state.login.custID;
      },
      set(value) {
        this.$store.commit("updateCustID", value);
      },
    },
    firstName: {
      get() {
        return this.$store.state.login.firstName;
      },
      set(value) {
        this.$store.commit("updatefirstName", value);
      },
    },
    birthdate: {
      get() {
        if (
          this.$store.state.login.birthdate != null &&
          this.$store.state.login.birthdate.toString().includes("-") &&
          this.$store.state.login.birthdate.length == 10
        ) {
          return moment(
            String(this.$store.state.login.birthdate),
            "YYYY-MM-DD"
          ).format("MM/DD/YYYY");
        }

        return this.$store.state.login.birthdate;
      },
      set(value) {
        if (value.toString().length > 10) {
          value = moment(value, "MM/DD/YYYY").format("MM/DD/YYYY");
        }
        this.$store.commit("updateBirthdate", value);
      },
    },
    birthdatenewformat: {
      get() {
        if (String(this.birthdate).length == 10) {
          var val = moment(String(this.birthdate), "MM/DD/YYYY").format(
            "YYYY-MM-DD"
          );
          return val;
        } else {
          return this.birthdate;
        }
      },
      set(value) {
        this.birthdate = value;
      },
    },
    errorMsg: {
      get() {
        return this.$store.state.login.errorMsg;
      },
    },
    identityErrorMsg: {
      get() {
        return this.$store.state.login.identityErrorMsg;
      },
    },
  },
  mounted() {
    this.focusfirstelement();
  },
  methods: {
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      //this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      //this.selected = ctx.selectedYMD;
      if (
        ctx.selectedYMD != null &&
        ctx.selectedYMD != "" &&
        ctx.selectedYMD != "2001-01-01" &&
        ctx.selectedYMD.length == 10
      ) {
        this.validateBirthDate();
      }
    },
    focusfirstelement() {
      document.getElementById("btnCPemailverification").focus();
    },
    onChangePasscodeMethod(event, NumberType) {
      this.choosePasscodeNumber = event.target.value;
      this.choosePasscodeType = NumberType;
      //console.log(this.choosePasscodeNumber + this.choosePasscodeType);
    },
    handleInput(index, value) {
      if (value.length > 1) {
        return;
      }
      this.otpValues[index] = value;
      if (value.length === 1 && index < this.otpValues.length - 1) {
        this.$refs.otpFields[index + 1].focus();
      }
      if (index == this.otpValues.length - 1) {
        this.passcodeverified = true; // enabled passcode next button
      }
    },
    handleBackspace(index) {
      if (this.otpValues[index] !== "") {
        this.otpValues[index] = "";
      } else if (index > 0) {
        this.$refs.otpFields[index - 1].focus();
      }
      this.passcodeverified = false; // disabled passcode next button
    },
    async navigate() {
      this.$store.commit("updateCalledFrom", "CreatePwd");
      this.$router.push({ name: "login" });
    },
    openCalendar() {
      this.$refs.birthdate.showCalendar();
    },
    updateBirthdate(eventval) {
      var inval = eventval;
      if (inval == "" || inval == null) {
        this.birthdate = "";
        this.birthdateerror = "";
        return;
      }
      try {
        if (!String(inval).includes("/")) {
          inval = moment(inval, "MM/DD/YYYY").format("MM/DD/YYYY");
        }

        var dateArray = inval.split("/");
        if (dateArray != null && dateArray.length == 3) {
          if (dateArray[0].length == 1) {
            dateArray[0] = "0" + dateArray[0];
          }
          if (dateArray[1].length == 1) {
            dateArray[1] = "0" + dateArray[1];
          }
          if (
            dateArray[0].length == 2 &&
            dateArray[1].length == 2 &&
            dateArray[2].length == 4
          ) {
            this.birthdate = dateArray.toString().replaceAll(",", "/");
          } else {
            this.birthdate = "";
          }
        } else {
          this.birthdate = "";
          this.birthdateerror = "";
          return;
        }
      } catch {
        //console.log(error);
      }
    },
    validateBirthDate() {
      if (
        this.birthdate == null ||
        this.birthdate == undefined ||
        this.birthdate == "" ||
        this.birthdate.length < 10
      ) {
       // this.$validator.validate("birthdate");
        return true;
      }
      try {
        var val = this.birthdate;
        var dateArray = val.split("/");
        if (dateArray == null || dateArray.length != 3) {
          this.birthdate = "";
         // this.$validator.validate("birthdate");
          return true;
        }

        var inp_date = moment(this.birthdate, "MM/DD/YYYY").format(
          "MM/DD/YYYY"
        );

        if (inp_date == "Invalid date") {
          this.birthdate = "";
          this.birthdateerror = "";
         // this.$validator.validate("birthdate");
          return true;
        }
        var cur_date = moment(new Date(), "MM/DD/YYYY").format("MM/DD/YYYY");
        var min_eligible_date = moment(cur_date, "MM/DD/YYYY").add(
          -18,
          "years"
        );
        if (
          moment(inp_date, "MM/DD/YYYY").isAfter(min_eligible_date) ||
          !moment(inp_date, "MM/DD/YYYY").isValid()
        ) {
          this.birthdateerror = "Please enter your date of birth";
          return true;
        }
      } catch {
        //console.log(error);
      }
      this.birthdateerror = "";
      return false;
    },
    allowonlynumbersandbackslash(event) {
      var keyCode = event.keyCode;
      var isShift = false;
      if (keyCode == 16) {
        isShift = true;
      }
      //Allow only Numeric Keys.
      if (
        ((keyCode >= 47 && keyCode <= 57) ||
          keyCode == 8 ||
          keyCode <= 37 ||
          keyCode <= 39) &&
        isShift == false
      ) {
        if (keyCode == 47) {
          if (
            event.target.value != null &&
            event.target.value.length >= 1 &&
            event.target.value.length <= 5 &&
            keyCode != 8
          ) {
            return true;
          } else {
            event.preventDefault();
          }
        } else {
          var inval = event.target.value;
          if (
            inval != null &&
            (inval.length == 2 || inval.length == 5) &&
            keyCode != 8 &&
            keyCode != 47
          ) {
            let length = inval.length - 1;
            let count = inval.split("/").length;
            if (inval[length] != "/" && count <= 2)
              event.target.value = inval + "/";
          }
        }
      } else {
        event.preventDefault();
      }
    },
    frmPassword_Criteria(event) {
      if (event.keyCode != 32) {
        var str = event.target.value;
        var cap = /(?=.*[A-Z])/;
        var lo = /(?=.*[a-z])/;
        var dig = /(?=.*\d)/;
        var sc = /(?=.*\W)/;
        var sp = /\s/;
        var leng = /^[\s\S]{8,20}$/;
        var ve1 = cap.test(str);
        var ve2 = lo.test(str);
        var ve3 = dig.test(str);
        var ve4 = sc.test(str);
        var ve5 = sp.test(str);
        var ve6 = leng.test(str);
        if (ve1 == true) {
          document
            .getElementById("revPwd_Reg1")
            .classList.replace("go-normal", "go-green");
        } else {
          document
            .getElementById("revPwd_Reg1")
            .classList.replace("go-green", "go-normal");
        }
        if (ve2 == true) {
          document
            .getElementById("revPwd_Reg2")
            .classList.replace("go-normal", "go-green");
        } else {
          document
            .getElementById("revPwd_Reg2")
            .classList.replace("go-green", "go-normal");
        }
        if (ve3 == true) {
          document
            .getElementById("revPwd_Reg3")
            .classList.replace("go-normal", "go-green");
        } else {
          document
            .getElementById("revPwd_Reg3")
            .classList.replace("go-green", "go-normal");
        }
        if (ve4 == true) {
          document
            .getElementById("revPwd_Reg4")
            .classList.replace("go-normal", "go-green");
        } else {
          document
            .getElementById("revPwd_Reg4")
            .classList.replace("go-green", "go-normal");
        }
        if (ve5 == true) {
          document
            .getElementById("revPwd_Reg5")
            .classList.replace("go-green", "go-normal");
        } else {
          document
            .getElementById("revPwd_Reg5")
            .classList.replace("go-normal", "go-green");
        }
        if (ve6 == true) {
          document
            .getElementById("revPwd_Reg6")
            .classList.replace("go-normal", "go-green");
        } else {
          document
            .getElementById("revPwd_Reg6")
            .classList.replace("go-green", "go-normal");
        }
      } else {
        document
          .getElementById("revPwd_Reg5")
          .classList.replace("go-green", "go-normal");
        event.preventDefault();
      }
    },
    validatePassword: function (password) {
      if (password == undefined || password == null) {
        return true;
      } else {
        // var re = /^(?=.*[A-Z])(?=.*[\W])(?=.*[0-9])(?=.*[a-z]).{8,20}$/;
        var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,20}/;
        var res = re.test(password);
        res = res ? !/\s/g.test(password) : res;
        if (!res) {
          document
            .getElementById("password")
            .parentElement.classList.add("field-error");
          if (document.getElementById("passworderror") != undefined)
            document.getElementById("passworderror").parentElement.id =
              "passwordError";
          if (document.getElementById("passwordError") != undefined)
            document.getElementById("passwordError").innerHTML =
              "Please enter a password that meets the criteria. func";
        } else {
          if (
            document
              .getElementById("password")
              .parentElement.classList.contains("field-error")
          ) {
            document
              .getElementById("password")
              .parentElement.classList.remove("field-error");
            if (document.getElementById("passwordError") != undefined)
              document.getElementById("passwordError").innerHTML = "";
          }
        }
        return res;
      }
    },

    ...mapMutations({
      updateInvalidEmail: "updateInvalidEmail",
      updateEmptyEmail: "updateEmptyEmail",
      updatevalidateUser: "updatevalidateUser",
      updateInvalidPassCode: "updateInvalidPassCode",
      updatePasscodeInvalidCodeAttempt: "updatePasscodeInvalidCodeAttempt",
      updatePasscodeAttemptFailed: "updatePasscodeAttemptFailed",
      updatePasscodeInvalidCodeSentAttempt:
        "updatePasscodeInvalidCodeSentAttempt",
    }),

    //#region  Prove
    activate() {
      setTimeout(() => {
        this.step4 = false;
        this.passwordCreated = true;
        setTimeout(() => {
          document.getElementById("birthdate").focus();
        }, 300);
      }, 3000);
    },
    async emailverification() {
      //this.$validator.validate();
      this.$store.commit("clear_identitymsg");
     // if (!this.errors.first("email")) 
      {
        if (this.$store.state.login.token == undefined) {
          const tokenResult = await this.$store.dispatch("createToken");
          this.$store.state.login.token = tokenResult.token.writtenToken;
        }
        this.$store.state.login.email = this.email;
        const res = await this.$store.dispatch("validateEmail");
        if (res != undefined) {
          this.$store.state.login.token = res.tokenToUse;
          this.$store.state.application.tokenID = res.tokenToUse;
          if (res.validEmail) {
            this.updateInvalidEmail(false);
            var hasRegisteredUsers = false,
              i;
            for (i = 0; i < res.users.length; i++) {
              if (res.users[i].userId > 0) {
                hasRegisteredUsers = true;
                break;
              }
            }

            if (!hasRegisteredUsers) {
              this.$store.commit("updateCustID", res.users[0].customerId);
              this.$store.commit("updatefirstName", res.users[0].firstName);

              if (
                (!res.twoFactorAuthenticationEnabled &&
                  res.twoFactorAuthenticationEnabled != undefined) ||
                res.twoFactorAuthenticationEnabled == null
              ) {
                this.step1 = false;
                this.step2 = false;
                this.step3 = false;
                this.activate();
                return;
              }
              //Add Customer ID and Token to Prove API
              this.$store.commit("updateCustomerId", res.users[0].customerId);
              this.$store.commit("updateProveToken", res.tokenToUse);

              var mobilenumber = res.users[0].custMobileNumber;
              var homenumber = res.users[0].custHomePhoneNumber;
              // var mobilenumber = "4259997473";
              // var homenumber = "";
              if (mobilenumber != "" && mobilenumber != null) {
                //Add Cell phone number to list
                this.verificationoptionlist.push({
                  CallType: "Call",
                  Numbertext: formatcellno2FA(mobilenumber),
                  numberval: mobilenumber,
                  id: "1",
                });
              }
              //Add Home/Main phone number to list
              if (homenumber != mobilenumber && homenumber != "") {
                this.verificationoptionlist.push({
                  CallType: "",
                  Numbertext: formatcellno2FA(homenumber),
                  numberval: homenumber,
                  id: "2",
                });
              }

              //Loop through all numbers added and check if number provided is a mobile number
              for (
                let index = 0;
                index < this.verificationoptionlist.length;
                index++
              ) {
                const element = this.verificationoptionlist[index];

                let trustres = await this.$store.dispatch(
                  "checkenterednumberifcellnumber",
                  {
                    phonenumber: element.numberval,
                  }
                );
                if (trustres == null) {
                  this.verificationoptionlist[index].CallType ="Call";
                } else {
                  this.verificationoptionlist[index].CallType =
                    trustres.lineType == "Mobile" ? "Text" : "Call";
                }
              }

              this.showsendcodeoptions = true;

              //this.formatemail2FA = formatemail2FA(this.email);
              this.step2 = true;
              this.step1 = false;
              setTimeout(() => {
                document.getElementById("CPidentityverify").focus();
              }, 200);
            } else {
              this.$store.commit("updateCalledFrom", "Home");
              this.$router.push({ name: "login" });
            }
          } else {
            if (this.email == null || this.email == "") {
              this.updateEmptyEmail(true); // Handle not associated email
            } else {
              this.updatevalidateUser(true);
              this.step6 = true;
              this.step1 = false;
            }
          }
        }
      }
    },
    async sendverificationcode() {
      if (
        this.choosepasscodeMethod == null ||
        this.choosepasscodeMethod == ""
      ) {
        this.showOptionError = true;
        return false;
      }
      this.$store.commit("updateverificationcodesenttime", GetCurrentDate());
      this.$store.commit("updatePasscodeSession", generatePasscode());
      this.extravalue = localStorage.PasscodeSession;
      var SMSText = pre.SMSText.replace("{PASSCODE}", this.extravalue);
      this.$store.commit("updateSendMessagesUtilitySMSText", SMSText);
      this.$store.commit("updateMessageFor", "2FA");

      if (this.choosepasscodeMethod.CallType.toUpperCase() == "TEXT") {
        // send sms to mobile
        //console.log("Text number- " + this.choosepasscodeMethod.numberval);
        await this.$store.dispatch("SendSMSOTP", {
          mobileNumber: this.choosepasscodeMethod.numberval,
        });
        //console.log(txtres, "SMS send Successfuly");
      } else if (this.choosepasscodeMethod.CallType.toUpperCase() == "CALL") {
        // Call to Mobile/Landline
        //console.log("Call number- " + this.choosepasscodeMethod.numberval);
        await this.$store.dispatch("PinDelivery", {
          mobileNumber: this.choosepasscodeMethod.numberval,
          passCode: this.extravalue,
        });

        //console.log(callres, "Successfully Called");
      } else if (this.choosepasscodeMethod.toUpperCase() == "EMAIL") {
        // send code to email

        // this.$store.commit(
        //   "updateSendMessagesUtilityEmailFirstName",
        //   this.firstName
        // );
        // this.$store.commit("updateSendMessagesUtilityEmail", this.email); // 'rama.vanum@slchq.com'
        // this.$store.commit(
        //   "updateSendMessagesUtilityToken",
        //   this.$store.state.login.token
        // );
        // this.$store.commit("updateSendMessagesUtilityCustId", this.custId);
        // this.$store.commit(
        //   "updateSendMessagesUtilityPasscode",
        //   this.extravalue
        // );
        // this.$store.commit(
        //   "updateSendMessagesUtilityEmailSubject",
        //   pre.EmailSubjectLine
        // );
        // this.$store.commit(
        //   "updateSendMessagesUtilityPreviewText",
        //   pre.PreviewText
        // );

        // this.imgemail = true;
        // await this.$store.dispatch("utilitiesSendEmail"); // sending email
      }
      this.step3 = true;
      this.step2 = false;
      localStorage.resendAttempt = 0;
      localStorage.createpwdAttempt = 0;
      setTimeout(() => {
        document.getElementById("CPstep3").focus();
      }, 200);
    },

    async reSendVerificatonCode() {
      this.otpValues = ["", "", "", "", "", ""];
      var resendAttempt = parseInt(localStorage.resendAttempt) + 1;
      localStorage.resendAttempt = resendAttempt;
      if (resendAttempt < this.wrongPwdAttempt) {
        this.$store.commit("updateverificationcodesenttime", GetCurrentDate());
        this.$store.commit("updatePasscodeSession", generatePasscode());
        this.extravalue = localStorage.PasscodeSession;
        this.updatePasscodeInvalidCodeAttempt(false);
        this.updatePasscodeInvalidCodeSentAttempt(false);
        this.isResendCode = true;

        var SMSText = pre.SMSText.replace("{PASSCODE}", this.extravalue);
        this.$store.commit("updateSendMessagesUtilitySMSText", SMSText);
        this.$store.commit("updateMessageFor", "2FA");
        if (this.choosepasscodeMethod.CallType.toUpperCase() == "TEXT") {
          // send sms to mobile
          //console.log(this.choosepasscodeMethod.numberval);
          await this.$store.dispatch("SendSMSOTP", {
            mobileNumber: this.choosepasscodeMethod.numberval,
          });
          //console.log("SMS send Successfuly");
        } else if (this.choosepasscodeMethod.CallType.toUpperCase() == "CALL") {
          // Call to mobile
          await this.$store.dispatch("PinDelivery", {
            mobileNumber: this.choosepasscodeMethod.numberval,
            passCode: localStorage.esignstatePasscodeSession,
          });
          //console.log(callres, "- Successfully Called");
        } else if (this.choosepasscodeMethod.toUpperCase() == "EMAIL") {
          // send code to email
          // this.$store.commit(
          //   "updateSendMessagesUtilityEmailFirstName",
          //   this.firstName
          // );
          // this.$store.commit("updateSendMessagesUtilityEmail", this.email); // 'rama.vanum@slchq.com'
          // this.$store.commit(
          //   "updateSendMessagesUtilityToken",
          //   this.$store.state.login.token
          // );
          // this.$store.commit("updateSendMessagesUtilityCustId", this.custId);
          // this.$store.commit(
          //   "updateSendMessagesUtilityPasscode",
          //   this.extravalue
          // );
          // this.$store.commit(
          //   "updateSendMessagesUtilityEmailSubject",
          //   pre.EmailSubjectLine
          // );
          // this.$store.commit(
          //   "updateSendMessagesUtilityPreviewText",
          //   pre.PreviewText
          // );

          // this.imgemail = true;
          // await this.$store.dispatch("utilitiesSendEmail"); // sending email
        }
      } else {
        this.isResendCode = false;
        this.updatePasscodeInvalidCodeSentAttempt(true);
      }
    },

    async verifyPassCode() {
      const enteredpasscode = Number(this.otpValues.join(""));
      this.isResendCode = false;

      //Application moved to withdrawn status
      if (this.appwithdrawn) {
        this.resendmaxlimit = true;
        localStorage.resendAttempt = this.wrongPwdAttempt;
        localStorage.createpwdAttempt=this.wrongPwdAttempt;
        this.updatePasscodeAttemptFailed(true);
        return; //"MAX_LIMIT"
      }
      this.$store.commit(
        "updateTimeElapsed",
        TimeElapsed(localStorage.verificationcodesenttime)
      );
      if (
        localStorage.createpwdAttempt > this.wrongPwdAttempt ||
        localStorage.TimeElapsed > localStorage.passcodetimeout
      ) {
        localStorage.PasscodeSession = "-11111111";
      }
      if (this.choosepasscodeMethod.CallType.toUpperCase() == "TEXT") {
        const txtres = await this.$store.dispatch("VerifySMSOTP", {
          passCode: enteredpasscode, sentNumber: this.choosepasscodeMethod.numberval,
        });
        await this.passcodeattemptcountcheck(txtres == "0");
        //console.log(txtres, "SMS send Successfuly");
      } else if (this.choosepasscodeMethod.CallType.toUpperCase() == "CALL") {
        const pinValidCheck = await this.passcodeattemptcountcheck(
          enteredpasscode == localStorage.PasscodeSession
        );
         await this.$store.dispatch("phoneCallVerify", {
          sentNumber: this.choosepasscodeMethod.numberval, pinCheckResponse: pinValidCheck,
        });
      } else if (this.choosepasscodeMethod.CallType.toUpperCase() == "EMAIL") {
        if (enteredpasscode == localStorage.PasscodeSession) {
          this.updatePasscodeInvalidCodeAttempt(false);
          this.updatePasscodeInvalidCodeSentAttempt(false);
          this.step4 = true;
          this.step3 = false;
          setTimeout(() => {
            document.getElementById("verificationsuccessmsg").focus();
          }, 200);
          this.activate();
        } else {
          var pwdAttempt = parseInt(localStorage.createpwdAttempt) + 1;
          localStorage.createpwdAttempt = pwdAttempt;
          if (pwdAttempt >= 5) {
            this.updatePasscodeInvalidCodeSentAttempt(true);
          } else {
            this.updatePasscodeInvalidCodeAttempt(true);
          }
        }
      }
    },

    async passcodeattemptcountcheck(passcodeverifiedresult) {
      let noteattempt = "Failed attempt",
        passcodeverificationstatus = "false";
      if (passcodeverifiedresult) {
        passcodeverificationstatus = "true";
        noteattempt = "Success attempt";
      } else {
        var pwdAttempts = parseInt(localStorage.createpwdAttempt) + 1;
        localStorage.createpwdAttempt = pwdAttempts;
      }

      this.$store.commit("updateProcessId", 1);
      this.$store.commit("updateProcessStatus", passcodeverificationstatus);
      this.$store.commit("updateAttemptNote", noteattempt);

      let result = await this.$store.dispatch(
        "getinvalidpasswordcreationattemptcount"
      );
      let retValue = "";
      if (
        result.attemptCount >= this.wrongPwdAttempt && passcodeverificationstatus == "false"
      ) {
        retValue = "MAX_LIMIT";

        this.$store.state.application.tokenID = result.tokenToUse;
        await this.$store
          .dispatch(
            "updateApplicationToWithdraw",
            "Failed ID verification - Credit application could not be verified."
          )
          .then((res) => {
            this.resendmaxlimit = true;
            this.updatePasscodeAttemptFailed(true);
            localStorage.resendAttempt = this.wrongPwdAttempt;
            localStorage.createpwdAttempt=this.wrongPwdAttempt;
            this.$store.state.login.tokenID = res.tokenToUse;
            this.appwithdrawn = true;
          })
          .catch(() => {
            //console.log("withdraw error-" + err);
            this.resendmaxlimit = true;
            this.updatePasscodeInvalidCodeSentAttempt(true);
          });
      } else if (passcodeverificationstatus == "true") {
        retValue = "PASS";
        this.validPin();
      } else {
        this.invalidPin();
        retValue = "FAIL";
      }

      return retValue;
    },

    validPin() {
      this.updatePasscodeInvalidCodeAttempt(false);
      this.updatePasscodeInvalidCodeSentAttempt(false);
      this.step4 = true;
      this.step3 = false;
      this.activate();
    },
    invalidPin() {
      if (localStorage.createpwdAttempt > this.wrongPwdAttempt) {
        this.updatePasscodeAttemptFailed(true);
      } else {
        this.updatePasscodeInvalidCodeAttempt(true);
      }
    },

    Email2faSelectedKeyDown(event) {
      this.choosepasscodeMethod = "email";
      document.getElementById("span2faEmail").focus();
      event.preventDefault();
    },
    Text2faSelectedKeyDown(event, value) {
      var elementid = event.target.id;
      this.choosepasscodeMethod = value;
      document.getElementById(
        "rdo2faText" + this.choosepasscodeMethod.id
      ).checked = true;

      if (elementid.toString().includes("span")) {
        event.preventDefault();
      }

      //uncheck and check radiobutton
      var elements = document.getElementsByName("radiofa");
      for (var i = 0; i < elements.length; i++) {
        if (elements[i].id == "span2faText" + this.choosepasscodeMethod.id) {
          elements[i].setAttribute("aria-checked", true);
          elements[i].focus();
        } else elements[i].removeAttribute("aria-checked");
      }
    },
    //#endregion Prove

    async PassCodeSuccess() {
      this.step5 = true;
      this.step4 = false;
      setTimeout(() => {
        document.getElementById("birthdate").focus();
      }, 300);
    },
    createPasswordInvalid()
    {
    },
    async createPassword() {
      // this.$validator.validate();
      this.$store.commit("clear_identitymsg");
     // if (this.isFormValid) 
      {
        this.createPasswordClicked = true;
        var newBirthDate = new Date(this.birthdate);
        if (
          newBirthDate <= this.birthdateStartDate &&
          newBirthDate > this.birthPreviousDate
        ) {
          //console.log(newBirthDate);
          await this.$store.dispatch("createToken");
          this.$store.commit("updateSSN", this.lastFourSocial);

          const res = await this.$store.dispatch(
            "validateIdentity",
            this.$store.state.login.token
          );
          if (res[1].validIdentity) {
            const result = await this.$store.dispatch(
              "createPassword",
              this.lastFourSocial + this.password
            );
            if (result.registered) {
              this.$store.commit("clear_identitymsg");
              this.$store.commit("updatePasswordCreationError", false);
              //this.passwordCreated = true;
              this.successfullyCreated = true;
              this.passwordCreated = false;
            } else {
              this.$store.commit("updatePasswordCreationError", true);
            }
            this.createPasswordClicked = false;
          } else {
            this.createPasswordClicked = false;
            if (localStorage.createpwdemail == this.email) {
              var pwdAttempt = parseInt(localStorage.createpwdAttempt) + 1;
              localStorage.createpwdAttempt = pwdAttempt;
              if (pwdAttempt >= this.wrongPwdAttempt) {
                this.$store.commit(
                  "updateAccountLockedPasswordCreationError",
                  true
                );
              } else {
                this.$store.commit(
                  "updateAccountLockedPasswordCreationError",
                  false
                );
                this.$store.commit("updateEmailNotFound", true);
                this.createPasswordClicked = false;
              }
            } else {
              localStorage.createpwdemail = this.email;
              localStorage.createpwdAttempt = 1;
              this.$store.commit("updateEmailNotFound", true);
              this.createPasswordClicked = false;
            }
            this.createPasswordClicked = false;
          }
        }
      }
    },

    limitNums,
  },
};

// Validator.localize({
//   en: {
//     custom: {
//       birthdate: {
//         required: "Please enter your date of birth",
//         min: "Please enter your date of birth",
//         length: "Please enter your date of birth",
//         date_format: "",
//       },
//     },
//   },
// });
</script>

<style scoped lang="scss">
#content {
  margin-bottom: 0px;
  @media (max-width: 922px) {
    margin: 70px 0 0 0;
  }
}
.button-primary {
  &:disabled {
    background: $orange;
    color: $white;
    cursor: not-allowed;
  }
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input {
  border: solid 1px $border-light;
  padding: 3px 5px 3px 10px;
}
.go-green {
  color: $green;
  font-weight: bold;
  font-size: 12px;
}
.go-normal {
  color: $gray;
  font-weight: normal;
  font-size: 12px;
}
main {
  .field .input-wrapper input {
    width: 100%;
    margin: 3px;
    height: 30px;
  }
  .login-box {
    align-items: flex-start;

    @media (max-width: 767px) {
      margin: 5% 18% !important;
      padding: 20px 20px 20px 20px !important;
    }
    @media (min-width: 767px) and (max-width: 1200px) {
      margin: 5% !important;
    }
    @media (max-width: 767px) {
      min-width: 340px;
    }
    @media (max-width: 400px) {
      min-width: 250px;
      margin: 40px 18px !important;
      padding: 25px 40px !important;
    }

    .rightcontentsection {
      .field {
        width: 100%;
      }
      .contentheader,
      .contentheader2 {
        font-weight: 600;
        text-transform: none;
        font-size: 16px;
        letter-spacing: 0.2px;
        margin: 0px;
        padding: 0px 0px 2px 0px;
      }

      .contentheader2 {
        padding-bottom: 10px;
      }
    }
  }
  .maincontentsection {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
  .maincontentsectiondiv {
    width: 100%;
    .error {
      font-size: 12px;
    }
    fieldset {
      margin: 0px;
      padding: 0px;
      border: none;
    }
  }
  .leftcontentsection {
    width: 45%;
    float: left;
    padding: 5px;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .rightcontentsection {
    width: 49%;
    float: right;
    padding: 5px 5px 5px 10px;
    @media (max-width: 767px) {
      width: 100%;
      padding: 5px;
      float: left;
    }
  }
  .maincontentlast {
    @media (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }
  .contentsection {
    display: inline;
    position: relative;
  }
  .contentsection-password {
    @media (max-width: 767px) {
      float: left;
      width: 60%;
    }
  }
  .contentsection-instructions {
    @media (max-width: 767px) {
      float: right;
      width: 35%;
    }
  }
  .para1 {
    padding-right: 16px;
    margin-top: 10px;
    letter-spacing: 0.1px;
    font-size: 0.8rem;
  }
  .para2 {
    padding-right: 5px;
    margin-top: 10px;
    letter-spacing: 0.1px;
    font-size: 0.75rem;
  }
  .ml {
    margin-left: 40px;
    @media (max-width: 767px) {
      margin-left: 0px;
    }
  }
  .leftbottomcontent {
    padding-right: 30px;
    position: relative;
    bottom: 0px;
    left: 0;
    top: 10px;
    font-size: 11px;
    font-weight: 600;
    opacity: 0.6;

    @media (max-width: 767px) {
      left: 7px;
      text-align: center;
    }

    @media (max-width: 450px) {
      left: 7px;
      font-size: 14px;
      padding-right: 20px;
    }

    @media (min-width: 400px) and (max-width: 420px) {
      padding-right: 2px;
    }

    @media (max-width: 400px) {
      font-size: 13px;
      left: 0px;
      padding-right: 0px;
    }

    sup {
      font-size: 6px;
    }
  }
  .congratssection {
    padding: 100px 200px 240px 210px;
    text-align: center;
  }
  .successheader {
    color: $orange;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 25px;
    margin-bottom: 10px;
  }
  .leftcontentheader {
    font-size: 20px;
    color: $orange;
    padding: 0px;
    margin-bottom: 15px;
  }
  .button {
    width: 100%;
    font-size: 16pt;
    padding: 10px 10px;
    font-weight: 700;
  }
  .login-box {
    height: 100%;
    min-height: 530px;
    justify-content: flex-start;
    @media (max-width: 767px) {
      min-height: 700px;
    }
  }
  .login-box,
  .loginbox-success {
    max-width: 720px;
    margin: 5% 18%;
    padding: 30px 50px 50px 50px;
  }
  .datepicker-icon {
    position: relative;
    margin-left: 2px !important;
    margin: 7px 10px;
  }
  .txtsubfont {
    font-size: 12px;
  }

  #CPidentityverify:focus,
  #CPstep3:focus,
  #btnCPemailverification:focus {
    outline: 2px solid #1e4388;
  }

  .section-title {
    align-self: flex-start;
    font-size: 30px;
    .subtitle > span {
      font-family: $font-primary;
      font-weight: 600;
      text-transform: none;
      //font-size: 22px;
      letter-spacing: 0.2px;
    }
  }

  .instructions {
    font-size: 11px;
    min-width: 314px;
    margin-bottom: 20px;
    margin-left: 5px;

    @media (max-width: 767px) {
      min-width: auto;
    }
    span {
      font-size: 12px;
      font-weight: 500;
    }

    h4 {
      text-transform: none;
      margin-top: 20px;
      font-size: 14px;
    }

    p {
      margin-right: 25px;
      margin-top: 0px;
      letter-spacing: 0.1px;
      font-size: 15px;

      @media (max-width: 767px) {
        margin-right: 15px;
        font-size: 14px;
        opacity: 0.9;
      }

      @media (max-width: 400px) {
        margin-right: 8px;
        font-size: 10pt;
      }
    }
  }
  .password-show-icon {
    position: absolute;
    margin: 10px 280px;
    color: $gray;
    text-align: center;

    @media (max-width: 400px) {
      margin: 10px 240px;
      right: -27%;
      @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
          margin: 10px 280px;
        }
      }
    }
    @media (min-width: 320px) and (max-width: 380px) {
      left: -30%;
    }
    @media (min-width: 400px) and (max-width: 450px) {
      left: -32%;
    }
    @media (min-width: 450px) and (max-width: 500px) {
      left: -20%;
    }
    @media (min-width: 500px) and (max-width: 550px) {
      left: -10%;
    }
    @media (min-width: 550px) and (max-width: 650px) {
      left: -7%;
    }
    i {
      height: 14px;
      width: 25px;
    }
  }
  .bottomsection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 30px;
    height: 34px;
  }
  .bottom {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 30px;
    height: 34px;

    .next {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      margin-left: auto;

      img {
        margin-left: 10px;
      }
    }
  }

  .congrats {
    margin: 0 0 40px;
  }
}

@media (max-width: 1200px) {
  .loginbox-success {
    margin: 5% 5% !important;
    max-width: 100% !important;
    padding: 20px !important;
  }

  .congratssection {
    padding: 40% 0px 90% 0px !important;
    width: 100%;
  }
}
//media queries start from here
@media (max-width: 767px) {
  .password {
    //flex: 0 0 85% !important;
    width: 100%;
  }

  .hidden {
    display: none;
  }
  .section-title .subtitle {
    text-align: left !important;
  }

  main .section-title .subtitle > span {
    font-size: 15pt;
  }
  main .contentsection .field {
    width: 100%;
  }
  main .rightcontentsection .field {
    width: 100%;
  }

  .rightcontentsection .field .input-wrapper input {
    height: 30px;
    width: 100%;
    margin-left:0px;
  }

  main .para1,
  main .para2,
  main .instructions h4 {
    padding-right: 0px;
    font-size: 14pt;
  }
  .input-reject::after {
    top: -3px;
  }
  .successheader {
    font-size: 20pt;
  }
  .congrats {
    font-size: 12pt;
  }
}

@media (min-width: 400px) and (max-width: 450px) {
  main .para1,
  main .para2,
  main .instructions h4 {
    padding-right: 0px;
    font-size: 12pt;
    max-width: 300px;
  }

  .loginbox-success {
    padding: 20px !important;
    margin: 5% 5% !important;
  }
}
@media (max-width: 400px) {
  main .para1,
  main .para2,
  main .instructions h4 {
    padding-right: 0px;
    font-size: 14px;
  }
  .loginbox-success {
    padding: 20px 10px 20px 10px !important;
    margin: 5% 4% !important;
  }

  .input-accept::after {
    left: 5px;
  }
}
.txtplaceholder {
  font-size: 12px;
  padding: 1px 10px;
  color: #1e4488;
}
.lblstyle {
  display: contents;
}
.loginlink {
  width: 100%;
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
  .login-link {
    color: $blue;
    border-bottom: 1px solid $blue;
    font-weight: 600;
  }
}
.cardtxt {
  font-size: 12px;
}
.txtemail {
  font-size: 14px;
  font-weight: 700;
  //padding-left: 5px;
  margin-bottom: 10px;
  margin-top: 0px;
}
.mt {
  margin-top: 20px;
}
.rowcontainer {
  .txtemail {
    padding-left: 5px;
  }
}
.txtspan {
  margin-left: 10px;
  font-weight: 500;
}
.contentsub {
  padding: 0px;
  margin: 0px 0px 10px;
  font-size: 13px;
}

#CPidentityverify .contentsub {
  max-width: 320px;
}
.txtblue {
  font-size: 14px;
  color: $royal-blue; // alya was provided
  margin: -7px 0px 15px 0px;
  padding: 0px;
}
.resenderror {
  font-size: 11px;
  margin-top: 0px;
  color: $disabled-gray;
  text-align: left;

@media (max-width: 767px) {
  font-size: 13px;  
}

  a {
    border-bottom: 1px solid $disabled-gray;
    color: $disabled-gray;
    text-decoration: none;
  }
}
#birthdate::placeholder {
  color: $disabled-gray;
  opacity: 1;
}
.choosediv {
  padding-left: 0px;

  .radio-input {
    .radio-checkmark {
      height: 14px;
      width: 14px;
    }
  }
}
.txtselection {
  color: $orange;
  font-size: 14px;
  font-weight: 700;
  margin-left: 22px;
}
.space {
  width: 17%;
}
.rowpadding {
  margin: 5px 0px 10px;
}
.passcodeimg {
  width: 25%;
  float: left;
  img {
    margin: 10px 0px;
    padding: 0px 5px;
    margin-bottom: 20px;
    width: 70%;
  }
}
.passcodetxt {
  width: 70%;
  float: left;
  margin: 5px 0px;

  .error {
    font-size: 11px;
    text-align: center;
  }
}
.passcodesec {
  width: 100%;
}
.passcodefield {
  border: none;
  background-color: transparent;
  border-bottom: 2px solid $disabled-gray;
  border-radius: 0px;
  width: 100%;
  font-size: 25px;
}
.button-verify {
  color: $white;
  background: $orange;
  font-size: 14px;
  font-weight: 800;
  padding: 6px 50px;
}
.button-verify:disabled {
  background: $gray;
  color: $white;
}

.btnsection {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  height: 34px;

  .button-verify {
    padding: 6px 50px;
  }
}
.button-resend {
  font-size: 14px;
  font-weight: 800;
  background-color: transparent;
  border-bottom: 2px solid $gray;
  border-radius: 0px;
  color: $gray;
  padding: 1px;
}
.button-resend:disabled {
  color: $dark-gray;
  background-color: transparent;
  border-bottom-color: $dark-gray;
}
.passcodesuccess {
  width: 100%;

  img {
    width: 15%;
    float: left;
    margin: 10px 0px;
  }
  .txt {
    width: 78%;
    float: left;
    padding: 0px 10px;

    .txtsuccess {
      color: $orange;
      font-weight: 700;
      letter-spacing: 1px;
    }
  }
}

.rowcontainer {
  .rows {
    width: 100%;

    .left {
      width: 60%;
      float: left;
    }
    .right {
      width: 35%;
      float: right;
    }
    .field {
      width: 100%;
    }
  }
}
@media (max-width: 767px) {
  .rowcontainer {
    .rows {
      .left {
        width: 110% !important;
      }
      .right {
        width: 100% !important;
      }
      .field {
        width: 99% !important;
      }
    }
  }
}

//passcode field start
.otp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .input-wrapper {
    input {
      margin: 0px 5px;
    }
  }
}

.otp-input {
  width: 0px;
  height: 40px;
  text-align: center;
  font-size: 30px;
  margin: 0 10px;
  border: none;
  border-bottom: 2px solid $border-light;
  box-shadow: none;
  background-color: transparent;
  color: $gray;
  transition: all 0.3s ease-in-out;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 0px;
  @media (min-width: 490px) and (max-width: 767px) {
    font-size: 20px;
  }
  @media (min-width: 970px) and (max-width: 1200px) {
    font-size: 25px;
  }
}

.otp-input:focus {
  outline: none;
  border-bottom-color: $blue;
}
//passcode field stop
img.emailicon {
  width: 80%;
  @media (min-width: 970px) and (max-width: 1200px) {
    width: 85%;
  }
}
.input-wrapper {
  input {
    width: 100% !important;
  }
}
.m-top {
  margin-top: 20px;
}
.show-icon {
  position: absolute;
  right: 10%;
  margin-top: 13px;
  @media (max-width: 767px) {
    right: 16%;
    margin-top: 12px;
  }
  @media (min-width: 767px) and (max-width: 1200px) {
    right: 8%;
  }
}
.rowcontainer {
  .m-size {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .rowcontainer {
    .m-size {
      width: 90%;
    }
    .rows {
      .left {
        width: 64%;
      }
      .right {
        width: 36%;
      }
    }
  }
  .btnsection {
    width: 100%;
    //margin: 0px auto;
    padding-top: 0px;
    margin-top: 20px;
  }
}
.input-reject::after {
  //top: -3px;
  top: 4px;
  font-size: 20px;
  font-weight: 900;
}
.input-accept::after {
  top: 0px;
  font-size: 25px;
  font-weight: 900;
}
.txtalign {
  text-align: center;
}

.txtalign .button-login {
  padding: 1px;
  line-height: 10px;
}

.matchicon {
  position: absolute;
  margin-top: 20px;
  font-size: 10px;
  font-weight: bold;
  color: #32cc66;
  padding-top: 2px;
}
.inputerror {
  margin: 10px 0px;
}

.txticon {
  width: 60% !important;
}
.field {
  .field-error {
    .error {
      height: auto !important;
    }
  }
}
.error {
  height: auto !important;
}
.btnbottom {
  margin: 45px 0px 30px;
}
.invalidsecion {
  text-align: center;
  margin-top: 10%;
  padding-right: 16px;
  letter-spacing: 0.1px;
  font-size: 15px;
}

@media (min-width: 767px) and (max-width: 970px) {
  .field .input-wrapper input {
    padding: 0px;
  }
  .otp-input {
    font-size: 18px;
  }
  .txticon {
    width: 75% !important;
  }
  .passcodeimg img {
    margin-top: 20px;
  }
  .btnsection {
    .button-verify {
      padding: 6px 20px;
    }
  }
  .button-resend {
    font-size: 12px;
  }
}
@media (min-width: 480px) and (max-width: 550px) {
  img.emailicon {
    width: 65%;
  }
}
@media (min-width: 551px) and (max-width: 766px) {
  .passcodeimg {
    width: 15%;
  }
  .passcodetxt {
    width: 55%;
  }
}
@media (min-width: 767px) and (max-width: 810px) {
  .rowcontainer {
    .rows {
      .right {
        width: 40%;
      }
    }
  }
}
@media (min-width: 767px) and (max-width: 1240px) {
  .rowcontainer {
    .rows {
      .left {
        width: 62%;
      }
      .right {
        width: 38%;
      }
    }
  }
}

.otpcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.otpimage {
  flex-basis: 40%;
}
.otpimage img {
  //max-width: 100%;
  height: 60px;
  margin-top: 10px;
  @media (max-width: 767px) {
    padding-left: 10px;
  }
}
.otptext {
  font-size: 20px;
  padding-left: 10px;

  @media (max-width: 767px) {
    padding-left: 10px;
  }
}
.button-login {
  background-color: transparent;
  text-transform: uppercase;
  color: $orange;
  font-weight: 700;
  text-decoration: underline;
  text-decoration-thickness: 1.5px;
  border-radius: 0px;
  text-align: center;
  border: none;
}
.contentheading {
  width: 100%;
  font-size: 20px;
  color: $orange;
}
.errorslot {
  width: 100%;
  margin-top: 5px;
  margin-left: 60px;
  text-align: center;

  .error {
    @media (max-width: 767px) {
      font-size: 14px !important;
    }
  }

  @media (max-width: 767px) {
    max-width: 298px;
    margin-left: 0px;
    margin-top: 15px;
    width: 100%;
  }
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.b-form-datepicker {
  margin-top: 4px;
}

.radio-input {
  padding-bottom: 15px;
}

.rows.m-size .field p.field-label {
  margin-bottom: 10px !important;
}

.wdadj{
  margin-left: 25px;
}

.mmwidth{
  @media (max-width:767px) {
    max-width: 320px;
  }
}
</style>