<template>
  <main>
    <PortalLoader />
    <h1 class="visually-hidden">eSign Your Line of Cred Documents</h1>
    <section class="form form-layout">
      <div id="divSignHeader" class="signatureheader" tabindex="-1">
        <h2>
          {{ firstName }}, you’ve been conditionally <br class="hidden-xs" />
          approved for a
          <span class="blue"
            >{{ currencyShort(creditLimit) }} line of credit.<sup>2</sup></span
          >
        </h2>
      </div>
      <div class="signatureheaderline">
        <div style="border-top: 1px solid gray; width: 100%">&nbsp;&nbsp;</div>
      </div>
      <br />
      <div class="row sectionborder">
        <div class="sectionspacing">
          <div class=""><h2>Repayment Method</h2></div>
          <div class="sectionsubheader" style="padding-top: 5px">
            Which Repayment Method do you prefer?
          </div>
          <div><span style="color: #1e4488">Choose One</span></div>
          <div class="inputs" role="radiogroup" id="rdogrppayment" aria-label="Repayment Method">
            <div class="radio-input">
              <div
                class="radio-wrapper"
                @keydown.space="paymentMethodEFTKeyDown($event);"
                @click="paymentMethodEFT();"
              >
                <input
                  type="radio"
                  id="rdoPaymentMethodEFT"
                  name="paymentMethod"
                  v-model="repaymentMethod"
                  value="direct-deposit"
                  aria-label = "Repayment Method"
                  tabindex="-1"
                  aria-hidden="true"
                />
                <span id="spanPaymentMethodEFT" 
                  class="radio-checkmark" tabindex="0" 
                  role="radio"
                  :aria-checked="repaymentMethod == 'direct-deposit' ? true : null"
                  aria-describedby="lblpaymentEFT"
                />
                <span class="radio-label" style="font-weight: bold">
                  <label id="lblpaymentEFT" for="rdoPaymentMethodEFT">Electronic Funds Transfer (EFT)</label>
                </span>
              </div>
            </div>
            <div class="radio-input">
              <div
                class="radio-wrapper"
                @click="paymentMethodPaper();"
                @keydown.space="paymentMethodPaperKeyDown($event);"
              >
                <input
                  type="radio"
                  id="rdoPaymentMethodPaperChk"
                  name="paymentMethod"
                  v-model="repaymentMethod"
                  value="paper-check"
                  aria-label = "Repayment Method"
                  tabindex="-1"
                  aria-hidden="true"
                />
                <span id="spanPaymentMethodPaperChk" 
                      class="radio-checkmark" 
                      tabindex="0" style="height: 14.5px; width: 18px;" 
                      role="radio"
                      :aria-checked="repaymentMethodADA == 'paper-check' ? true : null"
                      aria-describedby="lblpaymentPaperCheck"
                />
                <span class="radio-label" style="font-weight: bold">
                  <label id="lblpaymentPaperCheck" for="rdoPaymentMethodPaperChk">Paper Check</label>
                </span>
              </div>
            </div>
            <div class="radio-input">
              <div class="radio-label error" v-if="showPaymentError">
                Please select a repayment method
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row sectionborder" id="divMilitery" v-if="showDivMilitery">
        <div class="sectionspacing">
          <div id="divMilServHeading" class=""><h2>Military Service</h2></div>
          <div style="padding-top: 10px">
            <span style="color: #1e4488">Choose One Of The Following Statements</span>
          </div>
          <div class="subnoteesign">
            {{ legal.militaryDisclaimer }}
          </div>
          <div class="inputs" role="radiogroup" id="rdogrpmilserv" aria-label="Military Service">
                  <div class="radio-input">
                    <div
                      class="radio-wrapper"
                      @click="notMilitaryService();"
                      @keydown.space="notMilitaryServiceKeyDown($event);"
                    >
                      <input
                        type="radio"
                        id="rdoMilitaryServiceNo"
                        name="militaryService"
                        v-model="militaryService"
                        :value="false"
                        aria-label = "Military Service"
                        tabindex="-1"
                        aria-hidden="true"
                      />
                      <span id="spanMilitaryServiceNo" 
                          class="radio-checkmark" tabindex="0"
                          role="radio"
                          :aria-checked="militaryServiceADANo == true ? true : null"
                          aria-describedby="lblNotInMilitaryService"
                      />
                      <span class="radio-label">
                        <label id="lblNotInMilitaryService" for="rdoMilitaryServiceNo">I AM NOT {{ legal.militaryNo }}</label>
                      </span>
                    </div>
                  </div>
                  <div class="radio-input">
                    <div
                      class="radio-wrapper"
                      @click="inMilitaryService();"
                      @keydown.space="inMilitaryServiceKeyDown($event);"
                      >
                      <input
                        type="radio"
                        id="rdoMilitaryServiceYes"
                        name="militaryService"
                        v-model="militaryService"
                        :value="true"
                        aria-label = "Military Service"
                        tabindex="-1"
                        aria-hidden="true"
                      />
                      <span  id="spanMilitaryServiceYes" 
                        class="radio-checkmark" tabindex="0"
                        role="radio"
                        :aria-checked="militaryService ==  true ? true : null"
                        aria-describedby="lblInMilitaryService"
                      />
                      <span class="radio-label"> 
                        <label id="lblInMilitaryService" for="rdoMilitaryServiceYes">I AM {{ legal.militaryYes }}</label>
                        </span>
                    </div>
                  </div>
                  <div class="radio-input">
                    <div class="radio-label error" v-if="showMilServError">
                      Please select the statement that applies to you
                    </div>
                  </div>
          </div>
        </div>
      </div>

      <div
        class="row sectionborder"
        id="divChooseEsignDoc"
        v-if="showDivChooseEsignDoc"
      >
        <div class="sectionspacing">
          <div class="color:#53575a">
            <h2>Electronically Sign Your Credit Line Documents</h2>
          </div>
          <div class="inputs">
            <label class="input-checkbox-wrapper">
              <span>
                <label id="lbllegalsignatureterms" for="chkAcceptTerms">{{ legal.signatureTerms }}</label>
                </span>
                <input
                  type="checkbox"
                  id="chkAcceptTerms"
                  v-model="acceptTerms"
                  @click="HideErrorMessage('acceptTerms')"
                  tabindex="-1"                  
                />
                <span
                  id="spanAcceptTerms"
                  role="checkbox"
                  :aria-checked="acceptTerms ==  true ? true : null"
                  class="input-checkbox input-checkbox-sm"
                  tabindex="0"
                  style="top: 20px"
                  aria-describedby="lbllegalsignatureterms"
                />
            </label>
            <div class="error" v-if="showAcceptTermError">
              You must check the box as part of your eSignature
            </div>
            <div class="signature">
              <div style="font-weight: bold; color: black">
                <label  for="txtBorrowerName">
                  Borrower Name: {{ firstName + " " + lastName }}
                </label>
              </div>
              <div>
                <input
                  id="txtBorrowerName"
                  class="signature-input"
                  v-model="borrowerName"
                  @input="validateSignature"
                  aria-describedby="divBorrowerNameDesc"
                />              </div>
              <div class="subnoteesign" id="divBorrowerNameDesc">
                Please ensure your signature matches the name on your
                application.
              </div>
              <div class="visually-hidden">
                <label id="lblEmail">
                  {{ custEmail  }}
                </label>
              </div>
              <div class="error" v-if="showSigError">
                Please enter your name to eSign
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row papercheck"
        id="divPaperCheckValidation"
        v-if="showDivPaperCheckValidation"
      >
        <br />
        <br />
        <div style="padding-left: 5%; padding-right: 5%">
          <div style="text-align: center">
            <div class="papercheck-header">
              You've opted to repay any draws on your Line of Credit with paper
              checks.
            </div>
            <div class="papercheck-subheader">
              Selecting this option may extend the processing time of your
              application by 7 to 10 business days.
            </div>
          </div>
          <div class="papercheck-contents">
            <p class="papercheck-contents-header"><b>TO PROCEED:</b></p>
            <ul>
              <li class="papercheck-contents-subheader">
                Please call Customer Service at
                <a
                  href="tel:1.833.546.2135"
                  style="
                    color: #53575a;
                    text-decoration-line: underline;
                    border-bottom: none;
                  "
                  >1.833.546.2135</a
                >
                to complete your application confirming that you want to pay by
                paper check.
              </li>
              <li class="papercheck-contents-subheader">
                After we confirm that you want to pay by paper check, we’ll send
                you a Check Confirmation form, an eSign Consent form, an
                Arbitration Agreement and Privacy Notice.
              </li>
              <li class="papercheck-contents-subheader">
                You’ll need to complete and sign the forms and then email them
                back to us.
              </li>
              <li class="papercheck-contents-subheader">
                We’ll review the forms you send in and then notify you about
                your line of credit status.
              </li>
              <li class="papercheck-contents-subheader">
                If your line of credit is approved, we’ll provide you with
                additional instructions to complete the process online.
              </li>
            </ul>
          </div>
          <br />
          <div>
            <p class="papercheck-footer">
              <a href="tel:1.833.546.2135" class="callanagent"
                ><i>Call An Agent: 1.833.546.2135</i></a
              >
              <button
                class="button-papercheck"
                @click="
                  repaymentMethod = 'direct-deposit';
                  hideshowpapercheckpopup('ACH');
                "
              >
                GO BACK TO EFT AND FINISH ESIGN
              </button>
            </p>
          </div>
          <br /><br />
        </div>
      </div>
      <div class="row">
        <div class="hidelarge" style="text-align: center; padding-top: 20px">
          <span class="approved">
            Conditionally Approved Line of Credit Amount
            {{ currencyShort(creditLimit) }}<sup>2</sup>
          </span>
        </div>
        <div class="bottom" style="margin-top: 20px">
          <router-link :to="{ name: 'esign-consent' }" class="border-hover approved button-back">
            BACK
          </router-link>
          <span class="approved hidesmall">
            Conditionally Approved Line of Credit Amount
            {{ currencyShort(creditLimit) }}<sup>2</sup>
          </span>
          <button
            class="button-orange"
            @click="submitSignature"
            v-if="!showDivPaperCheckValidation && canEnableSubmit()"
          >
            SUBMIT
          </button>
          <button
            class="button-Save-disabled"
            v-if="showDivPaperCheckValidation || !canEnableSubmit()"
          >
            SUBMIT
          </button>
        </div>
        <div class="error genericerror" v-if="showGenericMsgError">
          Please complete all sections as noted above
        </div>
      </div>
    </section>
    <PaperCheckModal />
  </main>
</template>

<script>
import legal from "@/config/text/legal";
import help from "@/config/text/help";
import { PortalLoader, PaperCheckModal } from "@/components";
import { currencyShort } from "@/utils/filters/currency";

export default {
  name: "EsignSignature",
  title: "eSign",
  components: { PortalLoader, PaperCheckModal },
  mounted() {
    this.focusfirstelement();
  },
  async created() {
    if (!this.$store.state.esign.consentComplete) {
      this.$router.replace({ name: "esign-consent" });
    }

    if (this.ip == null) 
    {
      await this.$store.dispatch("findEsignIP");
    }

    if (this.repaymentMethod === "paper-check") {
      this.hideshowpapercheckpopup("papercheck");
    }
  },
  data() {
    return {
      submitting: false,
      legal: legal,
      help: help,
      showSigError: false,
      showPaymentError: false,
      showMilServError: false,
      showAcceptTermError: false,
      showGenericMsgError: false,
      showDivMilitery: true,
      showDivChooseEsignDoc: true,
      showDivPaperCheckValidation: false,
      currencyShort: currencyShort
    };
  },
  computed: {
    isFormValid: function () {
      const invalidFields = !!Object.values(this.fields).filter(
        (field) => !field.valid
      ).length;
      const invalidAuxFields =
        this.repaymentMethod === null ||
        this.militaryService === null ||
        this.acceptTerms === null ||
        this.acceptTerms === false ||
        this.borrowerName === null ||
        this.showSigError;
      return !invalidFields && !invalidAuxFields;
    },
    reloadcurrent: {
      get() {
        return this.$store.state.esign.reloadpage;
      },
      set(value) {
        this.$store.commit("updatereload", value);
      },
    },
    iovationProceedStatus: {
      get() {
        return this.$store.state.iovation.iovationproceedstatus;
      },
    },
    militaryService: {
      get() {
        return this.$store.state.esign.militaryService;
      },
      set(value) {
        this.$store.commit("updateMilitaryService", value);
      },
    },
    militaryServiceADANo: {
      get() {
        if (this.$store.state.esign.militaryService==false)
        {
            return true;
        }
        else
        {
            return false;
        }
        
      },
    },
    acceptTerms: {
      get() {
        return this.$store.state.esign.acceptTerms;
      },
      set(value) {
        this.$store.commit("updateAcceptTerms", value);
      },
    },
    borrowerName: {
      get() {
        return this.$store.state.esign.borrowerName;
      },
      set(value) {
        this.$store.commit("updateBorrowerName", value);
      },
    },
    creditLimit: {
      get() {
        return this.$store.state.esign.creditLimit;
      },
    },
    firstName: {
      get() {
        return this.$store.state.esign.firstName;
      },
    },
    lastName: {
      get() {
        return this.$store.state.esign.lastName;
      },
    },
    repaymentMethod: {
      get() {
        return this.$store.state.esign.paymentMethod;
      },
      set(value) {
        this.$store.commit("updatePaymentMethod", value);
      },
    },
    repaymentMethodADA: {
      get() {
        if(this.$store.state.esign.paymentMethod == 'paper-check')
        {return true;}
        else
        { return false;}
      },
    },
    IsPaperCheckAllowed: {
      get() {
        return this.$store.state.esign.IsPaperCheckAllowed;
      },
    },
    displayPaperCheckModal: {
      get() {
        return this.$store.state.esign.showPaperCheckModal;
      },
      set(value) {
        this.$store.state.esign.showPaperCheckModal = value;
      },
    },
    custEmail: {
      get() {
        return this.$store.state.esign.custEmail;
      },
    },

  },
  methods: {
    focusfirstelement() {
      document.getElementById("divSignHeader").focus();
    },
    notMilitaryService()
    {
            this.militaryService = false;
            this.HideErrorMessage('militaryService');  
            document.getElementById("spanMilitaryServiceNo").focus();    
    },
    notMilitaryServiceKeyDown(event)
    {
            this.militaryService = false;
            this.HideErrorMessage('militaryService');  
            document.getElementById("spanMilitaryServiceNo").focus();    
            event.preventDefault();
    },
    inMilitaryService()
    {
            this.militaryService = true;
            this.HideErrorMessage('militaryService');  
            document.getElementById("spanMilitaryServiceYes").focus();    
    },
    inMilitaryServiceKeyDown(event)
    {
            this.militaryService = true;
            this.HideErrorMessage('militaryService');  
            document.getElementById("spanMilitaryServiceYes").focus();  
            event.preventDefault();  
    },    
    paymentMethodEFT()
    {
            this.repaymentMethod = 'direct-deposit';
            this.HideErrorMessage('paymentMethod');
            this.hideshowpapercheckpopup('ACH');
            document.getElementById("spanPaymentMethodEFT").focus();    
    },
   paymentMethodEFTKeyDown(event)
    {
            this.repaymentMethod = 'direct-deposit';
            this.HideErrorMessage('paymentMethod');
            this.hideshowpapercheckpopup('ACH');
            document.getElementById("spanPaymentMethodEFT").focus();    
            event.preventDefault();
    },    
    paymentMethodPaper()
    {
            this.repaymentMethod = 'paper-check';
            this.HideErrorMessage('paymentMethod');
            this.hideshowpapercheckpopup('papercheck');
    },
    paymentMethodPaperKeyDown(event)
    {
            this.repaymentMethod = 'paper-check';
            this.HideErrorMessage('paymentMethod');
            this.hideshowpapercheckpopup('papercheck');
            event.preventDefault();
    },
    HideErrorMessage(sectionName) {
      if (sectionName === "paymentMethod") {
        this.showPaymentError = false;
      } else if (sectionName === "militaryService") {
        this.showMilServError = false;
      } else if (sectionName === "acceptTerms") {
        this.showAcceptTermError = false;
      }

      this.showGenericMsgError = false;
    },
    canEnableSubmit() {
      var result = true;
      if (
        this.repaymentMethod === null ||
        this.militaryService === null ||
        this.acceptTerms === null ||
        this.acceptTerms === false ||
        this.borrowerName === null
      ) {
        result = false;
      }
      if (this.borrowerName != null) {
        const borrower = this.borrowerName.toLowerCase().replace(" ", "");
        const signature = (this.firstName + this.lastName)
          .toLowerCase()
          .replace(" ", "");
        if (borrower !== signature) {
          result = false;
        }
      }
      if (this.showDivPaperCheckValidation) {
        result = false;
      }
      return result;
    },
    validate() {
      var result = true;

      if (this.repaymentMethod === null) {
        this.showPaymentError = true;
        result = false;
      }

      if (this.militaryService === null) {
        this.showMilServError = true;
        result = false;
      }

      if (this.acceptTerms === null || this.acceptTerms === false) {
        this.showAcceptTermError = true;
        result = false;
      }

      if (this.borrowerName === null) {
        this.showSigError = true;
        result = false;
      } else if (this.borrowerName != null) {
        const borrower = this.borrowerName.toLowerCase().replace(" ", "");
        const signature = (this.firstName + this.lastName)
          .toLowerCase()
          .replace(" ", "");
        if (borrower !== signature) {
          this.showSigError = true;
          result = false;
        }
      }

      if (result === false) {
        this.showGenericMsgError = true;
      }
      return result;
    },
    async submitSignature() {
      if (this.validate()) {
        this.submitting = true;

        this.$store.state.account.isLoading = true;

        if (this.iovationProceedStatus) {
          this.$store.state.iovation.enduserip =
            this.$store.state.esign.remoteIP;

          await this.$store.dispatch("PostIovation");
        }
        
        var result;
        result = await this.$store.dispatch("postSignatureData");

        if (result.status) {
          result = null;
          result = await this.$store.dispatch("saveSignatures");
          if (result.status) {
            if (
              this.$store.state.esign.firstDrawAmount !=
              this.$store.state.login.firstDrawAmount
            )
              await this.$store.dispatch("updateDrawAmount");
            if (!this.refuseDraw && result.status === true) {
              await this.$store.dispatch("saveNote");
            }
            this.$router.push({ name: "esign-contact" });
            this.$store.state.account.refreshaccount = true;
            this.$store.state.account.finalizeEsign = false;
          } else {
            alert("Data not saved. please try again.");
          }
        }

        this.$store.state.account.isLoading = false;
        this.submitting = false;
      }
    },
    validateSignature() {
      const borrower = this.borrowerName.toLowerCase().replace(" ", "");

      if (borrower != null) {
        const signature = (this.firstName + this.lastName)
          .toLowerCase()
          .replace(" ", "");
        this.showSigError = borrower !== signature;
      } else {
        this.showSigError = true;
      }
      this.showGenericMsgError = false;
    },
    hideshowpapercheckpopup(paymenttype) {
      if (this.IsPaperCheckAllowed === false) {
        if (paymenttype === "papercheck") {
          //this.showDivMilitery = false;
          //this.showDivChooseEsignDoc = false;
          this.showDivPaperCheckValidation = false;
          this.displayPaperCheckModal = true;
          setTimeout(() => {
          document.getElementById("btnGoBackEft").focus();
          }, 100)
        } else {
          //this.showDivMilitery = true;
          //this.showDivChooseEsignDoc = true;
          this.showDivPaperCheckValidation = false;
          this.displayPaperCheckModal = false;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">



main {
  width: 100%;

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
  .form-layout {
    margin-top: 50px;
  }

  .hidesmall {
    display: block;
  }
  .hidelarge {
    display: none;
  }

//--------------------- style for tab and focus

  #divSignHeader:focus-visible {
        outline: 0px solid #1e4388;
    }

span.radio-checkmark:focus-visible {
    outline-offset: 3px;
}
#spanAcceptTerms:focus-visible {
    outline: 2px solid #1e4388;
    filter: drop-shadow(0px 0px 4px gray);
}

#spanPaymentMethodPaperChk:focus {
    outline: 2px solid #1e4388;
    filter: drop-shadow(0px 0px 4px gray);
}


//--------------------------------------------

.signatureheader {
    text-align: center;

    sup {
      font-size: 18px;
    }
  }

  .signatureheaderline {
    text-align: center;
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 5px;
  }

  .sectionheader {
    color: gray;
    font-size: 25px;
    font-weight: bold;
  }

  .sectionsubheader {
    color: rgb(44, 43, 43);
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    @media (max-width: 768px) {
      text-transform: initial;
    }
  }

  .sectionspacing {
    padding-left: 30px;
    padding-bottom: 20px;
    padding-top: 20px;
    padding-right: 20px;
  }

  .sectionborder {
    border: 1px solid $gray;
    @media (max-width: 465px) {
      border-top: 1px solid $gray;
      border-left: none;
      border-right: none;
      border-bottom: none;
      border-radius: 0px;
    }
    border-radius: 6px;
  }

  .subnoteesign {
    font-size: 12px;
    font-style: italic;
    color: #6e6e6e;
    margin: 0px 0;
    //width:134%;
  }
  .inputs {
    flex-flow: column nowrap !important;
  }

  .row {
    margin: 30px 0 !important;
  }

  .choose {
    margin-left: 10px;
  }

  .radio-input {
    margin: 20px 20px 0 0;

    .radio-wrapper {
      width: 100%;

      .radio-label {
        width: 100%;
        color: black;
      }
    }
  }

  .input-checkbox-wrapper {
    height: 66px;
    margin: 10px 0;
    width: 95%;
  }
  .input-checkbox-wrapper .input-checkbox:after {
    content: "✓ ";
    left: -2px;
    top: -13px;
    width: 5px;
    color: $orange;
    font-size: 30px;
    font-weight: bold;
  }

  .input-checkbox-wrapper .input-checkbox-sm {
    top: 0px;
    height: 15px;
    width: 15px;
    border: 1px solid $gray;
    //box-shadow: 0px 2px 3px 0px $gray;

    &:after {
      content: "✓ ";
      left: -1px;
      top: -1px;
      width: 12px;
      height: 15px;
      font-size: 12px;
      padding-left: 3px;
      color: $absolute-white;
      background-color: $orange;
      border: 1px solid $orange;
      font-weight: bold;
    }
  }

  .signature {
    width: 85%;
    font-family: $font-primary; //$font-secondary;
    font-size: 14px;
    padding: 20px 0;
    //margin: 0 auto;

    .signature-input {
      height: 28px;
      width: 100%;
      border: 1px solid $gray;
      //padding-left: 50px;
      font-size: 14px;
      margin-left: 0px;
      border-radius: 0px;
    }

    i {
      position: absolute;
      margin: -36px 10px 0;
      color: $gray;
      height: 20px;
      width: 20px;
    }

    .error {
      color: $error;
    }
  }
  .papercheck {
    border: 1px solid $gray;
    color: $gray;
    background-color: $absolute-white;
    border-radius: 6px;

    .papercheck-header {
      font-size: 28px;
      font-weight: bold;
      padding-left: 10%;
      padding-right: 10%;
      margin-bottom: 30px;
    }
    .papercheck-subheader {
      font-size: 18px;
      padding-left: 10%;
      padding-right: 10%;
      font-style: italic;
      margin-top: 5px;
    }
    .papercheck-contents {
      padding-left: 24px;
      padding-right: 24px;

      .papercheck-contents-header {
        padding-left: 25px;
      }

      .papercheck-contents-subheader {
        margin-bottom: 20px;
      }
    }

    .papercheck-footer {
      font-size: 13px;
      text-align: center;
    }

    .callanagent {
      padding-right: 30px;
      color: $orange;
      text-decoration-line: underline;
      border-bottom: none;
      font-size: 18px;
    }

    .button-papercheck {
      color: $absolute-white;
      background: $orange;
      font-size: 18px;
      font-weight: 800;
      padding: 6px 30px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .bottom {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    .approved {
      color: $gray;
      font-family: $font-primary; // $font-secondary;
      // width: 80%;
      text-align: left;
      margin-right: 30px;
      font-size: 16px;
      font-weight: bold;

      sup {
        margin-left: 2px;
      }
    }

    .button-back {
      color: $orange;
      background: $absolute-white;
      font-size: 16pt;
      font-weight: 800;
      padding: 4px 30px;
      border: 2px solid $orange;
    }
    .button-Save-disabled {
      color: $absolute-white;
      background: $gray;
      font-size: 16pt;
      font-weight: 800;
      padding: 6px 30px;
    }
  }

  .warning {
    color: $gray;
    font-style: italic;
    font-size: 11px;
    padding: 20px 0;
  }
  .error {
    margin: 10px 0;
    text-align: left;
    color: $error;
  }
  .genericerror {
    text-align: right;
  }

  @media (min-width: 1024px) {
    section.form {
      width: 900px;
      padding: 30px 50px;
    }
  }

  @media (max-width: 1023px) {
    .signatureheaderline {
      text-align: center;
      padding-left: 10%;
      padding-right: 10%;
      padding-top: 5px;
    }

    .hidesmall {
      display: none;
    }
    .hidelarge {
      display: block;
    }

    .row {
      margin: 10px 0 !important;
    }

    .choose {
      margin-left: 4px;
    }

    .radio-input {
      margin: 10px;

      .radio-wrapper {
        width: 100%;

        .radio-label {
          color: black;
          width: 100%;
        }
      }
    }

    .subnote {
      padding: 10px;
      text-align: center;
      max-width: 500px;
      margin: 10px auto;
    }

    .input-checkbox-wrapper {
      height: initial;
      margin: 10px 0;
      max-width: 95%;
    }

    .signature {
      i {
        margin: 8px 0 0 -330px;
      }
    }

    .approved {
      color: $gray;
      font-family: $font-primary; // $font-secondary;
      font-size: 16px;
      font-weight: bold;

      sup {
        margin-left: 2px;
      }
    }

    .bottom {
      -webkit-box-orient: horizontal;
      flex-flow: row;
      justify-content: space-evenly;

      .approved {
        margin-bottom: 20px;
        margin-top: 20px;
        text-align: center;
        // padding-right: 0px;
        width: 100%;
      }
    }

    .warning {
      padding: 20px;
      text-align: center;
      max-width: 500px;
      margin: 0 auto;
    }

    .error {
      margin: 10px 0 0 0;
      text-align: left;
    }

    .genericerror {
      text-align: center;
    }

    .button-orange {
      max-width: 215px;
      font-size:16pt;
    }
  }

  @media (max-width: 768px) {
    .button-papercheck {
      margin-top: 20px;
    }
    .callanagent {
      padding-left: 30px;
    }
  }

  @media (max-width: 580px) {
    .signature {
      width: 100%;
      .subnoteesign {
        text-align: center;
      }
    }
  }

  @media (max-width: 480px) {
    .callanagent {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

 .hidden-xs {
   @media (max-width: 768px) {
     display: none;
   }
 }
</style>