<template>
  <main>
    <div class="maincontent" v-if="!showSuccessMessage">
      <div :class="step4 ? 'login-box loginbox-height' : 'login-box'">
        <ContentSectionTitle class="visually-hidden"
          >Welcome to Xact Flex</ContentSectionTitle
        >
        <fieldset>
          <legend>
            <!--<ContentSectionTitle> Forgot Password? </ContentSectionTitle>-->
            <h2 class="sectiontitle">Forgot Password?</h2>
          </legend>

          <!--<p class="content">
          {{ ContentText.forgotPasswordBody }}
        </p>-->
          <div class="container">
            <div v-if="step1" class="maincontentsection">
              <v-form
                :validation-schema="schemaEmail"
                keep-values
                @submit="emailverification"
                v-slot="{ errors }"
              >
                <p class="content">Please enter your email address.</p>
                <FormField :errormsg="errors['email']">
                  <template v-slot:label>
                    <label for="emailinput"> EMAIL </label>
                  </template>
                  <v-field v-model.trim="email" name="email" v-slot="{ field }">
                    <input
                      v-bind="field"
                      id="emailinput"
                      type="email"
                      aria-describedby="email-errormsg"
                      aria-required="true"
                      autocomplete="false"
                    />
                  </v-field>
                  <template v-slot:error>
                    <v-error id="email-errormsg" name="email" />
                  </template>
                </FormField>

                <div role="alert" class="error" v-html="errorMsg" />
                <div v-if="isNewEmail" role="alert">
                  <p class="error ne">
                    You do not currently have a password.
                    <router-link
                      :to="{ name: 'create-password' }"
                      class="login-link"
                      aria-label="Please tab here to create a password"
                      >Please tap here</router-link
                    >
                    to create one.
                  </p>
                </div>

                <div class="bottomsection">
                  <button
                    id="btnFPemailverification"
                    class="button button-primary"
                  >
                    NEXT
                  </button>
                </div>
                <div class="loginlink">
                  <p class="cardtxt">
                    Do you already have a password?
                    <router-link :to="{ name: 'login' }" class="login-link"
                      >Log in here</router-link
                    >
                  </p>
                </div>
              </v-form>
            </div>
            <div v-if="step6" class="maincontentsection">
              <div class="invalidsecion">
                <div role="alert" class="congrats" v-html="errorMsg" />
                <div class="btnbottom">
                  <a
                    class="button-login"
                    :href="'tel:' + companyInfo.contactPhone"
                    >Call Now</a
                  >
                </div>
              </div>
            </div>
            <div v-if="step7" class="maincontentsection">
              <div class="invalidsecion">
                <div class="successheader">Good job.</div>
                <div class="successbody">
                  You successfully reset your password.
                </div>
                <div class="btnbottom">
                  <router-link :to="{ name: 'login' }" class="button-login"
                    >Log In</router-link
                  >
                </div>
              </div>
            </div>
            <div v-if="step2" class="maincontentsectiondiv">
              <div tabindex="0" id="identityverify">
                <p class="contentheader">Identity Verification</p>
                <p class="contentsub">
                  For your security, we need to confirm it’s you by sending a
                  one-time passcode. Your passcode will be active for 30
                  minutes.
                </p>
              </div>
              <p class="txtemail">
                Email <span class="txtspan">{{ email }}</span>
              </p>

              <div tabindex="0">
                <p class="contentheader2">How should we send your code?</p>
                <p class="txtblue">Choose one.</p>
              </div>
              <section class="choosediv">
                <div
                  role="radiogroup"
                  aria-label="Identity Verification"
                  v-if="showsendcodeoptions"
                >
                  <div
                    v-for="optiontype in verificationoptionlist"
                    :key="optiontype.id"
                  >
                    <div class="radio-input">
                      <div class="radio-wrapper short">
                        <input
                          :id="'rdo2faText' + optiontype.id"
                          type="radio"
                          name="chooseMethod"
                          v-model="choosepasscodeMethod"
                          :value="optiontype"
                          tabindex="-1"
                          aria-hidden="true"
                          aria-describedby="optselerror"
                          @click="Text2faSelectedKeyDown($event, optiontype)"
                        />
                        <span
                          :id="'span2faText' + optiontype.id"
                          class="radio-checkmark"
                          role="radio"
                          name="radiofa"
                          tabindex="0"
                          :aria-describedby="'lbl2faText' + optiontype.id"
                          @keydown.space="
                            Text2faSelectedKeyDown($event, optiontype)
                          "
                        />
                        <label
                          :id="'lbl2faText' + optiontype.id"
                          :for="'rdo2faText' + optiontype.id"
                          class="bold"
                          style="font-size: 14px"
                          >{{ optiontype.CallType }}
                          <span class="txtselection">
                            {{ optiontype.Numbertext }}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="radio-input" v-if="emailoption">
                    <div class="radio-wrapper short">
                      <input
                        id="chooseEmail"
                        type="radio"
                        name="chooseMethod"
                        v-model="choosepasscodeMethod"
                        value="email"
                        tabindex="-1"
                        aria-hidden="true"
                      />
                      <span
                        id="span2faEmail"
                        class="radio-checkmark"
                        role="radio"
                        tabindex="0"
                        :aria-checked="
                          choosepasscodeMethod == 'email' ? true : false
                        "
                        aria-label="Email"
                        aria-describedby="errIdntityOption"
                        @keydown.space="Email2faSelectedKeyDown($event)"
                      />
                      <label
                        id="lbl2faEmail"
                        for="rdo2faEmail"
                        class="bold"
                        style="font-size: 14px"
                        >Email</label
                      >
                      <span class="txtselection">
                        {{ maskedemail }}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  v-if="showOptionError"
                  id="errIdntityOption"
                  class="error"
                  role="alert"
                >
                  Please select an option
                </div>
              </section>
              <div class="bottomsection mrg-20">
                <button
                  class="button button-primary"
                  alt="sendcode"
                  draggable="false"
                  @click="sendverificationcode"
                >
                  SEND CODE
                </button>
              </div>
            </div>
            <!-- Step2 completed-->

            <div v-if="step3" class="rightcontentsection">
              <p class="txtemail">
                Email <span class="txtspan">{{ email }}</span>
              </p>
              <p class="contentheader pt" tabindex="0" id="FPstep3">
                Let's get you verified.
              </p>
              <div class="contentsub">
                <label for="otpinput">
                  Please enter the passcode we provided and choose "Next."
                </label>
              </div>
              <!--<br class="hidden" />
            <b>{{ extravalue }}</b>-->
              <div v-if="true" class="otpcontainer">
                <div class="otpimage">
                  <img
                    src="../../assets/logos/2fa_icon_em.png"
                    alt="XactFlex"
                    class="otpemailicon"
                    draggable="false"
                    v-if="imgemail"
                  />
                  <img
                    src="../../assets/logos/2fa_icon.png"
                    alt="XactFlex"
                    class="otptxticon"
                    draggable="false"
                    v-else
                  />
                </div>
                <div class="otptext">
                  <FormField>
                    <input
                      id="otpinput"
                      v-for="(value, index) in otpValues"
                      :key="index"
                      type="text"
                      maxlength="1"
                      class="otp-input"
                      :value="value"
                      @input="handleInput(index, $event)"
                      @keydown.backspace="handleBackspace(index)"
                      ref="otpFields"
                      aria-describedby="passcodefield-errormsg"
                      aria-required="true"
                    />
                    <template v-slot:error>
                      <!--    <span
                              id="passcodefield-errormsg"
                              v-text="errors.first('passcodefield')"
                            ></span
                          >
                          -->
                    </template>
                  </FormField>

                  <div
                    :class="appwithdrawn ? 'errorslot errwialign' : 'errorslot'"
                  >
                    <div class="error" v-html="errorMsg" role="alert" />
                    <div v-if="isResendCode">
                      <p class="resenderror" role="alert">
                        We have received your request and sent your new
                        passcode. If you need further assistance, please contact
                        Customer Service at
                        <a
                          class="button-login"
                          :href="'tel:' + companyInfo.contactPhone"
                          aria-label=" "
                          >{{ companyInfo.contactPhone }}</a
                        >.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="btnsection">
                <div class="btnleft">
                  <button
                    class="button-resend"
                    :disabled="resendmaxlimit"
                    alt="ResendCode"
                    draggable="false"
                    @click="reSendVerificatonCode"
                  >
                    RESEND CODE
                  </button>
                </div>
                <div class="btnright">
                  <button
                    class="button-verify"
                    :disabled="!passcodeverified"
                    alt="NEXT"
                    draggable="false"
                    @click="verifyPassCode"
                  >
                    NEXT
                  </button>
                </div>
              </div>
              <br class="hidden" />
            </div>
            <!-- step3 completed-->

            <div v-if="step4" class="maincontentsectiondiv">
              <div class="passcodesuccess">
                <p class="txtemail">
                  Email <span class="txtspan">{{ email }}</span>
                </p>
                <div class="img">
                  <img
                    src="../../assets/logos/Circle_check.png"
                    alt="XactFlex"
                    class=""
                    draggable="false"
                  />
                </div>
                <div class="txt" id="verificationsuccessmsg" tabindex="0">
                  <p class="txtsuccess">
                    THANKS FOR VERIFYING<br />
                    THAT IT'S YOU.
                  </p>
                  <p class="txtsubfont">
                    You'll now be directed to reset your password.
                  </p>
                </div>
                <loaderbar />
              </div>
            </div>
            <div v-if="step5" class="maincontentsectiondiv rowcontainer">
              <v-form
                :validation-schema="schemaCreatePwd"
                @invalid-submit="sendresetemailInvalid"
                @submit="sendresetemail"
                v-slot="{ errors }"
              >
                <div class="error" v-html="identityErrorMsg" role="alert" />
                <p class="txtemail">
                  Email <span class="txtspan">{{ email }}</span>
                </p>
                <!--<FormField>
                        <template v-slot:label> EMAIL </template>
                        <input
                          name="email"
                          v-model.trim="email"
                          v-validate="{ required: true }"
                          data-vv-validate-on="blur"
                          type="email"
                        />
                        <template v-slot:error>
                          {{ errors.first("email") }}
                        </template>
                      </FormField>-->
                <div class="secondsection m-size">
                  <div class="birthdate-content">
                    <FormField :errormsg="errors['birthdate']">
                      <template v-slot:label>
                        <label for="birthdate" class="applabel"
                          >DATE OF BIRTH<span class="lowercase">
                            mm/dd/yyyy</span
                          ></label
                        ></template
                      >

                      <b-input-group class="inputgroupdatewrapper">
                        <v-field
                          v-model="birthdate"
                          name="birthdate_forgotpwd"
                          v-slot="{ field }"
                        >
                          <b-form-input
                            v-bind="field"
                            type="text"
                            id="birthdate"
                            autocomplete="off"
                            maxLength="10"
                            aria-describedby="birthdate-errormsg dateofbirth-errormsg"
                            aria-required="true"
                            @keypress="allowonlynumbersandbackslash"
                            @blur="validateBirthDate()"
                            aria-invalid="false"
                          ></b-form-input>
                        </v-field>
                        <v-field
                          v-model="birthdatenewformat"
                          name="dobformat"
                          v-slot="{ field }"
                        >
                          <b-input-group-append>
                            <b-form-datepicker
                              v-bind="field"
                              button-only
                              right
                              locale="en-US"
                              aria-controls="birthdate"
                              :max="maxDateinput"
                              @context="onContext"
                            ></b-form-datepicker>
                          </b-input-group-append>
                        </v-field>
                      </b-input-group>

                      <template v-slot:error>
                        <v-error
                          id="birthdate-errormsg"
                          name="birthdate_forgotpwd"
                          v-if="birthdateerror == ''"
                        />
                        <span
                          v-else
                          id="dateofbirth-errormsg"
                          v-text="birthdateerror"
                        ></span>
                      </template>
                    </FormField>
                  </div>
                  <div style="flex: 1 1 40%">
                    <FormField :errormsg="errors['SSN']">
                      <template v-slot:label>
                        <label for="socsecurity" class="applabel"
                          >LAST 4 OF SSN
                        </label>
                      </template>
                      <v-field
                        v-model.trim="lastFourSocial"
                        name="SSN"                        
                        v-slot="{ field }"
                      >
                        <input
                          v-bind="field"
                          id="socsecurity"
                          type="text"
                          maxlength="4"
                          aria-required="true"
                          @keypress="limitNums"
                          aria-describedby="ssn-errormsg"
                        />
                      </v-field>
                      <template v-slot:error>
                        <v-error id="ssn-errormsg" name="SSN" />
                      </template>
                    </FormField>
                  </div>
                </div>
                <div class="rows m-size">
                  <FormField :errormsg="errors['password']">
                    <template v-slot:label
                      ><label for="password" class="applabel"
                        >RESET YOUR PASSWORD</label
                      ></template
                    >
                    <v-field
                      v-model="password"
                      name="password"                      
                      v-slot="{ field }"
                    >
                      <input
                        v-bind="field"
                        id="password"
                        maxlength="20"
                        class="password"
                        aria-describedby="passwordcriteria resetpwderrormsg"
                        aria-required="true"
                        :type="showPlainPasswords ? 'text' : 'password'"
                        @keyup="frmPassword_Criteria"
                      />
                    </v-field>
                    <div
                      class="show-icon"
                      @click="showPlainPasswords = !showPlainPasswords"
                    >
                      <i
                        :class="
                          showPlainPasswords
                            ? 'fas fa-eye-slash fa-lg'
                            : 'fa fa-eye fa-lg'
                        "
                      />
                    </div>

                    <div
                      v-show="!errors.password && password"
                      class="input-accept"
                    />
                    <div v-show="errors.password" class="input-reject" />

                    <template v-slot:error>
                      <v-error id="resetpwderrormsg" name="password" />
                    </template>
                  </FormField>
                </div>
                <div class="rows m-size">
                  <FormField :errormsg="errors['confirmpassword']">
                    <template v-slot:label>
                      <label for="confirmpassword" class="applabel"
                        >CONFIRM PASSWORD</label
                      >
                    </template>
                    <v-field
                      v-model="confirmPassword"
                      name="confirmpassword"
                      v-slot="{ field }"
                    >
                      <input
                        v-bind="field"
                        id="txtconfirmpwd"
                        class="password"
                        aria-describedby="confirmpassword-errormsg"
                        aria-required="true"
                        :type="showConfirmPasswords ? 'text' : 'password'"
                      />
                    </v-field>
                    <div
                      class="show-icon"
                      @click="showConfirmPasswords = !showConfirmPasswords"
                    >
                      <i
                        :class="
                          showConfirmPasswords
                            ? 'fas fa-eye-slash fa-lg'
                            : 'fa fa-eye fa-lg'
                        "
                      />
                    </div>

                    <div
                      v-show="!errors.confirmpassword && confirmPassword"
                      class="input-accept"
                    >
                      <span class="matchicon">match</span>
                    </div>
                    <div v-show="errors.confirmpassword" class="input-reject" />
                    <template v-slot:error>
                      <v-error
                        id="confirmpassword-errormsg"
                        name="confirmpassword"
                      />
                    </template>
                  </FormField>
                </div>
                <div class="rows m-top">
                  <p class="instructions" id="passwordcriteria">
                    <span>Passwords must contain</span>
                    <!-- <span>
                          {{ help.passwordRules }}
                        </span> -->
                    <span ID="revPwd_Reg6" class="go-normal">
                      8 to 20 characters</span
                    ><span> and have at least</span>
                    <span ID="revPwd_Reg1" class="go-normal">
                      one uppercase letter,</span
                    >
                    <span ID="revPwd_Reg2" class="go-normal">
                      one lowercase letter,</span
                    >
                    <span ID="revPwd_Reg3" class="go-normal"> one number,</span>
                    <span ID="revPwd_Reg4" class="go-normal">
                      one special character (ex. !@%$&*)</span
                    >
                    <span> and</span>
                    <span ID="revPwd_Reg5" class="go-normal"> no spaces.</span>
                  </p>
                </div>
                <div class="bottom">
                  <!--<router-link
                          class="button button-primary nofill"
                          :to="{ name: 'login' }"
                        >
                          BACK
                        </router-link>-->
                  <button class="button button-primary" alt="Save Password">
                    SAVE PASSWORD
                  </button>
                </div>
              </v-form>
            </div>
          </div>
          <!-- Container end-->
        </fieldset>
      </div>
      <!-- login-box end-->
    </div>
    <!-- maincontent end-->
    <ForgotPasswordSuccess :email="this.email" v-if="showSuccessMessage" />
  </main>
</template>

<script>
import help from "@/config/text/help";
import companyInfo from "@/config/text/company";
import {
  ContentSectionTitle,
  FormField,
  ForgotPasswordSuccess,
  loaderbar,
} from "@/components";
import ContentText from "@/config/text/login";
// import DatePicker from "vuejs-datepicker";
import { passwordRegex } from "@/config/validations";
import { limitNums } from "@/utils/plugins/inputs";
//import { Validator } from "vee-validate";
import {
  birthdateStartDate,
  birthdateDisabledDates,
  birthPreviousDate,
} from "@/config/dates";
import moment from "moment";
import { mapActions, mapMutations } from "vuex";
import {
  formatemail2FA,
  formatcellno2FA,
  generatePasscode,
  GetCurrentDate,
  TimeElapsed,
} from "@/config/commonfunctions";
import { wrongPwdAttempt } from "@/store/login";
import pre from "@/config/text/createpassword";
import "../../styles/datepicker_bootstrap.scss";
import "../../styles/datepicker_bootstrapvue.scss";
import { date } from "@/utils/filters/dates";
import * as Yup from "yup";

export default {
  name: "ForgotPassword",
  title: "Forgot Password",
  components: {
    ContentSectionTitle,
    FormField,
    ForgotPasswordSuccess,
    loaderbar,
  },
  data() {
    let emailerrormsg =
      "Please enter your email address in this format: name@example.com";
    const schemaEmail = Yup.object().shape({
      email: Yup.string().email(emailerrormsg).required(emailerrormsg),
    });

    let birthdateReqerrormsg = "Please enter your date of birth";
    let birthdateInValiderrormsg = "Please enter a valid birthdate";
    let SSNerrormsg =
      "Please enter the last four digits of your social security number";
    let passworderrormsg = "Please enter a password that meets the criteria";
    let confirmpasswordReqerrormsg = "Please confirm your password.";
    let confirmpassworderrormsg = "Passwords must match.";
    const schemaCreatePwd = Yup.object().shape({
      birthdate_forgotpwd: Yup.string()
        .required(birthdateReqerrormsg)
        .matches(/^\d{2}\/\d{2}\/\d{4}$/, birthdateInValiderrormsg),
      SSN: Yup.string()
        .required(SSNerrormsg)
        .min(4, SSNerrormsg)
        .test("type", SSNerrormsg, (value) => !isNaN(value)),
      password: Yup.string()
        .required(passworderrormsg)
        .min(8, passworderrormsg)
        .matches(/(?=.*[a-z])/, passworderrormsg)
        .matches(/(?=.*[A-Z])/, passworderrormsg)
        .matches(/(?=.*\d)/, passworderrormsg)
        .matches(/(?=.*\W)/, passworderrormsg)
        .matches(/^\S*$/, passworderrormsg),
      confirmpassword: Yup.string()
        .required(confirmpasswordReqerrormsg)
        .oneOf([Yup.ref("password")], confirmpassworderrormsg),
    });

    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);
    maxDate.setFullYear(maxDate.getFullYear() - 18);
    return {
      schemaEmail,
      schemaCreatePwd,
      help: help,
      ContentText: ContentText,
      lastFourSocial: "",
      password: "",
      confirmPassword: "",
      ssnError: "",
      birthdateerror: "",
      CalledFrom: null,
      passwordRegex: passwordRegex,
      companyInfo: companyInfo,
      showPlainPasswords: false,
      showConfirmPasswords: false,
      showSuccessMessage: false,
      showOptionError: false,
      birthdateStartDate: birthdateStartDate,
      birthdateDisabledDates: birthdateDisabledDates,
      birthPreviousDate: birthPreviousDate,
      step1: true,
      step2: false,
      step3: false,
      step4: false,
      step5: false,
      step6: false,
      step7: false,
      formatemail2FA: formatemail2FA,
      formatcellno2FA: formatcellno2FA,
      formatmultiusercellno2FA: formatcellno2FA,
      generatePasscode: generatePasscode,
      GetCurrentDate: GetCurrentDate,
      otpValues: ["", "", "", "", "", ""],
      verificationoptionlist: [],
      showsendcodeoptions: false,
      mobileoption: false,
      emailoption: false,
      passcode: "",
      extravalue: "",
      passcodeverified: false,
      resendmaxlimit: false,
      havemobile: false,
      havemultiusermobile: false,
      isResendCode: false,
      imgemail: false,
      isNewEmail: false,
      appwithdrawn: false,
      maxDateinput: maxDate,
      formatlandlineno2FA: formatcellno2FA,
      havelandline: false,
      havePhone: false,
      haveemail: false,
      isMobileNumber: "Call",
      isLandLineNumber: "Call",
      choosePasscodeNumber: null,
      choosePasscodeType: null,
      wrongPwdAttempt: wrongPwdAttempt,
      date: date,
      sentNumber: "",
      pinCheckResponse:"",
    };
  },
  created() {
    //console.log(this.wrongPwdAttempt);
    this.$store.state.login.errorMsg = "";
    this.$store.commit("updateBirthdate", "");
    this.$store.commit("updateLoginSocial", "");
    this.$store.commit("updateEmailNotFound", false);
    this.$store.commit("updateCalledFrom", "ForgotPwd");
    // this.$nextTick(() => {
    //   this.$refs.verificationmessage.focus();
    // });
    // setTimeout(() => {
    //   document.getElementById("verificationsuccessmsg").focus();
    // }, 200);
  },
  computed: {
    isFormValid: function () {
      return !Object.values(this.fields).filter((field) => !field.valid).length;
      //&&  !this.validateBirthDate()
    },
    email: {
      get() {
        return this.$store.state.login.email;
      },
      set(value) {
        this.$store.commit("updateLoginEmail", value);
        this.isNewEmail = false;
      },
    },
    choosepasscodeMethod: {
      get() {
        return this.$store.state.login.chooseMethod;
      },
      set(value) {
        this.$store.commit("updatechooseMethod", value);
      },
    },
    setmultiusercustid: {
      get() {
        return this.$store.state.login.multiusercustid;
      },
      set(value) {
        this.$store.commit("updateMultiUserCustID", value);
      },
    },
    birthdate: {
      get() {
        if (
          this.$store.state.login.birthdate != null &&
          this.$store.state.login.birthdate.toString().includes("-") &&
          this.$store.state.login.birthdate.length == 10
        ) {
          return moment(
            String(this.$store.state.login.birthdate),
            "YYYY-MM-DD"
          ).format("MM/DD/YYYY");
        }

        return this.$store.state.login.birthdate;
      },
      set(value) {
        if (value.toString().length > 10) {
          value = moment(value, "MM/DD/YYYY").format("MM/DD/YYYY");
        }
        this.$store.commit("updateBirthdate", value);
      },
    },
    birthdatenewformat: {
      get() {
        if (String(this.birthdate).length == 10) {
          var val = moment(String(this.birthdate), "MM/DD/YYYY").format(
            "YYYY-MM-DD"
          );
          return val;
        } else {
          return this.birthdate;
        }
      },
      set(value) {
        this.birthdate = value;
      },
    },
    custId: {
      get() {
        return this.$store.state.login.custID;
      },
      set(value) {
        this.$store.commit("updateCustID", value);
      },
    },

    firstName: {
      get() {
        return this.$store.state.login.firstName;
      },
      set(value) {
        this.$store.commit("updatefirstName", value);
      },
    },
    errorMsg: {
      get() {
        //return this.$store.state.login.identityErrorMsg;
        return this.$store.state.login.errorMsg;
      },
    },
    identityErrorMsg: {
      get() {
        return this.$store.state.login.identityErrorMsg;
      },
    },
  },
  mounted() {
    this.focusfirstelement();
  },
  methods: {
    ...mapActions({
      createToken: "createToken",
      findIP: "findIP",
    }),
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      //this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      //this.selected = ctx.selectedYMD;
      if (
        ctx.selectedYMD != null &&
        ctx.selectedYMD != "" &&
        ctx.selectedYMD != "2001-01-01" &&
        ctx.selectedYMD.length == 10
      ) {
        this.validateBirthDate();
      }
    },
    focusfirstelement() {
      if (this.step1) document.getElementById("btnFPemailverification").focus();
    },
    Email2faSelectedKeyDown(event) {
      this.choosepasscodeMethod = "email";
      document.getElementById("span2faEmail").focus();
      event.preventDefault();
    },
    Text2faSelectedKeyDown(event, value) {
      var elementid = event.target.id;
      this.choosepasscodeMethod = value;
      document.getElementById(
        "rdo2faText" + this.choosepasscodeMethod.id
      ).checked = true;

      if (elementid.toString().includes("span")) {
        event.preventDefault();
      }

      //uncheck and check radiobutton
      var elements = document.getElementsByName("radiofa");
      for (var i = 0; i < elements.length; i++) {
        if (elements[i].id == "span2faText" + this.choosepasscodeMethod.id) {
          elements[i].setAttribute("aria-checked", true);
          elements[i].focus();
        } else elements[i].removeAttribute("aria-checked");
      }
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    onChangePasscodeMethod(event, NumberType) {
      this.choosePasscodeNumber = event.target.value;
      this.choosePasscodeType = NumberType;
    },
    handleInput(index, e) {
      let value = e.target.value;
      if (value.length > 1) {
        return;
      }
      this.otpValues[index] = value;
      if (value.length === 1 && index < this.otpValues.length - 1) {
        this.$refs.otpFields[index + 1].focus();
      }
      if (index == this.otpValues.length - 1) {
        this.passcodeverified = true; // enabled passcode next button
      }
    },

    handleBackspace(index) {
      if (this.otpValues[index] !== "") {
        this.otpValues[index] = "";
      } else if (index > 0) {
        this.$refs.otpFields[index - 1].focus();
      }
      this.passcodeverified = false; // disabled passcode next button
    },
    openCalendar() {
      this.$refs.birthdate.showCalendar();
    },
    updateBirthdate(eventval) {
      var inval = eventval;

      if (inval == "" || inval == null) {
        this.birthdate = "";
        this.birthdateerror = "";
        return;
      }

      if (!String(inval).includes("/")) {
        inval = moment(inval, "MM/DD/YYYY").format("MM/DD/YYYY");
      }

      var dateArray = inval.split("/");
      if (dateArray != null && dateArray.length == 3) {
        if (dateArray[0].length == 1) {
          dateArray[0] = "0" + dateArray[0];
        }
        if (dateArray[1].length == 1) {
          dateArray[1] = "0" + dateArray[1];
        }
        if (
          dateArray[0].length == 2 &&
          dateArray[1].length == 2 &&
          dateArray[2].length == 4
        ) {
          this.birthdate = dateArray.toString().replaceAll(",", "/");
        } else {
          this.birthdate = "";
        }
      } else {
        this.birthdate = "";
        this.birthdateerror = "";
        return;
      }
    },

    ...mapMutations({
      updateInvalidEmail: "updateInvalidEmail",
      updateEmptyEmail: "updateEmptyEmail",
      updateNewEmail: "updateNewEmail",
      updateinvalidUser: "updateinvalidUser",
      updateInvalidPassCode: "updateInvalidPassCode",
      updatePasscodeInvalidCodeAttempt: "updatePasscodeInvalidCodeAttempt",
      updatePasscodeAttemptFailed: "updatePasscodeAttemptFailed",
      updatePasscodeInvalidCodeSentAttempt:
        "updatePasscodeInvalidCodeSentAttempt",
    }),
    frmPassword_Criteria(event) {
      if (event.keyCode != 32) {
        var str = event.target.value;
        var cap = /(?=.*[A-Z])/;
        var lo = /(?=.*[a-z])/;
        var dig = /(?=.*\d)/;
        var sc = /(?=.*\W)/;
        var sp = /\s/;
        var leng = /^[\s\S]{8,20}$/;
        var ve1 = cap.test(str);
        var ve2 = lo.test(str);
        var ve3 = dig.test(str);
        var ve4 = sc.test(str);
        var ve5 = sp.test(str);
        var ve6 = leng.test(str);
        if (ve1 == true) {
          document
            .getElementById("revPwd_Reg1")
            .classList.replace("go-normal", "go-green");
        } else {
          document
            .getElementById("revPwd_Reg1")
            .classList.replace("go-green", "go-normal");
        }
        if (ve2 == true) {
          document
            .getElementById("revPwd_Reg2")
            .classList.replace("go-normal", "go-green");
        } else {
          document
            .getElementById("revPwd_Reg2")
            .classList.replace("go-green", "go-normal");
        }
        if (ve3 == true) {
          document
            .getElementById("revPwd_Reg3")
            .classList.replace("go-normal", "go-green");
        } else {
          document
            .getElementById("revPwd_Reg3")
            .classList.replace("go-green", "go-normal");
        }
        if (ve4 == true) {
          document
            .getElementById("revPwd_Reg4")
            .classList.replace("go-normal", "go-green");
        } else {
          document
            .getElementById("revPwd_Reg4")
            .classList.replace("go-green", "go-normal");
        }
        if (ve5 == true) {
          document
            .getElementById("revPwd_Reg5")
            .classList.replace("go-green", "go-normal");
        } else {
          document
            .getElementById("revPwd_Reg5")
            .classList.replace("go-normal", "go-green");
        }
        if (ve6 == true) {
          document
            .getElementById("revPwd_Reg6")
            .classList.replace("go-normal", "go-green");
        } else {
          document
            .getElementById("revPwd_Reg6")
            .classList.replace("go-green", "go-normal");
        }
      } else {
        document
          .getElementById("revPwd_Reg5")
          .classList.replace("go-green", "go-normal");
        event.preventDefault();
      }
    },

    //#region Prove
    activate() {
      setTimeout(() => {
        this.step4 = false;
        this.step5 = true;
        setTimeout(() => {
          document.getElementById("birthdate").focus();
        }, 300);
      }, 3000);
    },
    async emailverification() {
      this.isNewEmail = false;
      //this.$validator.validate();
      this.$store.commit("clear_identitymsg");
      /*
      let a =1;
      if (a==1) 
      {
      */
      if (this.$store.state.login.token == undefined) {
        const tokenResult = await this.$store.dispatch("createToken");
        this.$store.state.login.token = tokenResult.token.writtenToken;
      }
      this.$store.state.login.email = this.email;
      const res = await this.$store.dispatch("validateEmail");

      if (res != undefined) {
        this.$store.state.login.token = res.tokenToUse;
        this.$store.state.application.tokenID = res.tokenToUse;
        if (res.validEmail) {
          this.updateInvalidEmail(false);
          var hasRegisteredUsers = false,
            i;
          for (i = 0; i < res.users.length; i++) {
            if (res.users[i].userId > 0) {
              hasRegisteredUsers = true;
              break;
            }
          }
          if (!hasRegisteredUsers) {
            this.isNewEmail = true;
            //this.updateNewEmail(true);
          } else {
            this.choosepasscodeMethod = null;
            this.$store.commit("updateCustID", res.users[0].customerId);
            this.$store.commit("updatefirstName", res.users[0].firstName);

            //if 2FA is disabled customer to be moved directly to reset password section
            if (
              !res.twoFactorAuthenticationEnabled &&
              res.twoFactorAuthenticationEnabled != undefined
            ) {
              this.step1 = false;
              this.step2 = false;
              this.step3 = false;
              this.activate();
              return;
            }

            //Add Customer ID and Token to Prove API
            this.$store.commit("updateCustomerId", res.users[0].customerId);
            this.$store.commit("updateProveToken", res.tokenToUse);

            var mobilenumber = res.users[0].custMobileNumber;
            var homenumber = res.users[0].custHomePhoneNumber;
            // var mobilenumber = "4045792875";
            // var homenumber = "4564564564";
            if (mobilenumber != "" && mobilenumber != null) {
              //Add Cell phone number to list
              this.verificationoptionlist.push({
                CallType: "",
                Numbertext: formatcellno2FA(mobilenumber),
                numberval: mobilenumber,
                id: "1",
              });
            }
            //Add Home/Main phone number to list
            if (homenumber != mobilenumber && homenumber != "") {
              this.verificationoptionlist.push({
                CallType: "",
                Numbertext: formatcellno2FA(homenumber),
                numberval: homenumber,
                id: "2",
              });
            }

            //Loop through all numbers added and check if number provided is a mobile number
            for (
              let index = 0;
              index < this.verificationoptionlist.length;
              index++
            ) {
              const element = this.verificationoptionlist[index];

              let trustres = await this.$store.dispatch(
                "checkenterednumberifcellnumber",
                {
                  phonenumber: element.numberval,
                }
              );
              if (trustres == null) {
                this.verificationoptionlist[index].CallType = "Call";
              } else {
                this.verificationoptionlist[index].CallType =
                  trustres.lineType == "Mobile" ? "Text" : "Call";
              }
            }

            this.showsendcodeoptions = true;

            this.formatemail2FA = formatemail2FA(this.email);
            this.step2 = true;
            this.step1 = false;
            setTimeout(() => {
              document.getElementById("identityverify").focus();
            }, 200);
          }
        } else {
          if (this.email == null || this.email == "") {
            this.updateEmptyEmail(true); // Handle not associated email
          } else {
            this.updateinvalidUser(true);
            this.step6 = true;
            this.step1 = false;
          }
        }
      }

      //}
    },

    async sendverificationcode() {
      if (
        this.choosepasscodeMethod == null ||
        this.choosepasscodeMethod == ""
      ) {
        this.showOptionError = true;
        return false;
      }
      this.$store.commit("updateverificationcodesenttime", GetCurrentDate());
      this.$store.commit("updatePasscodeSession", generatePasscode());
      this.extravalue = localStorage.PasscodeSession;
      var SMSText = pre.SMSText.replace("{PASSCODE}", this.extravalue);
      this.$store.commit("updateSendMessagesUtilitySMSText", SMSText);
      this.$store.commit("updateMessageFor", "2FA");

      if (this.choosepasscodeMethod.CallType.toUpperCase() == "TEXT") {
        // send sms to mobile
        //console.log("Text number- " + this.choosepasscodeMethod.numberval);
        await this.$store.dispatch("SendSMSOTP", {
          mobileNumber: this.choosepasscodeMethod.numberval,
        });
        //console.log(txtres, "SMS send Successfuly");
      } else if (this.choosepasscodeMethod.CallType.toUpperCase() == "CALL") {
        // Call to Mobile/Landline
        //console.log("Call number- " + this.choosepasscodeMethod.numberval);
        await this.$store.dispatch("PinDelivery", {
          mobileNumber: this.choosepasscodeMethod.numberval,
          passCode: this.extravalue,
        });

        //console.log(callres, "Successfully Called");
      } else if (this.choosepasscodeMethod.toUpperCase() == "EMAIL") {
        // this.$store.commit(
        //   "updateSendMessagesUtilityEmailFirstName",
        //   this.firstName
        // );
        // this.$store.commit("updateSendMessagesUtilityEmail", this.email);
        // this.$store.commit(
        //   "updateSendMessagesUtilityToken",
        //   this.$store.state.login.token
        // );
        // this.$store.commit(
        //   "updateSendMessagesUtilityPasscode",
        //   this.extravalue
        // );
        // this.$store.commit(
        //   "updateSendMessagesUtilityEmailSubject",
        //   pre.EmailSubjectLine
        // );
        // this.$store.commit(
        //   "updateSendMessagesUtilityPreviewText",
        //   pre.PreviewText
        // );
        // this.imgemail = true;
        // await this.$store.dispatch("utilitiesSendEmail"); // sending email
        //console.log(pre.textEmailUpdate);
      }

      this.step3 = true;
      this.step2 = false;
      localStorage.resendAttempt = 0;
      localStorage.createpwdAttempt = 0;
      setTimeout(() => {
        document.getElementById("FPstep3").focus();
      }, 200);
    },

    async reSendVerificatonCode() {
      this.otpValues = ["", "", "", "", "", ""];
      var resendAttempt = parseInt(localStorage.resendAttempt) + 1;
      localStorage.resendAttempt = resendAttempt;
      this.passcodeverified = false;
      if (resendAttempt < this.wrongPwdAttempt) {
        this.$store.commit("updateverificationcodesenttime", GetCurrentDate());
        this.$store.commit("updatePasscodeSession", generatePasscode());
        this.extravalue = localStorage.PasscodeSession;
        this.updatePasscodeInvalidCodeAttempt(false);
        this.updatePasscodeInvalidCodeSentAttempt(false);

        this.isResendCode = true;

        var SMSText = pre.SMSText.replace("{PASSCODE}", this.extravalue);
        this.$store.commit("updateSendMessagesUtilitySMSText", SMSText);
        this.$store.commit("updateMessageFor", "2FA");

        if (this.choosepasscodeMethod.CallType.toUpperCase() == "TEXT") {
          // send sms to mobile
          //console.log(this.choosepasscodeMethod.numberval);
          await this.$store.dispatch("SendSMSOTP", {
            mobileNumber: this.choosepasscodeMethod.numberval,
          });
          //console.log("SMS send Successfuly");
        } else if (this.choosepasscodeMethod.CallType.toUpperCase() == "CALL") {
          // Call to mobile
          await this.$store.dispatch("PinDelivery", {
            mobileNumber: this.choosepasscodeMethod.numberval,
            passCode: localStorage.esignstatePasscodeSession,
          });
          //console.log(callres, "- Successfully Called");
        } else if (this.choosepasscodeMethod.toUpperCase() == "EMAIL") {
          // send code to email
          // this.$store.commit(
          //   "updateSendMessagesUtilityEmailFirstName",
          //   this.firstName
          // );
          // this.$store.commit("updateSendMessagesUtilityEmail", this.email); // 'rama.vanum@slchq.com'
          // this.$store.commit(
          //   "updateSendMessagesUtilityToken",
          //   this.$store.state.login.token
          // );
          // this.$store.commit("updateSendMessagesUtilityCustId", this.custId);
          // this.$store.commit(
          //   "updateSendMessagesUtilityPasscode",
          //   this.extravalue
          // );
          // this.$store.commit(
          //   "updateSendMessagesUtilityEmailSubject",
          //   pre.EmailSubjectLine
          // );
          // this.$store.commit(
          //   "updateSendMessagesUtilityPreviewText",
          //   pre.PreviewText
          // );
          // this.imgemail = true;
          // await this.$store.dispatch("utilitiesSendEmail"); // sending email
          //console.log(pre.textEmailUpdate);
        }
      } else {
        this.isResendCode = false;
        this.updatePasscodeInvalidCodeSentAttempt(true);
      }
    },

    async verifyPassCode() {
      this.isResendCode = false;

      //Application moved to withdrawn status
      if (this.appwithdrawn) {
        this.resendmaxlimit = true;
        localStorage.resendAttempt = this.wrongPwdAttempt;
        localStorage.createpwdAttempt = this.wrongPwdAttempt;
        this.updatePasscodeAttemptFailed(true);
        return; //"MAX_LIMIT"
      }

      this.$store.commit(
        "updateTimeElapsed",
        TimeElapsed(localStorage.verificationcodesenttime)
      );
      if (
        localStorage.createpwdAttempt > this.wrongPwdAttempt ||
        localStorage.TimeElapsed > localStorage.passcodetimeout
      ) {
        localStorage.PasscodeSession = "-11111111";
      }

      const enteredpasscode = Number(this.otpValues.join(""));

      //Prove Changes
      if (this.choosepasscodeMethod.CallType.toUpperCase() == "TEXT") {
        const txtres = await this.$store.dispatch("VerifySMSOTP", {
          passCode: enteredpasscode, sentNumber: this.choosepasscodeMethod.numberval,
        });

        await this.passcodeattemptcountcheck(txtres == "0");
        //console.log(txtres, "SMS send Successfuly");
      } else if (this.choosepasscodeMethod.CallType.toUpperCase() == "CALL") {
        const pinValidCheck = await this.passcodeattemptcountcheck(
          enteredpasscode == localStorage.PasscodeSession
        );
        await this.$store.dispatch("phoneCallVerify", {
          sentNumber: this.choosepasscodeMethod.numberval, pinCheckResponse: pinValidCheck,
        });
      } else if (this.choosepasscodeMethod.CallType.toUpperCase() == "EMAIL") {
        if (enteredpasscode == localStorage.PasscodeSession) {
          this.updatePasscodeInvalidCodeAttempt(false);
          this.updatePasscodeInvalidCodeSentAttempt(false);
          this.step4 = true;
          this.step3 = false;
          setTimeout(() => {
            document.getElementById("verificationsuccessmsg").focus();
          }, 200);
          this.activate();
        } else {
          var pwdAttempt = parseInt(localStorage.createpwdAttempt) + 1;
          localStorage.createpwdAttempt = pwdAttempt;
          if (pwdAttempt >= 5) {
            this.updatePasscodeInvalidCodeSentAttempt(true);
          } else {
            this.updatePasscodeInvalidCodeAttempt(true);
          }
        }
      }
    },

    async passcodeattemptcountcheck(passcodeverifiedresult) {
      let noteattempt = "Failed attempt",
        passcodeverificationstatus = "false";
      if (passcodeverifiedresult) {
        passcodeverificationstatus = "true";
        noteattempt = "Success attempt";
      } else {
        var pwdAttempts = parseInt(localStorage.createpwdAttempt) + 1;
        localStorage.createpwdAttempt = pwdAttempts;
      }

      this.$store.commit("updateProcessId", 1);
      this.$store.commit("updateProcessStatus", passcodeverificationstatus);
      this.$store.commit("updateAttemptNote", noteattempt);

      let result = await this.$store.dispatch(
        "getinvalidpasswordcreationattemptcount"
      );
      let retValue = "";
      
      if (
        result.attemptCount >= this.wrongPwdAttempt &&
        passcodeverificationstatus == "false"
      ) {
        retValue = "MAX_LIMIT";

        this.$store.state.application.tokenID = result.tokenToUse;
        await this.$store
          .dispatch(
            "updateApplicationToWithdraw",
            "Failed ID verification - Credit application could not be verified."
          )
          .then((res) => {
            this.appwithdrawn = true;
            this.resendmaxlimit = true;
            localStorage.resendAttempt = this.wrongPwdAttempt;
            localStorage.createpwdAttempt = this.wrongPwdAttempt;
            this.$store.state.login.tokenID = res.tokenToUse;
            this.updatePasscodeAttemptFailed(true);
          })
          .catch(() => {
            this.resendmaxlimit = true;
            //console.log("withdraw error-" + err);
            this.updatePasscodeInvalidCodeSentAttempt(true);
          });
      } else if (passcodeverificationstatus == "true") {
        retValue = "PASS";
        this.validPin();
      } else {
        this.invalidPin();
        retValue = "FAIL";
      }

      return retValue;
    },

    validPin() {
      this.updatePasscodeInvalidCodeAttempt(false);
      this.updatePasscodeInvalidCodeSentAttempt(false);
      this.step4 = true;
      this.step3 = false;
      setTimeout(() => {
        document.getElementById("verificationsuccessmsg").focus();
      }, 200);
      this.activate();
    },
    invalidPin() {
      if (localStorage.createpwdAttempt > this.wrongPwdAttempt) {
        this.updatePasscodeAttemptFailed(true);
      } else {
        this.updatePasscodeInvalidCodeAttempt(true);
      }
    },
    //#endregion Prove
    sendresetemailInvalid() {},
    async sendresetemail() {
      {
        //this.$validator.validate();
        this.$store.commit("clear_identitymsg");
        //    if (this.isFormValid) {

        this.$store.state.login.email = this.email;
        const res = await this.$store.dispatch("validateEmail");
        this.$store.commit("updateCustID", res.users[0].customerId);
        var newBirthDate = new Date(this.birthdate);
        if (
          newBirthDate <= this.birthdateStartDate &&
          newBirthDate > this.birthPreviousDate
        ) {
          await this.$store.dispatch("createToken");
          this.$store.commit("updateSSN", this.lastFourSocial);
          this.$store.commit("updatePassword", this.password);
          const res = await this.$store.dispatch(
            "validateIdentity",
            this.$store.state.login.token
          );
          if (res[1].validEmail && res[1].validIdentity) {
            // Reset Password Old code
            // const res = await this.$store.dispatch(
            //   "verifyIdentity",
            //   this.lastFourSocial
            // );
            // console.log(res);
            // debugger;
            // if (res !== undefined) {
            const result = await this.$store.dispatch(
              "ChangePassword",
              this.lastFourSocial + this.password
            );
            //console.log(result.updated);
            if (result.updated) {
              this.$store.commit("updateCalledFrom", "UpdatePwd");
              //this.$router.push({ name: "login" });
              this.step7 = true;
              this.step5 = false;
            } else {
              this.$store.commit("updatePasswordCreationError", true);
            }
          } else {
            //console.log("else");
            if (localStorage.Forgotpwdemail == this.email) {
              var pwdAttempt = parseInt(localStorage.ForgotpwdAttempt) + 1;
              localStorage.ForgotpwdAttempt = pwdAttempt;
              //console.log(pwdAttempt + ",," + this.wrongPwdAttempt);
              if (pwdAttempt >= this.wrongPwdAttempt) {
                this.$store.commit(
                  "updateAccountLockedPasswordCreationError",
                  true
                );
              } else {
                this.$store.commit(
                  "updateAccountLockedPasswordCreationError",
                  false
                );
                this.$store.commit("updateEmailNotFound", true);
              }
            } else {
              localStorage.Forgotpwdemail = this.email;
              localStorage.ForgotpwdAttempt = 1;
              this.$store.commit("updateEmailNotFound", true);
            }
          }
        }

        //   }
      }
    },

    validateBirthDate() {
      if (
        this.birthdate == null ||
        this.birthdate == undefined ||
        this.birthdate == "" ||
        this.birthdate.length < 10
      ) {
        //this.$validator.validate("birthdate_forgotpwd");
        return true;
      }

      var val = this.birthdate;
      var dateArray = val.split("/");
      if (dateArray == null || dateArray.length != 3) {
        this.birthdate = "";
        this.$validator.validate("birthdate_forgotpwd");
        return true;
      }
      try {
        var inp_date = moment(this.birthdate, "MM/DD/YYYY").format(
          "MM/DD/YYYY"
        );

        if (inp_date == "Invalid date") {
          this.birthdate = "";
          this.birthdateerror = "";
          this.$validator.validate("birthdate_forgotpwd");
          return true;
        }
        var cur_date = moment(new Date(), "MM/DD/YYYY").format("MM/DD/YYYY");
        var min_eligible_date = moment(cur_date, "MM/DD/YYYY").add(
          -18,
          "years"
        );
        if (
          moment(inp_date, "MM/DD/YYYY").isAfter(min_eligible_date) ||
          !moment(inp_date, "MM/DD/YYYY").isValid()
        ) {
          this.birthdateerror = "Please enter your date of birth";
          return true;
        }
      } catch (error) {
        //console.log(error);
      }
      this.birthdateerror = "";
      return false;
    },
    allowonlynumbersandbackslash(event) {
      var keyCode = event.keyCode;
      var isShift = false;
      if (keyCode == 16) {
        isShift = true;
      }
      //Allow only Numeric Keys.
      if (
        ((keyCode >= 47 && keyCode <= 57) ||
          keyCode == 8 ||
          keyCode <= 37 ||
          keyCode <= 39) &&
        isShift == false
      ) {
        if (keyCode == 47) {
          if (
            event.target.value != null &&
            event.target.value.length >= 1 &&
            event.target.value.length <= 5 &&
            keyCode != 8
          ) {
            return true;
          } else {
            event.preventDefault();
          }
        } else {
          var inval = event.target.value;
          if (
            inval != null &&
            (inval.length == 2 || inval.length == 5) &&
            keyCode != 8 &&
            keyCode != 47
          ) {
            let length = inval.length - 1;
            let count = inval.split("/").length;
            if (inval[length] != "/" && count <= 2)
              event.target.value = inval + "/";
          }
        }
      } else {
        event.preventDefault();
      }
    },
    limitNums,
  },
};
</script>


<style scoped lang="scss">
#content {
  margin-bottom: 0px;
  @media (max-width: 922px) {
    margin: 70px 0 0 0;
  }
}

input {
  border: solid 1px $fieldborder-gray;
  padding: 3px 5px 3px 5px;
}

.loginbox-height {
  height: 300px !important;
  @media (max-width: 767px) {
    height: 400px !important;
  }
}

.login-box {
  max-width: 350px;
  width: 100%;
  padding: 50px 78px 120px 78px;
  @media (max-width: 1260px) {
    padding: 40px 50px;
    margin: 10% auto;
  }
  @media (max-width: 600px) {
    min-width: 340px;
    padding: 40px 20px;
  }
  @media (max-width: 430px) {
    min-width: 300px;
  }
  @media (max-width: 400px) {
    padding: 40px 15px;
  }
  @media (max-width: 386px) {
    padding: 40px 13px;
    margin: 40px 20px;
  }
  fieldset {
    margin: 0px;
    padding: 0px;
    border: none;
    width: 100%;
  }

  .section-title {
    margin-left: 3px !important;
    padding-bottom: 3px;
    margin-bottom: 0px;
    h1 {
      font-size: 21pt;
      @media (max-width: 600px) {
        font-size: 21px;
      }
    }
  }
}

.rowcontainer {
  width: 100%;
  .rows {
    width: 100%;

    .left {
      width: 60%;
      float: left;
    }
    .right {
      width: 35%;
      float: right;
    }
    .field {
      width: 100%;
    }
  }
}
@media (max-width: 767px) {
  .rowcontainer {
    width: 95% !important;
    .rows {
      .left {
        width: 110% !important;
      }
      .right {
        width: 100% !important;
      }
      .field {
        width: 99% !important;
      }
    }
  }
}

.birthdate-content {
  width: 100%;
  flex: 1 1 60%;
}
.datepicker-icon {
  position: relative;
  margin-left: 2px !important;
  margin: 7px 10px;
  @media (max-width: 767px) {
    margin: 12px 10px;
  }
}

.successheader {
  color: #e75300;
  margin-bottom: 2px;
  margin-top: 15px;
  width: 100%;
  font-size: 24px;
  font-weight: 700;
}
.successbody {
  margin-top: 20px;
  margin-bottom: -15px;
}

main {
  .section-title {
    align-self: flex-start;
    margin-left: 0px !important;
    margin-bottom: 5px !important;
    font-weight: 700;

    @media (max-width: 767px) {
      margin-bottom: 0px !important;
      padding-bottom: 0px;
    }
  }
  .secondsection {
    display: flex;
    flex-direction: row wrap;
    //width: 100%;
  }

  #identityverify:focus,
  #FPstep3:focus,
  #btnFPemailverification:focus {
    outline: 2px solid $blue;
  }
  .datepicker-icon {
    margin-left: 120px;
  }
  .field .input-wrapper input {
    width: 100%;
    margin: 3px;
    height: 30px;
    @media (max-width: 767px) {
      height: 40px;
    }
  }
  .login-box .field {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .bottom {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 45px 0px 30px;

    @media (max-width: 400px) {
      margin: 55px 0px 50px;
    }

    .nofill {
      width: 50% !important;
    }

    .button-primary {
      margin-left: 0px;
      font-size: 16pt;
      font-weight: 700;
      padding: 8px 70px;
      width: 100%;

      @media (max-width: 767px) {
        padding: 10px;
      }
    }

    .next {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;

      img {
        margin-left: 10px;
      }
    }
  }

  .content {
    font-size: 14px;
    margin-top: 2px;
    margin-left: 5px;
    font-weight: 900;
    //opacity: 0.7;
    color: $gray;

    @media (max-width: 450px) {
      font-size: 16px;
      margin-top: 0;
      margin-left: 0;
      margin-bottom: 5px;
      padding: 0px 4px;
    }
  }
}

@media (max-width: 450px) {
  .section-title .header {
    margin-top: 0px;
  }
}

.txtplaceholder {
  font-size: 12px;
  padding: 1px 10px;
  color: #1e4488;
}

.container {
  width: 100%;
}
.lblstyle {
  display: contents;
}
.loginlink {
  width: 100%;
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
  .login-link {
    color: $blue;
    border-bottom: 1px solid $blue;
    font-weight: 600;
  }
}
.cardtxt {
  font-size: 12px;
}
.txtemail {
  font-size: 14px;
  font-weight: 700;
  padding-left: 5px;
  margin: 20px 0px;
}
.txtspan {
  margin-left: 10px;
  font-weight: 500;
}
.bottomsection {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  height: 34px;

  button {
    width: 100%;
  }

  .button-primary {
    font-size: 10pt;
    margin-left: 0px;
    padding: 8px 70px;
    font-weight: 700;
  }
}
.sectiontitle {
  color: $blue;
  margin-bottom: 2px;
  margin-top: 15px;
  width: 100%;
}
.contentheader,
.contentheader2 {
  font-weight: 600;
  text-transform: none;
  font-size: 16px;
  letter-spacing: 0.2px;
  margin: 0px;
  padding: 0px 0px 2px 5px;
}
.contentheader2 {
  padding-bottom: 10px;
}
.contentsub {
  padding: 0px 5px;
  margin: 0px 0px 10px;
  font-size: 14px;
  label {
    font-size: 12px;
    @media (max-width: 767px) {
      font-size: 13px;
      letter-spacing: 0.5px;
    }
  }
}
.pt {
  margin-top: 20px;
}

.txtblue {
  font-size: 13px;
  color: $royal-blue; //ayla was provided
  font-weight: 600;
  margin: -7px 0px 15px 0px;
  padding: 0px 5px;
}
.resenderror {
  font-size: 0.7rem;
  margin-left: 3px;
  color: $disabled-gray;
  text-align: left;

  @media (max-width: 767px) {
    font-size: 0.85rem;
    margin-top: 23px;
    margin-left: -54px;
    letter-spacing: 0.5px;
  }
  a {
    border-bottom: 1px solid $disabled-gray;
    color: $disabled-gray;
    text-decoration: none;
  }
}
#birthdate::placeholder {
  color: $disabled-gray;
  opacity: 1;
}
.ne {
  a {
    border-bottom: 1.5px solid #bc403a;
    color: #bc403a;
    text-decoration: none;
    font-weight: 700;
  }
}
.choosediv {
  padding-left: 5px;

  .radio-input {
    .radio-checkmark {
      height: 14px;
      width: 14px;
    }
  }
}
.txtselection {
  color: $orange;
  font-size: 14px;
  font-weight: 700;
  margin-left: 22px;
}
.space {
  width: 17%;
}
.rowpadding {
  margin: 5px 0px 10px;
}
.passcodeimg {
  width: 25%;
  float: left;
  img {
    margin: 10px 0px;
    padding: 0px 5px;
    margin-bottom: 20px;
  }
}
.passcodetxt {
  width: 70%;
  float: left;
  margin: 5px 0px;

  .error {
    font-size: 11px;
    text-align: center;
  }
}
.passcodesec {
  width: 100%;
}
.passcodefield {
  border: none;
  background-color: transparent;
  border-bottom: 2px solid #959595;
  border-radius: 0px;
}
.button-verify {
  color: $absolute-white;
  background: $orange;
  font-size: 14px;
  font-weight: 800;
  padding: 6px 50px;
}
.button-verify:disabled {
  background: $gray;
  color: $white;
}

.btnright button:disabled {
  background: $gray;
  color: $white;
}

.btnsection {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  height: 34px;
  padding-top: 20px;

  .button-verify {
    padding: 6px 50px;
  }
}
.button-resend {
  font-size: 14px;
  font-weight: 800;
  background-color: transparent;
  border-bottom: 2px solid $gray;
  border-radius: 0px;
  color: $gray;
  padding: 1px;
}
.button-resend:disabled {
  color: $dark-gray;
  background-color: transparent;
  border-bottom-color: $dark-gray;
}
.passcodesuccess {
  width: 100%;

  img {
    width: 15%;
    float: left;
    margin: 10px 0px;
  }
  .txt {
    width: 78%;
    float: left;
    padding: 0px 10px;

    .txtsuccess {
      color: $orange;
      font-weight: 700;
      letter-spacing: 2px;
      font-size: 16px;
    }
  }
}

.rowcontainer {
  .rows {
    width: 100%;

    .left {
      width: 60%;
      float: left;
    }
    .right {
      width: 40%;
      float: right;
    }
    .field {
      width: 100%;
    }
  }
}
.maincontentsection {
  .error {
    font-size: 12px;
  }
}
//passcode field start
.otp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .input-wrapper {
    input {
      margin: 0px 5px;
    }
  }
}

.otp-input {
  width: 0px;
  height: 40px;
  text-align: center;
  font-size: 40px;
  margin: 0 10px;
  border: none !important;
  border-bottom: 2px solid $border-light !important;
  box-shadow: none;
  background-color: transparent;
  color: $absolute-black;
  transition: all 0.3s ease-in-out;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 0px;
}

.otp-input:focus {
  outline: none;
  border-bottom-color: $blue;
}
//passcode field stop
img.emailicon {
  width: 80%;
  margin-top: 20px;
}
.input-wrapper {
  input {
    width: 100% !important;
  }
}
.show-icon {
  position: absolute;
  right: 18%;
  margin-top: 13px;
  @media (min-width: 767px) and (max-width: 1200px) {
    right: 14%;
  }
  @media (max-width: 767px) {
    right: 18%;
  }
  @media (max-width: 450px) {
    right: 14%;
    margin-top: 14px;
  }
}
.m-size {
  width: 99%;
  @media (max-width: 767px) {
    width: 93%;
  }
  @media (max-width: 430px) {
    width: 96%;
  }
}
@media (max-width: 767px) {
  .btnsection {
    width: 100%;
    padding-top: 0px;
    padding-bottom: 20px;
    margin-top: 20px;
    //margin: 0px auto;
  }
}
.input-reject::after {
  top: 5px;
  font-size: 20px;
  font-weight: 900;
}
.input-accept::after {
  top: 0px;
  font-size: 25px;
  font-weight: 900;

  @media (max-width: 450px) {
    top: 5px;
    left: 8px;
  }
}
.error {
  font-size: 12px;
}
.txtsubfont {
  font-size: 14px;
  font-weight: bold;
  max-width: 200px;
  letter-spacing: 1px;
}
.instructions {
  span {
    font-size: 0.8rem;
    font-weight: 500;
  }
}
.instructions {
  font-size: 11px;
  min-width: 350px;
  margin-bottom: 20px;
  margin-left: 5px;
  @media (max-width: 767px) {
    min-width: auto;
    max-width: 320px;
  }
}
.txtalign {
  text-align: center;
}
.matchicon {
  position: absolute;
  margin-top: 20px;
  font-size: 10px;
  font-weight: bold;
  color: $green;
  padding-top: 2px;
  @media (max-width: 450px) {
    margin-top: 24px;
  }
}
.go-green {
  color: $green;
  font-weight: bold;
  font-size: 12px;
}
.go-normal {
  color: $gray;
  font-weight: normal;
  font-size: 12px;
}
.btnbottom {
  margin: 45px 0px 30px;
}
.invalidsecion {
  text-align: center;
  padding-right: 16px;
  margin-top: 10px;
  letter-spacing: 0.1px;
  font-size: 15px;
}
.otpcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.otpimage {
  flex-basis: 40%;
  align-self: flex-start;
}
.otpimage img {
  // max-width: 100%;
  height: 60px;
  margin-top: 10px;
  @media (max-width: 767px) {
    padding-left: 10px;
  }
}
.otptext {
  font-size: 20px;
  padding-left: 10px;
}
.inputerror {
  margin: 10px 0px;
}
@media (min-width: 767px) and (max-width: 970px) {
  .field .input-wrapper input {
    padding: 0px;
  }
  .otp-input {
    font-size: 18px;
  }
  .passcodeimg img {
    margin-top: 20px;
  }
  .button-resend {
    font-size: 12px;
  }
}
@media (min-width: 480px) and (max-width: 550px) {
  img.emailicon {
    width: 65%;
  }
  .passcodetxt {
    width: 60%;
    margin-top: 17px;
  }
}
@media (min-width: 551px) and (max-width: 766px) {
  .passcodeimg {
    width: 20%;
  }
  .img.emailicon {
    width: 85%;
  }
  .passcodetxt {
    width: 60%;
    margin-top: 15px;
  }
}
@media (max-width: 480px) {
  .passcodetxt {
    margin-top: 15px;
  }
}
@media (max-width: 368px) {
  .field-label {
    font-size: 13px;
  }
}
.field-label {
  margin-top: 15px;
  margin-bottom: 1px;
}
.m-top {
  margin-top: 15px;
}
.button-login {
  background-color: #fff0;
  text-transform: uppercase;
  color: $orange;
  font-weight: 700;
  border-bottom: 1.5px solid $orange;
  border-radius: 0px;
  text-align: center;
}
.errorslot {
  text-align: left;
  margin-top: 10px;

  .error {
    text-align: center;
    margin-top: 5px;
    @media (max-width: 767px) {
      font-size: 13px;
      text-align: center;
      margin-top: 15px;
      margin-left: -35px;
      max-width: 300px;
      letter-spacing: 0.5px;
    }
  }
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.b-form-datepicker {
  margin-top: 3px;
}

.radio-input {
  padding-bottom: 15px;
}

.rowcontainer {
  width: 100%;
  .rows {
    width: 100%;

    .left {
      width: 60%;
      float: left;
    }
    .right {
      width: 35%;
      float: right;
    }
    .field {
      width: 100%;
    }
  }
}
@media (max-width: 767px) {
  .rowcontainer {
    width: 95% !important;
    .rows {
      .left {
        width: 110% !important;
      }
      .right {
        width: 100% !important;
      }
      .field {
        width: 99% !important;
      }
    }
  }
}

.mrg-20 {
  margin-top: 20px !important;
}

.mrmin-10 {
  @media (max-width: 767px) {
    margin-right: -10px;
  }
}

main .field .input-wrapper input {
  @media (max-width: 767px) {
    height: 30px;
  }
}

.errwialign {
  @media (min-width: 768px) {
    margin-top: 18px;
    .error {
      margin-left: 0px;
      font-size: 11px;
    }
  }
}
</style>
