<template>
    <div class="container">
        <div class="ball-loader">
            <div class="ball-loader-ball ball1"></div>
            <div class="ball-loader-ball ball2"></div>
            <div class="ball-loader-ball ball3"></div>
        </div>
    </div>
</template>

<script>
export default {
  name: "loaderbar",
};
</script>

<style scoped lang="scss">
$width: 100px;
$animationDuration: 1s;
$color: #EE6323;

div#step4 .container{
  @media (max-width: 480px) {
  margin-bottom:10%;
  }
}

.container{
    width: 90%;
    margin: 0px auto;
    margin-bottom:10%;
  @media (max-width: 480px) {
  margin-bottom:28%;
  }

}
.ball-loader{
  text-align: center;
}

.ball-loader-ball{
  will-change: transform;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: $color;
  display: inline-block;
  animation: grow $animationDuration ease-in-out infinite alternate;
  transform-origin: 50% 50%;
  
  &.ball1{
    margin-right: $width / 11;
  }
  
  &.ball2{
    margin-right: $width / 11;
    animation-delay: - $animationDuration * .33;
  }
  
  &.ball3{
    animation-delay: - $animationDuration * .66;
  }
}

@keyframes grow{
  0%{
    transform: scale(1);
    background-color: #f7aa86;
  } 
  50%{
    background-color: rgb(246, 125, 59);
  }
  100%{
    transform: scale(.2);
  }
}
</style>
