import privacyPolicy from '@/config/text/privacyPolicy';
import caPrivacyPolicy from '@/config/text/caPrivacyPolicy';
import caPrivacyPolicyInfo from "@/config/text/caPrivacyPolicyInfo";

import aboutUs from '@/config/text/aboutUs';
import tcpa from '@/config/text/TCPA';
import sms from '@/config/text/SMS';
import termsConditions from '@/config/text/termsConditions';
import arbitrationAgreement from '@/config/text/arbitrationAgreement';
import consentforElectronicRecords from '@/config/text/consentforElectronicRecords';
import feeChart from '@/config/text/feeChart';
import privacyNotice from '@/config/text/privacyNotice';
import {$gMiddleLayerApi} from '../config/appconfigsettings';

const initialState = {
  showDocumentModal: false,
  documentTitle: null,
  documentText: null,
  applicationAgreementDoc: null,
  privacyPolicy: null,
  tcpa:null,
  sms:null,
  refreshdoc: true,
  docData: [],
  pdf1: [], 
  token:null,
  aboutUs:null,
  caPrivacyPolicy:null,
  caPrivacyPolicyInfo:null,
  docsLoading:false,
  termsConditions:null,
  arbitrationAgreement:null,
  feeChart:null,
  privacyNotice:null,
  consentforElectronicRecords:null

}
//import Vue from 'vue';
import axios from "axios";

export default {
  state: () => {
    return {
      ...initialState
    }
  },
  getters: {
  docPdf: state => state.pdf1
  },
  mutations: {
    reset(state) {
      Object.keys(state).forEach(key => {
        state[key] = initialState[key];
      })
    },
    updateShowDocumentModal(state, value) {
      state.showDocumentModal = value;
    },
    updateApplicationAgreementDoc(state, value) {
      state.applicationAgreementDoc = value
    },   
    updatePdf1(state, value) {
      state.pdf1 = value
    },    
    updateRefreshDoc(state, value){
      state.refreshdoc = value;
     },
     updateIsLoading(state,value){
      state.isLoading = value;
    },
    updateDocsLoading(state,value){
      state.docsLoading = value;
    }
  },
  actions: {
    async getDocument({state}, doc) {     
        var reqSection,borrowerName;
        var div = document.createElement('div');
        div.innerHTML = state.applicationAgreementDoc; 
        state.documentText = "This Agreement section is currently not available." //Default Value
        if(doc.indexOf('$')> -1)
        {
          var name = doc.split('$');
          doc = name[0];
           borrowerName = name[1];
       //   console.log(doc);
         // console.log(borrowerName);
        }
      switch (doc) {
        case 'loca':
          state.documentTitle = 'Line of Credit Application';
          reqSection = div.querySelector("div[data-docsection='LOC Agreement']");
          state.documentText = reqSection.innerHTML;
          break;
        case 'appl':
          state.documentTitle = 'Application Agreement';
          reqSection = div.querySelector("div[data-docsection='Application Agreement']");
          state.documentText = reqSection.innerHTML;
          break;
        case 'elec':
          state.documentTitle = 'Electronic Consent';
          reqSection = div.querySelector("div[data-docsection='Electronic Consent']");
          state.documentText = reqSection.innerHTML;
          break;
        case 'tcpa':
          state.documentTitle = 'TCPA Agreement';
          reqSection = div.querySelector("div[data-docsection='TCPA']");
          if (reqSection != null) {state.documentText = reqSection.innerHTML;} 
          else {state.documentText = tcpa;}
          break;
        case 'tc':
          state.documentTitle = 'Terms and Conditions';
         // reqSection = div.querySelector("div[data-docsection='SMS']");
          //if (reqSection != null) {state.documentText = reqSection.innerHTML;} 
          //else {state.documentText = sms;}
          state.documentText = termsConditions;
          break;
          case 'arbitrationAgreement':
            state.documentTitle = 'Arbitration Agreement';
           // reqSection = div.querySelector("div[data-docsection='SMS']");
            //if (reqSection != null) {state.documentText = reqSection.innerHTML;} 
            //else {state.documentText = sms;}              
            state.documentText =  arbitrationAgreement.replace('{CUSTOMER_NAME}', borrowerName).replace('{103442_ESIGNATURE_1}', borrowerName).replace('{103442_ESIGNATUREDATE_1}', new Date().toLocaleString());
            break;
        case 'priv':
          state.documentTitle = 'Privacy Policy';
          reqSection = div.querySelector("div[data-docsection='Privacy Policy']");
          if (reqSection != null) {state.documentText = reqSection.innerHTML;} 
          else {state.documentText = privacyPolicy;}          
          break;
          case 'privacy':
            state.documentTitle = 'Privacy Policy';
           state.documentText = privacyPolicy;       
            break;
        case 'agrarbitration':
            state.documentTitle = 'Arbitration Agreement';
            reqSection = div.querySelector("div[data-docsection='Arbitration Agreement']");
            state.documentText = reqSection.innerHTML;
            break;
        case 'agrprivacypolicy':
              state.documentTitle = 'Privacy Notice';
              //reqSection = div.querySelector("div[data-docsection='Privacy Policy']");
              state.documentText = privacyNotice;
              break;  
        case 'agreleconsent':
                state.documentTitle = 'Consent for Electronic Records';
                state.documentText = state.consentforElectronicRecords;
                break;  
         case 'agrcalprivacyact':
                  state.documentTitle = 'California Financial Information Privacy Act';
                  //reqSection = div.querySelector("div[data-docsection='Application Agreement']");
                  state.documentText = "<h1>California Financial Information Privacy Act</h1>"; //reqSection.innerHTML;
                  break;  
         case 'agrcredit':
                    state.documentTitle = 'Credit Agreement';
                    reqSection = div.querySelector("div[data-docsection='LOC Agreement']");
                    state.documentText = reqSection.innerHTML;
                    break;  
      
            }
      state.showDocumentModal = true;

      setTimeout(() => {
        document.getElementById("linkClose").focus();
      }, 100)
    },

async getDocs({state,commit }) {

 if(state.refreshdoc == true){
    commit('updateDocsLoading', true); 

      var auth = 'Bearer '+ this.getters.token;
      var input =   JSON.stringify( 
          {
           "pdfOnly":true
        })
      try{
      const documentsResponse = await  fetch( $gMiddleLayerApi+`Account/GetDocuments`, {    
      "method": "POST",
      "body": input,
      "headers": {
          'Content-Type': 'application/json',
          'Authorization':auth
      }})
      
      commit('updateRefreshDoc', false); 

      if (documentsResponse.ok) {
        const jVal = await documentsResponse.json(); 
        state.token = jVal.tokenToUse;     
      
        //Setting the Loan Document pdf
     let pdf1URL = "";
     let htm1URL = "";
     if (jVal.eDocumentsData != null) {     
          pdf1URL = jVal.eDocumentsData[0].eDocumentURL;     
         // htm1URL = jVal.eDocumentsData[0].eDocumentHtmURL;    
          }
        this.pdf1 = pdf1URL;
        //Loan Document Pdf
        commit('updatePdf1', this.pdf1);  
        if (state.applicationAgreementDoc == null && htm1URL.length>0)
        {         
          try{         
            const res = await axios.get(htm1URL,{"headers": {'Content-Type': 'text/html; charset=utf-8'}});
           // const res = await fetch(htm1URL, {"method": "GET","headers": {'Content-Type': 'text/html; charset=utf-8'}}); //'Does not work 
              var htmDoc = res.data;
              if (htmDoc !=null && htmDoc.length>0){
                //alert(htmDoc);
                commit('updateApplicationAgreementDoc', htmDoc);
              }                  
            } catch(ex){commit('updateDocsLoading', false); } 
      ``}             
      } 

      commit('updateDocsLoading', false);       
    } catch(ex){commit('updateDocsLoading', false); }
  }
 },

    async getPrivacyPolicy({state}) {     
      state.privacyPolicy = privacyPolicy;  
    },

    async getTCPA({state}) {     
      state.tcpa = tcpa;  
    },

    async getSMS({state}) {     
      state.sms = sms;  
    },

    async getAboutUs({state}) {     
      state.aboutUs = aboutUs;  // Static About Us.  Could refactor here to fetch from API if needed
    },
    async getCaPrivacyPolicy({state}) {     
      state.caPrivacyPolicy = caPrivacyPolicy;  // Static Privacy Policy.  Could refactor here to fetch from API if needed
    },
    async getCaPrivacyPolicyInfo({state}) {
      state.caPrivacyPolicyInfo = caPrivacyPolicyInfo; // Static CA Privacy Policy info. Brought over from Xact.com.  Could refactor here to fetch from API if needed
    },
    async getTermsConditions({state}) {     
      state.termsConditions = termsConditions;  // Static About Us.  Could refactor here to fetch from API if needed
    },
    async getArbitrationAgreement({state}) {     
      state.arbitrationAgreement = arbitrationAgreement;  // Static About Us.  Could refactor here to fetch from API if needed
    },
    async getFeeChart({state}) {     
      state.feeChart = feeChart;  // Static About Us.  Could refactor here to fetch from API if needed
    },
    async getConsentForElectricRecords({state}) {
      state.consentforElectronicRecords = consentforElectronicRecords;  },
  }
}