const initialState = {
  custId: null,
  requestId: null,
  phoneNumber: null,
  lineType: null,
  clientContext: null,
  ewSID: null,
  replyToUrl: null,
  passCode: null,
  token: null,
  mobileNumber: null,
  HomePhoneNumber: null,
  attemptNote: null,
  processId: null,
  processStatus: null,
  sentNumber: null,
  pinCheckResponse: null,
};

import {$gMiddleLayerApi} from '../config/appconfigsettings';
import { uuid } from "vue-uuid";

export default {
  state: () => {
    return {
      ...initialState,
    };
  },
  getters: {},
  computed: {},
  mutations: {
    resetSendEmailSMSData(state) {
      Object.keys(state).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    updateCustId(state, value) {
      state.custId = value;
    },
    updateProveToken(state, value) {
      state.token = value;
    },
    updateRequestId(state, value) {
      state.requestId = value;
    },
    updateCustomerId(state, value) {
      if (value != null) {
        state.custId = value.toString();
      }
    },
    updateProvePhoneNumber(state, value) {
      state.phoneNumber = value;
    },
    updateProveMobileNumber(state, value) {
      state.mobileNumber = value;
    },
    updateProveHomePhoneNumber(state, value) {
      state.HomePhoneNumber = value;
    },
    updateLineType(state, value) {
      state.lineType = value;
    },
    updateClientContext(state, value) {
      state.clientContext = value;
    },
    updateEwSID(state, value) {
      state.ewSID = value;
    },
    updateReplyToUrl(state, value) {
      state.replyToUrl = value;
    },
    updatePassCode(state, value) {
      state.passCode = value;
    },
    updateAttemptNote(state, value) {
      state.attemptNote = value;
    },
    updateProcessId(state, value) {
      state.processId = value;
    },
    updateProcessStatus(state, value) {
      state.processStatus = value;
    },
    updateVerifyMobileNo(state, value) {
      state.sentNumber = value;
    },
  },
  actions: {
    // Getting phone number type
    async checkenterednumberifcellnumber({ state }, { phonenumber }) {
      var input = JSON.stringify({
        requestId: state.custId,
        phoneNumber: phonenumber, //"4259997473"
      });
      var queryParams = "?custId=" + state.custId;
      //console.log(queryParams);
      //console.log(input);
      var auth = "Bearer " + state.token;
      //console.log(state.token)
      try {
        const response = await fetch(
          $gMiddleLayerApi + `Communications/Trust` + queryParams,
          {
            method: "POST",
            body: input,
            headers: {
              Accept: "application/json",
              Authorization: auth,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          //console.log("SUCCESS");
          var jVal = await response.json();
          //console.log("Trust response- " + jVal);
          if (jVal.response != null) {
            //console.log(jVal.response.lineType);
            return Promise.resolve(jVal.response);
          }
        } else {
          return Promise.reject("Call failed");
        }
        // var responseval = {
        //   requestId: "34534534",
        //   status: 0,
        //   description: "",
        //   carrierStatus: "",
        //   response: {
        //     transactionId: "12345",
        //     trustScore: 10,
        //     phoneNumber: "4045792875",
        //     payfoneAlias: "",
        //     lineType: "Landline",
        //     carrier: "",
        //     isBaselined: true,
        //   },
        // };
        // if (phonenumber == "4045792875") {
        //   responseval.response.lineType="Mobile";       
        // }
        // return Promise.resolve(responseval.response);
      } catch (ex) {
        //console.log(ex);
      }
    },

    async SendSMSOTP({ state, commit }, { mobileNumber }) {
      //console.log("Sending SMS....");
      var guid = uuid.v1();
      var auth = "Bearer " + state.token;
      var queryParams =
        "?phoneNumber=" +
        mobileNumber +
        "&clientContext=" +
        guid +
        "&CustId=" +
        state.custId;
      //console.log(queryParams);
      try {
        const smsResponse = await fetch(
          $gMiddleLayerApi +
            `Communications/SendSMSOTP` +
            queryParams,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: auth,
              "Content-Type": "application/json",
            },
          }
        );
        if (smsResponse.ok) {
          //console.log("SUCCESS");
          var jValsms = await smsResponse.json();
          
          //console.log("SMSOTP- "+jValsms.statusCode);
          commit("updateEwSID", jValsms.ewSID);
          commit("updateReplyToUrl", jValsms.replyTo);
          return Promise.resolve(jValsms.statusCode);
        } else {
          return Promise.reject("Call failed");
        }
        // var response = {
        //   clientId: "12345",
        //   clientContext: "123456",
        //   app: "string",
        //   ewSID: "123456",
        //   replyTo: "SMS Sent",
        //   timestampISO8601: "string",
        //   statusCode: "1",
        //   statusText: "string",
        // };
        // if (mobileNumber == "4045792875") {
        //   response.statusCode = "0";
        //   commit("updateEwSID", response.ewSID);
        //   commit("updateReplyToUrl", response.replyTo);
        // }
        // return Promise.resolve(response.statusCode);
      } catch (ex) {
        //console.log(ex);
      }
    },

    async PinDelivery({ state, commit }, { mobileNumber, passCode }) {
      //console.log("Calling..");
      var auth = "Bearer " + state.token;
      var guid = uuid.v1();
      var queryParams =
        "?pin=" +
        passCode +
        "&phoneNumber=" +
        mobileNumber +
        "&clientContext=" +
        guid +
        "&CustId=" +
        state.custId;
      //console.log("PassCode :" + passCode);
      try {
        const callResponse = await fetch(
          $gMiddleLayerApi +
            `Communications/PinDelivery` +
            queryParams,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: auth,
              "Content-Type": "application/json",
            },
          }
        );
        if (callResponse.ok) {
          //console.log("SUCCESS");
          var jValCall = await callResponse.json();
          //console.log(jValCall);
          commit("updateEwSID", jValCall.ewSID);
          commit("updateReplyToUrl", jValCall.replyTo);
          return Promise.resolve(jValCall.statusCode);
        } else {
          return Promise.reject("Call failed");
        }
        // if (mobileNumber == "4045792875") {
        //   var response = {
        //     clientId: "string",
        //     clientContext: "string",
        //     app: "string",
        //     ewSID: "string",
        //     replyTo: "string",
        //     timestampISO8601: "string",
        //     statusCode: "string",
        //     statusText: "string",
        //   };
        //   commit("updateEwSID", response.ewSID);
        //   commit("updateReplyToUrl", response.replyTo);
        //   return Promise.resolve(response.statusCode);
        //}
      } catch (ex) {
        //console.log(ex);
      }
    },

    async VerifySMSOTP({ state }, { passCode, sentNumber } ) {
      //console.log("Verifing OTP");
      var auth = "Bearer " + state.token;
      var queryParams =
        "?pin=" +
        passCode +
        "&url=" +
        state.replyToUrl +
        "&CustId=" +
        state.custId +
        "&ewSID=" +
        state.ewSID +
        "&mobileNumber=" +
        sentNumber;
      //console.log(queryParams);
      try {
        const verifyResponse = await fetch(
          $gMiddleLayerApi +
            `Communications/VerifySMSOTP` +
            queryParams,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: auth,
              "Content-Type": "application/json",
            },
          }
        );
        if (verifyResponse.ok) {
          //console.log("SUCCESS");
          var jValVerify = await verifyResponse.json();
          return Promise.resolve(jValVerify.statusCode);
        } else {
          return Promise.reject("Call failed");
        }
        // var response = {
        //   clientId: "string",
        //   clientContext: "string",
        //   app: "string",
        //   ewSID: "string",
        //   replyTo: "string",
        //   timestampISO8601: "string",
        //   statusCode: "1",
        //   statusText: "string",
        // };
        // if (passCode == "123456") {
        //   response.statusCode = "0";
        // }
        // return Promise.resolve(response.statusCode);
      } catch (ex) {
        //console.log(ex);
      }
    },

    async getinvalidpasswordcreationattemptcount({ state }) {
      var input = JSON.stringify({
        Note: state.attemptNote,
        ProcessId: state.processId,
        Success: state.processStatus,
        CustomerId: state.custId,
      });
      //console.log(input);
      var auth = "Bearer " + state.token;
      try {
        const response = await fetch(
          $gMiddleLayerApi + `Auth/LoginAttempts`,
          {
            method: "POST",
            body: input,
            headers: {
              Accept: "application/json",
              Authorization: auth,
              "Content-Type": "application/json",
            },
          }
        );
        //console.log(response);
        if (response.ok) {
          //console.log("SUCCESS");
          var jVal = await response.json();
          //console.log(jVal);
          return Promise.resolve(jVal);
        } else {
          return Promise.reject("Call failed");
        }
        // var response = {
        //   tokenToUse: state.token,
        //   success: true,
        //   attemptCount: 0,
        //   message: "Attempt count exceeded",
        // };
        // return Promise.resolve(response);
      } catch (ex) {
        //console.log(ex);
      }
    },
    async phoneCallVerify({ state }, { sentNumber, pinCheckResponse }) {
      //console.log("Calling..");
      var auth = "Bearer " + state.token;
      var queryParams =
        "?LogTypeId=1&request=" +
        sentNumber + "-" + pinCheckResponse;
      try {
        const callResponse1 = await fetch(
          $gMiddleLayerApi +
            `Communications/LogTransaction` +
            queryParams,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: auth,
              "Content-Type": "application/json",
            },
          }
        );
        if (callResponse1.ok) {
          //console.log("SUCCESS");
          var jValCall = await callResponse1.json();
          
          return Promise.resolve(jValCall.statusCode);
        } else {
          return Promise.reject("Call failed");
        }
       
        
      } catch (ex) {
        //console.log(ex);
      }
    },
  },
};
