<template>
  <v-form
    @submit="onLogin"
    :validation-schema="currentSchema"
    keep-values
    v-slot="{ errors }"
  >
    <div class="maincontent">
      <div class="login-box">
        <fieldset>
          <legend class="visually-hidden">Log in to your account.</legend>
          <ContentSectionTitle>
            Welcome to <XactFlexBrandedText />
            <template v-slot:subtitle>
              <h2 class="loginhead">Log in to your account.</h2></template
            >
          </ContentSectionTitle>
          <PortalLoader />
          <div :class="[validateLogout() ? 'logout' : '']">
            <span>{{ logoutMsg }}</span>
          </div>
          <div role="alert" class="error" v-html="errorMsg" />

          <FormField :errormsg="errors['email']">
            <template v-slot:label>
              <label for="loginemail">EMAIL</label>
            </template>

            <v-field
              id="loginemail"
              name="email"
              v-model.trim="email"
              type="email"
              aria-describedby="email-errormsg"
            />
            <template v-slot:error>
              <v-error id="email-errormsg" name="email" />
            </template>
          </FormField>

          <FormField :errormsg="errors['passwordcheck']" v-if="showPassword">
            <template v-slot:label>
              <label for="passwordchk">PASSWORD</label>
            </template>
            <v-field
              id="passwordchk"
              name="passwordcheck"
              type="password"
              v-model.trim="password"
              ref="passwordref"
              aria-describedby="password-errormsg"
            />
            <template v-slot:error>
              <v-error id="password-errormsg" name="passwordcheck" />
            </template>
          </FormField>

          <FormField :errormsg="errors['SSN']" v-if="showSoc">
            <template v-slot:label>
              <span>Verify last 4 of SSN to log in</span>
            </template>
            <input
              name="SSN"
              v-model.trim="social"
              @keydown="limitAlphabates"
              maxLength="4"
            />
            <template v-slot:error>
              {{ errors.SSN }}
            </template>
          </FormField>

          <div class="bottom">
            <div class="login-links">
              <router-link
                class="forgotpwd"
                :to="{
                  name: 'create-password',
                  query: { CalledFrom: 'CreatePwd' },
                }"
              >
                Create Password
              </router-link>
              <router-link
                class="forgotpwd"
                :to="{
                  name: 'forgot-password',
                  query: { CalledFrom: 'ForgotPwd' },
                }"
              >
                Forgot Password
              </router-link>
            </div>
          </div>
          <button
            class="button button-float login"
            alt="Log In"
            v-if="showPassword"
            style="margin-left: 50px"
          >
            LOG IN
          </button>
          <button
            class="button button-float"
            alt="continue"
            v-if="!showPassword"
          >
            CONTINUE
          </button>
        </fieldset>
      </div>
    </div>
  </v-form>
</template>

<script>
import {
  ContentSectionTitle,
  FormField,
  PortalLoader,
  XactFlexBrandedText,
} from "@/components";
import { limitNums } from "@/utils/plugins/inputs";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import * as Yup from "yup";
import { wrongPwdAttempt } from "@/store/login";

export default {
  name: "LoginMain",
  title: "Log In To Your Account",
  components: {
    ContentSectionTitle,
    FormField,
    PortalLoader,
    XactFlexBrandedText,
  },
  async created() {
    this.checkEmailCookie();

    if (this.loggedIn) {
      //console.log("loanStatus" + this.loanStatus);
      if (this.loanStatus == "G") {
        if (this.requiresEsignature) {
          this.$router.push({ name: "app-approved" });
        } else {
          this.$router.push({ name: "esign-contact" });
        }
      } else if (this.loanStatus == "W") {
        this.$store.commit("updateFinalizeCredit", true);
        this.$router.push({ name: "portal-interruptercredit" });
      } else {
        this.$router.push({ name: "portal-account" });
      }
    } else {
      this.$store.state.account.isLoading = false;
      await this.createToken();
      if (this.email) {
        this.checkLoginType();
        if (!this.invalidEmail) {
          this.showPassword = true;
          this.currentStep = 1;
        }
      }
      if (this.ip == null) {
        this.findIP();
      }
    }
    this.$store.state.login.errorMsg = "";
  },
  data() {
    let EMAIL_REGX = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/;
    let emailerrormsg =
      "Please enter your email address in this format: name@example.com";
    const schema = [
      Yup.object().shape({
        email: Yup.string()
          .required(emailerrormsg)
          .matches(EMAIL_REGX, emailerrormsg),
      }),
      Yup.object().shape({
        email: Yup.string()
          .required(emailerrormsg)
          .matches(EMAIL_REGX, emailerrormsg),
        passwordcheck: Yup.string().required("Please enter a valid password"),
      }),
    ];
    return {
      schema,
      currentStep: 0,
      password: null,
      social: null,
      showPassword: false,
      multiTenant: false,
      token: null,
      wrongPwdAttempt: wrongPwdAttempt,
    };
  },
  computed: {
    ...mapState({
      showSoc: (state) => state.login.showSoc,
      errorMsg: (state) => state.login.errorMsg,
      logoutMsg: (state) => state.login.logoutMsg,
    }),
    ...mapGetters({ loggedIn: "loggedIn" }),

    isFormValid: function () {
      return !Object.values(this.fields).filter((field) => !field.valid).length;
    },
    currentSchema: {
      get() {
        return this.schema[this.currentStep];
      },
    },
    email: {
      get() {
        return this.$store.state.login.email;
      },
      set(value) {
        this.$store.commit("updateLoginEmail", value);
      },
    },
    last4SSN: {
      get() {
        return this.$store.state.login.last4SSN;
      },
      set(value) {
        this.$store.commit("updateLoginSocial", value);
      },
    },
    loanStatus: {
      get() {
        return this.$store.state.login.loanStatus;
      },
      set(value) {
        this.$store.commit("updateLoanStatus", value);
      },
    },
    requiresEsignature: {
      get() {
        return this.$store.state.login.requiresEsignature;
      },
      set(value) {
        this.$store.commit("updateRequiresEsignature", value);
      },
    },
    firstName: {
      get() {
        return this.$store.state.login.firstName;
      },
      set(value) {
        this.$store.commit("updatefirstName", value);
      },
    },
  },
  mounted() {
    this.focusfirstelement();
  },
  methods: {
    ...mapMutations({
      checkLoginType: "checkLoginType",
      checkEmailCookie: "checkEmailCookie",
      updateInvalidEmail: "updateInvalidEmail",
      updateLoggedIn: "updateLoggedIn",
      updateInvalidLogin: "updateInvalidLogin",
      updateAccountLocked: "updateAccountLocked",
      updatePasswordResetting: "updatePasswordResetting",
    }),
    ...mapActions({
      createToken: "createToken",
      findIP: "findIP",
      validateEmail: "validateEmail",
    }),
    focusfirstelement() {
      document.getElementById("loginemail").focus();
    },

    validateLogout: function () {
      if (
        this.logoutMsg == undefined ||
        this.logoutMsg == null ||
        this.logoutMsg === ""
      )
        return false;
      else return true;
    },
    async enter() {
      if (this.showSoc) {
        return;
      } else {
        await this.login();
      }
    },

    async onLogin() {
      this.$store.commit("updatePasswordResetTokenExpired", false);
      this.$store.commit("updateLogout", false);
      //this.$validator.validate();
      //if (this.isFormValid) {
      this.$store.state.account.isLoading = true;
      await this.createToken();
      if (this.showPassword && this.password != null) {
        const result = await this.$store.dispatch("Login", this.password);
        this.$store.state.account.isLoading = false;
        if (result.customerLoginResults == 1 && result.id > 0) {
          this.$store.state.account.customerId = result.custId;
          this.$store.state.account.token = result.tokenToUse;
          this.updateInvalidLogin(false);
          this.updateAccountLocked(false);
          this.updatePasswordResetting(false);
          this.$store.commit("updateUserId", result.id);
          this.$store.commit("updateSessionId", result.sessionId);
          this.updateLoggedIn(true);
          this.$store.commit("updatefirstName", result.firstName);
          this.$store.commit("updateToken", result.tokenToUse);

          if (result.loanStatus == "G") {
            this.$store.commit("updateLoanStatus", "G");
            this.$store.state.esign.firstDrawAmount = result.firstDrawAmount;
            this.$store.commit("updatefirstDrawAmount", result.firstDrawAmount);
            this.$store.state.esign.tokenID = result.tokenToUse;
            if (result.hasValidEsignatures) {
              this.$store.commit("updateRequiresEsignature", false);
              this.$router.push({ name: "esign-contact" });
            } else {
              this.$store.commit("updateRequiresEsignature", true);
              this.$router.push({ name: "app-approved" });
            }
          } else if (result.loanStatus == "W") {
            this.$store.commit("updateLoanStatus", "W");
            this.$store.commit("updateFinalizeCredit", true);
            this.$router.push({ name: "portal-interruptercredit" });
          } else {
            this.$store.state.account.finalizeCredit = false;
            this.$router.push({ name: "portal-account" });
            setTimeout(() => {
              document.getElementById("welcomeback").focus();
            }, 4000);
          }
        } else if (result.customerLoginResults == 10) {
          this.updateInvalidLogin(false);
          this.updatePasswordResetting(false);
          this.updateAccountLocked(true);
          this.checkLoginType();
          //this.$store.dispatch("checkLoginType");
        } else if (result.customerLoginResults == 6) {
          this.updateInvalidLogin(false);
          this.updatePasswordResetting(false);
          this.updateAccountLocked(true);
          this.checkLoginType();
          // this.$store.dispatch("checkLoginType");
        } else {
          if (localStorage.email == this.email) {
            var h = parseInt(localStorage.loginAttempt) + 1;
            localStorage.loginAttempt = h;
            if (h >= wrongPwdAttempt) {
              this.updatePasswordResetting(false);
              this.updateInvalidLogin(false);
              this.updateAccountLocked(true);
              this.checkLoginType(false);

              // console.log("Account Locked");
            } else {
              this.updatePasswordResetting(false);
              this.updateAccountLocked(false);
              this.updateInvalidLogin(true);
              this.checkLoginType(false);
              // console.log("Invalid Login 1");
            }
          } else {
            localStorage.email = this.email;
            localStorage.loginAttempt = 1;
            this.updatePasswordResetting(false);
            this.updateAccountLocked(false);
            this.updateInvalidLogin(true);
            this.checkLoginType(false);
            // console.log("Invalid Login");
          }
        }
      } else {
        const res = await this.validateEmail();
        this.$store.state.account.isLoading = false;
        if (res != undefined) {
          this.$store.state.login.token = res.tokenToUse;

          if (res.validEmail) {
            this.updateInvalidEmail(false);
            var hasRegisteredUsers = false,
              i;
            for (i = 0; i < res.users.length; i++) {
              if (res.users[i].userId > 0) {
                hasRegisteredUsers = true;
                break;
              }
            }

            if (!hasRegisteredUsers) {
              this.$router.push({ name: "create-password" });
            } else {
              this.showPassword = true;
              this.currentStep = 1;

              this.$nextTick(() => {
                document.getElementById("passwordchk").focus();
              });

              if (res.multiUser) {
                // this.multiTenant = true;
                this.multiTenant = false;
              } else {
                this.multiTenant = false;
                this.$store.commit("updateLoginSocial", res.users[0].custSSN);
              }
              // this.$store.dispatch("checkLoginType", this.multiTenant);
              this.checkLoginType(false);
            }
          } else {
            this.updateInvalidEmail(true);
            this.checkLoginType(false);
            this.showPassword = false;
          }
        }
      }
      //}
    },

    limitNums,
  },
};

// Validator.localize({
//   en: {
//     custom: {
//       passwordcheck: {
//         required: "Please enter a valid password",
//       },
//     },
//   },
// });
</script>

<style scoped lang="scss">
.maincontent::before {
  background-image: url("../../assets/backgrounds/xactflex-hero-background@2x.jpg");
}

.login-logo {
  height: 38px;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input {
  border: solid 1px #dedfdd;
  padding: 3px 5px 3px 5px;
}

.loginhead {
  margin-left: -1px !important;
  margin-bottom: 10px !important;
  font-size: 16pt !important;
}

.button-float {
  margin-left: 30px;
}

#content {
  margin-bottom: 0px;
  @media (max-width: 922px) {
    margin: 70px 0 0 0;
  }
}

form {
  a:hover {
    color: $red;
  }
  .login-box {
    h1 {
      margin-left: -4px;
      font-size: 16pt !important;
    }
  }
  #loginemail:focus {
    outline: 2px solid $blue;
  }
  .section-title {
    margin-left: 7px !important;
    margin-bottom: 0px !important;

    h1 {
      margin-left: -2px !important;
    }

    .subtitle {
      text-transform: none;
    }
    @media (max-width: 767px) {
      margin-bottom: 0px !important;
    }
  }

  .password-show-icon {
    position: absolute;
    margin: 10px 270px;
    color: $gray;
    text-align: center;

    i {
      height: 14px;
      width: 25px;
    }
  }

  .logout {
    padding-top: 0px;
    padding-bottom: 10px;
    padding-left: 0px;
    font-size: 14px;
    color: $red;
    font-weight: bold;
  }

  .login {
    top: 94%;
    @media (max-width: 1023px) {
      top: 94%;
    }
  }
  .bottom {
    width: 100%;

    .remember-me {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      height: 34px;

      .remember-me-box {
        margin-left: 46px;
        color: $gray;
      }

      img {
        height: 34px;
        cursor: pointer;
      }
    }

    .login-links {
      display: flex;
      flex-flow: row nowrap;
      //justify-content: right;
      align-items: center;
      font-size: 12px;
      padding: 15px 0px;
      //margin-right: 15px;
      //float: right;
      color: $red;
      width: 90%;
      justify-content: space-between;
      margin: 0px auto;
    }

    .forgotpwd {
      font-size: 14px;
      border-bottom: none;
      font-weight: 700;
    }
  }

  @media (min-width: 767px) and (max-width: 992px) {
    .login-box {
      padding: 30px 70px 50px 70px;
    }
  }
}
</style>