<template>
  <div class="bgcontainer">
    <div class="bglayout">
      <div class="helloAcct">
        <div class="clsh6">Welcome back, {{ firstName }}</div>
      </div>     

      <main class="portal-wrapper">
        <PortalNav page="account" />
        
        <div class="portal cardPortal card">
          <h1 class="visually-hidden">Your Account</h1>
          <PortalLoader />          
          <!-- <PortalMessage class="hide-large" page="account"/> -->
          <PortalAccountSum />

          <div v-if="!showDocumentDetails && !showTransactionDetails">
            <div class="wide">
              <PortalTable
                :columns="recentTransactionsColumns"
                :data="recentTransactionsTableData"
              >
                <div class="table-title">
                  <div class="title">Recent Transactions</div>
                  <a
                    tabindex="0"
                    v-if="allTransactionsTableData.length > 0"
                    @keypress.enter="openTransactionDetails()"
                    @click="openTransactionDetails()"
                    class="no-border"
                    aria-label="View all Recent Transactions"
                  >
                    View all
                  </a>
                </div>

                <span
                  class="staticText"
                  v-if="recentTransactionsTableData.length === 0"
                  >There are no recent transactions to display at this
                  time.</span
                >
              </PortalTable>
            </div>

            <div class="pair">
              <div class="left">
                <PortalTable
                  :columns="billingStatementsColumns"
                  :data="billingStatementsTableData"
                >
                  <div class="table-title">
                    <div class="title">Billing Statements</div>
                    <a
                      aria-label="View all Billing Statements"
                      tabindex="0"
                      @keypress.enter="openDocumentDetails()"
                      @click="openDocumentDetails()"
                      class="no-border"
                    >
                      View all
                    </a>
                  </div>

                  <span
                    class="staticText"
                    v-if="billingStatementsTableData.length === 0"
                    >There are no statements to display at this time.</span
                  >
                </PortalTable>
              </div>
              <div class="right">
                <div class="portal-call-button cardNew">
                  <img
                    src="../../assets/icons/questions.png"
                    alt="help icon"
                    class="call-icon"
                  />
                  <div v-if="!chargeoff && !delinquent">
                    <div class="clsh3">Questions?</div>
                    <div class="clsh5">
                      Call Us:
                      <a
                        class="no-border no-hover de-border"
                        :href="'tel:' + companyInfo.contactPhone"
                        >{{ companyInfo.contactPhone }}</a
                      >
                    </div>
                  </div>
                  <div v-else>
                    <div class="clsh3">Questions</div>
                    <div class="clsh5">
                      Talk to an Account <br />
                      Specialist:
                      <a
                        class="no-border no-hover de-border"
                        :href="
                          'tel:' + companyInfo.advancedCustomerServicePhone
                        "
                        >{{ companyInfo.advancedCustomerServicePhone }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="portal-details" v-else>
            <div class="tabs">
              <div role="tablist" aria-labelledby="tablist-1" class="pair">
                <button
                  id="tab-1"
                  type="button"
                  role="tab"
                  class="left"
                  aria-selected="true"
                  aria-controls="tabpanel-1"
                >
                  <span class="focus"> Transactions </span>
                </button>
                <button
                  id="tab-2"
                  type="button"
                  role="tab"
                  aria-selected="false"
                  aria-controls="tabpanel-2"
                  tabindex="-1"
                >
                  <span class="focus"> Documents </span>
                </button>
                <div class="closeDiv" @click="closeAllDetails()">
                  <img src="../../assets/icons/close.png" />
                </div>
              </div>

              <div id="tabpanel-1" role="tabpanel" aria-labelledby="tab-1">
                <div>
                  <!-- <div class="expander" v-if="pendingTransactionsTableData"> -->
                  <div class="expander">
                    <div class="title">
                      <div class="numInProg">
                        {{ pendingTransactionsTableData.length }}
                      </div>
                      <div class="static-part">
                        <div class="staticText" v-if="expandPayments">
                          Payments In Process & Future One-Time Payments<br />
                          <span>
                            This section includes automatic payments that are
                            currently in process and <br />
                            <span
                              >one-time payments that you've scheduled.</span
                            >
                          </span>
                        </div>
                        <div class="staticText" v-else>
                          Payments In Process <br />
                          & Future One-Time Payments
                        </div>
                        <div
                          class="icons"
                          v-if="pendingTransactionsTableData.length > 0"
                          @click="expandPayments = !expandPayments"
                          :class="expandPayments ? 'lengthened' : null"
                        >
                          <a v-if="expandPayments">Hide Details</a>
                          <a v-else>See Details</a>
                        </div>
                      </div>
                    </div>
                    <div v-show="expandPayments" class="note">
                      <div class="pendingWrapper">
                        <div
                          class="pendTrans"
                          v-for="(pend, index) in sortedArray"
                          :key="index"
                        >
                          {{ date(pend.date) }} <span class="gray">|</span>
                          {{ currencyLong(pend.pendingAmt) }}
                          <span class="gray">|</span> EFT Payment
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <PortalTransactionTable
                  :columns="allTransactionsColumns"
                  :data="filteredTransactionTableData"
                >
                  <div class="table-title">
                    <a
                      aria-label="Filter by All Completed Transactions"
                      class="all border-hover"
                      :class="activeFilter === 'all' ? 'active' : null"
                      tabindex="0"
                      @keypress.enter="filterTransactions('all')"
                      @click="filterTransactions('all')"
                    >
                      <img
                        v-if="activeFilter === 'all'"
                        src="../../assets/icons/trans_all.png"
                      />
                      <img
                        v-else
                        src="../../assets/icons/trans_all_inactive.png"
                      />
                      <span class="super">All Completed Transactions</span>
                    </a>
                    <a
                      aria-label="Filter by Draws"
                      class="filter border-hover"
                      :class="activeFilter === 'draw' ? 'active' : null"
                      tabindex="0"
                      @keypress.enter="filterTransactions('draw')"
                      @click="filterTransactions('draw')"
                    >
                      <img
                        v-if="activeFilter === 'draw'"
                        src="../../assets/icons/trans_draws.png"
                      />
                      <img
                        v-else
                        src="../../assets/icons/trans_draws_inactive.png"
                      />
                      <span class="super">Draws</span>
                    </a>
                    <a
                      aria-label="Filter by Payments"
                      class="filter border-hover"
                      :class="activeFilter === 'payment' ? 'active' : null"
                      tabindex="0"
                      @keypress.enter="filterTransactions('payment')"
                      @click="filterTransactions('payment')"
                    >
                      <img
                        v-if="activeFilter === 'payment'"
                        src="../../assets/icons/trans_payments.png"
                      />
                      <img
                        v-else
                        src="../../assets/icons/trans_payments_inactive.png"
                      />
                      <span class="super">Payments</span>
                    </a>
                  </div>

                  <span
                    class="staticText"
                    v-if="filteredTransactionTableData.length === 0"
                    >There are no transactions to display at this time.</span
                  >
                </PortalTransactionTable>
              </div>
              <div
                id="tabpanel-2"
                role="tabpanel"
                aria-labelledby="tab-2"
                class="is-hidden"
              >
                <PortalTable
                  :columns="documentsColumns"
                  :data="documentsTableData"
                >
                  <div class="expander">
                    <div class="title">
                      <div class="staticText">Line Of Credit Agreement</div>

                      <div class="icons">
                        <i
                          v-for="(row, index) in lineOfCreditAgreement"
                          :key="index"
                        >
                          <i
                            v-for="(column, index) in documentsColumns"
                            :key="index"
                            :class="column"
                          >
                            <a
                              v-if="column == 'download/view'"
                              @click="downloadFile(row[column])"
                              tabindex="0"
                              aria-label="Download/View Line Of Credit Agreement"
                            >
                              <i class="fas fa-file-pdf" /><br />Download/View
                            </a>
                          </i>
                        </i>
                      </div>
                    </div>
                  </div>

                  <div class="clsh4 staticText">Billing Statements</div>
                  <div class="clsh5 staticText" v-if="docsLoading">
                    Please wait while your statements are loaded.
                  </div>
                  <span
                    class="staticText"
                    v-else-if="documentsTableData.length === 0"
                    >There are no statements to display at this time.</span
                  >
                </PortalTable>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import {
  PortalNav,
  PortalAccountSum,
  PortalTable,
  // PortalMessage,
  PortalLoader,
  PortalTransactionTable,
} from "@/components";
import companyInfo from "@/config/text/company";
import { currencyLong } from "@/utils/filters/currency";
import { date } from '@/utils/filters/dates';
/*import { lvxScript,
          lvxURL } from "@/config/amounts";*/

export default {
  name: "PortalAccount",
  title: "Your Account",  
  components: {
    PortalNav,
    PortalAccountSum,
    PortalTable,
    // PortalMessage,
    PortalLoader,
    PortalTransactionTable,
  }, 
   mounted() {
    this.focusfirstelementOnPagewhenlanded();
  },
  async created() {
    this.showPartialSummary = false;

    if (this.$store.state.account.refreshaccount == true) {
      await this.$store.commit("updateIsLoading", true);
    }

    /*if (document.head.children["lvxChatScript"] == null && lvxScript != null && lvxScript != "") {
          let startScript = document.createElement('script');
          startScript.setAttribute('src', lvxURL);
          startScript.setAttribute('id', 'lvxChatInclude');
          startScript.onload = () => {
            let widgetScript = document.createElement('script');
            widgetScript.setAttribute('id', 'lvxChatScript');
            widgetScript.append(lvxScript);
            document.head.appendChild(widgetScript);
          };
          document.head.appendChild(startScript);
        }*/

    await this.$store.dispatch("getAccountSummaryInfo");
    if (this.$store.state.account.finalizeEsign == true) {
      this.$store.state.esign.tokenID = this.$store.state.account.token;
      this.$store.state.application.tokenID = this.$store.state.account.token;
      this.$router.push({ name: "app-approved" });
    }
    if (this.$store.state.account.finalizeApplication == true) {
      this.$store.state.esign.tokenID = this.$store.state.account.token;
      this.$store.state.application.tokenID = this.$store.state.account.token;
      this.$router.push({ name: "esign-contact" });
    }
    if (this.$store.state.account.finalizeEsign == true) {
      this.$store.state.esign.tokenID = this.$store.state.account.token;
      this.$store.state.application.tokenID = this.$store.state.account.token;
      this.$router.push({ name: "app-approved" });
    }
    if (this.$store.state.account.finalizeApplication == true) {
      this.$store.state.esign.tokenID = this.$store.state.account.token;
      this.$store.state.application.tokenID = this.$store.state.account.token;
      this.$router.push({ name: "esign-contact" });
    }
    if (this.$store.state.account.finalizeCredit) {
      this.$router.push({ name: "portal-interruptercredit" });
    }
    await this.$store.dispatch("getDocs");
    this.$store.state.portal.documentsTableData = await this.$store.dispatch(
      "getDocumentsTableData"
    );

    //Loader
    await this.$store.commit("updateIsLoading", false);
  },
  computed: {
    firstName: {
      get() {
        return this.$store.state.account.firstName;
      },
    },
    recentTransactionsTableData: {
      get() {
        return this.$store.state.account.recentTransactionsTableData;
      },
    },
    billingStatementsTableData: {
      get() {
        return this.$store.state.account.billingStatementsTableData;
      },
    },
    showTransactionDetails: {
      get() {
        return this.$store.state.portal.showTransactionDetails;
      },
    },
    showDocumentDetails: {
      get() {
        return this.$store.state.portal.showDocumentDetails;
      },
    },
    allTransactionsTableData: {
      get() {
        return this.$store.state.account.allTransactionsTableData;
      },
    },
    sortedArray: function () {
      return this.pendingTransactionsTableData.slice().sort(function (a, b) {
        return a.date > b.date ? 1 : -1;
      });
    },
    pendingTransactionsTableData: {
      get() {
        return this.$store.state.account.pendingTransactionsTableData;
      },
    },
    pendingPaymentType: {
      get() {
        return this.$store.state.account.pendingPaymentType;
      },
    },
    filteredTransactionTableData: function () {
      return this.activeFilter === "all"
        ? this.allTransactionsTableData
        : this.allTransactionsTableData.filter((item) =>
            item.description.toLowerCase().includes(this.activeFilter)
          );
    },
    documentsColumns: function () {
      return ["billing statements", "download/view"];
    },
    documentsTableData: {
      get() {
        return this.$store.state.portal.documentsTableData;
      },
    },
    docsLoading: {
      get() {
        return this.$store.state.documents.docsLoading;
      },
    },
    lineOfCreditAgreement: {
      get() {
        return this.$store.state.portal.lineOfCreditAgreement;
      },
    },
    lineOfCreditAgreementPDF: {
      get() {
        return this.$store.state.portal.lineOfCreditAgreementPDF;
      },
    },
    showPartialSummary: {
      get() {
        return this.$store.state.portal.showPartialSummary;
      },
      set(value) {
        this.$store.commit("updateShowPartialSummary", value);
      },
    },
    chargeoff: {
      get() {
        return this.$store.state.account.chargeoff;
      },
    },
    delinquent: {
      get() {
        return this.$store.state.account.delinquent;
      },
    },
  },
  methods: {
    focusfirstelementOnPagewhenlanded() {
         setTimeout(() => {
          document.getElementById("headerAccountPage").focus();
      }, 500);
    },

    InitializeTabs() {
      var tablists = document.querySelectorAll("[role=tablist]");
      for (var i = 0; i < tablists.length; i++) {
        this.tabs = [];

        this.firstTab = null;
        this.lastTab = null;
        this.tabs = Array.from(tablists[i].querySelectorAll("[role=tab]"));
        this.tabpanels = [];
        for (var z = 0; z < this.tabs.length; z += 1) {
          var tab = this.tabs[z];
          var tabpanel = document.getElementById(
            tab.getAttribute("aria-controls")
          );
          tab.tabIndex = -1;
          tab.setAttribute("aria-selected", "false");
          this.tabpanels.push(tabpanel);

          tab.addEventListener("keydown", this.onKeydown.bind(this));
          tab.addEventListener("click", this.onClick.bind(this));

          if (!this.firstTab) {
            this.firstTab = tab;
          }
          this.lastTab = tab;
        }

        this.setSelectedTab(this.firstTab, false);
      }
    },
    setSelectedTab(currentTab, setFocus) {
      if (typeof setFocus !== "boolean") {
        setFocus = true;
      }
      for (var i = 0; i < this.tabs.length; i += 1) {
        var tab = this.tabs[i];
        if (currentTab === tab) {
          tab.setAttribute("aria-selected", "true");
          tab.removeAttribute("tabindex");
          this.tabpanels[i].classList.remove("is-hidden");
          if (setFocus) {
            tab.focus();
          }
        } else {
          tab.setAttribute("aria-selected", "false");
          tab.tabIndex = -1;
          this.tabpanels[i].classList.add("is-hidden");
        }
      }
    },

    setSelectedToPreviousTab(currentTab) {
      var index;

      if (currentTab === this.firstTab) {
        this.setSelectedTab(this.lastTab);
      } else {
        index = this.tabs.indexOf(currentTab);
        this.setSelectedTab(this.tabs[index - 1]);
      }
    },

    setSelectedToNextTab(currentTab) {
      var index;

      if (currentTab === this.lastTab) {
        this.setSelectedTab(this.firstTab);
      } else {
        index = this.tabs.indexOf(currentTab);
        this.setSelectedTab(this.tabs[index + 1]);
      }
    },

    /* EVENT HANDLERS */

    onKeydown(event) {
      var tgt = event.currentTarget,
        flag = false;

      switch (event.key) {
        case "ArrowLeft":
          this.setSelectedToPreviousTab(tgt);
          flag = true;
          break;

        case "ArrowRight":
          this.setSelectedToNextTab(tgt);
          flag = true;
          break;

        case "Home":
          this.setSelectedTab(this.firstTab);
          flag = true;
          break;

        case "End":
          this.setSelectedTab(this.lastTab);
          flag = true;
          break;

        default:
          break;
      }

      if (flag) {
        event.stopPropagation();
        event.preventDefault();
      }
    },
    onClick(event) {
      this.setSelectedTab(event.currentTarget);
    },
    openDocumentDetails() {
      this.$store.commit("updateShowDocumentDetails", true);
      this.$store.commit("updateShowTransactionDetails", false);
      setTimeout(() => {
        this.InitializeTabs();
        this.setSelectedTab(this.lastTab);
      }, 200);
    },
    openTransactionDetails() {
      this.$store.commit("updateShowDocumentDetails", false);
      this.$store.commit("updateShowTransactionDetails", true);
      setTimeout(() => {
        this.InitializeTabs();
        this.setSelectedTab(this.firstTab);
      }, 200);
    },
    closeAllDetails() {
      this.$store.commit("updateShowDocumentDetails", false);
      this.$store.commit("updateShowTransactionDetails", false);
    },
    filterTransactions(filter) {
      this.activeFilter = filter;
    },
    downloadFile(file) {
      let pdfName = file; //.innerHTML;
      window.open(pdfName, "_blank");
    },
  },
  data() {
    return {
      recentTransactionsColumns: ["date", "amount", "description", "trans. id"],
      billingStatementsColumns: ["date", "download/view"],
      companyInfo: companyInfo,
      expandPayments: false,
      allTransactionsColumns: ["date", "amount", "description", "trans. id"],
      activeFilter: "all" /*,
      lvxScript: lvxScript,
      lvxURL: lvxURL*/,
      tabs: [],
      firstTab: null,
      lastTab: null,
      tabpanels: [],
      currencyLong : currencyLong,
      date: date
    };
  },
};
</script>

<style scoped lang="scss">
.portal-wrapper {
  @media (max-width: 960px) {
    flex-flow: column nowrap !important;
    margin-top: 25px;
    .portal {
      margin: 0px 0px 20px 0px;
    }
  }
}

.helloAcct {
  display: none;

  @media (max-width: 1023px) {
    margin-top: 20px;
    text-align: center;
    display: block;
  }
}

.table-title {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 250px;
  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-evenly;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
  }

  a {
    color: $blue;
    font-size: 12px;
    text-decoration: underline;
  }

  .super {
    vertical-align: super;
    margin-left: 5px;
  }

  .filters {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    .filter {
      margin: 0 10px;
      font-size: 10px;
    }

    .divider {
      height: 20px;
      border-right: 1px solid $border;
    }

    //   .scrollWrapper{
    //   display: block;
    //   height: 450px;
    //   overflow: scroll;
    // }
    @media (max-width: 1023px) {
      flex-flow: row wrap;
    }
  }
}

.cardPortal {
  background: $absolute-white;
}

.pair {
  width: 95%;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.portal-details {
  margin-left: 40px;
  margin-right: 50px;
  @media (max-width: 768px) {
    margin: 0px;
  }

  .pair {
    // width:50%;
    @media (max-width: 768px) {
      width: 100%;
    }
    display: inline-block;
    position: relative;
    top: 3px;

    button {
      border-radius: 6px;
      background-color: $dark-gray;
      color: #989a9b;
      font-size: 22px;
      @media (max-width: 768px) {
        font-size: 18px;
      }
      font-weight: 500;
      width: auto;
      padding: 5px 15px;
      float: left;
      cursor: pointer;
    }

    div {
      border-radius: 6px;
      background-color: $dark-gray;
      color: #989a9b;
      font-size: 22px;
      @media (max-width: 768px) {
        font-size: 18px;
      }
      font-weight: 500;
      width: auto;
      padding: 5px 20px;
      float: left;
      cursor: pointer;
    }

    div.active {
      background-color: $blue;
      color: $absolute-white;
      box-shadow: 4px 6px 12px 0px rgb(0 0 0 / 50%);
    }
  }

  .cardNew {
    margin: 0px !important;
    padding: 0px 0px 25px 0px !important;

    .expander .title {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .expander .title .icons {
      top: -12px;
      left: 12px;
      display: inline-block;
      text-align: center;

      @media (max-width: 768px) {
        top: 0px;
        left: auto;
        width: auto;
        float: right;
      }
    }

    .expander .title .icons a {
      font-size: 12px;
    }

    .expander .title .icons a .fa-file-pdf {
      font-size: 24px;
    }

    .expander .title .icons i {
      font-style: normal;
    }
  }

  .closeDiv {
    cursor: pointer !important;
    float: right !important;
    color: $orange !important;
    font-size: 24px !important;
    font-weight: 600 !important;
    position: relative !important;
    right: 5px !important;
    background-color: transparent !important;
    @media (max-width: 768px) {
      top: 0px !important;
      right: 0px !important;
    }
  }

  .table-title {
    width: 65%;
    @media (max-width: 810px) {
      width: 100%;
      margin-top: 30px;
    }

    a {
      text-decoration: none;
      color: $black;
      margin-right: 15px;
      @media (max-width: 768px) {
        margin-right: 0px;
      }
    }

    a.active {
      color: $orange !important;
    }
  }
}

.pair .left {
  width: 55%;
  float: left;
  @media (max-width: 768px) {
    width: 100%;
    float: none;
  }
}

.pair .right {
  width: 45%;
  float: right;
  @media (max-width: 768px) {
    width: 100%;
    float: none;
  }
}

.call-icon {
  width: 40px;
  height: 40px;
  float: left;
  position: relative;
  top: 8px;
  margin-right: 10px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.bgcontainer {
  background-image: url(../../assets/backgrounds/xactflex-hero-background@2x.jpg);
  background-size: cover;
}
.bglayout {
  width: 85%;
  margin: 0px auto;
  @media (min-width: 414px) and (max-width: 428px) {
    width: 95%;
    // margin-left: -4%;
  }
  @media (min-width: 360px) and (max-width: 413px) {
    width: 95%;
    // margin-left: -5%;
  }
}
.expander {
  font-family: $font-primary; //$font-secondary;
  background-color: $light-gray;
  margin-bottom: 15px;

  .title {
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    padding: 10px 20px;
    @media (max-width: 966px) and (min-width: 961px) {
      font-size: 19px;
    }
    @media (max-width: 837px) and (min-width: 833px) {
      font-size: 19px;
    }
    @media (max-width: 768px) {
      display: flex;
      flex-direction: row;
      align-items: center;
      .static-part {
        display: flex;
        flex-direction: column;
      }
    }

    .numInProg {
      font-size: 40px;
      color: $orange;
      float: left;
      padding-right: 25px;
    }

    .staticText {
      display: inline-block;
      min-width: 252px;

      @media (min-width: 769px) and (max-width: 832px) {
        width: 300px;
      }

      @media (max-width: 768px) {
        width: 100%;
      }

      span br {
        @media (max-width: 832px) {
          visibility: hidden;
          display: none;
        }
      }
      // position: absolute;
      // top: 0;
      // left: 5px;
    }

    .staticText,
    .staticTextSmall {
      span {
        font-size: 12px;
        font-weight: 500;
        font-style: italic;
        line-height: 12px;
        position: relative;
        top: -5px;

        span {
          position: relative;
          top: -5px;

          @media (max-width: 832px) {
            position: revert;
          }
        }
      }
    }

    .staticTextSmall {
      font-size: 12px;
      font-weight: 500;

      @media (min-width: 961px) and (max-width: 1023px) {
        width: 400px;
      }

      @media (min-width: 769px) and (max-width: 890px) {
        width: 400px;
      }
    }

    .icons {
      float: right;
      position: relative;
      top: 10px;

      a {
        font-size: 16px;
        color: $orange;
        border-bottom: 1px solid $orange;
      }

      @media (max-width: 768px) {
        top: 0px;
      }
    }

    .icons.lengthened {
      @media (min-width: 1000px) and (max-width: 1051px) {
        top: -50px;
      }
      @media (min-width: 961px) and (max-width: 999px) {
        top: -30px;
      }
      @media (min-width: 876px) and (max-width: 921px) {
        top: -50px;
      }
      @media (min-width: 832px) and (max-width: 875px) {
        top: -30px;
      }
      @media (min-width: 769px) and (max-width: 832px) {
        top: 10px;
      }
    }
  }

  .note {
    max-width: 500px;
    margin: 4px 0 0 65px;
    font-family: $font-primary;
    padding-bottom: 20px;
  }

  .pendingWrapper {
    margin-left: 22px;
    font-family: $font-primary; //$font-secondary;
    max-height: 250px;
    overflow: hidden;
    overflow-y: auto;

    .pendTrans {
      padding: 0 0 5px 0;
      color: $orange;
      font-weight: 600;

      .gray {
        color: $gray;
      }
    }
  }

  .scrollWrapper {
    display: block;
    height: 450px;
    overflow: scroll;
  }
  @media (max-width: 1023px) {
    margin-top: 0;
  }
}

.clsh4 .staticText {
  margin-top: 30px;
}

.clsh5 .staticText {
  margin: 10px 0px 0px 25px;
}

.clsh5 .acctSpec {
  margin-left: 50px;
}

.portal-wrapper .portal .cardNew.portal-call-button {
  padding-right: 0px;
  margin-right: 0px;
  margin-top: 80px;
  @media (max-width: 768px) {
    margin: 20px auto;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .clsh3 {
    font-size: 20px;
  }
  .clsh5 {
    font-size: 18px;

    @media (min-width: 961px) and (max-width: 971px) {
      font-size: 16px;
    }

    @media (min-width: 784px) and (max-width: 842px) {
      font-size: 16px;
    }

    @media (min-width: 769px) and (max-width: 783px) {
      font-size: 15px;
    }
  }
}

.wide .portal-table.cardNew,
.pair .left .cardNew {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
}

span.staticText,
.clsh4 .staticText {
  @media (max-width: 768px) {
    padding-left: 10px;
  }
}

a:focus-visible {
  outline-color: $blue;
}

[role="tablist"] {
  min-width: 100%;
}

[role="tab"],
[role="tab"]:focus,
[role="tab"]:hover {
  display: inline-block;
  position: relative;
  z-index: 2;
  top: 2px;
  margin: 0;
  margin-top: 4px;
  padding: 3px 3px 4px;
  outline: none;
  font-weight: bold;
  overflow: hidden;
  text-align: left;
  cursor: pointer;
}

[role="tab"][aria-selected="true"] {
  background-color: $blue !important;
  color: $absolute-white !important;
  box-shadow: 4px 6px 12px 0px rgb(0 0 0 / 50%);
  border-bottom: 0px;
}

[role="tab"][aria-selected="false"] {
  border-bottom: 0px solid hsl(219deg 1% 72%);
}

[role="tab"] span.focus {
  display: inline-block;
  margin: 2px;
}

[role="tabpanel"].is-hidden {
  display: none;
}
</style>