// import index from "../api/index";

const initialState = {
  consentComplete: false,
  signatureComplete: false,
  creditLimit: null,
  minimumDraw: 100,
  drawIncrement: 25,
  electronicConsent: null,
  marketingCalls: null,
  marketingTexts: null,
  refuseDraw: false,
  firstDrawAmount: 0,
  militaryService: null,
  acceptTerms: false,
  borrowerName: null,
  loanRequestTransactionId: null,
  applicationId: null,
  signatureBody: null,
  loanAppDocumentID: null,
  loanAppDocumentTitle: null,
  loanAppDocEsigCount: null,
  cellPhone: null,
  mainPhone: null,
  paymentMethod: "direct-deposit",
  showPaperCheckModal: false,
  custEmail: null,
  custID: null,
  ip: null,
  tokenID: null,
  firstName: null,
  lastName: null,
  agreementdocname: "",
  IsAgreeArbdocterms: false,
  IsAgreeArbdoctermsDisabled: true,
  IsAgreePriPolicydocterms: false,
  IsAgreePriPolicydoctermsDisabled: true,
  IsAgreeEleConsentdocterms: false,
  IsAgreeEleConsentdoctermsDisabled: true,
  IsAgreeCalPrivacyActdocterms: false,
  IsAgreeCalPrivacyActdoctermsDisabled: true,
  IsAgreeCreditdocterms: false,
  IsAgreeCreditdoctermsDisabled: true,
  actualDrawAmount: 0,
  cashAdvanceFee: 0,
  cashAdvanceFeePercent: 0,
  IsPaperCheckAllowed: false,
  //2fA
  IsTwoFactorAuthenticationEnabled: false,
  esignchooseMethod: null,
  custdob: null,
  ssn: 0,
  esignvaliduserforcratepwd: false,
  esignpasswordCreationError: false,
  esignresendPasscode: false,
  esignaccountlockedpasswordCreationError: false,
  identityErrorMsg: null,
  emailNotFound: false,
  esignpasscodeInvalidCodeAttempt: false,
  esignpasscodeInvalidCodeSentAttempt: false,
  passcodeAttemptFailed:false,
  passcodeerrorMsg: null,
  successfullyCreated: false,
  reloadpage: true,
};

//import Vue from "vue";
import {$gMiddleLayerApi} from '../config/appconfigsettings';
import moment from "moment";
import loginText from "@/config/text/login";

export default {
  state: () => {
    return {
      ...initialState,
    };
  },
  getters: {},
  mutations: {
    resetEsign(state) {
      Object.keys(state).forEach((key) => {
        state[key] = initialState[key];
      });
      localStorage.esignstatePasscodeSession = 0;
      // localStorage.esignpasscodetimeout = 2;
      localStorage.esignverificationcodesenttime = 0;
      localStorage.esignTimeElapsed = 0;
    },
    updatereload(state, value) {
      state.reloadpage = value;
    },
    updateElectronicConsent(state, value) {
      state.electronicConsent = value;
    },
    updateMarketingCalls(state, value) {
      state.marketingCalls = value;
    },
    updateMarketingTexts(state, value) {
      state.marketingTexts = value;
    },
    updateRefuseDraw(state, value) {
      state.refuseDraw = value;
    },
    updateFirstDrawAmount(state, value) {
      state.firstDrawAmount = value;
    },
    updateMilitaryService(state, value) {
      state.militaryService = value;
    },
    updateAcceptTerms(state, value) {
      state.acceptTerms = value;
    },
    updatePasscodeSession(state, value) {
      localStorage.esignstatePasscodeSession = value;
    },
    updateBorrowerName(state, value) {
      state.borrowerName = value;
    },
    updateCreditLimit(state, value) {
      state.creditLimit = value;
    },
    updateDrawIncrement(state, value) {
      state.drawIncrement = value;
    },
    updateMinimumDraw(state, value) {
      state.minimumDraw = value;
    },
    updateLoanRequestTransactionId(state, value) {
      state.loanRequestTransactionId = value;
    },
    updateApplicationId(state, value) {
      state.applicationId = value;
    },
    updatedFetchedDocumentTitle(state, value) {
      state.loanAppDocumentTitle = value;
    },
    updateAppDocEsigCount(state, value) {
      state.loanAppDocEsigCount = value;
    },
    updateDocumentID(state, value) {
      state.loanAppDocumentID = value;
    },
    updateCellPhone(state, value) {
      state.cellPhone = value;
    },
    updateMainPhone(state, value) {
      state.mainPhone = value;
    },
    updatePaymentMethod(state, value) {
      state.paymentMethod = value;
    },
    updateCriditLimit(state, value) {
      state.creditLimit = value;
    },
    updateCustEmail(state, value) {
      state.custEmail = value;
    },
    updateCustID(state, value) {
      state.custID = value;
    },
    updateFirstName(state, value) {
      state.firstName = value;
    },
    updateLastName(state, value) {
      state.lastName = value;
    },
    updateActualDrawAmount(state, value) {
      state.actualDrawAmount = value;
    },
    updateCashAdvanceFee(state, value) {
      state.cashAdvanceFee = value;
    },
    updateCashAdvanceFeePercent(state, value) {
      state.cashAdvanceFeePercent = value;
    },
    updateIsPaperCheckAllowed(state, value) {
      state.IsPaperCheckAllowed = value;
    },
    updateIsTwoFactorAuthenticationEnabled(state, value) {
      state.IsTwoFactorAuthenticationEnabled = value;
    },
    updateShowPaperCheckModal(state, value) {
      state.showPaperCheckModal = value;
    },
    //2FA
    updateesignchooseMethod(state, value) {
      state.esignchooseMethod = value;
    },
    updateesignvaliduserforcrtpwd(state, value) {
      state.esignvaliduserforcratepwd = value;
    },
    updatedob(state, value) {
      state.custdob = value;
    },
    updatessn(state, value) {
      state.ssn = value;
    },
    usersuccessfullycreated(state, value) {
      state.successfullyCreated = value;
    },

    esignclear_identitymsg(state) {
      state.identityErrorMsg = "";
    },
    esignclear_passcodeerrmsg(state) {
      state.passcodeerrorMsg = "";
    },
    esignupdateAccountLockedPasswordCreationError(state, value) {
      state.esignaccountlockedpasswordCreationError = value;

      if (state.esignaccountlockedpasswordCreationError) {
        state.identityErrorMsg = loginText.accountlockedpasswordCreationError;
      } else {
        state.identityErrorMsg = "";
      }
    },
    esignupdateEmailNotFound(state, value) {
      state.emailNotFound = value;
      if (state.emailNotFound) {
        state.identityErrorMsg = loginText.noAccountFound;
      } else {
        state.identityErrorMsg = "";
      }
    },
    esignupdatePasscodeInvalidCodeAttempt(state, value) {
      state.esignpasscodeInvalidCodeAttempt = value;
      if (state.esignpasscodeInvalidCodeAttempt) {
        state.passcodeerrorMsg = loginText.esignpasscodeInvalidCodeAttempt;
      } else {
        state.passcodeerrorMsg = "";
      }
    },
    esignupdatePasscodeInvalidCodeSentAttempt(state, value) {
      state.esignpasscodeInvalidCodeSentAttempt = value;
      if (state.esignpasscodeInvalidCodeSentAttempt) {
        state.passcodeerrorMsg = loginText.passcodeInvalidCodeSentAttempt;
      } else {
        state.passcodeerrorMsg = "";
      }
    },
    esignupdatePasscodeAttemptFailed(state, value) {
      state.passcodeAttemptFailed = value;
      if (state.passcodeAttemptFailed) {
        state.passcodeerrorMsg = loginText.passcodeAttemptFailed;
      }
      else {
        state.passcodeerrorMsg = "";
      }
    },
    esignapplicationWithdrawnErrorMsg(state, value) {      
      if (value) {
        state.passcodeerrorMsg = loginText.applicationwithdrawn;
      }
      else {
        state.passcodeerrorMsg = "";
      }
    },
    esignupdatePasswordCreationError(state, value) {
      state.esignpasswordCreationError = value;
      if (state.esignpasswordCreationError) {
        state.identityErrorMsg = loginText.passwordCreationError;
      } else {
        state.identityErrorMsg = "";
      }
    },
    esignupdateResendPassCode(state, value) {
      state.esignresendPasscode = value;

      if (state.esignresendPasscode) {
        state.passcodeerrorMsg = loginText.resendPasscode;
      } else {
        state.passcodeerrorMsg = "";
      }
    },
    esignupdateverificationcodesenttime(state, value) {
      localStorage.esignverificationcodesenttime = value;
    },
    esignupdateTimeElapsed(state, value) {
      localStorage.esignTimeElapsed = value;
    },
  },
  actions: {
    // API Calls and subsequent mutations are made here
    async getEsignLoanDocument({ state, commit }) {
      var auth = "Bearer " + state.tokenID;
      var input = JSON.stringify({
        pdfOnly: false,
      });

      try {
        const res = await fetch(
          $gMiddleLayerApi + "Account/GetDocuments",
          {
            //for now just testing api hitting endpoint, will need to change this later to accept paths
            method: "POST",
            body: input,
            headers: {
              "Content-Type": "application/json",
              Authorization: auth,
            },
          }
        );

        if (res.ok) {
          const jVal = await res.json(); // Get JSON value from the response body

          const loanDocs = jVal.documentsData;

          state.tokenID = jVal.tokenToUse;
          commit("updateApplicationAgreementDoc", loanDocs[0].documentText);
          commit("updateDocumentID", loanDocs[0].documentId);
          commit("updatedFetchedDocumentTitle", loanDocs[0].documentTitle);
          commit("updateAppDocEsigCount", loanDocs[0].eSigCount);

          return Promise.resolve(jVal);
        } else {
          return Promise.reject("Loan document downloading failed");
        }
      } catch (ex) {
        //console.log(ex);
      }
    },

    async getAccountSummary({ state, commit }) {
      var auth = "Bearer " + state.tokenID;
      //auth = 'Bearer eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGVuYyNyc2Etb2FlcCIsImVuYyI6IkExMjhDQkMtSFMyNTYiLCJraWQiOiI5QzM0OTJFRkFFMDU0NTJBQzZDRjIyMUVGMDMwQjhFM0E4RTk0MUU4IiwidHlwIjoiSldUIn0.KJE47tKDIHWPMkEvxZqlON9TNZ1zn9mgPrEEdnfDui_xoUHJn4XeNeCt-l2J5E0sbEVIb8xeUE-lkAFL2OfE8r6ctU8Uf1UyfmXPW8nD5WPOMqHwJirQrsjvhKKTqC2P1IMJFCNX9EAqnQ0LEgET3x5__i36a_g625dolqMtlLw9mRZitCTcYa8eduR9htydiZdK-s7i-38Q8bJ7zxUQPEMu65TeI8uJB6epvh0O0H6fHUr0vKh4U-zSihpep4BJCGwjKZUQFKWwDUCC9pRENTwEy1Y6d_2y5j_0DIPBapOi5vRgOAHuYcW0VLJaza4T_Af_azwsVckTAgX8GimQnw.n-GoQpLtI8Xsk5qk0adBcA.FObrcXbUWSixfCZ_45P-x2wdEdX-Uem-MNZOHbCIcRxyPjQmVdXcztNid5ikVLktgp9fwuj2czY0NzzwhVxbkefzuNTXNvHyUa0Qbq4-IUiEUGNxdIknIef9Y9Fss5OviV9VnL5G7DptZN2V6YZY6nfI193L39e656NlCG4oYYlKAnMr8FVU2kgeufBbDNFLTOdSzs_yLWcLIWodP9YhtAdg0BgwYtWbAZqfaXpMHLhqtaElYAsXAybgwQWYcTnArWpyWpdm2xBOIHCfmJpiaeDbMZW3n38YFPwyD0zW5-nWihDOLlP4s0xN3MRRI6zRAxHXlCBlT3zHVyEWa5RFQqWdDG4dOUpl8GYoAWJv37jM66hr99vtN00LoWWkD3L5B_tXsF7gURL9ganA6nnmE1YjFD5jxNZS1L1ufZEHAU3kE0H40WvOZ_Scz7g8zSDw6AAUFx54xpBpCo4qgMwOf6CjvjU0XBgljEWBZH2BIIZZgVjHm7hmElhTMbPDLy6FWRMekIXedytMcZB92MbolbQRp3ruWpXtDHbB870cAcjl78-vQGJ8FtwRrB7sXqlPDVDgzNKc0VkmXWuIDdOPrwX_rzeAT8Z04Myr2XzJ5cFgxYNPtqjX_tqVDbdQ9-4Ar9qaNdYpOZAfEeTrq9V7uLhgYdY6oeJvNIha1SbYJsrsCYrR-xp6l63Dt3fB9Wed48au28-XuTzrFYtdoGRBJA.mEopZRi-KgU_Mq8KTCYU2A';
      state.marketingTexts = null;
      state.marketingCalls = null;
      state.electronicConsent = null;
      state.refuseDraw = false;
      state.militaryService = null;
      state.acceptTerms = false;
      state.borrowerName = null;
      state.paymentMethod = "direct-deposit";
      state.successfullyCreated = false;

      try {
        const res = await fetch(
          $gMiddleLayerApi + "Account/GetSummary",
          {
            //for now just testing api hitting endpoint, will need to change this later to accept paths
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: auth,
            },
          }
        );

        if (res.ok) {
          const jVal = await res.json(); // Get JSON value from the response body

          if (jVal.accountHeader.creditLimit != null) {
            commit("updateCreditLimit", jVal.accountHeader.creditLimit);
          }

          if (jVal.accountHeader.requiresESignature) {
            commit("updateCreditLimit", jVal.accountHeader.preApprovedAmount);
          }

          commit("updateCreditLimit", jVal.accountHeader.preApprovedAmount);
          commit("updateDrawIncrement", jVal.accountConfig.incrementDrawAmount);
          commit("updateMinimumDraw", jVal.accountConfig.minimumDrawAmount);
          commit("updateMainPhone", jVal.accountProfile.phoneNumber);
          commit("updateCellPhone", jVal.accountProfile.mobileNumber);
          commit("updateCustEmail", jVal.accountProfile.custEmail);
          commit("updateCustID", jVal.accountProfile.custId);
          commit("updateApplicationId", jVal.accountHeader.applNo);
          //console.log("applno- " + jVal.accountHeader.applNo);
          commit("updateFirstName", jVal.accountProfile.firstName);
          commit("updateLastName", jVal.accountProfile.lastName);

          commit(
            "updateCashAdvanceFeePercent",
            jVal.accountHeader.cashAdvanceFeePercentage
          );
          commit(
            "updateIsPaperCheckAllowed",
            jVal.applicationFlags.isPaperCheckConfirmed
          );
          commit(
            "updateIsTwoFactorAuthenticationEnabled",
            jVal.applicationFlags.twoFactorAuthenticationEnabled
          );
          commit(
            "updatedob",
            moment(jVal.accountProfile.custDOB).format("MM/DD/YYYY")
          );
          commit("updatessn", jVal.accountProfile.last4SSN.substring(7));
          return Promise.resolve(jVal);
        } else {
          return Promise.reject("Loan document downloading failed");
        }
      } catch (ex) {        
        //console.log(ex);
      }
    },

    async postConsentData({ state }) {
      try {
        var auth = "Bearer " + state.tokenID;
        //auth = 'Bearer eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGVuYyNyc2Etb2FlcCIsImVuYyI6IkExMjhDQkMtSFMyNTYiLCJraWQiOiI5QzM0OTJFRkFFMDU0NTJBQzZDRjIyMUVGMDMwQjhFM0E4RTk0MUU4IiwidHlwIjoiSldUIn0.UFND8bz8i-_s2Gp3wx4CmUbPYj3OPyClp0QZmmX6DqRV3o9NsHWhHWVIXu1lR3Os7dLBzgyDcWCHInw06qgIyIbyKBZHEX06Q1Pe68V3NiLzmlijP3ACpTd7lK8E1xwymoGpsmSB5qsbHFCUmNXQz5LFPo0sgIBF7_XjpZKPNUbS89CncxERW8j2KhYkp6UllAQoKG5_dsSfEvZBA77d1rfzHqMNgUJ6R9kW9VoHHkuv6gXvcyBeKGl3jQRaWGhV2b-k1eGt0QlbTg0mQPi7jLGsLRnRVRiiwDlsfiTzXtPZjPVm4s-xkQVBHi3WwuXF0KS0BBBGUcfsmwSFMv5VRg.i9kMNWIRr9zgujPLMD8LcA.xAcl82lnpSAHKH7sUMBTiG0A6YdGGmfsY0fP8cYEPbnNlkDCJkjgruD82L_4WIQDjmEfNqwbn5xCH71LDDRMhuz-tYjVhdFK1ZFgEBFDc7AdqBGPg31-_8CeuQSPws9mmY35f8vjn2qnmN3VgWowqKUOO0bc1p9vhtnLfSCkgwCkgBrEVGp0jjWVz9xprH9t8zIiM99HBQoEWw1R7w2EzQKUadnI5g6pR3Z2c3d30vpbn1cwqcQDOy3QnQX-CtFk3xFJTvblB3f63NequoO-P19DRu9_Ism_fjBGj9OHlHAbH8fIIjN8VNLJoU5mLDIWOfZibsa4CYSm1glQLRtClYTeOaBPpqodYmdz6E9mBtXRTsLx0IDpgBZOitVxYJUL2RNjE0DaqzqbJyNso_h6jOeXwrW0qljO3130WpulnzQsMj9LBp083CWACl6Ftcp6hJKL08v2UhQe0vbEKRRim4xOlwXSSg_b_RlRbqabJSvzJQBMIdjnwNWzke3_FFEDTj4vaGKVgEQ6TPNlUG3Iv9ptAe7vJbSJWiczWnFdIBnWzK4-xW-0eMUB82_S-cQIlJ1neHV0HhbvwFXb-8cOmcmcsNyYTj8fF-RdxdJFfTGKOeDh99IepxNkWzmthAHvkHnjMuxpVSTTiCQFPmHHEVXn3acT47upGdSndEEkPs8.iOoAj7KVw6dG9BNnAMaVyA';
        // var unMaskedCellPhone = state.cellPhone.replace(/-/g,"");
        var unMaskedMainPhone = state.mainPhone.replace(/-/g, "");

        var input = JSON.stringify({
          custEmail: state.custEmail,
          //   mobileNumber: unMaskedCellPhone,
          phoneNumber: unMaskedMainPhone,
          preferences: {
            allowMarketingTexts: state.marketingTexts,
            allowMarketingCalls: state.marketingTexts,
          },
        });

        const acctUpdateResponse = await fetch(
          $gMiddleLayerApi + "Account/UpdateProfile",
          {
            method: "POST",
            body: input,
            headers: {
              "Content-Type": "application/json",
              Authorization: auth,
            },
          }
        );

        //console.log(acctUpdateResponse);
        if (acctUpdateResponse.ok) {
          const jVal = await acctUpdateResponse.json();

          state.consentComplete = true;
          return Promise.resolve(jVal);
        } else {
          return Promise.reject("Unable to Save Consent Data.");
        }
      } catch (ex) {
        // console.log(ex);
      }
    },

    async saveConsentData({ state }) {
      /* Mock Implementation */
      // Assemble and format POST body
      if (state.refuseDraw) {
        state.firstDrawAmount = 0;
      }

      /* const record = {
         electronicConsent: state.electronicConsent,
         marketingCalls: state.marketingCalls,
         marketingTexts: state.marketingTexts,
         firstDrawAmount: state.firstDrawAmount
       }*/

      // Call API (simulated) and return result (simulated)
      return new Promise((resolve) => {
        setTimeout(() => {
          //   console.log('POST to API: ', record);
          state.consentComplete = true;
          resolve(true);
        }, 500);
      });
    },

    async saveSignatureData({ state }) {
      /*
      let result = index.postSaveDocumentEsig(state)
      return result
      */

      /* Mock Implementation
      const record = {
        militaryService: state.militaryService,
        acceptTerms: state.acceptTerms,
        borrowerName: state.borrowerName
      } */
      return new Promise((resolve) => {
        setTimeout(() => {
          // console.log('POST to API: ', record);
          state.signatureComplete = true;
          resolve(true);
        }, 500);
      });
    },

    async saveNote({ state }) {
      //var auth = 'Bearer eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGVuYyNyc2Etb2FlcCIsImVuYyI6IkExMjhDQkMtSFMyNTYiLCJraWQiOiI5QzM0OTJFRkFFMDU0NTJBQzZDRjIyMUVGMDMwQjhFM0E4RTk0MUU4IiwidHlwIjoiSldUIn0.UFND8bz8i-_s2Gp3wx4CmUbPYj3OPyClp0QZmmX6DqRV3o9NsHWhHWVIXu1lR3Os7dLBzgyDcWCHInw06qgIyIbyKBZHEX06Q1Pe68V3NiLzmlijP3ACpTd7lK8E1xwymoGpsmSB5qsbHFCUmNXQz5LFPo0sgIBF7_XjpZKPNUbS89CncxERW8j2KhYkp6UllAQoKG5_dsSfEvZBA77d1rfzHqMNgUJ6R9kW9VoHHkuv6gXvcyBeKGl3jQRaWGhV2b-k1eGt0QlbTg0mQPi7jLGsLRnRVRiiwDlsfiTzXtPZjPVm4s-xkQVBHi3WwuXF0KS0BBBGUcfsmwSFMv5VRg.i9kMNWIRr9zgujPLMD8LcA.xAcl82lnpSAHKH7sUMBTiG0A6YdGGmfsY0fP8cYEPbnNlkDCJkjgruD82L_4WIQDjmEfNqwbn5xCH71LDDRMhuz-tYjVhdFK1ZFgEBFDc7AdqBGPg31-_8CeuQSPws9mmY35f8vjn2qnmN3VgWowqKUOO0bc1p9vhtnLfSCkgwCkgBrEVGp0jjWVz9xprH9t8zIiM99HBQoEWw1R7w2EzQKUadnI5g6pR3Z2c3d30vpbn1cwqcQDOy3QnQX-CtFk3xFJTvblB3f63NequoO-P19DRu9_Ism_fjBGj9OHlHAbH8fIIjN8VNLJoU5mLDIWOfZibsa4CYSm1glQLRtClYTeOaBPpqodYmdz6E9mBtXRTsLx0IDpgBZOitVxYJUL2RNjE0DaqzqbJyNso_h6jOeXwrW0qljO3130WpulnzQsMj9LBp083CWACl6Ftcp6hJKL08v2UhQe0vbEKRRim4xOlwXSSg_b_RlRbqabJSvzJQBMIdjnwNWzke3_FFEDTj4vaGKVgEQ6TPNlUG3Iv9ptAe7vJbSJWiczWnFdIBnWzK4-xW-0eMUB82_S-cQIlJ1neHV0HhbvwFXb-8cOmcmcsNyYTj8fF-RdxdJFfTGKOeDh99IepxNkWzmthAHvkHnjMuxpVSTTiCQFPmHHEVXn3acT47upGdSndEEkPs8.iOoAj7KVw6dG9BNnAMaVyA';
      var auth = "Bearer " + state.tokenID;

      var input = JSON.stringify({
        note:
          "DRAW REQUEST: Customer requested draw in the amount of $" +
          state.firstDrawAmount +
          " on " +
          new Date().toLocaleString(),
        isUrgent: false,
      });

      try {
        const res = await fetch(
          $gMiddleLayerApi + "Account/SaveNote",
          {
            //for now just testing api hitting endpoint, will need to change this later to accept paths
            method: "POST",
            body: input,
            headers: {
              "Content-Type": "application/json",
              Authorization: auth,
            },
          }
        );

        //  console.log(res.status);
        if (res.ok) {
          const jVal = await res.json(); // Get JSON value from the response body
          //   console.log(jVal);
          return Promise.resolve(jVal);
        } else {
          return Promise.reject(
            "Unable to Save Note for Requested Draw amount."
          );
        }
      } catch (ex) {
        //console.log(ex);
      }
    },
    async updateDrawAmount({ state }) {
      var auth = "Bearer " + state.tokenID;

      var input = JSON.stringify({
        requestedDrawAmount: state.firstDrawAmount,
      });
      // console.log("token"+auth);
      //  console.log("input"+input);
      try {
        const res = await fetch(
          $gMiddleLayerApi + "Applications/",
          {
            //for now just testing api hitting endpoint, will need to change this later to accept paths
            method: "POST",
            body: input,
            headers: {
              "Content-Type": "application/json",
              Authorization: auth,
            },
          }
        );

        //  console.log(res);
        if (res.ok) {
          const jVal = await res.json(); // Get JSON value from the response body
          //  console.log(jVal);
          return Promise.resolve(jVal);
        } else {
          return Promise.reject("Unable to updateDrawAmount.");
        }
      } catch (ex) {
        //console.log(ex);
      }
    },
    async getDrawAmount({ state, commit }) {
      var auth = "Bearer " + state.tokenID;

      //    console.log("token"+auth);

      try {
        const res = await fetch(
          $gMiddleLayerApi + "Applications/",
          {
            //for now just testing api hitting endpoint, will need to change this later to accept paths

            headers: {
              "Content-Type": "application/json",
              Authorization: auth,
            },
          }
        );

        //  console.log(res);
        if (res.ok) {
          const jVal = await res.json(); // Get JSON value from the response body
          this.state.login.firstDrawAmount =
            jVal.applications[0].requestedDrawAmount;
          commit(
            "updateFirstDrawAmount",
            jVal.applications[0].requestedDrawAmount
          );
          return Promise.resolve(jVal);
        } else {
          return Promise.reject("Unable to get DrawAmount.");
        }
      } catch (ex) {
        //console.log(ex);
      }
    },

    async postSignatureData({ state }) {
      try {
        var input;
        // var unMaskedCellPhone = state.cellPhone.replace(/-/g,"");
        var unMaskedMainPhone = state.mainPhone.replace(/-/g, "");

        if (state.paymentMethod === "direct-deposit") {
          input = JSON.stringify({
            custEmail: state.custEmail,
            //  mobileNumber: unMaskedCellPhone,
            phoneNumber: unMaskedMainPhone,
            preferences: {
              isMilitaryPersonnel: state.militaryService,
            },
            additionalFields: {
              100629: "Electronic Funds Transfer (EFT)",
            },
          });
        } else if (state.paymentMethod === "paper-check") {
          input = JSON.stringify({
            custEmail: state.custEmail,
            //  mobileNumber: unMaskedCellPhone,
            phoneNumber: unMaskedMainPhone,
            preferences: {
              isMilitaryPersonnel: state.militaryService,
            },
            additionalFields: {
              100629: "Paper Check",
            },
          });
        }

        var auth = "Bearer " + state.tokenID;
        //auth = 'Bearer eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGVuYyNyc2Etb2FlcCIsImVuYyI6IkExMjhDQkMtSFMyNTYiLCJraWQiOiI5QzM0OTJFRkFFMDU0NTJBQzZDRjIyMUVGMDMwQjhFM0E4RTk0MUU4IiwidHlwIjoiSldUIn0.UFND8bz8i-_s2Gp3wx4CmUbPYj3OPyClp0QZmmX6DqRV3o9NsHWhHWVIXu1lR3Os7dLBzgyDcWCHInw06qgIyIbyKBZHEX06Q1Pe68V3NiLzmlijP3ACpTd7lK8E1xwymoGpsmSB5qsbHFCUmNXQz5LFPo0sgIBF7_XjpZKPNUbS89CncxERW8j2KhYkp6UllAQoKG5_dsSfEvZBA77d1rfzHqMNgUJ6R9kW9VoHHkuv6gXvcyBeKGl3jQRaWGhV2b-k1eGt0QlbTg0mQPi7jLGsLRnRVRiiwDlsfiTzXtPZjPVm4s-xkQVBHi3WwuXF0KS0BBBGUcfsmwSFMv5VRg.i9kMNWIRr9zgujPLMD8LcA.xAcl82lnpSAHKH7sUMBTiG0A6YdGGmfsY0fP8cYEPbnNlkDCJkjgruD82L_4WIQDjmEfNqwbn5xCH71LDDRMhuz-tYjVhdFK1ZFgEBFDc7AdqBGPg31-_8CeuQSPws9mmY35f8vjn2qnmN3VgWowqKUOO0bc1p9vhtnLfSCkgwCkgBrEVGp0jjWVz9xprH9t8zIiM99HBQoEWw1R7w2EzQKUadnI5g6pR3Z2c3d30vpbn1cwqcQDOy3QnQX-CtFk3xFJTvblB3f63NequoO-P19DRu9_Ism_fjBGj9OHlHAbH8fIIjN8VNLJoU5mLDIWOfZibsa4CYSm1glQLRtClYTeOaBPpqodYmdz6E9mBtXRTsLx0IDpgBZOitVxYJUL2RNjE0DaqzqbJyNso_h6jOeXwrW0qljO3130WpulnzQsMj9LBp083CWACl6Ftcp6hJKL08v2UhQe0vbEKRRim4xOlwXSSg_b_RlRbqabJSvzJQBMIdjnwNWzke3_FFEDTj4vaGKVgEQ6TPNlUG3Iv9ptAe7vJbSJWiczWnFdIBnWzK4-xW-0eMUB82_S-cQIlJ1neHV0HhbvwFXb-8cOmcmcsNyYTj8fF-RdxdJFfTGKOeDh99IepxNkWzmthAHvkHnjMuxpVSTTiCQFPmHHEVXn3acT47upGdSndEEkPs8.iOoAj7KVw6dG9BNnAMaVyA';
        //  console.log(input);
        const acctUpdateResponse = await fetch(
          $gMiddleLayerApi + "Account/UpdateProfile",
          {
            method: "POST",
            body: input,
            headers: {
              "Content-Type": "application/json",
              Authorization: auth,
            },
          }
        );

        // console.log(acctUpdateResponse);

        if (acctUpdateResponse.ok) {
          const jVal = await acctUpdateResponse.json();
          // console.log(jVal);
          return Promise.resolve(jVal);
        } else {
          return Promise.reject("Unable to Save Esign data.");
        }
      } catch (ex) {
        // console.log(ex);
      }
    },

    async saveSignatures({ state }) {
      var auth = "Bearer " + state.tokenID;
      //auth = 'Bearer eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGVuYyNyc2Etb2FlcCIsImVuYyI6IkExMjhDQkMtSFMyNTYiLCJraWQiOiI5QzM0OTJFRkFFMDU0NTJBQzZDRjIyMUVGMDMwQjhFM0E4RTk0MUU4IiwidHlwIjoiSldUIn0.UFND8bz8i-_s2Gp3wx4CmUbPYj3OPyClp0QZmmX6DqRV3o9NsHWhHWVIXu1lR3Os7dLBzgyDcWCHInw06qgIyIbyKBZHEX06Q1Pe68V3NiLzmlijP3ACpTd7lK8E1xwymoGpsmSB5qsbHFCUmNXQz5LFPo0sgIBF7_XjpZKPNUbS89CncxERW8j2KhYkp6UllAQoKG5_dsSfEvZBA77d1rfzHqMNgUJ6R9kW9VoHHkuv6gXvcyBeKGl3jQRaWGhV2b-k1eGt0QlbTg0mQPi7jLGsLRnRVRiiwDlsfiTzXtPZjPVm4s-xkQVBHi3WwuXF0KS0BBBGUcfsmwSFMv5VRg.i9kMNWIRr9zgujPLMD8LcA.xAcl82lnpSAHKH7sUMBTiG0A6YdGGmfsY0fP8cYEPbnNlkDCJkjgruD82L_4WIQDjmEfNqwbn5xCH71LDDRMhuz-tYjVhdFK1ZFgEBFDc7AdqBGPg31-_8CeuQSPws9mmY35f8vjn2qnmN3VgWowqKUOO0bc1p9vhtnLfSCkgwCkgBrEVGp0jjWVz9xprH9t8zIiM99HBQoEWw1R7w2EzQKUadnI5g6pR3Z2c3d30vpbn1cwqcQDOy3QnQX-CtFk3xFJTvblB3f63NequoO-P19DRu9_Ism_fjBGj9OHlHAbH8fIIjN8VNLJoU5mLDIWOfZibsa4CYSm1glQLRtClYTeOaBPpqodYmdz6E9mBtXRTsLx0IDpgBZOitVxYJUL2RNjE0DaqzqbJyNso_h6jOeXwrW0qljO3130WpulnzQsMj9LBp083CWACl6Ftcp6hJKL08v2UhQe0vbEKRRim4xOlwXSSg_b_RlRbqabJSvzJQBMIdjnwNWzke3_FFEDTj4vaGKVgEQ6TPNlUG3Iv9ptAe7vJbSJWiczWnFdIBnWzK4-xW-0eMUB82_S-cQIlJ1neHV0HhbvwFXb-8cOmcmcsNyYTj8fF-RdxdJFfTGKOeDh99IepxNkWzmthAHvkHnjMuxpVSTTiCQFPmHHEVXn3acT47upGdSndEEkPs8.iOoAj7KVw6dG9BNnAMaVyA';
      var input = JSON.stringify({
        signaturesData: [
          {
            eSignatureId: 1,
            documentId: state.loanAppDocumentID,
            eSignatureText: state.borrowerName,
            eSignatureIPAddress: state.ip,
            eSignatureDate: new Date(),
            eSignatureOrderNo: 1,
          },
        ],
      });

      try {
        const res = await fetch(
          $gMiddleLayerApi + "Account/SaveSignatures",
          {
            //for now just testing api hitting endpoint, will need to change this later to accept paths
            method: "POST",
            body: input,
            headers: {
              "Content-Type": "application/json",
              Authorization: auth,
            },
          }
        );

        // console.log(res.status);
        if (res.ok) {
          const jVal = await res.json(); // Get JSON value from the response body
          //  console.log(jVal);
          state.signatureComplete = true;
          return Promise.resolve(jVal);
        } else {
          return Promise.reject("Unable to Save esignature data.");
        }
      } catch (ex) {
        //console.log(ex);
      }
    },

    async findEsignIP({ state }) {
      const ipCloudFlareresult = await fetch(
        "https://www.cloudflare.com/cdn-cgi/trace"
      );
      if (ipCloudFlareresult.ok) {
        var ipval = await ipCloudFlareresult.text();
        if (ipval != undefined) {
          state.remoteIP = ipval
            .substring(ipval.search("ip=") + 3, ipval.search("ts="))
            .trim();
          //state.countryCode = ipval.substring(ipval.search('loc=')+4, ipval.search('tls=')).trim();
        }
      }
      //  console.log(state.ip)
      if (state.ip == undefined) {
        const ipResult = await fetch("https://api.ipify.org?format=json");
        if (ipResult.ok) {
          var val = await ipResult.json();
          state.ip = val.ip;
        }
      }
    },

    async getUserDetails({ state }) {
      var input = JSON.stringify({
        emailAddress: state.custEmail,
        remoteIP: state.ip,
      });
      //  console.log(input);
      var auth = "Bearer " + state.tokenID;
      //auth = 'Bearer eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGVuYyNyc2Etb2FlcCIsImVuYyI6IkExMjhDQkMtSFMyNTYiLCJraWQiOiI5QzM0OTJFRkFFMDU0NTJBQzZDRjIyMUVGMDMwQjhFM0E4RTk0MUU4IiwidHlwIjoiSldUIn0.KJE47tKDIHWPMkEvxZqlON9TNZ1zn9mgPrEEdnfDui_xoUHJn4XeNeCt-l2J5E0sbEVIb8xeUE-lkAFL2OfE8r6ctU8Uf1UyfmXPW8nD5WPOMqHwJirQrsjvhKKTqC2P1IMJFCNX9EAqnQ0LEgET3x5__i36a_g625dolqMtlLw9mRZitCTcYa8eduR9htydiZdK-s7i-38Q8bJ7zxUQPEMu65TeI8uJB6epvh0O0H6fHUr0vKh4U-zSihpep4BJCGwjKZUQFKWwDUCC9pRENTwEy1Y6d_2y5j_0DIPBapOi5vRgOAHuYcW0VLJaza4T_Af_azwsVckTAgX8GimQnw.n-GoQpLtI8Xsk5qk0adBcA.FObrcXbUWSixfCZ_45P-x2wdEdX-Uem-MNZOHbCIcRxyPjQmVdXcztNid5ikVLktgp9fwuj2czY0NzzwhVxbkefzuNTXNvHyUa0Qbq4-IUiEUGNxdIknIef9Y9Fss5OviV9VnL5G7DptZN2V6YZY6nfI193L39e656NlCG4oYYlKAnMr8FVU2kgeufBbDNFLTOdSzs_yLWcLIWodP9YhtAdg0BgwYtWbAZqfaXpMHLhqtaElYAsXAybgwQWYcTnArWpyWpdm2xBOIHCfmJpiaeDbMZW3n38YFPwyD0zW5-nWihDOLlP4s0xN3MRRI6zRAxHXlCBlT3zHVyEWa5RFQqWdDG4dOUpl8GYoAWJv37jM66hr99vtN00LoWWkD3L5B_tXsF7gURL9ganA6nnmE1YjFD5jxNZS1L1ufZEHAU3kE0H40WvOZ_Scz7g8zSDw6AAUFx54xpBpCo4qgMwOf6CjvjU0XBgljEWBZH2BIIZZgVjHm7hmElhTMbPDLy6FWRMekIXedytMcZB92MbolbQRp3ruWpXtDHbB870cAcjl78-vQGJ8FtwRrB7sXqlPDVDgzNKc0VkmXWuIDdOPrwX_rzeAT8Z04Myr2XzJ5cFgxYNPtqjX_tqVDbdQ9-4Ar9qaNdYpOZAfEeTrq9V7uLhgYdY6oeJvNIha1SbYJsrsCYrR-xp6l63Dt3fB9Wed48au28-XuTzrFYtdoGRBJA.mEopZRi-KgU_Mq8KTCYU2A';

      const response = await fetch(
        $gMiddleLayerApi + "Auth/ValidateEmail",
        {
          //for now just testing api hitting endpoint, will need to change this later to accept paths
          method: "POST",
          body: input,
          headers: {
            "Content-Type": "application/json",
            Authorization: auth,
          },
        }
      );
      
      //  console.log(response);
      if (response.ok) {
        const jVal = await response.json(); // Get JSON value from the response body
        //   console.log(jVal);
        return Promise.resolve(jVal);
      }
    },

    async getUWSTransactionInformation({ state }, tranID) {
      var input = JSON.stringify({
        transactinId: tranID,
      });

      //  console.log(input);
      var auth = "Bearer " + state.tokenID;
      //auth = 'Bearer eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGVuYyNyc2Etb2FlcCIsImVuYyI6IkExMjhDQkMtSFMyNTYiLCJraWQiOiI5QzM0OTJFRkFFMDU0NTJBQzZDRjIyMUVGMDMwQjhFM0E4RTk0MUU4IiwidHlwIjoiSldUIn0.KJE47tKDIHWPMkEvxZqlON9TNZ1zn9mgPrEEdnfDui_xoUHJn4XeNeCt-l2J5E0sbEVIb8xeUE-lkAFL2OfE8r6ctU8Uf1UyfmXPW8nD5WPOMqHwJirQrsjvhKKTqC2P1IMJFCNX9EAqnQ0LEgET3x5__i36a_g625dolqMtlLw9mRZitCTcYa8eduR9htydiZdK-s7i-38Q8bJ7zxUQPEMu65TeI8uJB6epvh0O0H6fHUr0vKh4U-zSihpep4BJCGwjKZUQFKWwDUCC9pRENTwEy1Y6d_2y5j_0DIPBapOi5vRgOAHuYcW0VLJaza4T_Af_azwsVckTAgX8GimQnw.n-GoQpLtI8Xsk5qk0adBcA.FObrcXbUWSixfCZ_45P-x2wdEdX-Uem-MNZOHbCIcRxyPjQmVdXcztNid5ikVLktgp9fwuj2czY0NzzwhVxbkefzuNTXNvHyUa0Qbq4-IUiEUGNxdIknIef9Y9Fss5OviV9VnL5G7DptZN2V6YZY6nfI193L39e656NlCG4oYYlKAnMr8FVU2kgeufBbDNFLTOdSzs_yLWcLIWodP9YhtAdg0BgwYtWbAZqfaXpMHLhqtaElYAsXAybgwQWYcTnArWpyWpdm2xBOIHCfmJpiaeDbMZW3n38YFPwyD0zW5-nWihDOLlP4s0xN3MRRI6zRAxHXlCBlT3zHVyEWa5RFQqWdDG4dOUpl8GYoAWJv37jM66hr99vtN00LoWWkD3L5B_tXsF7gURL9ganA6nnmE1YjFD5jxNZS1L1ufZEHAU3kE0H40WvOZ_Scz7g8zSDw6AAUFx54xpBpCo4qgMwOf6CjvjU0XBgljEWBZH2BIIZZgVjHm7hmElhTMbPDLy6FWRMekIXedytMcZB92MbolbQRp3ruWpXtDHbB870cAcjl78-vQGJ8FtwRrB7sXqlPDVDgzNKc0VkmXWuIDdOPrwX_rzeAT8Z04Myr2XzJ5cFgxYNPtqjX_tqVDbdQ9-4Ar9qaNdYpOZAfEeTrq9V7uLhgYdY6oeJvNIha1SbYJsrsCYrR-xp6l63Dt3fB9Wed48au28-XuTzrFYtdoGRBJA.mEopZRi-KgU_Mq8KTCYU2A';

      const response = await fetch(
        $gMiddleLayerApi +
          "Underwriting/GetUWSTransactionInformation",
        {
          //for now just testing api hitting endpoint, will need to change this later to accept paths
          method: "POST",
          body: input,
          headers: {
            "Content-Type": "application/json",
            Authorization: auth,
          },
        }
      );
      //   console.log(response);
      if (response.ok) {
        const jVal = await response.json(); // Get JSON value from the response body
        //   console.log(jVal);
        state.tokenID = jVal.tokenToUse;
        return Promise.resolve(jVal);
      } else {
        return Promise.reject("Unable to load data.");
      }
    },
    //2FA
    async esignCreatePassword({ state }, ssnpassword) {
      var auth = "Bearer " + state.tokenID;

      var date = new Date(state.custdob);

      var bdate = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
      );
      var input = JSON.stringify({
        email: state.custEmail,
        password: ssnpassword.substring(4),
        confirmPassword: ssnpassword.substring(4),
        userName: state.custEmail,
        custSSN: ssnpassword.substring(0, 4),
        dob: bdate,
      });

      //console.log(input);
      try {
        const loginResponse = await fetch(
          $gMiddleLayerApi + `Auth/Register`,
          {
            //for now just testing api hitting endpoint, will need to change this later to accept paths
            method: "POST",
            body: input,
            headers: {
              "Content-Type": "application/json",
              Authorization: auth,
            },
          }
        );

        if (loginResponse.ok) {
          const jVal = await loginResponse.json(); // Get JSON value from the response body
          //console.log(jVal);
          return Promise.resolve(jVal);
        } else return Promise.reject("Call failed");
      } catch (ex) {
        // console.log(ex);
      }
    },

    async validateIdentity({ state, commit }) {
      var date = new Date(state.custdob);
      var bdate = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
      );
      var input = JSON.stringify({
        emailAddress: state.custEmail,
        custSSN: state.ssn,
        custDOB: bdate,
        remoteIP: state.ip,
      });

      try {
        var auth = "Bearer " + state.tokenID;

        //console.log(input);
        const response = await fetch(
          $gMiddleLayerApi + `Auth/VerifyIdentity`,
          {
            method: "POST",
            body: input,
            headers: {
              "Content-Type": "application/json",
              Authorization: auth,
            },
          }
        );
        if (response.ok) {
          const jVal = await response.json(); // Get JSON value from the response body

          //console.log(jVal);
          if (
            jVal.validEmail == true &&
            jVal.validIdentity == true &&
            jVal.tokenToUse != null &&
            jVal.isRegistered == false
          ) {
            commit("updateesignvaliduserforcrtpwd", true);
          } else {
            //commit('updateesignvaliduserforcrtpwd',true); //for test purpose need to rever it
            commit("updateesignvaliduserforcrtpwd", false);
          }

          return Promise.resolve(jVal);
        }
      } catch (ex) {
        //console.log(ex)
      }
    },
  },
};
