export const incomeSources =[
  { text: "Employment",value:"P"},
  { text: "Self-Employment",value:"O"},
  { text: "Other",value:"W"}
]

export const secondarySources =[
  { text: "Alimony/Child Support Income",value:"OA"},
  { text: "Social Security Income",value:"G"},
  { text: "Disability",value:"D"},
  { text: "Prefer Not To Disclose",value:"OP"},
  { text: "Not Applicable",value:"ON"},
]
