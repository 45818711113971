function getViewName(vm) {
  const { name } = vm.$options;
  if (name) {
    return typeof name === "function" ? name.call(vm) : name;
  }
}

import { useHead } from "@vueuse/head";

export default {
  mounted() {
    const metadata = getViewName(this);
    if (metadata != undefined) {
      var contentdata = "";
      if (metadata == "Home") {
        contentdata =
          "Access the funds you need when you need them with the XactFlex Line of Credit, an online-only, open-ended line of credit. Check out how it benefits you.";
      } else if (metadata == "Faq") {
        contentdata =
          "Get the answers to common questions about our lines of credit. Are you eligible for a line of credit? How much does it cost? How do you apply? Find your answers here.";
      } else if (metadata == "AboutUs") {
        contentdata =
          "Learn about our line of credit product and our partnerships. We work hard to meet the needs of everyday people and help them find financial solutions.";
      } else if (metadata == "PrivacyPolicy") {
        contentdata =
          "See the XactFlex Privacy Policy to better understand how we use your personal information.";
      } else if (metadata == "LoginMain") {
        contentdata =
          "Log in to manage your account online. You can log in to eSign your line of credit documents, check your balance, request cash advances and make payments.";
      } else if (metadata == "TermsAndConditions") {
        contentdata =
          "Read the terms and conditions of the XactFlex website here.";
      } else if (
        metadata == "AppPersonal" ||
        metadata == "AppAddress" ||
        metadata == "AppIncome" ||
        metadata == "AppPayFrequency" ||
        metadata == "AppBanking"
      ) {
        contentdata =
          "Apply for a line of credit and access up to $2,000 in a cash advance. Find out if you're approved – and your credit limit – right away. Don’t wait. Apply now.";
      } else return;
    }

    useHead({
      meta: [
        {
          vmid: "description",
          name: "description",
          content: contentdata,
        },
      ],
    });
  },
};
