<template>
  <main>

    <PageHeader>
      California Consumer Privacy Act (CCPA) Policy
    </PageHeader>

    <section>
      <div class="caPolicy" v-html="caPrivacyPolicy"/>
    </section>

  </main>
</template>

<script>
import { PageHeader } from '@/components';

export default {
  name: 'caPrivacyPolicy',
  title: 'Cali Privacy Policy',
  components: { PageHeader },
  computed: {
    caPrivacyPolicy: {
      get() { return this.$store.state.documents.caPrivacyPolicy }
    }
  },
  async created() {
    if (!this.caPrivacyPolicy) {
      this.$store.dispatch('getCaPrivacyPolicy');
    }
  },
}
</script>

<style scoped lang="scss">
#content{
    margin: 0 auto !important; 
  }
main {
  width: 100%;
  background: white;
  .page-header-wrapper{
    .page-header{
      justify-content: center;
      display: block;
    }
  }
  
  section {
    //margin-top: 60px;
    margin-top: 20px;
    padding: 0 0 70px 0;

    .caPolicy{
      max-width: 1100px;
      padding: 30px;
      color: #53575A;
      margin: 0 auto;
      //font-weight: 400;
      font-size: 15px;

      @media (max-width: 1023px) {
        margin: 0 10px;
      }
    }
  }
}
@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
</style>