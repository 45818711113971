<template>
  <div
    class="account-summary cardNew"
    :id="showPartialSummary ? 'rightPortalSum' : null"
    :class="[
      showPartialSummary ? 'special' : null,
      showPartialSummary ? 'card' : null,
    ]"
  >
    <div class="full">
      <div class="header" v-if="!showPartialSummary">
        <SectionTitle
          id="headerAccountPage"
          class="section_title"
          tabindex="-1"
        >
          <template v-slot:pagetitle>Account Summary</template></SectionTitle
        >
      </div>
      <div
        class="summary"
        :class="{
          special: showPartialSummary,
          column_direction: !showPartialSummary,
          row_direction: showPartialSummary,
        }"
      >
        <div :class="paymentMade ? 'chart chartadj' : 'chart'">
          <PortalDonutChart
            :availableCredit="
              chargeoff || delinquent ? '0.00' : availableCredit
            "
            :percent="creditUsedPercentage"
            :isDelinquent="delinquent"
            :isChargeOff="chargeoff"
          />
        </div>
        <div class="payment">
          <div class="pair" :class="showPartialSummary ? 'special' : null">
            <div class="upper">
              <div
                class="item left"
                :class="[
                  showPartialSummary ? 'special' : null,
                  chargeoff ? 'left-close' : null,
                ]"
              >
                <div class="title medium blue" v-if="!chargeoff && !delinquent">
                  Available Credit
                </div>
                <!-- <div class="title delinquent medium" v-else>
                  Past-Due Amount
                </div>-->
                <div class="value large blue" v-if="!chargeoff && !delinquent">
                  {{ currencyLong(availableCredit) }}
                </div>
                <!--<div class="value delinquent large" v-else>
                  {{ pastDue | currencyLong }}
                </div>-->
              </div>
              <!-- <div class="item right lightBlue large" v-if="!chargeoff && !delinquent">
                <div class="title">
                  Account Balance
                </div>
                <div class="value">
                  <span @mouseover="hover = true" @mouseleave="hover = false">{{ accountBalance | currencyLong }}
                  </span>
                </div>
              </div>
              <div class="item right del-gray large" 
                :class="nextPaymentDate == '1/1/1900' ? 'hideMe' : null" v-else-if="chargeoff">
                <div class="title">
                  Payment Date
                </div>
                <div class="value" :class="nextPaymentDate == '1/1/1900' ? 'hideMe' : null">
                  {{ nextPaymentDate | dateString }}
                </div>
              </div> -->
              <!-- <div class="delinquent small" :class="showPartialSummary ? 'special' : null" v-else>
                You have missed more than 3 payments. To make your account current, you must pay off your balance.
              </div> -->
              <!-- <div class="item right" :class="[showPartialSummary ? 'special' : null, delinquent || chargeoff ? 'del-gray' : null]">
              
              
              <div class="title medium" v-if="!chargeoff" :class="nextPaymentDate == '1/1/1900' ? 'hideMe' : null">
                Payment Date
              </div>
              
              <div class="value large" v-else-if="!chargeoff" :class="nextPaymentDate == '1/1/1900' ? 'hideMe' : null">
                {{ nextPaymentDate | dateString }}
              </div>-->
              <div
                class="item right"
                :class="[
                  showPartialSummary ? 'special' : null,
                  delinquent ? 'del-gray' : null,
                  chargeoff ? 'del-gray right-close' : null,
                ]"
              >
                <div
                  class="title medium lightBlue"
                  v-if="!chargeoff && !delinquent"
                >
                  Account Balance
                </div>
                <div
                  class="title medium"
                  v-else-if="!chargeoff"
                  :class="nextPaymentDate == '1/1/1900' ? 'hideMe' : null"
                >
                  Next Payment Due Date
                </div>
                <div
                  class="value large lightBlue"
                  v-if="!chargeoff && !delinquent"
                >
                  <span @mouseover="hover = true" @mouseleave="hover = false"
                    >{{ currencyLong(accountBalance) }}
                  </span>
                </div>
                <div
                  class="value large"
                  v-else-if="!chargeoff"
                  :class="nextPaymentDate == '1/1/1900' ? 'hideMe' : null"
                >
                  {{ dateString(nextPaymentDate) }}
                </div>
                <div
                  class="delinquent small"
                  :class="showPartialSummary ? 'special' : null"
                  v-if="chargeoff"
                  role="alert"
                >
                  Your loan is more than 90<br />
                  days past due. Your full account balance is due.
                </div>
              </div>
            </div>
            <div v-if="showPartialSummary">
              <div
                class="portal-call-button"
                :class="showPartialSummary ? 'special' : null"
              >
                <img
                  src="../../assets/icons/questions.png"
                  alt="help icon"
                  class="call-icon"
                  :class="showPartialSummary ? 'special' : null"
                />
                <div v-if="!chargeoff && !delinquent">
                  <div class="clsh3">Questions</div>
                  <div class="clsh5">
                    Call Us:
                    <a
                      class="no-border no-hover de-border"
                      :href="'tel:' + company.contactPhone"
                      >{{ formatPhoneNumber(company.contactPhone) }}</a
                    >.
                  </div>
                </div>
                <div v-else>
                  <div class="clsh3">Questions</div>
                  <div class="clsh5">
                    Talk to an Account Specialist:
                    <a
                      class="no-border no-hover de-border"
                      :href="'tel:' + company.advancedCustomerServicePhone"
                    >
                      {{
                        formatPhoneNumber(company.advancedCustomerServicePhone)
                      }}</a
                    >.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="trio" v-if="!showPartialSummary">
            <div
              class="item left medium"
              v-if="
                !(availableCredit === creditLimit && accountBalance == 0) &&
                !chargeoff &&
                !delinquent
              "
              :class="nextPaymentDate == '1/1/1900' ? 'hideMe' : null"
            >
              <div class="title">Payment Date</div>
              <div class="value">
                {{ dateString(nextPaymentDate) }}
              </div>
            </div>
            <div
              class="item left"
              :class="showPartialSummary ? 'special' : null"
              v-else-if="
                !(availableCredit === creditLimit && accountBalance == 0)
              "
            >
              <div class="title medium">Available Credit</div>

              <div class="value medium" v-if="!chargeoff && !delinquent">
                {{ currencyLong(availableCredit) }}
              </div>
              <div class="value medium" v-if="chargeoff || delinquent">
                $0.00
              </div>
            </div>
            <div class="item middle">
              <div
                class="title medium"
                :class="chargeoff ? 'delinquent' : null"
                v-if="chargeoff || delinquent"
              >
                Account Balance
              </div>
              <div
                class="value medium"
                :class="chargeoff ? 'delinquent' : null"
                v-if="chargeoff || delinquent"
              >
                <span @mouseover="hover = true" @mouseleave="hover = false"
                  >{{ currencyLong(accountBalance) }}
                </span>
              </div>
              <div v-if="!chargeoff && !delinquent">
                <div class="title medium">Next Minimum Due</div>
                <div class="value medium">
                  {{ currencyLong(minimumPaymentDue) }}
                </div>
              </div>
            </div>
            <div class="item right medium">
              <div class="title">Credit Limit</div>
              <div class="value">
                {{ currencyLong(creditLimit) }}
              </div>
            </div>
          </div>
          <button
            v-if="!showPartialSummary"
            @click="
              openMakePayment() == 1
                ? $router.push({ name: 'portal-payments' })
                : null
            "
            :disabled="maxPendingTranreachedOrZeroBalance ? true : false"
            :class="
              maxPendingTranreachedOrZeroBalance ? 'disablepayment' : null
            "
          >
            MAKE A PAYMENT
          </button>
        </div>
      </div>
    </div>
    <br v-if="!showPartialSummary" />
    <div
      v-if="maxPendingTranreached && !showPartialSummary"
      class="maxtranerror"
    >
      You have reached the maximum number of pending transactions allowed. No
      additional payments can be scheduled at this time.
    </div>
    <!-- <div v-if="hover && !showPartialSummary" style="ailgn">The account balance includes the accrued interest that has been billed to the account and all outstanding principal. This is not the full payoff amount. For a full payoff amount, call us at {{ company.contactPhone }}.</div>-->
  </div>
</template>

<script>
import companyInfo from "@/config/text/company";
import SectionTitle from "@/components/global/SectionTitle";
import PortalDonutChart from "@/components/portal/PortalDonutChart";
import { currencyLong } from "@/utils/filters/currency";
import { dateString } from '@/utils/filters/dates';
export default {
  name: "PortalAccountSum",
  components: { SectionTitle, PortalDonutChart },
  async created() {},

  computed: {
    showPartialSummary: {
      get() {
        return this.$store.state.portal.showPartialSummary;
      },
      set(value) {
        this.$store.commit("updateShowPartialSummary", value);
      },
    },
    accountId: {
      get() {
        return this.$store.state.account.accountId;
      },
    },
    accountBalance: {
      get() {
        return this.$store.state.account.accountBalance;
      },
    },
    creditUsedPercent: {
      get() {
        return this.$store.state.account.creditUsedPercent;
      },
    },
    nextPaymentDate: {
      get() {
        if (this.$store.state.account.nextPaymentDate != null)
          return this.$store.state.account.nextPaymentDate;
        else return "1/1/1900";
      },
    },
    minimumPaymentDue: {
      get() {
        return this.$store.state.account.minimumPaymentDue;
      },
    },
    pastDue: {
      get() {
        return this.$store.state.account.pastDue;
      },
    },
    creditLimit: {
      get() {
        return this.$store.state.account.creditLimit;
      },
    },
    delinquent: {
      get() {
        return this.$store.state.account.delinquent;
      },
    },
    paymentMade: {
      get() {
        return this.$store.state.portal.paymentMade;
      },
    },
    chargeoff: {
      get() {
        return this.$store.state.account.chargeoff;
      },
    },
    availableCredit: {
      get() {
        return this.$store.state.account.availableCredit;
      },
    },
    creditUsedPercentage: function () {
      if (this.creditLimit > 0)
        return Math.round(
          (this.availableCredit / this.creditLimit) * 100
        ).toFixed(0);
      // return 100 - (Math.round((this.$store.state.account.creditUsedPercent)).toFixed(0)); //100 - (Math.floor((this.accountBalance / this.creditLimit) * 100)).toFixed(0);
      else return 0;
    },
    maxPendingTranreached: {
      get() {
        //alert("this.$store.state.account.maxPendingTranreached : "+this.$store.state.account.maxPendingTranreached)
        return this.$store.state.account.maxPendingTranreached;
      },
    },
    maxPendingTranreachedOrZeroBalance: {
      get() {
        if (
          this.$store.state.account.maxPendingTranreached ||
          this.$store.state.account.accountBalance == 0
        )
          return true;
        else return false;
      },
    },
  },
  methods: {
    openMakePayment() {
      if (this.$store.state.account.creditLimit > 0) {
        this.$store.commit("updatePaymentAmount", null);
        this.$store.commit("updatePaymentDate", null);
        this.$store.commit("updateSelectedAmount", null);
        this.$store.commit("updateOtherAmount", 0);
        return 1;
      }
      return 0;
    },
    formatPhoneNumber(number) {
      //JO: Using method to format, instead of filter, since filters are going away in next version of VueJS
      return number.replace("-", ".");
    },
  },
  data() {
    return {
      company: companyInfo,
      hover: false,
      currencyLong : currencyLong,
      dateString : dateString
    };
  },
};
</script>

<style scoped lang="scss">
.account-summary.special {
  position: relative;
  // right:240px;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    height: fit-content;
  }
}

.account-summary {
  .hideMe {
    visibility: hidden;
  }

  #headerAccountPage:focus-visible {
    outline: 0px solid #1e4388;
  }

  .full {
    .header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: flex-start;
    }

    .summary.special {
      display: block;

      @media (max-width: 768px) {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
      }

      @media (max-width: 428px) {
        display: -webkit-box;
        width: 95%;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
      }

      .payment {
        background-color: $absolute-white;
        width: auto;
        padding: 0px;
        margin-left: 0;

        .portal-call-button {
          @media (max-width: 600px) {
            display: none;
          }
        }

        @media (max-width: 428px) {
          width: 50%;
        }

        @media (max-width: 359px) {
          width: 40%;
        }
      }

      .small.special {
        font-size: 12px;
        text-align: center;
        top: 0px;
        left: 0px;
      }

      .chart {
        margin-top: 60px;
      }

      .left,
      .right {
        float: none !important;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .column_direction {
      @media (max-width: 768px) {
        flex-flow: column nowrap !important;
      }
    }

    .row_direction {
      @media (max-width: 768px) {
        flex-flow: row nowrap !important;
      }
    }

    .summary {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      .chart {
        width: 220px;
      }

      .item {
        padding: 8px 4px;
      }

      .medium {
        font-size: 14px;
        font-weight: 700;

        @media (max-width: 768px) {
          font-size: 12px;
        }
      }

      .large {
        font-size: 20px;
        font-weight: 800;
      }

      .small {
        text-align: left;
        position: relative;
        top: -5px;
        left: -20px;
      }

      .payment {
        flex-flow: column nowrap;
        padding: 6px 20px 26px 20px;
        width: 100%;
        margin-left: 5%;

        @media (max-width: 768px) {
          width: 95%;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 50px;
          padding: 0px;
        }

        .pair:not(.special) {
          border-bottom: 1px solid $disabled-gray;
        }
      }

      .pair {
        width: 95%;

        .left-close {
        @media (max-width: 768px) {
          width: auto !important;
        }
      }
      .right-close {
        @media (max-width: 768px) {
          float: none !important;
        }

        .small {
          @media (max-width: 768px) {
            width: 175px;
            text-align: center !important;
            left: 0px !important;
          }
          @media (max-width:400px) {
            left: -15px !important;
          }
        }
      }
      }

      .pair .left {
        width: 45%;
        float: left;
      }

      .pair .right {
        width: 45%;
        float: right;
      }

      .pair .left.special,
      .pair .right.special {
        width: auto;
      }

      @media (max-width: 768px) {
        .right.special .title.medium {
          width: 100px;
        }
      }

      @media (max-width: 428px) {
        .right.special .title.medium {
          width: unset;
        }
      }

      .trio {
        width: 95%;
      }

      .trio .left {
        width: 30%;
        float: left;
      }

      .trio .middle {
        width: 30%;
        float: left;
      }

      .trio .right {
        width: 30%;
        float: right;
      }

      

      button {
        margin: 10px auto;
        padding: 10px 20px;
        background: $orange;
        color: $absolute-white;
        border-radius: 6px;
        font-weight: 700;
        font-size: 16pt;

        @media (max-width: 460px) {
          position: unset;
          margin: 10px auto -10px;
        }

        position: absolute;
        top: 338px;
      }

      .delinquent {
        color: $delinq-red;

        .donut {
          margin-left: 18px;
        }
      }

      .del-gray {
        color: #a1a2a3;
      }

      .del-warning {
        position: absolute;
        transform: translate(-36px, -4px);
        color: $error;
        font-size: 30px;
      }

      .disablepayment {
        background: $disabled-gray;
        cursor: not-allowed;
      }
    }
  }

  .collapsed {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    height: 40px;
  }

  .item {
    text-align: center;
    font-family: $font-primary; //$font-secondary;
    padding: 0 4px;

    .title {
      line-height: 16px;
      font-size: 14px;
      // border-bottom: 1px solid $border;
      text-transform: uppercase;
      padding: 5px 0px;
      margin-bottom: 2px;
    }

    .value {
      font-weight: 600;
    }
  }

  .payment {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 6px 10px;

    .title {
      font-family: $font-primary;
      font-size: 14px;

      @media (max-width: 460px) {
        font-size: 12px;
      }

      // border-bottom: 1px solid $border;
      text-transform: none;
    }
  }

  .folder {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    color: $orange;
    padding: 11px 0 11px 16px;
    cursor: pointer;
  }

  .maxtranerror {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    text-align: left;
    color: $error;
    font-size: 12px;
    font-family: $font-primary; //$font-secondary;
  }

  @media (max-width: 1023px) {
    .full {
      .header {
        margin-bottom: 0;
      }

      .summary {
        justify-content: space-around;

        .chart {
          width: 160px;
          display: inline-flex;
        }

        button {
          top: 368px;
        }

        .del-warning {
          transform: translate(-10px, 20px);
        }
      }
    }

    .collapsed {
      justify-content: space-around;
    }

    .item {
      margin: 0 auto;
    }

    .folder {
      padding: 8px 0;
      margin-left: auto;
    }
  }

  @media (max-width: 960px) {
    .full .summary button {
      top: 450px;
    }
  }

  @media (max-width: 939px) {
    .full .summary button {
      top: 450px;
    }
  }

  @media (max-width: 796px) {
    .full .summary button {
      top: 450px;
    }
  }

  @media (max-width: 770px) {
    .full .summary button {
      top: 450px;
    }
  }

  @media (max-width: 768px) {
    .full .summary button {
      top: 560px;
    }
  }

  .overlay {
    position: relative;
    width: 400px;
    height: 200px;
    padding: 30px 20px 20px 80px !important;
    margin: 40px 40px;
    background-color: $light-gray;
  }
}

.portal-call-button {
  @media (max-width: 768px) {
    display: none;
  }

  margin-top: 30px;
  margin-bottom: 20px;

  .clsh3 {
    font-size: 20px;
  }

  .clsh5 {
    margin-left: 50px;
    font-size: 14px;

    @media (max-width: 1023px) {
      margin-left: 0px;
    }

    @media (max-width: 960px) {
      margin-left: 50px;
    }

    @media (max-width: 935px) {
      margin-left: 0px;
    }
  }

  .call-icon {
    width: 36px;
    height: 36px;
    float: left;
    position: relative;
    margin-right: 7px;
    -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  }

  .call-icon.special {
    top: 0px;
  }
}

.summary.special .payment {
  @media (max-width: 768px) {
    margin-bottom: 0px !important;
    margin-top: 50px;
  }
}

.summary.special .chart {
  margin-left: auto;
  margin-right: auto;
}

.chartadj {
  @media (max-width: 767px) {
    margin-top: 5px !important;
  }
}
</style>