<template>
  <main>
    <div class="home">
      <div class="card-center" :style="[loggedIn ? { margin: margin } : '']">
        <h1 class="visually-hidden">Xact Flex</h1>
        <p class="card-txt2">Access the funds you need - when you need them.</p>
        <p class="card-header">
          Put <XactFlexBrandedText />
          to work for you!
        </p>
        <p class="card-txt">
          Lines of Credit up to
          <b>{{ currencyShort(maximumCreditAmount) }}<sup>1</sup></b>
        </p>
        <div class="card-button">
          <button
          :disabled="true"
            class="button-orange"
            alt="applynow"
            @click="applynow"
            v-if="!loggedIn" 
          >
            APPLY NOW
          </button>
          <button @click="backToAccount" class="button-orange" v-if="loggedIn">
            Back to Account
          </button>
          <p class="card-txt-blue" v-if="!loggedIn">
            Do you need to eSign?
            <router-link
              :to="{ name: 'login' }"
              class="login-link"
              v-if="!loggedIn"
              >Log In</router-link
            >
          </p>
        </div>
      </div>
    </div>

    <section class="home-links">
      <div class="links">
        <a
          href="javascript:void(0);"
          @click="goto('howitworks')"
          class="border-hover"
          role="navigation"
          aria-label="in page navigation"
          >How it Works</a
        >
        <a
          href="javascript:void(0);"
          @click="goto('whatisxact')"
          class="border-hover"
          role="navigation"
          aria-label="in page navigation"
          >What is <XactFlexBrandedTextNonTM />?</a
        >
        <a
          href="javascript:void(0);"
          @click="goto('compareyouroptions')"
          class="border-hover"
          role="navigation"
          aria-label="in page navigation"
          >Benefits to You</a
        >
      </div>
    </section>

    <section class="scrolling-wrapper hide-large-s">
      <a @click="goto('howitworks')" class="card">How it Works</a>
      <a @click="goto('whatisxact')" class="card"
        >What is <XactFlexBrandedTextNonTM />?</a
      >
      <a @click="goto('compareyouroptions')" class="card">Benefits to You</a>
    </section>

    <section class="middle" ref="howitworks">
      <h2 class="title">
        New customer? <br class="breaktag" />Here's how it works.
      </h2>
      <div class="cards">
        <div class="card iconbox bg-gray">
          <div class="icon">
            <img
              src="../../assets/icons/complete.svg"
              alt="Step 1 Apply Online Check Icon"
            />
          </div>
          <div class="text">
            <div class="big">Apply Online</div>
            <div class="small">
              You'll find out right away if<br />
              you're approved- and how much you can request.
            </div>
          </div>
        </div>
        <div class="card iconbox bg-blue">
          <div class="icon">
            <img
              src="../../assets/icons/esign.svg"
              alt="Step 2 eSign Documents Icon"
            />
          </div>
          <div class="text">
            <div class="big">eSign</div>
            <div class="small">
              eSign your line of credit documents.<br />
              You may need to create a password.
            </div>
          </div>
        </div>
        <div class="card iconbox bg-blue">
          <div class="icon">
            <img
              src="../../assets/icons/draw.svg"
              alt="Step 3 Request Funds Icon"
            />
          </div>
          <div class="text">
            <div class="big">Request Funds</div>
            <div class="small">
              While eSigning, request your first cash advance.<br />
              Just tell us how much you want.
            </div>
          </div>
        </div>
        <div class="card iconbox bg-blue">
          <div class="icon">
            <img
              src="../../assets/icons/money.svg"
              alt="Step 4 Receive the Funds Icon"
            />
          </div>
          <div class="text">
            <div class="big">Get the Funds</div>
            <div class="small">
              Your funds will be deposited into your bank account as soon as the
              next business day.<sup>2</sup>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="dev-middle">
      <div class="device-cards">
        <div class="card iconbox bg-gray">
          <div class="icon">
            <img
              src="../../assets/icons/complete.svg"
              alt="Step 1 Apply Online Check Icon For Device"
            />
          </div>
          <div class="text">
            <div class="big">Apply Online</div>
            <div class="small">
              You'll find out right away if you're approved-<br />
              and how much you can request.
            </div>
          </div>
        </div>
        <div class="card iconbox bg-blue">
          <div class="icon">
            <img
              src="../../assets/icons/esign.svg"
              alt="Step 2 eSign Documents Icon For Device"
            />
          </div>
          <div class="text">
            <div class="big">eSign</div>
            <div class="small">
              eSign your line of credit documents.<br />
              You may need to create a password.
            </div>
          </div>
        </div>
        <div class="card iconbox bg-blue">
          <div class="subtitle"></div>
          <div class="icon">
            <img
              src="../../assets/icons/draw.svg"
              alt="Step 3 Request Funds Icon For Device"
            />
          </div>
          <div class="text">
            <div class="big">Draw Funds</div>
            <div class="small">
              While eSigning, request your first cash advance.<br />
              Just tell us how much you want.
            </div>
          </div>
        </div>
        <div class="card iconbox bg-blue">
          <div class="subtitle"></div>
          <div class="icon">
            <img
              src="../../assets/icons/money.svg"
              alt="Step 4 Receive the Funds Icon For Device"
            />
          </div>
          <div class="text">
            <div class="big">Get the Funds</div>
            <div class="small">
              Your funds will be deposited into your bank account as<br />
              soon as the next business day.<sup>2</sup>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="whatisxact" ref="whatisxact">
      <div>
        <h2>What is <XactFlexBrandedText />?</h2>
        <p>
          <XactFlexBrandedTextNonTM /> is an online-only, open-ended line of
          credit. <XactFlexBrandedTextNonTM /> gives you access to the funds you
          need when you need them, up to your credit limit. Make regular
          payments as you go along and draw again when you need funds.
        </p>
        <p>
          We understand that life can be challenging. We offer financial
          solutions like the <XactFlexBrandedTextNonTM /> Line of Credit to
          help. Reach out today – we'll handle tomorrow, together.
        </p>
      </div>
    </div>

    <div class="compareyouroptions" ref="compareyouroptions">
      <h2>Benefits to You</h2>
      <table id="compareoptions">
        <tr>
          <th>Benefits</th>
          <th class="thcolor">
            <XactFlexBrandedTextNonTM /> <br />Line of Credit
          </th>
          <th class="thoths">
            Other <span style="white-space: nowrap">Short-Term</span><br />
            Loans
          </th>
        </tr>
        <tr>
          <td class="align-left">Draw Only What You Need</td>
          <td>
            <div class="checkmark"></div>
            <span class="visually-hidden">offers this</span>
          </td>
          <td></td>
        </tr>
        <tr>
          <td class="align-left">
            No Need To Reapply When You Need Funds Again
          </td>
          <td>
            <div class="checkmark"></div>
            <span class="visually-hidden">offers this</span>
          </td>
          <td></td>
        </tr>
        <tr>
          <td class="align-left">Open-Ended, Renewable Source Of Credit</td>
          <td>
            <div class="checkmark"></div>
            <span class="visually-hidden">offers this</span>
          </td>
          <td></td>
        </tr>
        <tr>
          <td class="align-left">Payments Based On What You Draw</td>
          <td>
            <div class="checkmark"></div>
            <span class="visually-hidden">offers this</span>
          </td>
          <td></td>
        </tr>
        <tr>
          <td class="align-left">No Fees Until You Initiate A Draw</td>
          <td>
            <div class="checkmark"></div>
            <span class="visually-hidden">offers this</span>
          </td>
          <td></td>
        </tr>
        <tr>
          <td class="align-left">No Penalty For Early Payment</td>
          <td>
            <div class="checkmark"></div>
            <span class="visually-hidden">offers this</span>
          </td>
          <td>
            <div class="checkmark"></div>
            <span class="visually-hidden">offers this</span>
          </td>
        </tr>
        <tr>
          <td class="align-left">Variable Interest</td>
          <td></td>
          <td>
            <div class="checkmark"></div>
            <span class="visually-hidden">offers this</span>
          </td>
        </tr>
        <tr>
          <td class="align-left">
            Financial Education with CCBank & EverFi
            <button
              class="hidden-xs button-green"
              alt="Financial Education"
              @click="financialeducation"
              target="_blank"
            >
              Go To Financial Education Center
            </button>
          </td>
          <td>
            <div class="checkmark"></div>
            <span class="visually-hidden">offers this</span>
          </td>
          <td></td>
        </tr>
      </table>
      <div class="visible-xs">
        <button
          class="button-green"
          alt="Financial Education"
          @click="financialeducation"
          target="_blank"
        >
          Go To Financial<br />Education Center
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { maximumCreditAmount } from "@/config/amounts";
import AccordionItems from "@/config/text/accordions/home";
import colors from "@/styles/colors.scss";
import { XactFlexBrandedText, XactFlexBrandedTextNonTM } from "@/components";
import { mapState, mapMutations } from "vuex";
import { currencyShort } from "@/utils/filters/currency";

export default {
  name: "Home",
  title: "Put Our Line Of Credit To Work For You",
  components: { XactFlexBrandedText, XactFlexBrandedTextNonTM },
  created() {
    if (!this.$store.getters.loggedIn) {
      this.$store.dispatch("Reset");
      this.$store.dispatch("findIP");
    }
  },
  data() {
    return {
      accordionItems: AccordionItems,
      colors: colors,
      maximumCreditAmount: maximumCreditAmount,
      currencyShort: currencyShort,
    };
  },
  computed: {
    ...mapState({
      errorMsg: (state) => state.login.errorMsg,
    }),
    loggedIn: {
      get() {
        return this.$store.state.login.loggedIn;
      },
    },

    email: {
      get() {
        return this.$store.state.login.email;
      },
      set(value) {
        this.$store.commit("updateLoginEmail", value);
      },
    },
  },

  methods: {
    ...mapMutations({
      updateInvalidEmail: "updateInvalidEmail",
      updateEmptyEmail: "updateEmptyEmail",
    }),

    applynow() {
      this.$router.push({ name: "app-personal" });
    },

    backToAccount() {
      // this.$store.commit("resetApplication");
      this.$router.push({ name: "login" });
    },

    financialeducation() {
      //window.location.href="https://ccbank.everfi-next.net"
      window.open("https://ccbank.everfi-next.net", "_blank");
    },

    async fetchTestAlsoRouteToHowItWorks() {
      // console.log("Doing API Testing Endpoint Test")
      // console.log(await Index.testQAEndpoint())
      this.$router.push({ name: "how-it-works" });
    },
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
  },
};
</script>

<style scoped lang="scss">
.accountlogin {
  display: inline-block;
  font-size: 14px;
  font-family: "AvertaStd-Regular", Helvetica, Arial, sans-serif;
  border: none;
  outline: none;
  border-radius: 6px;
  cursor: pointer;
  letter-spacing: 0.8px;
  -webkit-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
  max-width: 220px;

  @media (max-width: 767px) {
    padding: 6px 5px;
  }
}
.accountlogin:hover {
  color: $absolute-white !important;
}
#content {
  background-color: #fff;
  margin: 0 auto 1px auto;
}

main {
  .home {
    background-image: url("../../assets/backgrounds/xactflex-hero-background@2x.jpg");
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 360px;
    background-size: cover;

    .card-txt2 {
      font-size: 15px;
      color: #1e4388;
      /* padding: 0px 50px; */
      margin-bottom: -16px;
      margin-top: 30px;
    }
    .card-center {
      background-color: transparent;
      border: 0px;
    }

    .login-link {
      color: $blue;
      border-bottom: none !important;
      font-weight: 900;
      text-decoration: underline;
    }
    .form-email {
      padding: 5px 10px;
      font-size: 18px;
    }
    .field {
      margin: 0px auto;

      @media (max-width: 360px) {
        margin: -20px auto;
      }
    }
  }
}

main {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  width: 100%;

  .error {
    transform: translate(6px, -14px);
    color: $error;
    font-size: 12px;

    @media (max-width: 769px) {
      margin-bottom: 20px;
    }
    @media (max-width: 360px) {
      margin-bottom: -40px;
      margin-top: 10px;
    }
    @media (min-width: 481px) and (max-width: 537px) {
      margin-bottom: 40px;
      margin-top: -35px;
    }
    @media (min-width: 540px) and (max-width: 765px) {
      margin-bottom: 20px;
      margin-top: -20px;
    }
  }

  .button-orange {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    @media (max-width: 360px) {
      margin-top: 15px;
    }
  }

  .card-txt-blue {
    @media (max-width: 360px) {
      margin-top: 45px;
    }
  }

  .top {
    height: 700px;
    background-image: url("../../assets/backgrounds/home-bg.png");
    background-position: center;
    background-repeat: no-repeat;

    .bg-gradient {
      width: 100%;
      max-width: 2000px;
      margin: 0 auto;
      height: 100%;
      @include gradient-clear-black(180deg, 70%);
    }

    .bg-wrapper {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      height: 100%;
      max-width: 1324px;
      margin: 0 auto;
    }

    .hero {
      padding: 20px 100px;
      color: $absolute-white;

      .hero-subtitle {
        width: 300px;
        border-bottom: 1px solid $absolute-white;
      }
    }

    .boxes {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-around;
      align-items: flex-start;
      margin: 20px auto;

      .box {
        padding: 20px;
        margin: 20px 0;
        text-align: center;
        color: $absolute-white;

        .title {
          //font-size: 24px;
          font-weight: 800;
          margin-bottom: 30px;
        }
        .desc {
          font-size: 18px;
        }
        .bold {
          font-weight: 800;
        }
      }

      .divider {
        height: 190px;
        width: 0;
        border: 1px solid $absolute-white;
      }
    }
  }

  .compareyouroptions {
    overflow-x: auto;
    padding: 4% 13%;

    .thcolor {
      width: 16%;
    }
    .thoths {
      width: 18%;
    }
  }
  .middle {
    width: 1000px;
    margin: 0 auto;

    .title {
      //font-size: 24px;
      margin: 30px 0;
      text-align: center;
      font-weight: bold;
      color: $blue;

      .underline {
        border-bottom: 1px solid $green;
        width: 300px;
        padding: 0 0 6px 0;
      }
    }

    .subtitle {
      text-align: center;
      margin-top: 20px;
      font-size: 20px;
      font-weight: 600;
    }

    .cl-gray {
      color: rgba(226, 226, 227, 0.89) !important;
      visibility: hidden;

      @media (max-width: 769px) {
        visibility: hidden !important;
      }
      @media (max-width: 360px) {
        visibility: hidden;
      }
      @media (min-width: 481px) and (max-width: 537px) {
        visibility: hidden;
      }
      @media (min-width: 540px) and (max-width: 765px) {
        visibility: hidden;
      }
    }
    .cl-blue {
      color: $blue;
    }

    .cards {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
    }

    .VueCarousel {
      display: none;
    }

    .more-info {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      margin-top: 30px;
      font-size: 20px;

      button {
        margin: 10px 0;
      }
    }
  }

  .bottom {
    width: 100%;
    margin-top: 120px;
  }

  .bg-blue {
    background-color: $blue;
    color: #fff;
  }

  .card {
    box-shadow: none;
  }

  .bg-gray {
    background-color: rgba(226, 226, 227, 0.89);
    color: #53575a;
  }

  .card.iconbox {
    height: 300px;
    width: 250px;
    margin-bottom: 20px;
    padding-top: 15px;
    margin-left: -1px;

    .icon {
      flex: 0 0 110px;
      margin: 20px auto 0 auto;
    }

    .text {
      text-align: center;

      .big {
        font-size: 22px;
        margin-bottom: 12px;
        font-weight: 600;
      }

      .small {
        font-size: 16px;
        padding: 10px 25px;

        sup {
          font-size: 10px;
        }
      }
    }
  }
  .card.iconbox.green {
    background: $green;
    color: $absolute-white;

    .title {
      font-size: 32px;
      line-height: 27px;
      font-weight: 800;
      padding: 0 20px;
      margin: 20px 0 20px 0;
    }

    .text {
      text-align: left;
      padding-left: 20px;
    }

    a {
      border-bottom: 1px solid $absolute-white;
      font-weight: 700;
    }
  }

  .box2 {
    margin: 64px auto;
    border: 4px solid #ee6323;
    padding: 10px 5px;
    text-align: center;
    color: #fff;
    font-family: $font-primary;
    position: relative;
    width: 18%;
    left: 31%;
    background-color: #e75300; //#Ee6323;
    top: -35px;
  }

  .sb9:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 10px solid $orange;
    right: 50%;
    top: -23px;

    @media (min-width: 1801px) {
      right: 90%;
    }

    @media (min-width: 1530px) and (max-width: 1800px) {
      right: 75%;
    }

    @media (max-width: 1023px) {
      right: 8%;

      border-left: 16px solid transparent;
      border-right: 14px solid transparent;
      border-top: 2px solid transparent;
      border-bottom: 19px solid $orange;
    }
  }
  /*
.sb9:after {
 content: "";
 width: 0px;
 height: 0px;
 position: absolute;
 border-left: 10px solid transparent;
 border-right: 10px solid transparent;
 border-top: 10px solid transparent;
 border-bottom: 10px solid #Ee6323;
 right: 50%;
 top: -18px;
}
*/
  .box2 {
    P {
      font-size: 14px;
      color: #ffffffba;
    }
    h5 {
      font-size: 21px;
    }
  }

  .home-links {
    background-color: #fff;
    padding: 20px;

    @media (max-width: 768px) and (min-width: 429px) {
      margin-top: 50px;
      display: none !important;
    }
    @media (max-width: 768px) {
      display: none !important;
    }

    .links {
      font-size: 20px;
      color: #53575a;
      font-weight: 600;
      margin: 20px;

      @media (max-width: 560px) {
        width: 200px;
        font-size: 22px !important;
      }

      a {
        margin: 20px;

        @media (max-width: 810px) and (min-width: 768px) {
          margin: 10px;
        }
        @media (max-width: 680px) {
          margin-left: 5px;
          margin-right: 5px;
          margin-top: 10px;
          margin-bottom: 10px;
        }
        @media (max-width: 560px) {
          display: block;
          text-align: center;
        }
      }
      a:hover {
        color: $blue;
        border-bottom: 3px solid $blue;
        padding-bottom: 5px;
      }
      i {
        font-weight: normal;
      }
    }
    sup {
      font-size: 10px;
    }
    .esignlink {
      a {
        color: #ee6323;
      }
      a:hover {
        border: none;
      }
    }
  }
  html {
    scroll-behavior: smooth;
  }

  #compareoptions {
    border-collapse: collapse;
    width: 100%;
    font-size: 16px;
  }

  #compareoptions td,
  #compareoptions th {
    border: none;
    padding: 25px 10px;
  }

  #compareoptions tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  #compareoptions tr:hover {
    background-color: #ddd;
  }

  #compareoptions th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #fff;
    color: #53575a;
    text-align: center;
  }

  .checkmark {
    display: inline-block;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    height: 19px;
    width: 8px;
    border-bottom: 4px solid #ee6323;
    border-right: 4px solid #ee6323;
  }
  .breaktag {
    display: none;
  }

  .button-green {
    color: #ffffff;
    background: #119c49;
    font-size: 12px;
    font-weight: 600;
    padding: 7px 12px;
    margin-left: 10px;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }

  .hidden-xs {
    display: inline-block;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .visible-xs {
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
  }
  @media (max-width: 1023px) {
    .button-green {
      white-space: nowrap;
    }
    .breaktag {
      display: block;
    }

    .home {
      margin-top: 0;
    }

    .box2 {
      margin-right: 32%;
      margin-top: 10%;
    }
    .top {
      height: initial;
      width: 100%;
      overflow-x: hidden;
      background-position: top;
      background-size: auto 700px;
      display: flex;
      flex-flow: column nowrap;

      .bg-wrapper {
        align-items: center;
        width: 100%;
        @include gradient-clear-black(180deg, 30%);

        .hero {
          display: flex;
          flex-flow: row-reverse wrap;
          width: 340px;
          padding: 50px 10px;

          img {
            height: 50px;
          }
        }
        .hero-subtitle {
          order: 1;
        }

        .boxes {
          margin: 500px 0 0 0;
          flex-flow: column nowrap;

          .box {
            width: 100%;
            padding: 0;
          }

          .divider {
            height: 0;
            width: 340px;
          }
        }
      }
    }

    .middle {
      width: initial;

      .title {
        //font-size: 30px;
        margin: 20px;
      }

      .cards {
        display: none;
      }

      .VueCarousel {
        display: flex;
        width: 320px;
        margin-top: 50px;
      }
    }
  }
}

@media (min-width: 1023px) {
  .dev-middle {
    display: none;
  }

  .card-button {
    margin-bottom: -20px;
    margin-top: 5px;
  }
}

@media (min-width: 768px) and (max-width: 953px) {
  .button-green {
    white-space: nowrap;
    font-size: 13px;
  }

  .home {
    height: 430px !important;
  }

  .box2 {
    top: 0px !important;
    margin-top: 2% !important;
  }
  .card-center {
    margin-top: 8%;
  }

  .card-button {
    margin-bottom: -20px;
    margin-top: 23px;
  }
  .card-txt-blue {
    margin-top: 20px;
  }
}
@media (min-width: 953px) and (max-width: 1022px) {
  .card-center {
    margin-top: 6%;
  }
}

@media (min-width: 764px) and (max-width: 890px) {
  .card-center {
    width: 45%;
  }
}

@media (min-width: 842px) and (max-width: 864px) {
  .input-wrapper {
    margin-right: 10px;
  }
}

@media (max-width: 768px) {
  .button-green {
    white-space: nowrap;
    font-size: 14px !important;
    padding: 9px 50px !important;
    margin-left: 0px !important;
  }

  .home {
    height: 420px !important;
  }
  main .card.iconbox {
    padding-top: 0px;
  }
  .card-center {
    margin-top: 12%;
    width: 65%;
    left: 17%;
    height: 7%;

    .br-card-header {
      display: none;
    }
  }
  .card-sub {
    height: 2%;
  }
  .box2 {
    width: 32% !important;
  }

  .links {
    font-size: 16px !important;
    margin: 10px !important;
  }
}

@media (max-width: 624px) {
  .card-center {
    margin-top: 15%;
    width: 70%;
    left: 13%;
    height: 7%;
  }
  .card-txt {
    margin-bottom: 32px;
  }
}

@media (max-width: 580px) {
  .card-center {
    width: 75%;
    left: 10%;
  }
}

@media (max-width: 542px) {
  .card-center {
    width: 80%;
    left: 8%;
  }
}

@media (max-width: 510px) {
  .card-center {
    width: 95% !important;
    margin: 19% 2% 2% 2%;
    left: 0% !important;
  }
}
@media (max-width: 480px) {
  .card-center {
    margin: 9% 2% 2% 2%;
  }

  .card {
    justify-content: center;
  }

  .device-cards {
    .iconbox {
      margin-left: 0px !important;
    }

    .small {
      font-size: 15px !important;
      padding: 10px 20px !important;
    }
  }
}

@media (max-width: 440px) {
  .card-center {
    width: 95% !important;
    margin: 9% 2% 2% 2% !important;
  }
}

@media (max-width: 414px) {
  .home {
    background-position: unset;
    background-clip: padding-box;
  }
  .box2 {
    width: 92% !important;
    left: 2% !important;
  }
  .home-links {
    padding: 0px !important;
    margin-top: 20px;

    .links {
      margin: 5px !important;
    }
  }
}

@media (max-width: 400px) {
  .card-center {
    margin: 34% 2% 2% 2%;
  }
}
@media (max-width: 390px) {
  .card-center {
    margin: 38% 2% 2% 2% !important;
  }
}
@media (max-width: 400px) {
  .card-header {
    font-size: 24px;
  }
  .card-txt {
    font-size: 14px;
  }
}

@media (max-width: 428px) {
  .home {
    object-fit: contain;
    //height: 500px !important;
  }
  .home-links {
    display: flex;
  }
  .card-center {
    width: 100% !important;
    margin: 16% 2% 2% 0% !important;
    .card-button {
      /*margin-top: -5%;
      margin-bottom: auto;*/
      margin-bottom: -20px;
      margin-top: -7px !important;
    }
  }
  .box2 {
    width: 100% !important;
    height: 16%;
    left: -5px !important;
  }
  .middle {
    // margin-top: -5% !important;
    display: flex;
  }
}

@media (min-width: 370px) and (max-width: 428px) {
  .box2 {
    p {
      font-size: 18px !important;
    }
  }
}

.field {
  @media (max-width: 560px) {
    width: 90%;
  }
  @media (max-width: 768px) and (min-width: 540px) {
    position: relative;
    top: -20px;
  }
  @media (max-width: 539px) and (min-width: 481px) {
    position: relative;
    top: -30px;
  }
}

.button-orange {
  @media (min-width: 540px) and (max-width: 768px) {
    /*position: relative;*/
    top: -20px;
  }
  @media (min-width: 481px) and (max-width: 539px) {
    position: relative;
    top: -5px;
  }
  @media (max-width: 480px) {
    width: 60% !important;
  }
  @media (max-width: 400px) {
    width: 65% !important;
  }
  @media (max-width: 360px) {
    position: relative;
    top: 20px;
    //margin-top: 15px;
  }
}

.whatisxact {
  //text-align: justify;
  text-align-last: center;
  padding: 4% 18%;
}

@media (max-width: 980px) {
  .whatisxact {
    padding: 80px 15%;
  }
  .compareyouroptions {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .middle {
    padding-top: 60px;
  }
}

@media (max-width: 767px) {
  .whatisxact {
    text-align: center;
    padding: 80px 10%;
  }
}

@media (max-width: 428px) {
  .card-center {
    width: 99% !important;
  }
}

@media (max-width: 385px) {
  .whatisxact {
    padding: 70px 6%;
  }
}
</style>
