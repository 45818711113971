<template>
  <footer>
    <div v-if="!isEsignPagefooter">
      <section
        class="foo-links"
        v-show="!isLoginPage && !isPortalPage && !isAppPage"
      >
        <nav role="navigation" aria-label="footer navigation" class="links">
          <router-link
            :to="{ name: 'login' }"
            class="border-hover login-link"
            v-if="loggedIn && !isPortalPage"
            >Back to Account</router-link
          >
          <span class="border-md-right" v-if="loggedIn && !isPortalPage"></span>
          <router-link
            :to="{ name: 'login' }"
            class="border-hover login-link"
            v-if="!loggedIn"
            >Log In</router-link
          >
          <span class="border-md-right" v-if="!loggedIn"></span>
          <router-link :to="{ name: 'faq' }" class="border-hover"
            >FAQs</router-link
          >
          <span class="border-md-right"></span>
          <router-link :to="{ name: 'about-us' }" class="border-hover"
            >About Us</router-link
          >
          <span class="border-md-right" v-if="loggedIn"></span>
          <a class="border-hover" @click="logout" v-if="loggedIn">Log Out</a>
        </nav>
      </section>
    </div>
    <section class="consumer-notice">
      <p class="cosumerfont">Consumer Notice</p>
      <ol>
        <li>
          <p>
            Actual approved line of credit amount and terms will vary based on
            final evaluation and credit qualifications. Payment amounts vary
            based on the account balance appearing on the most recent statement
            from when funds were drawn, the actual balance carried from one
            period to the next, and payment frequency. As an example, if your
            repayment schedule is biweekly or semi-monthly and the line of
            credit balance at the end of the statement period is $1,000, you
            must pay a carried balance fee of $45 and 5% of the balance (i.e.
            $50) for a total minimum payment of $95. If your repayment schedule
            is monthly and the line of credit balance at the end of the
            statement period is $1,000, you must pay a carried balance fee of
            $90 and 10% of the balance (i.e. $100) for a total minimum payment
            of $190. In addition, each time funds are drawn from the line of
            credit, a cash advance fee will be assessed. Bi-weekly and
            semi-monthly customers pay a 5% cash advance fee while monthly
            customers pay a 10% cash advance fee.
          </p>
        </li>
        <li>
          <p>
            Approval depends upon meeting legal, regulatory and underwriting
            requirements. All times and dates are based on Eastern Time (ET). If
            your credit is approved and originated by 7:45 p.m. ET on a business
            day, your funds will typically become available on the next business
            day. The lender may, at their discretion, verify application
            information by using national databases that may provide information
            from one or more national credit bureaus, and the lender may take
            that into consideration in the approval process.
          </p>
        </li>
        <li>
          <p>Availability restrictions apply.</p>
          <p>
            XactFlex is a line of credit that is originated and funded by
            Capital Community Bank a Utah Chartered Bank, located in Provo, UT,
            Member FDIC.
          </p>
        </li>
      </ol>
      <p>
        <b
          >Important Information About Procedures for Opening a New Account or
          Applying for a Loan</b
        >: To help the government fight the funding of terrorism and money
        laundering activities, Federal law requires all financial institutions
        to obtain, verify, and record information that identifies each person
        who opens an account or applies for a loan. What this means for you:
        When you open an account or apply for a loan, we will ask for your name,
        street address, date of birth, tax identification number and other
        information that will allow us to identify you. We may also ask to see
        your driver's license, or other identifying documents.
      </p>
      <p>
        <b
          ><a
            href="./pdf/sc-consumer-pamphlet-xactflex.pdf"
            target="_blank"
            aria-label="Link to South Carolina Consumer Disclosure PDF"
            rel="noopener noreferrer"
            >South Carolina Consumer Disclosure (PDF)</a
          ><br
        /></b>
      </p>
    </section>
    <section class="bottom-links">
      <div class="bottom">
        <nav role="navigation" aria-label="footer privacy links" class="links">
          <router-link
            :to="{ name: 'privacy' }"
            target="_blank"
            class="no-border"
            aria-label="Link to open Privacy Policy page"
          >
            Privacy Policy
          </router-link>
          <div class="divider">|</div>
          <a
            href="./pdf/glba-privacy-notice_afs_9.2022.pdf"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Link to open Privacy Notice AFS PDF"
            >Privacy Notice AFS (PDF)</a
          >
          <!-- </router-link> -->
          <div class="divider">|</div>
          <!-- <router-link :to="{ name: 'privacy' }" class="no-border"> -->
          <a
            href="./pdf/ccb-privacy-notice.pdf"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Link to open Privacy Notice CCB PDF"
            >Privacy Notice CCB (PDF)</a
          >
          <!-- </router-link> -->
          <div class="divider">|</div>
          <router-link
            :to="{ name: 'TermsAndConditions' }"
            target="_blank"
            class="no-border"
            aria-label="Link to open Website Terms and Conditions page"
          >
            Website Terms and Conditions
          </router-link>
        </nav>
        <div class="terms">
          <p>
            {{ companyInfo.year }} Xact<i>Flex</i
            ><sup><span class="trademark">&reg;</span></sup>. All rights reserved.
          </p>
        </div>
      </div>
    </section>
    <section class="foo">
      <div class="foo-declaration">
        <div>
          <b>Contact Us</b>
          <p>
            Our customer service representatives are available to help 24/7.
          </p>
          <p>
            <a
              class="fe-border"
              :href="'tel:' + companyInfo.customerServicePhone"
              >{{ companyInfo.customerServicePhone }}</a
            >
          </p>
          <br />
          <p>{{ companyInfo.address_dec }}</p>
          <p>{{ companyInfo.address_dec2 }}</p>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
import companyInfo from "@/config/text/company";
import Legal from "@/config/text/legal";
import { pagesWithSameDay, pagesWith3k } from "@/config/disclaimers";
export default {
  name: "Footer",
  data() {
    return {
      companyInfo: companyInfo,
      legal: Legal,
    };
  },
  computed: {
    showSameDayDisclaimer: function () {
      return pagesWithSameDay.includes(this.$route.name);
    },
    show3kDisclaimer: function () {
      return pagesWith3k.includes(this.$route.name);
    },
    loggedIn: {
      get() {
        return this.$store.state.login.loggedIn;
      },
    },
    displayLogin: function () {
      return this.$route.meta.showLogin && !this.loggedIn;
    },
    isLoginPage: function () {
      return this.$route.meta.isLoginPage;
    },
    isPortalPage: function () {
      if (
        this.$route.meta.portal == true ||
        this.$route.meta.isPortalNavDisplay == true
      ) {
        return true;
      }

      return false;
    },
    isEsignPagefooter: function () {
      return this.$route.meta.isEsignPagefooter;
    },
    isAppPage: function () {
      return this.$route.meta.isAppPage;
    },
  },
  methods: {
    async logout() {
      if (this.$store.state.login.token == undefined) {
        const tokenResult = await this.$store.dispatch("createToken");
        this.$store.state.login.token = tokenResult.token.writtenToken;
      }
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style scoped lang="scss">
footer {
  .top {
    display: block;
    flex-flow: column nowrap;
    justify-content: space-around;
    align-items: center;
    background: $white;
    color: $gray;
    padding-top: 25px;
    margin-top: 100px;

    #xactflex-logo {
      height: 60px;
      padding: 10px;
    }

    .questions {
      text-align: center;

      .title {
        color: $orange;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 6px;
      }

      .phone {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 6px;
      }
    }

    .legal {
      font-size: 10px;
      font-weight: 700;
      line-height: 18px;
      text-align: center;
      white-space: pre-line;
      margin: 20px 0;
      padding: 20px;
      max-width: 1100px;
    }
  }

  .trademark {
    font-size: 12px;
    vertical-align: center;
    font-weight: 500;
  }

  .bottom {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    background: $gray;
    color: $absolute-white;
    border-bottom: 1px solid $absolute-white;

    a {
      font-size: 12px;
    }
    .logos {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: flex-end;
      margin: 30px 0 10px 0;

      img {
        height: 40px;
        margin: 0 4px;
      }
    }

    .links {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 10px;
      width: 90%;
      margin: 10px auto;

      .divider {
        margin: 0 14px;
      }
      a {
        line-height: 2;
      }
    }

    .contact {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 60px;

      .address {
        text-align: right;
        margin-right: 30px;

        .sitemap {
          font-size: 12px;
        }
      }

      .title {
        font-size: 12px;
        font-weight: 800;
      }
    }
  }

  .consumer-notice {
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    white-space: pre-line;
    //padding: 20px;
    max-width: 100%;
    padding: 5% 10%;
    background-color: #e0e0e0;
    color: $gray;
    padding-bottom: 0.75%;
    padding-top: 40px !important;

    .cosumerfont {
      font-size: 20px;
      margin: 0px;
      font-weight: 600;
    }
    ol {
      padding-left: 12px;
    }
    p {
      margin: 20px 0px;
    }
  }
  .border-md-right {
    border-right: 1px solid silver;
  }

  .foo-links {
    background-color: white;
    padding: 15px;
    border: 1px solid #e0e0e0;

    @media (max-width: 2900px) {
      padding-left: 0px;
    }
    @media (max-width: 1900px) {
      padding-left: 0px;
    }
    @media (max-width: 1650px) {
      padding-left: 00px;
    }

    @media (max-width: 1450px) {
      padding-left: 0px;
    }
    @media (max-width: 1230px) {
      //padding-left: 13%;
    }
    @media (max-width: 1200px) {
      //padding-left: 66px;
    }
    @media (max-width: 1187px) {
      //padding-left: 77px;
    }
    @media (max-width: 1023px) {
      // padding-left: 10px;
    }
    @media (max-width: 500px) {
      padding-left: 40px !important;
      margin-left: -32px;
    }

    .router-link-active {
      color: $blue !important;
    }

    .links {
      display: flex;
      justify-content: space-around;
      //width: 330px;
      // margin: 0 6px;
      // margin-left: 115px;
      font-weight: bold;
      color: $gray;

      .login-link {
        color: $blue;
      }
      .login-link:hover {
        color: $orange !important;
      }
      a:hover {
        color: $orange !important;
      }
      a:focus {
        color: #1e4488;
      }
    }
  }

  .bottom-links {
    h5 {
      font-size: 10px;
      text-transform: uppercase;
    }
    .terms {
      sup {
        font-size: 5px;
      }
      p {
        font-size: 12px;
        margin-bottom: 20px;
      }
      i {
        font-weight: normal !important;
      }
    }
    a {
      border-bottom: none;
    }
  }
  .foo {
    //background-color: #1e4488;
    //width: 100%;

    background-image: url("../../assets/backgrounds/foo-arrow.png");
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 130px;
    background-size: cover;
  }

  .foo-declaration {
    background-color: #1e4488;
    padding: 15px;
    padding-left: 10%;
    width: 50%;
    margin-left: 10%;

    P {
      font-size: 12px;
      color: white;
      margin: 2px;
      a {
        border-bottom: none;
      }
    }

    b {
      color: white;
    }
  }

  @media (max-width: 1023px) {
    .top {
      #xactflex-logo {
        height: 40px;
        padding: 10px;
      }

      .legal {
        font-size: 9px;
      }
    }

    .bottom {
      padding-bottom: 60px;

      .links {
        flex-flow: row wrap;
        //width: 300px;
      }

      .contact {
        flex-flow: column-reverse nowrap;

        .address {
          text-align: left;
          margin-top: 10px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .bottom {
    padding-bottom: 0px !important;
  }
  .terms {
    margin-top: 20px;
  }
  .foo {
    background-position: 85% !important;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    height: 200px !important;
  }
  .links {
    flex-flow: row wrap !important;
    width: 90% !important;
    //flex-flow: row nowrap !important;
    text-align: baseline;
    //margin-left: 40px !important;
    margin: 0px auto;
  }
  .foo-declaration {
    background-color: #1e448800 !important;
    padding-left: 5% !important;
    width: 80% !important;
    padding-top: 11% !important;
  }
  .foo-links {
    padding-left: 0px !important;
  }
  /*.divider{
      min-inline-size: -webkit-fill-available;
      color: #53575a;
    }*/
}

@media (min-width: 480px) {
  .bottom {
    padding-bottom: 0px !important;
  }
}
@media (min-width: 600px) {
  .bottom {
    padding-bottom: 0px !important;
  }
}
</style>