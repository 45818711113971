<template>
  <div class="field">
    <p class="field-label">
      <slot name="label" />
    </p>
    <div class="input-wrapper" :class="{ 'field-error': errormsg }">
      <slot class="input" />
    </div>
    <div class="error" v-if="errormsg">
      <slot name="error" />
    </div>
  </div>
</template>

<script>
export default {
  name: "FormField",
   props: {
    errormsg: {
      type: String
    }
  },
};
</script>

<style scoped lang="scss">
.rightcontentsection p.field-label {
  margin-bottom: 1px;
  @media (max-width: 767px) {
    margin-left: 0px;
  }
}

.contentsection-password.esigncreatepwdchk {
  p.field-label {
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .field {
    max-width: 315px;
  }
}

.esignpasscodetxt .field .input-wrapper {
  width: 80%;
  @media (max-width: 767px) {
    width: 100%;
  }
}

.field {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 4px;
  width: 340px;

  .field-label {
    display: flex;
    flex-flow: row nowrap;
    margin: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .input-wrapper {
    flex: 1 1 100%;
    display: flex;
    height: 36px;

    input {
      flex: 1 1 100%;
      height: 26px;
    }

    &.field-error {
      input {
        border: 1px solid $error;
      }
    }
  }

  .error {
    height: 15px;
    // max-width: 312px;
    width: auto;
    //margin: 10px 5px;
    margin: 5px;
    font-size: 12px;
    color: $error;
  }

  &.short {
    width: 150px;

    input {
      width: 0;
    }
  }

  &.medium {
    width: 225px;
  }
}

//application last pay date error msg device alignment
.lastdates .field .error {
  @media (max-width: 767px) {
    width: 120%;
  }
}
</style>