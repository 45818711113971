<template>
  <div class="vld-parent mb-3">
    <loading
      v-model:active="isLoading"
      :is-full-page="fullPage"
      :height="height"
      :width="width"
      :color="color"
      :loader="loader"
      background-color="#2b2a2a"      
    >
      <!-- <h3 v-if="useSlot">Loading ...</h3> -->
    </loading>
    <div
    v-if="isLoading"
      tabindex="0"
      aria-label="Loading"
      class="vld-overlay is-active"
      style="display: none"
    >
      <div class="vld-background" style="background: rgb(255, 255, 255)"></div>
      <div class="vld-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 30 30"
          height="128"
          width="128"
          fill="#007bff"
        >
          <rect x="0" y="10.6313" width="4" height="9.73744">
            <animate
              attributeName="height"
              attributeType="XML"
              values="5;21;5"
              begin="0s"
              dur="0.6s"
              repeatCount="indefinite"
            ></animate>
            <animate
              attributeName="y"
              attributeType="XML"
              values="13; 5; 13"
              begin="0s"
              dur="0.6s"
              repeatCount="indefinite"
            ></animate>
          </rect>
          <rect x="10" y="11.3687" width="4" height="8.26256">
            <animate
              attributeName="height"
              attributeType="XML"
              values="5;21;5"
              begin="0.15s"
              dur="0.6s"
              repeatCount="indefinite"
            ></animate>
            <animate
              attributeName="y"
              attributeType="XML"
              values="13; 5; 13"
              begin="0.15s"
              dur="0.6s"
              repeatCount="indefinite"
            ></animate>
          </rect>
          <rect x="20" y="7.36872" width="4" height="16.2626">
            <animate
              attributeName="height"
              attributeType="XML"
              values="5;21;5"
              begin="0.3s"
              dur="0.6s"
              repeatCount="indefinite"
            ></animate>
            <animate
              attributeName="y"
              attributeType="XML"
              values="13; 5; 13"
              begin="0.3s"
              dur="0.6s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </svg>
      </div>
    </div>
  </div>
</template>
 
<script>
// Import component
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/css/index.css';

export default {
  data() {
    return {
      fullPage: true,
      useSlot: false,
      loader: "dots",//dots, bars,spinner
      color:"#EE6323",
      height: 100,
      width: 100,
      //timeout: 3000, //ms
      //Test Loader feature
      // isLoading: true,
    };
  },

  components: {
    Loading
  },
  computed: {
    //cache: false,
     isLoading: {
      get() {   return this.$store.state.account.isLoading }
     },
  }
};
</script>
<style lang="scss" scoped>
.vld-shown {
  overflow: hidden;
}

.vld-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  align-items: center;
  display: none;
  justify-content: center;
  overflow: hidden;
  z-index: 10001;
}

.vld-overlay.is-active {
  display: flex;
  background: #2b2a2a;
  opacity: 0.5;
}

.vld-overlay.is-full-page {
  z-index: 10001;
  position: fixed;
}

.vld-overlay .vld-background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background: #f0f0f0;
  opacity: 0.5;
}

.vld-overlay .vld-icon,
.vld-parent {
  position: relative;
}
</style>