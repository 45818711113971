<template>
  <div class="bgcontainer">
    <div class="bglayout">
      <div class="helloAcct">
        <div class="clsh6">Welcome back, {{ firstName }}</div>
      </div>

      <main class="portal-wrapper">

        <PortalNav page="draw"/>

        <div class="portal cardPortal card">
          <h1 class="visually-hidden">Your Account</h1>
          <PortalLoader/>          
          <div class="portal-body">
            <PortalDrawReqMobile/>
            <div class="vertical-line"><svg xmlns="http://www.w3.org/2000/svg" width="1" height="450" viewBox="0 0 1 450"><path d="M1,150H0V450H1Z" fill="#ededed"/></svg></div>
            <PortalAccountSum/>
          </div>
        <div class="portal-footer">
            <div class="portal-call-button cardNew">
              <img
                src="../../assets/icons/questions.png"
                alt="Call Us"
                class="call-icon"
              />
              <div v-if="!chargeoff && !delinquent">
                <div class="clsh3">Questions?</div>
                <div class="clsh5">
                  Call Us:
                  <a
                    class="no-border no-hover de-border"
                    :href="'tel:' + companyInfo.contactPhone"
                    >{{ companyInfo.contactPhone }}</a
                  >
                </div>
              </div>
              <div v-else>
                <div class="clsh3">Questions</div>
                <div class="clsh5 acctSpec">
                  Talk to an Account <br />
                  Specialist:
                  <a
                    class="no-border no-hover de-border"
                    :href="'tel:' + companyInfo.advancedCustomerServicePhone"
                    >{{ companyInfo.advancedCustomerServicePhone }}</a
                  >
                </div>
              </div>
            </div>
          </div>
           </div>
      </main>
    </div>
  </div>
</template>

<script>
import {
  PortalNav,
  PortalDrawReqMobile,
  PortalAccountSum,
  PortalLoader
} from '@/components';
import companyInfo from "@/config/text/company";
/*import { lvxScript,
          lvxURL } from "@/config/amounts";*/

export default {
  name: 'PortalAccount',
  title: "Request A Cash Advance",
  components: {
    PortalNav,
    PortalDrawReqMobile,
    PortalAccountSum,
    PortalLoader
  },
  async created() {
    this.$store.state.account.isLoading= false;   

      /*if (document.head.children["lvxChatScript"] == null && lvxScript != null && lvxScript != "") {
          let startScript = document.createElement('script');
          startScript.setAttribute('src', lvxURL);
          startScript.setAttribute('id', 'lvxChatInclude');
          startScript.onload = () => {
            let widgetScript = document.createElement('script');
            widgetScript.setAttribute('id', 'lvxChatScript');
            widgetScript.append(lvxScript);
            document.head.appendChild(widgetScript);
          };
          document.head.appendChild(startScript);
        }*/
  },
  computed: {
    firstName: {
      get() { return this.$store.state.account.firstName }
    },
    chargeoff: {
      get() {
        return this.$store.state.account.chargeoff;
      },
    },
    delinquent: {
      get() {
        return this.$store.state.account.delinquent;
      },
    },
},
  data() {

    return {
      companyInfo: companyInfo,
      /*lvxScript: lvxScript,
      lvxURL: lvxURL*/
    }
  }
}
</script>

<style scoped lang="scss">
.portal-wrapper {
  @media (max-width: 960px) {
    flex-flow: column nowrap !important;
  margin-top: 25px;
    .portal {
  margin: 0px 0px 20px 0px;
    }
  }
}

.portal-body {
  height:max-content;
}

.helloAcct {
  display:none;

  @media (max-width: 1023px) {
    margin-top:20px;
    text-align:center;
    display:block;
  }
}

.vertical-line {
  display:block;
  @media (max-width:768px) {
    display: none;
  }
}
.table-title {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 40%;
  min-width: 220px;

  .title {
    font-size: 18px;
  }

  a {
    color: $gray;
    font-size: 12px;

    &:hover {
      color: $green;
    }
  }
}

.cardPortal {
  background: $absolute-white;
}

.pair {
  width:95%;
}

.pair .left {
  width:65%;
  float:left;
}

.pair .right {
  width:30%;
  float:right;
}


.bgcontainer{
  background-image: url(../../assets/backgrounds/xactflex-hero-background@2x.jpg);
  background-size: cover;
}
.bglayout{
  
  width: 85%;
  margin: 0px auto;
  @media (min-width: 396px) and (max-width: 444px) {
    width:95%;
  }
  @media (min-width: 360px) and (max-width: 395px)
  {
    width: 95%;
  }
}

.portal-call-button {
  @media (min-width: 768px) {
    display: none;
  }
}

.portal-wrapper .portal .cardNew.portal-call-button {
  padding-right: 0px;
  margin-right: 0px;
  margin-top: 80px;
  @media (max-width: 768px) {
   margin: 20px auto;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .clsh3 {
    font-size: 20px;
  }
  .clsh5 {
    font-size: 18px;

    @media (min-width: 961px) and (max-width: 971px) {
      font-size: 16px;
    }

    @media (min-width: 784px) and (max-width: 842px) {
      font-size: 16px;
    }

    @media (min-width: 769px) and (max-width: 783px) {
      font-size: 15px;
    }
  }
}

.portal-footer {
  width: 30%;
  float: right;
  @media (max-width: 768px) {
    width: 100%;
    float: none;
  }
}

.call-icon {
  width: 40px;
  height: 40px;
  float: left;
  position: relative;
  top: 8px;
  margin-right: 10px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

</style>