import companyInfo from '../company';
import {
  minimumCreditAmount,
  maximumCreditAmount,
  minimumDraw
} from "../../amounts";
import { currencyShort } from "@/utils/filters/currency";

export default [
  {
    title: `<h4>About XactFlex™</h4>`,
    value: `<b>Q. What is the <span style="white-space: nowrap;"><span id="xact">Xact</span><span id="flex"><i>Flex</i></span><span style="font-size: 8px;vertical-align: middle;font-weight:500;">&trade;</span></span> <b>line of credit (LOC)?</b>

    The XactFlex™ line of credit is an open-ended, flexible account that you can draw from as needed. When you’re approved, you will be assigned a credit limit. You can draw cash as needed, up to your credit limit, as long as you keep current with your payments. A Cash Advance Fee is charged when you make a draw. Depending on your balance, a Carried Balance Fee may be also be charged.
    
    <b>Q. What is the benefit of the XactFlex™ line of credit?</b>
    The main advantage is the ability to borrow only the amount needed and avoid paying interest on a large loan. You can access your line of credit whenever a funding need arises.
    
    <b> Q. How much can I borrow on my XactFlex™ line of credit?</b>
    You will be assigned a credit limit between ${ currencyShort(minimumCreditAmount) } and ${ currencyShort(maximumCreditAmount) } when you’re approved.
    
    <b> Q. Is there a minimum cash draw on my line of credit?</b>
    Yes. The minimum draw is ${ currencyShort(minimumDraw) }.
    
    <b> Q. What happens with my XactFlex™ account if I don’t make a draw?</b>
    Your account will stay open and available for draws for at least 12 months. If you activate your account but don’t take a draw for the first 12 months, your account will automatically be closed.  If you have made at least one draw, but haven’t had a balance on your account or account activity for two years, your account will be closed. You will be sent notice of the impending closure approximately 30 days before it occurs. If your account is closed, you can reapply on the XactFlex™ website. 
    `
  },
  {
    title: `<h4>About Lines of Credit</h4>`,
    value: `<b>Q. What’s a credit limit?</b>
    A credit limit is the maximum amount of money you can borrow as extended by a bank or other financial institution on a credit card or a line of credit. Your credit limit will be assigned at the time your line of credit is approved.
    
    <b> Q. What does it mean to default on a line of credit?</b>
    A borrower can default on a line of credit by failing to make payments by the due dates; by breaching terms and conditions; or by providing false, inaccurate, or misleading information.
    
    <b> Q. Where is my credit statement?</b>
    Credit statements are generated at least 14 days prior to the payment due date for each billing cycle. Payments are due at least 14 days after your first cash advance and correspond with your pay date and pay frequency. Log in to view your statement electronically.
    
    <b>Q. Is there a penalty for paying more than my required payment?</b>
    
    No. Customers can pay any amount over their required payment with no early payment fees.
    
    <b> Q. How do I change my billing cycle?</b>
    Billing cycle due dates correspond with customers’ pay dates and pay frequencies. If a payment due date falls on a non-business day, the payment will be credited on your account as
    of the payment due date – but it will be processed the next business day. Customers can request billing cycle changes or payment due date changes by calling Customer Service at <a class="no-border" href="tel:${ companyInfo.customerServicePhone }">${ companyInfo.customerServicePhone }</a>.
    
    <b>Q. How do I pay off my line of credit?</b>
    Payments are applied to any outstanding required payment for which a statement has been issued in the order of their due dates, beginning with the earliest due date. Payments are applied to each required payment in the following order: Carried Balance Fees and any other fees, then any remaining portion of the required payment. Once all required payments have been satisfied, then payments will be applied to any balance that remains unpaid until the line of credit reaches zero.
    
    <b>Q. How can I increase the amount on my line of credit?</b>
    To request an increase to your credit limit, please call Customer Service at <a class="no-border" href="tel:${ companyInfo.customerServicePhone }">${ companyInfo.customerServicePhone }</a>.
     
    <b>Q. How much does a line of credit cost?</b>
    Simply having a line of credit costs nothing. However, once you draw on your line of credit you will be charged a Cash Advance Fee and when your payment is due you will be charged a Carried Balance Fee.
    
    <b>Q. How do I eSign my line of credit documents?</b>
    Follow the prompts on the homepage to log in. If you haven’t already, you’ll need to create a password. Once you log in, you’ll be directed to the eSign screen. To eSign, answer the questions, review your line of credit agreement, the privacy policy and any other terms or conditions, then eSign and submit. Your eSignature is legally binding.
    `
  },
  {
    title: `<h4>Repayments and Your Account</h4>`,
    value: `<b>Q. When and how will I receive my funds when I request a draw?</b>
    Once you’ve eSigned, your line of credit will be processed. If you requested a draw during eSignature and we are able to verify your information and originate your line of credit before 8 p.m. Eastern Time, in most cases you can expect to receive your funds the next
    business day.
    
    If you request a draw on an existing credit line before 8 p.m. Eastern Time on a banking business day, in most cases, you can expect to receive your funds the next business day.
    
    We are unable to process draws on days when banks are closed. If you request a draw late in the day before a banking holiday, or on a banking holiday, your request will be processed the next business day, and you can expect to receive your funds on the second business day following your request.
    
    <b>Q. How is my line of credit serviced?</b>
    You can view and manage your account online or by calling Customer Service. You can schedule payments, see your credit availability and request draws online from your account page after logging in. You can also call Customer Service at 1.833.546.2135 <a class="no-border" href="tel:${ companyInfo.contactPhone }">${ companyInfo.contactPhone }</a> to conduct transactions, or you can write the Customer Service team.
    
    <b>Q. Can I change my line of credit payment due date?</b>
    Yes, you may be able to change your payment due date by calling or mailing the Customer Service team.
    
    <b>Q. How is my line of credit payment date calculated?</b>

    Your line of credit payment dates are based on the information about your paycheck frequency and next pay date that you provided when you applied.
    
    <b>Q. When will my line of credit payments be due – and what are my repayment options?  </b>

    Your payments will typically be automatically withdrawn from your bank account on your due date. To view your payment schedule, refer to your line of credit agreement. To pay down your balance faster, you can set up additional payments online or by calling Customer Service.
    
    
    <b>Q. How can I view my account information?</b>

    Just log in. You’ll be able to access your information through your account page after logging in. You can check your account status, see when you next payment is due, schedule additional payments, review your documents and change your communication preferences.
    `
  }
  
]