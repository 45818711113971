<template>
  <!-- <div> -->
    <!-- <div class="message card" v-if="!delinquentOrchargeoff">
      {{ message }}
    </div> -->
    <div class="delinquent card" v-if="delinquentOrchargeoff">
      <div class="title">
        YOUR ACCOUNT IS PAST DUE.
      </div>
      <div class="text">
        Please bring your account up to date.    
        <router-link :to="{ name: 'portal-account' }" class="no-border" @click="makePayment">
           <u>Make a payment online</u>            
        </router-link>       
         or contact an Account Specialist at
        <br><a :href="'tel:' + companyInfo.advancedCustomerService" class="no-border no-hover">
          {{ companyInfo.advancedCustomerService }}</a>.
      </div>
    </div>
    <!-- <div class="open" @click="openDrawer">
      <i class="fas fa-list-ul"/>
    </div> -->
    <!-- <PortalDrawer :direction="'left'" :exist="true" :page="page" ref="PortalDrawer"/>  -->
  <!-- </div> -->
</template>

<script>
import companyInfo from "@/config/text/company";
// import PortalDrawer from "@/components/portal/PortalDrawer";
//import bus from "@/main.js";
import { inject } from "vue";

export default {
  name: 'PortalMessage',
  // components: { PortalDrawer },   
  props: ['page'],
  async created() {
    if (!this.message) {
      await this.$store.dispatch('getPortalMessage');
    }     
  },
  computed: {
    message: {
      get() { return this.$store.state.portal.messageText }
    },
    delinquent: {
      get() { return this.$store.state.account.delinquent }
    },
    chargeoff: {
      get() { return this.$store.state.account.chargeoff }
    },
    delinquentOrchargeoff: {
      get() {  
          if (this.delinquent || this.chargeoff)
              return true;
          else
              return false;
      }
    },
  },
  data() {
    return {     
      companyInfo: companyInfo      
    }
  },
  methods: {
    openDrawer() {
      this.$refs.PortalDrawer.open();
    },

    makePayment() {
      if (this.$store.state.account.creditLimit > 0) {
        this.$store.commit("updatePaymentAmount", null);
        this.$store.commit("updatePaymentDate", null);
        this.$store.commit("updateSelectedAmount", null);
        this.$store.commit("updateOtherAmount", null);
        this.$data.activeFolder = "amount";
        //bus.$emit("pAccountSumEmit", this.$data.activeFolder);        
        const emitter = inject("emitter");
        emitter.emit("pAccountSumEmit", this.$data.activeFolder);
      }
    },
  },
};
</script>

<style scoped lang="scss">
/*.message {
  margin-bottom: 6px;
  padding: 30px 18px;
  background: $green;
  color: $absolute-white;
  font-size: 26px;
  font-weight: 700;

  @media (max-width: 1150px) {
    padding: 20px 14px;
    font-size: 20px;
  }

  @media (max-width: 1023px) {
    font-size: 18px;
    text-align: center;
    &.card {
      width: 100%;
      padding: 30px 20px 20px 80px !important;
      margin: 0 !important;
    }
  }
}*/
.delinquent {
  margin-bottom: 6px;
  padding: 30px 18px;
  background: $error;
  color: $absolute-white;

.delinquent_chargeoff {
  margin-bottom: 6px;
  padding: 30px 18px;
  background: $error;
  color: $absolute-white;
  }

  .title {
    font-family:$font-primary;// $font-secondary;
    font-size: 16px;
    line-height: 18px;
  }

  .text {
    font-size: 12px;
    margin-top: 10px;
  }

  @media (max-width: 1150px) {
    padding: 20px 14px;
  }

  @media (max-width: 1023px) {
    font-size: 18px;
    text-align: center;
    &.card {
      width: 100%;
      padding: 30px 20px 20px 80px !important;
      margin: 0 !important;
    }
  }  
}

.open {
  display: none;

  @media (max-width: 1023px) {
    display: initial;
    position: absolute;
    height: 30px;
    width: 30px;
    padding: 5px;
    margin: 20px 20px;
    border-radius: 20px;
    background: $purple;
    color: $absolute-white;
    font-size: 24px;
    text-align: center;
    box-shadow: 0 1px 1px 1px rgba(0,0,0,.3);
  }
}
</style>