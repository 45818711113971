<template>
  <main>
    <PortalLoader />
    <h1 class="visually-hidden">eSign Your Line of Cred Documents</h1>
    <section class="form form-layout">
      <div id="divConsentHeader" class="consentheader" tabindex="-1">
        <h2>
          {{ firstName }}, you’ve been conditionally <br class="hidden-xs" />
          approved for a
          <span class="blue"
            >{{ currencyShort(creditLimit) }} line of credit.<sup>2</sup></span
          >
        </h2>
      </div>
      <div class="consentheaderline">
        <div style="border-top: 1px solid gray; width: 100%">&nbsp;&nbsp;</div>
      </div>
      <br />
    </section>
    <section class="form sectionborder">
      <div class="row">
        <div class="sectionspacing">
          <div><h2>Agreements</h2></div>
          <div>All fields are required unless otherwise noted.</div>
          <br />
          <div>
            <div class="inputs">
              <div style="width: 260px">
                <span>
                  <label class="input-checkbox-wrapper">
                    <span class="italic"> </span>
                    <input
                      type="checkbox"
                      :disabled="IsAgreeArbdoctermsDisabled"
                      v-model="IsAgreeArbdocterms"
                      @change="HideErrorMessage('AgrArbitration')"
                      tabindex="-1"
                      aria-label="Read & Agree to the Arbitration Agreement"
                    />
                    <span
                      id="spanChkAgrArbitration"
                      role="checkbox"
                      :tabindex="IsAgreeArbdoctermsDisabled ? '-1' : '0'"
                      class="input-checkbox input-checkbox-sm"
                      :class="
                        IsAgreeArbdoctermsDisabled
                          ? 'input-checkbox-disabled'
                          : 'input-checkbox-enabled'
                      "
                      aria-label="Read & Agree to the Arbitration Agreement"
                      aria-describedby="errArbAgrDoc"
                      :aria-checked="IsAgreeArbdocterms == 'agreementselect' ? true : null"
                    />
                  </label>
                </span>
                <span style="padding-left: 5px">
                  <button
                    id="btnAgrArbitration"
                    :class="clsAgrArbitration"
                    @click="loadloandocument('arbitrationAgreement')"
                    aria-label="Read & Agree to the Arbitration Agreement"
                  >
                    READ & AGREE
                  </button>
                </span>
              </div>
              <div class="agrdocdesc">
                <label for="btnAgrArbitration"
                  >I have read, understand and accept the terms of the
                  Arbitration Agreement.
                </label>
              </div>
            </div>
            <div>
              <div
                v-if="!IsAgreeArbdoctermsDisabled && !IsAgreeArbdocterms"
                class="error"
                id="errArbAgrDoc"
                role="alert"
              >
                Please read & agree to the Arbitration Agreement
              </div>
            </div>
          </div>

          <!-- Arbitration end-->

          <div style="padding-top: 15px">
            <div class="inputs">
              <div style="width: 260px">
                <span>
                  <label class="input-checkbox-wrapper">
                    <span class="italic"> </span>
                    <input
                      type="checkbox"
                      :disabled="IsAgreeEleConsentdoctermsDisabled"
                      v-model="IsAgreeEleConsentdocterms"
                      @change="HideErrorMessage('AgrEleConsent')"
                      tabindex="-1"
                      aria-label="Read & Agree to the Consent for Electronic Records"
                    />
                    <span
                      id="spanChkAgrEleConsent"
                      role="checkbox"
                      :tabindex="IsAgreeEleConsentdoctermsDisabled ? '-1' : '0'"
                      class="input-checkbox input-checkbox-sm"
                      :class="
                        IsAgreeEleConsentdoctermsDisabled
                          ? 'input-checkbox-disabled'
                          : 'input-checkbox-enabled'
                      "
                      aria-label="Read & Agree to the Consent for Electronic Records"
                      aria-describedby="errEleConsentDoc"
                      :aria-checked="IsAgreeEleConsentdocterms == 'agreementselect' ? true: null"
                    />
                  </label>
                </span>
                <span style="padding-left: 5px">
                  <button
                    id="btnAgrEleConsent"
                    :class="clsAgrEleConsent"
                    @click="loadloandocument('agreleconsent')"
                    aria-label="Read & Agree to the Consent for Electronic Records"
                  >
                    READ & AGREE
                  </button>
                </span>
              </div>
              <div class="agrdocdesc">
                <label for="btnAgrEleConsent">
                  I have read, understand and accept the terms of the Consent
                  for Electronic Records.
                </label>
              </div>
            </div>
            <div>
              <div
                v-if="
                  !IsAgreeEleConsentdoctermsDisabled &&
                  !IsAgreeEleConsentdocterms
                "
                class="error"
                id="errEleConsentDoc"
                role="alert"
              >
                Please read & agree to the Consent for Electronic Records
              </div>
            </div>
          </div>

          <!-- Electronic Consent Ends -->

          <div style="padding-top: 40px">
            <div><h2>Document eSign</h2></div>
            <div>
              You’re almost done. To continue, read and agree to each of the
              documents below.
            </div>
          </div>

          <div>
            <div class="inputs" style="padding-top: 15px">
              <div style="width: 260px">
                <span>
                  <label class="input-checkbox-wrapper">
                    <span class="italic"> </span>
                    <input
                      type="checkbox"
                      :disabled="IsAgreeCreditdoctermsDisabled"
                      v-model="IsAgreeCreditdocterms"
                      @change="HideErrorMessage('AgrCredit')"
                      tabindex="-1"
                      aria-label="Read & Agree to the terms of Credit Agreement"
                    />
                    <span
                      id="spanChkAgrCredit"
                      role="checkbox"
                      :tabindex="IsAgreeCreditdoctermsDisabled ? '-1' : '0'"
                      class="input-checkbox input-checkbox-sm"
                      :class="
                        IsAgreeCreditdoctermsDisabled
                          ? 'input-checkbox-disabled'
                          : 'input-checkbox-enabled'
                      "
                      aria-label="Read & Agree to the terms of Credit Agreement"
                      aria-describedby="errCreditAgrDoc"
                      :aria-checked="IsAgreeCreditdocterms == 'agreementselect' ? true : null"
                    />
                  </label>
                </span>
                <span style="padding-left: 5px">
                  <button
                    id="btnAgrCredit"
                    :class="clsAgrCredit"
                    @click="loadloandocument('agrcredit')"
                    aria-label="Read & Agree to the terms of Credit Agreement"
                  >
                    READ & AGREE
                  </button>
                </span>
              </div>
              <div class="agrdocdesc">
                <label for="btnAgrCredit">
                  I have read, understand and accept the terms of the Credit
                  Agreement.
                </label>
              </div>
            </div>
            <div>
              <div
                v-if="!IsAgreeCreditdoctermsDisabled && !IsAgreeCreditdocterms"
                class="error"
                id="errCreditAgrDoc"
                role="alert"
              >
                Please read & agree to the Credit Agreement
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="form sectionborder">
      <div class="row">
        <div class="sectionspacing">
          <div class="section-title"><h2>Contact Consent</h2></div>
          <div style="font-weight: bold">Phone Calls and Text Message</div>
          <div style="color: #1e4488">Choose One</div>
          <div class="inputs" role="radiogroup" aria-label="Contact Consent">
            <div class="radio-input">
              <div
                class="radio-wrapper"
                @keydown.space="marketingTextYesSelectedKeyDown($event)"
                @click="marketingTextYesSelected()"
              >
                <input
                  type="radio"
                  id="rdoMarketingTextYes"
                  name="marketingTexts"
                  v-model="marketingTexts"
                  :value="true"
                  tabindex="-1"
                  aria-label="Contact Consent"
                  aria-hidden="true"
                />
                <span
                  id="spanMarketingTextYes"
                  name="marketingTexts"
                  aria-label="Contact Consent"
                  class="radio-checkmark"
                  tabindex="0"
                  role="radio"
                  aria-describedby="lblMarketingTextYes"
                  :aria-checked="marketingTexts == true ? true : null"
                />
                <span class="radio-label">
                  <label id="lblMarketingTextYes" for="rdoMarketingTextYes">
                    <span class="bold">Yes,</span> {{ legal.marketingTextsYes }}
                  </label>
                </span>
              </div>
              <br />
              <div class="agreements">
                <b>
                  I agree to the
                  <a
                    class="doc-link"
                    id="lnkTermsCondition"
                    @click="loadloandocument('tc')"
                    @keypress.enter="loadloandocument('tc')"
                    @keydown.space="loadloandocumentKeySpace('tc', $event)"
                    tabindex="0"
                    >Terms and Conditions</a
                  >
                  and
                  <a
                    class="doc-link"
                    id="lnkPrivacyPolicy"
                    @click="loadloandocument('privacy')"
                    @keypress.enter="loadloandocument('privacy')"
                    @keydown.space="loadloandocumentKeySpace('privacy', $event)"
                    tabindex="0"
                    >Privacy Policy</a
                  >.</b
                >
              </div>
              <br />
              <!-- <div class="phones"> <div class="phone">CELL: {{ cellPhone ? cellPhone : mainPhone }}</div></div>-->
            </div>
            <div class="radio-input">
              <div
                class="radio-wrapper short"
                @keydown.space="marketingTextNoSelectedKeyDown($event)"
                @click="marketingTextNoSelected()"
              >
                <input
                  type="radio"
                  id="rdoMarketingTextsNo"
                  name="marketingTexts"
                  v-model="marketingTexts"
                  :value="false"
                  tabindex="-1"
                  aria-hidden="true"
                />
                <span
                  id="spanMarketingTextsNo"
                  name="marketingTexts"
                  aria-label="Contact Consent"
                  class="radio-checkmark"
                  tabindex="0"
                  role="radio"
                  aria-describedby="lblMarketingTextNo"
                  :aria-checked="marketingTexts == false ? true : null"
                />
                <span class="radio-label">
                  <label id="lblMarketingTextNo" for="rdoMarketingTextsNo">
                    {{ legal.marketingTextsNo }}
                  </label>
                </span>
              </div>
            </div>
          </div>
          <div class="phones">
            <!-- <div class="subnote">{{ legal.marketingTextAmount }}</div>-->
          </div>
          <div class="radio-input">
            <div v-if="showMarkettingSMSError" class="error">
              Please select an option
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="form sectionborder" style="border-bottom: 1px solid gray">
      <div class="row">
        <div class="sectionspacing firstsec">
          <div>
            <div><h2>Request Your First Draw</h2></div>
          </div>
          <div class="draw">
            <div class="left">
              <div class="subnote" style="font-size: 13px">
                Use the slider below to select the amount you would like to
                draw. This request will not be processed until after you’ve
                successfully eSigned your documents and finalized your credit
                line.
              </div>
              <br />
              <div>
                <h4>
                  Conditionally Approved<br />
                  Line of Credit:
                  <span class="blue"
                    >{{ currencyShort(creditLimit) }}<sup>2</sup></span
                  >
                </h4>
              </div>
              <br />
              <div class="draw-amount">
                <b>Minimum Draw:</b>
                <span class="orange">
                  {{ currencyShort(minimumDraw) }}
                </span>
              </div>
              <div class="draw-amount">
                <b>Draw increments:</b>
                <span class="orange"> ${{ drawIncrement }} </span>
              </div>
              <div class="subnote" style="margin-top: 10px; font-size: 13px">
                Up to your available credit.
              </div>
            </div>
            <div class="draw-request" :class="clsDisableDrawReq">
              <div class="cash">
                <div class="draw-amount" v-show="!drawRequested">
                  <div>
                    <span class="dollar">{{
                      currencyShort(firstDrawAmount)
                    }}</span>
                    <!--<input class="draw-input"  :readonly="true" :value="firstDrawAmount" type="number">-->
                  </div>
                  <div class="totaldrawamount" style="">Total Draw Amount</div>
                </div>
              </div>
              <div class="slider" v-if="!drawRequested">
                <div class="input-slider-wrapper">
                  <!--<div class="min" v-if="!refuseDraw">{{ minimumDraw | currencyShort }}</div>-->
                  <div class="min">{{ currencyShort(0) }}</div>
                  <div class="left-cap" />
                  <input
                    id="inpSlider"
                    name="draw"
                    type="range"
                    :min="minimumDraw - drawIncrement"
                    :max="creditLimit"
                    :step="drawIncrement"
                    class="slider-input"
                    v-bind:style="{ backgroundImage: computedGradiant }"
                    @change="errorMsg = null"
                    v-model="firstDrawAmount"
                    :disabled="refuseDraw"
                    aria-label="Total Draw Amount"
                  />
                  <div class="right-cap" :class="clsrightcaplimit" />
                  <div class="max">
                    {{ currencyShort(creditLimit) }}
                  </div>
                </div>
                <div class="slidetext">
                  <span class="cashfeetitle" style="font-size: 14px">
                    <label for="inpSlider"
                      >Slide to select your amount. &nbsp;</label
                    >
                  </span>
                </div>
              </div>

              <div class="depositamount" v-if="firstDrawAmount > 0.0">
                <div>
                  <div class="amountwidth">
                    <span class="cashfeetitle">Cash Advance Fee</span>
                    <span class="cashfee"
                      >-{{ currencyLong(this.cashAdvanceFee) }}</span
                    >
                    <span class="cashfeehelpicon"
                      ><HelpIcon
                        :message="help.cashAdvanceFee"
                        :id="'cashfeeinfo'"
                    /></span>
                  </div>
                  <div class="depositamountline"></div>
                  <div class="amountwidth">
                    <span class="depositamttitle">Amount You Will Receive</span>
                    <span class="depositamt" style="">{{
                      currencyLong(this.actualDrawAmount)
                    }}</span>
                    <span class="depositamthelpicon"
                      ><HelpIcon
                        :message="help.depositAmount"
                        :id="'depositamtinfo'"
                    /></span>
                  </div>
                </div>
              </div>
              <div class="error">
                <div v-if="errorMsg">{{ errorMsg }}</div>
              </div>
            </div>
            <!-- end dra-->
          </div>
          <div class="no-draw">
            <label class="input-checkbox-wrapper">
              <span
                ><label id="lblDrawRequestNotReq" for="chkCanelDrawRequest"
                  >I don't want to request a draw right now.</label
                ></span
              >
              <input
                id="chkCanelDrawRequest"
                type="checkbox"
                v-model="refuseDraw"
                @click="noDrawRequested()"
                tabindex="-1"
              />
              <span
                role="checkbox"
                id="spanCanelDrawRequest"
                tabindex="0"
                class="input-checkbox input-checkbox-sm"
                aria-describedby="lblDrawRequestNotReq"
                :aria-checked="refuseDraw == 'enabledrawreq' ? true : null"
              />
            </label>
            <span class="cashfeehelpicon"
              ><HelpIcon :message="help.dontWantDraw" :id="'dontwantdrawinfo'"
            /></span>
          </div>
          <div v-if="showReqDrawError" class="error">
            Please select a draw amount or check the box indicating you don’t
            want to draw right now
          </div>
        </div>
      </div>
    </section>
    <!-- 2FA Start -->
    <section
      v-if="esignvaliduserforcratepwdsection"
      class="form sectionborder"
      style="border-bottom: 1px solid gray; padding-top: 15px"
    >
      <div class="row">
        <div class="sectionspacing mobilesectionspace">
          <div v-if="showconfirmmsg || passwordCreated || successfullyCreated">
            <div class="left">
              <h3 id="sec2faheader" class="esignleftcontentheader">Create Your Password</h3>
              <p class="esigncontenttext headercontextadjust">
                Create your password now to set up your account so that you can
                view & update your account details, request draws and make
                payments.
              </p>
            </div>
            <br v-if="showconfirmmsg || successfullyCreated" />
          </div>
          <div v-if="showsendcode || showverifypasscode">
            <div class="esigncontentheader" style="font-size: 26px">
              Identity Verification
            </div>
            <div class="esigncontenttext secheader">
              For your security, we need to confirm it’s you by sending a
              one-time passcode. Your passcode will be active for 30 minutes.
            </div>
            <br />
          </div>
          <div id="step2" class="left" v-if="showsendcode">
            <p class="smallcontentheader">How should we send your code?</p>
            <p class="esigntxtblue">Choose one.</p>
            <div
              role="radiogroup"
              aria-label="Identity Verification"
              v-if="showsendcodeoptions"
            >
              <div
                v-for="optiontype in verificationoptionlist"
                :key="optiontype.id"
              >
                <div class="radio-input">
                  <div class="radio-wrapper short">
                    <input
                      :id="'rdo2faText' + optiontype.id"
                      type="radio"
                      name="esignchooseMethod"
                      v-model="choosepasscodeMethodvalue"
                      :value="optiontype"
                      tabindex="-1"
                      aria-hidden="true"
                      aria-describedby="errIdntityOption"
                      @click="Text2faSelectedKeyDown($event, optiontype)"
                    />
                    <span
                      :id="'span2faText' + optiontype.id"
                      class="radio-checkmark"
                      role="radio"
                      name="radiofa"
                      tabindex="0"
                      :aria-describedby="'lbl2faText' + optiontype.id"
                      @keydown.space="
                        Text2faSelectedKeyDown($event, optiontype)
                      "
                      
                    />
                    <label
                      :id="'lbl2faText' + optiontype.id"
                      :for="'rdo2faText' + optiontype.id"
                      class="bold"
                      style="font-size: 14px"
                      >{{ optiontype.CallType }}
                      <span class="esigntxtselection">
                        {{ optiontype.Numbertext }}
                      </span></label
                    >
                  </div>
                </div>
              </div>

              <div class="radio-input" v-if="emailoption">
                <div class="radio-wrapper short">
                  <input
                    id="rdo2faEmail"
                    type="radio"
                    name="esignchooseMethod"
                    v-model="choosepasscodeMethodvalue"
                    value="email"
                    tabindex="-1"
                    aria-hidden="true"
                  />
                  <span
                    id="span2faEmail"
                    class="radio-checkmark"
                    role="radio"
                    tabindex="0"
                    :aria-checked="
                      choosepasscodeMethodvalue == 'email' ? true : null
                    "
                    aria-label="Email"
                    aria-describedby="errIdntityOption"
                    @keydown.space="Email2faSelectedKeyDown($event)"
                  />
                  <label
                    id="lbl2faEmail"
                    for="rdo2faEmail"
                    class="bold space"
                    style="font-size: 14px"
                    >Email</label
                  >
                  <span class="esigntxtselection">
                    {{ maskedemail }}
                  </span>
                </div>
              </div>
            </div>
            <div
              v-if="showsencodeerror"
              id="errIdntityOption"
              class="error"
              role="alert"
            >
              Please select an option
            </div>
            <div class="bottomsection">
              <button
                class="button button-primary"
                alt="sendcode"
                @click="esignsendverificationcode"
              >
                Send Code
              </button>
            </div>
            <div></div>
          </div>
          <!-- Step2 end -->

          <div id="step3" class="left" v-if="showverifypasscode">
            <div id="divotpscreenheader" tabindex="-1">
              <p class="esigncontentheader">Let’s get you verified.</p>
              <p class="esigncontenttext mt-0" for="otpinput">
                Please enter the passcode we provided and choose “Next.”
              </p>
              <br class="hidden" />
            </div>
            <!-- <div> -->
            <div class="esignpasscodesec">
              <div class="esignpasscodeimg">
                <img
                  src="../../assets/logos/2fa_icon.png"
                  alt="Xact"
                  class="mobileiconimg"
                  v-if="mobileicon"
                />

                <img
                  src="../../assets/logos/2fa_icon_em.png"
                  alt="Xact"
                  class="Emailiconimg"
                  v-if="Emailicon"
                />
              </div>
              <div class="esignpasscodetxt">
                <FormField>
                  <input
                    id="otpinput"
                    name="otpinput"
                    v-for="(value, index) in otpValues"
                    :key="index"
                    type="text"
                    maxlength="1"
                    class="otp-input"
                    :value="value"
                    @input="handleInput(index, $event.target.value)"
                    @keydown.backspace="handleBackspace(index)"
                    ref="otpFields"
                    aria-describedby="optinput-errormsg"
                  />
                  <template v-slot:error>
                    <span id="optinput-errormsg"></span>
                  </template>
                </FormField>
                <div
                  :class="esignappwithdrawn ? 'error wderror' : 'error'"
                  role="alert"
                  v-html="passcodeerrorMsg"
                />
                <div
                  id="divResendCodeMsg"
                  v-if="esignisResendCode"
                  role="alert"
                >
                  <p class="resenderror">
                    We have received your request and sent your new passcode. If
                    you need further assistance, please contact Customer Service
                    at
                    <a
                      class="button-login"
                      :href="'tel:' + companyInfo.contactPhone"
                      >{{ companyInfo.contactPhone }}</a
                    >.
                  </p>
                </div>
              </div>
            </div>

            <div class="esignbtnsection">
              <div class="btnleft">
                <button
                  :disabled="esignappwithdrawn"
                  class="esignbutton-resend"
                  alt="ResendCode"
                  @click="esignreSendVerificatonCode"
                >
                  Resend Code
                </button>
              </div>
              <div class="btnright">
                <button
                  :disabled="!passcodeverified ? true : false"
                  class="esignbutton-verify"
                  alt="next"
                  @click="esignverifyPassCode"
                >
                  Next
                </button>
              </div>
            </div>
            <!-- </div> -->
          </div>
          <!-- step3 end-->

          <div id="step4" class="left" v-if="showconfirmmsg">
            <div>
              <div class="esignpasscodesuccess">
                <img
                  src="../../assets/logos/Circle_check.png"
                  alt="XactFlex"
                  class="esignimg"
                />

                <div id="div2FASuccess" tabindex="-1" class="text">
                  <p class="txtsuccess">THANKS FOR VERIFYING</p>
                  <p class="txtconfrim">THAT IT'S YOU.</p>
                </div>
              </div>
              <div class="textsucessalignment">
                You'll now be directed to create your password.
              </div>
              <Esignloader />
            </div>
          </div>
          <!-- Step4 end -->

          <!-- 2FA Stop -->
          <v-form
            id="step5"
            v-if="passwordCreated"
            ref="form"
            :validation-schema="schemaCreatePwd"
            @invalid-submit="createPasswordInvalidSubmit"
            @submit="createPasswordSubmit"
            v-slot="{ errors }"
          >
            <!-- <div> -->
            <div class="left">
              <div class="error" role="alert" v-html="identityErrorMsg" />
              <div class="contentsection">
                <div class="contentsection-password esigncreatepwdchk">
                  <FormField :errormsg="errors['password']">
                    <template v-slot:label
                      ><label id="lblCreatePwd" for="password">
                        CREATE PASSWORD
                      </label></template
                    >

                    <v-field
                      v-model="password"
                      name="password"
                      id="password"
                      v-slot="{ field }"
                    >
                      <input
                        v-bind="field"
                        maxlength="20"
                        class="password"
                        autocomplete="false"
                        :type="showPlainPasswords ? 'text' : 'password'"
                        aria-describedby="errcreatepwd"
                        @keyup="passwordcriteriacheck"
                      />
                    </v-field>
                    <div
                      class="password-show-icon"
                      @click="showPlainPasswords = !showPlainPasswords"
                    >
                      <i
                        :class="
                          showPlainPasswords
                            ? 'fas fa-eye-slash fa-lg'
                            : 'fa fa-eye fa-lg'
                        "
                      />
                    </div>
                    <div
                      v-show="!errors.password && password"
                      class="input-accept"
                    />
                    <div v-show="errors.password" class="input-reject" />
                    <template v-slot:error>
                      <v-error id="errcreatepwd" name="password" />
                    </template>
                  </FormField>
                  <FormField :errormsg="errors['confirmpassword']">
                    <template v-slot:label
                      ><label for="txtconfirmpwd">
                        CONFIRM PASSWORD
                      </label></template
                    >
                    <v-field
                      v-model="confirmPassword"
                      name="confirmpassword"
                      id="txtconfirmpwd"
                      v-slot="{ field }"
                    >
                      <input
                        v-bind="field"
                        maxLength="20"
                        class="password"
                        autocomplete="false"
                        :type="showConfirmPasswords ? 'text' : 'password'"
                        aria-describedby="errconfirmpwd"
                      />
                    </v-field>
                    <div
                      class="password-show-icon"
                      @click="showConfirmPasswords = !showConfirmPasswords"
                    >
                      <i
                        :class="
                          showConfirmPasswords
                            ? 'fas fa-eye-slash fa-lg'
                            : 'fa fa-eye fa-lg'
                        "
                      />
                    </div>

                    <div
                      v-show="!errors.confirmpassword && confirmPassword"
                      class="input-accept"
                    >
                      <span class="matchicon">match</span>
                    </div>
                    <div v-show="errors.confirmpassword" class="input-reject" />

                    <template v-slot:error>
                      <v-error id="errconfirmpwd" name="confirmpassword" />
                    </template>
                  </FormField>
                </div>

                <div class="contentsection-instructions">
                  <div class="instructions">
                    <!-- <span>Passwords must contain</span>
                    <span> 8 to 20 characters and have at least</span>
                    <span> one uppercase letter,</span>
                    <span> one lowercase letter,</span>
                    <span> one number,</span>
                    <span> one special character (ex. !@%$&*)</span>
                    <span> and no spaces.</span> -->
                    <span>Passwords must contain</span>
                    <span :class="matchpwdcriteria_characters ? 'go-green' : ''">
                      8 to 20 characters
                    </span>
                    <span>and have at least</span>
                    <span :class="matchpwdcriteria_uppercase ? 'go-green' : ''">
                      one uppercase letter,</span
                    >

                    <span :class="matchpwdcriteria_lowercase ? 'go-green' : ''">
                      one lowercase letter,</span
                    >

                    <span :class="mathcpwdcriteria_number ? 'go-green' : ''">
                      one number,</span
                    >

                    <span :class="matchpwdcriteria_specialchar ? 'go-green' : ''">
                      one special character (ex. !</span
                    >
                    <span :class="matchpwdcriteria_specialchar ? 'go-green' : ''">
                      @%$&*)</span
                    >
                    <span> and </span>
                    <span :class="matchpwdcriteria_spaces ? 'go-green' : ''">
                      no spaces.</span
                    >
                  </div>
                </div>
              </div>
              <div class="bottom">
                <button
                  class="button button-orange savepassword"
                  :disabled="!(!errors.confirmpassword && confirmPassword)"
                  type="submit"
                  alt="save"
                >
                  Save Password
                </button>
              </div>
            </div>
          </v-form>

          <div id="step6" class="left" v-if="successfullyCreated" tabindex="-1">
            <div class="esignsuccessheader esigncongratssection">
              Congratulations!
            </div>
            <div class="congrats esigncongratssection">
              <p>You’ve successfully created your password.</p>
              Please choose "Save and Continue,"<br />
              below, to finish eSigning.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="form">
      <div class="consentfooter">
        <span class="approved hidesmall">
          Conditionally Approved Line of Credit Amount
          {{ currencyShort(creditLimit) }}<sup>2</sup>
        </span>

        <button
          class="button-orange consentfooterbutton"
          :disabled="!validates()"
          @click="submitConsent"
        >
          SAVE AND CONTINUE
        </button>

        <div class="error" v-if="showGenericMsgError">
          <span style="text-align: center"
            >Please complete all sections as noted above</span
          >
        </div>
      </div>
      <div
        class="preference-modal-wrapper"
        v-if="showWarningModal"
        @click="closeWarningModal"
        aria-modal="true"
        role="alertdialog"
        aria-describedby="contactConsentTitle"
      >
        <div class="preference-modal" @click.stop="">
          <div class="title" id="contactConsentTitle">Are you sure?</div>
          <div class="subnote">
            Texting is a great way to keep valuable info at your fingertips. You
            can also opt out by responding “STOP” to any of our text messages.
          </div>
          <div class="options">
            <div class="option" @click="stayinformed">
              <button
                class="buttonaccept"
                id="btnMktTxtYes"
                @keydown.shift.tab="focuspreviouseleinpopup($event)"
              >
                STAY INFORMED
              </button>
            </div>
            <div class="option" @click="closeWarningModal">
              <button
                class="buttonDeny"
                id="btnMktTxtNo"
                @keydown.tab="focusnexteleinpopup($event)"
              >
                OPT ME OUT
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div>
      <input id="ioBB" name="ioBB" type="hidden" />
    </div>
  </main>
</template>
<!--
<script type="text/javascript" src="../js/iovation.js"></script>
-->
<script>
import legal from "@/config/text/legal";
import companyInfo from "@/config/text/company";
import help from "@/config/text/help";
import { currencyShort, currencyLong } from "@/utils/filters/currency";
import {
  formatemail2FA,
  formatcellno2FA,
  generatePasscode,
  GetCurrentDate,
  TimeElapsed,
} from "@/config/commonfunctions";
import { passwordRegex } from "@/config/validations";
import pre from "@/config/text/createpassword";
import { wrongPwdAttempt } from "@/store/login";
import { HelpIcon, PortalLoader, FormField, Esignloader } from "@/components";
import * as Yup from "yup";
import iovationsnare from "@/config/iovation_snare";
import initialiseBlackbox from "@/config/initialiseBlackbox.js";

export default {
  name: "EsignConsent",
  title: "eSign Your Line Of Credit Documents",
  components: { HelpIcon, PortalLoader, FormField, Esignloader },

  async created() {
    this.$store.state.account.isLoading = true;
    if (
      this.$store.state.esign.ip == null ||
      this.$store.state.esign.ip == undefined
    ) {
      await this.$store.dispatch("findEsignIP");
    }
    await this.$store.dispatch("getConsentForElectricRecords");

    this.firstDrawAmount = this.$store.state.esign.firstDrawAmount;
    if (!this.$store.state.esign.creditLimit) {
      this.$router.replace({ name: "app-approved" });
    }
    await this.$store.dispatch("validateIdentity");

    if (this.esignvaliduserforcratepwdsection) {     
      if (
        (!this.$store.state.esign.IsTwoFactorAuthenticationEnabled &&
          this.$store.state.esign.IsTwoFactorAuthenticationEnabled !=
            undefined) ||
        this.$store.state.esign.IsTwoFactorAuthenticationEnabled == null
      ) {
        this.showsendcode = false;
        this.showverifypasscode = false;
        this.showconfirmmsg = false;
        this.esignactivate(false);
      } else {
        //Add Customer ID and Token to Prove API
        this.$store.commit("updateCustomerId", this.custId);
        this.$store.commit("updateProveToken", this.$store.state.esign.tokenID);
        var mobilenumber = this.$store.state.esign.cellPhone;
        var homenumber = this.$store.state.esign.mainPhone;
        // var mobilenumber = "4045792875";
        // var homenumber = "4564564564";
        if (mobilenumber != "") {
          //Add Cell phone number to list
          this.verificationoptionlist.push({
            CallType: "Call",
            Numbertext: formatcellno2FA(mobilenumber),
            numberval: mobilenumber,
            id: "1",
          });
        }
        //Add Home/Main phone number to list
        if (homenumber != mobilenumber && homenumber != "") {
          this.verificationoptionlist.push({
            CallType: "",
            Numbertext: formatcellno2FA(homenumber),
            numberval: homenumber,
            id: "2",
          });
        }

        //Loop through all numbers added and check if number provided is a mobile number
        for (
          let index = 0;
          index < this.verificationoptionlist.length;
          index++
        ) {
          const element = this.verificationoptionlist[index];

          let trustres = await this.$store.dispatch(
            "checkenterednumberifcellnumber",
            {
              phonenumber: element.numberval,
            }
          );
          if (trustres == null) {
            this.verificationoptionlist[index].CallType = "Call";
          } else {
            this.verificationoptionlist[index].CallType =
              trustres.lineType == "Mobile" ? "Text" : "Call";
          }
        }
        this.showsendcodeoptions = true;
      }
    }
    setTimeout(() => {
      this.$store.state.account.isLoading = false;
    }, 3000);
    this.$store.commit("esignclear_identitymsg");
    this.$store.commit("esignclear_passcodeerrmsg");
  },
  data() {
    let passworderrormsg = "Please enter a password that meets the criteria";
    let confirmpassworderrormsg = "Passwords must match.";
    let confirmpasswordreqerrormsg = "Please confirm your password.";
    const schemaCreatePwd = Yup.object().shape({
      password: Yup.string()
        .required(passworderrormsg)
        .min(8, passworderrormsg)
        .matches(/(?=.*[a-z])/, passworderrormsg)
        .matches(/(?=.*[A-Z])/, passworderrormsg)
        .matches(/(?=.*\d)/, passworderrormsg)
        .matches(/(?=.*\W)/, passworderrormsg)
        .matches(/^\S*$/, passworderrormsg),
      confirmpassword: Yup.string()
        .required(confirmpasswordreqerrormsg)
        .oneOf([Yup.ref("password")], confirmpassworderrormsg),
    });
    return {
      schemaCreatePwd,
      showWarningModal: false,
      help: help,
      submitting: false,
      legal: legal,
      companyInfo: companyInfo,
      drawRequested: false,
      errorMsg: null,
      showEleConsentError: null,
      showMarkettingCallError: null,
      showMarkettingSMSError: null,
      showReqDrawError: null,
      showGenericMsgError: false,
      description: "button-orange",
      canShowAgrArbitration: null,
      canShowAgrPrivacyPolicy: null,
      canShowAgrEleConsent: null,
      canShowAgrCalPrivacyAct: null,
      canShowAgrCredit: null,
      previousfirstDrawAmount: 0,
      showsendcode: true,
      showsendcodeoptions: false,
      showverifypasscode: false,
      showconfirmmsg: false,
      passwordCreated: false,
      GetCurrentDate: GetCurrentDate,
      passwordRegex: passwordRegex,
      password: null,
      confirmPassword: null,
      showPlainPasswords: false,
      showConfirmPasswords: false,
      mobileicon: true,
      Emailicon: false,
      choosepasscodeMethodvalue: null,
      showsencodeerror: false,
      extravalue: "",
      Enablenextbtn: false,
      esignisResendCode: false,
      otpValues: ["", "", "", "", "", ""],
      verificationoptionlist: [],
      showrfvCPsendcode: false,
      showInvalidCodeAttempt: false,
      showInvalidCodeSentAttempt: false,
      esignpasscodetimeout: 30,
      lastFourSocial: "",
      validatepwd: false,
      otpattemptfailedcount: 0,
      resendotpattemptfailedcount: 0,
      esignPasscodeSession: 0,
      mobileoption: false,
      emailoption: false,
      passcodeverified: false,
      esigncreatepwdAttempt: 0,
      esigncreatepwdemail: "",
      esignappwithdrawn: false,
      matchpwdcriteria_characters: false,
      matchpwdcriteria_uppercase: false,
      matchpwdcriteria_lowercase: false,
      mathcpwdcriteria_number: false,
      matchpwdcriteria_specialchar: false,
      matchpwdcriteria_spaces: false,
      wrongPwdAttempt: wrongPwdAttempt,
      currencyShort: currencyShort,
      currencyLong: currencyLong,
      sentNumber: "",
      pinCheckResponse:"",
    };
  },
  computed: {
    computedGradiant() {
      let percentage =
        ((this.firstDrawAmount - this.minimumDraw + this.drawIncrement) /
          (this.creditLimit - this.minimumDraw + this.drawIncrement)) *
        100;
      if (percentage < 0) {
        percentage = 0;
      }
      return `linear-gradient(90deg, #EE6323 ${percentage}%, transparent ${percentage}%)`;
    },
    clsAgrArbitration() {
      if (this.IsAgreeArbdocterms) {
        return "agreementselect";
      } else {
        return "agreementdeselect";
      }
    },
    clsAgrPrivacyPolicy() {
      if (this.IsAgreePriPolicydocterms) {
        return "agreementselect";
      } else {
        return "agreementdeselect";
      }
    },
    clsAgrEleConsent() {
      if (this.IsAgreeEleConsentdocterms) {
        return "agreementselect";
      } else {
        return "agreementdeselect";
      }
    },
    clsAgrCalPrivacyAct() {
      if (this.IsAgreeCalPrivacyActdocterms) {
        return "agreementselect";
      } else {
        return "agreementdeselect";
      }
    },
    clsAgrCredit() {
      if (this.IsAgreeCreditdocterms) {
        return "agreementselect";
      } else {
        return "agreementdeselect";
      }
    },
    clsDisableDrawReq() {
      if (this.refuseDraw) {
        return "disabledrawreq";
      } else {
        return "enabledrawreq";
      }
    },
    clsrightcaplimit() {
      if (this.firstDrawAmount >= this.creditLimit) {
        return "right-cap-limit";
      } else {
        return "";
      }
    },

    validatePassword() {
      if (this.password == undefined || this.password == null) {
        return false;
      } else {
        var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,20}/;
        var res = re.test(this.password);
        res = res ? !/\s/g.test(this.password) : res;
        if (!res) {
          document
            .getElementById("password")
            .parentElement.classList.add("field-error");
          if (document.getElementById("passworderror") != undefined)
            document.getElementById("passworderror").parentElement.id =
              "passwordError";
          if (document.getElementById("passwordError") != undefined)
            document.getElementById("passwordError").innerHTML =
              "Please enter a password that meets the criteria. func";
        } else {
          if (
            document
              .getElementById("password")
              .parentElement.classList.contains("field-error")
          ) {
            document
              .getElementById("password")
              .parentElement.classList.remove("field-error");
            if (document.getElementById("passwordError") != undefined)
              document.getElementById("passwordError").innerHTML = "";
          }
        }
        return res;
      }
    },
    isFormValid: function () {
      const invalidFields = !!Object.values(this.fields).filter(
        (field) => !field.valid
      ).length;
      const invalidAuxFields =
        this.electronicConsent === null ||
        this.electronicConsent === false ||
        this.marketingTexts === null ||
        (!this.refuseDraw && this.firstDrawAmount === 0);
      return !invalidFields && !invalidAuxFields;
    },
    iovationtoken: {
      get() {
        return this.$store.state.iovation.tokenID;
      },
      set(value) {
        this.$store.commit("updateIovationTokenId", value);
      },
    },
    reloadcurrent: {
      get() {
        return this.$store.state.esign.reloadpage;
      },
      set(value) {
        this.$store.commit("updatereload", value);
      },
    },
    blackboxcode: {
      get() {
        return this.$store.state.iovation.beginblackboxcode;
      },
      set(value) {
        this.$store.commit("updateBlackBoxCode", value);
      },
    },
    electronicConsent: {
      get() {
        return this.$store.state.esign.electronicConsent;
      },
      set(value) {
        this.$store.commit("updateElectronicConsent", value);
      },
    },
    marketingCalls: {
      get() {
        return this.$store.state.esign.marketingCalls;
      },
      set(value) {
        this.$store.commit("updateMarketingCalls", value);
      },
    },

    marketingTexts: {
      get() {
        return this.$store.state.esign.marketingTexts;
      },
      set(value) {
        this.$store.commit("updateMarketingTexts", value);
      },
    },
    marketingTextsNo: {
      get() {
        if (this.$store.state.esign.marketingTexts == false) {
          return true;
        } else {
          return false;
        }
      },
    },
    refuseDraw: {
      get() {
        return this.$store.state.esign.refuseDraw;
      },
      set(value) {
        this.$store.commit("updateRefuseDraw", value);
      },
    },
    firstDrawAmount: {
      get() {
        return this.$store.state.esign.firstDrawAmount;
      },
      set(value) {
        if (parseInt(value) < this.minimumDraw) {
          value = 0;
        }
        this.$store.commit("updateFirstDrawAmount", value);

        this.cashAdvanceFee = parseFloat(
          (parseInt(value) * parseFloat(this.cashAdvanceFeePercent)) / 100
        ).toFixed(2);
        this.actualDrawAmount =
          parseFloat(value) - parseFloat(this.cashAdvanceFee);
      },
    },
    actualDrawAmount: {
      get() {
        return this.$store.state.esign.actualDrawAmount;
      },
      set(value) {
        this.$store.commit("updateActualDrawAmount", value);
      },
    },
    cashAdvanceFee: {
      get() {
        return this.$store.state.esign.cashAdvanceFee;
      },
      set(value) {
        this.$store.commit("updateCashAdvanceFee", value);
      },
    },
    cashAdvanceFeePercent: {
      get() {
        return this.$store.state.esign.cashAdvanceFeePercent;
      },
    },
    creditLimit: {
      get() {
        return this.$store.state.esign.creditLimit;
      },
      set(value) {
        this.$store.commit("updateCreditLimit", value);
      },
    },
    minimumDraw: {
      get() {
        return this.$store.state.esign.minimumDraw;
      },
      set(value) {
        this.$store.commit("updateMinimumDraw", value);
      },
    },
    esignvaliduserforcratepwdsection: {
      get() {
        //return true;
        return this.$store.state.esign.esignvaliduserforcratepwd;
      },
      set(value) {
        this.$store.commit("updateesignvaliduserforcrtpwd", value);
      },
    },

    drawIncrement: {
      get() {
        return this.$store.state.esign.drawIncrement;
      },
      set(value) {
        this.$store.commit("updateDrawIncrement", value);
      },
    },
    firstName: {
      get() {
        return this.$store.state.esign.firstName;
      },
    },
    lastName: {
      get() {
        return this.$store.state.esign.lastName;
      },
    },
    maskedemail: {
      get() {
        return formatemail2FA(this.$store.state.esign.custEmail);
      },
    },
    ssn: {
      get() {
        return this.$store.state.esign.ssn;
      },
    },
    custId: {
      get() {
        return this.$store.state.esign.custID;
      },
      set(value) {
        this.$store.commit("updateCustID", value);
      },
    },
    successfullyCreated: {
      get() {
        return this.$store.state.esign.successfullyCreated;
      },
      set(value) {
        this.$store.commit("usersuccessfullycreated", value);
      },
    },
    identityErrorMsg: {
      get() {
        return this.$store.state.esign.identityErrorMsg;
      },
    },
    passcodeerrorMsg: {
      get() {
        return this.$store.state.esign.passcodeerrorMsg;
      },
    },

    email: {
      get() {
        return this.$store.state.esign.custEmail;
      },
    },
    mainPhone: {
      get() {
        return this.formatPhoneNumber(this.$store.state.esign.mainPhone);
      },
    },
    cellphonemasked: {
      get() {
        return formatcellno2FA(this.$store.state.esign.cellPhone);
      },
      //get() { return formatcellno2FA(this.$store.state.esign.mainPhone) }
    },
    cellPhone: {
      get() {
        return this.$store.state.esign.cellPhone;
      },
    },
    IsAgreeArbdocterms: {
      get() {
        return this.$store.state.esign.IsAgreeArbdocterms;
      },
      set(value) {
        this.$store.state.esign.IsAgreeArbdocterms = value;
      },
    },
    IsAgreeArbdoctermsDisabled: {
      get() {
        return this.$store.state.esign.IsAgreeArbdoctermsDisabled;
      },
      set(value) {
        this.$store.state.esign.IsAgreeArbdoctermsDisabled = value;
      },
    },
    IsAgreePriPolicydocterms: {
      get() {
        return this.$store.state.esign.IsAgreePriPolicydocterms;
      },
      set(value) {
        this.$store.state.esign.IsAgreePriPolicydocterms = value;
      },
    },
    IsAgreePriPolicydoctermsDisabled: {
      get() {
        return this.$store.state.esign.IsAgreePriPolicydoctermsDisabled;
      },
      set(value) {
        this.$store.state.esign.IsAgreePriPolicydoctermsDisabled = value;
      },
    },

    IsAgreeEleConsentdocterms: {
      get() {
        return this.$store.state.esign.IsAgreeEleConsentdocterms;
      },
      set(value) {
        this.$store.state.esign.IsAgreeEleConsentdocterms = value;
      },
    },
    IsAgreeEleConsentdoctermsDisabled: {
      get() {
        return this.$store.state.esign.IsAgreeEleConsentdoctermsDisabled;
      },
      set(value) {
        this.$store.state.esign.IsAgreeEleConsentdoctermsDisabled = value;
      },
    },
    IsAgreeCalPrivacyActdocterms: {
      get() {
        return this.$store.state.esign.IsAgreeCalPrivacyActdocterms;
      },
      set(value) {
        this.$store.state.esign.IsAgreeCalPrivacyActdocterms = value;
      },
    },
    IsAgreeCalPrivacyActdoctermsDisabled: {
      get() {
        return this.$store.state.esign.IsAgreeCalPrivacyActdoctermsDisabled;
      },
      set(value) {
        this.$store.state.esign.IsAgreeCalPrivacyActdoctermsDisabled = value;
      },
    },
    IsAgreeCreditdocterms: {
      get() {
        return this.$store.state.esign.IsAgreeCreditdocterms;
      },
      set(value) {
        this.$store.state.esign.IsAgreeCreditdocterms = value;
      },
    },
    IsAgreeCreditdoctermsDisabled: {
      get() {
        return this.$store.state.esign.IsAgreeCreditdoctermsDisabled;
      },
      set(value) {
        this.$store.state.esign.IsAgreeCreditdoctermsDisabled = value;
      },
    },
    getApplicationId: {
      get() {
        return this.$store.state.esign.applicationId;
      },
    },

    IsEsignLoanDocumentExist: {
      get() {
        if (this.$store.state.documents.applicationAgreementDoc == null) {
          return false;
        }
        return true;
      },
    },
  },
  mounted() {
    if (this.$store.state.esign.tokenID == null) {
      this.$router.push({ name: "login" });
    }
    initialiseBlackbox();
    iovationsnare();
    if (this.reloadcurrent) {
      this.reloadcurrent = false;
      this.$router.go();
    }

    //this.initialiseBlackbox();
    this.focusfirstelement();
  },
  methods: {
    focusnexteleinpopup(event) {
      document.getElementById("btnMktTxtYes").focus();
      event.stopPropagation();
      event.preventDefault();
    },
    focuspreviouseleinpopup(event) {
      document.getElementById("btnMktTxtNo").focus();
      event.stopPropagation();
      event.preventDefault();
    },
    focusfirstelement() {
      document.getElementById("divConsentHeader").focus();
    },
    openWarningModal() {
      this.showWarningModal = true;
    },
    closeWarningModal() {
      this.showWarningModal = false;
      this.marketingTexts = false;
      document.getElementById("spanMarketingTextsNo").focus();
    },
    stayinformed() {
      this.showWarningModal = false;
      this.marketingTexts = true;
      this.marketingCalls = true;
      document.getElementById("spanMarketingTextsNo").focus();
    },
    handleInput(index, value) {
      if (value.length > 1) {
        return;
      }
      this.otpValues[index] = value;
      if (value.length === 1 && index < this.otpValues.length - 1) {
        this.$refs.otpFields[index + 1].focus();
      }
      if (index == this.otpValues.length - 1) {
        this.passcodeverified = true; // enabled passcode next button
      }
    },
    handleBackspace(index) {
      if (this.otpValues[index] !== "") {
        this.otpValues[index] = "";
      } else if (index > 0) {
        this.$refs.otpFields[index - 1].focus();
      }
      this.passcodeverified = false; // disabled passcode next button
    },

    //#region  Prove
    esignactivate(createpwdfocus) {
      if(createpwdfocus == true)
      {
        setTimeout(() => {
          this.showconfirmmsg = false;
          this.passwordCreated = true;
          document.getElementById("sec2faheader").focus();
        }, 3000);
      }
      else
      {
        setTimeout(() => {
          this.showconfirmmsg = false;
          this.passwordCreated = true;
        }, 3000);
      }
    },

    async esignsendverificationcode() {
      if (
        this.choosepasscodeMethodvalue == null ||
        this.choosepasscodeMethodvalue == ""
      ) {
        this.showsencodeerror = true;
        return false;
      }

      this.$store.commit(
        "esignupdateverificationcodesenttime",
        GetCurrentDate()
      );
      this.$store.commit("updatePasscodeSession", generatePasscode());
      this.extravalue = localStorage.esignstatePasscodeSession;
      this.$store.commit("updateMessageFor", "2FA");
      //console.log("ETRA:" + this.extravalue);

      if (this.choosepasscodeMethodvalue.CallType.toUpperCase() == "TEXT") {
        // send sms to mobile
        //console.log("Text number- " + this.choosepasscodeMethodvalue.numberval);
        await this.$store.dispatch("SendSMSOTP", {
          mobileNumber: this.choosepasscodeMethodvalue.numberval,
        });
        //console.log("SMS send Successfuly");
      } else if (
        this.choosepasscodeMethodvalue.CallType.toUpperCase() == "CALL"
      ) {
        // Call to Mobile/Landline
        //console.log("Call number- " + this.choosepasscodeMethodvalue.numberval);
        await this.$store.dispatch("PinDelivery", {
          mobileNumber: this.choosepasscodeMethodvalue.numberval,
          passCode: this.extravalue,
        });

        //console.log("Successfully Called");
      } else if (this.choosepasscodeMethodvalue.toUpperCase() == "EMAIL") {
        this.Emailicon = true;
        this.mobileicon = false;
        // send code to email
        this.$store.commit(
          "updateSendMessagesUtilityEmailFirstName",
          this.firstName
        );
        this.$store.commit("updateSendMessagesUtilityEmail", this.email);
        this.$store.commit(
          "updateSendMessagesUtilityToken",
          this.$store.state.esign.tokenID
        );
        this.$store.commit("updateSendMessagesUtilityCustId", this.custId);
        this.$store.commit(
          "updateSendMessagesUtilityPasscode",
          this.extravalue
        );
        this.$store.commit(
          "updateSendMessagesUtilityEmailSubject",
          pre.EmailSubjectLine2FA
        );
        this.$store.commit(
          "updateSendMessagesUtilityPreviewText",
          pre.PreviewText2FA
        );
        //this.$store.commit("updateSendMessagesUtilitySMSText", SMSText);
        this.$store.commit("updateMessageFor", "2FA");

        if (this.choosepasscodeMethodvalue == "text") {
          this.mobileicon = true;
          await this.$store.dispatch("utilitiesSendSMS"); // send sms to mobile
          //console.log(pre.textPhoneUpdate);
        } else {
          //this.Emailicon = true;
          //await this.$store.dispatch("utilitiesSendEmail");
          //console.log(pre.textEmailUpdate);
        }
      }
      this.showsendcode = false;
      this.showverifypasscode = true;
      setTimeout(() => {
        document.getElementById("divotpscreenheader").focus();
      }, 100);
    },

    async esignreSendVerificatonCode() {
      this.otpValues = ["", "", "", "", "", ""];
      this.passcodeverified = false;
      this.showsendcode = false;
      this.showverifypasscode = true;
      this.otpattemptfailedcount = 0;
      var resendcount = parseInt(this.resendotpattemptfailedcount) + 1;
      this.resendotpattemptfailedcount = resendcount;

      if (resendcount <= this.wrongPwdAttempt) {
        this.$store.commit(
          "esignupdateverificationcodesenttime",
          GetCurrentDate()
        );
        this.$store.commit("updatePasscodeSession", generatePasscode());
        this.extravalue = localStorage.esignstatePasscodeSession;
        this.$store.commit("esignupdatePasscodeInvalidCodeAttempt", false);
        this.$store.commit("esignupdatePasscodeInvalidCodeSentAttempt", false);
        this.esignisResendCode = true;
        this.$store.commit("updateMessageFor", "2FA");
        //console.log("ETRA : " + this.extravalue);

        if (this.choosepasscodeMethodvalue.CallType.toUpperCase() == "TEXT") {
          // send sms to mobile
          //console.log(this.choosepasscodeMethodvalue.numberval);
          await this.$store.dispatch("SendSMSOTP", {
            mobileNumber: this.choosepasscodeMethodvalue.numberval,
          });
          //console.log("SMS send Successfuly");
        } else if (
          this.choosepasscodeMethodvalue.CallType.toUpperCase() == "CALL"
        ) {
          // Call to mobile
          await this.$store.dispatch("PinDelivery", {
            mobileNumber: this.choosepasscodeMethodvalue.numberval,
            passCode: localStorage.esignstatePasscodeSession,
          });
          //console.log(callres, "- Successfully Called");
        } else if (this.choosepasscodeMethodvalue.toUpperCase() == "EMAIL") {
          this.$store.commit(
            "updateSendMessagesUtilityEmailFirstName",
            this.firstName
          );
          this.$store.commit("updateSendMessagesUtilityEmail", this.email);
          this.$store.commit(
            "updateSendMessagesUtilityToken",
            this.$store.state.esign.tokenID
          );
          this.$store.commit("updateSendMessagesUtilityCustId", this.custId);
          this.$store.commit(
            "updateSendMessagesUtilityPasscode",
            this.extravalue
          );
          this.$store.commit(
            "updateSendMessagesUtilityEmailSubject",
            pre.EmailSubjectLine2FA
          );
          this.$store.commit(
            "updateSendMessagesUtilityPreviewText",
            pre.PreviewText2FA
          );
          this.Emailicon = true;
          this.mobileicon = false;
          //await this.$store.dispatch("utilitiesSendEmail"); // sending email
          //console.log(pre.textEmailUpdate);
        }
      } else {
        this.esignisResendCode = false;
        //this.$store.commit("esignupdatePasscodeInvalidCodeSentAttempt", true);
      }
    },

    async esignverifyPassCode() {
      this.esignisResendCode = false;
      this.showsendcode = false;

      //Application moved to withdrawn status
      if (this.esignappwithdrawn) {
        this.resendotpattemptfailedcount = this.wrongPwdAttempt;
        this.$store.commit("esignapplicationWithdrawnErrorMsg", true);
        return; //"MAX_LIMIT"
      }
      this.$store.commit(
        "esignupdateTimeElapsed",
        TimeElapsed(localStorage.esignverificationcodesenttime)
      );

      if (localStorage.esignTimeElapsed > this.esignpasscodetimeout) {
        localStorage.esignstatePasscodeSession = "-11111111";
      }

      const enteredpasscode = Number(this.otpValues.join(""));

      if (this.choosepasscodeMethodvalue.CallType.toUpperCase() == "TEXT") {
        const txtres = await this.$store.dispatch("VerifySMSOTP", {
          passCode: enteredpasscode, sentNumber: this.choosepasscodeMethodvalue.numberval,
        });
        await this.passcodeattemptcountcheck(txtres == "0");
      } 
      else if (this.choosepasscodeMethodvalue.CallType.toUpperCase() == "CALL") 
      {
        const pinValidCheck = await this.passcodeattemptcountcheck(
          enteredpasscode == localStorage.esignstatePasscodeSession
        );
        await this.$store.dispatch("phoneCallVerify", {
          sentNumber: this.choosepasscodeMethodvalue.numberval, pinCheckResponse: pinValidCheck,
        });
      } 
      else if (
        this.choosepasscodeMethodvalue.CallType.toUpperCase() == "EMAIL"
            ) 
      {
        if (enteredpasscode == localStorage.esignstatePasscodeSession) {
          //this.showverifypasscode = false;
          //this.showconfirmmsg = true;
          setTimeout(() => {
            document.getElementById("div2FASuccess").focus();
          }, 100);
        } else {
          var h = parseInt(this.otpattemptfailedcount) + 1;
          this.otpattemptfailedcount = h;

          if (h > 5) {
            this.$store.commit(
              "esignupdatePasscodeInvalidCodeSentAttempt",
              true
            );
          } else {
            this.$store.commit("esignupdatePasscodeInvalidCodeAttempt", true);
          }
        }
      }
    },

    async Enableverifypasscode(event) {
      var keyCode = event.keyCode;
      var key = event.key;

      if (
        !(
          (keyCode >= 65 && keyCode <= 95) ||
          keyCode >= 106 ||
          (keyCode >= 48 && keyCode <= 57 && isNaN(key))
        ) &&
        keyCode != 32
      ) {
        if (keyCode != 8 && keyCode != 46) {
          if (this.passcodevalue.length >= 6) {
            this.Enablenextbtn = true;
          } else {
            this.Enablenextbtn = false;
          }
        } else {
          this.Enablenextbtn = false;
        }
        return true;
      } else {
        event.preventDefault();
        return false;
      }
    },

    async passcodeattemptcountcheck(passcodeverifiedresult) {
      let noteattempt = "Failed attempt",
        passcodeverificationstatus = "false";
      if (passcodeverifiedresult) {
        passcodeverificationstatus = "true";
        noteattempt = "Success attempt";
      } else {
        var h = parseInt(this.otpattemptfailedcount) + 1;
        this.otpattemptfailedcount = h;
      }

      this.$store.commit("updateProcessId", 1);
      this.$store.commit("updateProcessStatus", passcodeverificationstatus);
      this.$store.commit("updateAttemptNote", noteattempt);

      let result = await this.$store.dispatch(
        "getinvalidpasswordcreationattemptcount"
      );
      let retValue = "";
      if (
        result.attemptCount >= this.wrongPwdAttempt &&
        passcodeverificationstatus == "false"
      ) {
        retValue = "MAX_LIMIT";

        this.$store.state.application.tokenID = result.tokenToUse;
        await this.$store
          .dispatch(
            "updateApplicationToWithdraw",
            "Verification failed. Your application has been withdrawn"
          )
          .then((res) => {
            this.esignappwithdrawn = true;
            this.$store.commit("esignapplicationWithdrawnErrorMsg", true);
            this.resendotpattemptfailedcount = this.wrongPwdAttempt;
            this.$store.state.esign.tokenID = res.tokenToUse;
          })
          .catch(() => {
            //console.log("withdraw error-" + err);
            this.esignappwithdrawn = false;
          });
      } else if (passcodeverificationstatus == "true") {
        retValue = "PASS";
        this.showverifypasscode = false;
        this.showconfirmmsg = true;
          setTimeout(() => {
            document.getElementById("div2FASuccess").focus();
          }, 100);
        this.otpattemptfailedcount = 0;
        this.esignactivate(true);
      } else {
        this.$store.commit("esignupdatePasscodeInvalidCodeAttempt", true);
        retValue = "FAIL";
      }

      return retValue;
    },

    //#endregion Prove

    passwordcriteriacheck(event) {
      if (event.keyCode != 32) {
        var str = event.target.value;
        var cap = /(?=.*[A-Z])/;
        var lo = /(?=.*[a-z])/;
        var dig = /(?=.*\d)/;
        var sc = /(?=.*\W)/;
        var sp = /^\S*$/;
        var leng = /^[\s\S]{8,20}$/;
        this.matchpwdcriteria_uppercase = cap.test(str);
        this.matchpwdcriteria_lowercase = lo.test(str);
        this.mathcpwdcriteria_number = dig.test(str);
        this.matchpwdcriteria_specialchar = sc.test(str);
        this.matchpwdcriteria_spaces = sp.test(str);
        this.matchpwdcriteria_characters = leng.test(str);
      } else {
        event.preventDefault();
      }
    },
    createPasswordInvalidSubmit() {
      this.validatepwd = false;
    },
    async createPasswordSubmit() {
      this.validatepwd = true;

      if (this.validatepwd) {
        this.$store.commit("esignclear_identitymsg");

        const result = await this.$store.dispatch(
          "esignCreatePassword",
          this.ssn + this.password
        );

        if (result.registered) {
          this.passwordCreated = false;
          this.successfullyCreated = true;
          this.$store.commit("usersuccessfullycreated", true);
          this.$store.commit("esignclear_identitymsg");
          this.$store.commit("esignupdatePasswordCreationError", false);
          setTimeout(() => {
            document.getElementById("step6").focus();
          }, 200);
        } else {
          this.$store.commit("esignupdatePasswordCreationError", true);
        }
      }
    },

    formatPhoneNumber(phoneNumberString) {
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    },
    noDrawRequested() {
      if (!this.refuseDraw) {
        this.previousfirstDrawAmount = this.firstDrawAmount;
        this.firstDrawAmount = 0;
      } else {
        this.firstDrawAmount = this.previousfirstDrawAmount;
      }
      this.HideErrorMessage("requestDraw");
      document.getElementById("spanCanelDrawRequest").focus();
    },
    marketingTextYesSelected() {
      this.marketingTexts = true;
      this.HideErrorMessage("marketingTexts");
      document.getElementById("spanMarketingTextYes").focus();
    },
    marketingTextYesSelectedKeyDown(event) {
      this.marketingTexts = true;
      this.HideErrorMessage("marketingTexts");
      document.getElementById("spanMarketingTextYes").focus();
      event.preventDefault();
    },
    marketingTextNoSelected() {
      this.marketingTexts = false;
      this.HideErrorMessage("marketingTexts");
      document.getElementById("spanMarketingTextsNo").focus();
    },
    marketingTextNoSelectedKeyDown(event) {
      event.preventDefault();

      this.marketingTexts = false;
      this.HideErrorMessage("marketingTexts");
      document.getElementById("spanMarketingTextsNo").focus();
    },
    Email2faSelectedKeyDown(event) {
      this.choosepasscodeMethodvalue = "email";
      document.getElementById("span2faEmail").focus();
      event.preventDefault();
    },
    Text2faSelectedKeyDown(event, value) {
      var elementid = event.target.id;
      this.choosepasscodeMethodvalue = value;
      document.getElementById(
        "rdo2faText" + this.choosepasscodeMethodvalue.id
      ).checked = true;

      if (elementid.toString().includes("span")) {
        event.preventDefault();
      }

      //uncheck and check radiobutton
      var elements = document.getElementsByName("radiofa");
      for (var i = 0; i < elements.length; i++) {
        if (
          elements[i].id ==
          "span2faText" + this.choosepasscodeMethodvalue.id
        ) {
          elements[i].setAttribute("aria-checked", true);
          elements[i].focus();
        } else elements[i].removeAttribute("aria-checked");
      }
    },

    HideErrorMessage(sectionName) {
      //  console.log(sectionName);

      if (sectionName === "EleConsent") {
        this.showEleConsentError = false;
      } else if (sectionName === "marketingTexts") {
        this.showMarkettingSMSError = false;
        if (!this.marketingTexts) {
          this.showWarningModal = true;
          setTimeout(() => {
            document.getElementById("btnMktTxtYes").focus();
          }, 100);
        } else {
          if (this.showWarningModal) {
            this.showWarningModal = false;
          }
        }
      } else if (sectionName === "AgrArbitration") {
        this.canShowAgrArbitration = false;
      } else if (sectionName === "AgrCredit") {
        this.canShowAgrCredit = false;
      } else if (sectionName === "requestDraw") {
        this.showReqDrawError = false;
      }
      this.showGenericMsgError = false;
    },
    validateDrawAmt() {
      let reqAmount = this.firstDrawAmount;
      if (reqAmount > 0 && reqAmount < this.minimumDraw) {
        this.errorMsg = "The minimum draw is $" + this.minimumDraw + ".";
        this.drawRequested = false;
      }

      if (this.errorMsg == null) {
        this.drawRequested = true;
        this.HideErrorMessage("requestDraw");
      }
    },
    validates() {
      var result = true;
      if (this.marketingTexts === null) {
        //  this.showMarkettingSMSError=true;
        result = false;
      }

      if (this.IsAgreeArbdocterms != true) {
        // this.canShowAgrArbitration=true;
        result = false;
      }

      //  if( this.IsAgreePriPolicydocterms != true)
      //{
      // this.canShowAgrPrivacyPolicy=true;
      //result = false;
      //}-->

      if (this.IsAgreeEleConsentdocterms != true) {
        // this.canShowAgrEleConsent=true;
        result = false;
      }

      if (this.IsAgreeCreditdocterms != true) {
        // this.canShowAgrCredit=true;
        result = false;
      }

      if (!this.refuseDraw && this.firstDrawAmount === 0) {
        //this.showReqDrawError=true;
        result = false;
      }

      if (result === false) {
        // this.showGenericMsgError=true;
        result = false;
      }

      if (this.esignvaliduserforcratepwdsection) {
        if (this.successfullyCreated == false) {
          result = false;
        }
      }

      return result;
    },
    async submitConsent() {
      if (this.validates()) {
        this.submitting = true;
        this.iovationtoken = this.$store.state.esign.tokenID;
        this.blackboxcode = document.getElementById("ioBB").value;
        await this.$store.dispatch("GetIovationConfig");

        this.$store.state.account.isLoading = true;
        await this.$store.dispatch("postConsentData");
        // if(result.tokenToUse != null)
        // this.$store.state.esign.tokenID = result.tokenToUse;
        this.$store.state.account.isLoading = false;

        this.$router.push({ name: "esign-signature" });
        this.submitting = false;
      }
    },
    openDocument(doc) {
      this.$store.dispatch("getDocument", doc);
    },
    updateFirstDrawAmount(event) {
      this.errorMsg = null;
      let reqAmount = event.target.value;

      if (reqAmount == "" || reqAmount == 100) {
        reqAmount = 0;
      }
      if (reqAmount > 0 && reqAmount < this.minimumDraw) {
        this.errorMsg = "The minimum draw is $" + this.minimumDraw + ".";
      } else if (reqAmount > this.creditLimit) {
        this.errorMsg = "Your draw amount cannot exceed your credit limit";
      } else if (reqAmount % this.drawIncrement !== 0) {
        this.errorMsg =
          "Draws must be in $" + this.drawIncrement + " increments.";
      }

      this.firstDrawAmount = reqAmount;

      //if(reqAmount < 1500){this.cashAdvanceFee = -25;}
      //else{this.cashAdvanceFee = 25;}

      //this.actualDrawAmount = parseInt(reqAmount) +  parseInt(this.cashAdvanceFee);
    },
    openloanDocument(doc) {
      if (doc == "arbitrationAgreement") {
        this.$store.dispatch(
          "getDocument",
          doc +
            "$" +
            this.$store.state.esign.firstName +
            " " +
            this.$store.state.esign.lastName
        );
      } else {
        this.$store.dispatch("getDocument", doc);
      }
    },
    async loadloandocument(agrname) {
      if (!this.IsEsignLoanDocumentExist) {
        await this.$store.dispatch("getEsignLoanDocument");
      }
      this.$store.state.esign.agreementdocname = agrname;
      this.openloanDocument(agrname);
    },
    async loadloandocumentKeySpace(agrname, event) {
      event.preventDefault();
      if (!this.IsEsignLoanDocumentExist) {
        await this.$store.dispatch("getEsignLoanDocument");
      }
      this.$store.state.esign.agreementdocname = agrname;
      this.openloanDocument(agrname);
    },

    // initialiseBlackbox() {
    //   //Subscriber supplied variables for snare// Snare operation to perform
    //   var io_operation = "ioBegin";

    //   // io_bbout_element_id should refer to the hidden field in your form that contains the blackbox
    //   var io_bbout_element_id = "ioBB";

    //   var io_install_flash = false;
    //   var io_install_stm = false;
    //   var io_exclude_stm = 12;
    //   var io_install_rip = true;
    //   window.IGLOO = window.IGLOO || {
    //     enable_flash: false,
    //     bbout_element_id: io_bbout_element_id,
    //     install_stm: io_install_stm, // do not install Active X
    //     exclude_stm: io_exclude_stm, // do not run Active X
    //     install_flash: io_install_flash, // do not install Flash
    //     min_flash_version: 9999, // disable Flash
    //     enable_rip: io_install_rip,
    //     bb_callback: function (bb, complete) {
    //       var bb_field = document.getElementById("ioBB");
    //       bb_field.value = bb;
    //       this.blackboxcode = bb;
    //       //console.log("bb : ");
    //       //console.log(bb);

    //       //console.log("complete : ");
    //       //console.log(complete);
    //       //console.log(io_operation);
    //       // console.log(io_bbout_element_id);
    //       // console.log(io_install_flash);
    //       // console.log(io_install_stm);
    //       // console.log(io_exclude_stm);
    //       // console.log(io_install_rip);
    //       // console.log(io_flash_needs_update_handler);
    //       // console.log(io_install_flash_error_handler);
    //     },
    //     loader: {
    //       version: "5.2.2",
    //     },
    //   };
    //   //window["IGLOO"].getBlackbox();
    // },

    LoadSnareScript() {
      let snareScript = document.createElement("script");
      snareScript.setAttribute("src", "https://mpsnare.iesnare.com/snare.js");
      document.head.appendChild(snareScript);
    },
  },
};
</script>

<style scoped lang="scss">
.button-orange {
  color: $absolute-white;
  background: $red;
  font-size: 16pt !important;
  font-weight: 800;
  padding: 5px 30px;
  //float: right;
  max-width: 400px;
}

.firstsec {
  @media (max-width: 767px) {
    text-align: center;
  }
}

.mainsubnote {
  padding-top: 10px;
}

.preference-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9991;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  .preference-modal {
    background: #e75300; //#EE6323;
    color: $absolute-white;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    z-index: 9992;
    width: 350px;
    padding: 10px 20px;

    .title {
      font-family: $font-primary; //$font-secondary;
      font-size: 24px;
      font-weight: bold;
    }

    .subnote {
      color: $white;
      text-align: center;
      font-size: 14px;
      font-weight: normal;
    }

    .options {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      .option {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        font-family: $font-primary; //$font-secondary;
        font-size: 12px;
        margin: 10px 20px;
        cursor: pointer;
        .buttonaccept {
          width: 120px;
          background-color: $absolute-white;
          color: $orange;
          font-size: 12px;
          font-weight: bold;
          height: 30px;
        }

        .buttonDeny {
          width: 120px;
          background-color: #e75300; //#EE6323;
          color: $absolute-white;
          font-size: 12px;
          text-decoration: underline;
        }
      }
    }
  }
}

.mt-0 {
  margin-top: 0px;
}

main {
  width: 100%;

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }

  .form-layout {
    margin-top: 50px;
  }

  //---------------------ADA style for tab and focus
  button:focus {
    outline: 2px solid #1e4388;
    filter: drop-shadow(0px 0px 4px grey);
  }

  #divConsentHeader:focus-visible {
    outline: 0px solid #1e4388;
  }
  #div2FASuccess:focus {
    outline: 0px solid #1e4388;
  }
  #step6:focus {
    outline: 0px solid #1e4388;
  }
  #spanChkAgrArbitration:focus-visible {
    outline: 2px solid #1e4388;
    filter: drop-shadow(0px 0px 4px gray);
  }

  #spanChkAgrEleConsent:focus-visible {
    outline: 2px solid #1e4388;
    filter: drop-shadow(0px 0px 4px gray);
  }

  #spanChkAgrCredit:focus-visible {
    outline: 2px solid #1e4388;
    filter: drop-shadow(0px 0px 4px gray);
  }

  #spanCanelDrawRequest:focus-visible {
    outline: 2px solid #1e4388;
    filter: drop-shadow(0px 0px 4px gray);
  }
  #spanMarketingTextsNo:focus {
    outline: 2px solid #1e4388;
    filter: drop-shadow(0px 0px 4px grey);
  }

  /*
    input-checkbox:focus-visible {
        outline: 2px solid #1e4388;
        filter: drop-shadow(0px 0px 4px gray);
    }
*/
  span.radio-checkmark:focus-visible {
    outline-offset: 3px;
  }

  #lnkPrivacyPolicy:focus {
    outline: 2px solid #1e4388;
  }

  #lnkTermsCondition:focus {
    outline: 2px solid #1e4388;
  }

  #span2faEmail:focus-visible {
    outline: 2px solid #1e4388;
    filter: drop-shadow(0px 0px 4px grey);
  }

  #span2faText:focus-visible {
    outline: 2px solid #1e4388;
    filter: drop-shadow(0px 0px 4px grey);
  }

  #divotpscreenheader:focus-visible {
    outline: 0px solid #1e4388;
  }

  #sec2faheader:focus-visible {
    outline: 0px solid #1e4388;
  }
  //---------------------

  .consentheader {
    text-align: center;
    margin: auto;
    margin-bottom: 20px;
    max-width: 500px;
    //font-size: 25pt;
    //font-weight: bold;
    //color: gray;
    @media (max-width: 767px) {
      max-width: 400px;
    }
  }

  .consentheaderline {
    text-align: center;
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 5px;
  }

  .sectionheader {
    color: gray;
    font-size: 30px;
    font-weight: bold;
  }

  .sectionspacing {
    padding-left: 20px;
    padding-right: 20px;
    .section-title {
      margin-bottom: 20px;
    }
  }
  .mobilesectionspace {
    @media (max-width: 450px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .sectionborder {
    border: 1px solid gray;
    @media (max-width: 465px) {
      // border-top: 1px solid gray;
      // border-left: none;
      // border-right: none;
      // border-bottom: none;
      // border-radius: 0px;
      max-width: 95% !important;
    }
    border-radius: 6px;
  }

  .choose {
    margin-left: 10px;
  }

  .input-checkbox-wrapper {
    display: inline;
    @media (max-width: 400px) {
      padding-left: 22px;
    }
  }

  .input-checkbox-wrapper .input-checkbox:after {
    content: "✓ ";
    left: -2px;
    top: -12px;
    width: 5px;
    color: $orange;
    font-size: 30px;
    font-weight: bold;
  }
  .input-checkbox {
    top: -5px;
  }

  h3 {
    color: $gray;
  }

  .input-checkbox-enabled {
    border: 1px solid $gray;
  }
  .input-checkbox-disabled {
    border: 1px solid lightgray;
  }

  .input-checkbox-wrapper .input-checkbox-sm {
    top: 0px;
    height: 15px;
    width: 15px;
    //border: 1px solid $gray;
    //box-shadow: 0px 2px 3px 0px $gray;

    &:after {
      content: "✓ ";
      left: -1px;
      top: -1px;
      width: 12px;
      height: 15px;
      font-size: 12px;
      padding-left: 3px;
      color: $absolute-white;
      background-color: $orange;
      border: 1px solid $orange;
      font-weight: bold;
    }
  }

  .radio-input {
    margin: 20px 20px 20px 0;

    .radio-wrapper {
      width: 450px;

      .radio-label {
        width: 405px;
      }
    }

    .radio-wrapper.short {
      width: 330px;

      .radio-label {
        width: 100%;
      }
    }
  }

  .agreements {
    margin: 0 30px;
    font-style: italic;

    .doc-link {
      font-style: italic;
      cursor: pointer;
      color: #1e4488;
      font-weight: 800;

      /*
      @media (max-width: 768px) {
        color: $orange;
      }
      */
    }
  }
  .phones {
    margin: 0 30px 20px;
    font-family: $font-primary; // $font-secondary;
    font-size: 12px;
    color: $orange;

    .phone {
      margin-right: 40px;
      font-weight: bold;
      font-size: 14px;
    }
    .subnote {
      color: rgb(24, 23, 23);
      font-style: normal;
    }
  }

  .legal {
    padding: 20px 0 40px;
    font-style: italic;
  }

  .draw {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    height: 220px;
    padding-bottom: 20px;
    @media (max-width: 768px) {
      padding-bottom: 0px;
    }
    .left {
      height: 200px;
      @media (max-width: 768px) {
        height: 265px;
        width: 100%;
      }
      @media (max-width: 500px) {
        height: 260px;
      }
      .subnote {
        font-size: 11px;
        color: rgb(48, 47, 47);
        font-style: normal;
        @media (max-width: 768px) {
          max-width: 290px;
          font-size: 14px !important;
          padding: 10px 35px;
          line-height: 1.5;
          margin: auto;
        }
        @media (max-width: 400px) {
          padding: 10px 15px;
        }
      }
      .credit {
        font-family: $font-primary; // $font-secondary;
        margin-bottom: 30px;

        .credit-line {
          font-size: 26px;
          color: $orange;
        }
      }
    }

    .draw-amount {
      font-family: $font-primary; //$font-secondary;
      font-size: 14px;
      color: $gray;
    }

    .draw-request {
      flex: 0 0 50%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: center;
      height: 200px;
      margin-left: auto;
      margin-top: 50px;

      .slidetext {
        text-align: center;
        font-style: italic;
        color: $orange;
        font-weight: 600;
        font-size: 12px;
        padding: 10px 0 0 0;
      }
      .cash {
        align-self: flex-end;
        width: 100%;
        text-align: -webkit-center;
        //margin-bottom: 30px;
        @media (max-width: 768px) {
          margin-top: 30px;
        }
      }

      .draw-amount {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        align-items: center;
        width: 230px;
        align-self: flex-start;
        margin-left: -20px;

        .dollar {
          font-family: $font-primary; // $font-secondary;
          font-size: 30px;
          line-height: 30px;
          color: $orange;
          font-weight: bold;
        }
        .draw-input {
          text-align: right;
          width: 66px;
          height: 30px;
          padding: 0 4px;
          border: none;
          //border-bottom: 1px solid $orange;
          font-family: $font-primary; //$font-secondary;
          font-size: 28px;
          line-height: 30px;
          background: $absolute-white;
          color: $orange;
          font-weight: bold;
        }
        .totaldrawamount {
          padding-top: 5px;
          border-top: 2px solid $light-gray;
          color: $orange;
        }
      }
      .slider {
        width: 100%;
        margin: 20px 0;
      }
      .button {
        font-size: 10px;
      }

      .depositamount {
        @media (max-width: 420px) {
          margin-left: -20px;
        }

        .amountwidth {
          display: flex;
          width: 360px;
          padding-top: 5px;
          @media (max-width: 376px) {
            width: 310px;
          }
          @media (max-width: 320px) {
            width: 294px;
          }

          .cashfeetitle {
            width: 220px;
            color: $gray;
            font-size: 13px;
            text-align: right;
            font-style: italic;
          }
          .cashfee {
            text-align: left;
            width: 120px;
            padding-left: 20px;
            color: $gray;
            font-weight: bold;
            @media (max-width: 320px) {
              width: 106px;
              padding-left: 10px;
            }
          }
          .cashfeehelpicon {
            position: relative;
            margin-top: -2px;
            margin-left: 5px;
            @media (max-width: 465px) {
              //margin-left:-25px;
              //margin-right: -20px;
            }
          }
          .depositamttitle {
            width: 220px;
            font-weight: bold;
            text-align: right;
            font-size: 15px;
            color: $gray;
            padding-top: 5px;
          }
          .depositamt {
            text-align: left;
            width: 120px;
            font-weight: 800 !important;
            padding-left: 20px;
            font-size: 16pt !important;
            color: $orange;
            @media (max-width: 720px) {
              font-size: 14pt;
            }
            @media (max-width: 420px) {
              font-size: 12pt;
            }
            @media (max-width: 320px) {
              font-size: 10pt;
              width: 106px;
              padding-left: 10px;
            }
          }
          .depositamthelpicon {
            position: relative;
            margin-top: -2px;
            margin-left: 5px;
            @media (max-width: 465px) {
              //margin-left:25px;
              //margin-right: -20px;
            }
          }
        }
        .depositamountline {
          width: 275px;
          border-bottom: 2px solid $light-gray;
          padding-top: 5px;
          margin-bottom: 5px;
          margin-left: 28px;
        }
      }
    }

    .request-made {
      align-self: flex-end;
      margin: 0 20px 40px 0;
      text-align: center;

      .thanks {
        font-size: 24px;
        margin-bottom: 20px;
      }
      .request {
        font-family: $font-primary; // $font-secondary;
      }
    }
  }

  .no-draw {
    @media (max-width: 768px) {
      text-align: center;
    }
  }
  .bottom {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 0 40px;

    .button {
      text-transform: uppercase;
      @media (min-width: 600px) and (max-width: 768px) {
        width: 45%;
      }
      @media (max-width: 600px) {
        width: 54%;
      }
    }

    .hours {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 12px;
      color: $green;
      font-weight: 700;

      .title {
        margin: 0;
        padding: 0;
        font-size: 12px;
      }
    }

    .call {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      color: $orange;
      margin-top: 20px;

      img {
        height: 20px;
        width: 20px;
        margin-right: 10px;
      }
    }

    .right {
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
      align-items: center;

      .pre-approved {
        font-family: $font-primary; // $font-secondary;
        text-align: center;
        color: $gray;
        font-size: 12px;
      }

      button {
        margin-top: 20px;
      }
    }
  }
  .wderror {
    margin-left: -36px !important;
  }

  .error {
    margin: 10px 0;
    //text-align: center;
    color: $error;
    //height: 18px;
  }

  .esignpasscodetxt .error {
    font-size: 13px;
    text-align: center;
    max-width: 290px;
    line-height: 1.5;
    @media (max-width: 1023px) {
      max-width: 320px;
    }

    @media (max-width: 500px) {
      font-size: 15px;
      margin-left: -52px;
      margin-top: 16px;
    }
    @media (max-width: 411px) {
      font-size: 14px;
    }
  }

  .agrdocdesc {
    margin-top: auto;
    margin-bottom: auto;
    width: 80%;
    color: $gray;
    font-weight: bold;
  }

  .agreementselect {
    color: #ffffff;
    background: #1e4488;
    //font-size: 14px;
    font-weight: 800;
    padding: 6px 30px;
    border: 2px solid #1e4488;
    width: 190px;
  }

  .agreementdeselect {
    color: #1e4488;
    background: #ffffff;
    //font-size: 14px;
    font-weight: 800;
    padding: 6px 30px;
    border: 2px solid #1e4488;
    width: 190px;
  }

  .help-icon .icon {
    padding: 1px 4px;
    font-size: 20px;
    margin-left: 14px;
    top: -4px;
  }

  .disabledrawreq {
    filter: grayscale(1);
  }
  .enabledrawreq {
    filter: none;
  }
  .consentfooter {
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    @media (max-width: 767px) {
      flex-direction: column;
    }

    .consentfootertext {
      font-size: 14px;
      font-weight: 600;
      color: #53575a;
      padding-right: 60px;
    }

    sup {
      margin-left: 1px;
    }
  }

  @media (min-width: 1024px) {
    section.form {
      width: 900px;
      padding: 30px 0px;
    }
  }

  @media (max-width: 1023px) {
    .consentheaderline {
      text-align: center;
      padding-left: 10%;
      padding-right: 10%;
      padding-top: 5px;
    }

    .button-orange {
      //float: right;
      width: 100%;
      max-width: 400px;
    }

    .choose {
      margin-left: 4px;
    }

    .radio-input {
      margin-right: 0;

      .radio-wrapper {
        width: 90%;

        .radio-label {
          width: 100%;
        }
      }

      .radio-wrapper.short {
        width: 90%;

        .radio-label {
          width: 100%;
        }
      }
    }

    .agreements {
      max-width: 100%;
    }

    .phones {
      //margin: 0 auto 30px;
      text-align: left;
      //max-width: 500px;

      .phone {
        margin: 0 0px;
      }

      .subnote {
        margin: 0 10px;
      }
    }

    .legal {
      padding: 10px 0 40px;
      max-width: 500px;
      margin: 0 auto;
      text-align: center;
    }

    .draw {
      flex-flow: column nowrap;
      align-items: left;
      height: initial;
      //padding: 20px;

      .no-draw {
        margin: 30px 0 0;
      }

      .draw-request {
        width: 100%;
        margin-left: 0;
        margin-top: 0px;

        @media (max-width: 480px) {
          padding-top: 20px;
        }
      }
    }

    .bottom {
      flex-flow: column nowrap;
      align-items: flex-start;

      .hours {
        margin-top: 20px;
      }

      .call {
        margin: 20px 0 30px;
      }

      .right {
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        align-items: center;

        .pre-approved {
          font-family: $font-primary; //$font-secondary;
          text-align: center;
          color: $gray;
          font-size: 12px;
        }

        button {
          margin-top: 20px;
        }
      }
    }

    c {
      margin: 10px 0 0 0;
      //text-align: center;
    }

    .agrdocdesc {
      margin-left: 36px;
      padding-top: 15px;
    }
  }

  .consentfooterbutton {
    //background:$gray !important;
    padding-top: 5px;
    padding-bottom: 5px;
    // font-size:16pt;
  }

  @media (max-width: 1024px) {
    margin-left: 12px;
  }

  @media (max-width: 720px) {
    .consentfooter {
      text-align: center;

      .consentfootertext {
        padding-right: 0px;
      }

      .consentfooterbutton {
        //float: right;
        margin-top: 16px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}

input.slider-input {
  z-index: 4;
}

input.slider-input:disabled {
  border: none;
}
.textuppercase {
  text-transform: uppercase;
}
input {
  border: solid 1px #dedfdd;
  padding: 3px 5px 3px 5px;
}
.password-show-icon {
  position: absolute;
  margin: 10px 280px;
  color: gray;
  text-align: center;
}
.instructions {
  font-size: 11px;
  min-width: 314px;
  margin-bottom: 18px;

  margin-top: 36px;
}
main .instructions h4 {
  padding-right: 0px;
  font-size: 14pt;
}
@media (min-width: 400px) and (max-width: 450px) {
  main .instructions h4 {
    padding-right: 0px;
    font-size: 12pt;
  }
}
@media (max-width: 400px) {
  main .instructions h4 {
    padding-right: 0px;
    font-size: 11pt;
  }
}

.contentsection {
  display: Flex;
  position: relative;
}

.savepassword {
  padding: 10px 20px;
  font-size: 14px;

  @media (max-width: 767px) {
    width: 50%;
  }
}

.space {
  width: 17%;
}
.esigntxtselection {
  color: $red;
  font-size: 14px;
  font-weight: 700;
  margin-left: 22px;
  @media (max-width: 767px) {
    margin-left: 48px;
  }
}
.esignpasscodesec {
  width: 100%;

  .esignpasscodeimg {
    //width: 10%;
    float: left;

    .Emailiconimg {
      margin: 10px 0px;
      //padding: 0px 5px;
      margin-bottom: 20px;
      height: 60px;
      -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
      @media (max-width: 885px) {
        width: 70px;
      }
    }
    .mobileiconimg {
      margin: 10px 0px;
      padding: 0px 5px;
      margin-bottom: 20px;
      height: 65px;
      -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
    }
  }

  .esignpasscodetxt {
    width: 40%;
    float: left;
    margin-top: 0px;
    padding-left: 30px;
    @media (max-width: 540px) {
      width: 75%;
      float: left;
      //padding-top: 20px;
      padding-left: 10px;
      //margin-top: 20px;
    }
    @media (min-width: 540px) and (max-width: 600px) {
      width: 64%;
      float: left;

      padding-left: 10px;
    }
    @media (min-width: 601px) and (max-width: 645px) {
      width: 55%;
      float: left;

      padding-left: 10px;
    }
    @media (min-width: 646px) and (max-width: 700px) {
      width: 53%;
      float: left;

      padding-left: 10px;
    }
    @media (min-width: 701px) and (max-width: 799px) {
      width: 46%;
      float: left;

      padding-left: 10px;
    }

    //  @media (max-width: 886px) {
    //       width: 70%;
    //       float: left;
    //       //padding-top: 20px;
    //       padding-left: 10px;
    //       //margin-top: 20px;
    //     }

    .field {
      width: 100%;

      .field-label {
        display: flex;

        margin: 5px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
    .otp-input {
      width: 0px;
      height: 40px;
      text-align: center;
      font-size: 45px;
      margin: 0 5px;
      border: none;
      border-bottom: 1px solid black;
      box-shadow: none;
      background-color: transparent;
      color: #333;
      transition: all 0.3s ease-in-out;
      font-family: Arial, Helvetica, sans-serif;
      border-radius: 0px;
      @media (max-width: 886px) {
        //font-size: 24px;
        margin: 0 3px;
      }
    }
  }
}

.passcodefield {
  border: none;
  background-color: transparent;
  border-bottom: 2px solid #959595;
  border-radius: 0px;
  width: 100%;
  font-size: 25px;
}

.esignbtnsection {
  display: flex;

  align-items: center;
  width: 100%;
  //margin-top: 30px;
  margin-top: 35px;
  height: 34px;

  @media (max-width: 767px) {
    place-content: space-around;
  }

  .esignbutton-resend {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 800;
    background-color: transparent;
    letter-spacing: 0px;
    border-radius: 0px;
    color: $gray;
    //padding: 2px;
    margin-left: 60px;
    margin-right: 42px;
    -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
    @media (max-width: 319px) {
      font-size: 14px;
      margin-left: 0px;
      margin-right: 30px;
    }

    @media (max-width: 439px) {
      font-size: 14px;
      margin-left: 0px;
      margin-right: 30px;
    }
    @media (max-width: 480px) {
      margin-left: 0px;
    }
    @media (min-width: 440px) and (max-width: 900px) {
      font-size: 14px;
      margin-right: 42px;
    }
  }

  .esignbutton-verify {
    text-transform: uppercase;
    color: $absolute-white;
    background: $red;
    font-size: 14px;
    font-weight: 800;
    padding: 7px 72px;
    -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
    @media (max-width: 412px) {
      font-size: 14px;
      padding: 6px 50px;
    }

    @media (min-width: 412px) and (max-width: 700px) {
      font-size: 14px;
      padding: 6px 50px;
    }
  }
  .esignbutton-verify:disabled {
    background: $gray;
    color: $absolute-white;
  }
}

.maincontentsectiondiv {
  width: 100%;
}
.leftcontentsection {
  width: 45%;
  float: left;
  padding: 5px;
  @media (max-width: 767px) {
    width: 100%;
  }
}
// .rightcontentsection{
// .field{
//   width:100%;
// }
// .contentheader{
//   font-weight: 600;
//   text-transform: none;
//   font-size: 16px;
//   letter-spacing: 0.2px;
//   margin: 0px;
//   padding: 0px 0px 10px 5px;
// }
//}
.esignleftcontentheader {
  font-size: 25px;
  color: $gray;
}

#divotpscreenheader.esigncontentheader {
  @media (max-width: 767px) {
    margin-top: 10px;
  }
}

.esigncontentheader {
  font-weight: 700;
  font-size: 21px;
  margin: 0px;
  color: $gray;
}
.smallcontentheader {
  font-weight: 700;
  font-size: 16px;
  margin: 0px;
  color: $gray;
}
.headercontextadjust {
  max-width: 780px;
  @media (max-width: 430px) {
    max-width: 330px;
  }
}

.secheader {
  padding-top: 10px;
  max-width: 600px;

  @media (max-width: 600px) {
    max-width: 330px;
  }
}

.esigncontenttext {
  font-size: 14px;
  font-style: normal;
  color: $gray;
  // margin: 6px 0;

  @media (max-width: 430px) {
    max-width: 330px;
  }
  @media (max-width: 400px) {
    margin-right: -10px;
  }
}
.hidden {
  display: none;
}
.bottomsection {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  height: 34px;

  .button {
    padding: 8px 36px;
    text-transform: uppercase;
    margin-left: 0px;
    font-size: 0.8rem;
  }
}
.txt {
  width: 70%;
  float: left;
  padding: 0px 10px;

  .txtsuccess {
    color: $orange;
    font-weight: 700;
    letter-spacing: 1px;
  }
  .txtconfrim {
    color: $orange;
    font-weight: 700;
    letter-spacing: 1px;
  }
}
.ml {
  margin-left: 40px;
  @media (max-width: 767px) {
    margin-left: 0px;
  }
}
.esigncongratssection {
  text-align: center;
}
.esignsuccessheader {
  color: $orange;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 25px;
  margin-bottom: 10px;
}
.congrats {
  margin: 0 0 100px;
}
.esignpasscodesuccess {
  display: flex;
  padding-left: 30%;
  padding-top: 35px;
  text-transform: uppercase;

  @media (max-width: 480px) {
    display: flex;
    padding-left: 0%;
    padding-top: 35px;
    margin-right: -30px;
  }

  //  //width: 100%;
  // display: flex;
  //   padding-left: 20%;
  //   padding-top: 35px;
  // text-align: center;
  .esignimg {
    width: 60px;
    height: 60px;
    /* padding-left: 44%; */

    float: left;
    margin: 15px 0px 10px 0px;
    // width: 10%;
    // float: left;
    // margin-right: 10px;
    //   width: 47%;
    // padding-left: 44%;

    //margin: 10px 0px;
    -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  }
  .text {
    width: 70%;
    float: left;
    padding: 0px 10px;
  }
  .txtsuccess {
    color: $orange;
    font-weight: 900;
    letter-spacing: 1px;
    font-size: 18px;
    margin-bottom: 0px;
  }
  .txtconfrim {
    color: $orange;
    font-weight: 900;
    letter-spacing: 1px;
    font-size: 17px;
    padding-left: 55px;
    margin-top: 10px;
    margin-bottom: 0px;
  }
}

.esigninputfield {
  border: none;
  width: 15ch;
  background: repeating-linear-gradient(
      90deg,
      #00000029 0,
      #00000029 1ch,
      transparent 0,
      transparent 2.5ch
    )
    0 100%/100% 2px no-repeat;
  color: #4c4747;
  font: 6ch consolas, monospace;
  letter-spacing: 1.5ch;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 2px;
}
.inputfield {
  border: none;
  width: 9ch;
  background: repeating-linear-gradient(
      90deg,
      #00000029 0,
      #00000029 1ch,
      transparent 0,
      transparent 1.5ch
    )
    0 100%/100% 2px no-repeat;
  color: #4c4747;
  font: 5ch consolas, monospace;
  letter-spacing: 0.5ch;
}
.esigntxtblue {
  font-size: 14px;
  color: $light-blue;
  margin: 0px 0px 8px 0px;
  font-weight: 700;
}
.radio-input .radio-checkmark {
  height: 15px;
  width: 15px;
}
.rowcontainer {
  .rows {
    width: 100%;

    .field {
      width: 50%;
    }
  }
}

.contentsection-password {
  float: left;
  width: 60%;

  @media (max-width: 767px) {
    .field {
      width: 100%;
    }
    .password {
      flex: 0 0 85% !important;
    }
    .password-show-icon {
      @media (max-width: 399px) {
        margin: 10px 142px;
      }

      @media (min-width: 400px) and (max-width: 480px) {
        margin: 10px 167px;
      }
      @media (min-width: 400px) and (max-width: 420px) {
        margin: 10px 155px;
      }
      @media (min-width: 481px) and (max-width: 530px) {
        margin: 10px 197px;
      }

      @media (min-width: 530px) and (max-width: 640px) {
        margin: 10px 230px;
      }
    }
    .contentsection-instructions {
      .instructions {
        min-width: auto;
        font-size: 11px;
        min-width: 314px;
        margin-bottom: 25px;
        margin-top: 8px;
        margin-left: 20px;
        //padding-left: 12px;
      }
    }
  }
}

@media (max-width: 767px) {
  .contentsection {
    .contentsection-instructions {
      .instructions {
        min-width: auto;
      }
    }
    .bottom {
      width: 66%;
    }
  }
}

.contentsection-instructions {
  float: right;
  width: 35%;

  @media (max-width: 400px) {
    width: 50%;
  }
  @media (max-width: 376px) {
    width: 40%;
  }

  .instructions {
    font-size: 13px;
    min-width: 200px;
    max-width: 230px;
    margin-bottom: 18px;
    margin-left: -38%;
    margin-top: 60px;
    @media (max-width: 767px) {
      min-width: auto;
      font-size: 11px;
      min-width: 314px;
      margin-bottom: 0px;
      margin-left: 20px;
      margin-top: 30px;
    }

    @media (max-width: 430px) {
      padding-left: 24px;
      margin-right: -10px;
    }
  }
}

.go-green {
  color: $green;
  font-weight: bold;
  font-size: 12px;
}
.go-normal {
  color: $black;
  font-weight: normal;
  font-size: 12px;
}

.textsucessalignment {
  padding-left: 31%;
  margin-bottom: 5%;
  @media (max-width: 480px) {
    padding-left: 0%;
    margin-bottom: 5%;
  }
}
.contentsection {
  .bottom {
    display: flex;

    align-items: center;
    padding: 10px 0 40px;
    width: 100%;
    @media (max-width: 767px) {
      width: 66%;
    }
  }
}
.matchicon {
  position: absolute;
  margin-top: 16px;
  font-size: 10px;
  font-weight: bold;
  color: $green;
  padding-top: 2px;
}
.approved {
  color: $gray;
  font-family: "AvertaStd-Regular", Helvetica, Arial, sans-serif;
  //width: 80%;
  text-align: center;
  //padding-right: 83px;
  font-size: 18px;
  font-weight: bold;

  margin-right: auto;

  @media (min-width: 120px) and (max-width: 391px) {
    padding-right: 0px;
    font-size: 14px;

    margin-right: 0px;
  }
  @media (min-width: 391px) and (max-width: 720px) {
    padding-right: 0px;
    font-size: 16px;

    margin-right: 0px;
  }
  //   @media (min-width: 457px) and  (max-width:767px) {

  // padding-right: 0px;
  // font-size: 18px;

  // margin-right: 0px;
  //  }
  @media (min-width: 768px) and (max-width: 1024px) {
    padding-right: 0px;
    font-size: 18px;

    margin-right: 0px;
  }
}
.esignconfirmmsg {
  width: 64%;
  float: right;
  padding: 13px 5px 5px 10px;
}
@media (min-width: 721px) and (max-width: 1024px) {
  main .button-orange {
    width: 100%;
    max-width: 320px;
    float: right;
  }
  .approved {
    padding-right: 0px;
    font-size: 16px;
    font-weight: bold;

    margin-right: 0px;
  }
}

@media (max-width: 480px) {
  main .button-orange {
    width: 100%;
    max-width: 400px;
    //float: right;
  }
}
// main .consentfooter{
// .approved{
//   font-size: 14px;
// }
// }
.input-reject::after {
  //top: -3px;
  top: 0px;
  font-size: 20px;
  font-weight: 900;
  @media (max-width: 400px) {
    left: 8px;
  }
}
.input-accept::after {
  top: -3px;
  font-size: 25px;
  font-weight: 900;
  left: 8px;
}
.resenderror {
  font-size: 12px;
  color: $black;
  text-align: left;
  margin-left: -84px;
  margin-top: 18px;

  @media (max-width: 767px) {
    font-size: 15px;
    margin-left: -65px;
    margin-top: 18px;
    margin-right: -48px;
    max-width: 380px;
  }
  @media (max-width: 400px) {
    font-size: 14px;
  }
  a {
    border-bottom: none;
    text-decoration: underline;
    color: $black;
  }
}

 .hidden-xs {
   @media (max-width: 768px) {
     display: none;
   }
 }
</style>