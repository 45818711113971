// export const initialiseBlackbox = (value = '') =>
//     Object.assign(window.IGLOO = window.IGLOO || {
//             "enable_flash": false,
//             "bbout_element_id": 'ioBB',
//             "bb_callback": function (bb, complete) {
//                 console.log("bb:-");
//                 console.log(bb);
//                 console.log("complete:-");
//                 console.log(complete);
//                 var bb_field = document.getElementById("ioBB");
//                 bb_field.value = bb;

//             },
//             "loader": {
//                 "version": "5.2.2"
//             }
//         },
//         { getBlackbox: () => ({ backbox: value }) });
export default function initialiseBlackbox() {
  //Subscriber supplied variables for snare// Snare operation to perform
  //var io_operation = 'ioBegin';

        // io_bbout_element_id should refer to the hidden field in your form that contains the blackbox
        var io_bbout_element_id = 'ioBB';

        var io_install_flash = false;
        var io_install_stm = false;
        var io_exclude_stm = 12;
        var io_install_rip = true;
        // var io_flash_needs_update_handler = "";
        // var io_install_flash_error_handler = "";

    window.IGLOO = window.IGLOO || {
    "enable_flash": false,
    "bbout_element_id": io_bbout_element_id,
    "install_stm"       : io_install_stm,        // do not install Active X
    "exclude_stm"       : io_exclude_stm,           // do not run Active X
    "install_flash"     : io_install_flash,        // do not install Flash
    "min_flash_version" : 9999,        // disable Flash
    "enable_rip"        :io_install_rip,
    "bb_callback": function (bb, complete) {
        var bb_field = document.getElementById("ioBB");
        bb_field.value=complete;
        bb_field.value = bb;
        //console.log("bb : ");
        //console.log(bb_field.value);
        
        // console.log("complete : ");
        //console.log(complete);
        // console.log(io_operation);
        // console.log(io_bbout_element_id);
        // console.log(io_install_flash);
        // console.log(io_install_stm);
        // console.log(io_exclude_stm);
        // console.log(io_install_rip);
        // console.log(io_flash_needs_update_handler);
        // console.log(io_install_flash_error_handler);
        
    },
    loader: {
      version: "5.2.2",
    },
  };
}
