import dayjs from "dayjs";

export const date = (date) => {  
  if (date != null && date.toString().length >= 10) {
    let dateval = dayjs(date).format("MM/DD/YYYY");
    return dateval == "Invalid Date" ? null : dateval;
  } else {
    return date;
  }
};

export const dateString = (date) => {
  return dayjs(date).format("MMM. D, YYYY");
};

export const dateStringLong = (date) => {
  return dayjs(date).format("dddd, MMM. D, YYYY");
};
