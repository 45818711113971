<template>
    <span style="white-space: nowrap;"><span ref="xact">Xact</span><span style="font-weight: 100" ref="flex"><i>Flex</i></span><sup style="font-size: 8px;margin-left: 3px;"><span class="trademark">&reg;</span></sup></span>
</template>

<script>
export default {
  name: "XactFlexBrandedText",
};
</script>
<style scoped lang="scss">
.trademark {
    font-size: 12px;
    // vertical-align: top;
    font-weight: 500;
  }
</style>