import Home from '@/views/marketing/Home.vue'
import Faq from '@/views/marketing/Faq';
import HowItWorks from '@/views/marketing/HowItWorks';
//import Contact from '@/views/marketing/ContactUs';
import Privacy from '@/views/marketing/Privacy';
import TermsAndConditions from '@/views/marketing/TermsAndConditions';
import CaliPrivacy from '@/views/marketing/CaliPrivacy';
import CaliPrivacyInfo from '@/views/marketing/CaliPrivacyInfo';

import AboutUs from '@/views/marketing/AboutUs';

import AppPersonal from '@/views/application/Personal';
import AppAddress from '@/views/application/Address';
import AppIncome from '@/views/application/Incomes';
import AppPayFrequency from '@/views/application/PayFrequency';
import AppBanking from '@/views/application/Banking';
import AppApproved from '@/views/application/Approved';
import AppDenied from '@/views/application/Denied';
import LeadPostError from '@/views/application/LeadPostError';

import EsignConsent from '@/views/esign/Consent';
import EsignSignature from '@/views/esign/Signature';
import EsignContact from '@/views/esign/Contact';

import Login from "@/views/login/Login";
import VerifyIdentity from "../views/login/VerifyIdentity";
import ForgotPassword from "../views/login/ForgotPassword";
import CreatePassword from "../views/login/CreatePassword";
import ResetPassword from "../views/login/ResetPassword";

import PortalAccountSummary from '@/views/portal/AccountSummary';
import PortalTransactions from '@/views/portal/Transactions';
import PortalDocuments from '@/views/portal/Documents';
import PortalDrawReqMobile from '@/views/portal/DrawReqMobile';
import PortalPayments from '@/views/portal/Payments';
import PortalPreferences from '@/views/portal/Preferences';
import PortalInterrupterClosed from '../views/portal/AccountInterrupterClosed';
import PortalInterrupterCredit from '../views/portal/AccountInterrupterCredit';

import NotFound from '@/views/NotFound';

export default [

  // Marketing Pages
  
  { path: '/', name: 'home', component: Home, meta: { isHome: true, showApply: true, showLogin: true } },
  { path: '/faq', name: 'faq', component: Faq, meta: { isHome: true, showApply: true, showLogin: true } },
  { path: '/how-it-works', name: 'how-it-works', component: HowItWorks, meta: { showApply: true, showLogin: true } },
  //{ path: '/contact-us', name: 'contact', component: Contact, meta: { showApply: true, showLogin: true } },
  { path: '/privacy-policy', name: 'privacy', component: Privacy, meta: { showApply: true, showLogin: true } },
  { path: '/termsandconditions', name: 'TermsAndConditions', component: TermsAndConditions, meta: { showApply: true, showLogin: true } },
  { path: '/caprivacy-policy', name: 'caprivacy', component: CaliPrivacy, meta: { showApply: true, showLogin: true } },
  { path: '/caprivacy-policy-info', name: 'caprivacy-info', component: CaliPrivacyInfo, meta: { showApply: true, showLogin: true } },
  { path: '/about-us', name: 'about-us', component: AboutUs, meta: { showApply: true, showLogin: true } },

  // Application Pages  
  { path: '/apply/personal', name: 'app-personal', component: AppPersonal, meta: { isAppPage: true } },
  { path: '/apply/address', name: 'app-address', component: AppAddress, meta: { isAppPage: true } },
  { path: '/apply/income', name: 'app-income', component: AppIncome, meta: { isAppPage: true } },
  { path: '/apply/payfrequency', name: 'app-payfrequency', component: AppPayFrequency, meta: { isAppPage: true } },
  { path: '/apply/banking', name: 'app-banking', component: AppBanking, meta: { isAppPage: true } },
  { path: '/apply/approved', name: 'app-approved', component: AppApproved, meta: { isEsignPage: true, isEsignPagefooter: true } },
  { path: '/apply/review', name: 'app-denied', component: AppDenied, meta: { isAppPage: true } },
  { path: '/apply/leadpost', name: 'leadpost-error', component: LeadPostError },

  // eSign Pages
  { path: '/esign/consent', name: 'esign-consent', component: EsignConsent, meta: { isEsignPage: true, isEsignPagefooter: true } },
  { path: '/esign/signature', name: 'esign-signature', component: EsignSignature, meta: { isEsignPage: true, isEsignPagefooter: true } },
  { path: '/esign/contact', name: 'esign-contact', component: EsignContact, meta: {isEsignContact:true, isPortalNavDisplay: true, isEsignPagefooter: true } },

  // Login Pages
  { path: '/login', name: 'login', component: Login, meta: { showApply: true, hidelogin: true, isLoginPage: true } },
  { path: '/verify-identity', name: 'verify-identity', component: VerifyIdentity, meta: { showApply: true } },
  { path: '/create-password', name: 'create-password', component: CreatePassword, meta: { showApply: true, hidelogin: true, isLoginPage: true } },
  { path: '/forgot-password', name: 'forgot-password', component: ForgotPassword, meta: { showApply: true, hidelogin: true, isLoginPage: true } },
  { path: '/reset-password', name: 'reset-password', component: ResetPassword, meta: { showApply: true, hidelogin: true, isLoginPage: true } },

  // Portal Pages
  { path: '/account', name: 'portal-account', component: PortalAccountSummary, meta: { portal: true } },
  { path: '/transactions', name: 'portal-transactions', component: PortalTransactions, meta: { portal: true } },
  { path: '/documents', name: 'portal-documents', component: PortalDocuments, meta: { portal: true } },
  { path: '/draw', name: 'portal-draw', component: PortalDrawReqMobile, meta: { portal: true } },
  { path: '/payments', name: 'portal-payments', component: PortalPayments, meta: { portal: true } },
  { path: '/preferences', name: 'portal-preferences', component: PortalPreferences, meta: { portal: true } },
  { path: '/accountclosed', name: 'portal-interrupterclosed', component: PortalInterrupterClosed, meta: { portal: true } },
  { path: '/accountcredit', name: 'portal-interruptercredit', component: PortalInterrupterCredit, meta: { portal: true } },

  // Default Routes
  { path: '/:pathMatch(.*)*', name: '404', component: NotFound }
]