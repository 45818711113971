const initialState = {
  messageText: null,
  didKnowText: null,
  showTransactionDetails: false,
  showDocumentDetails: false,
  showDrawRequest: false,
  drawAmount: 0,
  selectedAmount: null,
  otherAmount: null,
  paymentAmount: null,
  paymentDate: null,
  paymentMade: false,
  showPartialSummary: false,
  recentTransactionsTableData: [],
  billingStatementsTableData: [],
  allTransactionsTableData: [],
  documentsTableData: [],
  lineOfCreditAgreement: [],
  lineOfCreditAgreementPDF:null,
  customerId:null,
  token:null,
  paymentEffectiveDate: null,
  maxACHBizDate: 0
}

//import { minimumDraw } from "@/config/amounts";

export default {
  state: () => {
    return {
      ...initialState
    }},
  getters: {  },
  mutations: {
    resetPortal(state) {
      Object.keys(state).forEach(key => {
        state[key] = initialState[key];
      })
    },
    updateShowPartialSummary(state, value) {
      state.showPartialSummary = value;
    },
    updateShowTransactionDetails(state, value) {
      state.showTransactionDetails = value;
    },
    updateShowDocumentDetails(state, value) {
      state.showDocumentDetails = value;
    },
    updateShowDrawRequest(state, value) {
      state.showDrawRequest = value;
    },
    updateDrawAmount(state, value) {
      state.drawAmount = value;
    },
    updateSelectedAmount(state, value) {
      state.selectedAmount = value;
    },
    updateOtherAmount(state, value) {
      state.otherAmount = value;
    },
    updatePaymentAmount(state, value) {
      state.paymentAmount = value;
    },
    updatePaymentDate(state, value) {
      state.paymentDate = value;
    },
    updatePaymentMade(state, value) {
      state.paymentMade = value;
    }
  },
  
  actions: {

    async getPortalMessage({ state }) {
      state.messageText = 'Thank you for visiting today.';
    },
    async getDidKnowMessage({ state }) {

      // Mock Implementation
      state.didKnowText = 'You may be able to significantly reduce the finance charges you pay by making extra ' +
        'payments or paying off your draws early. With XactFlex, there are no penalties for early payoff.';
    },
    async getRecentTransactionsTableData() {
     // alert("state in portal");
     return this.getters.recentTransactionsTableData;
     
    },
    async getBillingStatementsTableData() {
        return this.getters.billingStatementsTableData;
    },
    async getAllTransactionsTableData() {
     return this.getters.allTransactionsTableData;
    },
    async getpaymentEffectiveDate(){
     // alert("this.getters.paymentEffectiveDate : " + this.getters.paymentEffectiveDate);
      return this.getters.paymentEffectiveDate;
    },
    async getmaxPaymentDate()
    {
     // alert(" this.getters.maxACHBizDate : " + this.getters.maxACHBizDate)
      return this.getters.maxACHBizDate;
    },
    async getDocumentsTableData({ state }) {
      state.lineOfCreditAgreementPDF = this.getters.docPdf;
      
       state.lineOfCreditAgreement = [
        {
          'line of credit agreement':
            // 'Application Agreement | Electronic Consent | Privacy Policy | TCPA Disclosure',
            'Application & Credit Line Agreement', 
          'download/view': this.getters.docPdf,
        }
      ];
      return this.getters.allStatementsTableData;
    },
    }
  }
