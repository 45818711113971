export const minimumCreditAmount = process.env.VUE_APP_MINIMUM_CREDIT_AMOUNT;
export const maximumCreditAmount = process.env.VUE_APP_MAXIMUM_CREDIT_AMOUNT;
export const newLineCreditAmount = process.env.VUE_APP_NEW_CREDIT_AMOUNT;
export const defaultLoanAmount = process.env.VUE_APP_DEFAULT_REQUESTED_LOAN_AMOUNT;
export const lvxURL = process.env.VUE_APP_LVX_URL;
export const lvxScript = process.env.VUE_APP_LVX_SCRIPT;
export const lvxWidgetID = process.env.VUE_APP_LVX_WIDGET_ID;
export const minimumDraw = 100;
export const drawIncrement = 25;
export const minimumOneTimePayment = 10;
export const PayBankruptcy = 0;
export const EmplType = "F";
export const JobTitle = "NA";
export const WorkShift = "N";
export const Cust18YrsOld = "Y";
export const ActiveFlag = "P";
export const PayrollActiveFlag = "P";
export const LoanType = "S";
export const PayGarnishment = "N";
export const BankAccountType = "C";
export const WorkPhone = "999-999-9999";
export const WorkExt = "9999";
export const lvxServiceID = process.env.VUE_APP_LVX_SERVICEID;
export const lvxFrom = process.env.VUE_APP_LVX_FROM;
export const actonAccountID = process.env.VUE_APP_ACTON_ACCOUNT_ID;
export const actonTemplateID = process.env.VUE_APP_ACTON_TEMPLATE_ID;
export const actonFromEmailID = process.env.VUE_APP_ACTON_FROM_EMAIL_ID;
export const actonReplytoEmailID = process.env.VUE_APP_ACTON_REPLY_TO_EMAIL_ID;

export const acton2FAFromEmailID = process.env.VUE_APP_ACTON_2FA_FROM_EMAIL_ID;
export const acton2FATemplateID = process.env.VUE_APP_ACTON_2FA_TEMPLATE_ID;