const initialState = {
    tokenID: null,
    iovationproceedstatus: true,
    enduserip: '',
    beginblackboxcode: ''  
}

//import Vue from 'vue';
import {$gMiddleLayerApi} from '../config/appconfigsettings';

export default {
    state: () => {
        return {
            ...initialState
        }
    },
    mutations: {
        updateIovationTokenId(state, value) {
            state.tokenID = value;
        },       
        updateProceedStatus(state, value) {
            state.iovationproceedstatus = value;
        },
        updateBlackBoxCode(state, value) {
            state.beginblackboxcode = value;
        },
        updateUserIp(state, value) {
            state.enduserip = value;
        },       
    },
    actions: {
        async PostIovation({ state, commit }) {
            try {
                var auth = 'Bearer ' + state.tokenID;
                var input = JSON.stringify({
                    ipAddress: state.enduserip,
                    blackBoxData: state.beginblackboxcode
                })


                const iovationresponse = await fetch( $gMiddleLayerApi + `Iovations/Process`, {
                    "method": "POST",
                    "body": input,
                    "headers": {
                        'Content-Type': 'application/json',
                        'Authorization': auth
                    }
                })
                
                if (iovationresponse.ok) {
                    const jVal = await iovationresponse.json(); // Get JSON value from the response body
                    //console.log(jVal);
                    
                    commit("updateIovationTokenId", jVal.tokenToUse);
                    Promise.resolve(jVal);
                    return jVal;
                }
                else
                    return Promise.reject('Call failed');

            }
            catch (ex) {
                //console.log(ex);
            }
        },

        async GetIovationConfig({ state, commit }) {            
            try {
                var auth = 'Bearer ' + state.tokenID;
                const iovationresponse = await fetch( $gMiddleLayerApi + `Iovations/Config`, {
                    "method": "GET",
                    "headers": {
                        'Content-Type': 'application/json',
                        'Authorization': auth
                    }
                })
                
                if (iovationresponse.ok) {
                    const jVal = await iovationresponse.json(); // Get JSON value from the response body

                    //console.log(jVal);
                    commit("updateProceedStatus", (jVal.status));
                    commit("updateIovationTokenId", jVal.tokenToUse);
                    Promise.resolve(jVal);
                    return jVal;
                }
                else
                    return Promise.reject('Call failed');
            } catch (ex) {
                //console.log(ex);
            }
        },
    }
}