<template>
  <div class="make-payment">
    <div class="payment cardNew">
      <div id="headerAccountPayment" tabindex="-1">
        <div class="top" v-if="!paymentMade && !paymentSubmitted">
          <SectionTitle
            ><template v-slot:pagetitle>
              Make a One-Time Payment
            </template></SectionTitle
          >

          <!-- <div class="note" v-if="!delinquentOrChargeoff">
          <h5>Automatic EFT payments are withdrawn from your bank account on a regular schedule that aligns with your pay dates.</h5>
        </div>-->
          <div class="subnote" v-if="!delinquentOrChargeoff">
            <span
              >On this page, you can opt to make one-time payments of
              {{ currencyShort(minPaymentAmount) }} or more. These payments
              occur outside of your automatic EFT payments.
            </span>
          </div>
          <div v-else>
            Your account is past due. Please set up a payment below. If you'd
            like to discuss options with an Account Specialist, call
            <a
              :href="'tel:' + companyInfo.advancedCustomerServicePhone"
              class="orange"
              >{{ companyInfo.advancedCustomerServicePhone }}</a
            >.
          </div>
          <div
            class="next"
            v-if="minimumPaymentDue > 0 && !delinquentOrChargeoff"
          >
            <span class="payMessage" v-if="todayDate === nextPaymentDate"
              >Today's payment is in process.<br
            /></span>
            Your next minimum payment of
            {{ currencyLong(minimumPaymentDue) }} will be due on
            {{ dateStringLong(nextPaymentDate) }}.
          </div>
          <div class="next" v-else-if="!delinquentOrChargeoff">
            You don't have any upcoming payments at this time.
          </div>
          <!-- <div class="next delinquent" v-else>
          Your account is past due in the amount of  {{pastDue | currencyLong}}.-->
          <br />
          <div>
            <span class="subDelinquent" v-if="delinquent"
              >Your Minimum Payment of {{ currencyLong(currentDue) }} is due on
              {{ dateStringLong(nextPaymentDate) }};<br />
              this does not include your Past Due amount of
              {{ currencyLong(pastDue) }}.
            </span>
          </div>
        </div>
        <div
          class="top"
          v-else-if="
            paymentSubmitted &&
            !paymentMade &&
            paymentAmount > 0 &&
            !paymentError
          "
        >
          <div id="paymentreview" tabindex="-1">
            <SectionTitle>
              <template v-slot:pagetitle>Review & Authorization</template>
            </SectionTitle>

            <span
              >Please review your payment details. If everything is in order,
              submit your payment.</span
            >
          </div>
        </div>
        <div
          class="top confirmMsg"
          v-else-if="paymentMade"
          id="thanksmsg"
          tabindex="0"
        >
          <SectionTitle class="thanksmsg">
            Thanks For Scheduling Your Payment
          </SectionTitle>
          <div class="confirmed">
            <div class="summary">
              <div class="summed">
                <span>Pay from</span>
                <span class="value"
                  >{{ "EFT " + accountType + " Acct "
                  }}{{ accountLastFour }}</span
                >
              </div>
              <div class="summed">
                <span>Date</span>
                <span class="value">{{ date(paymentDate) }}</span>
              </div>
              <div class="summed">
                <span>Amount</span>
                <span class="value"
                  ><span
                    v-if="
                      selectedAmount === 'minimum' && !delinquentOrChargeoff
                    "
                    >Minimum Due </span
                  ><span v-else-if="selectedAmount === 'minimum'"
                    >Past Due </span
                  ><span v-else-if="selectedAmount === 'other'">Other </span
                  ><span v-else-if="selectedAmount === 'full'">Balance </span
                  >{{ currencyLong(paymentAmount) }}</span
                >
              </div>
            </div>
            <div class="confirmNum" v-if="paymentTransactionId != ''">
              CONFIRMATION NUMBER<br />#{{ paymentTransactionId }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="bottom"
        :class="this.accountBalance <= 0 ? 'zeroBalance' : null"
      >
        <div class="folders" v-if="!paymentMade && !paymentSubmitted">
          <div class="folder eftMethod" v-if="activeFolder === 'method'">
            <div class="label">
              1. Method
              <div class="selection"></div>
              <div class="icon">
                <button
                  tabindex="0"
                  class="btnarrow"
                  @click="focustabelements($event, null)"
                  @keypress.space="focustabelements($event, null)"
                  aria-label="Collapse method section "
                >
                  <i class="fas fa-chevron-down" />
                </button>
              </div>
            </div>
            <div id="eftlabel" class="eftSelected" tabindex="0">
              {{ "EFT " + accountType + " Acct "
              }}<span class="orange">{{ accountLastFour }}</span>
            </div>
            <div class="note" tabindex="0" v-if="!delinquentOrChargeoff">
              If you want to make payment by debit card or change the checking
              account we have on file, please contact an Account Specialist at
              <a
                v-if="!delinquentOrChargeoff"
                :href="'tel:' + companyInfo.contactPhone"
                class="no-border"
                >{{ companyInfo.advancedCustomerServicePhone }}</a
              ><a
                v-else
                :href="'tel:' + companyInfo.advancedCustomerServicePhone"
                class="no-border"
                >{{ companyInfo.advancedCustomerServicePhone }}</a
              >
              .
            </div>
            <div class="note" tabindex="0" v-else>
              If you want to make payment by debit card or change the checking
              account we have on file, please contact an Account Specialist at
              <a
                v-if="!delinquentOrChargeoff"
                :href="'tel:' + companyInfo.contactPhone"
                class="no-border"
                >{{ companyInfo.contactPhone }}</a
              ><a
                v-else
                :href="'tel:' + companyInfo.advancedCustomerServicePhone"
                class="no-border"
                >{{ companyInfo.advancedCustomerServicePhone }}</a
              >.
            </div>
            <div class="buttons">
              <button @click="resetPayment" class="button-cancel">
                CANCEL
              </button>
              <button
                class="button-confirm"
                @click="focustabelements($event, 'amount')"
              >
                CONTINUE
              </button>
            </div>
          </div>
          <div class="folder collapsed" v-else>
            <fieldset>
              <legend class="visually-hidden">1. Method</legend>
              <div class="label clickable">
                <label class="lblheader" id="lblmethodexpand">
                  1. Method
                  <div class="selection">
                    <span class="value">
                      {{ "EFT " + accountType + " Acct " }}</span
                    ><span class="orange">{{ accountLastFour }}</span>
                  </div>
                </label>
                <div class="icon">
                  <button
                    id="btnmethodexpand"
                    aria-labelledby="lblmethodexpand"
                    ref="methodexpand"
                    class="btnarrow"
                    :tabindex="maxPendingTranreachedOrZeroBalance ? -1 : 0"
                    aria-expanded="false"
                    @click="focustabelements($event, 'method')"
                    @keydown.space="focustabelements($event, 'method')"
                  >
                    <i class="fas fa-chevron-right" />
                  </button>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="folder" v-if="activeFolder === 'amount'">
            <div class="label">
              2. Amount
              <div
                class="selection"
                v-if="paymentAmount && activeFolder != 'amount'"
              >
                <span
                  v-if="selectedAmount === 'minimum' && !delinquentOrChargeoff"
                  >Next Minimum Due </span
                ><span v-else-if="selectedAmount === 'minimum'">Past Due </span
                ><span v-else-if="selectedAmount === 'other'"
                  >Other Amount </span
                ><span v-else-if="selectedAmount === 'full'"
                  >Pay Off Balance </span
                ><span class="orange">{{ currencyLong(paymentAmount) }}</span>
              </div>
              <div class="icon">
                <button
                  tabindex="0"
                  class="btnarrow"
                  @click="focustabelements($event, null)"
                  @keypress.space="focustabelements($event, null)"
                  aria-label="Collapse amount section"
                >
                  <i class="fas fa-chevron-down" />
                </button>
              </div>
            </div>
            <div class="centerdiv">
              <span class="ind">Select one</span>
              <div class="amount-wrapper">
                <div
                  id="minduesec"
                  :role="minimumPaymentDue != 0 ? 'group' : ''"
                  class="amount-select narrow"
                  :tabindex="minimumPaymentDue === 0 ? -1 : 0"
                  aria-labelledby="minamtdescription"
                  v-if="!chargeOff"
                  :class="[
                    selectedAmount === 'minimum' ? 'selected' : null,
                    minimumPaymentDue === 0 ||
                    this.paymentEffectiveDate > this.nextPaymentDate
                      ? 'disabled'
                      : null,
                  ]"
                  @click="focusamtbutton()"
                  @keydown.space="focusamtbutton()"
                  @focus="
                    minimumPaymentDue === 0 ||
                    paymentEffectiveDate > nextPaymentDate
                      ? null
                      : selectAmount('minimum')
                  "
                >
                  <input
                    type="radio"
                    name="selectedAmount"
                    v-model="selectedAmount"
                    hidden="true"
                    value="minimum"
                  />
                  <span class="radio-checkmark" />
                  <span
                    class="radio-label in-line"
                    v-if="!delinquentOrChargeoff"
                  >
                    Next&nbsp; <span class="regular-view">Minimum</span
                    ><span class="mobile-view">Min.</span> &nbsp;Due
                  </span>
                  <span class="radio-label" v-else> Past Due </span>
                  <br />
                  <span class="amount orange">
                    <span class="amount orange" v-if="!delinquentOrChargeoff">
                      {{ currencyLong(minimumPaymentDue) }}
                    </span>
                    <span class="amount orange" v-else>
                      {{ currencyLong(pastDue) }}
                    </span>
                  </span>
                </div>
                <div
                  id="fullsec"
                  tabindex="0"
                  role="group"
                  :aria-labelledby="
                    minimumPaymentDue === 0
                      ? 'fullamtdescription minduesec'
                      : 'fullamtdescription'
                  "
                  class="amount-select payoff narrow"
                  :class="[
                    selectedAmount === 'full' ? 'selected' : null,
                    this.paymentEffectiveDate > this.nextPaymentDate
                      ? 'disabled'
                      : null,
                  ]"
                  @click="focusamtbutton()"
                  @focus="
                    paymentEffectiveDate > nextPaymentDate
                      ? null
                      : selectAmount('full')
                  "
                  @keydown.space="focusamtbutton()"
                >
                  <input
                    type="radio"
                    name="selectedAmount"
                    v-model="selectedAmount"
                    hidden="true"
                    value="full"
                  />
                  <span class="radio-checkmark" />
                  <span class="radio-label"> Pay Off Balance </span><br />
                  <span class="amount orange">
                    <span class="amount orange">
                      {{ currencyLong(accountBalance) }}
                    </span>
                  </span>
                </div>
                <div
                  class="amount-select narrow"
                  :class="selectedAmount === 'other' ? 'selected' : null"
                  @click="selectAmount('other')"
                >
                  <input
                    type="radio"
                    name="selectedAmount"
                    v-model="selectedAmount"
                    hidden="true"
                    value="other"
                  />
                  <span class="radio-checkmark" />
                  <label
                    id="lblOtherAmt"
                    for="otherPaymentAmount"
                    class="radio-label"
                  >
                    Other Amount </label
                  ><br />
                  <span class="amount orange">
                    <span class="orange">$</span>
                    <input
                      name="otherAmountInput"
                      id="otherPaymentAmount"
                      ref="otherPaymentAmount"
                      type="number"
                      v-model.number="otherAmount"
                      @change="handleOtherAmount(otherAmount)"
                      @keyPress="isNumber($event)"
                      @focus="focuselement($event)"
                      @click="focuselement($event)"
                      aria-describedby="otheramtdescription"
                    />
                  </span>
                </div>
              </div>
              <div class="centerdiv">
                <div class="tooltip-container">
                  <div class="ttipMin" v-if="!chargeOff">
                    <div
                      v-if="selectedAmount === 'minimum'"
                      id="minamtdescription"
                    >
                      <span class="icon">
                        <i class="fas fa-chevron-up" />
                      </span>
                      <span v-if="!delinquentOrChargeoff"
                        >Make your upcoming minimum payment early. This payment
                        must be scheduled during your current billing
                        cycle.</span
                      >
                      <span v-else
                        >Pay your past-due amount and make your account current
                        with this option. This payment must be scheduled during
                        your current billing cycle.</span
                      >
                    </div>
                  </div>
                  <div class="ttipFull" :class="chargeOff ? 'twoOnly' : null">
                    <div
                      v-if="selectedAmount === 'full'"
                      id="fullamtdescription"
                    >
                      <span class="icon">
                        <i class="fas fa-chevron-up" />
                      </span>
                      <span v-if="returnItem">
                        Pay your current balance with this option. This payment
                        must be scheduled during your current billing
                        cycle.</span
                      >
                      <span v-else-if="chargeOff">
                        Pay your current balance with this option.</span
                      >
                      <span v-else
                        >Pay off your current balance with this option. This
                        payment must be scheduled during your current billing
                        cycle.</span
                      >
                    </div>
                  </div>
                  <div class="ttipOther" :class="chargeOff ? 'twoOnly' : null">
                    <div
                      v-if="selectedAmount === 'other'"
                      id="otheramtdescription"
                    >
                      <span class="icon">
                        <i class="fas fa-chevron-up" />
                      </span>
                      <span v-if="returnItem"
                        >Pay {{ currencyShort(minPaymentAmount) }} or more.
                        Schedule this payment up to 45 days in advance. Payments
                        are applied first to any past-due amounts, then to the
                        next minimum due on the billing cycle when they occur.
                      </span>
                      <span v-else-if="chargeOff"
                        >Pay {{ currencyShort(minPaymentAmount) }} or more, up
                        to your account balance. Schedule this payment up to 45
                        days in advance.</span
                      >
                      <span v-else
                        >Pay {{ currencyShort(minPaymentAmount) }} or more.
                        Schedule this payment up to 45 days in advance. Payments
                        are applied first to any past-due amounts, then to the
                        next minimum due on the billing cycle when they occur.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="buttons">
              <button @click="resetPayment" class="button-cancel">
                CANCEL
              </button>
              <button
                ref="btnamtcontinue"
                class="button-confirm"
                :aria-disabled="checkSelectedAmount()"
                :class="checkSelectedAmount() ? 'disabled' : null"
                @click="
                  checkSelectedAmount()
                    ? showAmountError(
                        selectedAmount,
                        otherAmount,
                        minPaymentAmount
                      )
                    : openFolder('date')
                "
              >
                CONTINUE
              </button>
            </div>
            <!-- <div class="subnote conditional">
              <span v-if="selectedAmount == null">The minimum payment is {{ minPaymentAmount | currencyShort }}.</span>
              <span v-else-if="selectedAmount ===  'minimum' && !delinquentOrChargeoff">This option enables you to pay your upcoming minimum payment early, before the due date, which may help you save on fees.  If you don't schedule this payment, it will automatically be withdrawn from your bank account on the due date.</span>
              <span v-else-if="selectedAmount ===  'minimum'">This option enables you to pay off all past-due amounts<br />and make your account current.</span>
              <span v-else-if="selectedAmount === 'other' && returnItem">This option allows you to make a one-time payment of {{ minPaymentAmount | currencyShort }} or more, up to your account balance. This payment will be applied first to your past-due amount. If it is more than your past-due amount, the excess will be applied to your upcoming minimum payment due.</span>
              <span v-else-if="selectedAmount === 'other' && chargeOff">The minimum payment is {{ minPaymentAmount | currencyShort }}. Please enter any amount between {{ minPaymentAmount | currencyShort }} and your outstanding balance.</span>
              <span v-else-if="selectedAmount === 'other'">This option allows you to make a one-time payment of {{ minPaymentAmount | currencyShort }} or more, up to your account balance. This payment will be applied to your upcoming minimum payment, lowering that payment amount. If the amount you enter exceeds your upcoming minimum payment, the extra goes to pay down your principal.</span>
              <span v-else-if="selectedAmount === 'full'">This option allows you to pay the balance that's currently showing on your account. Please be aware that this may not be the full payoff amount. You may have incurred fees that have not yet been applied to your account, but will show up in your next billing statement.</span>
            </div> -->
          </div>
          <div class="folder collapsed" v-else>
            <fieldset>
              <legend class="visually-hidden">2. Amount</legend>
              <div class="label clickable">
                <label class="lblheader" id="lblamountexpand">
                  2. Amount
                  <div class="selection" v-if="paymentAmount">
                    <span
                      v-if="
                        selectedAmount === 'minimum' && !delinquentOrChargeoff
                      "
                      >Next Minimum Due
                    </span>
                    <span v-else-if="selectedAmount === 'minimum'"
                      >Past Due
                    </span>
                    <span v-else-if="selectedAmount === 'other'"
                      >Other Amount
                    </span>
                    <span v-else-if="selectedAmount === 'full'"
                      >Pay Off Balance
                    </span>
                    <span class="orange">{{
                      currencyLong(paymentAmount)
                    }}</span>
                  </div></label
                >
                <div class="icon">
                  <button
                    id="btnamountexpand"
                    class="btnarrow"
                    :tabindex="maxPendingTranreachedOrZeroBalance ? -1 : 0"
                    aria-expanded="false"
                    aria-labelledby="lblamountexpand"
                    @click="focustabelements($event, 'amount')"
                    @keydown.space="focustabelements($event, 'amount')"
                  >
                    <i class="fas fa-chevron-right" />
                  </button>
                </div>
              </div>
            </fieldset>
          </div>

          <div
            class="folder"
            v-if="
              isPaymentEntered
                ? activeFolder === 'date'
                : activeFolder === 'null'
            "
            :disabled="!isPaymentEntered"
          >
            <div class="label">
              <label for="selecteddate"
                >3. Date
                <span
                  style="
                    padding-left: 5px;
                    font-size: 12px;
                    padding-top: 3px;
                    font-weight: 300;
                  "
                >
                  mm/dd/yyyy
                </span>
              </label>
              <div class="selection" :class="{ selectedDate: paymentDate }">
                <input
                  id="selecteddate"
                  :value="paymentDate"
                  :class="{ selectedDate: paymentDate }"
                  @click="activeFolder === 'date' ? toggleCalendar() : null"
                  class="dateInput"
                  disabled
                  name="payment date"
                />
              </div>
              <div class="icon">
                <button
                  tabindex="0"
                  class="btnarrow"
                  @click="focustabelements($event, null)"
                  @keypress.space="focustabelements($event, null)"
                  aria-label="Collapse date section"
                >
                  <i class="fas fa-chevron-down" />
                </button>
              </div>
            </div>
            <div class="date">
              <div class="enter-date subnote" role="alert">
                <!--Select any banking business<br />day between {{ stringToDateString(this.paymentEffectiveDate) }} and<br />{{ stringToDateString(this.maxACHBizDate) }}.-->
                <span
                  v-if="
                    returnItem &&
                    (selectedAmount === 'full' || selectedAmount === 'minimum')
                  "
                  >This payment must be scheduled during your current billing
                  cycle. Select any banking business day between
                  {{ stringToDateString(this.paymentEffectiveDate) }} and
                  {{ stringToDateString(nextPaymentDate) }}.</span
                >
                <span
                  v-else-if="
                    (selectedAmount === 'other' || selectedAmount === 'full') &&
                    (chargeOff || returnItem)
                  "
                  >This payment can be scheduled up to 45 days in advance.
                  Select any banking business day between
                  {{ stringToDateString(this.paymentEffectiveDate) }} and
                  {{ stringToDateString(maxPmtDays) }} .</span
                >
                <span
                  v-else-if="
                    !delinquentOrChargeoff && selectedAmount === 'other'
                  "
                  >This payment can be scheduled up to 45 days in advance.
                  Select any banking business day between
                  {{ stringToDateString(this.paymentEffectiveDate) }} and
                  {{ stringToDateString(maxPmtDays) }}.</span
                >
                <span
                  v-else-if="
                    !delinquentOrChargeoff &&
                    (selectedAmount === 'full' || selectedAmount === 'minimum')
                  "
                  >This payment must be scheduled during your current billing
                  cycle. Select any banking business day between
                  {{ stringToDateString(this.paymentEffectiveDate) }} and
                  {{ stringToDateString(nextPaymentDate) }}.</span
                >
              </div>
              <span>
                <b-calendar
                  id="dtpEffDate"
                  ref="datepicker"
                  @context="onContext"
                  aria-controls="selecteddate"
                  class="inputgroupdatewrapper dtp"
                  locale="en"
                  :hide-header="true"
                  :date-disabled-fn="
                    !chargeOff
                      ? oneTimePaymentDisabledDates.customPredictor
                      : acsOneTimePaymentDisabledDates.customPredictor
                  "
                  :min="
                    !chargeOff
                      ? oneTimePaymentDisabledDatesUpdate.to
                      : acsOneTimePaymentDisabledDatesUpdate.to
                  "
                  :max="
                    !chargeOff
                      ? oneTimePaymentDisabledDatesUpdate.from
                      : acsOneTimePaymentDisabledDatesUpdate.from
                  "
                ></b-calendar>
              </span>
              <span v-if="!chargeOff">
                <!-- <DatePicker
                  id="dtpEffDate"
                  name="payment date"
                  v-model="paymentDate"
                  format="MM/DD/YYYY"
                  placeholder="mm/dd/yyyy"
                  wrapper-class="payment-picker-wrapper"
                  calendar-class="payment-picker"
                  :disabled-dates="oneTimePaymentDisabledDatesUpdate"
                  :open-date="null"
                  maximum-view="day"
                  :full-month-name="true"
                  :inline="true"
                  v-validate="{ required: true }"
                /> -->
              </span>
              <span v-if="chargeOff">
                <!-- <DatePicker
                  id="dtpEffDate"
                  name="payment date"
                  v-model="paymentDate"
                  format="MM/DD/YYYY"
                  placeholder="mm/dd/yyyy"
                  wrapper-class="payment-picker-wrapper"
                  calendar-class="payment-picker"
                  :disabled-dates="acsOneTimePaymentDisabledDatesUpdate"
                  :open-date="null"
                  maximum-view="day"
                  :full-month-name="true"
                  :inline="true"
                  v-validate="{ required: true }"
                /> -->
              </span>
            </div>
            <div class="buttons">
              <button @click="resetPayment" class="button-cancel">
                CANCEL
              </button>
              <button
                class="button-confirm"
                @click="isSelectedDateValid(true)"
                :class="
                  !isFormComplete || !isSelectedDateValid(false)
                    ? 'disabled'
                    : null
                "
              >
                CONTINUE
              </button>
            </div>
          </div>
          <div class="folder collapsed" v-else :disabled="!isPaymentEntered">
            <fieldset>
              <legend class="visually-hidden">3. Date</legend>
              <div class="label clickable">
                <label class="lblheader" id="lbldateexpand">
                  3. Date
                  <div class="selection" v-if="paymentDate">
                    {{ date(paymentDate) }}
                  </div></label
                >
                <div class="icon">
                  <button
                    id="btndateexpand"
                    class="btnarrow"
                    :tabindex="maxPendingTranreachedOrZeroBalance ? -1 : 0"
                    aria-expanded="false"
                    aria-labelledby="lbldateexpand"
                    @click="focustabelements($event, 'date')"
                    @keydown.space="focustabelements($event, 'date')"
                    :disabled="!isPaymentEntered"
                  >
                    <i class="fas fa-chevron-right" />
                  </button>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        <div
          class="submitted"
          v-if="
            paymentSubmitted &&
            !paymentMade &&
            paymentAmount > 0 &&
            !paymentError
          "
        >
          <div class="summary" tabindex="0">
            <div class="summed">
              <span>Pay from</span>
              <span class="value"
                >{{ "EFT " + accountType + " Acct "
                }}{{ accountLastFour }}</span
              >
            </div>
            <div class="summed">
              <span>Date</span>
              <span class="value">{{ paymentDate }}</span>
            </div>
            <div class="summed">
              <span>Amount</span>
              <span class="value"
                ><span
                  v-if="selectedAmount === 'minimum' && !delinquentOrChargeoff"
                  >Minimum Due </span
                ><span v-else-if="selectedAmount === 'minimum'">Past Due </span
                ><span v-else-if="selectedAmount === 'other'">Other </span
                ><span v-else-if="selectedAmount === 'full'">Balance </span>
                <span style="padding-left: 5px"></span
                >{{ currencyLong(paymentAmount) }}</span
              >
            </div>
          </div>
          <div class="authCheck" v-if="delinquentOrChargeoff">
            <div class="authAgreementLeft">
              <label id="lblpaymentauth" for="authchk">
                <span class="first"
                  >I authorize
                  <span id="xact" style="font-style: normal">Xact</span>
                  <span id="flex">
                    <i>Flex</i>
                  </span>
                  <sup
                    style="font-size: 3px; margin-left: 1px; font-weight: 500"
                    ><span class="trademark">&reg;</span></sup
                  >
                  to make the above EFT payment using my checking account.</span
                ></label
              >
            </div>
            <div class="authAgreementRight">
              <label
                class="input-checkbox-wrapper"
                :class="chargeOff ? 'chargeoff' : null"
              >
                <input
                  type="checkbox"
                  id="chkAuthorized"
                  :checked="authChecked"
                  @change="chkAuthorized_change()"
                  tabindex="-1"
                />
                <span
                  id="authchk"
                  class="input-checkbox"
                  tabindex="0"
                  aria-labelledby="lblpaymentauth"
                  role="checkbox"
                  :class="{ 'input-checkbox-selected': authChecked }"
                  @keydown.space="chkAuthorized_change;"
                />
              </label>
            </div>
          </div>
          <div class="buttons">
            <button @click="backToFolders" class="button-cancel submitSect">
              CANCEL
            </button>
            <button
              class="button-confirm submitSect"
              :class="
                !delinquentOrChargeoff || paymentAuthorized ? null : 'disabled'
              "
              @click="
                !delinquentOrChargeoff || paymentAuthorized
                  ? confirmPayment()
                  : null
              "
              :aria-disabled="!(!delinquentOrChargeoff || paymentAuthorized)"
            >
              SUBMIT PAYMENT
            </button>
          </div>
          <div class="subnote" v-if="delinquentOrChargeoff">
            You may revoke this authorization by contacting us at least 3 days
            prior to your payment date either in writing at
            {{ companyInfo.address_dec }}, {{ companyInfo.address_dec2 }}, or by
            phone at
            <a
              v-if="!delinquentOrChargeoff"
              :href="'tel:' + companyInfo.contactPhone"
              class="no-border de-border"
              >{{ formatPhoneNumber(companyInfo.contactPhone) }}</a
            ><a
              v-else
              :href="'tel:' + companyInfo.advancedCustomerServicePhone"
              class="no-border de-border"
              >{{
                formatPhoneNumber(companyInfo.advancedCustomerServicePhone)
              }}</a
            >.
          </div>
          <div
            class="modal"
            v-if="showModal && delinquentOrChargeoff"
            aria-modal="true"
            role="alertdialog"
            aria-describedby="doctitle"
          >
            <div class="first">
              I understand that this authorization will remain in effect until I
              cancel it. I agree to allow
              <span style="white-space: nowrap" class="XACT-font"
                ><span id="xact" style="font-style: normal">Xact</span
                ><span id="flex"><i>Flex</i></span
                ><span
                  style="
                    font-size: 8px;
                    vertical-align: middle;
                    font-weight: 500;
                  "
                  >&trade;</span
                ></span
              >
              up to three (3) days to process a request to cancel this
              authorization and understand a request submitted fewer than three
              days in advance of a payment due date may not prevent that payment
              from being processed. I agree to notify
              <span style="white-space: nowrap" class="XACT-font"
                ><span id="xact" style="font-style: normal">Xact</span
                ><span id="flex"><i>Flex</i></span
                ><span
                  style="
                    font-size: 8px;
                    vertical-align: middle;
                    font-weight: 500;
                  "
                  >&trade;</span
                ></span
              >
              in writing of any changes in my account information at least 15
              days prior to the next billing date. If the above noted payment
              dates fall on a weekend or holiday, I understand that the payments
              may be executed on the next business day. For debits to my
              checking/savings account, I understand that because these are
              electronic transactions, these funds may be withdrawn from my
              account as soon as the noted transaction dates. I acknowledge that
              the origination of debit transactions to my account must comply
              with the provisions of U.S. law. I certify that I am an authorized
              user of this bank account and will not dispute these scheduled
              transactions with my bank; so long as the transactions correspond
              to the terms indicated in this authorization form. <br /><br />
              A receipt for this payment will be provided to you and the charge
              will appear on your bank statement. You have the right to receive
              notice of all debits that vary in amount.
              <div class="buttons">
                <button
                  id="btnCanceldelinquentOrChargeoff"
                  @click="btnCanceldelinquentOrChargeoff_click"
                  class="button-cancel"
                  @keydown.shift.tab="PreviousElementFromChargeoffPopup($event)"
                >
                  CANCEL
                </button>
                <button
                  id="btnAgreedelinquentOrChargeoff"
                  class="button-confirm"
                  @click="btnAgreedelinquentOrChargeoff_click"
                  @keydown.tab="NextElementFromChargeoffPopup($event)"
                >
                  AGREE
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="submitted error" v-if="paymentError" role="alert">
          <div class="clsh2 delinquent">We're sorry.</div>
          <span class="note"
            >An error occurred and we were unable to process your payment.<br />Please
            try again later or contact an Account Specialist at
            <a
              v-if="!delinquentOrChargeoff"
              :href="'tel:' + companyInfo.contactPhone"
              class="no-border de-border"
              >{{ formatPhoneNumber(companyInfo.contactPhone) }}</a
            ><a
              v-else
              :href="'tel:' + companyInfo.advancedCustomerServicePhone"
              class="no-border de-border"
              >{{
                formatPhoneNumber(companyInfo.advancedCustomerServicePhone)
              }}</a
            >.</span
          >
        </div>

        <!-- Display when an error occurs -->
        <div class="error" v-if="errorMsg" role="alert">
          {{ errorMsg }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from "@/components/global/SectionTitle";
import { minimumOneTimePayment } from "@/config/amounts";
import companyInfo from "@/config/text/company";
import { currencyShort, currencyLong } from "@/utils/filters/currency";
import { dateStringLong, date } from "@/utils/filters/dates";

//import DatePicker from "vuejs-datepicker";
import dayjs from "dayjs";
import moment from "moment";
import {
  oneTimePaymentDisabledDates,
  oneTimePaymentStartDate,
  acsOneTimePaymentDisabledDates,
} from "@/config/dates";
//import bus from "@/main.js";
import { inject } from "vue";
import "../../styles/datepicker_bootstrap.scss";
import "../../styles/datepicker_bootstrapvue.scss";

export default {
  name: "PortalMakePayment",
  components: { SectionTitle },
  computed: {
    oneTimePaymentDisabledDatesUpdate: function () {
      oneTimePaymentDisabledDates["from"] = dayjs(
        this.nextPaymentDate
      ).toDate();
      if (
        this.selectedAmount === "other" ||
        (this.chargeOff && this.selectedAmount === "full")
      ) {
        oneTimePaymentDisabledDates["from"] = dayjs(this.maxPmtDays).toDate();
      }
      oneTimePaymentDisabledDates["to"] = dayjs(
        this.paymentEffectiveDate
      ).toDate();
      return oneTimePaymentDisabledDates;
    },
    acsOneTimePaymentDisabledDatesUpdate: function () {
      acsOneTimePaymentDisabledDates["from"] = dayjs(
        this.nextPaymentDate
      ).toDate();
      if (
        this.selectedAmount === "other" ||
        (this.chargeOff && this.selectedAmount === "full")
      ) {
        acsOneTimePaymentDisabledDates["from"] = dayjs(
          this.maxPmtDays
        ).toDate();
      }
      acsOneTimePaymentDisabledDates["to"] = dayjs(
        this.paymentEffectiveDate
      ).toDate();
      return acsOneTimePaymentDisabledDates;
    },
    isFormComplete: function () {
      return this.paymentAmount && this.paymentDate;
    },
    isPaymentEntered: function () {
      //alert(this.paymentAmount>0);
      return this.paymentAmount > 0;
    },
    minPaymentAmount: {
      get() {
        return this.$store.state.account.minAchTransactionAmount;
      },
    },
    accountBalance: {
      get() {
        return this.$store.state.account.accountBalance;
      },
    },
    selectedAmount: {
      get() {
        if (this.$store.state.portal.selectedAmount == null) {
          if (this.chargeOff) {
            if (this.paymentEffectiveDate <= this.nextPaymentDate) {
              this.selectAmount("full");
              return "full";
            } else {
              this.selectAmount("other");
              return "other";
            }
          } else if (
            this.minimumPaymentDue > 0 &&
            this.paymentEffectiveDate <= this.nextPaymentDate
          ) {
            this.selectAmount("minimum");
            return "minimum";
          } else if (this.paymentEffectiveDate <= this.nextPaymentDate) {
            this.selectAmount("full");
            return "full";
          } else {
            this.selectAmount("other");
            return "other";
          }
        } else {
          return this.$store.state.portal.selectedAmount;
        }
      },
      set(value) {
        this.$store.commit("updateSelectedAmount", value);
      },
    },
    otherAmount: {
      get() {
        return this.$store.state.portal.otherAmount
          ? this.$store.state.portal.otherAmount
          : "0.00";
      },
      set(value) {
        this.$store.commit("updateOtherAmount", value);
      },
    },
    paymentAmount: {
      get() {
        //alert("paymentamount: "+ this.$store.state.portal.paymentAmount)
        return this.$store.state.portal.paymentAmount;
      },
      set(value) {
        this.$store.commit("updatePaymentAmount", value);
      },
    },
    paymentDate: {
      get() {
        if (
          this.$store.state.portal.paymentDate != null &&
          this.$store.state.portal.paymentDate.toString().includes("-") &&
          this.$store.state.portal.paymentDate.length == 10
        ) {
          return moment(
            String(this.$store.state.portal.paymentDate),
            "YYYY-MM-DD"
          ).format("MM/DD/YYYY");
        }
        return this.$store.state.portal.paymentDate;
      },
      set(value) {        
        if (value.toString().length >= 10) {
          value = moment(
            String(value),
            "YYYY-MM-DD"
          ).format("MM/DD/YYYY");
        }        
        this.$store.commit("updatePaymentDate", value);
      },
    },
    paymentDatenewformat: {
      get() {
        if (String(this.paymentDate).length == 10) {
          var val = moment(String(this.paymentDate), "MM/DD/YYYY").format(
            "YYYY-MM-DD"
          );
          return val;
        } else {
          return this.paymentDate;
        }
      },
      set(value) {
        this.paymentDate = value;
      },
    },
    paymentMade: {
      get() {
        return this.$store.state.portal.paymentMade;
      },
      set(value) {
        this.$store.commit("updatePaymentMade", value);
      },
    },
    nextPayment: {
      get() {
        return this.$store.state.account.nextPayment;
      },
    },
    nextPaymentDate: {
      get() {
        let nxtPmtDate = this.$store.state.account.nextPaymentDate;
        return dayjs(nxtPmtDate).format("YYYY-MM-DD");
      },
    },
    accountLastFour: {
      get() {
        return "#" + this.$store.state.account.accountLastFour;
      },
    },
    accountType: {
      get() {
        return this.$store.state.account.accountType;
      },
    },
    minimumPaymentDue: {
      get() {
        return this.$store.state.account.minimumPaymentDue;
      },
    },
    pastDue: {
      get() {
        return this.$store.state.account.pastDue;
      },
    },
    currentDue: {
      get() {
        return this.$store.state.account.currentDue;
      },
    },
    email: {
      get() {
        return this.$store.state.account.email;
      },
    },
    delinquentOrChargeoff: {
      get() {
        return (
          this.$store.state.account.delinquent ||
          this.$store.state.account.chargeoff
        );
      },
    },
    delinquent: {
      get() {
        return this.$store.state.account.delinquent;
      },
    },
    chargeOff: {
      get() {
        return this.$store.state.account.chargeoff;
      },
    },
    returnItem: {
      get() {
        return this.$store.state.account.delinquent;
      },
    },
    paymentEffectiveDate: {
      get() {
        let effdate = this.$store.state.account.achEffectiveDate;
        return dayjs(effdate).format("YYYY-MM-DD");
      },
    },
    maxACHBizDate: {
      get() {
        return dayjs(this.$store.state.account.maxPaymentDate).format(
          "YYYY-MM-DD"
        );
      },
    },
    maxPmtDays: {
      get() {
        const today = new Date();
        //currentDate = dayjs(today).format('YYYY-MM-DDT00:00:00');
        return dayjs(today).add(45, "day").format("YYYY-MM-DD");
      },
    },
    maxPendingTranreachedOrZeroBalance: {
      get() {
        if (
          this.$store.state.account.maxPendingTranreached ||
          this.$store.state.account.accountBalance == 0
        )
          return true;
        else return false;
      },
    },
  },

  mounted() {
    this.focusfirstelementOnPagewhenlanded();

    this.activeFolder = this.accountBalance > 0 ? "amount" : "";
    // bus.$on("pAccountSumEmit", (val) => {
    //   if (val != undefined) {
    //     this.activeFolder = val;
    //     this.paymentSubmitted = false;
    //   }
    // });
    const emitter = inject("emitter");
    emitter.on("pAccountSumEmit", (val) => {
      if (val != undefined) {
        this.activeFolder = val;
        this.paymentSubmitted = false;
      }
    });
  },

  created() {
    this.paymentSubmitted = this.paymentMade;
  },

  data() {
    return {
      companyInfo: companyInfo,
      minimumOneTimePayment: minimumOneTimePayment,
      activeFolder: "amount",
      paymentSubmitted: false,
      paymentError: false,
      paymentAuthorized: false,
      showModal: false,
      authChecked: false,
      paymentTransactionId: "",
      oneTimePaymentDisabledDates: oneTimePaymentDisabledDates,
      oneTimePaymentStartDate: oneTimePaymentStartDate,
      acsOneTimePaymentDisabledDates: acsOneTimePaymentDisabledDates,
      errorMsg: null,
      showMoreInfo: false,
      todayDate: "",
      currencyShort: currencyShort,
      currencyLong: currencyLong,
      dateStringLong: dateStringLong,
      date: date,
    };
  },

  methods: {
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      //this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      //this.selected = ctx.selectedYMD;
      if (
        ctx.selectedYMD != null &&
        ctx.selectedYMD != "" &&
        ctx.selectedYMD != "2001-01-01" &&
        ctx.selectedYMD.length == 10 &&
        ctx.selectedYMD !=this.paymentDatenewformat
      ) {
        this.paymentDatenewformat = ctx.selectedYMD;
      }
    },
    focusfirstelementOnPagewhenlanded() {
      document.getElementById("headerAccountPayment").focus();
    },
    focuselement(event) {
      event.target.select();
      this.selectedAmount = "other";
    },
    isRequired(value) {
      if (value && value.trim()) {
        return true;
      }
      return "This is required";
    },
    focustabelements(e, tabval) {
      var val = this.activeFolder;
      if (this.accountBalance > 0) {
        this.openFolder(tabval);
      }
      if (tabval === "method") {
        setTimeout(() => {
          var srceftlabel = document.getElementById("eftlabel");
          if (srceftlabel != null) srceftlabel.focus();
        }, 300);
      } else if (tabval === "amount") {
        if (
          (!this.chargeOff &&
            this.paymentEffectiveDate > this.nextPaymentDate) ||
          this.returnItem
        ) {
          setTimeout(() => {
            var srcmindue = document.getElementById("minduesec");
            if (srcmindue != null) srcmindue.focus();
          }, 300);
        } else {
          setTimeout(() => {
            if (this.selectedAmount === "other") {
              this.$refs.otherPaymentAmount.focus();
            } else {
              var srcfullsec = document.getElementById("fullsec");
              if (srcfullsec != null) srcfullsec.focus();
            }
          }, 300);
        }
      }
      if (val != null && tabval != null) {
        val = tabval;
      }
      if (val === "method") {
        setTimeout(() => {
          var srcmethod = document.getElementById("btnmethodexpand");
          if (srcmethod != null) srcmethod.focus();
        }, 300);
      }
      if (val === "amount") {
        setTimeout(() => {
          var srcamount = document.getElementById("btnamountexpand");
          if (srcamount != null) srcamount.focus();
        }, 300);
      }
      if (val === "date") {
        setTimeout(() => {
          var srcdate = document.getElementById("btndateexpand");
          if (srcdate != null) srcdate.focus();
        }, 300);
      }

      e.preventDefault();
      return false;
    },
    focusamtbutton() {
      this.$refs.btnamtcontinue.focus();
    },
    openFolder(folder) {
      this.errorMsg = null;
      this.paymentSubmitted = false;
      if (folder == "date") {
        if (this.paymentAmount > 0) {
          this.activeFolder = folder;
          this.$nextTick(() => {
            this.$refs.datepicker.focus();
          });
        } else {
          this.errorMsg = "Please select a payment amount option";
          this.activeFolder = "amount";
        }
      } else {
        this.activeFolder = folder;
      }
    },
    closeMakePayment() {
      this.resetPayment();
      this.$router.push({ name: "portal-account" });
    },
    handleOtherAmount(value) {
      // console.log('currency: ', value);
      if (value != "" && value <= this.accountBalance) {
        this.otherAmount = this.formatCurrencySpecial(value);
        this.paymentAmount = this.otherAmount;
      }
    },
    formatPhoneNumber(number) {
      //JO: Using method to format, instead of filter, since filters are going away in next version of VueJS
      return number.replace("-", ".");
    },
    formatCurrencySpecial(value) {
      return parseFloat(value).toFixed(2);
    },
    isNumber(evt) {
      const keysAllowed = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        ".",
      ];
      const keyPressed = evt.key;

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    },
    selectAmount(amount) {
      // console.log("selectedAmount fired: ", amount);
      /* alert(amount);
      alert(this.otherAmount);*/
      this.errorMsg = null;
      this.selectedAmount = amount;
      this.paymentAmount =
        amount === "other"
          ? this.otherAmount
          : amount === "full"
          ? this.accountBalance
          : this.delinquentOrChargeoff
          ? this.pastDue
          : this.minimumPaymentDue;
      if (amount != "other") this.otherAmount = 0;
      if (amount == "other") {
        this.$nextTick(() => {
          this.$refs.otherPaymentAmount.focus();
        });
      }
    },
    backToFolders() {
      this.errorMsg = null;
      this.activeFolder = "date";
      this.paymentSubmitted = false;
      this.paymentAuthorized = false;
      this.showModal = false;
      this.authChecked = false;
    },
    resetPayment() {
      this.errorMsg = null;
      this.activeFolder = "amount";
      this.paymentError = false;
      this.paymentAuthorized = false;
      this.showModal = false;
      this.authChecked = false;
      this.paymentTransactionId = "";
      this.paymentSubmitted = false;

      this.$store.commit("updatePaymentAmount", null);
      this.$store.commit("updatePaymentDate", null);
      this.$store.commit("updateSelectedAmount", null);
      this.$store.commit("updateOtherAmount", 0);
    },
    submitPayment() {
      if (
        this.accountBalance != undefined &&
        this.paymentAmount > this.accountBalance
      ) {
        this.errorMsg =
          "Your payment cannot exceed your $" +
          parseFloat(this.accountBalance).toFixed(2) +
          " account balance. Please enter a different amount.";
      } else {
        this.activeFolder = null;
        this.paymentSubmitted = true;
        setTimeout(() => {
          document.getElementById("paymentreview").focus();
        }, 200);
      }
    },
    isSelectedDateValid(submit) {
      let validDate = this.nextPaymentDate;
      if (
        this.selectedAmount === "other" ||
        (this.chargeOff && this.selectedAmount === "full")
      )
        validDate = this.maxPmtDays;

      if (
        dayjs(this.paymentDate).format("YYYY-MM-DD") >=
          dayjs(this.paymentEffectiveDate).format("YYYY-MM-DD") &&
        dayjs(this.paymentDate).format("YYYY-MM-DD") <=
          dayjs(validDate).format("YYYY-MM-DD")
      ) {
        if (submit) {
          document.documentElement.scrollTop = 0;
          this.submitPayment();
        }
        return true;
      }
      return false;
    },
    updatePostDate(event) {
      this.paymentDate = event.target.value;
    },
    allowedDates(val) {
      for (var i = 0; i < this.arrayDates.length; i++) {
        if (this.arrayDates[i] == val) {
          return val;
        }
      }
    },
    //Post Payment
    async confirmPayment() {
      var isValidPmt = false;

      if (this.paymentAmount > 0 && this.paymentDate != null) {
        let pmtDate = dayjs(this.paymentDate).format("YYYY-MM-DD");
        let achEffDate = dayjs(this.paymentEffectiveDate).format("YYYY-MM-DD");
        let maxachdate = dayjs(this.nextPaymentDate).format("YYYY-MM-DD");
        if (
          this.selectedAmount === "other" ||
          (this.chargeOff && this.selectedAmount === "full")
        ) {
          maxachdate = dayjs(this.maxPmtDays).format("YYYY-MM-DD");
        }

        /* console.log("pmtDate : "+ pmtDate.format('YYYY-MM-DD') + " achEffDate: " + achEffDate.format('YYYY-MM-DD') + " maxachdate: " +maxachdate.format('YYYY-MM-DD'))
      //   console.log("pmtDate.isBetween(achEffDate, maxachdate) "+pmtDate.isBetween(achEffDate, maxachdate))*/

        if (pmtDate >= achEffDate && pmtDate <= maxachdate) {
          isValidPmt = true;
          // console.log("valid pmt dt");
        } else {
          isValidPmt = false;
        }

        // console.log("isValidPmt : "+isValidPmt)

        if (isValidPmt == false) {
          this.errorMsg =
            "Invalid date. Your payment date must be between " +
            dayjs(this.paymentEffectiveDate).format("MM/DD/YYYY") +
            " and " +
            maxachdate +
            ".";
          isValidPmt = false;
        } else if (
          this.paymentAmount < this.$store.state.account.minAchTransactionAmount
        ) {
          this.errorMsg =
            "The minimum payment is $10. Please enter $10 or more.";
          isValidPmt = false;
        } else if (
          this.accountBalance != undefined &&
          this.paymentAmount > this.accountBalance
        ) {
          this.errorMsg =
            "Your payment cannot exceed your $" +
            parseFloat(this.accountBalance).toFixed(2) +
            " account balance. Please enter a different amount.";
          isValidPmt = false;
        } else isValidPmt = true;
        //  console.log("isValidPmt : " + isValidPmt);

        if (isValidPmt == true) {
          //  console.log(
          //   "Post Transaction :" + this.paymentAmount + " " + this.paymentDate
          // );
          const res = await this.$store.dispatch("postPayment", {
            tranAmount: this.paymentAmount,
            postDate: this.paymentDate,
          });
          if (res != null && res.paymentTransactionId > 0) {
            //  console.log("pmt trans id: " + res.paymentTransactionId);
            this.paymentTransactionId = res.paymentTransactionId;
            await this.$store.dispatch("getAccountSummaryInfo");
            this.$store.state.portal.documentsTableData =
              await this.$store.dispatch("getDocumentsTableData");
            this.paymentMade = true;
            this.errorMsg = "";
            setTimeout(() => {
              document.getElementById("thanksmsg").focus();
            }, 200);
          } else if (res != null && res.paymentTransactionId == 0) {
            this.paymentMade = false;
            this.paymentError = true;
            // console.log("Message from api : " + res.statusDescription);
          }
        }
      } else {
        //it should never come here as continue button should be disabled
        this.paymentMade = false;
        this.errorMsg = "Invalid Payment Amount or Payment Date.";
      }
    },
    finishPayment() {
      this.paymentMade = false;
      this.resetPayment();
    },
    checkSelectedAmount() {
      return (
        this.selectedAmount == null ||
        (this.selectedAmount === "other" &&
          (this.otherAmount === 0 ||
            this.otherAmount == null ||
            this.otherAmount < this.minPaymentAmount ||
            this.otherAmount > this.accountBalance))
      );
    },
    showAmountError(selectedAmount, otherAmount, minPaymentAmount) {
      this.errorMsg = null;
      if (selectedAmount === "other") {
        var formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        });

        var minAmtShown = formatter.format(minPaymentAmount); /* $2,500.00 */
        var maxAmtShown = formatter.format(this.accountBalance); /* $2,500.00 */

        if (
          otherAmount === 0 ||
          otherAmount == null ||
          otherAmount < minPaymentAmount
        ) {
          this.errorMsg =
            "The minimum payment is " +
            minAmtShown.substring(0, minAmtShown.length - 3) +
            ". Please enter " +
            minAmtShown.substring(0, minAmtShown.length - 3) +
            " or more.";
        } else if (otherAmount > this.accountBalance) {
          this.errorMsg =
            "Your payment cannot exceed your " +
            maxAmtShown.substring(0, maxAmtShown.length - 3) +
            " account balance.  Please enter a different amount.";
        }
      }
    },
    toggleCalendar() {
      //this.$refs.programaticOpen.showCalendar();
    },
    stringToDateString(date) {
      var parts = date.split("-");
      return parts[1] + "/" + parts[2] + "/" + parts[0];
    },
    getCurrentDate: function () {
      const today = new Date();
      this.todayDate = dayjs(today).format("YYYY-MM-DDT00:00:00");
    },
    NextElementFromChargeoffPopup(event) {
      document.getElementById("btnCanceldelinquentOrChargeoff").focus();
      event.stopPropagation();
      event.preventDefault();
    },
    PreviousElementFromChargeoffPopup(event) {
      document.getElementById("btnAgreedelinquentOrChargeoff").focus();
      event.stopPropagation();
      event.preventDefault();
    },
    chkAuthorized_change() {
      this.authChecked = !this.authChecked;
      this.showModal = this.authChecked ? true : false;
      this.paymentAuthorized = this.authChecked
        ? this.paymentAuthorized
        : false;

      setTimeout(() => {
        document.getElementById("btnCanceldelinquentOrChargeoff").focus();
      }, 100);
    },
    btnAgreedelinquentOrChargeoff_click() {
      this.paymentAuthorized = true;
      this.showModal = false;
      document.getElementById("authchk").focus();
    },
    btnCanceldelinquentOrChargeoff_click() {
      this.paymentAuthorized = false;
      this.showModal = false;
      this.authChecked = false;
      document.getElementById("authchk").focus();
    },
  },
  beforeMount() {
    this.getCurrentDate();
  },
};
</script>

<style scoped lang="scss">
.selectedDate {
  color: $orange;
}
.make-payment {
  display: block;

  .centerdiv {
    vertical-align: middle;
  }

  #headerAccountPayment:focus-visible {
    outline: 0px solid #1e4388;
  }

  #btnCanceldelinquentOrChargeoff:focus {
    outline: 2px solid #1e4388;
  }

  .centerdiv .ind {
    right: -340px;
    position: relative;
    top: -35px;

    @media (max-width: 1470px) {
      right: -85%;
    }
    @media (max-width: 1400px) {
      right: -80%;
    }
    @media (max-width: 1430px) {
      right: -75%;
    }
    @media (max-width: 990px) {
      right: -70%;
    }
    @media (max-width: 960px) {
      right: -80%;
    }
    @media (max-width: 900px) {
      right: -70%;
    }
    @media (max-width: 768px) {
      right: -80%;
    }
    @media (max-width: 570px) {
      right: -70%;
    }
    @media (max-width: 359px) {
      right: -55%;
    }
  }
  .centerdiv .tooltip-container {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    div {
      max-width: 130px;
      font-size: xx-small;
      padding: 3px;
      margin-right: 10px;

      div {
        display: flex;
        flex-direction: column;
        .icon {
          font-size: 20px;
          color: $orange;
        }
        box-shadow: 3px 3px rgb(241, 240, 240);
        background-color: $absolute-white;
      }
    }

    div.ttipMin {
      position: relative;
      right: 20%;

      @media (max-width: 960px) {
        top: -0px;
      }
    }

    div.ttipFull {
      position: relative;
      left: 5%;

      @media (max-width: 960px) {
        top: -0px;
        left: 0%;
      }
    }

    div.ttipFull.twoOnly {
      position: relative;
      left: -10%;

      @media (max-width: 1122px) {
        top: -0px;
      }
      @media (max-width: 960px) {
        top: -0px;
        left: -15%;
      }
      @media (max-width: 886px) {
        top: -0px;
        left: -15%;
      }
      @media (max-width: 768px) {
        top: -0px;
        left: -10%;
      }
      @media (max-width: 594px) {
        top: -0px;
        left: -15%;
      }
    }

    div.ttipOther {
      position: relative;
      left: 30%;

      @media (max-width: 1470px) {
        left: 25%;
      }
      @media (max-width: 960px) {
        left: 30%;
      }
      @media (max-width: 820px) {
        left: 25%;
      }
    }

    div.ttipOther.twoOnly {
      position: relative;
      left: 15%;

      @media (max-width: 1470px) {
        left: 15%;
      }
      @media (max-width: 1122px) {
        left: 20%;
      }
      @media (max-width: 960px) {
        left: 20%;
      }
      @media (max-width: 886px) {
        left: 15%;
      }
      @media (max-width: 768px) {
        left: 15%;
      }
      @media (max-width: 594px) {
        left: 20%;
      }
    }

    justify-content: center;
  }

  .amount-wrapper {
    justify-content: center;
  }

  .centerdiv .amount-wrapper {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    .amount-select {
      max-width: 130px;
      padding: 15px 5px 0px 5px;
      height: 45px;
      // margin-left:10px;
      // margin-right:10px;
      margin: auto 5px;
      border: 1px solid $absolute-black;
      border-radius: 6px;
      display: inline-block;
      vertical-align: middle;
      padding-top: 15px;
      cursor: pointer;

      @media (max-width: 820px) {
        width: 87px;
        font-size: 13px;
      }

      @media (max-width: 768px) {
        width: 115px;
        margin-left: 5px;
        margin-right: 5px;
        margin-top: 5px;
      }

      @media (max-width: 540px) {
        width: 100%;
        font-size: 13px;
      }
      @media (max-width: 420px) {
        width: 86px;
        font-size: 12px;
      }

      .orange {
        font-size: 16px;
        font-weight: 600;
      }

      .regular-view {
        display: block;
        margin: auto 5px;
        @media (max-width: 1000px) {
          display: none;
        }
      }

      .mobile-view {
        display: none;
        margin: auto 5px;
        @media (max-width: 1000px) {
          display: block;
        }
      }

      .in-line {
        display: inline-flex;
      }
    }

    .amount-select.selected,
    .amount-select.selected .orange {
      background-color: $blue;
      color: $absolute-white;
      cursor: default;
    }

    input {
      width: 75px;
      border-bottom: 1px solid $gray;
      border-radius: 0px;
      text-align: right;
      padding-top: 2px;
      padding-bottom: 2px;
      color: $gray;

      @media (max-width: 820px) {
        width: 65%;
      }

      @media (max-width: 540px) {
        width: 70%;
      }
      @media (max-width: 420px) {
        width: 50px;
      }
    }
  }

  .close {
    order: 3;
    cursor: pointer;
  }

  .next {
    margin-right: 0px;
    padding: 6px;
    background: $gray;
    color: $absolute-white;
    font-family: $font-primary; //$font-secondary;
    font-size: 12px;
    text-align: center !important;
    box-shadow: inset 0 3px 3px 1px rgba(0, 0, 0, 0.2);
  }

  .payMessage {
    color: $orange !important;
  }
  .delinquent {
    color: $delinq-red !important;
    text-align: center !important;
    font-size: 14px !important;
  }
  .subDelinquent {
    font-size: 12px !important;
    color: $delinq-red !important;
  }

  .payment {
    display: block;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 0 !important;

    .top {
      margin-top: 10px;

      @media (max-width: 768px) {
        margin-top: -50px;
      }

      a.orange {
        border-bottom: 1px solid $orange;
      }

      h5 {
        font-size: 14px;
        font-weight: 600;
      }

      .next {
        background-color: $light-gray;
        color: $gray;
        box-shadow: none;
        text-align: left;
        font-size: 12px;
        font-weight: 600;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .section-title.one-time-section {
        font-size: 18px;
        margin-bottom: 20px;
      }

      .note {
        font-size: 12px;

        &.thanks {
          margin-top: 20px;
          font-size: 14px;
        }
      }

      .image-small {
        width: 15px;
        height: 15px;
      }

      a:link {
        text-decoration: none;
      }
    }

    .bottom.zeroBalance .folders .folder,
    .bottom.zeroBalance .folders .folder span {
      color: $gray !important;
      opacity: 40%;
    }

    .bottom.zeroBalance .folders .folder .label.clickable {
      cursor: default !important;
    }

    .bottom.zeroBalance .folders .folder .icon {
      color: $gray;
      cursor: default !important;
    }

    div.thanksmsg {
      @media (max-width: 767px) {
        top: 45px;
      }
    }
    .bottom {
      display: block;
      justify-content: space-between;
      align-items: flex-end;
      min-height: 260px;

      button {
        font-size: 16px;
        font-weight: 600;
        margin: 20px 30px 20px 8px;
        background-color: $border;
        color: $absolute-white;
        padding: 8px 16px;
      }

      .button-confirm {
        background: $orange;

        &:disabled {
          background: $border;
        }
      }

      .button-cancel {
        color: $gray;
        background: none;
        text-decoration: underline;
        font-size: 14px;
      }
    }

    .button-confirm.disabled {
      background: $gray;
      cursor: default;
    }

    .amount-select.disabled {
      background: $gray;
      cursor: default;
      color: $absolute-white;
    }

    .folders {
      width: 100%;

      .folder {
        margin-bottom: 15px;
        border-bottom: 1px solid $border;

        .label.clickable {
          cursor: pointer;
        }

        .label {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          font-family: $font-primary; //$font-secondary;
          font-size: 20px;
          color: $blue;
          font-weight: 600;
          margin-bottom: 15px;

          .selection {
            padding: 2px 6px;
            margin: 0 10px 0 auto;
            font-family: $font-primary;
            font-size: 18px;
            font-weight: 500;
            color: $gray;

            .value {
              font-size: 15px;
            }
          }

          .icon {
            color: $orange;
            font-size: 22px;
            cursor: pointer;
          }
        }

        .radio-input {
          display: flex;
          flex-flow: row nowrap;
          margin: 10px 0;
          vertical-align: middle;

          .radio-checkmark {
            margin-right: 6px;
          }

          .radio-label {
            font-size: 12px;
            display: inline-block;
            vertical-align: middle;
            line-height: normal;
          }

          .amount {
            width: 140px;
            margin: 0 0 0 auto;
            color: $black;
            font-family: $font-primary; //$font-secondary;
            font-size: 14px;
            display: inline-block;
            vertical-align: middle;
            line-height: normal;
          }

          input {
            width: 80px;
            padding: 0;
            color: $black;
            font-family: $font-primary; //$font-secondary;
            font-size: 14px;
            border: none;
            border-bottom: 1px solid $black;
          }
        }

        .date {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: flex-start;
          margin-top: 6px;

          .subnote {
            margin-left: 20px;
            margin-top: 0px;
          }
        }

        .dateInput {
          border: 1px solid $gray;
          text-align: right;
          width: 120px;
        }

        .dateInput:disabled {
          cursor: default;
        }

        .buttons {
          width: 100%;
          text-align: center;
        }

        .note,
        .subnote {
          font-size: 10px;
        }

        .subnote {
          padding-bottom: 10px;
        }

        .subnote.conditional {
          text-align: center;
          margin-top: 0px;
        }

        &.collapsed {
          .label {
            font-size: 20px;
            color: $blue;
            font-weight: 600;
          }
        }
      }
    }

    .submitted {
      font-size: 12px;
      margin-left: auto;
      margin-right: auto;
      padding-top: 20px;
      padding-left: 5px;
      padding-right: 5px;

      .header {
        font-size: 26px;
        font-weight: 600;
      }

      span.note {
        text-align: left;
        font-weight: 500;
      }

      .delinquent {
        font-size: 24px !important;
        margin-bottom: 10px;
      }

      .subnote {
        font-size: 10px;
        text-align: center;
        padding-bottom: 20px;
      }

      .summary {
        margin-top: 20px;
        width: 80%;
        margin-left: 3%;
        @media (max-width: 472px) {
          width: 88%;
        }
        @media (max-width: 400px) {
          width: 90%;
        }

        .summed {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          font-family: $font-primary; //$font-secondary;
          font-size: 16px;
          padding: 8px 0px;

          span {
            color: $blue;
            font-weight: bold;
          }
          .value {
            color: $orange;

            span {
              color: $gray;
              font-size: 12px;
            }
          }
        }
      }

      .authCheck {
        padding-top: 40px;
        padding-bottom: 20px;

        .first {
          display: block;
          margin-left: 25px;
          font-size: 9px;
        }

        input {
          width: 0;
          height: 0;
          position: relative;
          top: -25px;
          right: -85%;
          opacity: 0;
        }

        input:checked {
          border: 3px solid $orange;
          background-color: $orange;
        }

        .authAgreementLeft {
          /* display: inline-flex; */
          width: 90%;
        }
        .authAgreementRight {
          .input-checkbox-wrapper.chargeoff {
            @media (min-width: 1471px) {
              left: 100%;
            }
            @media (max-width: 1470px) {
              left: 80%;
            }
            @media (max-width: 1420px) {
              left: 90%;
            }
            @media (max-width: 1320px) {
              left: 95%;
            }
          }

          .input-checkbox-wrapper.chargeoff,
          .input-checkbox-wrapper {
            @media (max-width: 768px) {
              left: 80%;
            }
            @media (max-width: 678px) {
              left: 90%;
            }
            @media (max-width: 615px) {
              left: 95%;
            }
          }

          .input-checkbox-wrapper {
            color: $white;
            height: 0;
            width: 0;
            top: -15px;
            left: 90%;
            font-weight: 600;
            padding: 6px 30px;

            @media (max-width: 1485px) {
              left: 95%;
            }
            @media (max-width: 1470px) {
              left: 70%;
            }
            @media (max-width: 1360px) {
              left: 80%;
            }
            @media (max-width: 1265px) {
              left: 90%;
            }
            @media (max-width: 1200px) {
              left: 95%;
            }

            .input-checkbox,
            .input-checkbox-sm {
              color: $white;
              background-color: $white;
              left: -25%;
              height: 12px;
              width: 12px;
            }

            .input-checkbox:after {
              content: "✓ ";
              font-size: 12px;
              color: $white;
              background-color: unset;
              border: unset;
              font-weight: bold;
              top: -1px;
              left: 20%;
            }

            /*.input-checkbox-sm:after {
              content: "✓ ";
              font-size: 12px;
              color: #FFFFFF;
              background-color: unset;
              border: unset;
              font-weight: bold;
              top: -3px;
              left: 10%;
            }*/
            .input-checkbox-selected {
              background-color: $orange;
            }
          }
        }
      }

      .modal {
        position: fixed;
        z-index: 9999;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.3);
        overflow: hidden;

        div.first {
          position: relative;
          top: 25%;
          left: 30%;
          z-index: 9999;
          background-color: #ffffff;
          border-style: solid;
          border-width: 1px;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.5);
          width: 450px;
          padding: 10px 20px 10px 20px;
          text-align: left;

          @media (max-width: 900px) {
            left: 20%;
          }
          @media (max-width: 768px) {
            top: 15%;
          }
          @media (max-width: 740px) {
            left: 15%;
          }
          @media (max-width: 620px) {
            width: 400px;
          }
          @media (max-width: 570px) {
            left: 0%;
          }
          @media (max-width: 420px) {
            top: 5%;
            left: 0%;
            width: 340px;
          }
          @media (max-width: 415px) {
            top: 25%;
            left: 0%;
            width: 370px;
          }
          @media (max-width: 390px) {
            top: 20%;
            left: 1%;
            width: 335px;
          }
        }
      }

      .buttons {
        text-align: center;
      }
    }

    .submitted.error {
      max-width: 1254px;
      width: auto;
      color: $gray;
      padding-bottom: 30px;
    }

    .confirmed {
      width: 70%;
      font-family: $font-primary; //$font-secondary;
      margin: 40px auto;

      .clsh2 {
        color: $orange;
        text-align: center;
      }

      .summary {
        margin-top: 20px;

        .summed {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          font-family: $font-primary; //$font-secondary;
          font-size: 16px;
          font-weight: 600;
          padding: 8px 0px;
          span {
            color: $gray;
          }
          span.value {
            color: $gray;
            font-size: 12px;
          }
        }
      }

      .confirmNum {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        margin-top: 20px;
        color: $blue;
      }
    }
  }

  .payment.cardNew {
    height: max-content;
    min-width: 435px;
    @media (max-width: 1470px) {
      min-width: unset;
    }

    @media (max-width: 768px) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .eftMethod .eftSelected,
  .eftMethod .note {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .eftMethod .eftSelected {
    border: 1px solid $absolute-black;
    border-radius: 6px;
    width: 250px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 600;

    span {
      color: $orange;
    }
  }

  .eftMethod .note {
    width: 300px;
    margin-top: 10px;
    margin-bottom: 15px;

    a {
      color: $orange;
      border-bottom: 1px solid $orange;
    }
  }

  .subnote a {
    color: $orange;
    border-bottom: 1px solid $orange;
    .info-button {
      height: 12px;
      width: 12px;
      float: right;
      position: relative;
      top: -30px;
      padding: -1px;
    }
  }

  a.img-link:link {
    text-decoration: none;
  }

  a.img-link:visited {
    text-decoration: none;
  }

  a.img-link:hover {
    text-decoration: none;
  }

  a.img-link:active {
    text-decoration: none;
  }

  .error {
    margin: 10px 0;
    text-align: center;
    color: $error;
  }
  .short {
    min-width: 190px;
    margin-right: 10px;
  }

  @media (max-width: 1023px) {
    .close {
      order: -1;
      margin-left: auto;
    }
    .next {
      margin: -10px 0 0;
      background: $black;
    }
    .payment {
      .bottom {
        .buttons {
          width: 100%;
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
        }
      }
      .folders {
        width: 100%;
      }

      .submitted {
        font-size: 12px;

        .summary {
          margin-top: 20px;

          .summed {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            font-family: $font-primary; //$font-secondary;
            span {
              color: $blue;
            }
            .value {
              font-family: $font-primary;
            }
          }
        }
      }

      .confirmed {
        width: 240px;
        font-family: $font-primary; //$font-secondary;
        margin-top: 40px;

        .summary {
          margin-top: 20px;

          .summed {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            align-items: flex-start;
            font-size: 12px;
            padding: 4px 10px;
          }
          span {
            color: $blue;
          }
          .value {
            font-family: $font-primary;
          }
        }
      }
    }
  }
}

.bottom .buttons .button-cancel.submitSect,
.bottom .buttons .button-confirm.submitSect {
  @media (max-width: 1422px) {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.payoff {
  @media (max-width: 820px) and (min-width: 768px) {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  @media (max-width: 420px) {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
}

header.b-calendar-header {
  display: none;
}

.make-payment .payment .bottom button.btnarrow {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0px;
  background-color: transparent;
  color: $red;
  padding: 0px;

  &:focus-visible {
    filter: none;
  }
}

.lblheader {
  display: flex;
  width: 100%;
  margin-bottom: 0px;
}

.trademark {
    font-size: 10px;
    vertical-align: center;
    font-weight: 500;
  }
</style>