const initialState = {
  custId:null,
  firstName: null,
  lastName: null,
  email: null,
  cellNo: null,
  messageSubjectline: '',
  messageBody: '',
  passcode:'',
  previewText: '',
  tokenID : null,
  smsText :null,
  messageFor : null
}

//import Vue from 'vue';
import {$gMiddleLayerApi} from '../config/appconfigsettings';
import { lvxServiceID, lvxFrom, actonAccountID, actonTemplateID, actonFromEmailID, actonReplytoEmailID, acton2FAFromEmailID, acton2FATemplateID } from '@/config/amounts';
export default {
  state: () => {
    return {
      ...initialState
    }
  },
  getters: {},
  computed: {
  },
  mutations: {
    resetSendEmailSMSData(state) {
      Object.keys(state).forEach(key => {
        state[key] = initialState[key];
      })
    },
    updateSendMessagesUtilityEmailFirstName(state, value) {
      state.firstName = value;
    },
    updateSendMessagesUtilityEmailLastName(state, value) {
      state.lastName = value;
    },
    updateSendMessagesUtilityEmail(state, value) {
      state.email = value;
    },
    updateSendMessagesUtilityMobile(state, value) {
      state.cellNo = value.replace(/-/g,"");
    },
    updateSendMessagesUtilityEmailSubject(state, value) {
      state.messageSubjectline = value;
    },  
    updateSendMessagesUtilityEmailBody(state, value) {
      state.messageBody = value;
    },
    updateSendMessagesUtilityPreviewText(state, value) {
      state.previewText = value;
    },
    updateSendMessagesUtilityToken(state, value) {
      state.tokenID = value;
    },
    updateSendMessagesUtilityCustId(state, value) {
      state.custId = value;
    },    
    updateSendMessagesUtilitySMSText(state, value) {
      state.smsText = value;
    },
    updateSendMessagesUtilityPasscode(state, value) {
      state.passcode = value;
    },
    updateMessageFor(state, value) {
      state.messageFor = value;
    },
  },
  actions: {
    async utilitiesSendEmail({
      state
    }) {
        if(state.messageFor == '2FA'){
          this.actonFromEmailID = acton2FAFromEmailID;
          this.actonTemplateID = acton2FATemplateID;
        }
        else{
          this.actonFromEmailID = actonFromEmailID;
          this.actonTemplateID = actonTemplateID;
        }

        var input = JSON.stringify({
          accountId: actonAccountID,
          templateId: this.actonTemplateID,
          eteRequest: {
            envelope: {
              fromAddress: this.actonFromEmailID,
              fromDisplayName: "XactFlex",
              toAddress: state.email,
              replyToAddress: actonReplytoEmailID
            },
            content: {
              subjectLine: state.messageSubjectline,
              previewText: state.previewText,
              personalizationDataMap: {
                account_BIZ_ADDRESS: "PO Box 965391, Marietta, GA 30066",
                firstname: state.firstName,
                passcode: state.passcode
              }
            },
            metadata: {
              transactional: "true",
              externalId: ""
            },
            actions: {
              trackOpens: true,
              trackClicks: true,
              deliveryWhenDelayed: "FALSE"
            }
          }
         });
        //console.log(input);
        var auth = 'Bearer ' + state.tokenID;
        try {
          const responseEmail = await fetch( $gMiddleLayerApi + 'Communications/SendEmail', { //for now just testing api hitting endpoint, will need to change this later to accept paths
            "method": "POST",
            "body": input,
            "headers": {
              'Content-Type': 'application/json',
              'Authorization':auth
            }
          })  
          //console.log(responseEmail);
          if (responseEmail.ok) {
            //console.log('SUCCESS');
            var jVal = await responseEmail.json(); 
            return Promise.resolve(jVal);
          } else
          {
            return Promise.reject('Call failed');
          }
        } catch (ex) {
          //console.log(ex);
        }
    },

    async utilitiesSendSMS({
      state
    }) {
        //console.log("SMS Sending");

        var input = JSON.stringify({
              from : lvxFrom,
              to : state.cellNo,
              serviceId : lvxServiceID,
              account : "'"+ state.custId + "'",
              body : {
                message: state.smsText
              },
        })
        //console.log(input);

        var auth = 'Bearer ' + state.tokenID;

        try {
          const sendSMSResponse = await fetch( $gMiddleLayerApi + `Communications/SendSMS`, { //for now just testing api hitting endpoint, will need to change this later to accept paths
            "method": "POST",
            "body": input,
            "headers": {
              'Content-Type': 'application/json',
              'Authorization':auth
            }
          })
  
          if (sendSMSResponse.ok) {
            //console.log('SUCCESS');
            var jVal = await sendSMSResponse.json(); 
            return Promise.resolve(jVal);
          } else
          {
            return Promise.reject('Call failed');
          }
        } catch (ex) {
          //console.log(ex);
        }
      },
  }
}