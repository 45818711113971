<template>
  <main>
    <div class="maincontent" v-if="resetPasswordVisible">
      <div class="login-box">
        <ContentSectionTitle> Reset Your Password </ContentSectionTitle>
        <h2 class="instructionheader">Passwords must contain</h2>
        <div class="instructions">
          {{ help.passwordRules }}
        </div>

        <FormField>
          <template v-slot:label> ENTER A PASSWORD </template>
          <input
            name="password"
            maxlength="20"
            id="password"
            class="password"
            v-model="password"
            :type="showPlainPasswords ? 'text' : 'password'"
            v-validate="{ required: true, max: 20, invalidPassword: true }"
          />
          <div
            class="password-show-icon"
            @click="showPlainPasswords = !showPlainPasswords"
          >
            <i
              :class="
                showPlainPasswords
                  ? 'fas fa-eye-slash fa-lg'
                  : 'fa fa-eye fa-lg'
              "
            />
          </div>
          <div
            v-show="!errors.first('password') && password"
            class="input-accept"
          />
          <div
            v-show="errors.first('password')"
            class="input-reject"
          />
          <template v-slot:error
            >{{ errors.first("password") }}
            <label
              id="passworderror"
              name="passworderror"
              v-show="!errors.first('password')"
            ></label>
          </template>
        </FormField>

        <FormField>
          <template v-slot:label> CONFIRM PASSWORD </template>
          <input
            name="confirm password"
            class="password"
            v-model="confirmPassword"
            :type="showConfirmPasswords ? 'text' : 'password'"
            v-validate="{ required: true, is: password }"
          />
          <div
            class="password-show-icon"
            @click="showConfirmPasswords = !showConfirmPasswords"
          >
            <i
              :class="
                showConfirmPasswords
                  ? 'fas fa-eye-slash fa-lg'
                  : 'fa fa-eye fa-lg'
              "
            />
          </div>
          <div
            v-show="!errors.first('confirm password') && confirmPassword"
            class="input-accept"
          />
          <div v-show="errors.first('confirm password')" class="input-reject" />
          <template v-slot:error>{{
            errors.first("confirm password")
          }}</template>
        </FormField>

        <div class="bottom">
          <button class="button button-float" @click="submit">
            SUBMIT & GO TO LOG IN
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import help from "@/config/text/help";
import { FormField, ContentSectionTitle } from "@/components";
import { passwordRegex } from "@/config/validations";

export default {
  name: "ResetPassword",
  title: "Reset Password",
  components: { FormField, ContentSectionTitle },
  data() {
    return {
      help: help,
      password: null,
      passwordRegex: passwordRegex,
      showPlainPasswords: false,
      showConfirmPasswords: false,
      confirmPassword: null,
      resetPasswordVisible: false,
    };
  },
  computed: {
    isFormValid: function () {
      return (
        !Object.values(this.fields).filter((field) => !field.valid).length &&
        this.validatePassword(this.password)
      );
    },
  },
  async created() {
    this.resetPasswordVisible=false;
    if (this.$store.state.login.token == undefined) {
      const tokenResult = await this.$store.dispatch("createToken");

      this.$store.state.login.token = tokenResult.token.writtenToken;
    }

    this.$store.state.login.passwordGuid = this.$route.query.token;
    const validateTokenResult = await this.$store.dispatch("validateToken");

    if (validateTokenResult.tokenState === 0) {
      this.$store.commit("updatePasswordResetTokenExpired", false);
      this.resetPasswordVisible = true;
      this.$store.state.login.email = validateTokenResult.emailAddress;
      this.$store.state.login.token = validateTokenResult.tokenToUse;
      this.$store.state.login.passwordGuid = validateTokenResult.passwordGUID;
      return;
    } else {
      this.$store.state.login.email = validateTokenResult.emailAddress;
      this.$store.commit("updatePasswordResetTokenExpired", true);
      this.$router.push({ name: "login" });
    }
  },
  methods: {
    submit: function () {
      this.resetPassword();
    },
    validatePassword: function (password) {
      if (password == undefined || password == null) {
        return true;
      } else {
        var re = /^(?=.*[A-Z])(?=.*[\W])(?=.*[0-9])(?=.*[a-z]).{8,20}$/;
        var res = re.test(password);
        res = res ? !/\s/g.test(password) : res;
        if (!res) {
          document
            .getElementById("password")
            .parentElement.classList.add("field-error");
          if (document.getElementById("passworderror") != undefined)
            document.getElementById("passworderror").parentElement.id =
              "passwordError";
          if (document.getElementById("passwordError") != undefined)
            document.getElementById("passwordError").innerHTML =
              "Please enter a password that meets the criteria.";
        } else {
          if (
            document
              .getElementById("password")
              .parentElement.classList.contains("field-error")
          ) {
            document
              .getElementById("password")
              .parentElement.classList.remove("field-error");
            if (document.getElementById("passwordError") != undefined)
              document.getElementById("passwordError").innerHTML = "";
          }
        }
        return res;
      }
    },
    async resetPassword() {
      this.$validator.validate();
      this.$store.commit("clear_identitymsg");
      if (this.isFormValid) {
      //  console.log(this.$store.state.login.token);
       // console.log(this.$store.state.login.email);
       // console.log(this.$store.state.login.passwordGuid);
       const result = await this.$store.dispatch(
        "updatePassword",
         this.password
        );
      //  console.log(result);
        // Password reset functionality goes here
        if (result.updated) {
          this.$store.commit("updateCalledFrom", "UpdatePwd");
          this.$router.push({ name: "login" });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
#content {
  margin-bottom: 0px;
  @media (max-width: 922px) {
    margin: 70px 0 0 0;
  }
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input {
  border: solid 1px #dedfdd;
  padding: 3px 5px 3px 5px;
}

main {
  .login-box {
    align-items: flex-start;
    margin: 10% 20% !important;
    padding: 30px 100px 40px 100px;
    @media (max-width: 767px) {
      margin: 10% 0%;
      padding: 30px 10px 50px 10px;
    }
  }
  .section-title {
    align-items: flex-start;
    margin: 5px 5px 10px 4px !important;

    p {
      margin-top: 10px;
    }
  }
  .instructionheader {
    text-transform: none;
    margin-bottom: 10px;
    margin-left: 5px;
    font-size: 14pt;

    @media (max-width: 767px) {
      margin-bottom: 0px;
    }
  }
  .instructions {
    font-size: 14px;
    width: 100%;
    margin: 5px 5px 15px 5px;
  }
  .wrapper {
    width: 92%;
  }
  .password-show-icon {
    position: absolute;
    margin: 10px 52%;
    color: $gray;
    text-align: center;

    @media (max-width: 767px) {
      margin: 10px 70%;
    }
    @media (min-width: 451px) and (max-width: 580px) {
      margin: 10px 74%;
    }
    @media (min-width: 400px) and (max-width: 450px) {
      margin: 10px 65%;
    }

    i {
      height: 14px;
      width: 25px;
    }
  }

  .bottom {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
  }

  .button-float {
    padding: 8px 30px;
    top: 95%;
    font-size: 14pt !important;

    @media (max-width: 1023px) {
      top: 95%;
    }
  }
}
@media (max-width: 767px) {
  .password {
    flex: 0 0 85% !important;
  }
  .password-show-icon {
    margin: 10px 270px;
  }
}
@media (max-width: 450px) {
  .login-box {
    padding: 10px 30px 20px 30px !important;
    max-width: 100%;
  }
  .section-title .header {
    margin-top: 0px;
  }

  .instructions {
    font-size: 10pt !important;
    margin: 5px !important;
  }
}
@media (max-width: 400px) {
  .login-box {
    padding: 10px 15px 20px 15px !important;
  }
}
</style>