<template>
  <div class="section-title">
    <div class="optional">
      <slot name="optional"></slot>
    </div>
    <slot></slot>
    <h2><slot name="pagetitle"></slot></h2>
    <div class="subtitle">
      <slot name="subtitle"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionTitle",
};
</script>

<style scoped lang="scss">
.section-title {
  font-size: 36px;
  width: 100%;
  font-weight:700;
  @media (max-width:768px) {
    text-align: center;
  }

  .subtitle {
    font-family: $font-primary;//$font-secondary;
    text-transform: uppercase;
    font-size: 16px;
    margin: 8px 0;
  }

  .underline {
    border-bottom: 1px solid $green;
    width: 200px;
  }

  .optional {
    font-family: $font-primary;//$font-secondary;
    font-size: 12px;
  }

  @media (max-width: 1023px) {
    font-size: 24px;
    padding: 10px 0px;
    margin: 0;
  }

  @media (min-width: 770px) and (max-width: 1265px) {
    white-space: nowrap;
  }
}

.confirmMsg .section-title {
  color:$orange;
  text-align:center;
  font-size:28px;
}

.make-payment .section-title {
  @media (min-width: 770px) and (max-width: 1265px) {
    white-space: unset;
  }
}

.draw-request-modal .section-title, .make-payment .section-title {
  @media  (max-width:768px) {
    position:relative;
    top:-170px;
    z-index:2;    
  }
}

</style>