<template>
  <main>

    <PageHeader>
      Terms and Conditions
    </PageHeader>

    <section>
      <div class="policy" v-html="termsConditions"/>
    </section>

  </main>
</template>

<script>
import { PageHeader } from '@/components';

export default {
  name: 'TermsAndConditions',
  title: 'Website Terms and Conditions',
  components: { PageHeader },
  computed: {
    termsConditions: {
      get() { return this.$store.state.documents.termsConditions }
    }
  },
  async created() {
    if (!this.termsConditions) {
      this.$store.dispatch('getTermsConditions');
    }
  },
}
</script>

<style scoped lang="scss">
#content{
    margin: 0 auto !important; 
  }
main {
  width: 100%;
  background: white;
  section {
    margin-top: 20px;
    padding: 0 0 70px 0;
    .policy {
     max-width: 830px;
    margin: 0 auto;
    font-size: medium;
    text-align: justify;
      @media (max-width: 1023px) {
        margin: 0 10px;
      }
    }

    .caPolicy{
      max-width: 1100px;
      padding: 30px;
      color: #53575A;
      margin: 0 auto;

      @media (max-width: 1023px) {
        margin: 0 10px;
      }
    }
    .subTitle{
      color: #1e4488
    }
  }
}
@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
</style>