export default `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="generator" content="CoffeeCup HTML Editor (www.coffeecup.com)">
    <meta name="dcterms.created" content="Wed, 11 Nov 2020 16:54:11 GMT">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <title>About Us</title>
    
    
  </head>
  <body>
    <div class="container">
      
    <h2 style="font-size:24px; text-align:center" class="aboutTitle">
    What is Xact<i>Flex</i><span style="font-size: 8px;margin-left: 1px;vertical-align: top;">&reg;</span>?
    </h2>
        
        <p>
        <span style="white-space: nowrap;"><span id="xact1">Xact</span><span id="flex1"><i>Flex</i></span></span> is an online-only, open-ended line of credit. <span style="white-space: nowrap;"><span id="xact2">Xact</span><span id="flex2"><i>Flex</i></span></span> gives you access to the funds you need when you need it, up to your credit limit. Make regular payments as you go along and draw again when you need funds.
        <br/>
        <br/>
            We understand that life can be challenging. We offer financial solutions like the <span style="white-space: nowrap;"><span id="xact3">Xact</span><span id="flex3"><i>Flex</i></span></span> Line of Credit to help. <span>Reach out today – we’ll handle tomorrow, together.
        </p>
        <br/>
        <hr/>
        <br/>
        <h2 style="font-size:24px; text-align:center">
            Partnerships
        </h2>
        <p>
            CCBank, Member FDIC and Axcess Financial, partnered with a common goal of providing a financial solution that was lacking in the market to meet the needs of everyday people. By listening to the needs of customers, and through a diligent partnership, the <span style="white-space: nowrap;"><span id="xact4">Xact</span><span id="flex4"><i>Flex</i></span></span> Line of Credit was developed. 
            </p><p>Founded in 1993, Capital Community Bank (CCBank), Member FDIC is based in Provo, Utah. Voted into the Top 100 Community Banks (2016), CCBank prides itself as a community-focused financial institution, committed to the financial well-being of their customers. CCBank provides secure, online lending, as part of that commitment, to
            ensure your personal and financial information stays protected. CCBank works hard to ensure customers have options that meet their needs – and that’s why they teamed up with a company that has similar philosophies to bring a new product to the market. 
            <br/>
            <br/>
            For more than 25 years, Axcess Financial (Cincinnati, OH) has been a financial service provider for real people. Axcess Financial is proud to serve hardworking customers, who have experienced life’s toughest moments. Through a family of brands, Axcess is committed to providing financial solutions to those who need it most. 
            <br/>
            <br/>
            The goal of this partnership has always been, and will always be, to put people first. Together, they don’t just lend money – they lend a hand.
        </p>
            
    </div>
  </body>
</html>
`