<template>
  <div class="section-title">
    <h1 class="header">
      <slot></slot>
    </h1>
    <div class="subtitle">
      <slot name="subtitle"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "ContentSectionTitle",
};
</script>

<style scoped lang="scss">
.section-title {
  font-size: 21pt;
  width: 100%;
  font-weight: 700;

  .header {
    color: #1e4488;//#1e4488;
    margin-bottom: 2px;
    margin-top: 15px;
    width: 100%;
    @media (max-width: 767px) {
       margin-top: 5px;
       text-align: left;
    }
  }
  h1{
    font-size: 21pt;
  }

  .subtitle {
    font-family: $font-primary;//$font-secondary;
    font-size: 16pt;
    margin: 0;

    @media (max-width: 767px) {
      text-align: left;
    }
  }

  .underline {
    border-bottom: 1px solid $green;
    width: 200px;
  }

  .optional {
    font-family: $font-primary;//$font-secondary;
    font-size: 12px;
  }

  @media (max-width: 1023px) {
    padding: 10px 0px;
    margin: 0;

    h1{
    font-size: 24px;
    }
  }
}
</style>