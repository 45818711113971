<template>
  <div class="portal-table">
    <div class="title">
      <slot></slot>
    </div>
  
   <div class="table-container">
    <table :class="data.length === 0 ? 'hidden' : null">
      <thead>
        <tr class="header">
          <th v-for="(column, index) in columns" :key="index" :class="column">
            <div v-if="column === 'trans. id'">
              Trans. ID
            </div>
            <div v-else>
              {{ column }}
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in data" :key="index">
          <td v-for="(column, index) in columns" :key="index" :class="column">
            <span v-if="column !== 'download/view'">
              <span v-if="column === 'amount'">
                <span v-if="row[column] > 0"> {{ currencyLong(row[column]) }} </span>
                <span v-if="row[column] < 0"> ({{ currencyLong((-1)*row[column]) }}) </span>
              </span>
              <span v-else-if="column === 'date' || column === 'billing statements'">
                {{ date(row[column]) }}
              </span>
              <!-- <span v-else-if="column === 'line of credit agreement'" class="loca">
                {{ row[column] }}
              </span> -->
              <span v-else>
                {{ row[column] }}
              </span>
            </span>
          </td>
        </tr>
      </tbody>
      
    </table>
        </div>

  </div>
</template>

<script>
import { currencyLong } from "@/utils/filters/currency";
import { date } from '@/utils/filters/dates';

export default {
  name: 'PortalTable',
  props: ['columns', 'data'],
  data() {
      return {
        currencyLong : currencyLong,
        date: date
      }
  }
}
</script>

<style scoped lang="scss">
.portal-table {

  .date, [class='billing statements'] {
     width:15%;
  }
  .description, [class='billing statements'] {
     width:35%
  }
  [class='line of credit agreement'] {
    text-align: left;
  }
  
  td.date {
    padding-left:5px;
  }

  .table-container table .id {
    text-align: right;
    padding-right:10px;
  }

  .table-container table th.id div {
    padding-right:10px;
  }

  .table-container {
    margin-bottom:25px;
    max-height:340px;
    overflow: hidden;
    overflow-y: auto;
  }

  table.hidden {
    visibility: hidden;
  }

   @media (min-width: 768px){
      .table-container table {
      display: flex;
     flex-flow: column;
     height: 100%;
     width: 100%;
    //  overflow: scroll;
    // display: block;
    }
    .table-container table thead {
        flex: 0 0 auto;
        width: 100%;
    }
    .table-container table tbody {
        flex: 1 1 auto;
        display: block;
        width: 100%;
        overflow-y: overlay;
    }
    .table-container table tbody tr {
        width: 100%;
    }
    .table-container table thead, .table-container table tbody tr {
        display: table;
        table-layout: fixed;
    }
    .table-container table {
        border-collapse: collapse;
    }
    .table-container table td, .table-container table th {
        padding: 0.4em;
    }
    //  .table-container table th {
    
    // }
    .table-container table td {
      //  border: 1px solid #e7e1e1;
      
        font-size: 14px;
        /* necessary to set for proper "showing row x of y" calculations if infinate scoll */
        white-space: nowrap;
        // text-align: center;
        padding: 10px 5px;
        white-space: nowrap;
        text-overflow: ellipsis;

    }
    //.table-container table thead {
        //  border: 2px solid #0F0FA3;
    //}
    .table-container th {
        -webkit-user-select: none;
      -moz-user-select: none !important;
        -ms-user-select: none;
        user-select: none;
    }
    .table-container table tbody td {
        padding: 8px;
    }
    .table-container table thead td {
        padding: 10px 5px;
    }

    /* START Adjustments for width and scrollbar hidden */
    //  .table-container th.table-action, .table-container td.table-action {
    //      width: 5.8vw;
    // }
    //  .table-container table thead {
    //      width: calc(100% - 1px);
    // }
    //  .table-container table tbody::-webkit-scrollbar {
    //      width: 1px;
    // }
    //  .table-container table tbody::-webkit-scrollbar {
    //      width: 1px;
    // }
    //  .table-container table tbody::-webkit-scrollbar-thumb {
    //      width: 1px;
    // }
    /* END Adjustments for width and scrollbar */

  }
}

</style>

