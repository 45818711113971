<template>
  <main>

    <PageHeader>
      About <XactFlexBrandedText />
    </PageHeader>

    <section>
      <div class="policy" v-html="aboutUs"/>
       <!--<div class="row justify-content-evenly images">
            <div class="col ccbank">
                <img src="../../assets/logos/ccbanklogo.png" width="240px" alt="CCBank">
            </div>
            <div class="col axcess">
                <img src="../../assets/logos/axcessfinlogo.png" width="240px" alt="Axcess">
            </div>
        </div>-->
    </section>

  </main>
</template>

<script>

import { PageHeader, XactFlexBrandedText } from '@/components';

export default {
  name: 'AboutUs',
  title: 'About Us',
  components: { PageHeader, XactFlexBrandedText },
  computed: {
    aboutUs: {
      get() { return this.$store.state.documents.aboutUs }
    }
  },
  async created() {
    if (!this.aboutUs) {
      this.$store.dispatch('getAboutUs');
    }
  },
}
</script>

<style scoped lang="scss">

#content{
    margin: 0 auto !important; 
  }
main {
  width: 100%;
  background: white;
  section {
    margin-top: 20px;
    padding: 0 0 70px 0;
    line-height: 1.5rem;
    .policy {
      max-width: 1100px;
      margin: 0 auto;
      padding: 20px;
      
      @media (max-width: 1023px) {
        margin: 0 10px;
      }
      
    }

    .images {
      max-width: 750px;
      margin: 0 auto;
      padding: 20px;
     
      @media (max-width: 1023px) {
        margin: 0 10px;
      }
    }
  }
}
@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
</style>