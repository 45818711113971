// import { Validator } from 'vee-validate';
// import dayjs from "dayjs";

// Validator.extend('socialLength', {
//   getMessage: field => 'The ' + field + ' must be 9 digits in length',
//   validate: value => value.replaceAll('-', '').length === 9
// });

// Validator.extend('phoneLength', {
//   getMessage: 'Please enter your 10-digit main phone number',
//   validate: value => value.replaceAll('-', '').length === 10
// });
// Validator.extend('cellLength', {
//   getMessage: 'Please enter your 10-digit cell phone number',
//   validate: value => value.replaceAll('-', '').length === 10
// });
// Validator.extend('workLength', {
//   getMessage: 'Please enter your 10-digit work phone number',
//   validate: value => value.replaceAll('-', '').replaceAll('x', '').length >= 10
// });
// Validator.extend('zipLength', {
//   getMessage: 'Please enter your 5-digit zip code',
//   validate: value => value.replaceAll('', '').length === 5
// });

// Validator.extend('routingLength', {
//   getMessage: 'Please enter your bank’s 9-digit ABA routing number. Only numbers are allowed',
//   validate: value => value.replaceAll('-', '').length === 9
// });
// Validator.extend('accountLength', {
//   getMessage: 'Please enter your bank’s 15-digit Bank Account Number. Only numbers are allowed',
//   validate: value => value.replaceAll('-', '').length === 15
// });
// Validator.extend('invalidPassword', {
//   getMessage: 'Please enter a password that meets the criteria',
//   validate: value => {
//     // var re = /^(?=.*[A-Z])(?=.*[\W])(?=.*[0-9])(?=.*[a-z]).{8,20}$/;
//     // var res = re.test(value);
//     // return res;
//     var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,20}/;
//     var verify = re.test(value)
//     if (verify == true) {
//         re = /\s/;
//         if (re.test(value))
//             verify = false;
//     }
//     return verify;
//   }
// });

// Validator.extend('validatebirthdateminage', {
//   getMessage: 'Please enter a valid birthdate',
//   validate: value => {
//     if (value == null || value == undefined) {
//       return false;
//     }
//     var min_eligible_date = dayjs().subtract(18, "years").toDate();
//     var selectedBirthDate = new Date(value);
//     if (selectedBirthDate <= min_eligible_date) {
//       return true;
//     }
//     else{
//       return false;
//     }
    
//   }
// });

//export const passwordRegex = /(?=.^\S)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,20}/;
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{7,19}$/;

// Validator.localize({
//   en: {
//     custom: {
//       email: {
//         required:
//           "Please enter your email address in this format: name@example.com",
//       },
//       SSN: {
//         required:
//           "Please enter the last four digits of your social security number",
//         length:
//           "Please enter the last four digits of your social security number",
//         numeric:
//           "Please enter the last four digits of your social security number",
//       },
//       birthdate: {
//         required: "Please enter your birthdate as MM/DD/YYYY",
//         length: "Please enter your birthdate as MM/DD/YYYY",
//         date_format: "",
//       },
//       password: {
//         required: "Please enter a password that meets the criteria",
//         min: "Please enter a password that meets the criteria",
//         max: "Please enter a password that meets the criteria",
//       },
//       "confirm password": {
//         required: "Please confirm your password.",
//         is: "Passwords must match.",
//         min: "Passwords must match.",
//         max: "Passwords must match.",
//       },
//     },
//   },
// });