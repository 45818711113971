import routes from './routes';
import { createRouter as _createRouter, createWebHistory } from 'vue-router'

export default _createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  linkActiveClass:'nav-active-link',
  scrollBehavior () {
    return { left: 0, top: 0 }
  }
})
