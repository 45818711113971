<template>
  <div class="draw-request-modal">
    <div
      class="header"
      v-show="!initialDrawRequested"
      :class="delinquentOrchargeoff || !enableDraw ? 'del-gray' : null"
    >
      <section id="drawmainheader" tabindex="-1">
        <SectionTitle
          ><template v-slot:pagetitle> Request a Draw </template></SectionTitle
        >
        <div class="clsh5">
          Use the slider below to select the amount you would like to draw.
        </div>
      </section>
    </div>
    <div class="container" v-show="!initialDrawRequested">
      <div
        class="right-container"
        :class="delinquentOrchargeoff || !enableDraw ? 'del-gray' : null"
      >
        <div
          class="draw-amount"
          :class="delinquentOrchargeoff || !enableDraw ? 'del-gray' : null"
        >
          <span
            class="dollar"
            :class="delinquentOrchargeoff || !enableDraw ? 'del-gray' : null"
          ></span>
          <span
            v-if="!delinquentOrchargeoff && enableDraw"
            class="draw-input"
            >{{ currencyLong(initialDrawAmount) }}</span
          >
          <span v-else class="draw-input del-gray">{{ currencyLong(0) }}</span>
        </div>
        <div
          class="note"
          style="text-align: center"
          :class="delinquentOrchargeoff || !enableDraw ? 'del-gray' : null"
        >
          Total Draw Amount
        </div>
      </div>
      <div class="left-container">
        <div
          class="draw-amount"
          :class="delinquentOrchargeoff || !enableDraw ? 'del-gray' : null"
        >
          Minimum Draw:
          <span
            class="blue"
            :class="delinquentOrchargeoff || !enableDraw ? 'del-gray' : null"
          >
            {{ currencyLong(minimumDraw) }}
          </span>
        </div>
        <div
          class="draw-amount"
          :class="delinquentOrchargeoff || !enableDraw ? 'del-gray' : null"
        >
          Draw Increments:
          <span
            class="blue"
            :class="delinquentOrchargeoff || !enableDraw ? 'del-gray' : null"
          >
            {{ currencyLong(drawIncrement) }}
          </span>
        </div>
        <div
          class="subnote"
          :class="delinquentOrchargeoff || !enableDraw ? 'del-gray' : null"
        >
          Up to your available credit.
        </div>
      </div>
    </div>
    <div class="draw-request" v-show="!initialDrawRequested && enableDraw">
      <div class="slider">
        <div class="input-slider-wrapper">
          <div class="min" :class="delinquentOrchargeoff ? 'del-gray' : null">
            {{ delinquentOrchargeoff ? 0 : currencyLong(minimumDraw) }}
          </div>
          <div
            class="left-cap z-indexAdjust"
            :class="delinquentOrchargeoff ? 'left-cap-disable' : 'left-cap'"
          />
          <input
            name="draw"
            id="inpSlider"
            type="range"
            :min="minimumDraw"
            :max="availableCredit"
            :step="drawIncrement"
            aria-label="Draw Request Amount"
            class="slider-input z-indexAdjust"
            v-bind:style="
              delinquentOrchargeoff
                ? { 'background-image': 'none' }
                : { 'background-image': computedGradiant }
            "
            :class="
              delinquentOrchargeoff || valida ||validatestate()? 'slider-input-disabled' : 'slider-input'
            "
            @change="errorMsg = null"
            @input="setsliderinterval"
            v-model="initialDrawAmount"
          />
          <div
            class="right-cap z-indexAdjust"
            :class="delinquentOrchargeoff ? 'right-cap-disable' : 'right-cap'"
          />
          <div class="max" :class="delinquentOrchargeoff ? 'del-gray' : null">
            {{ delinquentOrchargeoff ? 0 : currencyLong(availableCredit) }}
          </div>
        </div>
        <div class="slidetext" v-show="!delinquentOrchargeoff">
          <label for="inpSlider" class="blue" v-show="!delinquentOrchargeoff"
            >Slide to select your amount. &nbsp;</label
          >
        </div>
        <div class="depositamount" v-show="!delinquentOrchargeoff">
          <div>
            <div class="amountwidth">
              <span class="cashfeetitle"
                >Cash Advance Fee &nbsp;&nbsp;
                <span class="cashfeehelpicon"
                  ><HelpIcon
                    :message="help.cashAdvanceFeeDraw"
                    :id="'cashfeeinfo'"
                /></span>
              </span>

              <span class="cashfee">
                - {{ currencyLong(this.cashAdvanceFee) }}</span
              >
            </div>
            <div class="depositamountline"></div>
            <div class="amountwidth">
              <span class="depositamttitle"
                >Amount You Will Receive &nbsp; &nbsp;</span
              >
              <span class="depositamthelpicon"
                ><HelpIcon :message="help.depositAmount" :id="'depositamtinfo'"
              /></span>
              <span class="depositamt" style="">{{
                currencyLong(this.actualDrawAmount)
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="buttons" v-show="!delinquentOrchargeoff">
        <button
          class="button-orange"
          @click="updateReviewRequest"
          v-if="!initialDrawRequested"
          :disabled="!enableDraw ||validatestate()"
          :class="!enableDraw ? 'button-orange disabled' : 'button-orange'"
        >
          REVIEW REQUEST
        </button>
      </div>
      <div class="error" v-if="errorMsg">
        {{ errorMsg }}
      </div>
    </div>
    <div class="acsmessage" v-show="delinquentchk">
      Your ability to draw funds is temporarily suspended because your account
      is past due. Please make a payment to bring your account up to date.
    </div>
    <div class="acsmessage flexmark" v-show="chargeoffchk">
      <span>Your account has been closed due to lack </span>
      <span>of payment. You can no longer draw </span>
      <span>funds from this account.</span>
    </div>
    <div
      class="acsmessage"
      v-show="
        !delinquentOrchargeoff &&
        !drawRequested &&
        this.$store.state.account.minimumDraw >
          this.$store.state.account.availableCredit
      "
    >
      You do not have enough available credit at this time to make a draw.
      You'll be able to draw again once you have enough available credit for the
      minimum draw.
    </div>
    <div class="review" v-show="initialDrawRequested">
      <br /><br />
      <div
        class="initialrequest-made"
        v-if="initialDrawRequested && !drawRequested"
      >
        <h2 class="review-section-title">Review Your Request</h2>
        <div class="clsh6">If everything is in order, submit your request.</div>
        <br /><br />
        <div class="drawmargin" style="display: flex; flex-flow: row">
          <div id="drawmarginsel" tabindex="0" ref="drawsel">
            <div class="request">
              Total Draw Amount
              <span class="blue val1">{{
                currencyLong(initialDrawAmount)
              }}</span>
            </div>
            <div class="request caf">
              Cash Advance Fee
              <span class="blue val2">
                -{{ currencyLong(this.cashAdvanceFee) }}
              </span>
            </div>
            <div class=""><hr /></div>
            <div class="request blue val3">
              Amount You Will Receive
              <span class="blue val3">
                {{ currencyLong(this.actualDrawAmount) }}
              </span>
            </div>
          </div>
          <a
            aria-label="Edit total draw amount"
            class="blue editAmt"
            @click="modifyDrawRequest"
            tabindex="0"
            >Edit</a
          >
        </div>
      </div>
      <div class="buttons">
        <button
          class="button-orange"
          @click="postdraw"
          v-if="initialDrawRequested && !drawRequested"
        >
          SUBMIT REQUEST
        </button>
        <button
          class="button-orange"
          @click="cancelDrawRequest"
          v-if="initialDrawRequested && !drawRequested"
        >
          CANCEL REQUEST
        </button>
      </div>
      <div class="request-made" v-if="drawRequested">
        <h2 class="orange">Thank You</h2>
        <br />
        <div class="clsh5 lightblue">We're processing your request.</div>
        <span class="thankMsg">
          You could see your funds in the bank as soon as the next business
          day.*
        </span>
        <!-- <p class = "thankMsg">
            You could see your funds in the bank as soon as the next business day.*
        </p> -->
        <br />
        <br />
        <br />
        <div class="initialrequest-made">
          <!-- <div class="request">Total Draw Amount
            <span class="orange val1">{{ currencyLong(initialDrawAmount) }}</span>
          </div>
          <div class="request caf">Cash Advance Fee
            <span class="orange val2"> -{{ currencyLong(this.cashAdvanceFee) }}
                </span>
          </div>-->
          <div class="blue val3">
            {{ currencyLong(this.actualDrawAmount) }}
          </div>
          <div><hr /></div>
          <div class="blue val3">Amount You Will Receive</div>
        </div>
      </div>
    </div>
    <!--  <div class="legal" v-if="!drawRequested">
      {{ legal.abilityToDraw }}
    </div>-->
    <div class="legal" v-if="drawRequested">
      {{ legal.drawSubmitted }}
    </div>
  </div>
</template>

<script>
import SectionTitle from "@/components/global/SectionTitle";
import legal from "@/config/text/legal";
import help from "@/config/text/help";
import dayjs from "dayjs";
import { currencyLong } from "@/utils/filters/currency";
//import { minimumDraw, drawIncrement } from "@/config/amounts";

import { HelpIcon } from "@/components";

export default {
  name: "PortalDrawReq",
  components: { SectionTitle, HelpIcon },
  computed: {
    computedGradiant() {
      const percentage =
        ((this.initialDrawAmount - this.minimumDraw) /
          (this.availableCredit - this.minimumDraw)) *
        100;
      return `linear-gradient(90deg, #EE6323 ${percentage}%, transparent ${percentage}%)`;
    },

    availableCredit: {
      get() {
        //  alert('available credit: '+ this.$store.state.account.availableCredit);
        return this.$store.state.account.availableCredit;
      },
    },
    delinquentOrchargeoff: {
      get() {
        if (
          this.$store.state.account.delinquent ||
          this.$store.state.account.chargeoff
        )
          return true;
        else return false;
      },
    },
    chargeoffchk: {
      get() {
        if (          
          this.$store.state.account.chargeoff
        )
          return true;
        else return false;
      },
    },
    delinquentchk:{
      get() {
        if (
          this.$store.state.account.delinquent
        )
          return true;
        else return false;
      },
    },
    minimumDraw: {
      get() {
        //alert('mindraw amount in porraldrawreq:' + this.$store.state.account.minimumDraw);
        return this.$store.state.account.delinquent ||
          this.$store.state.account.chargeoff
          ? 0
          : this.$store.state.account.minimumDraw;
      },
    },
    enableDraw: {
      get() {
        if (
          this.$store.state.account.minimumDraw >
          this.$store.state.account.availableCredit
        )
          return false;
        else return this.$store.state.account.isEligibleForDraw;
      },
    },

    drawIncrement: {
      get() {
        //alert('increment amount in portaldrawreq:' + this.$store.state.account.drawIncrement);
        return this.$store.state.account.drawIncrement;
      },
    },
    cashAdvanceFee: {
      get() {
        return parseFloat(
          (parseInt(this.initialDrawAmount) *
            parseFloat(this.cashAdvanceFeePercent)) /
            100
        ).toFixed(2);
      },
      set(value) {
        this.$store.commit("updateCashAdvanceFee", value);
      },
    },
    cashAdvanceFeePercent: {
      get() {
        //alert("cash advance fee percent:" + this.$store.state.account.cashAdvanceFeePercent);
        return this.$store.state.account.cashAdvanceFeePercent;
      },
    },
    drawAmount: {
      get() {
        return this.minimumDraw;
      },
      set(value) {
        this.$store.commit("updateDrawAmount", value);
      },
    },

    actualDrawAmount: {
      get() {
        return (
          parseFloat(this.initialDrawAmount) -
          parseFloat(this.cashAdvanceFee).toFixed(2)
        );
      },
      set(value) {
        this.$store.commit("updateActualDrawAmount", value);
      },
    },
    paymentEffectiveDate: {
      get() {
        let effdate = this.$store.state.account.achEffectiveDate;
        return dayjs(effdate).format("YYYY-MM-DD");
      },
    },
    maxACHBizDate: {
      get() {
        let effdate = this.$store.state.account.achEffectiveDate;
        let maxachbizDays = this.$store.state.account.maxNoOfAchEffectiveDays;
        var maxtrandate = dayjs(effdate).add(maxachbizDays, "day");
        return maxtrandate.format("YYYY-MM-DD");
      },
    },
    postDate: {
      get() {
        let tranDate = new Date();
        if (this.$store.state.account.allowSameDayACH == true) {
          return tranDate;
        } else {
          if (this.$store.state.account.maxNoOfAchEffectiveDays > 0)
            tranDate = this.maxACHBizDate;
          else tranDate = this.achEffectiveDate;
        }
        return tranDate;
      },
    },
    clsDisableDrawReq() {
      if (this.refuseDraw) {
        return "disabledrawreq";
      } else {
        return "enabledrawreq";
      }
    },
    clsrightcaplimit() {
      if (this.initialDrawAmount >= this.availableCredit) {
        return "right-cap-limit";
      } else {
        return "";
      }
    },
  },
  data() {
    return {
      legal: legal,
      drawRequested: false,
      initialDrawRequested: false,
      errorMsg: null,
      help: help,
      initialDrawAmount: this.$store.state.account.minimumDraw,
      incrementer: 1,
      currencyLong : currencyLong
    };
  },
  mounted(){
 this.focusfirstelement();
  },
  methods: {
    focusfirstelement() {
      document.getElementById("drawmainheader").focus();
    },
    setsliderinterval(e) {
      let clickedElement = e.target,
        max = clickedElement.max,
        val = clickedElement.value;

      let nearest25 = Math.round(parseInt(String(max / 25).split(".")[0])) * 25;

      if (val == nearest25) {
        //clickedElement.step = 1;
        let valued = parseInt(val) + parseInt(this.incrementer == 0 ? 1 : 0);
        val = String(valued);
      }

      if (val != max) {
        if (val > nearest25) {
          val = max;
        } else if (val < nearest25) {
          val = Math.round(val / 25) * 25;
        }
      }
      this.initialDrawAmount = val;
    },

    updateDrawAmount(event) {
      this.errorMsg = null;
      let reqAmount = event.target.value;

      if (reqAmount < this.minimumDraw) {
        reqAmount = this.minimumDraw;
        this.errorMsg = "The minimum draw is $" + this.minimumDraw + ".";
      }

      //validate increment amounts
      if (reqAmount % this.drawIncrement !== 0) {
        reqAmount =
          Math.ceil(reqAmount / this.drawIncrement) * this.drawIncrement;
        this.errorMsg =
          "Draws must be in $" + this.drawIncrement + " increments.";
      }
      if (reqAmount > this.availableCredit) {
        reqAmount = this.availableCredit;
        this.errorMsg = "Your draw amount cannot exceed your available credit.";
      }

      this.drawAmount = reqAmount;
    },
    updateReviewRequest() {
      this.initialDrawRequested = true;
      document.documentElement.scrollTop = 0;
      this.$nextTick(function(){
        this.$refs.drawsel.focus();
       });
    },
    modifyDrawRequest() {
      this.initialDrawRequested = false;
    },
    cancelDrawRequest() {
      this.initialDrawRequested = false;
      this.initialDrawAmount = this.$store.state.account.minimumDraw;
    },
    async postdraw() {
      if (this.errorMsg == null && this.initialDrawAmount > 0) {
        // const res = await this.$store.dispatch('postDrawTransaction', this.initialDrawAmount, );

        //   console.log('post date'+ this.postDate);
        const res = await this.$store.dispatch("postDrawTransaction", {
          tranAmount: this.initialDrawAmount,
          postDate: this.postDate,
        });
        //   console.log('pmt trans id: ' + res.paymentTransactionId);

        if (res != null && res.paymentTransactionId > 0) {
          this.drawRequested = true;
          this.initialDrawRequested = true;
          this.errorMsg = "";

          await this.$store.dispatch("getAccountSummaryInfo");
          this.$store.state.portal.documentsTableData =
            await this.$store.dispatch("getDocumentsTableData");

          document.documentElement.scrollTop = 0;
        } else if (res != null && res.paymentTransactionId == 0) {
          this.drawRequested = true;
          this.errorMsg = res.statusDescription;
        }
      }
    },
    validatestate() {
     if ( this.$store.state.account.state =="WA") {
       return true;
     }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.flexmark{
  display: flex;
  flex-direction: column;
}
.draw-request-modal {
  height: fit-content;

  .del-gray {
    color: $dark-gray;
  }

  #drawmainheader:focus-visible {
    outline: 0px solid #1e4388;
}

  @media (min-width: 1361px) and (max-width: 1365px) {
    width: 105%;
  }
  @media (min-width: 769px) and (max-width: 825px) {
    width: 105%;
  }

  @media (max-width: 768px) {
    height: fit-content;
    padding-left: 20px;
    padding-right: 20px;
  }

  .buttons {
    margin: 30px auto 15px;
    width: fit-content;

    button {
      font-size: 16px;
      margin: 20px 8px 0px 8px;
      background-color: $orange;
      padding: 8px 16px;
      font-weight: 600;
    }

    .button-cancel {
      background-color: $border;
      color: $absolute-white;
      padding: 6px 16px;

      &:hover {
        background: $purple;
      }
    }
  }
  .acsmessage {
    margin: 100px 0 50px;
    font-size: 19px;
    font-weight: bold;
    text-align: center;
    color: $red;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  
  .clsh6 {
    font-family: $font-primary; //$font-secondary;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    text-align: left;
    margin: 0;
  }

  .header {
    padding-bottom: 30px;
    font-size: 26px;

    @media (max-width: 768px) {
      margin-top: -50px;
    }

    .clsh5 {
      font-size: 14px;
      font-weight: 600;

      @media (max-width: 768px) {
        text-align: center;
      }
    }

    .close {
      font-size: 20px;
      color: $gray;
      cursor: pointer;
    }
  }

  .container {
    margin-bottom: 40px;
    @media (max-width: 768px) {
      display: flex;
      flex-flow: column-reverse;
    }
  }

  .left-container {
    width: 70%;
    @media (max-width: 768px) {
      width: 100%;
      justify-content: center;
    }

    .subnote {
      @media (max-width: 768px) {
        text-align: center;
      }
    }
  }

  .right-container {
    float: right;
    text-align: right;
    @media (max-width: 768px) {
      text-align: center;
    }

    .note {
      font-size: 14px;
      color: $blue;
      font-weight: 400;
    }
    .del-gray {
      color: $dark-gray;
    }
  }

  .draw-amount {
    margin: 14px 0px;
    font-family: $font-primary; //$font-secondary;
    font-size: 16px;
    font-weight: 600;

    @media (max-width: 768px) {
      text-align: center;
    }
  }

  .draw-amount-disabled {
    margin: 4px 0px;
    font-family: $gray;
    font-size: 16px;
    font-weight: 600;
  }

  .dollar {
    font-family: $font-primary; //$font-secondary;
    font-size: 28px;
    color: $orange;
    position: relative;
    left: 30px;
    bottom: 2px;
  }
  .dollar-disabled {
    font-family: $gray;
    font-size: 28px;
    color: $orange;
    position: relative;
    left: 30px;
    bottom: 2px;
  }

  .draw-input {
    width: 80px;
    padding: 0 20px;
    border-bottom: 1px solid $gray;
    border-radius: 0px;
    font-family: $font-primary; //$font-secondary;
    font-size: 30px;
    font-weight: 600;
    text-align: right;
    background: $absolute-white;
    color: $blue;
    cursor: default;
  }
  .draw-input-disabled {
    width: 80px;
    padding: 0 20px;
    border-bottom: 1px solid $gray;
    border-radius: 0px;
    font-family: $gray;
    font-size: 30px;
    font-weight: 600;
    text-align: right;
    background: $absolute-white;
    color: $orange;
    cursor: default;
  }
  .subnote {
    font-size: 12px;
    font-weight: 600;
  }

  .legal {
    text-align: justify;
    font-size: 11px;
    line-height: 12px;
    margin: 0 30px;
    // width:400px;
    @media (max-width: 768px) {
      margin-bottom: 10px;
    }
  }
  .blue {
    color: $blue;
    font-weight: 600;
    //font-size: 11px;
  }
  .lightblue {
    color: $light-blue;
    font-weight: 600;
    //font-size: 11px;
  }
  .thankMsg {
    font-size: 12px;
    font-style: italic;
  }

  @media (max-width: 800px) {
    .z-indexAdjust {
      z-index: 4;
    }

    .input-slider-wrapper .left-cap,
    .input-slider-wrapper .right-cap {
      z-index: 3;
    }
  }

  @media (max-width: 768px) {
    .z-indexAdjust {
      position: relative;
    }
  }
  input::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    -webkit-appearance: none;
    box-sizing: content-box;
    border: 1px solid var(--thumb-border-color);
    height: var(--thumb-size);
    width: var(--thumb-size);
    border-radius: var(--thumb-border-radius);
    background-color: #fff;
    cursor: pointer;
    margin: calc((var(--thumb-size) / 2) - var(--thumb-size)) 0 0 0;
  }

  .draw-request {
    align-items: center;

    .cash {
      margin: 20px 0 10px 20px;
    }
    .slidetext {
      text-align: center;
      font-style: italic;
      color: $orange;
      font-weight: 600;
      font-size: 12px;
      padding: 10px 0 0 0;
    }

    .draw-amount {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      width: 60%;
      margin: 0 auto;
      align-self: flex-start;
    }
    .slider {
      width: 100%;
      margin: 20px 0;

      .min,
      .max {
        font-size: 18px;
      }
    }
  }
  .depositamount {
    margin-top: 30px;
    text-align: center;

    .depositamountline {
      margin-left: auto;
      margin-right: auto;
      border-bottom: 1px solid lightgray;
      padding-top: 10px;
      margin-bottom: 10px;
    }

    .cashfeetitle,
    .cashfee {
      font-style: italic;
    }

    .cashfee {
      font-weight: 600;
    }

    .depositamttitle {
      font-weight: 600;
      color: $blue;
      font-size: 16px;

      @media (max-width: 796px) {
        font-size: 15px;
      }
      @media (max-width: 786px) {
        font-size: 14px;
      }
      @media (max-width: 776px) {
        font-size: 13px;
      }
      @media (max-width: 768px) {
        font-size: 16px;
      }
      @media (max-width: 360px) {
        font-size: 15px;
      }
    }

    .depositamt {
      font-weight: 600;
      color: $blue;
      font-size: 18px;
    }

    .cashfeehelpicon,
    .depositamthelpicon,
    .depositamt,
    .cashfee {
      float: right;
    }

    .depositamt,
    .cashfee {
      text-align: right;
    }

    .amountwidth {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
      text-align: left;
      padding: 0 30px;

      @media (max-width: 1270px) {
        margin-left: 0px;
        margin-right: 0px;
        width: 100%;
      }

      @media (max-width: 768px) {
        width: 90%;
      }

      @media (max-width: 420px) {
        width: 100%;
        padding: 0 0px;
      }
    }
  }

  .request-made {
    margin: 0 auto 10px;
    text-align: center;
    height: 300px;
    .thanks {
      font-size: 28px;
      margin-bottom: 4px;
      color: orange;
    }
    .request {
      font-family: $font-primary; // $font-secondary;
    }
  }

  .review {
    margin-top: -40px;
  }

  .initialrequest-made,
  .request-made {
    margin: 0 auto 10px;
    text-align: center;

    h3 {
      margin-top: 60px;
      margin-bottom: 30px;
    }

    .editAmt {
      float: right;
      position: relative;
      font-size: 12px;
      top: 9px;
      left: 13px;
      text-decoration: underline;
      border-bottom: none;

      @media (max-width: 395px) {
        left: 3px;
      }
    }

    .thanks {
      font-size: 28px;
      margin-bottom: 4px;
      color: orange;
    }

    hr {
      width: 280px;
      margin-left: auto;
      margin-right: auto;
    }

    .request {
      font-family: $font-primary; //$font-secondary;
      width: 280px;
      margin-left: auto;
      margin-right: auto;
      text-align: left;
      padding: 5px 0px;
      font-size: 18px;

      @media (max-width: 428px) {
        width: 310px;
      }

      @media (max-width: 395px) {
        width: 296px;
      }

      .orange {
        float: right;
        font-size: 20px;
      }
    }

    .caf {
      font-style: italic;
    }

    .val1 {
      font-size: 18px !important;
    }
    .drawmargin {
      margin-left: 40px;
      @media (max-width: 428px) {
        margin-left: 12px;
      }
      @media (max-width: 380px) {
        margin-left: 10px;
      }
    }

    .val3 {
      font-size: 18px !important;
      font-weight: 600;

      @media (max-width: 428px) {
        font-size: 18px !important;
        font-weight: 600;
        //width:280px;
      }
    }

    .review-section-title {
      font-size: 26px;
      width: 100%;
      font-weight: 700;
      text-align: left;
      @media (max-width: 768px) {
        text-align: left;
      }
    }
  }

  .review .buttons {
    margin-top: 10px;
    margin-bottom: 80px;
  }

  @media (max-width: 1150px) {
    .draw-request {
      .cash {
        margin: 10px 0;
      }
      .buttons {
        button {
          margin: 0 8px;
        }
      }
    }
  }
  .error {
    margin: 10px 0;
    text-align: center;
    color: $error;
  }

  @media (max-width: 1023px) {
    .draw-request {
      .draw-amount {
        width: 200px;
      }
      .buttons {
        button {
          font-size: 16px;
          padding: 12px 12px;
          margin: 0 8px;
        }
      }
    }
    .depositamount {
      .amountwidth {
        .cashfee {
          text-align: left;
          padding-left: 20px;
          color: gray;
          font-weight: bold;
        }
        .depositamt {
          text-align: left;
          font-weight: bold;
          padding-left: 20px;
          font-size: 17px;
          color: $blue;

          @media (max-width: 420px) {
            font-size: 16px;
          }
          @media (max-width: 360px) {
            font-size: 15px;
          }
        }
      }
    }
  }
}

.slider-input {
  width: 60% !important;
  @media (max-width: 1200px) {
    width: 50% !important;
  }
  @media (max-width: 850px) {
    width: 40% !important;
  }
}
</style>


disclaimers.js
export const pagesWithSameDay = [
  'home',
  'how-it-works',
  'faq',
  'esign-consent',
  'portal-account'
];

export const pagesWith3k = [
  'home',
  'how-it-works'
];