export default {
  copyright: '©2023 XactFlex | All rights reserved',
  addressLine1: 'P.O. Box 36454',
  addressLine2: 'Cincinnati, OH 45236-0454',
  physAddressLine1: '7755 Montgomery Road',
  physAddressLine2: 'Cincinnati, OH 45236',
  contactPhone: '1.833.546.2135',
  hours1: '8 a.m. - 9 p.m. Monday-Thursday',
  hours2: '8 a.m. - 8 p.m. Friday',
  hours3: '8 a.m. - 5 p.m. Saturday-Sunday',
  advancedCustomerService: '1.833.793.1152',
  customerServiceEmail: 'info@xactflex.com',
  latePaymentsEmail: 'acs@xactflex.com',
  advancedCustomerServiceEmail: 'ombudsman@xactflex.com',
  customerServicePhone: '1.833.546.2135',
  advancedCustomerServicePhone: '1.833.546.2136',
  contactus_hours1: '8 a.m. - 9 p.m.',
  contactus_days1: 'Monday-Thursday',
  contactus_hours2: '8 a.m. - 8 p.m.',
  contactus_days2: 'Friday',
  contactus_hours3: '8 a.m. - 5 p.m.',
  contactus_days3: 'Saturday-Sunday',

  address_dec:'P.O. Box 965391',
  address_dec2:'Marietta, GA 30066',
  year:'©2023'
}