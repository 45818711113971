export default `
<div id="InterestRates" data-docsection="Interest Rates">
<table border="1" bordercolor="black" cellpadding="2" cellspacing="0" width="100%">
    <tbody>
        <tr>
            <td colspan="4" rowspan="1" align="left" bgcolor="#000000">
                <span style="margin-bottom: 0pt; margin-bottom: 0pt;" align="justify">
                    <font face="Arial" size="3" color=white><b>Fees</b>
                    </font>
                </span>
            </td>
        </tr>
        <tr>
            <td colspan="1" align="top" width="25%">
                <div style="margin-top: 0pt; margin-bottom: 0pt; margin-left: 0pt; margin-right: 0pt;" align="center" class="a">
                    <font face="Arial" size="3"><b>
                     Cash <br>Advance Fee</b> </font>
                </div>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="left">
                For each Cash Advance requested
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="left">
                    If you pay Bi-weekly or Semi-Monthly:
                    <br>
                    <br>
                    <b>5%</b> of the amount of each Cash Advance
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="left">
                    If you pay Monthly:
                    <br>
                    <br>
                    <b>10%</b> of the amount of each Cash Advance
                </p>
            </td>
        </tr>
        <tr>
            <td colspan="4" rowspan="1" align="left" bgcolor="#000000">
                <p style="margin-bottom: 0pt; margin-bottom: 0pt;" align="justify">
                </p>
            </td>
        </tr>
        <tr>
            <td colspan="1" rowspan="50" align="top" width="25%">
                <div style="margin-top: 0pt; margin-bottom: 0pt;" align="center" class="a">
                    <font face="Arial" size="3"><b>
                            Carried Balance <br>Charge</b> </font>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="left">
                    <br>For each Statement that shows a Carried Balance of:
                    <br>
                    <br>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="left">
                    <br>If you pay Bi-weekly or Semi-Monthly:
                    <br>
                    <br>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="left">
                    If you pay Monthly:
                    <br>
                    <br>
                </p>
            </td>
        </tr>
        <tr>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $0.00 - $10.00 </font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $0.00</font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $0.00 </font>
                </p>
            </td>
        </tr>
                           <tr>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $10.01 - $250.00 </font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $5.00</font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $10.00 </font>
                </p>
            </td>
        </tr>
                            <tr>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $250.01 - $500.00 </font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $20.00</font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $40.00 </font>
                </p>
            </td>
        </tr>
                           <tr>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $500.01 - $750.00 </font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $30.00</font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $60.00 </font>
                </p>
            </td>
        </tr>
        <tr>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $750.01 - $1,000.00 </font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $45.00</font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $90.00 </font>
                </p>
            </td>
        </tr>
                           <tr>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $1,000.01 - $1,250.00 </font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $55.00</font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $110.00 </font>
                </p>
            </td>
        </tr>
                            <tr>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $1,250.01 - $1,500.00 </font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $70.00</font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $140.00 </font>
                </p>
            </td>
        </tr>   
                            <tr>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $1,500.01 - $1,750.00 </font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $80.00</font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $160.00 </font>
                </p>
            </td>
        </tr>
                           <tr>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $1,750.01 - $2,000.00 </font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $95.00</font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $190.00 </font>
                </p>
            </td>
        </tr>
                            <tr>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $2,000.01 - $2,250.00 </font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $105.00</font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $210.00 </font>
                </p>
            </td>
        </tr>
                           <tr>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $2,250.01 - $2,500.00 </font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $120.00</font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $240.00 </font>
                </p>
            </td>
        </tr>
                            <tr>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $2,500.01 - $2,750.00 </font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $130.00</font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $260.00 </font>
                </p>
            </td>
        </tr>
                           <tr>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $2,750.01 - $3,000.00 </font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $145.00</font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $290.00 </font>
                </p>
            </td>
        </tr>
                            <tr>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $3,000.01 - $3,250.00 </font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $155.00</font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $310.00 </font>
                </p>
            </td>
        </tr>
                           <tr>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $3,250.01 - $3,500.00 </font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $170.00</font>
                </p>
            </td>
            <td colspan="1" align="left" width="25%">
                <p style="margin-top: 0pt; margin-bottom: 0pt;" align="center">
                    <font face="Arial" size="2">
                            $340.00 </font>
                </p>
            </td>
        </tr>        
    </tbody>
</table>
</div>
`