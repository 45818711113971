const initialState = {
  personalComplete: false,
  addressComplete: false,
  incomeComplete: false,
  payfrequencyComplete: false,
  bankingComplete: false,
  firstName: null,
  lastName: null,
  birthdate: null,
  social: null,
  email: null,
  streetAddress: null,
  apt: null,
  city: null,
  state: null,
  zip: null,
  mainPhone: null,
  cellPhone: null,
  mainIsCell: true,
  source: null,
  secondarySource: "0",
  employerName: null,
  workPhone: null,
  ext: null,
  paymentMethod: null,
  takeHomePay: null,
  paymentInterval: null,
  lastPayDate: null,
  firstPayDate: null,
  secondPayDate: null,
  routing: null,
  account: null,
  repaymentMethod: null,
  finalizeCredit: false,
  confirmEmail: '',
  passwordForReg: null,
  tokenID: null,
  reloadpage:true,
  personalfrombacktabs: false,
  pdLoanRcvdFrom:null
}

import { formatSocSec, formatPhone, formatWorkPhone, formatDate, removeCharacter, fourDigitSSN } from "@/utils/plugins/inputs";
import index from "../api/index";
import {$gMiddleLayerApi} from '../config/appconfigsettings';
import moment from 'moment';
import { defaultLoanAmount, PayBankruptcy, EmplType, JobTitle, WorkShift, Cust18YrsOld, ActiveFlag, LoanType, PayrollActiveFlag, PayGarnishment, BankAccountType, WorkPhone, WorkExt } from "@/config/amounts";
import { uuid } from 'vue-uuid';


export default {
  state: () => {
    return {
      ...initialState
    }
  },
  getters: {},
  mutations: {
    resetApplication(state, fromHomePage) {
      let fname = state.firstName;
      let lname = state.lastName;
      let email = state.email;

      Object.keys(state).forEach(key => {
        if(key != "pdLoanRcvdFrom")
        {
          state[key] = initialState[key];
        }
        
      })

      if (fromHomePage) {
        state["firstName"] = fname;
        state["lastName"] = lname;
        if (email != null || email != undefined)
          state["email"] = email.toLowerCase();
      }
    },
    updatereload(state, value) {
      state.reloadpage = value;
    },
    updatecompletePersonal(state, value) {
      state.personalComplete = value;
    },
    updatecompleteAddress(state, value) {
      state.addressComplete = value;
    },
    updatecompleteIncome(state, value) {
      state.incomeComplete = value;
    },
    updatecompletePayFrequency(state, value) {
      state.payfrequencyComplete = value;
    },
    updatecompleteBanking(state, value) {
      state.bankingComplete = value;
    },
    updateFirstName(state, value) {
      state.firstName = value;
    },
    updateLastName(state, value) {
      state.lastName = value;
    },
    updateBirthdate(state, value) {
      if(value.toString().includes("-")){
        state.birthdate =moment(value,"YYYY-MM-DD").format('MM/DD/YYYY');
        return;
      }
      state.birthdate = value;
    },
    updateSocial(state, value) {
      state.social = formatSocSec(value);
    },
    updatePasswordForReg(state, value) {
      state.passwordForReg = value;
    },
    updateEmail(state, value) {
      state.email = value;
    },
    updateConfirmEmail(state, value) {
      state.confirmEmail = value;
    },
    updateStreetAddress(state, value) {
      state.streetAddress = value;
    },
    updateApt(state, value) {
      state.apt = value;
    },
    updateCity(state, value) {
      state.city = value;
    },
    updateState(state, value) {
      state.state = value;
    },
    updateZip(state, value) {
      state.zip = value;
    },
    updateMainPhone(state, value) {
      state.mainPhone = formatPhone(value);
    },
    updateCellPhone(state, value) {
      state.cellPhone = formatPhone(value);
    },
    updateMainIsCell(state, value) {
      state.mainIsCell = value;
    },
    updateSource(state, value) {
      state.source = value;
    },
    updateSecondarySource(state, value) {
      state.secondarySource = value;
    },
    updateEmployerName(state, value) {
      state.employerName = value;
    },
    updateWorkPhone(state, value) {
      if (value != null) {
        state.workPhone = formatWorkPhone(value);
        if (value.includes("x")) {
          var phoneval = value.split('x');
          state.workPhone = phoneval[0];
          state.ext = phoneval[1];
        }
        else {
          state.ext = "";
        }
      }
    },
    updateExt(state, value) {
      state.ext = value;
    },
    updatePaymentMethod(state, value) {
      state.paymentMethod = value;
    },
    updateTakeHomePay(state, value) {
      state.takeHomePay = (value);
    },
    updatePaymentInterval(state, value) {
      state.paymentInterval = value;
    },
    updateLastPayDate(state, value) {      
      if(value.toString().includes("-")){
        state.lastPayDate =moment(value,"YYYY-MM-DD").format('MM/DD/YYYY');
        return;
      }
      if (!value.toString().includes("GMT")) {
        state.lastPayDate = formatDate(value);
      }
      else {
        state.lastPayDate = (value.getMonth() + 1) + "/" + value.getDate() + "/" + value.getFullYear();
      }
    },
    updateFirstPayDate(state, value) {
      // state.firstPayDate = value;
      if(value.toString().includes("-")){
        state.firstPayDate =moment(value,"YYYY-MM-DD").format('MM/DD/YYYY');
        return;
      }
      if (!value.toString().includes("GMT")) {
        state.firstPayDate = formatDate(value);
      }
      else {
        state.firstPayDate = (value.getMonth() + 1) + "/" + value.getDate() + "/" + value.getFullYear();
      }
    },
    updateSecondPayDate(state, value) {
      // state.secondPayDate = value;
      if(value.toString().includes("-")){
        state.secondPayDate =moment(value,"YYYY-MM-DD").format('MM/DD/YYYY');
        return;
      }
      if (!value.toString().includes("GMT")) {
        state.secondPayDate = formatDate(value);
      }
      else {
        state.secondPayDate = (value.getMonth() + 1) + "/" + value.getDate() + "/" + value.getFullYear();
      }
    },
    updateRouting(state, value) {
      state.routing = value;
    },
    updateAccount(state, value) {
      state.account = value;
    },
    updateRepaymentMethod(state, value) {
      state.repaymentMethod = value;
    },
    updateFinalizeCredit(state, value) {
      state.finalizeCredit = value;
    },
    updateAppToken(state, value) {
      state.tokenID = value;
    },
    updatepersonalfrombacktabs(state, value) {
      state.personalfrombacktabs = value;
    },
    updateGoogleCampaignCode(state, value) {
      state.pdLoanRcvdFrom = value;
    },
  },
  actions: {
    xmlStringToDomXml(xmlString) {
      let parser = new DOMParser();
      return parser.parseFromString(xmlString, "text/xml")
    },
    async postAppBankingData({ state, commit }) {

      //console.log("Starting API Call")
      let apiResponse = await index.postLoanApplication(state)
      let parsedResponse = index.parseXml(apiResponse)
      let responseBody = index.parseXml(parsedResponse.firstChild.firstChild.firstChild.textContent)
      let transactionData = responseBody.firstChild.firstChild.nextSibling.childNodes
      //console.log("API Response Data: " + responseBody.firstChild.firstChild.nextSibling.textContent)

      var approved = false;
      var creditLimit = 0
      var applicationId = ''
      var requestTransactionId = ''
      transactionData.forEach(node => {
        if (node.nodeName === "APPSTATUS") {
          if (node.textContent === "D") {
            // console.log("Loan Rejected")
            approved = false
            return true
          }
          else if (node.textContent === "A") {
            // console.log("Loan Accepted")
            approved = true;
          } else {
            //  console.log("Loan result not found")
            return true
          }
        } else if (node.nodeName === "APPROVEDAMOUNT") {
          creditLimit = parseFloat(node.textContent)
        } else if (node.nodeName === "APPLICATIONID") {
          applicationId = node.textContent
        }
      })
      if (approved) {
        responseBody.firstChild.firstChild.childNodes.forEach(node => {
          if (node.nodeName === "STLTRANSACTIONID") {
            requestTransactionId = node.textContent
            return true
          }
        })
      }

      if (approved) {
        state.bankingComplete = true;
        commit('updateCreditLimit', creditLimit)
        commit('updateLoanRequestTransactionId', requestTransactionId)
        commit('updateApplicationId', applicationId)
        return { approved: true };

      } else {
        state.bankingComplete = true;
        return { approved: false }
      }
    },

    async postRequestForEsigDocuments({ state, commit }) {
      // console.log("Starting API Call")
      //API not working at the moment, use import from config for testing
      let apiResponse = await index.postRequestForEsigDocuments(state)
      //let apiResponse = testApiData.apiResponse
      let parsedResponse = index.parseXml(apiResponse).firstChild
      let statusData = parsedResponse.firstChild.nextSibling.firstChild.nextSibling.firstChild.nextSibling.firstChild.nextSibling
      let documentsData = statusData.nextSibling.nextSibling.firstChild.nextSibling
      commit('updateDocumentID', documentsData.childNodes.item(1).textContent)
      commit('updatedFetchedDocumentTitle', documentsData.childNodes.item(3).textContent)
      commit('updateAppDocEsigCount', documentsData.childNodes.item(7).textContent)
      let documentText = documentsData.childNodes.item(5).textContent
      let documentHTML = documentText.substr(documentText.search("<html>"), documentText.length)
      commit('updateApplicationAgreementDoc', documentHTML)
    },

    async ssnValidate({ state }) {

      var ssn = state.social
      ssn = ssn.replace(/\D/g, "");
      var input = `Account/Validate?custSSN=` + ssn;
      //console.log(input);
      var auth = 'Bearer ' + state.tokenID;
      const response = await fetch( $gMiddleLayerApi + input, {     //for now just testing api hitting endpoint, will need to change this later to accept paths

        "method": "POST",
        "headers": {
          'Content-Type': 'application/json',
          'Authorization': auth
        }
      })

      //console.log(response);

      if (response.ok) {
        const jVal = await response.json(); // Get JSON value from the response body    
        return Promise.resolve(jVal);
      }
    },

    async findIPAddress({ state }) {
      const ipCloudFlareresult = await fetch('https://www.cloudflare.com/cdn-cgi/trace');
      if (ipCloudFlareresult.ok) {
        var ipval = await ipCloudFlareresult.text();
        if (ipval != undefined) {
          state.ip = ipval.substring(ipval.search('ip=') + 3, ipval.search('ts=')).trim();
          state.countryCode = ipval.substring(ipval.search('loc=') + 4, ipval.search('tls=')).trim();
        }
      }
      // console.log(state.ip)
      if (state.ip == undefined) {
        const ipResult = await fetch('https://api.ipify.org?format=json');
        if (ipResult.ok) {
          var val = await ipResult.json();
          state.ip = val.ip;
        }
      }
    },

    async ProcessApplication({ state }) {

      var auth = 'Bearer ' + state.tokenID;
      var ssn = removeCharacter(state.social);
      var mainphone = removeCharacter(state.mainPhone);
      var cellphone = "";
      if (state.mainIsCell)
        cellphone = mainphone;
      
      if (state.cellPhone != null) {
        cellphone = removeCharacter(state.cellPhone);
      }
      var empName = ""; var empType = ""; var phone = WorkPhone; var ext = WorkExt;
      var sourcePriOrSec ="";
      
      if ((state.source == 'P') || (state.source == 'O')) {
        empName = state.employerName;
        empType = EmplType;
        phone = removeCharacter(state.workPhone);
        ext = state.ext;
        sourcePriOrSec = state.source;
      }
      else {
        empName = "Benefits";
        sourcePriOrSec = state.secondarySource;
        
         if (sourcePriOrSec.search("O") != -1)
          {
            sourcePriOrSec ="O"
          }
      }

      var currentdt = moment(new Date()).format('MM/DD/YYYY');
      var defaultRequestedLoanAmount;
      try {
        defaultRequestedLoanAmount = String(defaultLoanAmount);
      } catch {
        defaultRequestedLoanAmount = String(1000);
      }
      var RequestedDueDt = moment().add(3, "days").format("MM/DD/YYYY");
      var BenefitsStartDt = moment().subtract(1, "year").format("MM/DD/YYYY");
      var guid = uuid.v1();
      var input = JSON.stringify({
        TransactionInfo: {
          ExtTransactionID: guid,
          RequestIPAddress: state.ip,
          UserAgent: navigator.userAgent
        },
        ExtTransactionData: {
          Customer: {
            CustSSN: ssn,
            CustFName: state.firstName,
            CustLName: state.lastName,
            CustAddress1: state.streetAddress,
            CustAddress2: state.apt,
            CustCity: state.city,
            CustState: state.state,
            CustZip: state.zip,
            CustHomePhone: mainphone,
            CustWorkPhone:phone,
            CustWorkPhoneExt:ext,
            CustMobilePhone: cellphone,
            CustEmail: state.email,
            CustDOB: state.birthdate,
            Cust18YrsOld: Cust18YrsOld
          },
          Bank: {
            AbaNo: state.routing,
            AcctNo: state.account,
            AcctType: BankAccountType,
            ActiveFlag: ActiveFlag
          },
          Employer: {
            TypeOfIncome: sourcePriOrSec,
            EmployerName: empName,
            BenefitsStartDate: BenefitsStartDt,
            phone: phone,
            phoneExt: ext,
            EmplType: empType,
            JobTitle: JobTitle,
            WorkShift: WorkShift,
            AvgSalary: String(state.takeHomePay),
            TypeOfPayroll: state.paymentMethod,
            Periodicity: state.paymentInterval,
            PayGarnishment: PayGarnishment,
            PayBankruptcy: String(PayBankruptcy),
            NextPayDate: state.firstPayDate,
            LastPayDate: state.lastPayDate,
            SecondPayDate: state.secondPayDate,
            PayrollActiveFlag: PayrollActiveFlag
          },
          Application: {
            RequestedAmount: defaultRequestedLoanAmount,
            RequestedDueDate: RequestedDueDt,
            ApplicationDate: currentdt,
            LoanType: LoanType,
            PaymentMethod: state.repaymentMethod,
            pdLoanRcvdFrom: state.pdLoanRcvdFrom
          }
        }
      });
      //  console.log(auth);
      //console.log(input);      
      var url = $gMiddleLayerApi + `Account/ProcessApplication`;
      const processResponse = await fetch(url, {     //for now just testing api hitting endpoint, will need to change this later to accept paths        
        "method": "POST",
        "body": input,
        "headers": {
          'Content-Type': 'application/json',
          'Authorization': auth
        }
      })
      //console.log(processResponse);
      if (processResponse.ok) {
        const jVal = await processResponse.json(); // Get JSON value from the response body              
        return Promise.resolve(jVal);
      }
    },

    async createPasswordAfterApproval({ state }) {
      //alert("Enter in create Password after approval flow."); 
      var auth = 'Bearer ' + state.token;
      // alert("Merchant ID : " +  state.merchantId);                
      // alert("StatePassword : " + state.passwordForReg);                   
      var fourdigitssn = fourDigitSSN(state.social);
      //alert("fourdigitssn :" + fourdigitssn);

      var date = new Date(state.birthdate);
      var birthdt = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
      var input = JSON.stringify({
        email: state.email,
        password: state.passwordForReg,
        confirmPassword: state.passwordForReg,
        userName: state.email,
        custSSN: fourdigitssn,
        dob: birthdt
      })

      //console.log(input);
      try {
        const loginResponse = await fetch( $gMiddleLayerApi + `Auth/Register`, {     //for now just testing api hitting endpoint, will need to change this later to accept paths
          "method": "POST",
          "body": input,
          "headers": {
            'Content-Type': 'application/json',
            'Authorization': auth
          }
        })
        //console.log(loginResponse);
        if (loginResponse.ok) {
          const jVal = await loginResponse.json(); // Get JSON value from the response body      
          return Promise.resolve(jVal);
        }
        else
          return Promise.reject('Call failed');
      } catch (ex) { //console.log(ex);
      }


    },

    async updateApplicationToWithdraw({ state }, reason) {
      var input = JSON.stringify({voidReason: reason});

      //console.log(input);
      var auth = "Bearer " + state.tokenID;
      try{
      const response = await fetch(
        $gMiddleLayerApi + "Applications/void",
        {
          method: "POST",
          body: input,
          headers: {
            "Content-Type": "application/json",
            Authorization: auth,
          },
        }
      );
      //   console.log(response);
      if (response.ok) {
        const jVal = await response.json(); // Get JSON value from the response body
        //console.log(jVal);      
        
        if(jVal.status)
        {
        return Promise.resolve(jVal);
        }
        else{
          return Promise.reject("Last application is not in pending state");
        }
      } else {
        return Promise.reject("Failed to update status.");
      }
    } catch (error) {
      return Promise.reject("Failed to update status.");
    }
    },
  }
}