<template>
  <div class="interruptorcontentsection">
     <p class="title"><slot name="title"></slot></p>
    <i class="fas fa-exclamation-triangle icon"></i>
    <p class="para1"><slot name="para1"></slot></p>
    <p class="para2"><slot name="para2"></slot></p>
    <p class="para3"><slot name="para3"></slot></p>
    <slot name="button1"></slot>
  </div>
</template>

<script>
export default {
  name: "PortalInterrupter",
};
</script>

<style lang="scss" scoped>
.button-float{
  font-weight: 500 !important;
}

.interruptorcontentsection {
  text-align: center;
  padding: 6% 8% !important;
  margin: 5% 0% !important;
  max-width: 700px !important;

  @media (max-width:767px) {
    padding: 6% 6% !important;
    margin: 10% 5% !important;
  }
}

.icon {
  font-size: 50pt;
  color: $red;
  margin-bottom: 15px;
}
.title {
  color: #1e4488;
  margin-top: 0;
  margin-bottom: 30px;
  width: 100%;
  font-size: 18pt;
  font-weight: 700;
  @media (max-width: 767px) {
    margin-top: 0px;
  }
}
.para1 {
  color: #1e4488;
  margin-top: 15px;
  margin-bottom: 0;
  width: 100%;
  font-size: 18pt;
  font-weight: 700;
  @media (max-width: 767px) {
    margin-top: 5px;
  }
}

.para2 {
  font-size: 14pt;
  font-weight: 600;
  margin-bottom: 10px;
  opacity: 0.8;
  color: $gray;
}
.para3 {
  font-size: 13pt;
  font-weight: 600;
  margin-top: 0;
  opacity: 0.8;
  color: $gray;

  a {
    text-decoration: none;
    border-bottom: 1px solid gray !important;
  }

  a:hover {
    color: $gray !important;
  }
}
</style>