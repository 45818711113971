<template>
  <div class="stepper-wrapper">
    <div  
      :class="
        this.navselected == 1
          ? 'stepper-item active first'
          : 'stepper-item completed first'
      "
      @click="back('Personal')"
      @keypress.enter="back('Personal')"
    >
      <div class="step-name" id="divpersonal" role="navigation" aria-label="aplication steps"  :tabindex="this.navselected > 1? '0':'-1'" >PERSONAL</div>
      <div class="step-counter"></div>
    </div>
    <div 
      :class="
        this.navselected == 2
          ? 'stepper-item active'
          : this.navselected > 2
          ? 'stepper-item completed' 
          : 'stepper-item'
      "
      @click="back('Address')"
      @keypress.enter="back('Address')"
    >
      <div class="step-name" id="divaddress" role="navigation" aria-label="aplication steps"  :tabindex="this.navselected > 2? '0':'-1'" >ADDRESS</div>
      <div class="step-counter"></div>
    </div>
    <div
      :class="
        this.navselected == 3
          ? 'stepper-item active'
          : this.navselected > 3
          ? 'stepper-item completed income'
          : 'stepper-item'
      "
      @click="back('Income')"
      @keypress.enter="back('Income')"
    >
      <div class="step-name" id="divincome" role="navigation" aria-label="aplication steps"  :tabindex="this.navselected > 3? '0':'-1'"  >INCOME</div>
      <div class="step-counter"></div>
    </div>
    <div
      :class="
        this.navselected == 4
          ? 'stepper-item active seclast'
          : this.navselected > 4
          ? 'stepper-item completed seclast'
          : 'stepper-item seclast'
      "
      @click="back('PayFrequency')"
      @keypress.enter="back('PayFrequency')"
    >
      <div class="step-name" id="divpayfrequency" role="navigation" aria-label="aplication steps"  :tabindex="this.navselected > 4? '0':'-1'">PAY FREQUENCY</div>
      <div class="step-counter"></div>
    </div>
    <div
      :class="
        this.navselected == 5 ? 'stepper-item active last' : 'stepper-item last'
      "
      @click="back('Banking')"
      @keypress.enter="back('Banking')"
    >
      <div class="step-name" id="divbanking" role="navigation" aria-label="aplication steps"  :tabindex="this.navselected > 5? '0':'-1'" >BANKING</div>
      <div class="step-counter"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppNav",
  props: ["navselected"],

  methods: {
    back(link) {
      if (
        link == "Personal" &&
        this.$store.state.application.personalComplete
      ) {
        this.$store.commit("updatecompletePersonal", false);
        this.$store.commit("updatecompleteAddress", false);
        this.$store.commit("updatecompleteIncome", false);
        this.$store.commit("updatecompletePayFrequency", false);
        this.$store.commit("updatecompleteBanking", false);
        this.$store.commit("updatepersonalfrombacktabs", true);
        this.$router.push({ name: "app-personal" });
      }
      if (link == "Address" && this.$store.state.application.addressComplete) {
        this.$store.commit("updatecompleteAddress", false);
        this.$store.commit("updatecompleteIncome", false);
        this.$store.commit("updatecompletePayFrequency", false);
        this.$store.commit("updatecompleteBanking", false);
        this.$router.push({ name: "app-address" });
      }
      if (link == "Income" && this.$store.state.application.incomeComplete) {
        this.$store.commit("updatecompleteIncome", false);
        this.$store.commit("updatecompletePayFrequency", false);
        this.$store.commit("updatecompleteBanking", false);
        this.$router.push({ name: "app-income" });
      }
      if (
        link == "PayFrequency" &&
        this.$store.state.application.payfrequencyComplete
      ) {
        this.$store.commit("updatecompletePayFrequency", false);
        this.$store.commit("updatecompleteBanking", false);
        this.$router.push({ name: "app-payfrequency" });
      }
      if (link == "Banking" && this.$store.state.application.bankingComplete) {
        this.$store.commit("updatecompleteBanking", false);
        this.$router.push({ name: "app-banking" });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.stepper-wrapper {
  margin-top: 50px;
  display: inline-flex;
  justify-content: space-between;
  margin-bottom: 0px;
  margin-left: -15px;
  width: 56%;

  //firefox
  @-moz-document url-prefix() {
    width: 58% !important;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 75%;
     margin-left: 0px;
  }
  @media (max-width: 767px) {
    width: 100% !important;
    margin-left: -20px;
  }

  @media (max-width: 450px) {
    margin-left: 0px;
  }
}
.stepper-item {
  font-size: 14px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  color: $orange;
  font-weight: 600;
  flex-wrap: wrap;
  align-content: space-evenly;

  @media (max-width: 450px) {
    font-size: 11px;
    flex: none;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid $light-gray;
  width: 100%;
  top: 20px;
  left: -20%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid $light-gray;
  opacity: 100%;
  //opacity: 0.7;
  width: 100%;
  top: 20px;
  left: 80%;
  z-index: 2;
}

.stepper-item.active .step-counter {
  color: $white;
  position: relative;
  z-index: 5;
  top: -1px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: $gray;
  margin-bottom: 6px;
  left: 22%;
  @media (max-width: 450px) {
    top: 2px;
    left: 40%;
  }
}

.stepper-item.active,
.stepper-item.completed {
  font-weight: bold;
  .step-name {
    color:  $blue;
    opacity: 100%;
  }
}

.step-name {
  color: $gray;
  opacity: 0.7;
}

.stepper-item.completed .step-counter {
  background-color: $blue;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid $blue;
  width: 133%;
  top: 20px;
  left: 20%;
  z-index: 3;
  opacity: 100%;
  @media (max-width: 450px) {
    width: 155%;
  }

  @media (max-width: 380px) {
    width: 130%;
  }
}

.stepper-item.active.first::after {
  left: 30%;
  opacity: 100%;
}
.stepper-item.completed.seclast::after {
  width: 160%;
  @media (max-width: 767px) {
    width: 140%;
  }
  @media (max-width: 380px) {
    width: 130%;
  }
}
.stepper-item.completed.income::after {
  @media (max-width: 450px) {
    width: 225%;
  }
  @media (max-width: 380px) {
    width: 200%;
  }
}

.stepper-item.completed {
  cursor: pointer;
}

.stepper-item.active.last .step-counter {
  left: 50%;

  @media (max-width: 450px) {
    left: 80%;
  }
}

.stepper-item.active.seclast .step-counter {
  left: 48%;
}

.stepper-item:first-child::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid  $blue;
  width: 30%;
  top: 20px;
  left: 20%;
  z-index: 3;
  @media (max-width: 450px) {
    left: 5%;
    width: 38%;
  }
}

.stepper-item.active.first .step-counter {
  left: 30%;
  @media (max-width: 450px) {
    left: 44%;
  }
}

.stepper-item:last-child::after {
  content: none;
}
</style>