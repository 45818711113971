<template>
  <main>

    <PageHeader>
      Line Of Credit Basics
      <template v-slot:subtitle>
       <span STYLE="text-transform:none">
        NO NEED TO JUMP THROUGH HOOPS.<br>
        WE KEEP IT SIMPLE.
        </span>
      </template>
      <template v-slot:content>
        <div class="reqs">
          <div class="pretext">
            If you:
          </div>
          <div class="req">
            <img src="../../assets/icons/person.svg" alt="18 Years Old">
            <div class="desc">
              Are at least 18 years old
            </div>
          </div>
          <div class="req">
            <img src="../../assets/icons/flag.svg" alt="US Resident">
            <div class="desc">
              Are a U.S. resident
            </div>
          </div>
          <div class="req">
            <img src="../../assets/icons/cash-white.svg" alt="Steady Income">
            <div class="desc">
              Have a steady income and
            </div>
          </div>
          <div class="req">
            <img src="../../assets/icons/bank.svg" alt="Bank Account">
            <div class="desc">
              Have a bank account
            </div>
          </div>
        </div>
        <div class="apply">
          <button  :disabled="true" class="button-purple hide-small" @click="applyNow();">
            Apply Now
          </button>
          <div class="desc">
            Then just complete our user-friendly online application to see if you qualify.
            If approved, you can request your first draw right away if you need funds now.
          </div>
        </div>
      </template>
    </PageHeader>

    <section>
      <div class="top">
        <div class="mr-happy"/>
        <div class="title">
          Here's How It Works
          <div class="subhead">
            <h2>
            EXPERIENCE THE COMFORT OF EASY ACCESS TO FUNDS WITH A LINE OF CREDIT UP TO
            {{ currencyShort(maximumCreditAmount) }}.<sup class="small">†</sup>
            </h2>
          </div>
        </div>
      </div>
      <div class="steps hide-large">
        <div class="step">
          <img src="../../assets/icons/complete.svg" alt="Complete">
          <div class="desc" style="cursor: pointer;">
            Take a moment to complete our simple, user-friendly online application.
          </div>
        </div>
        <div class="step" >
          <img src="../../assets/icons/review.svg" alt="Review">
          <div class="desc"  style="cursor: pointer;">
            If approved, review and eSign your agreement and confirm your info with Customer Service.
          </div>
        </div>
        <div class="step">
          <img src="../../assets/icons/transaction.svg" alt="Request a Draw">
          <div class="desc" style="cursor: pointer;">
            Request a draw by phone or through our secure online portal. See funds in your account as soon as today.
            <sup>*</sup>
          </div>
        </div>
        <div class="step">
          <img src="../../assets/icons/request.svg" alt="Keep Up">
          <div class="desc"  style="cursor: pointer;">
            Keep up with your minimum payments and draw again whenever you need funds, up to your limit.
          </div>
        </div>
        <img src="../../assets/slides/HIWslide4.png" alt="Application Sample" class="sample">
      </div>
    </section>

    <section class="carousel-wrapper">
      <nav>
        <div class="slider-nav" :class="currentSlide === 0 ? 'active' : null" @click="currentSlide = 0">
          <div class="icon">
            <img src="../../assets/icons/complete.svg" draggable="false">
          </div>
          <div class="text" style="cursor: pointer;">
            Take a moment to complete our simple, user-friendly online application.
          </div>
        </div>
        <div class="slider-nav" :class="currentSlide === 1 ? 'active' : null" @click="currentSlide = 1">
          <div class="icon">
            <img src="../../assets/icons/review.svg" draggable="false">
          </div>
          <div class="text" style="cursor: pointer;">
            If approved, review and eSign your agreement and confirm your info with Customer Service.
          </div>
        </div>
        <div class="slider-nav"  :class="currentSlide === 2 ? 'active' : null" @click="currentSlide = 2">
          <div class="icon">
            <img src="../../assets/icons/transaction.svg" draggable="false">
          </div>
          <div class="text" style="cursor: pointer;">
            Request a draw by phone or through our secure online portal. See funds in your account as soon as today.*
          </div>
        </div>
        <div class="slider-nav" :class="currentSlide === 3 ? 'active' : null" @click="currentSlide = 3">
          <div class="icon">
            <img src="../../assets/icons/request.svg" draggable="false">
          </div>
          <div class="text" style="cursor: pointer;">
            Keep up with your minimum payments and draw again whenever you need funds, up to your limit.
          </div>
        </div>
      </nav>
      <Carousel
          :per-page="1"
          :navigation-enabled="false"
          :pagination-enabled="false"
          :value="currentSlide"
          :touch-drag="false"
          :mouse-drag="false"
      >
        <slide>
          <img src="../../assets/slides/HIWslide1.png" draggable="false">
        </slide>
        <slide>
          <img src="../../assets/slides/HIWslide2.png" draggable="false">
        </slide>
        <slide>
          <img src="../../assets/slides/HIWslide3.png" draggable="false">
        </slide>
        <slide>
          <img src="../../assets/slides/HIWslide4.png" draggable="false">
        </slide>
      </Carousel>
    </section>

    <ApplyNowPill/>

  </main>
</template>

<script>
import { PageHeader, ApplyNowPill } from '@/components';
import { maximumCreditAmount } from "@/config/amounts";
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: 'HowItWorks',
  title: 'How it Works',
  components: { PageHeader, ApplyNowPill, Carousel, Slide },
  created () {
    window.addEventListener('scroll', this.startAnimation);
  },
  unmounted () {
    window.removeEventListener('scroll', this.startAnimation);
  },
  data() {
    return {
      maximumCreditAmount: maximumCreditAmount,
      currentSlide: 0
    }
  },
  methods:
  {
     applyNow()
       {
          this.$store.commit('resetApplication');
          this.$router.push({ name: 'app-about-you' });
          return true;
       },
  }
}
</script>

<style scoped lang="scss">
main {
  width: 100%;
  
   .subhead {
          font-size: 18px;            
        border-bottom: 1px solid $green;
        width: 300px;
        padding: 0 0 6px 0;
      
        }

  .page-header {

    .reqs {
      width: 100%;
      max-width: 1124px;
      margin: 60px auto;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: flex-start;

      .pretext {
        font-size: 22px;
        font-style: italic;
        font-weight: 800;
        margin: 0 20px 0 10px;
        flex: 0 0 75px;
      }

      .req {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        width: 240px;
        margin-right: 30px;

        .desc {
          font-size: 18px;
          padding-left: 16px;
        }
      }
    }

    .apply {
      max-width: 1100px;
      margin: 0 auto 140px auto;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      button {
        padding: 10px;
        font-size: 24px;
        flex: 0 0 300px;
        display: table-cell;
        vertical-align: middle;
        margin: 0 60px 0 10px;
      }

      .desc {
        font-size: 22px;
        flex: 0 1 800px;
        margin-right: 100px;
      }
    }
  }

  .top {
    position: relative;
    display: flex;
    flex-flow: row;
    margin-bottom: -100px;

    .mr-happy {
      position: relative;
      top: -90px;
      flex: 0 0 659px;
      height: 400px;
      background-image: url('../../assets/backgrounds/howitworks-bg.png');
      background-repeat: no-repeat;
      background-size: contain;
    }

    .title {
      font-size: 38px;
      margin: 90px 0 0 60px;

      .underline {
        border-bottom: 1px solid $green;
        width: 500px;
        padding: 10px 0 6px 0;
      }

      .small {
        font-size: 10px;
      }
    }
  }

  .carousel-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    nav {
      flex: 0 0 40%;

      .slider-nav {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        margin: 20px 0;
        opacity: .5;

        .icon {
          margin-right: 30px;

          img {
            width: 80px;
          }
        }

        .text {
          font-size: 20px;
        }

        &.active {
          opacity: 1;
        }
      }
    }
    .VueCarousel {
      width: 700px;
    }
  }

  @media (max-width: 1323px) {

    .page-header {

      .reqs {
        margin: 30px auto;
      }

      .apply {
        margin: 0 auto 66px auto;

        .desc {
          font-size: 20px;
        }
      }
    }

    .top {

      .mr-happy {
        flex: 0 0 500px;
      }

      .title {
        margin-left: auto;
        margin-right: 10px;
      }
    }

    .carousel-wrapper {

      nav {
        margin-left: 10px;
        .slider-nav {
          .icon {
            margin-right: 20px;
            img {
              width: 50px;
            }
          }
          .text {
            font-size: 16px;
          }
        }
      }
    }
  }

  @media (max-width: 1023px) {
    .page-header {

      .reqs {
        flex-flow: row wrap;
        margin: 20px auto;
        width: 350px;

        .pretext {
          flex: 1 0 90%;
          font-size: 18px;
          margin-bottom: 14px;
        }

        .req {
          flex: 1 1 160px;
          margin: 6px;

          img {
            width: 60px;
          }

          .desc {
            font-size: 14px;
          }
        }
      }

      .apply {
        margin: 0 auto 10px auto;

        button {
          display: none;
        }

        .desc {
          flex: 0 1 600px;
          font-size: 18px;
          margin: 0 auto;
          padding: 10px;
        }
      }
    }

    .top {
      margin-bottom: 20px;
      flex-flow: column;

      .mr-happy {
        top: 0;
        background-size: cover;
        background-position: 70% 0;
      }

      .title {
        font-size: 30px;
        margin: 20px;

        .underline {
          width: initial;
          max-width: 800px;
        }
      }
    }

    .steps {
      display: flex;
      flex-flow: column nowrap;

      .step {
        display: flex;
        flex-flow: row nowrap;
        padding: 0 10px;
        margin: 20px 0;

        img {
          width: 80px;
        }

        .desc {
          font-size: 18px;
          margin-left: 20px;
        }
      }

      .sample {
        margin: 20px auto;
        width: 340px;
      }
    }

    .carousel-wrapper {
      display: none;
    }
  }
}
</style>