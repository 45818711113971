<template>
  <div class="bgcontainer">
    <div class="bglayout">
      <div class="helloAcct">
        <div class="clsh6">Welcome back, {{ firstName }}</div>
      </div>
      <main class="portal-wrapper">
        <PortalNav page="preferences" />
        <PortalLoader />
        <div class="portal cardPortal card">
          <PortalMessage class="hide-large" page="preferences" />
          <div class="card">
            <div class="header">
              <div class="header-section">
                <h1 class="visually-hidden">Your Account</h1>
                <SectionTitle id="prefPageTitle" tabindex="-1">
                  <template v-slot:pagetitle> Preferences </template>
                  <template v-slot:subtitle>
                    <div class="clsh3 sub-title">
                      Tell us how you want to communicate
                    </div>
                    <div class="head-description">
                      <div class="clsh6">
                        <span
                          >Stay up to date with
                          <span style="white-space: nowrap" class="XACT-font">
                            <span id="xact" style="font-style: normal"
                              >Xact</span
                            >
                            <span id="flex">
                              <i>Flex</i>
                            </span>
                            <sup
                              style="
                                font-size: 5px;
                                margin-left: 1px;
                                font-weight: 500;
                              "
                              ><span class="trademark">&reg;</span></sup
                            > </span
                          >. Pick your preferences below.
                        </span>
                      </div>
                    </div>
                  </template>
                </SectionTitle>
              </div>
              <div class="button-save-container">
                <button
                  class="button-save"
                  :aria-disabled="!readyForSave"
                  @click="saveChanges('PREFSAVETOP')"
                  id="btnSaveChangesTop"
                >
                  SAVE CHANGES
                </button>
              </div>
            </div>
            <div></div>

            <div class="preference bar">
              <fieldset>
                <div class="row">
                  <legend class="left subheader">Email Marketing</legend>
                  <div class="right space">
                    <div class="toggle-container">
                      <label
                        for="lbltogglebuttonemail"
                        class="toggle-text"
                        aria-label="Get the latest info & offers"
                        >Get the latest info & offers</label
                      >

                      <div class="switchToggle">
                        <label
                          id="lbltoggleemail"
                          class="switch"
                          for="lbltogglebuttonemail"
                          tabindex="0"
                          @keydown.enter="
                            toggleMarketingEmail('lbltogglebuttonemail')
                          "
                        >
                          <input
                            tabindex="-1"
                            id="lbltogglebuttonemail"
                            type="checkbox"
                            @click="
                              toggleMarketingEmail('lbltogglebuttonemail')
                            "
                          />
                          <div class="slider round"></div>
                        </label>
                        <span
                          :class="
                            changeMarketingEmails
                              ? 'switch-left'
                              : 'switch-right'
                          "
                          >{{ changeMarketingEmails ? "YES" : "NO" }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <label for="email" class="emaillabel"
                  >Email name@example.com</label
                >
                <div class="row row-mobile">
                  <div class="left">
                    <input
                      id="email"
                      type="text"
                      v-model="email"
                      aria-label="Email"
                      v-on:blur="validateEmptyEmail(email)"
                      aria-describedby="erroremailformat erroremailfieldblank"
                    />
                    <div class="icon">
                      <br />
                      <span
                        id="erroremailformat"
                        class="email-validation"
                        v-if="!validateEmail(email)"
                      >
                        Please enter your email address in this format:
                        name@example.com
                      </span>
                      <span
                        id="erroremailfieldblank"
                        class="email-validation"
                        v-if="validateEmail(email) && alertEmailMsg"
                      >
                        You must have an email address for your account. If you
                        want to opt out of marketing emails, select “No.”
                      </span>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>

            <div class="preference phone-preference">
              <fieldset>
                <div class="row">
                  <legend class="subheader left">
                    Phone Calls and Text Messaging
                  </legend>
                  <div class="right space">
                    <div class="toggle-container">
                      <label class="toggle-text" for="lbltogglebuttoncall"
                        >Marketing</label
                      >

                      <div class="switchToggle">
                        <label
                          id="lbltogglecall"
                          class="switch"
                          tabindex="0"
                          @keydown.enter="
                            toggleMarketingCalls('lbltogglebuttoncall')
                          "
                        >
                          <input
                            tabindex="-1"
                            id="lbltogglebuttoncall"
                            type="checkbox"
                            @click="toggleMarketingCalls('lbltogglebuttoncall')"
                          />
                          <div class="slider round"></div>
                        </label>
                        <span
                          :class="
                            changeMarketingCalls
                              ? 'switch-left'
                              : 'switch-right'
                          "
                          >{{ changeMarketingCalls ? "YES" : "NO" }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="left sidespace">
                    <div class="phone-container">
                      <div class="mainphonelabel">
                        <div class="input-checkbox-wrapper">
                          <label for="mainphone" style="margin-left: -30px">
                            Main ###-###-####</label
                          >
                          <span style="margin-left: 40px"
                            ><label id="checkboxPreference" for="chkMyCell">
                              This is my cell</label
                            ></span
                          >
                          <input
                            id="chkMyCell"
                            type="checkbox"
                            v-model="mainIsCell"
                            @click="toggleMainIsCellPhone"
                            tabindex="-1"
                          />
                          <span
                            role="checkbox"
                            class="input-checkbox input-checkbox-sm"
                            aria-describedby="checkboxPreference"
                            tabindex="0"
                            @click="toggleMainIsCellPhone"
                            aria-label="This is my cell"
                            :aria-checked="mainIsCell"
                          />
                        </div>
                        <div>
                          <input
                            id="mainphone"
                            v-model="mainPhone"
                            class="phone"
                            maxLength="12"
                            type="text"
                            aria-describedby="main-phone-errormsg"
                            :class="[
                              !validatePhone(mainPhone) ? 'redBorder' : '',
                            ]"
                          />
                          <div class="icon phone-icon">
                            <span
                              id="main-phone-errormsg"
                              class="phone-validation"
                              v-if="!validatePhone(mainPhone)"
                            >
                              <br />Please enter your 10-digit main phone number
                            </span>
                          </div>
                        </div>
                      </div>

                      <div style="display: flex">
                        <div class="cellphonelabel" v-if="!mainIsCell">
                          <label for="cellPhone" style="padding-left: 8px"
                            >Cell ###-###-####</label
                          ><br />
                          <div style="margin-top: 4px; margin-left: 0px">
                            <input
                              id="cellPhone"
                              v-model="cellPhone"
                              class="phone"
                              maxLength="12"
                              type="text"
                              aria-describedby="cell-phone-errormsg"
                              :class="[
                                cellPhone.length > 0 &&
                                !validatePhone(cellPhone)
                                  ? 'redBorder'
                                  : '',
                              ]"
                            />
                            <div class="icon phone-icon">
                              <span
                                id="cell-phone-errormsg"
                                class="phone-validation"
                                v-if="
                                  cellPhone.length > 0 &&
                                  !validatePhone(cellPhone)
                                "
                              >
                                <br />Please enter your 10-digit cell phone
                                number
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="leftside">
                    Yes, I hereby expressly consent to recurring contact from
                    XactFlex or its business partners at the number(s) I
                    provided regarding products or services via live, automated,
                    or prerecorded telephone calls or text messages. I
                    understand that my telephone company may impose charges on
                    me for these contacts, and I am not required to enter this
                    agreement as a condition of receiving a line of credit. I
                    understand that I can revoke this consent at any time.
                    <br /><br /><br />
                    <b
                      >I agree to the
                      <a
                        class="doclink"
                        id="lnkTermsCondition"
                        @click="openDocument('tc')"
                        tabindex="0"
                        @keypress.enter="openDocument('tc')"
                        @keydown.space="openDocumentKeySpace('tc', $event)"
                        >Terms and Conditions</a
                      >
                      and
                      <a
                        class="doclink"
                        id="lnkPrivacyPolicy"
                        @click="openDocument('privacy')"
                        @keypress.enter="openDocument('privacy')"
                        @keydown.space="openDocumentKeySpace('privacy', $event)"
                        tabindex="0"
                        >Privacy Policy</a
                      >.
                    </b>
                    <br /><br />
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="row">
              <div class="legal">{{ legal.textFrequency }}</div>
              <div class="button-save-container btnspace">
                <button
                  class="button-save"
                  :aria-disabled="!readyForSave"
                  @click="saveChanges('PREFSAVEBOTTOM')"
                  id="btnSaveChangesBottom"
                >
                  SAVE CHANGES
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="preference-modal-wrapper"
          v-if="showWarningModal"
          @click="closeWarningModal"
          aria-modal="true"
          role="alertdialog"
          aria-describedby="preference-modal-title"
        >
          <div class="preference-modal" @click.stop="">
            <div class="title" id="preference-modal-title">Are you sure?</div>
            <div class="subnote">
              {{ warningText }}
            </div>
            <div class="options">
              <div class="option" @click="closeWarningModal">
                <button
                  class="buttonaccept"
                  id="btnMktEmailsYes"
                  @keydown.shift.tab="focuspreviouseleinpopup($event)"
                >
                  STAY INFORMED
                </button>
              </div>
              <div class="option" @click="acceptChange">
                <button
                  class="buttonDeny"
                  id="btnMktEmailsNo"
                  @keydown.tab="focusnexteleinpopup($event)"
                >
                  OPT ME OUT
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="preference-modal-wrapper"
          v-if="showMarketingModal"
          @click="showMarketingModal = false"
        >
          <div class="preference-modal" @click.stop="">
            <div class="title">Wait.</div>
            <div class="subnote">Please choose your preference for</div>
            <div class="options">
              <div class="option" @click="acceptMarketingCallsChange">
                <button class="buttonaccept">MARKETING CALLS</button>
              </div>
              <div class="option" @click="acceptMarketingTextsChange">
                <button class="buttonaccept">TEXT MESSAGES</button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="preference-modal-wrapper"
          v-if="showSaveModal"
          aria-modal="true"
          role="alertdialog"
          aria-describedby="save-change-title"
        >
          <div class="preference-modal" @click.stop="">
            <div class="title" id="save-change-title">
              Please Save Your Changes
            </div>
            <div class="subnote">
              Wait. You have unsaved changes. To keep any changes you made,
              select SAVE CHANGES.
            </div>
            <div class="options">
              <div class="option" @click="saveChanges('')">
                <button
                  class="buttonaccept"
                  id="btnDialogSave"
                  @keydown.tab="focuscanceleleinpopup($event)"
                >
                  SAVE
                </button>
              </div>
              <div class="option" @click="cancelChanges">
                <button
                  class="buttonDeny"
                  @keydown.tab="focussaveleinpopup($event)"
                  id="btnDialogCancel"
                >
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        </div>

        <PrefSaveConfirm
          :showmessageFor="showemailsavemessage"
          :isCellBlank="isCellNoBlank"
          :invokedFrom="prefConfPopupCalledFrom"
        />
      </main>
    </div>
  </div>
</template>

<script>
import legal from "@/config/text/legal";
import preferences from "@/config/text/preferences";
/*import { lvxScript,
          lvxURL } from "@/config/amounts";*/

import {
  PortalNav,
  PortalMessage,
  // PortalDidYouKnow,
  SectionTitle,
  PrefSaveConfirm,
  PortalLoader,
  // XactFlexBrandedText
} from "@/components";

export default {
  name: "PortalPreferences",
  title: "Select Your Account Preferences",
  components: {
    PortalNav,
    PortalMessage,
    SectionTitle,
    PrefSaveConfirm,
    PortalLoader,
  },

  created() {
    this.changeEmail = this.email;
    this.changeMainPhone = this.mainPhone;
    this.changeCellPhone = this.cellPhone;
    this.changeMarketingEmails = this.marketingEmails;
    this.changeMarketingCalls = this.marketingCalls;
    this.showSaveEmailPhonePopup = false;

    //this.changeMarketingTexts = this.marketingTexts;
    //this.changeServiceTexts = this.serviceTexts;

    /*if (document.head.children["lvxChatScript"] == null && lvxScript != null && lvxScript != "") {
          let startScript = document.createElement('script');
          startScript.setAttribute('src', lvxURL);
          startScript.setAttribute('id', 'lvxChatInclude');
          startScript.onload = () => {
            let widgetScript = document.createElement('script');
            widgetScript.setAttribute('id', 'lvxChatScript');
            widgetScript.append(lvxScript);
            document.head.appendChild(widgetScript);
          };
          document.head.appendChild(startScript);
        }*/
  },
  mounted() {
    this.focusfirstpageelementwhenlanded();

    if (this.changeMarketingCalls) {
      document.getElementById("lbltogglebuttoncall").checked = true;
    }
    if (this.changeMarketingEmails) {
      document.getElementById("lbltogglebuttonemail").checked = true;
    }
  },
  beforeRouteLeave(to, from, next) {
    var isReady = false;

    if (
      this.email != null &&
      (this.mainPhone != null || this.cellPhone != null)
    ) {
      isReady = this.readyForSave;
    }

    if (isReady) {
      this.showSaveModal = true;
      setTimeout(() => {
        document.getElementById("btnDialogSave").focus();
      }, 100);
    } else {
      if (this.mainPhone != null) {
        if (this.mainPhone.replace(/-/g, "").length == 0) {
          this.mainPhone = this.changeMainPhone;
        }
      }
      next();
    }
  },

  computed: {
    firstName: {
      get() {
        return this.$store.state.account.firstName;
      },
    },

    readyForSave: function () {
      var returnValue = false;

      if (
        this.validateEmail(this.email) &&
        this.validatePhone(this.mainPhone) &&
        (this.cellPhone.length == 0 ||
          (this.cellPhone.length > 0 && this.validatePhone(this.cellPhone)))
      ) {
        returnValue =
          this.changeMarketingEmails !== this.marketingEmails ||
          this.changeMarketingCalls !== this.marketingCalls ||
          // || (this.changeMarketingTexts !== this.marketingTexts)
          //|| (this.changeServiceTexts !== this.serviceTexts)
          this.changeEmail !== this.email ||
          this.changeMainPhone.replace(/-/g, "") !==
            this.mainPhone.replace(/-/g, "") ||
          this.changeCellPhone.replace(/-/g, "") !==
            this.cellPhone.replace(/-/g, "");
      }
      return returnValue;
    },
    email: {
      get() {
        return this.$store.state.account.email;
      },
      set(value) {
        this.$store.commit("updateEmail", value);
      },
    },
    mainPhone: {
      get() {
        return this.$store.state.account.mainPhone;
      },
      set(value) {
        this.$store.commit("updateMainPhone", value);
      },
    },

    cellPhone: {
      get() {
        if (this.mainIsCell) {
          return this.$store.state.account.mainPhone;
        } else {
          return this.$store.state.account.cellPhone;
        }
      },

      set(value) {
        this.$store.commit("updateCellPhone", value);
      },
    },
    marketingEmails: {
      get() {
        return this.$store.state.account.marketingEmails;
      },
      set(value) {
        this.$store.commit("updateMarketingEmails", value);
      },
    },
    marketingCalls: {
      get() {
        return this.$store.state.account.marketingCalls;
      },
      set(value) {
        this.$store.commit("updateMarketingCalls", value);
      },
    },
    marketingTexts: {
      get() {
        return this.$store.state.account.marketingTexts;
      },
      set(value) {
        this.$store.commit("updateMarketingTexts", value);
      },
    },
    serviceTexts: {
      get() {
        return this.$store.state.account.serviceTexts;
      },
      set(value) {
        this.$store.commit("updateServiceTexts", value);
      },
    },
    mainIsCell: {
      get() {
        return this.$store.state.application.mainIsCell;
      },
      set(value) {
        this.$store.commit("updateMainIsCell", value);
      },
    },
    showSaveEmailPhonePopup: {
      get() {
        return this.$store.state.account.prefShowSavedMessage;
      },
      set(value) {
        this.$store.commit("updatePrefShowSavedMessage", value);
      },
    },
    getToken: {
      get() {
        return this.$store.state.account.token;
      },
    },
    getCustId: {
      get() {
        return this.$store.state.account.customerId;
      },
    },
  },
  data() {
    return {
      legal: legal,
      preferences: preferences,
      showWarningModal: false,
      selectedOption: null,
      warningText: "",
      changesReady: false,
      changeEmail: null,
      changeMainPhone: null,
      changeCellPhone: null,
      changeMarketingEmails: false,
      changeMarketingCalls: false,
      changeMarketingTexts: false,
      changeServiceTexts: false,
      showSaveModal: false,
      showMarketingModal: false,
      expandCallingAgreement: false,
      expandTextAgreement: false,
      alertEmailMsg: false /*,
      lvxScript: lvxScript,
      lvxURL: lvxURL*/,
      showemailsavemessage: null,
      isCellNoBlank: false,
      prefConfPopupCalledFrom: null,
      lastfocuselementid: "",
    };
  },
  methods: {
    focusfirstpageelementwhenlanded() {
      document.getElementById("prefPageTitle").focus();
    },
    focuspreviouseleinpopup(event) {
      document.getElementById("btnMktEmailsNo").focus();
      event.stopPropagation();
      event.preventDefault();
    },
    focusnexteleinpopup(event) {
      document.getElementById("btnMktEmailsYes").focus();
      event.stopPropagation();
      event.preventDefault();
    },
    focuscanceleleinpopup(event) {
      document.getElementById("btnDialogCancel").focus();
      event.stopPropagation();
      event.preventDefault();
    },
    focussaveleinpopup(event) {
      document.getElementById("btnDialogSave").focus();
      event.stopPropagation();
      event.preventDefault();
    },
    toggleMarketingEmail(elementid) {
      if (this.changeMarketingEmails) {
        document.getElementById(elementid).checked = true;
        this.selectedOption = "markEmail";
        this.warningText =
          "Email is a great way to keep up with new features and offers. " +
          "You can also opt out any time through the unsubscribe link on our marketing emails.";
        this.openWarningModal(elementid);
      } else {
        document.getElementById(elementid).checked = true;
        this.changeMarketingEmails = true;
      }
    },
    toggleMarketingCalls(elementid) {
      if (this.changeMarketingCalls) {
        document.getElementById(elementid).checked = true;
        this.selectedOption = "markCall";
        this.warningText =
          "Calls are an easy way to stay in the loop with XactFlex™ offers and information.";
        this.openWarningModal(elementid);
      } else {
        document.getElementById(elementid).checked = true;
        this.changeMarketingCalls = true;
      }
    },
    toggleMarketingTexts() {
      if (this.changeMarketingTexts) {
        this.selectedOption = "markText";
        this.warningText =
          "Texting is a great way to keep valuable info at your " +
          "fingertips. You can also opt out by responding “STOP” to any of our text messages.";
        this.openWarningModal("");
      } else {
        this.changeMarketingTexts = true;
      }
    },
    toggleServiceTexts() {
      if (this.changeServiceTexts) {
        this.selectedOption = "servText";
        this.warningText =
          "Texting is a great way to keep valuable info at your " +
          "fingertips. You can also opt out by responding “STOP” to any of our text messages.";
        this.openWarningModal("");
      } else {
        this.changeServiceTexts = true;
      }
    },
    toggleCallingAgreement() {
      this.expandCallingAgreement = !this.expandCallingAgreement;
      this.expandTextAgreement = false;
    },
    toggleTextAgreement() {
      this.expandTextAgreement = !this.expandTextAgreement;
      this.expandCallingAgreement = false;
    },
    toggleMainIsCellPhone() {
      this.mainIsCell = !this.mainIsCell;
    },
    openDocument(doc) {
      this.$store.state.esign.agreementdocname = doc;
      this.$store.dispatch("getDocument", doc);
    },
    openDocumentKeySpace(doc, event) {
      event.preventDefault();
      this.$store.state.esign.agreementdocname = doc;
      this.$store.dispatch("getDocument", doc);
    },
    openWarningModal(id) {
      this.showWarningModal = true;
      this.lastfocuselementid = id;
      setTimeout(() => {
        document.getElementById("btnMktEmailsYes").focus();
      }, 100);
    },
    closeWarningModal() {
      this.showWarningModal = false;
      this.focusonlastelement();
      this.lastfocuselementid = "";
    },
    focusonlastelement() {
      document.getElementById(this.lastfocuselementid).focus();
      if (this.lastfocuselementid == "lbltogglebuttonemail") {
        document.getElementById("lbltoggleemail").focus();
      } else {
        document.getElementById("lbltogglecall").focus();
      }
      if (this.changeMarketingEmails) {
        document.getElementById(this.lastfocuselementid).checked = true;
      } else {
        document.getElementById(this.lastfocuselementid).checked = false;
      }
      if (this.changeMarketingCalls) {
        document.getElementById("lbltogglebuttoncall").checked = true;
      } else {
        document.getElementById("lbltogglebuttoncall").checked = false;
      }
    },
    acceptMarketingCallsChange() {
      this.changeMarketingCalls = true;
      this.closeWarningModal();
      this.saveChanges("");
    },
    acceptMarketingTextsChange() {
      this.changeMarketingTexts = true;
      this.closeWarningModal();
      this.saveChanges("");
    },
    acceptChange() {
      switch (this.selectedOption) {
        case "markEmail":
          this.changeMarketingEmails = false;
          break;
        case "markCall":
          this.changeMarketingCalls = false;
          break;
        case "markText":
          this.changeMarketingTexts = false;
          break;
        case "servText":
          this.changeServiceTexts = false;
          break;
      }
      this.closeWarningModal();
    },
    async saveChanges(calledFrom) {
      this.prefConfPopupCalledFrom = calledFrom;
      var isEmailChanged = false;
      var isMainPhoneChanged = false;
      var isCellPhoneChanged = false;

      if (this.changeEmail != this.email) {
        isEmailChanged = true;
      }

      if (
        this.changeMainPhone.replace(/-/g, "") !=
        this.mainPhone.replace(/-/g, "")
      ) {
        isMainPhoneChanged = true;
      }

      if (
        this.changeCellPhone.replace(/-/g, "") !=
        this.cellPhone.replace(/-/g, "")
      ) {
        isCellPhoneChanged = true;
      }

      if (this.mainIsCell) {
        if (this.mainPhone != this.cellPhone) {
          isCellPhoneChanged = true;
        }
      }

      this.changeEmail = this.email;
      this.changeMainPhone = this.mainPhone;
      this.changeCellPhone = this.cellPhone;
      this.marketingEmails = this.changeMarketingEmails;
      this.marketingCalls = this.changeMarketingCalls;
      this.marketingTexts = this.changeMarketingCalls;
      //  this.serviceTexts = this.changeServiceTexts;

      if (this.mainIsCell) {
        this.changeCellPhone = this.mainPhone;
        this.cellPhone = this.mainPhone;
      } else if (!this.mainIsCell && this.cellPhone.length == 0) {
        this.marketingTexts = false;
        this.serviceTexts = false;
      }

      this.changeMarketingTexts = this.marketingTexts;
      this.changeServiceTexts = this.serviceTexts;
      this.showSaveModal = false;
      this.showMarketingModal = false;
      this.alertEmailMsg = false;
      const res = true;

      this.$store.dispatch("savePreferences");

      this.showSaveEmailPhonePopup = false;

      if (res) {
        this.$store.commit(
          "updateSendMessagesUtilityEmailFirstName",
          this.firstName
        );
        this.$store.commit("updateSendMessagesUtilityEmail", this.changeEmail);
        this.$store.commit(
          "updateSendMessagesUtilityMobile",
          this.changeCellPhone
        );
        this.$store.commit("updateSendMessagesUtilityToken", this.getToken);
        this.$store.commit("updateSendMessagesUtilityCustId", this.getCustId);
        this.$store.commit(
          "updateSendMessagesUtilityEmailSubject",
          preferences.prefEmailSubjectLine
        );
        this.$store.commit(
          "updateSendMessagesUtilityPreviewText",
          preferences.prefPreviewText
        );
        this.$store.commit(
          "updateSendMessagesUtilitySMSText",
          preferences.prefSMSText
        );

        this.isCellNoBlank = false;
        if (!this.mainIsCell && this.cellPhone.length == 0) {
          this.isCellNoBlank = true;
        }

        //let resEmailSms=false;
        //Phone change and email change
        if (
          isMainPhoneChanged == true ||
          (isCellPhoneChanged == true && isEmailChanged == true)
        ) {
          //alert('Both');
          if (this.isMainPhoneChanged == true && isCellPhoneChanged != true) {
            if (this.isEmailChanged == true) {
              this.$store.dispatch("utilitiesSendEmail");
              this.showemailsavemessage = "EMAIL&PHONE";
            }
          } else if (isCellPhoneChanged == true) {
            if (!this.isCellNoBlank == true) {
              this.$store.dispatch("utilitiesSendSMS");
              this.$store.dispatch("utilitiesSendEmail");
              this.showemailsavemessage = "EMAIL&PHONE";
            } else {
              this.$store.dispatch("utilitiesSendEmail");
              this.showemailsavemessage = "EMAIL&PHONE";
            }
          }
          //this.showemailsavemessage="EMAILPHONE";
          this.showSaveEmailPhonePopup = true;
        }
        //Phone number change but no email changes
        if (
          isMainPhoneChanged == true ||
          (isCellPhoneChanged == true && isEmailChanged == false)
        ) {
          if (isMainPhoneChanged == true && isCellPhoneChanged != true) {
            this.$store.dispatch("utilitiesSendEmail");
            this.showemailsavemessage = "EMAILPHONE";
          } else if (isCellPhoneChanged == true && isMainPhoneChanged != true) {
            if (!this.isCellNoBlank == true) {
              this.$store.dispatch("utilitiesSendSMS");
              this.showemailsavemessage = "SMSPHONE";
            } else {
              this.$store.dispatch("utilitiesSendEmail");
              this.showemailsavemessage = "EMAILPHONE";
            }
          } else {
            this.$store.dispatch("utilitiesSendEmail");
            this.$store.dispatch("utilitiesSendSMS");
            this.showemailsavemessage = "SMSPHONE";
          }
          this.showSaveEmailPhonePopup = true;
        }
        //No phone changes but there is an email change
        if (
          isMainPhoneChanged == false &&
          isCellPhoneChanged == false &&
          isEmailChanged == true
        ) {
          //alert('Email');
          this.$store.dispatch("utilitiesSendEmail");
          this.showemailsavemessage = "EMAIL";
          this.showSaveEmailPhonePopup = true;
        }

        if (this.showSaveEmailPhonePopup) {
          setTimeout(() => {
            document.getElementById("pref-confirm-close").focus();
          }, 100);
        }
      }
    },
    cancelChanges() {
      this.email = this.changeEmail;
      this.mainPhone = this.changeMainPhone;
      this.cellPhone = this.changeCellPhone;
      this.changeMarketingEmails = this.marketingEmails;
      this.changeMarketingCalls = this.marketingCalls;
      this.changeMarketingTexts = this.marketingTexts;
      this.changeServiceTexts = this.serviceTexts;
      this.showSaveModal = false;
      this.showMarketingModal = false;
      this.alertEmailMsg = false;
      document.getElementById("lbltogglebuttonemail").checked =
        this.changeMarketingEmails;
      document.getElementById("lbltogglebuttoncall").checked =
        this.changeMarketingCalls;
      this.$nextTick(function () {
        document.getElementById("navaccount").focus();
      });

      //this.$router.push({ name: "portal-account" });
    },

    validateEmail: function (emailAddress) {
      if (emailAddress != null && emailAddress.length > 0) {
        var re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(emailAddress);
      } else {
        return false;
      }
    },

    validateEmptyEmail: function (emailAddress) {
      if (emailAddress != null && emailAddress.length == 0) {
        this.email = this.changeEmail;
        this.alertEmailMsg = true;
        return false;
      } else {
        this.alertEmailMsg = false;
        return true;
      }
    },

    validatePhone: function (phoneNumber) {
      if (phoneNumber == null || phoneNumber.replace(/-/g, "").length !== 10) {
        return false;
      }
      var re = /^[0-9]{1,3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,7}$/;
      return re.test(phoneNumber);
    },
  },
};
</script>

<style scoped lang="scss">
.portal-wrapper {
  @media (max-width: 960px) {
    flex-flow: column nowrap !important;
    margin-top: 25px;
    .portal {
      margin: 0px 0px 20px 0px;
    }
  }
}

.helloAcct {
  display: none;

  @media (max-width: 1023px) {
    margin-top: 20px;
    text-align: center;
    display: block;
  }
}

.hide-large {
  display: none;
}

//ADA changes start
.vue-js-switch:focus-visible,
.vue-js-switch:focus {
  outline: 2px solid $blue;
}

#lnkPrivacyPolicy:focus {
  outline: 2px solid #1e4388;
}

#lnkTermsCondition:focus {
  outline: 2px solid #1e4388;
}

#prefPageTitle:focus-visible {
  outline: 0px solid #1e4388;
}

//ADA changes ends

.header-section {
  h1 {
    font-size: 36px;
    width: 100%;
    font-weight: 700;
    margin: 10px 0px;

    @media (max-width: 1023px) {
      font-size: 30px;
    }
  }
  .clsh3 {
    margin: 5px 0px;
    font-weight: 400;
  }
  .clsh6 {
    font-weight: 400;
  }
  @media (max-width: 768px) {
    .section-title {
      text-align: left;
      margin-left: 20px !important;
    }
    .head-description {
      -webkit-box-align: center;
      align-items: flex-start;
      -webkit-box-pack: start;
      h5,
      .clsh6 {
        font-size: 14px;
        text-align: left !important;
        padding-right: 20px;
      }
    }
  }
}

.header {
  display: flex;
  flex-flow: row nowrap;
  @media (max-width: 768px) {
    flex-flow: column nowrap;
  }
  @media (max-width: 960px) {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  justify-content: space-between;
  align-items: flex-start;
  .header-section {
    width: 100%;
  }
  .button-save-container {
    margin-top: 10%;
    display: flex;
    justify-content: right;
    @media (max-width: 768px) {
      margin-top: 0px;
      margin-bottom: 10px;
      justify-content: center;
      width: 100%;
    }
    @media (max-width: 960px) {
      margin-top: 2%;
      margin-left: -7px;
      margin-bottom: 10px;
    }
    button:disabled {
      background: #cbcbcb;
      color: #ffffff;
      cursor: not-allowed;
    }
  }
}

.head-description {
  display: flex;
  flex-flow: row;
  text-transform: none;
  @media (max-width: 768px) {
    flex-flow: column;
  }
  align-items: center;
  justify-content: flex-start;

  h5 {
    padding-left: 8px;

    @media (max-width: 768px) {
      padding-left: 0px;
    }
  }
}
.legal {
  font-size: 12px;
  font-style: italic;
  text-align: center;
  height: 50px;
  padding: 20px 20px 0px 20px;
}

input {
  border: solid 1px #d0d2d3;
  padding: 6px 15px;
  border-radius: 0px;
}

h5,
.clsh6 {
  font-size: 18px;
  @media (max-width: 768px) {
    text-align: center;
    display: flex;
    flex-flow: column;
  }
}
.XACT-font {
  color: #1c4587;
  font-weight: 600;
}

.sub-title {
  font-size: 20px;
  text-transform: none;
  @media (max-width: 768px) {
    font-size: 18px;
  }
}

.sub-mid-title {
  font-size: 18px;
}

button {
  font-size: 30px;
  margin: 0 8px;
}
.button-save {
  background-color: $blue;
  color: $absolute-white;
  padding: 4px 16px;
  width: 200px;
  white-space: nowrap;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  //&:disabled {
  //  background: $border;
  // }
}

.btnspace {
  float: right;
  margin: 20px 0px;
  button:disabled {
    background: #cbcbcb;
    color: #ffffff;
    cursor: not-allowed;
  }
  @media (max-width: 980px) {
    float: left;
    width: 100%;
    margin: 25px auto;
    display: flex;
    justify-content: space-around;
  }
}

.doclink {
  font-style: normal;
  cursor: pointer;
  color: $blue;
  border-bottom: none;
  text-decoration: underline;
}

.note {
  font-size: 12px;
  margin: 10px 0;
}

.redBorder {
  border: 2px solid red;
}

.portal .card {
  box-shadow: none;
}

.phone-validation {
  display: flex;
  font-family: $font-primary;
  font-size: 10px;
  font-weight: normal;
  color: red;
  margin-top: 10px;
  margin-left: -125px !important;
}

.email-validation {
  display: flex;
  font-family: $font-primary;
  font-size: 10px;
  font-weight: normal;
  color: red;
  margin-top: 5px;
  margin-left: -165px;
  @media (max-width: 768px) {
    width: 330px;
  }
  @media (max-width: 460px) {
    width: 300px;
  }
}

@media (max-width: 980px) {
  .bar {
    border-bottom: 1px solid #ededed;
    border-top: 1px solid #ededed;
    margin: 20px 0px !important;
  }
  .phone-preference {
    border-bottom: 1px solid #ededed;
  }
}
.phone-preference {
  .sidespace {
    @media (max-width: 768px) {
      width: 75% !important;
    }
  }
  @media (max-width: 768px) {
    height: 100%;
  }
}
.preference {
  margin: 10px 0px;
  @media (max-width: 768px) {
    margin: 10px 20px !important;
  }
  @media (max-width: 980px) {
    background-color: #fff;
  }
  padding: 10px 5px;
  background-color: $light-gray;
  .clsh3 {
    font-size: 20px;
  }

  .label {
    font-family: $font-primary; //$font-secondary;
    font-size: 12px;
    margin-bottom: 8px;
  }

  .phoneText {
    height: 20px;
    width: 85%;
    color: #ee6323;
    font-weight: bold;
  }
  .space {
    margin-top: 20px !important;
  }
  .subheader {
    font-size: 20px;
    font-weight: bold;
    color: #1e4488;
    margin-left: 20px;
    @media (max-width: 768px) {
      margin-left: 5px;
      margin-bottom: 15px;
      font-size: 16px;
    }
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .emaillabel {
    font-size: small;
    font-weight: bold;
    color: #53575a;
    margin-left: 20px;
    margin-bottom: 5px;
    @media (max-width: 480px) {
      margin-left: 10px;
    }
  }

  .mainphonelabel {
    display: flex;
    flex-flow: column nowrap;
    font-size: small;
    font-weight: bold;
    color: #53575a;
    margin-bottom: 5px;
    white-space: nowrap;
  }

  .cellphonelabel {
    font-size: small;
    font-weight: bold;
    color: #53575a;
    height: 30px;
    margin-left: 50px;

    span:first-of-type {
      margin-left: 10px;
    }

    @media (max-width: 768px) {
      margin-left: 0px;
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
    }
    white-space: nowrap;
  }

  .row-mobile {
    @media (max-width: 768px) {
      flex-flow: column nowrap !important;
    }
    .left {
      margin-bottom: 20px;
      @media (max-width: 768px) {
        width: 75% !important;
      }
    }
  }
  .column-flex {
    flex-flow: column !important;
  }
  .row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;

    .desc {
      font-size: 12px;
      margin-left: 5px;
      padding: 15px 15px;
    }
    .leftside {
      width: 65%;
      margin: 25px;
      @media (max-width: 768px) {
        margin: 25px 0px 10px 0px;
        width: 100%;
      }
    }
    .left {
      width: 55%;
      @media (max-width: 768px) {
        width: 100%;
        margin-left: 0px;
      }
      margin-left: 15px;
      .phone-container {
        display: flex;
        flex-flow: row nowrap;
        @media (max-width: 768px) {
          flex-flow: column nowrap;
        }
      }
      .expander {
        .expander-name {
          color: #ee6323;
          margin-left: 5px;
          font-size: 14px;
          cursor: pointer;
          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
      }
      .inputs {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        margin-top: 20px;
        font-family: $font-primary; //$font-secondary;

        .input-label {
          margin-right: 10px;
          font-size: 12px;
        }
      }

      input {
        height: 20px;
        width: 200px;
        background-color: #ededed;
        @media (max-width: 980px) {
          //width:100%;
          background-color: #fff;
        }
        @media (max-width: 768px) {
          width: 100%;
        }
        padding-right: 20px;
        margin-right: 4px;
        font-size: 12px;
        border-style: solid;
        border-color: #b5b5b5;
        &.phone {
          height: 20px;
          width: 85%;
          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }
      .icon {
        position: absolute;
        transform: translate(170px, -21px);
        color: $gray;

        &.phone-icon {
          transform: translate(130px, -21px);
        }
      }
      .second-input {
        margin-left: 20px;
      }
      .second-icon {
        margin-left: 0px;
      }
    }

    .right {
      display: flex;
      flex-flow: row nowrap;
      //justify-content: space-around;
      align-items: center;
      width: 45%;
      @media (max-width: 987px) {
        width: 55%;
      }
      @media (max-width: 785px) {
        width: 60%;
      }
      @media (max-width: 768px) {
        width: 90%;
        margin-top: 30px;
      }
      @media (max-width: 550px) {
        //width:180px;
      }
      margin: 10px 15px;
      float: right;
      .toggle-container {
        width: 100%;
        display: -webkit-flex;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
        .toggle-text {
          vertical-align: top;
          text-align: left;
          color: darkblue;
          font-size: 18px;
          @media (max-width: 768px) {
            font-size: 14px;
          }
        }
        .toggleButton {
          vertical-align: top;
          margin-left: 12px;
          @media (max-width: 768px) {
            margin-left: 8px;
            margin-right: 0px;
          }
          margin-right: 5px;
        }
      }
    }
  }
}

.preference-footer {
  display: flex;
  flex-flow: row nowrap;
  @media (max-width: 768px) {
    flex-flow: column-reverse nowrap;
  }
  padding: 20px;
  .left-text {
    width: 70%;
    @media (max-width: 768px) {
      width: 100%;
    }
    float: left;
    font-size: 12px;
    font-style: italic;
    text-align: left;
  }
  .right-button {
    width: 30%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}
.input-checkbox-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 10px;
  //width: 80%;
  //border: 2px solid yellow;
}

.input-checkbox {
  position: absolute;
  top: 2px;
  left: 150px;
  height: 10px;
  width: 10px;
  background-color: #ededed;
  border: 2px solid #ee6323;
  border-radius: 3px;
}

.input-checkbox:after {
  content: "";
  position: absolute;
  display: none;
}
.input-checkbox-wrapper input:checked ~ .input-checkbox:after {
  display: block;
}
.input-checkbox-wrapper .input-checkbox:after {
  content: "\2713\0020";
  left: -1px;
  top: -1px;
  width: 12px;
  height: 12px;
  color: #fff;
  font-size: 10px;
  background-color: rgb(238, 99, 35);
  text-align: center;
  border-radius: 2px;
}
input[type="checkbox"]:disabled {
  cursor: not-allowed;
}

.bottom {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 0;

  .subnote {
    max-width: 430px;
    color: $absolute-white;
  }
}

.preference-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9991;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  .preference-modal {
    background: #e75300; //#EE6323;
    color: $absolute-white;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    z-index: 9992;
    width: 350px;
    padding: 10px 20px;

    .title {
      font-family: $font-primary; //$font-secondary;
      font-size: 24px;
      font-weight: bold;
    }

    .subnote {
      color: $white;
      text-align: center;
      font-size: 14px;
      font-weight: normal;
    }

    .options {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      .option {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        font-family: $font-primary; //$font-secondary;
        font-size: 12px;
        margin: 10px 20px;
        cursor: pointer;
        .buttonaccept {
          width: 120px;
          background-color: #ffffff;
          color: #ee6323;
          font-size: 12px;
          font-weight: bold;
          height: 30px;
        }

        .buttonDeny {
          width: 120px;
          background-color: #e75300; //#EE6323;
          color: #ffffff;
          font-size: 12px;
          text-decoration: underline;
        }
      }
    }
  }
}

.bgcontainer {
  background-image: url(../../assets/backgrounds/xactflex-hero-background@2x.jpg);
  background-size: cover;
}

.bglayout {
  width: 85%;
  margin: 0px auto;

  @media (min-width: 414px) and (max-width: 428px) {
    width: 95%;
  }
  @media (min-width: 360px) and (max-width: 413px) {
    width: 95%;
  }
}
@media (max-width: 1023px) {
  .note {
    margin: 0;
  }
  .preference {
    .row {
      .left {
        width: 50%;
        .inputs {
          flex-flow: column nowrap;
          align-items: flex-start;
        }
      }
    }
  }
  .bottom {
    align-items: center;
  }

  .preference-modal-wrapper {
    .preference-modal {
      width: 330px;
      padding: 10px 20px;

      .title {
        font-family: $font-primary; //$font-secondary;
        font-size: 24px;
        font-weight: bold;
      }

      .subnote {
        color: $white;
        text-align: center;
        font-size: 14px;
        font-weight: normal;
      }

      .options {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        .option {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          font-family: $font-primary; //$font-secondary;
          font-size: 10px;
          margin: 10px 20px;
          cursor: pointer;

          .buttonaccept {
            width: 120px;
            background-color: #ffffff;
            color: #ee6323;
            font-size: 12px;
            font-weight: bold;
            height: 30px;

            @media (max-width: 448px) {
              width: 130px;
              padding: 0px 5px;
            }
          }

          .buttonDeny {
            width: 120px;
            background-color: #e75300; //#EE6323;
            color: $absolute-white;
            font-size: 12px;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

// toggle changes
.switch {
  position: relative;
  display: block;
  width: 60px;
  height: 22px;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(193, 188, 188);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3.1px;
  bottom: 3.2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  @media (max-width: 767px) {
    bottom: 3px;
  }
}

input:checked + .slider {
  background-color: rgb(30, 68, 136);
}

input:focus + .slider {
  box-shadow: 0 0 1px #101010;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(37px);
}

.slider.round {
  border-radius: 34px;
  margin: 0px;
}

.slider.round:before {
  border-radius: 50%;
}

.switchToggle {
  position: relative;
  margin-left: 12px;
  margin-right: 5px;
}
.switch-left {
  position: absolute;
  top: 3px;
  font-weight: 600;
  color: #ffffff !important;
  z-index: 2;
  left: 10px;
  font-size: 12px;
}
.switch-right {
  position: absolute;
  top: 3px;
  font-weight: 600;
  color: #ffffff !important;
  z-index: 2;
  right: 10px;
  font-size: 12px;
}

label:focus {
  outline-color: #1e4488;
}

.trademark {
    font-size: 10px;
    vertical-align: center;
    font-weight: 500;
  }
</style>