const initialState = {
  refreshaccount: true,
  accountId: null,
  email: null,
  firstName: null,
  lastName: null,
  mainPhone: null,
  cellPhone: null,
  accountBalance: 0,
  accountLastFour: null,
  accountType: null,
  nextPayment: null,
  nextPaymentDate: null,
  minimumPaymentDue: null,
  currentDue:null,
  pastDue: null,
  pendingPayment: null,
  pendingPaymentType: null,
  creditLimit: 0,
  availableCredit: 0,
  isOriginated:false,
  loanStatusCode:"",
  delinquent: false,
  marketingEmails: null,
  marketingCalls: null,
  marketingTexts: null,
  serviceTexts: null,
  finalizeEsign: false,
  finalizeCredit: false,
  finalizeApplication: false,
  applNo: null,
  minimumDraw: 0,
  drawIncrement: 0,
  isEligibleForDraw: false,
  drawMessage: "",
  achEffectiveDate: null,
  minAchTransactionAmount: 1.00,
  maxPendingAchTranCount: 0,
  maxNoOfAchEffectiveDays: 0,
  maxPaymentDate: null,
  allowSameDayACH: false,
  bankAccountID: 0,
  recentTransactionsTableData: [],
  billingStatementsTableData: [],
  allTransactionsTableData: [],
  allStatementsTableData: [],
  pendingTransactionsTableData: [],
  showDrawRequest: false,
  showTransactionDetails: false,
  showDocumentDetails: false,
  chargeoff: false,
  requiresESignature: false,
  preApprovedAmount: 0,
  //For Documents
  token: null,
  //For Loader
  isLoading: true,
  paymentEffectiveDate: null,
  maxACHBizDate: null,
  maxPendingTranreached: null,
  customerId: 0,
  actualDrawAmount: 0,
  cashAdvanceFee: 0,
  cashAdvanceFeePercent: 0,
  firstDrawAmount: 100,
  creditUsedPercent: 0,
  prefShowSavedMessage:false,
  state: null,
}
//import Vue from 'vue';
import dayjs from 'dayjs';
import {$gMiddleLayerApi} from '../config/appconfigsettings';
import { formatPhone } from "@/utils/plugins/inputs";
export default {
  state: () => {
    return {
      ...initialState
    }
  },
  getters: {
    refreshaccount: state => state.refreshaccount,
    recentTransactionsTableData: state => state.recentTransactionsTableData,
    billingStatementsTableData: state => state.billingStatementsTableData,
    allTransactionsTableData: state => state.allTransactionsTableData,
    allStatementsTableData: state => state.allStatementsTableData,
    showDrawRequest: state => state.isEligibleForDraw,
    showTransactionDetails: state => state.showTransactionDetails,
    showDocumentDetails: state => state.showDocumentDetails,
    paymentEffectiveDate: state => state.paymentEffectiveDate,
    maxACHBizDate: state => state.maxACHBizDate,
    maxPaymentDate: state => state.maxPaymentDate,
    pendingTransactionsTableData: state => state.pendingTransactionsTableData,
    ///For Documents
    token: state => state.token,
  },

  mutations: {
    resetAccount(state) {
      Object.keys(state).forEach(key => {
        state[key] = initialState[key];
      })
    },
    updaterefreshaccount(state, value) {
      state.refreshaccount = value;
    },
    updateEmail(state, value) {
      state.email = value;
    },
    updateMainPhone(state, value) {
      state.mainPhone = formatPhone(value);
    },
    updateCellPhone(state, value) {
      state.cellPhone = formatPhone(value);
    },
    updateMarketingEmails(state, value) {
      state.marketingEmails = value;
    },
    updateMarketingCalls(state, value) {
      state.marketingCalls = value;
    },
    updateMarketingTexts(state, value) {
      state.marketingTexts = value;
    },
    updateServiceTexts(state, value) {
      state.serviceTexts = value;
    },
    updateFinalizeEsign(state, value) {
      state.finalizeEsign = value;
    },
    updateFinalizeCredit(state, value) {
      state.finalizeCredit = value;
    },
    updateFinalizeApplication(state, value) {
      state.finalizeApplication = value;
    },
    updateDelinquent(state, value) {
      state.delinquent = value;
    },
    updateChargeoff(state, value) {
      state.chargeoff = value;
    },
    updateCreditLimit(state, value) {
      state.creditLimit = value;
    },
    updateAccountBalance(state, value) {
      state.accountBalance = value;
    },
    updateAvailableCredit(state, value) {
      if (value > 0)
        state.availableCredit = value;
      else
        state.availableCredit = 0;
    },
    updateAccountId(state, value) {
      state.accountId = value;
    },
    updateApplNo(state, value) {
      state.applNo = value;
    },
    updateFirstName(state, value) {
      state.firstName = value;
    },
    updateLastName(state, value) {
      state.lastName = value;
    },
    updateNextPayment(state, value) {
      state.nextPayment = value;
    },
    updateNextPaymentDate(state, value) {
      state.nextPaymentDate = value;
    },
    updateMinimumPaymentDue(state, value) {
      state.minimumPaymentDue = value;
    },
    updatecurrentDue(state, value) {
      state.currentDue = value;
     // console.log(" state.currentDue"+ state.currentDue);
    },
    updatePastDue(state, value) {
      state.pastDue = value;
    },
    updateMinimumDrawAmount(state, value) {
      state.minimumDraw = value;
    },
    updateIsEligibleForDraw(state, value) {
      state.isEligibleForDraw = value;
    },
    updateDrawMessage(state, value) {
      state.drawMessage = value;
    },
    updateIncrementDrawAmount(state, value) {
      state.drawIncrement = value;
    },
    updateAchEffectiveDate(state, value) {
      state.achEffectiveDate = value;
    },
    updateMinAchTransactionAmount(state, value) {
      state.minAchTransactionAmount = value;
    },
    updateMaxPendingAchTranCount(state, value) {
      state.maxPendingAchTranCount = value;
    },
    updateMaxNoOfAchEffectiveDays(state, value) {
      state.maxNoOfAchEffectiveDays = value;
    },
    updateMaxPaymentDate(state, value) {
      state.maxPaymentDate = value;
    },
    updateAllowSameDayACH(state, value) {
      state.allowSameDayACH = value;
    },
    updateBankAcctId(state, value) {
      state.bankAccountID = value;
    },
    updateAccountType(state, value) {
      state.accountType = value;
    },
    updateBankAccountLastFour(state, value) {
      state.accountLastFour = value;
    },
    updaterecentTransactionsTableData(state, value) {
      state.recentTransactionsTableData = value;
    },
    updatebillingStatementsTableData(state, value) {
      state.billingStatementsTableData = value;
    },
    updateallTransactionsTableData(state, value) {
      state.allTransactionsTableData = value;
    },
    updateallStatementsTableData(state, value) {
      state.allStatementsTableData = value;
    },
    updatependingPayment(state, value) {
      state.pendingPayment = value;
    },
    updatependingPaymentType(state, value) {
      state.pendingPaymentType = value;
    },
    updatependingTransactionsTableData(state, value) {
      state.pendingTransactionsTableData = value;
    },
    updateShowDrawRequest(state, value) {
      state.showDrawRequest = value;
    },
    updateShowDocumentDetails(state, value) {
      state.showDocumentDetails = value;
    },
    updateShowTransactionDetails(state, value) {
      state.showTransactionDetails = value;
    },
    updateRequireeSignature(state, value) {
      state.requiresESignature = value;
    },
    updatePreApprovedAmount(state, value) {
      state.preApprovedAmount = value;
    },
    updateIsOriginated(state, value) {
      state.isOriginated = value;
    },
    updateLoanStatusCode(state, value) {
      state.loanStatusCode = value;
    },
    updateIsLoading(state, value) {
      state.isLoading = value;
    },
    updatepaymentEffectiveDate(state, value) {
      state.paymentEffectiveDate = value;
    },
    updatemaxACHBizDate(state, value) {
      state.maxACHBizDate = value;
    },
    updatemaxPendingTranreached(state, value) {
      state.maxPendingTranreached = value;
    },
    updateActualDrawAmount(state, value) {
      state.actualDrawAmount = value;
    },
    updateCashAdvanceFee(state, value) {
      state.cashAdvanceFee = value;
    },
    updateCashAdvanceFeePercent(state, value) {
      state.cashAdvanceFeePercent = value;
    },
    updateCreditUsedPercent(state, value) {
      state.creditUsedPercent = value;
    },
    updatePrefShowSavedMessage(state, value) {
      state.prefShowSavedMessage = value;
    },
    updateState(state, value) {
      state.state = value;
    },
  
  },
  actions: {
    async getAccountSummaryInfo({ state, commit }) {
      if (state.customerId > 0 && this.getters.refreshaccount) {
        try {
          commit('updateIsLoading', true);
          var auth = 'Bearer ' + state.token;
          const acctSummaryResponse = await fetch( $gMiddleLayerApi + `Account/GetSummary`, {     //for now just testing api hitting endpoint, will need to change this later to accept paths
            "method": "POST",
            //"body": input,
            "headers": {
              'Content-Type': 'application/json',
              'Authorization': auth
            }
          })

        //  console.log("in getAccountSummary Response: ");

          if (acctSummaryResponse.ok) {
            const jVal = await acctSummaryResponse.json(); // Get JSON value from the response body
            //  console.log(jVal);
            state.token = jVal.tokenToUse;
            commit('updaterefreshaccount', false);

          //  console.log("getAccountSummary Response successful ");

            const accountProfile = jVal.accountProfile;
            const acctHeader = jVal.accountHeader;
            const acctConfig = jVal.accountConfig;
            const bankAcctInfo = jVal.bankAccountInfo;
            const customerpreferences = jVal.preferences;
            const employerPayrollInfo = jVal.employerPayrollInfo;

            //Profile details
            if (accountProfile != null) {
              commit('updateFirstName', accountProfile.firstName);
              commit('updateLastName', accountProfile.lastName);
              commit('updateEmail', accountProfile.custEmail);
              commit('updateMainPhone', accountProfile.phoneNumber);
              
              commit('updateCellPhone', accountProfile.mobileNumber);
              if (accountProfile.mobileNumber == accountProfile.phoneNumber) {
                commit('updateMainIsCell', true);
              }
              else {
                commit('updateMainIsCell', false);
              }
              commit('updateStreetAddress', accountProfile.address1);
              commit('updateApt', accountProfile.address2);
              commit('updateCity', accountProfile.city);
              commit('updateState', accountProfile.state);
              commit('updateZip', accountProfile.zip);
              commit('updateBirthdate', accountProfile.custDOB);
            }

            //Account Header
            if (acctHeader != null) {
              commit('updateApplNo', acctHeader.applNo);
              commit('updateCreditLimit', acctHeader.creditLimit);
              commit('updateAccountBalance', acctHeader.accountBalance);
              commit('updateAccountId', acctHeader.accountNumber);
              commit('updateAvailableCredit', acctHeader.availableCredit);
              commit('updateNextPayment', 0);
              commit('updateNextPaymentDate', acctHeader.nextPaymentDate);
              commit('updateMinimumPaymentDue', acctHeader.minPaymentAmount);
              commit('updatecurrentDue', acctHeader.currentDue);
              commit('updatePastDue', acctHeader.pastDueAmount);
              commit('updateDelinquent', acctHeader.isDelinquent);
              commit('updateChargeoff', acctHeader.isChargeoff);

              //  console.log("PendingTransactionAmount : " + acctHeader.PendingTransactionAmount + "PendingTranType: " + acctHeader.PendingTranType)
              commit('updatependingPayment', acctHeader.pendingTransactionAmount);
              commit('updatependingPaymentType', acctHeader.pendingTranType);
              commit('updatePreApprovedAmount', acctHeader.preApprovedAmount);
              commit('updateIsOriginated', acctHeader.isOriginated);
              commit('updateLoanStatusCode', acctHeader.loanStatusCode);
              commit('updateCashAdvanceFeePercent', jVal.accountHeader.cashAdvanceFeePercentage);
              commit('updateCreditUsedPercent', jVal.accountHeader.creditUsedPercent);

              if (acctHeader.requiresESignature) {
                commit('updateCreditLimit', acctHeader.preApprovedAmount);
                commit('updateFinalizeEsign', true);
                commit('updateIsLoading', false);
              }
              if (acctHeader.creditLimit == 0.0 && !acctHeader.requiresESignature) {
                if (acctHeader.loanStatusCode == 'G') {
                  commit('updateCreditLimit', acctHeader.preApprovedAmount);
                  commit('updateFinalizeApplication', true);
                  commit('updateIsLoading', false);
                }               
                else {
                  commit('updateConfirmEmail', accountProfile.custEmail);
                  commit('updateFinalizeApplication', false);
                  commit('updateFinalizeCredit', true);
                  commit('updateIsLoading', false);
                }
              }
            }

            //Account Information
            if (bankAcctInfo != null) {
              commit('updateBankAcctId', bankAcctInfo.accountID);
              commit('updateAccountType', bankAcctInfo.accountTypeDescription);
              commit('updateBankAccountLastFour', bankAcctInfo.accountNumber);
            }

            //Employer Information
            if (employerPayrollInfo != null) {
              commit('updateSource', employerPayrollInfo.incomeTypeCode);
              commit('updateEmployerName', employerPayrollInfo.employerName);
              commit('updateWorkPhone', employerPayrollInfo.workPhone);
              commit('updateExt', employerPayrollInfo.workPhoneExtension);

              commit('updatePaymentMethod', employerPayrollInfo.payrollTypeCode);
              commit('updateTakeHomePay', employerPayrollInfo.payrollAmount);
              commit('updatePaymentInterval', employerPayrollInfo.payFrequencyCode);
            }

            //update Preference flags
            if (customerpreferences != null) {
              commit('updateMarketingEmails', customerpreferences.allowMarketingEmails != null?customerpreferences.allowMarketingEmails:true);
              commit('updateMarketingCalls', customerpreferences.allowMarketingCalls);
              commit('updateMarketingTexts', customerpreferences.allowMarketingTexts);
              commit('updateServiceTexts', customerpreferences.allowServicingTexts);

            }
            //Config Values
            if (acctConfig != null) {
              commit('updateMinimumDrawAmount', acctConfig.minimumDrawAmount);
              commit('updateIsEligibleForDraw', acctConfig.isEligibleForDraw);
              commit('updateDrawMessage', acctConfig.drawMessage);
              commit('updateIncrementDrawAmount', acctConfig.incrementDrawAmount);
              commit('updateAchEffectiveDate', acctConfig.achEffectiveDate);
              commit('updateMinAchTransactionAmount', acctConfig.minAchTransactionAmount);
              commit('updateMaxPendingAchTranCount', acctConfig.maxPendingAchTranCount);
              commit('updateMaxNoOfAchEffectiveDays', acctConfig.maxNoOfAchEffectiveDays);
              commit('updateAllowSameDayACH', acctConfig.allowSameDayACH);

              let effdate = acctConfig.achEffectiveDate;

              if (acctConfig.achEffectiveDate != null) {
                commit("updatepaymentEffectiveDate", dayjs(effdate).format('YYYY-MM-DD'));
              }

              if (acctConfig.achEffectiveDate != null && acctConfig.maxNoOfAchEffectiveDays > 0) {
                let maxachbizDays = acctConfig.maxNoOfAchEffectiveDays;
                commit("updatemaxACHBizDate", dayjs(effdate).add(maxachbizDays, 'day').format('YYYY-MM-DD'));
              }

              var maxtrandate = dayjs(acctHeader.nextPaymentDate);
              if (maxtrandate < dayjs(effdate) || acctHeader.isChargeoff) {
                let maxachbizDays = acctConfig.maxNoOfAchEffectiveDays;
                maxtrandate = dayjs().add(maxachbizDays, 'day');
              }
              commit('updateMaxPaymentDate', maxtrandate);
            }

            if (jVal.recentStatements != undefined) {
              this.billingStatementsTableData = jVal.recentStatements.map((item) => {
                return {
                  date: item.eDocumentDate,
                  'download/view': item.eDocumentURL

                };
              })
            }
            else {
              this.billingStatementsTableData = []
            }

            if (jVal.allTransactions != undefined) {
              this.allTransactionsTableData = jVal.allTransactions.map((item) => {
                return {
                  'date': item.transationDate,
                  'amount': item.transationAmount,
                  'description': item.transationDescription,
                  'trans. id': item.transationId //== 0 ? 'pending' : item.transationId
                };
              })

            }
            else {
              this.allTransactionsTableData = []
            }

            if (jVal.recentTransactions != undefined) {
              this.recentTransactionsTableData = jVal.recentTransactions.map((item) => {
                return {
                  date: item.transationDate,
                  amount: item.transationAmount,
                  description: item.transationDescription,
                  'trans. id': item.transationId
                };
              })

              if(this.recentTransactionsTableData.length < 3 && this.allTransactionsTableData.length > this.recentTransactionsTableData.length) {
                // Have 3 transactions minimum in recent
                var iInd = this.recentTransactionsTableData.length;
                for (var iAdd = 3 - this.recentTransactionsTableData.length; iAdd > 0; iAdd--) {
                  if (iInd <= this.allTransactionsTableData.length - 1) {
                    this.recentTransactionsTableData.push(this.allTransactionsTableData[iInd]);
                  }
                  iInd++;
                }
              }
            }
            else {
              this.recentTransactionsTableData = []
            }

            if (jVal.allStatements != undefined) {
              this.allStatementsTableData = jVal.allStatements.map((item) => {
                return {
                  'billing statements': item.eDocumentDate,
                  'download/view': item.eDocumentURL
                };
              })
            }
            else {
              this.allStatementsTableData = []
            }

            if (jVal.pendingETransactions != undefined) {
              this.pendingTransactionsTableData = jVal.pendingETransactions.map((item) => {
                return {
                  "Pending": item.transactionDescription,
                  "pendingAmt": item.transationAmount,
                  "date": item.transationDate
                };
              })
            }
            else {
              this.pendingTransactionsTableData = []
            }

            //Transactions and Statements
            commit('updaterecentTransactionsTableData', this.recentTransactionsTableData);//jVal.recentTransactions);
            commit('updatebillingStatementsTableData', this.billingStatementsTableData);

            commit('updateallTransactionsTableData', this.allTransactionsTableData);//jVal.allTransactions);
            commit('updateallStatementsTableData', this.allStatementsTableData);//jVal.allStatements);

            //Pending Transactions
            commit('updatependingTransactionsTableData', this.pendingTransactionsTableData);
            var disablePayment = false;

            //if (this.pendingTransactionsTableData != undefined && this.pendingTransactionsTableData.length > 0) {
            // if (this.pendingTransactionsTableData.length > acctConfig.maxPendingAchTranCount) {
            //   disablePayment = true;
            // }
            // }
            commit('updatemaxPendingTranreached', disablePayment)
            // console.log("maxPendingTranreached: " + disablePayment)

            commit('updateIsLoading', false);
            return Promise.resolve(jVal);
          }
          else
            commit('updateIsLoading', false);
          return Promise.reject('Get Account Summary Call failed');
        }
        catch (ex) {
          commit('updateIsLoading', false);
        //  console.log(ex);
        }
      }
    },

    async savePreferences({ state }) {

      if (state.customerId > 0) {
        try {
          var auth = 'Bearer ' + state.token;
          var unMaskedCellPhone = state.cellPhone.replace(/-/g, "");
          var unMaskedMainPhone = state.mainPhone.replace(/-/g, "");
          var input="";
          if(state.cellPhone.length>0 )
          {
           input = JSON.stringify({
            custEmail: state.email,
            mobileNumber: unMaskedCellPhone,
            phoneNumber: unMaskedMainPhone,
            preferences: {
              allowMarketingEmails: state.marketingEmails,
              allowMarketingCalls: state.marketingCalls,
              allowMarketingTexts: state.marketingCalls   
            },
          })
        }
        else
          {
           input = JSON.stringify({
            custEmail: state.email,          
            phoneNumber: unMaskedMainPhone,
            preferences: {
              allowMarketingEmails: state.marketingEmails,
              allowMarketingCalls: state.marketingCalls,
              allowMarketingTexts: state.marketingCalls  
            },
          })
        }


          //alert(input);
          //console.log(input);
          const acctUpdateResponse = await fetch( $gMiddleLayerApi + `Account/UpdateProfile`, {
            "method": "POST",
            "body": input,
            "headers": {
              'Content-Type': 'application/json',
              'Authorization': auth
            }
          })

          //console.log(acctUpdateResponse);

          if (acctUpdateResponse.ok) {
            const jVal = await acctUpdateResponse.json();
          //  console.log(jVal);
            state.token = jVal.tokenToUse;
            //alert(jVal.statusDescription);               
            return Promise.resolve(jVal);
          }
          else {
            return Promise.reject('Unable to Save Preferences.');
            //alert("Unable to Save Preferences.");  
          }

        }

        catch (ex) {
       //   console.log(ex);
        }
      }
      //else  
      // {
      //  alert("Invalid Customer.")
      //}            
    },
    /**** Post Core Transaction *****/
    async postDrawTransaction({ state, commit }, { tranAmount, postDate }) {
      if (state.customerId > 0) {
        try {
          //Loader
          commit('updateIsLoading', true);
          // var currentDate = new Date();

          var auth = 'Bearer ' + state.token;
          var input = JSON.stringify({
            "applNo": state.applNo,
            "transactionDate": postDate,//currentDate,
            "transactionAmount": tranAmount,
            "transactionCode": "D",
            "isSameDay": false,
          })

          const postDrawResponse = await fetch( $gMiddleLayerApi + `Account/PostTransaction`,
            {
              "method": "POST",
              "body": input,
              "headers": {
                'Content-Type': 'application/json',
                'Authorization': auth
              }
            })

         // console.log(" in postDrawTransaction");
          if (postDrawResponse.ok) {
            const jVal = await postDrawResponse.json(); // Get JSON value from the response body
            state.token = jVal.tokenToUse;

            //Loader
            commit('updateIsLoading', false);
            commit('updaterefreshaccount', true);
            return Promise.resolve(jVal);
          }
          else
            //Loader
            commit('updateIsLoading', false);
          return Promise.reject('Post Draw transaction failed Call failed');
        }
        catch (ex) {
      //    console.log(ex);
        }
      }
    },

    async postPayment({ state, commit }, { tranAmount, postDate }) {
      if (state.customerId > 0) {
        try {
          //Loader
          commit('updateIsLoading', true);
        //  console.log("in post payment posting with : " + tranAmount + " " + dayjs(postDate).format('YYYY-MM-DD'))
          var auth = 'Bearer ' + state.token;
          var input = JSON.stringify({
            "applNo": state.applNo,
            "transactionDate": dayjs(postDate).format('YYYY-MM-DD'),
            "transactionAmount": tranAmount,
            "transactionCode": "P",
            "isSameDay": false,  //send this as true for merchants who want to do same day disbursement
          })

        //  console.log("input" + input)
          const postPmtResponse = await fetch( $gMiddleLayerApi + `Account/PostTransaction`,
            {
              "method": "POST",
              "body": input,
              "headers": {
                'Content-Type': 'application/json',
                'Authorization': auth
              }
            })

          //   console.log(" in postPayment");
          if (postPmtResponse.ok) {
            const jVal = await postPmtResponse.json(); // Get JSON value from the response body
            state.token = jVal.tokenToUse;
            //Loader
            commit('updateIsLoading', false);
            commit('updaterefreshaccount', true);
            return Promise.resolve(jVal);
          }
          else
            //Loader
            commit('updateIsLoading', false);
          return Promise.reject('Post Payment transaction failed Call failed');
        }
        catch (ex) {
         // console.log(ex);
        }
      }
    }

  }
}