import dayjs from 'dayjs';
// import store from '../store';

// var maxPaymentDate = store.state.maxPaymentDate;
// console.log(maxPaymentDate);
export const birthdateDisabledDates = {
  from: dayjs().subtract(18, 'years').toDate()
}
export const birthdateStartDate = dayjs().subtract(18, 'years').toDate();
export const birthPreviousDate = dayjs().subtract(150, 'years').toDate();
export const oneTimePaymentDisabledDates = {

  to: new Date(dayjs(new Date).add(1, 'day') - 8640000),
  from: dayjs().add(1, 'years').toDate(),
  // from: dayjs(maxPaymentDate).toDate(),
  days: [6, 0],
  customPredictor: function (ymd, date) {  
    // disables the date if it is federal holiday
    var nthWeekDay = Math.ceil(date.getDate() / 7)
    // Disable weekends (Sunday = `0`, Saturday = `6`)
    const weekdaychk=date.getDay();
    if (weekdaychk === 0 || weekdaychk === 6) { return true }
    // New Years Day (Jan 1, or preceding Friday/following Monday if weekend) and MLK Day(3rd monday in January)
    if (date.getMonth() == 11 && date.getDate() == 31 && date.getDay() == 5) { return true }
    if (date.getMonth() == 0) {
      if (date.getDate() == 1 && !(date.getDay() == 6 || date.getDay() == 0)) { return true }
      if (date.getDate() == 2 && date.getDay() == 2) { return true }
      if (nthWeekDay == 3 && date.getDay() == 1) { return true }
    }
    //President’s Day (3rd Monday in February)
    if (nthWeekDay == 3 && date.getDay() == 1 && date.getMonth() == 1) { return true }
    // Memorial Day (Last Monday in May)
    if (date.getMonth() == 4) {
      var newDate = new Date(date.getYear(), date.getMonth(), date.getDate())
      newDate.setDate(newDate.getDate() + 7)
      if (date.getDay() == 1 && newDate.getMonth() == 5) { return true }
    }
    // Independence Day (July 4, or prededing friday/following monday if weekend
    if (date.getMonth() == 6) {
      if (date.getDate() == 3 && date.getDay() == 5) { return true }
      if (date.getDate() == 4 && !(date.getDay() == 6 || date.getDay() == 0)) { return true }
      if (date.getDate() == 5 && date.getDay() == 1) { return true }
    }
    //Labor Day (1st Monday in September)
    if (nthWeekDay == 1 && date.getDay() == 1 && date.getMonth() == 8) { return true }
    // Columbus Day (2nd Monday in October)
    if (nthWeekDay == 2 && date.getDay() == 1 && date.getMonth() == 9) { return true }
    //Veteran's Day (November 11, or preceding Friday/following Monday if weekend
    // Thanksgiving Day (4th Thursday in November)
    if (date.getMonth() == 10) {
      if (date.getDate() == 10 && date.getDay() == 5) { return true }
      if (date.getDate() == 11 && !(date.getDay() == 6 || date.getDay() == 0)) { return true }
      if (date.getDate() == 12 && date.getDay() == 1) { return true }
      if (nthWeekDay == 4 && date.getDay() == 4) { return true }
    }
    //Christmas Day (December 25, or preceding Friday/following Monday if weekend)
    if (date.getMonth() == 11) {
      if (date.getDate() == 24 && date.getDay() == 5) { return true }
      if (date.getDate() == 25 && !(date.getDay() == 6 || date.getDay() == 0)) { return true }
      if (date.getDate() == 26 && date.getDay() == 1) { return true }
    }
  }
  // custom predictor end
}

export const oneTimePaymentStartDate = dayjs(new Date).add(1, 'day');
export const acsOneTimePaymentDisabledDates = {

  to: new Date(dayjs(new Date).add(1, 'day') - 8640000),
  from: dayjs().add(45, 'day').toDate(),
//  from: dayjs(store.getters.maxPaymentDate).toDate(),
  days: [6, 0],
  customPredictor: function (ymd, date) {
    // disables the date if it is federal holiday
    var nthWeekDay = Math.ceil(date.getDate() / 7)
    // Disable weekends (Sunday = `0`, Saturday = `6`)
    const weekdaychk=date.getDay();
    if (weekdaychk === 0 || weekdaychk === 6) { return true }
    // New Years Day (Jan 1, or preceding Friday/following Monday if weekend) and MLK Day(3rd monday in January)
    if (date.getMonth() == 11 && date.getDate() == 31 && date.getDay() == 5) { return true }
    if (date.getMonth() == 0) {
      if (date.getDate() == 1 && !(date.getDay() == 6 || date.getDay() == 0)) { return true }
      if (date.getDate() == 2 && date.getDay() == 2) { return true }
      if (nthWeekDay == 3 && date.getDay() == 1) { return true }
    }
    //President’s Day (3rd Monday in February)
    if (nthWeekDay == 3 && date.getDay() == 1 && date.getMonth() == 1) { return true }
    // Memorial Day (Last Monday in May)
    if (date.getMonth() == 4) {
      var newDate = new Date(date.getYear(), date.getMonth(), date.getDate())
      newDate.setDate(newDate.getDate() + 7)
      if (date.getDay() == 1 && newDate.getMonth() == 5) { return true }
    }
    // Independence Day (July 4, or prededing friday/following monday if weekend
    if (date.getMonth() == 6) {
      if (date.getDate() == 3 && date.getDay() == 5) { return true }
      if (date.getDate() == 4 && !(date.getDay() == 6 || date.getDay() == 0)) { return true }
      if (date.getDate() == 5 && date.getDay() == 1) { return true }
    }
    //Labor Day (1st Monday in September)
    if (nthWeekDay == 1 && date.getDay() == 1 && date.getMonth() == 8) { return true }
    // Columbus Day (2nd Monday in October)
    if (nthWeekDay == 2 && date.getDay() == 1 && date.getMonth() == 9) { return true }
    //Veteran's Day (November 11, or preceding Friday/following Monday if weekend
    // Thanksgiving Day (4th Thursday in November)
    if (date.getMonth() == 10) {
      if (date.getDate() == 10 && date.getDay() == 5) { return true }
      if (date.getDate() == 11 && !(date.getDay() == 6 || date.getDay() == 0)) { return true }
      if (date.getDate() == 12 && date.getDay() == 1) { return true }
      if (nthWeekDay == 4 && date.getDay() == 4) { return true }
    }
    //Christmas Day (December 25, or preceding Friday/following Monday if weekend)
    if (date.getMonth() == 11) {
      if (date.getDate() == 24 && date.getDay() == 5) { return true }
      if (date.getDate() == 25 && !(date.getDay() == 6 || date.getDay() == 0)) { return true }
      if (date.getDate() == 26 && date.getDay() == 1) { return true }
    }
  }
  // custom predictor end
}

export const isBetween = require('dayjs/plugin/isBetween');

//dayjs.extend(isBetween);
export const lastTimePaymentDisabledDates = {
  from: dayjs().subtract(1, 'day').toDate()
  
}
export const lastTimePaymentStartDate = dayjs().toDate();

