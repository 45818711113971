<template>
  <main>

    <PageHeader>Thanks for choosing XactFlex.</PageHeader>

    <FormSteps>
      <div>
        Message 
      </div>
    </FormSteps>

    <section class="form">

      <SectionTitle>
        Welcome to XactFlex
        <template v-slot:subtitle>
        <span STYLE="text-transform:none"></span>
        </template>
      </SectionTitle>
      <br/>
      <br/>
      <div class="message">Your line of credit application is conditionally approved, but it appears that an error may have occurred during processing.</div>
      <br/><br/>
      <div v-if="!isAppGuidBlank">
        <div class="message">Please call 833-793-1150 as soon as possible to complete your application. You’ll need to provide this information:</div>
        <br/>
        <div class="message">*Application ID: {{ApplicationID}}</div>
        <br/>
      </div>
      <br/>
      <br/>
    </section>

    <div class="bottom">
      <div class="thanks">Thanks for choosing XactFlex.</div>
      <router-link :to="{ name: 'home' }">Back to Homepage</router-link>
    </div>
  </main>
</template>

<script>
import { PageHeader, SectionTitle, FormSteps } from '@/components';
export default {
  name: 'LeadPostError',
  title: 'Thanks for Choosing XactFlex',
  components: { PageHeader, SectionTitle, FormSteps },
  async created() {
      if (this.$route.query.appid == undefined)
      {
        this.ApplicationID = '';
        this.isAppGuidBlank = true;
      }
      else
      {
        this.ApplicationID = this.$route.query.appid;
        this.isAppGuidBlank = false;
      }

  },
    data() {
    return {
      ApplicationID: '',
      isAppGuidBlank:false
    }
  }
}
</script>

<style scoped lang="scss">
main {
  width: 100%;
  
  .message {
    font-size: 16px;
    
  }
  .note {
    font-size: 14px;
    color: $gray;
    font-style: italic;
    margin: 0 140px;
  }

  .todos {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 50px 140px 100px;

    .todo {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      .desc {
        width: 230px;
        margin-left: 16px;

        .bold {
          font-weight: 700;
        }
      }
    }
  }

  .bottom {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    .thanks {
      font-size: 20px;
      color: $green;
      margin: 20px 0 10px;
    }

    a {
      color: $gray;
      font-size: 16px;
      border-bottom: 1px solid $gray;
    }
  }

  @media (max-width: 1023px) {
    .note {
      margin: 0 10px;
    }

    .todos {
      padding: 30px 10px 30px;
    }
  }
}
</style>