<template>
  <div class="bgcontainer">
    <div class="bglayout">
      <PageHeaderApplication>
        Line of Credit Application - Address
        <template v-slot:subtitle>
          <span>Find out if you qualify in these simple steps.</span>
        </template>
      </PageHeaderApplication>

      <main>
        <v-form
          @invalid-submit="onIncompleteSection"
          @submit="onCompleteSection"
          :validation-schema="currentSchema"
          keep-values
          v-slot="{ errors }"
        >
          <section>
            <AppNav :navselected="2" />
            <fieldset class="form">
              <h3 class="contentheader" id="tittleaddress" tabindex="-1">
                Tell us your contact information.
              </h3>
              <legend class="visually-hidden">
                Line of Credit Application - ADDRESS
              </legend>
              <div class="inputs">
                <FormField :errormsg="errors['streetaddress']">
                  <template v-slot:label>
                    <label for="inpstreet" class="applabel"
                      >Street Address</label
                    >
                  </template>
                  <v-field
                    id="inpstreet"
                    name="streetaddress"
                    v-model="streetAddress"
                    type="text"
                    aria-describedby="streetaddress-errormsg"
                  />
                  <template v-slot:error>
                    <v-error id="streetaddress-errormsg" name="streetaddress" />
                  </template>
                </FormField>

                <FormField :errormsg="errors['apt']">
                  <template v-slot:label>
                    <label for="apt" class="applabel"
                      >Apt/Suite #
                    </label></template
                  >
                  <v-field name="apt" v-model="apt" id="apt" type="text" />
                  <template v-slot:error>
                    <v-error id="apt-errormsg" name="apt" />
                  </template>
                </FormField>
              </div>
              <div class="inputs">
                <FormField :errormsg="errors['city']">
                  <template v-slot:label>
                    <label for="city" class="applabel">City</label></template
                  >
                  <v-field
                    name="city"
                    id="city"
                    v-model="city"
                    type="text"
                    aria-describedby="city-errormsg"
                  />
                  <template v-slot:error>
                    <v-error id="city-errormsg" name="city" />
                  </template>
                </FormField>
                <div>
                  <div class="state-zip">
                    <FormField :errormsg="errors['state']" class="short">
                      <template v-slot:label>
                        <label for="state" class="applabel"
                          >State</label
                        ></template
                      >
                      <div
                        class="select"
                        @mouseover="hideStateMsg()"
                        @mouseout="showStateMsg()"
                      >
                        <v-field
                          name="state"
                          id="state"
                          v-model="state"
                          aria-describedby="state-errormsg"
                          as="select"
                        >
                          <option :selected="true" value="0">Select</option>
                          <option
                            v-for="selectedState in stateWithCodes"
                            :disabled="
                              invalidStatesWithCodes.includes(
                                selectedState.value
                              )
                            "
                            :key="selectedState.value"
                            :value="selectedState.value"
                          >
                            {{ selectedState.text }}
                          </option>
                        </v-field>
                        <i class="fas fa-chevron-down icon"></i>
                      </div>
                      <template v-slot:error>
                        <v-error id="state-errormsg" name="state" />
                      </template>
                    </FormField>

                    <FormField :errormsg="errors['zip']" class="short">
                      <template v-slot:label>
                        <label for="zip" class="applabel"
                          >Zip Code &nbsp;&nbsp; #####</label
                        ></template
                      >
                      <v-field v-model.trim="zip" name="zip" v-slot="{ field }">
                        <input
                          v-bind="field"
                          id="zip"
                          v-on:keypress="isNumber($event)"
                          @keydown="limitNums"
                          maxLength="5"
                          style="width: 100%"
                          aria-describedby="zip-errormsg"
                        />
                      </v-field>
                      <template v-slot:error>
                        <v-error id="zip-errormsg" name="zip" />
                      </template>
                    </FormField>
                  </div>
                  <div class="select-overlay italic">
                    {{ legal.statesNoBusiness }}
                  </div>
                </div>
              </div>
              <div class="inputs">
                <FormField :errormsg="errors['mainphone']">
                  <template v-slot:label>
                    <label for="mainphone" class="applabel">Main Phone</label>
                    <span style="font-weight: bold; padding-left: 4px"
                      >&nbsp; &nbsp; &nbsp; ###-###-####</span
                    >
                  </template>
                  <span style="flex-direction: column; width: 94%">
                    <div style="display: flex; flex-direction: row; width: 94%">
                      <label class="input-checkbox-wrapper" style="left: -3px">
                        <span class="cellphonecheckerbox"
                          ><label id="lblchk" for="chkMaincell">
                            This is my cell phone</label
                          ></span
                        >
                        <input
                          id="chkMaincell"
                          name="chkMaincell"
                          type="checkbox"
                          v-model="mainIsCell"
                          tabindex="-1"
                        />
                        <span
                          role="checkbox"
                          class="input-checkbox"
                          tabindex="0"
                          :aria-checked="mainIsCell"
                          aria-describedby="lblchk"
                          aria-label="This is my cell phone"
                        />
                      </label>
                      <div class="helpiconcust">
                        <HelpIcon :message="help.cellPhone" :id="'phoneinfo'" />
                      </div>
                    </div>
                    <div style="height: 5px; width: 100%"></div>
                    <v-field
                      v-model.trim="mainPhone"
                      name="mainphone"
                      v-slot="{ field }"
                    >
                      <input
                        v-bind="field"
                        id="mainphone"
                        aria-describedby="phoneinfo-errmsg"
                        v-on:keypress="isNumber($event)"
                        @keydown="limitNums"
                        maxLength="12"
                        style="width: 100%"
                      />
                    </v-field>
                  </span>
                  <template v-slot:error>
                    <v-error id="phoneinfo-errmsg" name="mainphone" />
                  </template>
                </FormField>
                <div class="cell-check" v-if="mainIsCell"></div>
              </div>
              <div class="inputs" v-if="!mainIsCell">
                <FormField :errormsg="errors['cellphone']">
                  <template v-slot:label>
                    <label for="cellphone" class="applabel"
                      >Cell Phone
                      <span class="italic">optional</span>&nbsp;&nbsp;
                      ###-###-####</label
                    >
                  </template>
                  <v-field
                    v-model.trim="cellPhone"
                    name="cellphone"
                    v-slot="{ field }"
                  >
                    <input
                      v-bind="field"
                      id="cellphone"
                      v-on:keypress="isNumber($event)"
                      @keydown="limitNums"
                      maxLength="12"
                      type="text"
                      aria-describedby="cellphone-errormsg"
                    />
                  </v-field>
                  <template v-slot:error>
                    <v-error id="cellphone-errormsg" name="cellphone" />
                  </template>
                </FormField>
              </div>
            </fieldset>
            <div class="upperbottom" role="alert" v-show="showcommonerrormsg">
              {{ help.commonapperror }}
            </div>
            <div class="bottom">
              <button class="button-orange">CONTINUE</button>
            </div>
          </section>
        </v-form>
      </main>
    </div>
  </div>
</template>

<script>
import legal from "@/config/text/legal";
import help from "@/config/text/help";
import { FormField, HelpIcon } from "@/components";
//import { Validator } from "vee-validate";
import AppNav from "@/components/application/AppNav.vue";
import { limitNums, limitAlphabates } from "@/utils/plugins/inputs";
import { stateWithCodes, invalidStatesWithCodes } from "@/config/states";
import PageHeaderApplication from "@/components/global/PageHeaderApplication";
import * as Yup from "yup";
export default {
  name: "AppAddress",
  title: "Line of Credit Application",
  components: {
    PageHeaderApplication,
    FormField,
    HelpIcon,
    AppNav,
  },
  created() {
    if (!this.$store.state.application.personalComplete) {
      this.$router.replace({ name: "app-personal" });
    }
    if (this.ip == null) {
      this.$store.dispatch("findIPAddress");
    }
    if (
      this.$store.state.application.state == "" ||
      this.$store.state.application.state == null
    ) {
      this.$store.state.application.state = "0";
    }

    if (this.$store.state.application.mainIsCell) {
      this.currentStep = 0;
    } else {
      this.currentStep = 1;
    }
  },
  data() {
    let streetaddresserrormsg = "Please enter your street address";
    //let apterrormsg = "Please enter your city";
    let cityerrormsg = "Please enter your city";
    let stateerrormsg = "Please select your state";
    let zipcodeerrormsg = "Please enter your 5-digit zip code";
    let mainphoneerrormsg = "Please enter your 10-digit main phone number";
    let cellphoneerrormsg = "Please enter your 10-digit cell phone number";
    const schema = [
      Yup.object().shape({
        streetaddress: Yup.string().required(streetaddresserrormsg),
        //apt: Yup.string().required(apterrormsg),
        city: Yup.string().required(cityerrormsg),
        state: Yup.string().notOneOf(["Select", "0"], stateerrormsg),
        zip: Yup.string().required(zipcodeerrormsg).min(5, zipcodeerrormsg),
        mainphone: Yup.string()
          .required(mainphoneerrormsg)
          .min(10, mainphoneerrormsg),
      }),
      Yup.object().shape({
        streetaddress: Yup.string().required(streetaddresserrormsg),
        //apt: Yup.string().required(apterrormsg),
        city: Yup.string().required(cityerrormsg),
        state: Yup.string().notOneOf(["Select", "0"], stateerrormsg),
        zip: Yup.string().required(zipcodeerrormsg).min(5, zipcodeerrormsg),
        mainphone: Yup.string()
          .required(mainphoneerrormsg)
          .min(10, mainphoneerrormsg),        
        cellphone: Yup.string().nullable()        
          .test("len", cellphoneerrormsg, (val) => {
            if (val == undefined) {
              return true;
            }
            return val.length < 1 || val.length === 12;
          }),
      }),
    ];
    return {
      schema,
      currentStep: 0,
      legal: legal,
      help: help,
      showcommonerrormsg: false,
      stateWithCodes: stateWithCodes,
      invalidStatesWithCodes: invalidStatesWithCodes,
    };
  },
  computed: {
    currentSchema: {
      get() {
        return this.schema[this.currentStep];
      },
    },
    isFormValid: function () {
      return !Object.values(this.fields).filter((field) => !field.valid).length;
    },
    streetAddress: {
      get() {
        return this.$store.state.application.streetAddress;
      },
      set(value) {
        this.$store.commit("updateStreetAddress", value);
      },
    },
    apt: {
      get() {
        return this.$store.state.application.apt;
      },
      set(value) {
        this.$store.commit("updateApt", value);
      },
    },
    city: {
      get() {
        return this.$store.state.application.city;
      },
      set(value) {
        this.$store.commit("updateCity", value);
      },
    },
    state: {
      get() {
        return this.$store.state.application.state;
      },
      set(value) {
        this.$store.commit("updateState", value);
      },
    },
    zip: {
      get() {
        return this.$store.state.application.zip;
      },
      set(value) {
        this.$store.commit("updateZip", value);
      },
    },
    mainPhone: {
      get() {
        return this.$store.state.application.mainPhone;
      },
      set(value) {
        this.$store.commit("updateMainPhone", value);
      },
    },
    cellPhone: {
      get() {
        return this.$store.state.application.cellPhone;
      },
      set(value) {
        this.$store.commit("updateCellPhone", value);
      },
    },
    mainIsCell: {
      get() {
        return this.$store.state.application.mainIsCell;
      },
      set(value) {
        this.$store.commit("updateMainIsCell", value);
        if (value) {
          this.currentStep = 0;
        } else {
          this.currentStep = 1;
        }
      },
    },
  },
  mounted() {
    this.focusfirstelement();
    document.getElementById("divaddress").setAttribute("aria-current", "step");
  },
  methods: {
    focusfirstelement() {
      document.getElementById("tittleaddress").focus();
    },
    validatezip() {
      if (this.zip == null || this.zip == undefined || this.zip == "") {
        this.$validator.validate("zip");
        return true;
      }
    },
    onIncompleteSection() {
      this.showcommonerrormsg = true;
    },
    async onCompleteSection() {
      this.showcommonerrormsg = false;
      this.$store.commit("updatecompleteAddress", true);
      this.$router.push({ name: "app-income" });
      /*
      this.$validator.validate().then((valid) => {
        if (valid) {
          this.showcommonerrormsg = false;
          this.$store.commit("updatecompleteAddress", true);
          this.$router.push({ name: "app-income" });
        } else {
          this.showcommonerrormsg = true;
        }
      });
      */
    },
    hideStateMsg() {
      if (this.state == null) {
        this.state = "";
      }
    },
    showStateMsg() {
      if (this.state == "") {
        this.state = null;
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    isDatestring(e) {
      var char = String.fromCharCode(e.keyCode); // Get the character
      if (char.match(/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/))
        return true;
      else e.preventDefault();
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    allowonlynumbersandbackslash(event) {
      var keyCode = event.keyCode;
      var isShift = false;
      if (keyCode == 16) {
        isShift = true;
      }
      //Allow only Numeric Keys.
      if (
        ((keyCode >= 47 && keyCode <= 57) ||
          keyCode == 8 ||
          keyCode <= 37 ||
          keyCode <= 39) &&
        isShift == false
      ) {
        if (keyCode == 47) {
          if (
            event.target.value != null &&
            (event.target.value.length == 2 ||
              event.target.value.length == 5) &&
            keyCode != 8
          ) {
            return true;
          } else {
            event.preventDefault();
          }
        } else {
          if (
            event.target.value != null &&
            (event.target.value.length == 2 ||
              event.target.value.length == 5) &&
            keyCode != 8
          ) {
            event.target.value = event.target.value + "/";
          }
        }
      } else {
        event.preventDefault();
      }
    },
    limitNums,
    limitAlphabates,
  },
};
</script>

<style scoped lang="scss">
main {
  width: 100%;

  section {
    display: flex;
    flex-flow: column nowrap;
    max-width: 1023px !important;

    @media (max-width: 767px) {
      margin-left: 15px !important;
      margin-right: 15px !important;
    }
  }
  .form {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    padding: 30px 20px 30px 20px;
    margin-top: 23px;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid $gray;
    justify-content: space-evenly;
    align-items: left;
    border-radius: 6px;

    @media (max-width: 767px) {
      max-width: 720px;
      margin: 5% 0px !important;
      padding: 20px !important;
    }
    .inputs {
      display: flex;
      flex-flow: row nowrap;

      @media (max-width: 767px) {
        flex-flow: column nowrap;
      }
    }
  }

  .applabel {
    font-weight: 600;
  }

  .cellphonecheckerbox {
    margin-bottom: 5px;
    text-transform: none;
    @media (max-width: 380px) {
      font-size: 11px;
    }
  }

  .contentheader {
    font-weight: 600;
    margin-left: 80px;
    margin-bottom: 10px;
    @media (min-width: 768px) and (max-width: 1023px) {
      margin-left: 15px;
    }
    @media (max-width: 767px) {
      margin-left: 5px;
      margin-right: 15px;
      font-size: 20px;
    }

    @media (max-width: 428px) {
      margin-right: 5px;
      font-size: 18px;
    }

    @media (max-width: 380px) {
      font-size: 15px;
    }
  }

  input {
    border: solid 1px $fieldborder-gray;
    padding: 3px 5px 3px 5px;
  }

  .field {
    margin-left: 75px;
    margin-right: 75px;
    @media (min-width: 768px) and (max-width: 1023px) {
      margin-left: 10px;
      margin-right: 20px;
    }
    @media (max-width: 767px) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .select {
    display: block;
    height: 26px;
    border: 1px solid $fieldborder-gray;
  }

  .select:after {
    display: none;
  }

  select {
    color: $gray;
    padding: 0px 15px 0px 15px;
  }

  option {
    font-size: 11px;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  .icon {
    position: absolute;
    color: $orange;
    font-size: 22px;
    cursor: pointer;
    pointer-events: none;
    margin-left: -22px;
    margin-top: 3px;
  }

  .radio-input {
    margin: 20px 120px 0 0;
  }

  .bottom {
    display: block;
    text-align: right;
    margin-top: 15px;

    @media (min-width: 768px) and (max-width: 1023px) {
      margin-left: 18px;
      margin-right: 18px;
    }
  }
  .upperbottom {
    text-align: right;
    color: $error;
    margin: 15px 0 10px;
    @media (max-width: 767px) {
      text-align: center;
      margin-top: 0px;
    }
  }

  .privacy {
    padding-right: 80px;
    a {
      color: $blue;
      border-color: $blue;
    }

    @media (max-width: 767px) {
      flex-flow: column nowrap;
      padding: 0px;
    }
  }

  .subnote {
    height: 15px;
    width: 240px;
    margin: 20px auto;
  }

  .error {
    height: 15px;
    max-width: 312px;
    margin: 0 5px;
    font-size: 13px;
    color: $red;
  }

  .repaymentMethod {
    position: relative;
    margin-top: 20px;
  }
  .repaymentMethodSpan {
    width: 350px;
    position: absolute;
  }

  @media (max-width: 1023px) {
    .banking-info {
      flex-flow: column nowrap;
      align-items: center;
    }

    .radio-input {
      margin: 20px 20px 0 0;
    }

    .bottom {
      .inputs {
        flex-flow: column-reverse nowrap;
        align-items: center;

        button {
          margin-bottom: 10px;
        }
      }
    }
  }

  .button-orange {
    @media (min-width: 768px) and (max-width: 1023px) {
      width: 30%;
    }
    @media (max-width: 767px) {
      width: 50%;
      max-width: inherit;
    }
    @media (max-width: 420px) {
      padding: 6px 33px;
    }
    @media (max-width: 380px) {
      padding: 6px 15px;
    }
  }

  @media (max-width: 767px) {
    .bottom {
      margin-top: 0;
      text-align: center;
    }
  }
}

.state-zip {
  display: flex;
  flex-flow: row nowrap;
  margin-left: 62px;
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-left: 15px;
  }
  @media (max-width: 767px) {
    margin-left: 0px;
  }

  .field.short {
    max-width: 160px;
    width: auto;
    margin-left: 15px;
    margin-right: 0px;
    @media (max-width: 767px) {
      width: 100%;
      max-width: inherit;
      margin-left: 5px !important;
    }
  }

  .field.short input {
    width: auto;
  }
}

.select-overlay {
  position: absolute;
  font-size: 12px;
  margin-top: 5px;
  margin-left: 75px;

  @media (min-width: 768px) and (max-width: 1023px) {
    margin-left: 15px;
  }
  @media (max-width: 767px) {
    margin-left: 5px;
    position: relative;
  }
}

.select {
  border: 1px solid $light-gray;
}

.input-checkbox-wrapper {
  text-transform: math-auto;
}

.input-checkbox {
  position: absolute;
  top: 2px;
  left: 10px;
  height: 12px;
  width: 12px;
  background-color: $absolute-white;
  border: 2px solid $orange;
  border-radius: 3px;
}

.input-checkbox:after {
  content: "";
  position: absolute;
  display: none;
}
.input-checkbox-wrapper input:checked ~ .input-checkbox:after {
  display: block;
}
.input-checkbox-wrapper .input-checkbox:after {
  content: "\2713\0020";
  left: 0px;
  top: 0px;
  width: 12px;
  height: 12px;
  color: $absolute-white;
  font-size: 12px;
  font-weight: 600;
  background-color: $orange;
  text-align: center;
  // border-radius: 2px;
}
input[type="checkbox"]:disabled {
  cursor: not-allowed;
}
</style>