<template>
  <main>

    <PageHeader>Error 404</PageHeader>

    <div class="bottom">
      <div class="thanks">We couldn't find that page!.</div>
      <router-link :to="{ name: 'home' }">Back to Homepage</router-link>
    </div>

  </main>
</template>

<script>
import { PageHeader } from '@/components';
export default {
  name: '404NotFound',
  title: 'You line to financial peace of mind',
  components: { PageHeader }
}
</script>

<style scoped lang="scss">
main {
  width: 100%;

  .bottom {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin: 100px auto;

    .thanks {
      font-size: 30px;
      color: $green;
      margin: 20px 0 10px;
    }

    a {
      color: $gray;
      font-size: 16px;
      border-bottom: 1px solid $gray;
    }
  }
}
</style>