<template>
  <Header />
  <router-view id="content" v-slot="{ Component }">
    <component :is="Component" />
  </router-view>
  <Footer />
  <DocumentModal />
</template>

<script>
import { watch } from "vue";
import { Header, Footer, DocumentModal } from "@/components";
import { useCounter, useIdle } from "@vueuse/core";
import { useStore } from "vuex";

export default {
  name: "XactFlexApp",
  components: { Header, Footer, DocumentModal },
  setup() {
    const { inc } = useCounter();
    const { idle, reset } = useIdle(process.env.VUE_APP_INACTIVE_LOGOUT_TIMEINSECONDS);
    //const { idle, reset } = useIdle(20*1000); // 20 sec
    const store = useStore();

    watch(idle, (idleValue) => {      
      if (idleValue && store.getters.loggedIn) {
        inc();
        //console.log(`Triggered ${count.value} times`);
        reset(); // restarts the idle timer. Does not change lastActive value
        store.dispatch("logout");
      }
    });
  },
  watch: {
    items: {      
      deep: true,
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "AvertaStd-Regular";
  src: local("AvertaStd-Regular"),
    url("./assets/fonts/AvertaStd-Regular.otf") format("opentype");
}
@font-face {
  font-family: "AvertaStd-RegularItalic";
  src: local("AvertaStd-RegularItalic"),
    url("./assets/fonts/AvertaStd-RegularItalic.otf") format("opentype");
}
@font-face {
  font-family: "AvertaStd-Bold";
  src: local("AvertaStd-Bold"),
    url("./assets/fonts/AvertaStd-Bold.otf") format("opentype");
}
@font-face {
  font-family: "AvertaStd-BoldItalic";
  src: local("AvertaStd-BoldItalic"),
    url("./assets/fonts/AvertaStd-BoldItalic.otf") format("opentype");
}
@font-face {
  font-family: "AvertaStd-Light";
  src: local("AvertaStd-Light"),
    url("./assets/fonts/AvertaStd-Light.otf") format("opentype");
}
@font-face {
  font-family: "AvertaStd-LightItalic";
  src: local("AvertaStd-LightItalic"),
    url("./assets/fonts/AvertaStd-LightItalic.otf") format("opentype");
}
@font-face {
  font-family: "AvertaStd-Semibold";
  src: local("AvertaStd-Semibold"),
    url("./assets/fonts/AvertaStd-Semibold.otf") format("opentype");
}

@font-face {
  font-family: "AvertaDemo-Regular";
  src: local("AvertaDemo-Regular"),
    url("./assets/fonts/AvertaDemo-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue"),
    url("./assets/fonts/HelveticaNeue.ttf") format("truetype");
}

body {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-primary;
  font-size: 14px;
  //@include gradient-black-white;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  min-width: 360px;
  //background-image: url('assets/backgrounds/wave-bg.svg');
  background-repeat: no-repeat;
  //background-position: 50% 650px;
  background-position: 0% 135px;
  background-size: 2000px auto;
  background-origin: content-box;

  @media (max-width: 1322px) {
    background-position: 0% 660px;
    background-size: 1322px auto;
  }
  @media (max-width: 1023px) {
    background-position: 50% 300px;
    background-size: 1023px auto;
  }
}
.maincontent::before {
  background-image: url("./assets/backgrounds/xactflex-hero-background@2x.jpg");
  background-attachment: fixed;
}

#content {
  width: 100%;
  min-height: 100%;
  flex: 1;
  margin: 0 auto 0 auto;
  padding-bottom: 70px;
  background-color: #ffffff;

  @media (max-width: 1023px) {
    margin: 73px 0 0 0;
  }

  section {
    max-width: 1324px;
    margin-left: auto;
    margin-right: auto;
  }
}

.hide-small {
  display: inherit;

  @media (max-width: 1023px) {
    display: none;
  }
}

.hide-large {
  display: none;

  @media (max-width: 1023px) {
    display: inherit;
  }
}

.hide-small-s {
  display: inherit;

  @media (max-width: 480px) {
    display: none;
  }
}

.hide-large-s {
  display: none;

  @media (max-width: 480px) {
    display: none;
  }
}

* {
  -webkit-tap-highlight-color: transparent;
}
</style>