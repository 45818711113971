export default {
  passwordRules: '8 to 20 characters and have at least one uppercase letter, one lowercase letter, one number, one special character (ex. !@%$&*) and no spaces.',
  social: 'We use your social security number to verify your identity and check your history with us.',
  cellPhone: 'Please uncheck if this is not a mobile phone.',
  workPhone: 'We use this number for employment verification.',
  eft: 'By selecting this option, you are authorizing the lender to automatically deduct line of ' +
    'credit payments from your bank account through the Automated Clearing House (ACH) Network.',
  txtaCheck: 'TxtaCheck is a service provider that creates checks for your payments, after you provide your ' +
    'signature. TxtaCheck will provide these checks to the lender. If you choose this option, you’ll receive text ' +
    'messages (carrier charges may apply) or emails for you to set up and sign the checks.',
  cashAdvanceFee: 'You pay a Cash Advance Fee whenever you make a draw from your credit line. The Cash Advance Fee is calculated based on a percentage of your requested draw. We deduct the Cash Advance Fee from your total draw amount, and the remaining deposit amount is the amount that you will receive in your banking account.',
  cashAdvanceFeeDraw: 'You pay a Cash Advance Fee whenever you make a draw from your credit line. The Cash Advance Fee is calculated based on a percentage of your requested draw. We deduct the Cash Advance Fee from your total draw amount, and the remaining amount is what you will receive in your bank account.',
  depositAmount: 'This is the amount that we will transfer into your bank account after we deduct the Cash Advance Fee from your total draw.',
  dontWantDraw:'You have up to a year to request a draw. If you don’t request a draw within a year, your line of credit will automatically close.',
  commonapperror:'Please complete all sections',
}