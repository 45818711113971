<template>
  <article class="apply-bug">
    <div class="closed" @click="applyNow();">
      <div class="hide-small">
        <i class="fa fa-pencil-alt fa-2x"/>
        <span>
          Apply<br>Now
        </span>
      </div>
      <div class="bottom hide-large">
        <div class="title">
          Lines of credit up to {{ currencyShort(maximumCreditAmount) }}.
          <sup class="small">†</sup>
        </div>
        <div class="action">
          Apply Now
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import { maximumCreditAmount } from "@/config/amounts";
import { currencyShort } from "@/utils/filters/currency";
export default {
  name: 'ApplyNowPill',
  data() {
    return {
      maximumCreditAmount: maximumCreditAmount,
      currencyShort: currencyShort
    }
  },
  methods:
  {
     applyNow()
       {
          this.$store.commit('resetApplication');
          this.$router.push({ name: 'app-personal' });
          return true;
       },
  }
}
</script>

<style scoped lang="scss">
.apply-bug {

  .closed {
    position: fixed;
    top: 30%;
    right: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 6px 30px 6px 10px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background: $purple;
    color: $absolute-white;
    font-weight: 700;
    line-height: 14px;
    width: 100px;

    &:hover {
      background: $purple-hover;
    }

    i {
      margin-right: 4px;
    }
  }

  .small {
    font-size: 12px;
  }

  @media (max-width: 1023px) {

    .closed {
      top: initial;
      right: initial;
      left: initial;
      bottom: 0;
      border-radius: 0;
      width: 100%;
      padding: 0px;

      .bottom {
        display: flex;
        flex-flow: column;
        align-items: stretch;
        width: 100%;
        text-align: center;
      }

      .title {
        background: $absolute-white;
        color: $purple;
        height: 24px;
        width: 100%;
        font-size: 18px;
        font-weight: 700;
        padding: 10px 0 0 0;
      }

      .action {
        font-size: 30px;
        padding: 20px;
      }
    }
  }
}
</style>