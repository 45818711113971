const initialState = {
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  messageCategory: null,
  message: '',
}

//import Vue from 'vue';
import {$gMiddleLayerApi} from '../config/appconfigsettings';
//import router from '@/router/';
import {
  formatPhone
} from "@/utils/plugins/inputs";

export default {
  state: () => {
    return {
      ...initialState
    }
  },
  getters: {},
  mutations: {
    resetContact(state) {
      Object.keys(state).forEach(key => {
        state[key] = initialState[key];
      })
    },
    updateContactFirstName(state, value) {
      state.firstName = value;
    },
    updateContactLastName(state, value) {
      state.lastName = value;
    },
    updateContactEmail(state, value) {
      state.email = value;
    },
    updateContactPhone(state, value) {
      state.phone = formatPhone(value);
    },
    updateContactMessageCategory(state, value) {
      state.messageCategory = value;
    },
    updateContactMessage(state, value) {
      state.message = value;
    }
  },
  actions: {
    async submitContactRequest({
      state
      ,commit
    }) {

      /* Mock Implementation */
      //  var auth = 'Bearer '+state.token;
      var input = JSON.stringify({
        contactEmail: state.email,
        messageCategory: state.messageCategory.email,
        messageBody: state.message,
        firstName: state.firstName,
        lastName: state.lastName,
        contactPhone: state.phone
      })

      try {
        const contactUsResponse = await fetch( $gMiddleLayerApi + `ContactUs/ContactUs`, { //for now just testing api hitting endpoint, will need to change this later to accept paths
          "method": "POST",
          "body": input,
          "headers": {
            'Content-Type': 'application/json'
            // 'Authorization':auth
          }
        })

        if (contactUsResponse.ok) {
          var jVal = await contactUsResponse.json(); // Get JSON value from the response body
          if (jVal.success == true) {
            
            commit('resetContact');
          }
          return Promise.resolve(jVal);
        } else
          return Promise.reject('Call failed');
      } catch (ex) {
        //console.log(ex);
      }

    }
  }
}