<template>
  <div class="page-header-wrapper">
    <div class="page-header">
      <h1 class="text">
        <slot></slot>
      </h1>
      <div class="subtitle">
        <h2>
          <slot name="subtitle"></slot>
        </h2>
      </div>
      <div class="divider"/>
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHeader'
}
</script>

<style scoped lang="scss">
.page-header-wrapper {
  width: 100%;
  color: $gray;
  //padding: 50px 0;
  padding: 30px 0;
  background-image: url('../../assets/backgrounds/xactflex-hero-background@2x.jpg');
  background-repeat: no-repeat;
  background-position: 0 80%;
  background-size: cover;
  background-origin: border-box;
  background-color: rgba(255, 255, 255, 0.486);
  background-blend-mode: overlay;

  .page-header {
    display: flex;
    flex-flow: column;
    //justify-content: space-between;
    justify-content: center;
    //align-items: flex-start;
    align-items: center;
    max-width: 1324px;
    margin: 0 auto;
    font-weight: 500;

    .text {
      //margin-left: 80px; Previous
      //font-size: 38px;
      font-size: 34px;
    }

    .subtitle {
      margin: 10px 0 0 80px;
    }

    /* .divider { Previous
      width: 300px;
      margin: 20px 0 0 80px;
      border-bottom: 1px solid $absolute-white;
    } */
  }

  @media (max-width: 1023px) {
    .page-header {
      margin-top: 6rem;
      .text {
        margin-left: 20px;
        font-size: 30px;
      }

      .subtitle {
        margin: 10px 0 0 20px;
      }

      .divider {
        margin: 20px 0 0 20px;
      }
    }
  }
}
</style>