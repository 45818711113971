export default `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8">
    <meta name="generator" content="CoffeeCup HTML Editor (www.coffeecup.com)">
    <meta name="dcterms.created" content="Wed, 11 Nov 2020 16:54:11 GMT">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <title>Privacy Policy</title>
    
    
  </head>
  <body>
        <div class="container gray">
            Effective: September 1, 2022 Version 1
            <br/>
            <br/>            
            This Privacy Policy ("Policy") applies to the use of Capital Community Bank of Utah and Axcess Financial Services, Inc. as Servicer (herein collectively referred to as "Company") online sites and any Company affiliate or subsidiary sites. This Policy describes how the Company may collect, share, and use personal information about you based on your interaction with our sites and the choices you make about how your information is collected and used.
            <br/>
            <br/>
            Please read this Policy carefully. Your continued use of our sites means that you understand and consent to the terms of this Policy.
            <br/>
            <br/>
            This Policy is intended to cover only our U.S. customers. If you access our online services or visit our U.S. sites, your information may be transferred or processed in the United States.
            <br/>
            <br/>
            <h3>What We Collect</h3> <br/>
            <h4 class="blue">Personal Information</h4> <br/>
            When you use the Company' online sites, we may collect and share your personal information that you provide via forms, applications, surveys, and other online fields. This information can include:
            <br/>
            <br/>
            <ul style="list-style-type: none; padding: 0; margin: 0;">
              <li>Name, address, and contact information;</li>
              <li>Social Security number and income information;</li>
              <li>Payment history and employment information;</li>
              <li>Financial account details and account balances; or</li>
              <li>Information from identification documentation, such as your driver's license, passport, or utility bills.</li>
            </ul>
            <br/>
            <br/>
            <h4 class="blue">Non-Personal Information</h4> <br/>
            In addition to the personal information that we may collect from you, we may also automatically gather and store non-personal information about your visit including the IP address from which you access our sites; browser information; computer operating system information; the date and time you access our sites; the pages, files, and links you visit; and the web address from which you linked to our sites.
            <br/>
            <br/>
            We may collect this information from the devices and networks that you use to visit our sites in order to help improve the services we provide. We may use this information to analyze trends, administer the site, track users' movements, and gather broad demographic information for aggregate use or for other business purposes.
            <br/>
            <br/>
            <h3>Cookies and Other Tracking Technology</h3> <br/>
            Company uses cookies and other tracking technology, to monitor website performance. These other tracking technologies may include some combination of web beacons, clear GIFs, or pixel tags. Cookies and other tracking technology allow us to collect information, such as your browser type, the time you spend on our sites, the pages you visited, your language preferences, and other anonymous visit data. We may use this information to improve the quality of our sites.
            <br/>
            <br/>
            Company may also contract with third-party companies that use cookies, web beacons, clear GIFs, pixel tags, and other tracking technology for our sites' analytics and to advertise our products and services on unaffiliated websites and mobile apps. With our approval, these companies will have access to your information as needed to perform their functions, but they cannot use your information for any other purpose. These companies will not have access to information that can personally identify you. Most web browsers are initially set up to accept cookies. Many web browsers also allow you to refuse cookies, but doing so may degrade your experience on our sites. Please see the information about "Your Choices" below.
            <br/>
            <br/>
            <h3>How We Use Your Information</h3> <br/>
            The information we collect is used to increase your service and experience on our sites. We may use your information for everyday business purposes including: to process your requests, transactions, applications, and inquiries; to verify your identity; to allow access to your online accounts; to recognize you when you return to our sites; to prevent fraud and increase security of our online services; and to comply with applicable legal requirements.
            <br/>
            <br/>
            We reserve the right to contact you regarding changes to this Policy or any other policies or agreements relevant to your use of our sites.
            <br/>
            <br/>
            <h3>How We Share Your Information</h3> <br/>
            Company may share your information with our service providers, affiliates, and other third parties. Where applicable, we limit sharing your non-public personal information according to our financial privacy notice.
            <br/>
            <br/>
            With regard to Social Security Numbers ("SSNs"), the Company takes all reasonable steps to protect the confidentiality of SSNs, prohibit unlawful disclosure of SSNs and limit access to SSNs.
            <br/>
            <br/>
            <h3>Service Providers, Affiliates, or Joint Marketers</h3> <br/>
            We may disclose certain personal and non-personal information we collect from your use of our sites to companies that perform services on our behalf, including those who assist us in processing transactions, preparing, and mailing statements (if applicable), and responding to user inquiries. In addition, we may disclose that information to service providers that perform marketing services for us and to financial institutions outside our corporate family with whom we have joint marketing agreements. We further reserve the right to share, rent, sell, or otherwise disclose the information we collect with affiliates and third parties for direct marketing purposes.
            <br/>
            <br/>
            <h3>Non-Affiliated Third Parties</h3> <br/>
            We may disclose certain non-personal information we collect from your use of our sites to third parties outside our corporate family ("Non-Affiliated Third Parties"). Non-Affiliated Third Parties may include financial service providers (such as banks or other lenders), non-financial companies (such as publishers, retailers, and direct marketers), and others. We may disclose your personal information to trusted third parties who assist us in operating our website, processing transactions, conducting our business, or servicing you, so long as these parties agree to keep this information confidential, consistent with this Policy. We may also disclose information about you to Non-Affiliated Third Parties as permitted by law, such as to comply with a subpoena, respond to inquiries from government authorities, or defend legal actions. Your mobile telephone number, opt-in data and SMS messaging will not be shared with any unaffiliated third parties or otherwise sold, transferred, disclosed, or disseminated to any third party.
            <br/>
            <br/>
            <h3>YOUR CHOICES</h3> <br/>
            <h4>Do Not Track ("DNT")</h4> <br/>
            Some web browsers have a "do not track" feature that lets you tell websites that you do not want to have your online activities tracked. Our website does not respond to web browsers' do-not track signals.
            <br/>
            <br/>
            <h3>Links to Other Websites</h3> <br/>
            We may provide links to third-party websites from within our sites for your convenience. Please be aware that we are not responsible for the privacy practices of other sites. We encourage you to be aware when leaving our sites of the privacy policies and the terms and conditions that apply when you visit other sites that may collect your information.
            <br/>
            <br/>
            <h3>Security</h3> <br/>
            Company uses security measures consistent with industry standards to protect your information collected through our sites. These measures include technical, procedural, monitoring, and tracking safeguards to protect your information from misuse, unauthorized access or disclosure, loss, alteration, or destruction. Please keep in mind that no data transmission over the Internet is ever 100% secure, and any information that you transmit to us is done at your own risk.
            <br/>
            <br/>
            <h3>Wireless Operator Authorization</h3> <br/>
            You authorize your wireless carrier to use or disclose information about your account and your wireless device, if available, to CNG Holdings Inc., or its service provider for the duration of your business relationship, solely to help them identify you or your wireless device and to prevent fraud. See our Privacy Policy for how to see how we treat your data.
            <br/>
            <br/>
            <h3>Children's Privacy</h3> <br/>
            Company does not knowingly contact or collect personal information from children under 13 years. Company' sites are not designed or directed to solicit information of any kind from children under 13 years.
            <br/>
            <br/>
            <h3>Complaints and Corrections</h3> <br/>
            If you have any questions about this Policy or if you have a complaint with regard to the use of your personal information, you may reach us at 833-546-2135.
            <br/>
            <br/>
            <h3>Policy Updates</h3> <br/>
            This Policy is effective as of September 2022. We will notify you of any changes to this Policy by posting a notice on our homepage. Any changes will be effective as soon as they are published on our sites.
            <br/>
            <br/>
            <h3>State Law Disclosures</h3> <br/>
            Nevada: We are providing you this notice pursuant to state law. If you would like more information about this notice call us at our toll free number or email address included in this notice or the Bureau of Consumer Protection, Office of the Nevada Attorney General, 555 E. Washington Street, Suite 3900, Las Vegas, NV 89101; Phone number – 702.486.3132; e-mail: BCPINFO@ag.state.nv.us.
            <br/>
            <br/>
            <h3>Contact Us</h3>
            <br/>
            <br/>
            833-546-2135
            <br/>
            <br/>
            P.O. Box 36454
            <br/>
            <br/>
            Cincinnati, OH 45236-0454
            <br/>
            <br/>
            XactFlex Customer Service Hours:
            <br/>
            <br/>
            All times Eastern Time (ET)
            <br/>
            <br/>
            Monday–Friday: 8 a.m.–6 p.m.
            <br/>
            <br/>
            Saturday and Sunday: CLOSED
            <br/>
           
        </div>
  </body>
</html>
`