export default function iovationsnare() {
  function v(e, a) {
    var b = {},
      c;
    for (c = e.length - 1; -1 < c; c--)
      0 < c
        ? (b[c] = (function () {
            var d = c;
            return function () {
              return w(e[d], b[d + 1], a);
            };
          })())
        : w(e[c], b[c + 1], a);
  }

  function w(e, n, k) {
    var c = document.createElement("script"),
      f,
      g,
      l;
    l = A((a[k] && a[k].staticVer && a[k].staticVer + "/") || e[1]);
    e[0] = e[0].replace("##version##", l);
    f = e[0].split("?")[0].split("/");
    g = f[f.length - 1].split(".")[0];
    u.test(e[1]) &&
      l !== e[1] &&
      d("loader: Overriding configured version with staticVer.");
    c.setAttribute("src", e[0]);
    c && c.addEventListener
      ? c.addEventListener("error", function () {
          b[k + "_" + g + "_load_failure"] = "true";
        })
      : c.attachEvent &&
        c.attachEvent("onerror", function () {
          b[k + "_" + g + "_load_failure"] = "true";
        });
    n && (c.onload = n);
    document.getElementsByTagName("head")[0].appendChild(c);
  }

  function d(e) {
    if ("function" === typeof a.trace_handler)
      try {
        a.trace_handler(e);
      } catch (b) {
        //console.log(b);
      }
  }

  function f(b, a) {
    var d = null !== b && void 0 !== b;
    return !d || ("1" !== b.toString() && "true" !== b.toString().toLowerCase())
      ? !d || ("0" !== b.toString() && "false" !== b.toString().toLowerCase())
        ? "boolean" === typeof a
          ? a
          : !1
        : !1
      : !0;
  }

  function A(a) {
    d("********** version before replace: " + a + " **********");
    d('localNamespace[ "url_dots_to_dashes" ]: ' + b.url_dots_to_dashes);
    d("numericVersionPattern.test( output ): " + u.test(a));
    b.url_dots_to_dashes && u.test(a) && (a = a.replace(/\./g, "-"));
    d("version after replace: " + a);
    return a;
  }
  var g = window,
    x = g.io_global_object_name || "IGLOO",
    a = (g[x] = g[x] || {}),
    b = (a.loader = a.loader || {}),
    y = [],
    z = [],
    u = /^[0-9]{1,3}(\.[0-9]{1,3}){2}\/$/;
  if (b.loaderMain)
    return (
      d(
        "loader: Loader script has already run, try reducing the number of places it's being included."
      ),
      !1
    );
  b.loaderMain = iovationsnare;
  b.loaderVer = "5.2.2";
  (function () {
    var e = f(b.tp, !0),
      n = f(b.fp_static, !0),
      k = f(b.fp_dyn, !0),
      c = f(b.enable_legacy_compatibility),
      u = f(b.tp_split),
      v =
        (b.tp_host && b.tp_host.replace(/\/+$/, "")) ||
        "https://mpsnare.iesnare.com",
      l = b.fp_static_override_uri,
      m = void 0 !== b.uri_hook ? b.uri_hook + "/" : "/iojs/",
      p = (b.version || "versionOrAliasIsRequired") + "/",
      w = b.subkey ? g.encodeURIComponent(b.subkey) + "/" : "",
      x = b.tp_resource || "wdp.js",
      q = b.tp_host ? "&tp_host=" + g.encodeURIComponent(b.tp_host) : "",
      C = l ? "&fp_static_uri=" + g.encodeURIComponent(l) : "",
      r,
      t,
      h;
    b.tp_host = v;
    r = f(a.enable_flash, !0);
    t = a.io && a.io.enable_flash;
    h = a.fp && a.fp.enable_flash;
    t = void 0 !== t && null !== t ? f(t, !0) : r;
    void 0 !== h && null !== h ? (h = f(h, !0)) : (t = r);
    r = t ? "&flash=true" : "&flash=false";
    h = h ? "&flash=true" : "&flash=false";
    q =
      "?loaderVer=" +
      b.loaderVer +
      "&compat=" +
      c +
      "&tp=" +
      e +
      "&tp_split=" +
      u +
      q +
      "&fp_static=" +
      n +
      "&fp_dyn=" +
      k +
      C;
    e ||
      n ||
      d("loader: Not currently configured to load fp_static or tp script(s).");
    a.fp &&
      a.fp.staticVer &&
      a.fp.staticVer + "/" !== p &&
      ((p = A(a.fp.staticVer + "/")),
      d(
        "loader: Configured version replaced with that from pre-loaded static script."
      ));
    n || (a.fp && a.fp.staticMain)
      ? ((m = (m + "##version##" + w).replace(/\/\//g, "/")),
        n &&
          (a.fp && a.fp.staticMain
            ? c &&
              !a.fp.preCompatMain &&
              d(
                "loader: enable_legacy_compatibility on, but included static does not have the compat wrapper."
              )
            : l
            ? y.push([l, ""])
            : y.push([m + "static_wdp.js" + q + h, p])),
        !k || (a.fp && a.fp.dynMain)
          ? a.fp &&
            a.fp.dynMain &&
            d(
              "loader: First party dynamic script has already been loaded, disable fp_dyn or make sure you're not manually including the dynamic file separately."
            )
          : y.push([m + "dyn_wdp.js" + q + h, p]))
      : f(b.fp_dyn) &&
        d(
          "loader: Invalid Config, first party dynamic script set to load without static."
        );
    e &&
      (a.io && a.io.staticMain
        ? d("loader: Third party script has already been loaded.")
        : ((m = v + "/##version##" + w),
          u
            ? (z.push([m + "static_wdp.js" + q + r, p]),
              z.push([m + "dyn_wdp.js" + q + r, p]),
              b.tp_resource &&
                d(
                  "loader: Invalid Config: both tp_resource and tp_split set. Ignoring tp_resource."
                ))
            : z.push([m + x + q + r, p])));
  })();
  v(y, "fp");
  v(z, "io");
}
