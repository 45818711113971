export default `

<head>
    <title>Xact Flex Electronic Consent</title>
    <style type="text/css">
        /* body */
        body {
            margin: 0 auto;
            font-family: "Arial", Times, serif;
            font-size: 14pt;
            color: #181818;
            background-color: #ffffff;
            width: 830px;
        }

        /* footer */
        .footer {
            position: relative;
            padding: 2px 2px 2px 2px;
            top: 40;
            width: 100%;
            height: 10px;
            font-family: "Times New Roman", Times, serif;
            font-size: 7pt;
        }

        /*list */
        ul {
            padding-left: 3%;
            margin-left: 20;
        }

        /* trying to fix page breaks for emailed docs  */
        .pagebreak {
            clear: both;
            border: 1px solid transparent;
            page-break-after: always;
        }
    </style>
</head>
    <div id="applicationagreement" data-docsection="Electronic Consent">
        <table border="0" bordercolor="black" cellpadding="2" cellspacing="0" width="100%">
            <tbody>
                <tr>
                    <td align="center" width="50%">
                        <p align="center">
                            <font face="Arial" size="3"><b>CONSENT FOR ELECTRONIC RECORDS<b><br>
                            </font>
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
        <div id="ConsumerBox" data-docsection="Electronic Consent">
            <p style="margin-top: 0pt; margin-bottom: 0pt;" align="left">
                <font face="Arial" size="3">Please read this information carefully and, forfuture reference, either print a copy of this document or retain this information electronically.<br>
             </p>
        </div>
		<br>
            <p style="margin-top: 0pt; margin-bottom: 0pt;" align="left">
                <font face="Arial" size="3">1. INTRODUCTION. You are submitting a credit application to Capital Community Bank of Utah (CCBank) and Axcess as Servicer for CCBank. We  can  give  you  the  benefits  of  our  on  line  service only if you consent to use and  accept  electronic  signatures,  electronic  records,  and  electronic disclosures  in  connection  with   this   transaction   (your   "Consent").   By   completing   and  submitting   an online credit application  (your  "Application"),  you  acknowledge  that  you  have  received  this document  and  have  consented  to  the  use  of  electronic  signatures,   electronic   record,   and   electronic disclosures in connection with this transaction (collectively "Records").
				<br>
				<br>
             </p>
            <p style="margin-top: 0pt; margin-bottom: 0pt;" align="left">
                <font face="Arial" size="3">2. ELECTRONIC COMMUNICATIONS. You may request a paper copy of any Record by e-mailing: support@xactflex.com. You may request a paper copy even if you withdraw your Consent. We will retain the Records as required by law and will provide you a paper copy of any Record at no charge.
				<br>
				<br>
             </p>
            <p style="margin-top: 0pt; margin-bottom: 0pt;" align="left">
                <font face="Arial" size="3">3. CONSENTING TO DO BUSINESS ELECTRONICALLY. Before giving your Consent, you should consider whether you have the required hardware and software capabilities described below.
				<br>
				<br>
             </p>
            <p style="margin-top: 0pt; margin-bottom: 0pt;" align="left">
                <font face="Arial" size="3">4. SCOPE OF CONSENT. Your Consent and our agreement to conduct this transaction electronically only apply to this transaction. If we receive your Consent, then we will conduct this transaction with you electronically.
				<br>
				<br>
             </p>
            <p style="margin-top: 0pt; margin-bottom: 0pt;" align="left">
                <font face="Arial" size="3">5. HARDWARE AND SOFTWARE REQUIREMENTS. To access and retain the Records electronically, you will need to use the following computer software and hardware: A PC, Mac, Android or iOS device with the latest version of Google Chrome, Mozilla Firefox, Apple Safari, Internet Explorer or Microsoft Edge. To read some documents, you will need a PDF file reader  like Adobe Acrobat Reader. If at any time during this transaction these requirements change in a way that creates a material risk that you may not be able to receive records electronically, we will notify you of these changes.
				<br>
				<br>
             </p>
            <p style="margin-top: 0pt; margin-bottom: 0pt;" align="left">
                <font face="Arial" size="3">6. WITHDRAWING CONSENT. You may withdraw your Consent at any time and at no charge to you. If you withdraw your Consent prior to receiving the credit transaction, then your withdrawal will prevent you from obtaining an online credit transaction (i.e., a credit transaction obtained over the Internet). To withdraw your Consent, you must e-mail us at: support@xactflex.com. Note, however, that the withdrawal of your Consent will not affect the legal effectiveness, validity, or enforceability of the credit transaction or of any Records that you received electronically prior to such withdrawal (including but not limited to the Arbitration Agreement and the Notice-of-Grievance Agreement, each set forth above).
				<br>
				<br>
             </p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; align="left">
                <font face="Arial" size="3"> 7. CHANGE TO YOUR CONTACT INFORMATION. You must keep us informed of any change in  your e-mail address or your ordinary mail address. To update your address information, either phone us at 1-833-546-2135 or e-mail us at support@xactflex.com.
				<br>
				<br>
             </p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; word-spacing: -2px;"" align="left">
                <font face="Arial" size="3"> 8. AUTHORIZATION, AGREEMENT, AND REPRESENTATIONS. BY CLICKING THE "I AGREE" BUTTON BELOW, YOU CONSENT TO USE AND ACCEPT ELECTRONIC SIGNATURES, ELECTRONIC RECORDS, AND ELECTRONIC DISCLOSURES IN CONNECTION WITH THIS TRANSACTION. YOU ACKNOWLEDGE THAT YOU CAN ACCESS THE RECORDS IN THE DESIGNATED FORMATS DESCRIBED ABOVE, AND YOU UNDERSTAND THAT YOU MAY REQUEST A PAPER COPY OF THE RECORDS AT ANY TIME AND AT NO CHARGE. IF YOU DO NOT GIVE YOUR CONSENT OR IF YOU DO NOT AGREE TO THE TERMS AND CONDITIONS DESCRIBED IN THIS DOCUMENT, THEN CLICK THE "I DO NOT AGREE" BUTTON BELOW.
				<br>
				<br>
             </p>
        </div>
        <!-- PAGE 1 ENDS -->
        <!--NEW PAGE -->
        <div class="pagebreak"></div>
        <!--END OF DOCUMENT-->
        `