<template>
  <main>

    <PageHeader>
      FAQs
    </PageHeader>

    <section>
      <div id="faqSubHeader" align="center">

          <b class="faqTitle">Frequently Asked Questions</b>
      </div>

      <div class="container">
        <div class="accordion" id="faqAccordian">
          <div class="faqTitle text-right">
            <a class="mainToggle" data-toggle="" href="" role="button" aria-expanded="false" aria-controls="" data-target=".multi-collapse" data-parent="#faqAccordian" id="expandAll" @click.prevent="expandAll"> {{expand ? 'Collapse All' : 'Expand All'  }}
            </a>
            <!--<a class="mainToggle" data-toggle="" href="" role="button" aria-expanded="true" aria-controls="" data-target=".multi-collapse" data-parent="#faqAccordian" id="collapseAll" @click.prevent="collapseAll" hidden="hidden">Collapse All
            </a>-->
          </div>
            <!-- ------------------------------------------------------------------------------------------------ -->
                <div class="modal-overlay" v-if="showMessage2" aria-modal="true"
                role="alertdialog" 
                aria-describedby="doctitleFee">
                  <span
                    class="" id="message1">
                    <!-- <span class="icon">?</span> -->
                      <transition name="fade1">
                        <div class="message1 fade1" v-if="showMessage2">
                          <div class="title">
                            <span id="doctitleFee" class="helpGlossary1">Fee Chart</span>
                            <div id="linkClose" class="help-icon" 
                            @click="toggleMessage2" aria-label="Close Modal"
                            tabindex="0"
                            @keyup.esc="toggleMessage2"
                            @keydown.space.exact="toggleMessage2KeySpace($event)"
                            @keydown.tab.exact="focusnexteleinpopup($event);"
                            
                            @keydown.shift.tab="focuspreviouseleinpopup($event);"  
                            >X</div>
                          </div>
                          <div class="innerMessage">
                            <div v-html="feeChart"/>
                          </div>
                        </div>
                    </transition>
                  </span>
                </div>
                <!-- ------------------------------------------------------------------------------------------------ -->
          <ul>
          <li class="buttonTitle">
            <div class="" id="headingOne">
              <div class="mb-0">
                <button id="about" class="faqTitle text-left accordion__toggle-button" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" @click="setDataParent('about')">
                  About Xact<i>Flex</i><span style="font-size: 10px;">&reg;</span>
                </button>
              </div>
            </div>

            <div id="collapseOne" class="collapse multi-collapse" aria-labelledby="headingOne" data-parent="#faqAccordian">
              <div class="card-body">
              
                <p class="">
                  <b>Q. What is the Xact</b><i>Flex</i> <b>Line of Credit?</b>
                  <br/>
                  The Xact<i>Flex</i> line of credit is an open-ended, flexible account that you can draw from as needed, up to your credit limit, as long as you are current with your payments.  A Cash Advance Fee is charged when you make a draw.  When your billing statement is generated, a Carried Balance Fee is added based on your outstanding balance.  See the Cost of Credit <a class="helpGlossary1" tabindex="0" 
                  @click="toggleMessage2"
                  @keypress.enter="toggleMessage2" 
                  >page</a>  for details on the cost of a Line of Credit through Xact<i>Flex</i>.
                  <br/><br/>
                  
                  <b>Q. What are the benefits of the Xact<i>Flex</i> Line of Credit?</b>
                  <br/>
                  The main advantage is the ability to borrow only the amount needed, when needed.  As you make payments, you continue to have access to your approved credit limit.  You can access your Line of Credit whenever a funding need arises.  Your account status will be reported to TransUnion, which may impact your credit score.*
                  <br/><br/>

                  <b>Q. Who is CCBank?</b>
                  <br/>
                  Lines of credit are originated and funded by Capital Community Bank, a Utah Chartered bank, located in Provo, Utah, Member FDIC.
                  <br/><br/>

                  <b> Q. How much can I borrow on my Xact<i>Flex</i> Line of Credit?</b>
                  <br/>
                  You will be assigned a credit limit between {{currencyShort(minimumCreditAmount)}} and {{currencyShort(maximumCreditAmount)}} if you are approved.
                  <br/><br/>

                  <b> Q. Is there a minimum draw amount on my Line of Credit?</b>
                  <br/>
                  Yes.  The minimum draw is the lower of {{currencyShort(minimumDraw)}} or remaining Line of Credit.  Draws can be made in $25 increments.  Please note that you are not required to take a draw.  If you do not take a draw, your account will close within 12 months.  Please see <a @click="acctCloseText" class="" >account closure </a> section for additional details.

                  <br><br>
                  *The exact impact of payment(s) and/or account activity on your Line of Credit through Xact<i>Flex</i> to your credit score is unknown as your score is influenced by numerous contributing factors.

                </p>
              </div>
            </div>
          </li>
          <li class="buttonTitle">
            <div class="" id="headingTwo">
                <div class="mb-0">
                  <button id="eligibility" class="faqTitle text-left accordion__toggle-button" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" @click="setDataParent('eligibility')">
                    Eligibility
                  </button>
                </div>
            </div>
            <div id="collapseTwo" class="collapse multi-collapse" aria-labelledby="headingTwo" data-parent="#faqAccordian">
                <div class="card-body">
                  <p>
                    <b>Q. Am I eligible for an Xact<i>Flex</i> account?</b>
                    <br/>
                    You may be eligible for an Xact<i>Flex</i> account if you meet the following criteria:
                    <ul>
                      <li>You are of legal age to contract in your state of residence</li>
                      <li>You are a US citizen or a permanent resident</li>
                      <li>You have an active checking account</li>
                      <li>You have a regular source of income or benefits</li>
                      <li>You provide information required to verify your identity</li>
                      <li>You have a valid contact number</li>
                      <li>You have an active email address and consent to receive electronic disclosures</li>
                      <li>You meet our credit underwriting standards; and</li>
                      <li>You agree to the <router-link :to="{ name: 'TermsAndConditions' }"  target="_blank" class="no-border">Terms and Conditions</router-link> </li>
                    </ul>
                    
                  </p>
                   <!-- ------------------------------------------------------------------------------------------------ -->
                <div  class="helpGlossary">
                  <span
                    class="">
                    <!-- <span class="icon">?</span> -->
                      <transition name="faded">
                        <div class="message faded" v-if="showMessage">
                          <div class="title">
                            <span class="helpGlossary">Terms and Conditions</span><div class="help-icon" @click="toggleMessage">X</div>
                          </div>
                          <div class="innerMessage">

                            <div v-html="termsConditions"/>
                          </div>
                        </div>
                    </transition>
                  </span>
                  <!-- <span class="helpGlossary">Glossary of Terms</span> -->
                </div>
                <!-- ------------------------------------------------------------------------------------------------ -->
                </div>
            </div>
          </li>
          <li class="buttonTitle">
            <div class="" id="headingThree">
              <div class="mb-0">
                <button id="application" class="faqTitle text-left accordion__toggle-button collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" @click="setDataParent('application');">
                  Application
                </button>
              </div>
            </div>
            <div id="collapseThree" class="collapse multi-collapse" aria-labelledby="headingThree" data-parent="#faqAccordian">
              <div class="card-body">
                <p>
                  <b>Q. Do you check my credit rating when I apply for a Line of Credit?</b>
                    <br/>
                      Yes.  We request credit information from credit reporting agencies.
                      <br/><br/>


                      <b>Q. How do I eSign my Line of Credit documents?</b> 
                      <br/>
                      You can sign your Line of Credit documents online by logging into your account on <a class="orangeUnderline" href="https://xactflex.com">https://xactflex.com </a> and checking the “eSign” box when prompted.
                      <br/><br/>

                      When you check the eSign box, it serves as a legally binding electronic signature.  Always review your customer agreement, the privacy policy and any other terms or conditions that apply before you sign your application.
                      <br/><br/>


                      <b>Q. Are there any fees to apply?</b>
                      <br/>
                      No.  There are no fees to apply for a Line of Credit.  See the Cost of Credit <a class="helpGlossary1" 
                      @click="toggleMessage2"
                      @keypress.enter="toggleMessage2" 
                      tabindex="0"
                      >page</a>  for details on the cost of a Line of Credit through Xact<i>Flex</i>.
                      <br/><br/>

                      <b>Q. How do I know if my request for a Line of Credit has been approved?</b>
                      <br/>

                      You will receive an email notifying you of the approval.

                </p>
              </div>
            </div>
          </li>
          <li class="buttonTitle">
            <div class="" id="headingFour">
              <div class="mb-0">
                <button id="draw" class="faqTitle text-left accordion__toggle-button collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" @click="setDataParent('draw');">
                  Draw
                </button>
              </div>
            </div>
            <div id="collapseFour" class="collapse multi-collapse" aria-labelledby="headingFour" data-parent="#faqAccordian">
              <div class="card-body">
                <p>
                  <b>Q. How do I make a draw?</b>
                    <br/>
                      You can select a draw in your account portal online by logging into <a class="orangeUnderline" href="https://xactflex.com">https://xactflex.com </a>.
                      <br/><br/>


                    <b>Q. When will I receive my funds?</b>
                      <br/>
                      Once you are approved, your Line of Credit will be processed.  If you requested a draw during eSignature, and we can verify your information and originate your Line of Credit before 7:45 p.m. Eastern Time, in most cases you can expect to receive your funds the next business day.

                      <br/><br/>

                      If you request a draw on an existing credit line before 7:45 p.m. Eastern Time on a banking business day, in most cases, you can expect to receive your funds the next business day.
                      <br/><br/>
                      We are unable to process draws on days when banks are closed.  If you request after 7:45 p.m. Eastern Time on Friday, a weekend, or on a banking holiday, your request will be processed the next business day, and you can expect to receive your funds on the second business day following your request.
                </p>
              </div>
            </div>
          </li>
          <li class="buttonTitle">
            <div class="" id="headingFive">
              <div class="mb-0">
                <button id="costfees" class="faqTitle text-left accordion__toggle-button collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive" @click="setDataParent('costfees');">
                  Cost & Fees
                </button>
              </div>
            </div>
            <div id="collapseFive" class="collapse multi-collapse" aria-labelledby="headingFive" data-parent="#faqAccordian">
              <div class="card-body">
                <p>
                  <b>Q. How much does a Line of Credit cost?</b>
                    <br/>
                      Simply having a Line of Credit costs nothing – if you do not draw any funds or have an outstanding balance.  However, once you draw on your Line of Credit, you will be charged a Cash Advance Fee.  When your billing statement is generated, a Carried Balance Fee is added based on your outstanding balance.  See the Cost of Credit 
                      <a class="helpGlossary1" 
                      @click="toggleMessage2"
                      @keypress.enter="toggleMessage2" 
                      tabindex="0"
                      >page</a>  for details on the cost of a Line of Credit through Xact<i>Flex</i>.
                      <br/><br/>

                  <b>Q. What is a Cash Advance Fee?</b>
                      <br/>
                      You pay a cash advance fee whenever you make a draw from your credit line.  The Cash Advance Fee is calculated as a percentage of your requested draw amount.  This fee is deducted from your total draw amount, and the remaining deposit is the amount that you will receive in your banking account.
                      <br/><br/>

                      A cash advance fee of 5% is charged for bi-weekly and semi-monthly billing cycles and 10% for monthly billing cycles.  Please see the Cost of Credit 
                      <a class="helpGlossary1" 
                      @click="toggleMessage2"
                      @keypress.enter="toggleMessage2" 
                      tabindex="0"
                      >page</a>  for details on the cost of a Line of Credit through Xact<i>Flex</i>.
                      <br/><br/>


                  <b>Q. What is a Carried Balance Fee?</b>
                      <br/>
                     When your billing statement is generated, a Carried Balance Fee is added.  It is a flat fee based on your outstanding balance.  Please see the Cost of Credit 
                     <a class="helpGlossary1" 
                     @click="toggleMessage2"
                     @keypress.enter="toggleMessage2" 
                     tabindex="0"
                     >page</a>  for details on the cost of a Line of Credit through Xact<i>Flex</i>.
                </p>
              </div>
            </div>
          </li>
          <li class="buttonTitle">
            <div class="" id="headingSix">
              <div class="mb-0">
                <button id="statement" class="faqTitle text-left accordion__toggle-button collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix" @click="setDataParent('statement');">
                  Statement & Account Information
                </button>
              </div>
            </div>
            <div id="collapseSix" class="collapse multi-collapse" aria-labelledby="headingSix" data-parent="#faqAccordian">
              <div class="card-body">
                <p>
                  <b>Q. How can I view my account information?</b>
                    <br/>
                     You can view and manage your account online at <a class="orangeUnderline"  href="https://xactflex.com">https://xactflex.com </a> or by calling Customer Support at <a class="no-border orangeUnderline" :href="'tel:' + companyInfo.customerServicePhone">{{companyInfo.customerServicePhone}}</a>.
                      <br/><br/>

                      You will be able to access your information through your account page after logging in.  You can request draws, check your account status, see when you next payment is due, schedule additional payments, review your documents, and change your communication preferences.
                      <br/><br/>

                    <b>Q. What is available credit?</b>
                      <br/>
                      The maximum amount of money you can draw less a cash advance fee.  Your credit limit will be assigned at the time a Line of Credit is approved.  This assumes your account status is current and all required payments are made on time.  Please note that recent payment activity may not be reflected in your Available Credit balance on your statement or online account while the payment is processing.
                      <br/><br/>

                    <b>Q. Where is my credit statement / billing statement?</b>
                      <br/>
                      Credit statements, also known as billing statements, are generated at least 14 days prior to the payment due date for each billing cycle.  They will be sent via the email you provided during account setup.  You can also view your statement by logging into your account online at <a class="orangeUnderline" href="https://xactflex.com">https://xactflex.com </a>.
                      <br/><br/>

                      <b  id="closure">Q. When will my account close?  Can I cancel my account?</b>
                      <br/>

                      If your Xact<i>Flex</i> account is activated but you do not take a Cash Advance, your account will close after 12 months.
                      <br/><br/>
                      If your Xact<i>Flex</i> account is activated and you take at least one Cash Advance, your account will close two years after the account has been paid to zero with no activity.
                      <br/><br/>
                      <span>If you would like to request <span >account closure</span>, you may do so by calling customer support at <a class="no-border orangeUnderline" :href="'tel:' + companyInfo.customerServicePhone">{{companyInfo.customerServicePhone}}</a>.  Please keep in mind all balances must be paid in full to close your account.
                      </span>
                     <br/><br/>

                    <b>Q. What does it mean to default on a Line of Credit?</b>
                      <br/><br/>

                      A borrower can default on a Line of Credit by failing to make payments by the due dates; by breaching terms and conditions; or by providing false, inaccurate, or misleading information.

                </p>
              </div>
            </div>
          </li>
          <li class="buttonTitle">
            <div class="" id="headingSeven">
              <div class="mb-0">
                <button id="payments" class="faqTitle text-left accordion__toggle-button collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven" @click="setDataParent('payments');">
                  Payments
                </button>
              </div>
            </div>
            <div id="collapseSeven" class="collapse multi-collapse" aria-labelledby="headingSeven" data-parent="#faqAccordian">
              <div class="card-body">
                <p>
                  <b>Q. How do I make a payment?</b>
                    <br/>
                     During account setup online, you have the option of selecting ACH (Automated Clearing House) via your checking account or paper check repayment.
                      <br/><br/>

                      If you select ACH authorization, payments will be automatically debited from your checking account on the due date, as determined by your billing cycle.
                      <br/><br/>

                      You may revoke this authorization by contacting us at least three days prior to your payment date either in writing (address below) or by phone at <a class="no-border orangeUnderline" :href="'tel:' + companyInfo.customerServicePhone">{{companyInfo.customerServicePhone}}</a>.
                      <br/><br/>
                      <b>
                        Attn:  XactFlex Customer Support
                        <br/>
                        {{companyInfo.address_dec}}
                        <br/>
                        {{companyInfo.address_dec2}}
                        <br/><br/>
                      </b>

                      If you revoke the ACH, you have the option of the following payment methods:

                      <ul>
                        <li>You may make one-time debit card payments by calling Customer Support at <a class="no-border orangeUnderline" :href="'tel:' + companyInfo.customerServicePhone">{{companyInfo.customerServicePhone}}</a>.</li>
                        <li>You may also send in a personal check, certified check or Money Order directed to <b>“XactFlex”</b> to the following address:
                          <br/>
                          <b>
                            Attn:  XactFlex Customer Support
                            <br/>
                            {{companyInfo.addressLine1}}
                            <br/>
                            {{companyInfo.addressLine2}}
                          </b>
                        </li>
                      </ul>

                    <b>Q. Where do I send my payment?</b>
                      <br/>
                      Please send in a personal check, certified check or Money Order directed to <b>“XactFlex”</b> to the following address:
                      <br/><br/>
                      <b>
                          Attn:  XactFlex Customer Support
                           <br/>
                           {{companyInfo.addressLine1}}
                           <br/>
                           {{companyInfo.addressLine2}}
                           <br/><br/>
                      </b>
                    <b>Q. Is there a penalty for paying more than my required payment?</b>
                      <br/>
                      No.  Customers can pay any amount over their required payment with no early penalty fees.
                      <br/><br/>
                      To make additional payments, please call Customer Support at <a class="no-border orangeUnderline" :href="'tel:' + companyInfo.customerServicePhone">{{companyInfo.customerServicePhone}}</a>.  You will have the option of making a single payment from your checking account or debit card.
                      <br/><br/>
                      If you prefer to send in a personal check, certified check, or Money Order, please direct to <b>“XactFlex”</b> and send to
                      <br/><br/>
                      <b>
                        Attn:  XactFlex Customer Support
                           <br/>
                           {{companyInfo.addressLine1}}
                           <br/>
                           {{companyInfo.addressLine2}}
                          <br/><br/>
                      </b>
                    <b>Q. If I make a payment outside of my current billing cycle (off-cycle), will my normal ACH payment process?</b>
                      <br/><br/>

                      It depends.
                      <br/><br/>
                      If you make a payment outside of your current billing cycle that is <b>less </b> your next required minimum due, your next ACH payment will still process.  Depending on the timing and off-cycle payment type (checking, debit, personal check, certified check, money order), the amount of the ACH may be the required minimum less the off-cycle payment you made or the full ACH.
                      <br/><br/>
                      If you make a payment outside of your current billing cycle that is <b>more </b> your next required minimum due, your next ACH payment may skip a payment cycle.  ACH skipping or continued processing will depend on the timing and off-cycle payment type (checking, debit, personal check, certified check, money order).
                      <br/><br/>
                      Please call Customer Support with any questions and clarifications for off-cycle payments at <a class="no-border orangeUnderline" :href="'tel:' + companyInfo.customerServicePhone">{{companyInfo.customerServicePhone}}</a>.
                      <br/><br/>

                  <b>Q. How often are my payments due?</b>
                      <br/><br/>

                      Your payment due date is determined based on your pay date and pay frequency you submitted in your Application.
                      <br/><br/>
                       <ul>
                        <li>If your pay frequency is weekly or bi-weekly, your billing cycle will be bi-weekly with bi-weekly due dates.</li>
                        <li>If your pay frequency is semi-monthly, your billing cycle will be semi-monthly with semi-monthly due dates. </li>
                        <li>If your pay frequency is monthly, your billing cycle will be monthly with monthly due dates.</li>
                        <li>Payments will begin coming due at least 14 days after your first draw and correspond with your pay date and pay frequency.  Login to <a class="orangeUnderline" href="https://xactflex.com">https://xactflex.com </a> to view your statement electronically.</li>
                      </ul>

                  <b>Q. Can I change my payment due date?</b>
                      <br/>

                      Yes.  There are conditions where you may be able to change your payment due date by calling Customer Support at <a  class="no-border orangeUnderline" :href="'tel:' + companyInfo.customerServicePhone">{{companyInfo.customerServicePhone}}</a>.
                       <br/><br/>
                  <b>Q. How do I pay off my Line of Credit?</b>
                      <br/>
                      Payments are applied to any outstanding required payment for which a statement has been issued in the order of their due dates, beginning with the earliest due date.  Payments are applied to each required payment in the following order: Carried Balance Fees, then any remaining portion of the required payment.  Once all required payments have been satisfied, payments will be applied to any balance that remains unpaid until the Line of Credit reaches zero.
                </p>
              </div>
            </div>
          </li>
          <li class="">
            <div class="" id="headingEight">
              <div class="mb-0">
                <button id="contactus" class="faqTitle text-left accordion__toggle-button collapsed" type="button" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight" @click="setDataParent('contactus');">
                  Contact Us
                </button>
              </div>
            </div>
            <div id="collapseEight" class="collapse multi-collapse" aria-labelledby="headingEight" data-parent="#faqAccordian">
              <div class="card-body">
                <p>
                  <b>Q. How do I contact Customer Support?</b>
                    <br/><br/>
                      <b>Call:</b>
                      <br/>
                      <a class="no-border orangeUnderline" :href="'tel:' + companyInfo.customerServicePhone">{{companyInfo.customerServicePhone}}</a>
                      <br/><br/>

                      <b>Write: </b>
                      <br/>
                      <!-- <b> -->
                        Attn:  XactFlex Customer Support
                            <br/>
                            {{companyInfo.address_dec}}
                            <br/>
                            {{companyInfo.address_dec2}}
                            <br/><br/>
                      <!-- </b> -->
                      <!--<br/><br/>-->
                       <b>Payments:</b>
                       <br/>
                        You may make one-time ACH payments by logging into your account on <a class="orangeUnderline" href="https://xactflex.com">https://xactflex.com </a>.
                       <br/><br/>
                        You may make one-time debit card payments by calling Customer Support at <a class="no-border orangeUnderline" :href="'tel:' + companyInfo.customerServicePhone">{{companyInfo.customerServicePhone}}</a>.
                       <br/><br/>
                        If you prefer to send in a personal check, certified check, or Money Order, please direct to <b>“XactFlex”</b> and send to
                        <br>
                        Attn:  XactFlex Customer Support
                            <br/>
                            {{companyInfo.addressLine1}}
                            <br/>
                            {{companyInfo.addressLine2}}
                       <br><br>
                       <b>Hours of Operation:</b>
                       <br/>
                       24/7

                </p>
              </div>
            </div>
          </li>
          </ul>
        </div>

      </div>

        <!-- <VueFaqAccordion :items="accordionItems" :activeColor="colors['export-orange']"/> -->
    </section>



  </main>
</template>

<script>
//import VueFaqAccordion from 'vue-faq-accordion';
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import AccordionItems from "@/config/text/accordions/faq";
import Colors from "@/styles/colors.scss";
import $ from "jquery";
import companyInfo from "../../config/text/company";
import scrollIntoView from "scroll-into-view-if-needed";
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
//import termsConditions from '../../config/text/termsConditions';
import {
  minimumCreditAmount,
  maximumCreditAmount,
  minimumDraw,
} from "../../config/amounts";
import { currencyShort } from "@/utils/filters/currency";
import { PageHeader } from "@/components";

export default {
  name: "Faq",
  title: "Frequently Asked Questions",
  components: { PageHeader },
  computed: {
    termsConditions: {
      get() {
        return this.$store.state.documents.termsConditions;
      },
    },
    feeChart: {
      get() {
        return this.$store.state.documents.feeChart;
      },
    },
  },
  async created() {
    if (!this.termsConditions) {
      this.$store.dispatch("getTermsConditions");
    }
    if (!this.feeChart) {
      this.$store.dispatch("getFeeChart");
    }
  },
  data() {
    return {
      accordionItems: AccordionItems,
      colors: Colors,
      minimumDraw: minimumDraw,
      currencyShort: currencyShort,
      minimumCreditAmount: minimumCreditAmount,
      maximumCreditAmount: maximumCreditAmount,
      companyInfo: companyInfo,
      //termsCondtions: termsConditions,
      showMessage: false,
      showMessage2: false,
      expand: false,
      navHidden: false,
    };
  },
  methods: {
    toggleMessage2() {
      this.showMessage2 = !this.showMessage2;
      //Option 1 - Availble for all browsers
      window.scrollTo({
        top: 200,
        left: 100,
        behavior: "smooth",
      });
      if(this.showMessage2)
      {
              setTimeout(() => {
              document.getElementById("linkClose").focus();
            }, 100);
      }
      //Option 2 - Partial available to safari
      // $('html,body').animate({
      //   scrollTop: $("#message1").offset().top},
      //   'slow');
    },
    toggleMessage2KeySpace(event)
    {
      event.preventDefault();
      this.showMessage2 = !this.showMessage2;
    },
    focuspreviouseleinpopup(event) {
      event.stopPropagation();
      event.preventDefault();
    },
    focusnexteleinpopup(event) {
      event.stopPropagation();
      event.preventDefault();
    },
    toggleMessage() {
      this.showMessage = !this.showMessage;
    },
    setNavHidden() {
      this.navHidden = !this.navHidden;
    },
    async acctCloseText() {
      var content = document.body.textContent || document.body.innerText;
      var hasText = content.indexOf("account close?") !== -1;
      //console.log(content);
      //  console.log(hasText);
      // console.log(this.expand);
      if (this.expand == false) {
        if (hasText) {
          $(function () {
            $(".multi-collapse")
              .attr("data-parent", "#faqAccordian")
              .collapse("hide");
            $(".accordion__toggle-button2").addClass(
              "accordion__toggle-button"
            );
            $(".accordion__toggle-button2").removeClass(
              "accordion__toggle-button2"
            );
            $("#collapseAll").attr("hidden", "hidden");
            $("#expandAll").removeAttr("hidden");
            $(".accordion__toggle-button").attr("disabled", false);
          });

          $("#statement").removeClass("collapsed");
          $("#statement").removeClass("accordion__toggle-button");
          $("#statement").addClass("accordion__toggle-button2");
          $("#collapseSix")
            .attr("data-parent", "#faqAccordian")
            .collapse("show");
          //$("#closure").css("border", "#5ba2d5 1px solid");
        }
      } else {
        $("#statement").removeClass("collapsed");
        $("#statement").removeClass("accordion__toggle-button");
        $("#statement").addClass("accordion__toggle-button2");
        $("#collapseSix").attr("data-parent", "#faqAccordian").collapse("show");

        //Option 2 - scroll to section
        //  var el = document.getElementById('closure');
        // el.scrollIntoView({block: 'start'});
      }
      //}

      setTimeout(() => {
        if (screen.width < 750) {
          const node = document.getElementById("closure");
          const scrollIntoViewSmoothly =
            "scrollBehavior" in document.documentElement.style
              ? scrollIntoView
              : smoothScrollIntoView;
          scrollIntoViewSmoothly(node, {
            behavior: (actions) =>
              // list is sorted from innermost (closest parent to your target) to outermost (often the document.body or viewport)
              actions.forEach(({ el, top }) => {
                // Adjusting scrolltop as per the top menu size
                el.scrollTop = top - 75;
              }),
            scrollMode: "if-needed",
            block: "start",
            inline: "center",
          });
        } else {
          const node = document.getElementById("closure");
          const scrollIntoViewSmoothly =
            "scrollBehavior" in document.documentElement.style
              ? scrollIntoView
              : smoothScrollIntoView;
          scrollIntoViewSmoothly(node, {
            behavior: "smooth",
            scrollMode: "if-needed",
            block: "start",
            inline: "center",
          });
        }
      }, 200);
    },
    async expandAll() {
      var type = document.getElementById("expandAll").innerText
      if(type == "Expand All"){
        this.expand = false
      } else if(type == "Collapse All"){
        this.expand = true
      }

      this.expand = this.expand ? false : true;  //this.expand = true;
      if(this.expand){
        $(function () {
          $(".multi-collapse").removeAttr("data-parent").collapse("show");
          $(".accordion__toggle-button").addClass("accordion__toggle-button2");
          $(".accordion__toggle-button").removeClass("accordion__toggle-button");
          //$("#expandAll").attr("hidden", "hidden");
          //$("#collapseAll").removeAttr("hidden");
          $(".accordion__toggle-button2").attr("disabled", false);
        });
      }
      else{
        $(function () {
          $(".multi-collapse")
            .attr("data-parent", "#faqAccordian")
            .collapse("hide");
          $(".accordion__toggle-button2").addClass("accordion__toggle-button");
          $(".accordion__toggle-button2").removeClass(
            "accordion__toggle-button2"
          );
          //$("#collapseAll").attr("hidden", "hidden");
          //$("#expandAll").removeAttr("hidden");
          $(".accordion__toggle-button").attr("disabled", false);
        });
      }
      
    },

    async collapseAll() {
      this.expand = false;
      $(function () {
        $(".multi-collapse")
          .attr("data-parent", "#faqAccordian")
          .collapse("hide");
        $(".accordion__toggle-button2").addClass("accordion__toggle-button");
        $(".accordion__toggle-button2").removeClass(
          "accordion__toggle-button2"
        );
        $("#collapseAll").attr("hidden", "hidden");
        $("#expandAll").removeAttr("hidden");
        $(".accordion__toggle-button").attr("disabled", false);
      });
    },
    async setDataParent(agrname) {
      $(function () {
        if ($("#" + agrname).hasClass("collapsed")) {
          $("#" + agrname).removeClass("accordion__toggle-button2");
          $("#" + agrname).addClass("accordion__toggle-button");
        } else {
          $("#" + agrname).removeClass("accordion__toggle-button");
          $("#" + agrname).addClass("accordion__toggle-button2");
        }

        if (
          $("#about").hasClass("collapsed") &&
          $("#eligibility").hasClass("collapsed") &&
          $("#application").hasClass("collapsed") &&
          $("#draw").hasClass("collapsed") &&
          $("#costfees").hasClass("collapsed") &&
          $("#statement").hasClass("collapsed") &&
          $("#payments").hasClass("collapsed") &&
          $("#contactus").hasClass("collapsed")
        ) {
          //$("#collapseAll").attr("hidden", "hidden");
          //$("#expandAll").removeAttr("hidden");
          this.expand = true
        }
        if (
          $("#about").hasClass("accordion__toggle-button2") &&
          $("#eligibility").hasClass("accordion__toggle-button2") &&
          $("#application").hasClass("accordion__toggle-button2") &&
          $("#draw").hasClass("accordion__toggle-button2") &&
          $("#costfees").hasClass("accordion__toggle-button2") &&
          $("#statement").hasClass("accordion__toggle-button2") &&
          $("#payments").hasClass("accordion__toggle-button2") &&
          $("#contactus").hasClass("accordion__toggle-button2")
        ) {
          this.expand = false
          //$("#expandAll").attr("hidden", "hidden");
          //$("#collapseAll").removeAttr("hidden");
        }

        /* if (agrname == 'about') {
          $("#about").removeClass("accordion__toggle-button");
          $("#about").addClass("accordion__toggle-button2");
        }
        if (agrname == 'eligibility') {
          $("#eligibility").removeClass("accordion__toggle-button");
          $("#eligibility").addClass("accordion__toggle-button2");
        }
        if (agrname == 'application') {
          $("#application").removeClass("accordion__toggle-button");
          $("#application").addClass("accordion__toggle-button2");
        }
        if (agrname == 'draw') {
          $("#draw").removeClass("accordion__toggle-button");
          $("#draw").addClass("accordion__toggle-button2");
        }
        if (agrname == 'costfees') {
          $("#costfees").removeClass("accordion__toggle-button");
          $("#costfees").addClass("accordion__toggle-button2");
        }
        if (agrname == 'statement') {
          $("#statement").removeClass("accordion__toggle-button");
          $("#statement").addClass("accordion__toggle-button2");
        }
        if (agrname == 'payments') {
          $("#payments").removeClass("accordion__toggle-button");
          $("#payments").addClass("accordion__toggle-button2");
        }
        if (agrname == 'contactus') {
          $("#contactus").removeClass("accordion__toggle-button");
          $("#contactus").addClass("accordion__toggle-button2");
        }*/

        //$(":focus").removeClass("accordion__toggle-button");
        //$(":focus").addClass("accordion__toggle-button2");
        $("#closure").css("border", "none");

        $("#collapseOne").on("hidden.bs.collapse", function () {
          $("#about").removeClass("accordion__toggle-button2");
          $("#about").addClass("accordion__toggle-button");
        });
        $("#collapseTwo").on("hidden.bs.collapse", function () {
          $("#eligibility").removeClass("accordion__toggle-button2");
          $("#eligibility").addClass("accordion__toggle-button");
        });
        $("#collapseThree").on("hidden.bs.collapse", function () {
          $("#application").removeClass("accordion__toggle-button2");
          $("#application").addClass("accordion__toggle-button");
        });
        $("#collapseFour").on("hidden.bs.collapse", function () {
          $("#draw").removeClass("accordion__toggle-button2");
          $("#draw").addClass("accordion__toggle-button");
        });
        $("#collapseFive").on("hidden.bs.collapse", function () {
          $("#costfees").removeClass("accordion__toggle-button2");
          $("#costfees").addClass("accordion__toggle-button");
        });
        $("#collapseSix").on("hidden.bs.collapse", function () {
          $("#statement").removeClass("accordion__toggle-button2");
          $("#statement").addClass("accordion__toggle-button");
        });
        $("#collapseSeven").on("hidden.bs.collapse", function () {
          $("#payments").removeClass("accordion__toggle-button2");
          $("#payments").addClass("accordion__toggle-button");
        });
        $("#collapseEight").on("hidden.bs.collapse", function () {
          $("#contactus").removeClass("accordion__toggle-button2");
          $("#contactus").addClass("accordion__toggle-button");
        });
      });
    },
  },
};
$(document).ready(function () {
  //const realWidth = window.screen.width * window.devicePixelRatio;
  const screenWidth = window.screen.width;
  if (screenWidth <= 1023) {
    $("#about").removeClass("accordion__toggle-button");
    $("#about").addClass("accordion__toggle-button2");
    $("#collapseOne").collapse("show");
  }
});
</script>

<style scoped lang="scss">

  #linkClose:focus{
        outline: 2px solid #1e4388;
        filter: drop-shadow(0px 0px 4px grey);      
  }

a {
  color: #1e4488 !important;
  text-decoration: underline !important;
  border-bottom: none !important;
}
#content {
  margin: 0 auto !important;
}
main {
  width: 100%;
  background: $absolute-white;

  section {
    margin-top: 20px;
    @media (max-width: 1023px) {
      margin-top: 0px;
    }
    .faqTitle {
      font-size: 16px;
    }
    .orangeUnderline {
      color: $orange;
      border-bottom: $orange 1px solid;
    }
    //////////////////////////////////////////////
    .help-icon {
      float: right;
      padding: 0px 0px 0 0;
      margin-right: 6px;
      @media (max-width: 460px) {
        // width:10px;
      }
    }

    div.modal-overlay {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100% !important;
      z-index: 9999;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;

      @media (max-width: 960px) {
        width: 60px;
        text-align: center;
      }

      @media (max-width: 500px) {
        font-size: 13px;
      }

      .message,
      .message1 {
        width: 680px;
        border-radius: 0px;
        background: $absolute-white;
        color: $gray;
        border: 1px solid $light-gray;
        box-shadow: none;
        z-index: 4;
        max-height: 500px;
        overflow-y: auto;

        @media (max-width: 1360px) {
          left: 275px;
        }
        @media (max-width: 1150px) {
          left: 175px;
        }
        @media (max-width: 960px) {
          left: 125px;
          width: 450px !important;
        }
        @media (max-width: 450px) {
          width: 341px !important;
        }

        .innerMessage {
          padding: 4px 10px;
          height: 100%;

          @media (max-width: 991px) {
            max-height: 450px;
            overflow-y: scroll;
          }

          @media (max-width: 450px) {
            overflow: scroll;
            max-width: 450px;
          }
          span {
            display: block;
            font-size: 12px;
            font-weight: 500;
          }

          .header {
            margin-top: 15px;
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
    }
    .helpGlossary,
    .helpGlossary1 {
      cursor: pointer;
      @media (max-width: 960px) {
      }
      .help-icon {
        float: right;
        padding: 0px 6px 0 0;

        @media (max-width: 460px) {
          // width:10px;
        }
      }

      .helpGlossary,
      .helpGlossary1 {
        text-decoration: underline;
      }
      .message1 {
        width: 700px !important;
        @media (max-width: 767px) {
          width: auto !important;
        }
      }
    }

    .title {
      background-color: $orange;
      color: $absolute-white;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      padding: 8px 4px 8px 4px;

      .icon {
        background: $absolute-white;
        color: $orange;
        border: none;
        border-radius: 50%;
        padding: 2px 6px;
        margin-left: 4px;
        margin-right: 4px;
        font-size: 10px;
        font-weight: 700;
      }
    }
    //////////////////////////////////////////////////////////////
    #faqAccordian {
      //padding: 15px;
      // padding-left: 10%;
      //padding: 0 5.5rem;
      ul{
        list-style: none;
      } 
      .faqTitle:focus-visible{
        outline: 2px solid #1e4388;
        filter: drop-shadow(0px 0px 4px grey);
      }
      .mainToggle:focus-visible{
        outline: 2px solid #1e4388;
        filter: drop-shadow(0px 0px 4px grey);
      }

      @media (max-width: 1900px) {
        padding-left: 0%;
      }
      @media (max-width: 1600px) {
        padding-left: 30px;
      }

      @media (max-width: 1550px) {
        padding-left: 55px;
      }

      @media (max-width: 1500px) {
        padding-left: 80px;
      }
      @media (max-width: 1450px) {
        padding-left: 55px;
      }
      @media (max-width: 1400px) {
        padding-left: 80px;
      }
      @media (max-width: 1350px) {
        padding-left: 100px;
      }
      @media (max-width: 1300px) {
        padding-left: 130px;
      }
      @media (max-width: 1250px) {
        padding-left: 150px;
      }
      @media (max-width: 1200px) {
        padding-left: 90px;
      }
      @media (max-width: 1150px) {
        padding-left: 120px;
      }
      @media (max-width: 1100px) {
        padding-left: 140px;
      }
      @media (max-width: 1023px) {
        padding-left: 0px;
      }
    }
    .mainToggle {
      border-bottom: none;
      color: $blue;
      //margin: 0 0 0 65px;
      margin: 0 65px 0 0px;
      font-weight: 600;
      @media (max-width: 1023px) {
        //margin: 0 10px;
        margin: 0 0 0 0px;
        padding: 0 15px 0 0px;
        // display: none;
      }
    }
    .text-left {
      color: $blue;
      background: $absolute-white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 15px;
      transition: all 0.3s;
      font-weight: 600;

      a {
        cursor: pointer;
      }

      @media (max-width: 1023px) {
        text-align: initial;
        padding: 10px 0 10px 20px;
      }
    }

    .text-right {
      text-align: right;
      color: $blue;
      background: $absolute-white;
      //display: flex;
      justify-content: space-between;
      //align-items: center;
      padding: 10px 15px 10px 15px;
      //transition: all 0.3s;

      a {
        cursor: pointer;
      }

      @media (max-width: 1023px) {
        text-align: initial;
        padding: 10px 0 10px 20px;
        // display: none;
      }
    }
    .card-body {
      margin: 0 58px;

      @media (max-width: 1023px) {
        margin: 0 0px;
      }
    }

    h4 {
      color: $blue;
    }

    .container {
      padding: 0 0 70px 0;
      @media (max-width: 1023px) {
        max-width: 100%;
        padding: 0 0 0px 0;
      }
    }
    .accordion__toggle-button::before {
      content: "+";
      left: 0 0 0 47px !important;
      height: 10px;
      width: 19px;
      line-height: 8px;
      display: inline-block;
      font-size: 26px;
      vertical-align: middle;
      color: $blue;

      @media (max-width: 1023px) {
        //   content:"+";
        margin: 0 0 0 5px !important;
        //   right: 1.25rem;
        //   position: absolute;
        //   height:10px;
        //   width:19px;
        //   line-height:8px;
        //   display: inline-block;
        //   font-size:28px;
        //   vertical-align:middle;
        //   color: $blue;
      }
    }
    .accordion__toggle-button2::before {
      /*content: "-";
      margin: 0 0 0 49px !important;
      height: 10px;
      width: 15px;
      line-height: 8px;
      display: inline-block;
      font-size: 18px;
      vertical-align: middle;
      color: $blue;*/

      content: "-";
      margin: 0 0 0 49px !important;
      height: 10px;
      width: 19px;
      line-height: 8px;
      display: inline-block;
      font-size: 26px;
      vertical-align: middle;
      color: #1e4488;

      @media (max-width: 1023px) {
        //   content:"x";
        margin: 0 0 0 7px !important;
        //   right: 1.25rem;
        //   position: absolute;
        //   height:10px;
        //   width:15px;
        //   line-height:8px;
        //   display: inline-block;
        //   font-size:18px;
        //   vertical-align:middle;
        //   color: $blue;
      }
    }
    @media (max-width: 1023px) {
      // .buttonTitle{
      //   border-bottom: $gray 1px solid;
      // }
      #faqSubHeader {
        //display: none;
        padding: 20px 0 0 0;
      }
      .page-header-wrapper {
        padding: 30px 0 20px;
      }
      #faqAccordian {
        padding: 0;
      }
    }
  }
}
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
</style>