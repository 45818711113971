export default `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8">
    <meta name="generator" content="CoffeeCup HTML Editor (www.coffeecup.com)">
    <meta name="dcterms.created" content="Wed, 11 Nov 2020 16:54:11 GMT">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <title>Privacy Notice</title>
    
  </head>
  <body>
<div id="glba" class="pdfobject-container">
<embed class="pdfobject" type="application/pdf" title="Embedded PDF" src="../pdf/glba-privacy-notice_afs_9.2022.pdf" style="overflow: auto; width: 100%; height: 100%;">
</div>
<div id="ccb" class="pdfobject-container"><embed class="pdfobject" type="application/pdf" title="Embedded PDF" src="../pdf/ccb-privacy-notice.pdf" style="overflow: auto; width: 100%; height: 100%;"></div>

<script src="https://unpkg.com/pdfobject@2.2.8/pdfobject.min.js"></script>
<script>
var options = {
	page: 2,
	pdfOpenParams: {
		navpanes: 1,
		view: "FitH",
		pagemode: "thumbs"
	}
};
PDFObject.embed("../pdf/glba-privacy-notice_afs_9.2022.pdf", "#glba", options);
PDFObject.embed("../pdf/ccb-privacy-notice.pdf", "#ccb");

</script>
</body>
<style>
.pdfobject-container {
    width: 100%;
    max-width: 600px;
    height: 600px;
    margin: 2em 0;
}

}
</style>
</html>

`