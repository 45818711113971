export const limitNums = (event) => {
  if (event.target.value.length >= event.target.maxLength) {
    if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) {
      event.preventDefault()
    }
  }
}

export const limitAlphabates = (event) => {
  if ((event.keyCode >= 65 && event.keyCode <= 90) || event.keyCode == 8 || event.keyCode == 9 ||
    event.keyCode == 189 || event.keyCode == 32 || event.keyCode == 222) {
    return;
  }
  else {
    event.preventDefault()
  }
}

export const AcceptOnlyNums = (event) => {
  if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) {
    event.preventDefault()
  }
}

export const formatSocSec = (value) => {
  let val = value.replace(/\D/g, '');
  val = val.replace(/^(\d{3})(\d{1,2})/, '$1-$2');
  val = val.replace(/^(\d{3})-(\d{2})(.+)/, '$1-$2-$3');
  return val.substring(0, 11);
}

export const formatPhone = (value) => {
  let val = value.replace(/\D/g, '');
  val = val.replace(/^(\d{3})(\d{1,3})/, '$1-$2');
  val = val.replace(/^(\d{3})-(\d{3})(.+)/, '$1-$2-$3');
  return val.substring(0, 12);
}

export const formatWorkPhone = (value) => {
  let x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,4})/);
  value = !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '') + (x[4] ? 'x' + x[4] : '');
  return value;
}

export const formatDate = (value) => {
  let val = value.replace(/\D/g, '');
  val = val.replace(/^(\d{2})/, '$01/');
  val = val.replace(/\/(\d{2})/, '/$01/');
  val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
  return val.substring(0, 12);
}

export const removeCharacter = (value) => {
  let val = value.replace('-', '');
  val = val.replace('-', '').replace('x', '');
  return val;
}

export const fourDigitSSN = (value) => {
  return value.substring(7, 11);
}