const initialState = {
  loggedIn: false,
  email: null,
  showPassword: false,
  chooseMethod:null,
  passCodefield: null,
  mobileNumber:null,
  multiusermobileNumber: null,
  homePhoneNumber:null,
  multiusercustid:null,
  showSoc: false,
  rememberMe: false,
  errorMsg: null,
  identityErrorMsg: null,
  user: {},               /* data sent back from API about user account after login*/
  loginResponse: null,     /* enum, refer to login API document*/
  multiTenant: false,
  last4SSN: null,
  token: null,
  custID:null,
  birthdate: null,
  CalledFrom: null,
  passwordGuid: null,
  passwordResetTokenExpired: false,
  passwordResetting: false,
  invalidEmail: false,
  accountLocked: false,
  sessionId: null,
  finalizeCredit: false,
  userId: 0,
  ip: null,
  countryCode: null,
  invalidLogin: false,
  reapply: false,
  emailNotFound: false,
  passwordCreationError: false,
  accountlockedpasswordCreationError: false,
  logoutMsg: "",
  disabledLogOut: false,
  firstName:null,
  firstDrawAmount:0,
  loanStatus:null,
  requiresEsignature:false
}

import router from '@/router/';
import loginText from '@/config/text/login';
import { lvxWidgetID } from '@/config/amounts';
//import Vue from 'vue';
import {$gMiddleLayerApi} from '../config/appconfigsettings';

export default {
  state: () => {
    return {
      ...initialState
    }
  },
  getters: {
    loggedIn: state => state.loggedIn
  },
  mutations: {
    resetLogin(state) {
      Object.keys(state).forEach(key => {
        state[key] = initialState[key];
      })
      localStorage.email = "";
      localStorage.loginAttempt = 0;
      localStorage.createpwdemail = "";
      localStorage.createpwdAttempt = 0;
      localStorage.Forgotpwdemail = "";
      localStorage.ForgotpwdAttempt = 0;
      localStorage.PasscodeAttempt = 0;
      localStorage.PasscodeSession = 0;
      localStorage.resendAttempt = 0;

      localStorage.passcodetimeout = 30;
      localStorage.verificationcodesenttime = 0;
      localStorage.TimeElapsed = 0;
    },
    updateCalledFrom(state, value) {
      state.CalledFrom = value;
    },
    updateToken(state, value) {
     // console.log("token- " + value);
      state.token = value;
    },
    updateLoggedIn(state, value) {
      state.loggedIn = value;
    },
    updateLoanStatus(state, value) {
      state.loanStatus = value;
    },
    updateRequiresEsignature(state, value) {
      state.requiresEsignature = value;
    },
    updateUserId(state, value) {
      state.userId = value;
    },
    updatePasscodeSession(state, value) {
      localStorage.PasscodeSession = value;
    },
    updateverificationcodesenttime(state, value) {
      localStorage.verificationcodesenttime = value;
    },
    updateTimeElapsed(state, value) {
      localStorage.TimeElapsed = value;
    },
    updatefirstName(state, value) {     
      state.firstName = value;
    }, 
     updatefirstDrawAmount(state, value) {     
      state.firstDrawAmount = value;
    },
    updateSessionId(state, value) {
      state.sessionId = value;
    },
    updateSSN(state, value) {
      state.last4SSN = value;
    },
    updateCustID(state, value) {
      state.custID = value;
    },
    updatechooseMethod(state, value) {
      state.chooseMethod = value;
    },
    updatepassCode(state, value) {
      state.passCodefield = value;
    },
    updateMobileNumber(state, value) {
      state.mobileNumber = value;
    },
    updateMultiUserMobileNumber(state, value) {
      state.multiusermobileNumber = value;
    },
    updateHomePhoneNumber(state, value) {
      state.homePhoneNumber = value;
    },
    updateMultiUserCustID(state, value) {
      state.multiusercustid = value;
    },
    updateLoginEmail(state, value) {
      state.errorMsg = null;
      state.showPassword = false;
      state.showSoc = false;
      state.email = value;
    },
    updateBirthdate(state, value) {
      state.birthdate = value;
    },
    updateLoginSocial(state, value) {
      state.last4SSN = value;
    },
    updateFinalizeCredit(state, value) {
      state.finalizeCredit = value;
    },
    updateInvalidEmail(state, value) {
      state.invalidEmail = value;
      if (state.invalidEmail) {
        state.errorMsg = loginText.invalidEmail;
      }
      else {
        state.errorMsg = "";
      }
    },
    updateEmptyEmail(state, value) {
      state.emptyEmail = value;
      if (state.emptyEmail) {
        state.errorMsg = loginText.emptyEmail;
      }
      else {
        state.errorMsg = "";
      }
    },
    updateNewEmail(state, value) {
      state.newEmail = value;
      if (state.newEmail) {
        state.errorMsg = loginText.newEmail;
      }
      else {
        state.errorMsg = "";
      }
    },
    updateInvalidPassCode(state, value) {
      state.invalidPassCode = value;
      if (state.invalidPassCode) {
        state.errorMsg = loginText.invalidPassCode;
      }
      else {
        state.errorMsg = "";
      }
    },
    updateEmailNotFound(state, value) {
      state.emailNotFound = value;
      if (state.emailNotFound) {
        if (state.CalledFrom === "ForgotPwd") {
          state.identityErrorMsg = loginText.noAccountFound;//forgotPasswordnoAccountFound
        } else
          state.identityErrorMsg = loginText.noAccountFound;
      }
      else {
        state.identityErrorMsg = "";
      }
    },
    updatePasswordCreationError(state, value) {
      state.passwordCreationError = value;
      if (state.passwordCreationError) {
        state.identityErrorMsg = loginText.passwordCreationError;
      }
      else {
        state.identityErrorMsg = "";
      }
    },
    updateAccountLockedPasswordCreationError(state, value) {
      state.accountlockedpasswordCreationError = value;
      if (state.accountlockedpasswordCreationError) {
        if (state.CalledFrom === "ForgotPwd") {
          state.identityErrorMsg = loginText.accountlockedpasswordforgotError;
        } else
        state.identityErrorMsg = loginText.accountlockedpasswordCreationError;
      }
      else {
        state.identityErrorMsg = "";
      }
    },
    updatevalidateUser(state, value) {
      state.validateUser = value;
      if (state.validateUser) {
        state.errorMsg = loginText.validateUser;
      }
      else {
        state.errorMsg = "";
      }
    },
    updateinvalidUser(state, value) {
      state.invalidUser = value;
      if (state.invalidUser) {
        state.errorMsg = loginText.invalidUser;
      }
      else {
        state.errorMsg = "";
      }
    },
    updatePasscodeInvalidCodeAttempt(state, value) {
      state.passcodeInvalidCodeAttempt = value;
      if (state.passcodeInvalidCodeAttempt) {
        state.errorMsg = loginText.passcodeInvalidCodeAttempt;
      }
      else {
        state.errorMsg = "";
      }
    },
    updateResendPassCode(state, value) {
      state.resendPasscode = value;
      if (state.resendPasscode) {
        state.errorMsg = loginText.resendPasscode;
      }
      else {
        state.errorMsg = "";
      }
    },    
    updatePasscodeInvalidCodeSentAttempt(state, value) {
      state.passcodeInvalidCodeSentAttempt = value;
      if (state.passcodeInvalidCodeSentAttempt) {
        state.errorMsg = loginText.passcodeInvalidCodeSentAttempt;
      }
      else {
        state.errorMsg = "";
      }
    },
    updatePasscodeAttemptFailed(state, value) {
      state.passcodeAttemptFailed = value;
      if (state.passcodeAttemptFailed) {
        state.errorMsg = loginText.passcodeAttemptFailed;
      }
      else {
        state.errorMsg = "";
      }
    },
    updatePasswordResetTokenExpired(state, value) {
      state.passwordResetTokenExpired = value;
      if (state.passwordResetTokenExpired) {
        state.errorMsg = loginText.passwordResetTokenExpired;
      }
      else {
        state.errorMsg = "";
      }
    },
    updateAccountLocked(state, value) {
      state.accountLocked = value;
      if (state.accountLocked) {
        state.errorMsg = loginText.accountLocked;
      }
      else {
        state.errorMsg = "";
      }
    },
    updateLogout(state, value) {
      if (value) {
        state.logoutMsg = loginText.logout;
      }
      else {
        state.logoutMsg = "";
      }
    },
    updateInvalidLogin(state, value) {
      state.invalidLogin = value;
      if (state.invalidLogin) {
        state.errorMsg = loginText.invalidLogin;
      }
      else {
        state.errorMsg = "";
      }
    },
    updatePasswordResetting(state, value) {
      state.passwordResetting = value;
      if (state.passwordResetting) {
        state.errorMsg = loginText.passwordResetting;
      }
      else {
        state.errorMsg = "";
      }
    },

    updateRememberMe(state, value) {
      state.rememberMe = value;
      if (value) {
        localStorage.username = state.email;
        localStorage.checkbox = value;
      }
      else {
        localStorage.username = "";
        localStorage.checkbox = "";
      }
    },
    clear_identitymsg(state) {
      state.identityErrorMsg = ""
    },
    checkEmailCookie(state) {
      if (localStorage.checkbox && localStorage.checkbox !== "") {
        if (state.email == null || state.email == localStorage.username) {
          // state.rememberMe = true;
          state.email = localStorage.username;
        }
      } else {
        state.rememberMe = false;
        if (state.CalledFrom !== "CreatePwd" && state.CalledFrom !== "Home" && state.CalledFrom !== "UpdatePwd")
          state.email = "";
      }
    },
    checkLoginType(state, multiTenant) {
      // Mock Implementation
      state.loggedIn = false;

      if (multiTenant == undefined && !state.passwordResetTokenExpired && !state.passwordResetting
        && state.invalidEmail && !state.accountLocked && !state.invalidLogin) {
        state.showPassword = false;
        state.showSoc = false;
        return;
      }

      if (state.accountLocked || state.invalidEmail || state.passwordResetTokenExpired
        || state.invalidLogin || state.passwordResetting) {
        return;
      }
      else
      {
        state.showPassword = true;
      }

      // User has multiple accounts tied to an email
      if (multiTenant) {
        // state.showSoc = true;
        state.showPassword = true;
      }
      else {
        if (!multiTenant)
          state.showPassword = true;
      }
    },
  },
  actions: {

    async logoutIfNotSignedIn({ commit }) {
      //console.log('logoutIfNotSignedIn');
      commit('resetAccount');
      commit('resetApplication');
      commit('resetPortal');
      commit('resetEsign');
      commit('resetLogin'); 
      router.push({ name: 'home' }).catch(err => err);  
    },

    async logout({ state, commit }) {
      commit('resetAccount');
      commit('resetApplication');
      commit('resetPortal');
      commit('resetEsign');
      commit('resetSendEmailSMSData');


      var auth = 'Bearer ' + state.token;
      var input = JSON.stringify(
        {
          userId: state.userId,
          sessionId: state.sessionId,
          browserVersion: navigator.appVersion,
          osVersion: navigator.userAgent,
          mobileVersion: navigator.userAgent,
          countryCode: state.countryCode,
          logoutType: 2
        })
     // console.log(input);

      try {
          if (document.head.children["lvxChatScript"] != null) {
            document.head.removeChild(document.head.children["lvxChatInclude"]);
            document.head.removeChild(document.head.children["lvxChatScript"]);
            document.body.removeChild(document.body.children[lvxWidgetID]);
          }

          if (document.getElementById('rightPortalSum') != null) {
            document.getElementById('rightPortalSum').style.visibility = "hidden";
          }
        } catch (ex) {
      //  console.log(ex);
      }

      try {
         await fetch( $gMiddleLayerApi + `Auth/Logout`, {     //for now just testing api hitting endpoint, will need to change this later to accept paths
          "method": "POST",
          "body": input,
          "headers": {
            'Content-Type': 'application/json',
            'Authorization': auth
          }
        })
       // console.log(loginResponse);
        commit('resetLogin');
        commit('updateLogout', true);
        await router.push({ name: 'home' }).catch(err => err);
        //router.go(0);
      } catch (ex) {
        commit('resetLogin'); router.push({ name: 'home' }).catch(err => err);
      //  console.log(ex);
      }
    },

    async verifyIdentity({ state, commit }, social) {
      // Identity verification functionality goes here
      commit('updateLogout', false);
      var auth = 'Bearer ' + state.token;
      //console.log('Verify Identity!', social);
      state.social = social;
      var date = new Date(state.birthdate);
      var bdate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));

      var input = JSON.stringify({
        email: state.email,
        last4SSN: state.social,
        DateOfBirth: bdate
      })
    //  console.log(auth);
      //console.log(input);
      try {
        const loginResponse = await fetch( $gMiddleLayerApi + `Auth/ResetPassword`, {     //for now just testing api hitting endpoint, will need to change this later to accept paths
          "method": "POST",
          "body": input,
          "headers": {
            'Content-Type': 'application/json',
            'Authorization': auth
          }
        })
      //  console.log("resp" + loginResponse);
        commit('updateInvalidLogin', false);
        commit('updateAccountLocked', false);
        if (loginResponse.ok) {
          const jVal = await loginResponse.json(); // Get JSON value from the response body      
        //  console.log(jVal);
          return Promise.resolve(jVal);
        }
        else {
          return Promise.reject('Call failed');
        }
      } catch (ex) { //console.log(ex); 
      }
    },

    async updatePassword({ state, commit }, password) {
      commit('updateLogout', false);
      var auth = 'Bearer ' + state.token;
      var input = JSON.stringify({
        email: state.email,
        password: password,
        confirmPassword: password,
        tokenId: state.passwordGuid,
      })
    //  console.log(auth);
      //console.log(input);
      try {
        const loginResponse = await fetch( $gMiddleLayerApi + `Auth/UpdatePassword`, {     //for now just testing api hitting endpoint, will need to change this later to accept paths
          "method": "POST",
          "body": input,
          "headers": {
            'Content-Type': 'application/json',
            'Authorization': auth
          }
        })
      //  console.log(loginResponse);
        if (loginResponse.ok) {
          const jVal = await loginResponse.json(); // Get JSON value from the response body      
          return Promise.resolve(jVal);
        }
        else
          return Promise.reject('Call failed');
      } catch (ex) {
        // console.log(ex);
       }



    },

    async ChangePassword({ state, commit }, ssnpassword) {
      commit('updateLogout', false);
      var auth = 'Bearer ' + state.token;
      var date = new Date(state.birthdate);
      var bdate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));

      var input = JSON.stringify({
        email: state.email,
        last4SSN: ssnpassword.substring(0, 4),
        dateOfBirth: bdate,
        password: ssnpassword.substring(4),
        confirmPassword: ssnpassword.substring(4),
        custId : state.custID
        
      })
      try {
        const loginResponse = await fetch( $gMiddleLayerApi + `Auth/ChangePassword`, {     //for now just testing api hitting endpoint, will need to change this later to accept paths
          "method": "POST",
          "body": input,
          "headers": {
            'Content-Type': 'application/json',
            'Authorization': auth
          }
        })
        //console.log(loginResponse);
        if (loginResponse.ok) {
          const jVal = await loginResponse.json(); // Get JSON value from the response body      
          return Promise.resolve(jVal);
        }
        else
          return Promise.reject('Call failed');
      } catch (ex) {
        // console.log(ex);
       }
    },

    async createPassword({ state, commit }, ssnpassword) {
      commit('updateLogout', false);
      var auth = 'Bearer ' + state.token;
      var date = new Date(state.birthdate);
      var bdate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
      var input = JSON.stringify({
        email: state.email,
        password: ssnpassword.substring(4),
        confirmPassword: ssnpassword.substring(4),
        userName: state.email,
        custSSN: ssnpassword.substring(0, 4),
        dob: bdate
      })

    //  console.log(input);
      try {
        const loginResponse = await fetch( $gMiddleLayerApi + `Auth/Register`, {     //for now just testing api hitting endpoint, will need to change this later to accept paths
          "method": "POST",
          "body": input,
          "headers": {
            'Content-Type': 'application/json',
            'Authorization': auth
          }
        })
      //  console.log(loginResponse);
        if (loginResponse.ok) {
          const jVal = await loginResponse.json(); // Get JSON value from the response body      
          return Promise.resolve(jVal);
        }
        else
          return Promise.reject('Call failed');
      } catch (ex) {
        // console.log(ex);
       }
    },
    async createToken({ state, commit }) {
      try {
        if (state.token === undefined || state.token === null) {
          const response = await fetch( $gMiddleLayerApi + `Token/CreateMerchantToken?merchantIdKey=` + process.env.VUE_APP_MERCHANT_ID, {     //for now just testing api hitting endpoint, will need to change this later to accept paths
            "method": "POST"
          })
          if (response.ok) {
            const jVal = await response.json(); // Get JSON value from the response body
            Promise.resolve(jVal);
            commit("updateToken", jVal.token.writtenToken);
            return jVal;
          }
          else
            return Promise.reject('Call failed');
        }
      }
      catch (ex) {
       // console.log(ex);
      }
    },
    async validateToken({ state, commit }) {
      commit('updateLogout', false);
      var auth = 'Bearer ' + state.token;
      var input = JSON.stringify({ token: state.passwordGuid });
   //   console.log(input);
      try {
        const response = await fetch( $gMiddleLayerApi + 'Auth/ValidateToken', {     //for now just testing api hitting endpoint, will need to change this later to accept paths
          "method": "POST",
          "body": input,
          "headers": {
            'Content-Type': 'application/json',
            'Authorization': auth
          }
        })

        if (response.ok) {
          const jVal = await response.json(); // Get JSON value from the response body
          return Promise.resolve(jVal);
        }
        else
          return Promise.reject('Call failed');
      }
      catch (ex) {
       // console.log(ex);
      }

    },



    async Login({ state, commit }, password, social) {
      commit('updateLogout', false);
      if (social == undefined) social = state.last4SSN;
      var auth = 'Bearer ' + state.token;
      var input = JSON.stringify({
        userName: state.email,
        password: password,
        fetchAdditionalInformation: true,
        userSession:
        {
          browserVersion: navigator.appVersion,
          osVersion: navigator.userAgent,
          mobileVersion: navigator.userAgent,
          countryCode: state.countryCode,
          logoutType: 1
        }
      })
    //  console.log(input);
      try {
        const loginResponse = await fetch( $gMiddleLayerApi + `Auth/Login`, {     //for now just testing api hitting endpoint, will need to change this later to accept paths
          "method": "POST",
          "body": input,
          "headers": {
            'Content-Type': 'application/json',
            'Authorization': auth
          }
        })
      //  console.log(loginResponse);
        if (loginResponse.ok) {
          const jVal = await loginResponse.json(); // Get JSON value from the response body
         // console.log("Customer Login Result - " + jVal.customerLoginResults);
          if (jVal.customerLoginResults == 1) {
            state.userAuth = 0;
            commit('updateLoggedIn', true);
          }
          return Promise.resolve(jVal);
        }
        else
          return Promise.reject('Call failed');
      } catch (ex) {// console.log(ex);
       }
    },

    Reset({ commit }) {
      commit('resetLogin');
    },

    async findIP({ state }) {
     // console.log('findIp');
      const ipCloudFlareresult = await fetch('https://www.cloudflare.com/cdn-cgi/trace');
      if (ipCloudFlareresult.ok) {
        var ipval = await ipCloudFlareresult.text();
        if (ipval != undefined) {
          state.ip = ipval.substring(ipval.search('ip=') + 3, ipval.search('ts=')).trim();
          state.countryCode = ipval.substring(ipval.search('loc=') + 4, ipval.search('tls=')).trim();
        }
      }
      if (state.ip == undefined) {
        const ipResult = await fetch('https://api.ipify.org?format=json');
        if (ipResult.ok) {
          var val = await ipResult.json();
          state.ip = val.ip;
        }
      }
    },
    async validateIdentity({ state, commit }) {
      commit('updateLogout', false);
      var date = new Date(state.birthdate);
      var bdate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
      var input = JSON.stringify({
        emailAddress: state.email,
        custSSN: state.last4SSN,
        custDOB: bdate,
        remoteIP: state.ip
      });
    //  console.log(input);
      try {
        var auth = 'Bearer ' + state.token;
      //  console.log(auth);
        const response = await fetch( $gMiddleLayerApi + `Auth/VerifyIdentity`, {     //for now just testing api hitting endpoint, will need to change this later to accept paths
          "method": "POST",
          "body": input,
          "headers": {
            'Content-Type': 'application/json',
            'Authorization': auth
          }
        })
        if (response.ok) {
          const jVal = await response.json(); // Get JSON value from the response body
          return Promise.resolve(jVal);
        }
      }
      catch (ex) {
        //console.log(ex) 
      }
    },
    async validateEmail({ state }) {
      var input = JSON.stringify({
        emailAddress: state.email,
        remoteIP: state.ip
      });
    //  console.log(input);
     // console.log(state.token);
      try {
        var auth = 'Bearer ' + state.token;
        const response = await fetch( $gMiddleLayerApi + `Auth/ValidateEmail`, {     //for now just testing api hitting endpoint, will need to change this later to accept paths
          "method": "POST",
          "body": input,
          "headers": {
            'Content-Type': 'application/json',
            'Authorization': auth
          }
        })
        if (response.ok) {
          const jVal = await response.json(); // Get JSON value from the response body
         // console.log(jVal);
          return Promise.resolve(jVal);
        }
      }
      catch (ex) { //console.log(ex)
      }
    }
  }
}

export const wrongPwdAttempt = 3;