function formatemail2FA(customeremail){
    
    var formatedemail = customeremail.charAt(0);

    for (var i = 1;i < customeremail.lastIndexOf("@")-1; i++) {
      formatedemail = formatedemail + ".";
    }
    formatedemail = formatedemail + customeremail.substring(customeremail.lastIndexOf("@") - 1)

    return formatedemail;

  }      

function formatcellno2FA(custCellNo){
      var convMobileNumber = custCellNo;
      var formatedmobile = "";

      if(convMobileNumber.trim().length>=10)
      {
        for (var i = 0; i < (convMobileNumber.length - 4); i++) 
        {
          if(i == 3){formatedmobile = formatedmobile + "-";}  
          
          formatedmobile = formatedmobile + "x";
        
        }
           
        formatedmobile = formatedmobile + "-" + convMobileNumber.substring((convMobileNumber.length - 4))

      }
        return formatedmobile;
}

  function generatePasscode() {
          
    var allowedchars = '0123456789'
    let Passcode = '';
      
    // Find the length of string
    var len = allowedchars.length;
    for (let i = 0; i < 6; i++ ) {
        Passcode += allowedchars[Math.floor(Math.random() * len)];
    }
    return Passcode;
}

function GetCurrentDate(){
  var endDate   = new Date();
  return endDate.getTime();
}


function TimeElapsed(lastCodeSentTime) {
  var endDate   = new Date();
  var seconds = (endDate.getTime() - lastCodeSentTime) / 1000;
  const minutes = Math.floor(seconds / 60);
  //console.log(minutes)
  return minutes;
}
export {formatemail2FA, formatcellno2FA, generatePasscode, GetCurrentDate, TimeElapsed};

