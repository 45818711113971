export default `
<div id="SMS" data-docsection="SMS">
    <b>SMS STATEMENT NOTIFICATIONS DISCLOSURE</b>. This
    SMS Statement Notifications Disclosure (this "Disclosure") applies to
    each account you have with  XactFlex™ for which you have elected
    to receive Short Message Service ("SMS") messages. <br>
    <br>
    As used in this SMS Statement Notifications Disclosure, "SMS Statement
    Notifications" means any SMS (text message) communications from us to
    you pertaining to your Loan, sent to the phone numbers listed on your
    application, including but not limited to payment information, account
    information, due dates, delinquent accounts, program updates,
    promotions, coupons and other marketing messages. <br>
    <br>
    1. &nbsp; <b>How to Unsubscribe</b>. You may withdraw
    your consent to receive SMS Statement Notifications by changing your
    preference on the Notification Settings page within <a
        href="http://www.xactflex.com">www.xactflex.com</a>.
    Alternatively, you may call us at 1.833.546.2135. At our option, we may
    treat your provision of an invalid mobile phone number, or the
    subsequent malfunction of a previously valid mobile phone number, as a
    withdrawal of your consent to receive SMS Statement Notifications. We
    will not impose any fee to process the withdrawal of your consent to
    receive SMS Statement Notifications. Any withdrawal of your consent to
    use SMS Statement Notifications will be effective only after we have a
    reasonable period of time to process your withdrawal. <br>
    <br>
    <b>You may opt-out</b> and remove your SMS approval
    for additional messages by sending "STOP", "END", "CANCEL",
    "UNSUBSCRIBE", "REMOVE", "QUIT", or "STOPALL" to the SMS text message
    you have received. If you remove your SMS approval from our database,
    your number will no longer be used for secondary purposes, disclosed to
    third parties and used by us for third parties to send promotional
    correspondence to you. <br>
    <br>
    2. &nbsp; To request additional information, contact us by
    telephone at 1.833.546.2135. For help of additional information
    regarding
    our texting services email us at <a
        href="mailto:Info@xactflex.com">Info@xactflex.com</a>
    or reply "HELP"
    anytime from your mobile device to the message you receive. <br>
    <br>
    3. &nbsp; The services are available on the following carriers:
    AT&amp;T, Verizon Wireless, T-Mobile, Sprint, Nextel, Dobson, U.S.
    Cellular, and Virgin Mobile. Additional carriers are added as they
    become available. <br>
    <br>
    4. &nbsp; In order to access, view, and retain SMS Statement
    Notifications that we make available to you, you must have: (1) an
    SMS-capable mobile phone, (2) an active mobile phone account with a
    communication service provider; and (3) sufficient storage capacity on
    your mobile phone. <br>
    <br>
    5. &nbsp; All SMS Statement Notifications in electronic format from
    us to you will be considered "in writing." <br>
    <br>
    6. &nbsp; There is no service fee for SMS Statement Notifications
    but you are responsible for any and all charges, including but not
    limited to fees associated with text messaging, imposed by your
    communications service provider. Other charges may apply. Such charges
    may include those from your communications service provider. Please
    consult your mobile service carrier&#39;s pricing plan to determine the
    charges for sending and receiving text messages. These charges will
    appear on your phone bill. Message frequency depends on account
    settings. <br>
    <br>
    7. &nbsp; Additionally, you agree that we may send any SMS
    Statement Notifications through your communication service provider in
    order to deliver them to you and that your communication services
    provider is acting as your agent in this capacity. You agree to provide
    a valid mobile phone number for these services so that we may send you
    certain information about your loan. Additionally, you agree to
    indemnify, defend and hold us harmless from and against any and all
    claims, losses, liability, cost and expenses (including reasonable
    attorneys&#39; fees) arising from your provision of a mobile phone number
    that is not your own or your violation of applicable federal, state,
    Tribal or local law, regulation or ordinance. Your obligation under
    this paragraph shall survive termination of this Agreement. SMS
    Statement Notifications are provided for your convenience only. Receipt
    of each SMS Statement may be delayed or impacted by factor(s)
    pertaining to your communications service provider(s). We will not be
    liable for losses or damages arising from any disclosure of account
    information to third parties, non-delivery, delayed delivery,
    misdirected delivery or mishandling of, or inaccurate content in, the
    SMS Statement Notifications sent by us. <br>
    <br>
    8. &nbsp; We may modify or terminate SMS from time to time, for any
    reason, and without notice, including the right to terminate text
    messaging with or without notice, without liability to you, any other
    user or a third party. We reserve the right to modify these Terms of
    Use from time to time without notice. Please review these Terms of Use
    from time to time so that you are timely notified of any changes. <br>
    <br>
</div>
`