<template>
  <main>
    <div class="maincontent">
      <div class="esigncontact-box">
        <div  class="e-header">
          <h1 tabindex="-1" id="htagContactHeader">
            Congrats, {{ firstName }}. You've successfully
            <br class="hidesmall" />eSigned your line of credit.
          </h1>
        </div>
        <hr class="e-divider" />
        <div class="e-imgcontainer">
          <!-- <div class="left">
            <div class="img-container">
                    <span><img style="" src="../../assets/icons/clock.png" alt=""></span>                  
            </div>
          </div>
          <div class="right">
            <span class="img-header">We're Setting Up <br class="hidelarge"/>Your Account</span><br/>
            <span class="e-txt" style="font-size:12pt;">This usually takes a few minutes. We'll send an email when it's ready.</span>
            <div v-if="firstDrawAmount > 0.0">
              <i><span class="e-txt" style="font-size:10pt;">You'll be able to log in to view your account information,<br/> make payments and request draws.</span></i>
             </div>
          </div>         -->
          <div>
            <span class="img-header">We're setting up your account.</span>
            <p class="e-txt" style="font-size: 10pt">
              This usually takes a few minutes. We'll send an email when it's
              ready.
            </p>
            <div class="e-txt" v-if="firstDrawAmount > 0.0">
              <span class="e-txt" style="font-size: 10pt"
                >You'll be able to log in to view your account information,<br
                  class="hidden-xs"
                />
                make payments and request draws.</span
              >
            </div>
          </div>
          <!-- <div class="" v-if="firstDrawAmount > 0.0">
            
             <div class="img-header">. We're processing your {{firstDrawAmount}} draw.</div><br/>
             <i><span class="e-txt" style="font-size:10pt;">The Cash Advance Fee is deducted before funds are deposited into your bank account,<br/> So the amount you receive will be less than your total draw. </span></i>
            <div class="e-txt">
              You could see cash in the bank as soon as the next business day.<sub>3</sub>
             
            </div>
          </div> -->
          <div class="e-txt" v-if="(firstDrawAmount = 0.0)">
            <span class="e-txt" style="font-size: 10pt"
              >Once your account is set up, you can request your first draw.
              <br />Just log in and select “Draw” on the left menu and follow
              the instructions to submit your request.
            </span>
          </div>
        </div>
        <div class="content-block">
          <!-- <div>
             <span class="img-header">We're Setting Up <br class="hidelarge"/>Your Account</span><br/>
            <span class="e-txt" style="font-size:12pt;">This usually takes a few minutes. We'll send an email when it's ready.</span>
            <div v-if="firstDrawAmount > 0.0">
              <i><span class="e-txt" style="font-size:10pt;">You'll be able to log in to view your account information,<br/> make payments and request draws.</span></i>
            </div>
          </div> -->
          <div class="" v-if="firstDrawAmount > 0.0">
            <!-- When your account is ready, you’ll be able to log in to view your account information, make payments and request draws. -->
            <div class="img-header">
              We're processing your {{ currencyShort(firstDrawAmount) }} draw.
            </div>
            <br />
            <!-- <i><span class="e-txt" style="font-size:10pt;">The Cash Advance Fee is deducted before funds are deposited into your bank account,<br/> so the amount you receive will be less than your total draw. </span></i>-->
            <div class="e-txt">
              You could see funds in the bank as soon as the next business
              day.<sup>2</sup>
              <!-- <i class="mobile-blue">If you requested a draw while you were eSigning, you could see the cash in the bank as soon as the next business day.</i> -->
            </div>
          </div>
          <div class="e-txt" v-if="firstDrawAmount <= 0.0">
            <span class="e-txt" style="font-size: 10pt"
              >Once your account is set up, you can request your first draw.
              <br />Just log in and select “Draw” on the left menu and follow
              the instructions to submit your request.
            </span>
          </div>

          <button
            class="button button-float"
            alt="logout"
            draggable="false"
            @click="logout"
          >
            LOG OUT
          </button>
        </div>
      </div>

      <div class="esigncontfooter">
        <div class="e-txt1">
          If you need to re-eSign your line of credit documents, click
          <a aria-label="If you need to re-eSign your line of credit documents, click here" class="reesign" href="/apply/approved"> here</a>.
        </div>
        <div class="link-txt">
          Questions? Call Customer Service at
          <a            
            :href="'tel:' + companyInfo.contactPhone"
            style="text-decoration: none"
          >
            <span class="phone" style="font-">{{
              companyInfo.contactPhone
            }}</span> </a>.
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import companyInfo from "@/config/text/company";
import { currencyShort } from "@/utils/filters/currency";

export default {
  name: "EsignContact",
  title: "eSignature Success",

  async created() {
    if (this.$store.state.login.token == undefined) {
      const tokenResult = await this.$store.dispatch("createToken");
      this.$store.state.login.token = tokenResult.token.writtenToken;
    }
    this.$store.state.esign.tokenID = this.$store.state.login.token;
  },
  mounted() {
    this.focusfirstelement();
  },
  computed: {
    firstName: {
      get() {
        if (this.$store.state.login.loggedIn == true) {
          return this.$store.state.login.firstName;
        } else {
          return this.$store.state.esign.firstName;
        }
      },
    },
    custID: {
      get() {
        return this.$store.state.esign.custID;
      },
    },
    custEmail: {
      get() {
        return this.$store.state.esign.custEmail;
      },
    },
    firstDrawAmount: {
      get() {
        return this.$store.state.esign.firstDrawAmount;
      },
      set(value) {
        this.value = value;
      },
    },
    loggedIn: {
      get() {
        return this.$store.state.login.loggedIn;
      },
    },
  },
  data() {
    return {
      companyInfo: companyInfo,
      isUserRegisterd: false,
      custSSN: "",
      custDOB: "",
      currencyShort: currencyShort
    };
  },
  methods: {
    focusfirstelement() {
      document.getElementById("htagContactHeader").focus();
    },

    async logout() {
      if (this.loggedIn) {
        if (this.$store.state.login.token == undefined) {
          const tokenResult = await this.$store.dispatch("createToken");
          this.$store.state.login.token = tokenResult.token.writtenToken;
        }
        this.$store.dispatch("logout");
      } else {
        this.$store.dispatch("logoutIfNotSignedIn");
      }
    },
  },
};
</script>

<style scoped lang="scss">
#content {
  margin-bottom: 0px !important;
}

 
main {
  

 //--------------------- style for tab and focus
    #htagContactHeader:focus {
        outline: 0px;
    }
//--------------------- style for tab and focus

  #content {
    margin-bottom: 0px;
    @media (max-width: 922px) {
      margin: 70px 0 0 0;
    }
  }
  .maincontent {
    border-radius: 6px;
    flex-flow: column wrap;
  }
  //width: 100%;
  .logout-button {
    background-color: $orange;
    color: $absolute-white;
    padding: 10px 60px;
    font-size: 14pt;
    border-radius: 6px;
  }

  .top {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-end;

    .title {
      padding: 0 0 10px;
      margin: 0;
      font-size: 10px;
      border-bottom: 1px solid $white;

      .big {
        font-size: 12px;
      }
    }

    .hour {
      width: 100px;
      margin: 8px 0;
    }
  }
}

.create {
  width: 300px;

  .subtitle {
    font-family: $font-primary; // $font-secondary;
    font-size: 16px;
  }
}

.bottom {
  text-align: center;
  font-family: $font-primary; //$font-secondary;
  font-size: 20px;
  padding: 40px 0;
}

.login {
  text-align: center;
  padding: 20px;

  .thanks {
    color: $green;
    font-size: 22px;
    margin-bottom: 10px;
  }

  .link {
    color: $gray;
    font-size: 16px;
  }
}

// 6611-V1 styles
.hidelarge {
  display: none;
}
.hidesmall {
  display: block;
}
.e-header {
  text-align: center;
}
.e-imgcontainer {
  //width: 100%;
  margin-bottom: 3%;
  -webkit-box-orient: horizontal;
  align-content: center;
  flex-flow: row;
  display: flex;
  text-align: center;

  .left {
    //width: 20%;
    //float: left;
    text-align: right;
    padding-right: 10px;
  }

  .right {
    //width: 75%;
    //float: right;
    text-align: left;
    padding-top: 5px;
  }

  .img-header {
    font-size: 22px;
    color: $blue;
    font-weight: 600;
  }
}
.content-block {
  //width:85%;
  text-align: center;
  .img-header {
    font-size: 22px;
    color: $blue;
    font-weight: 600;
  }
}
.e-txt {
  color: $gray;
  //padding-right: 16px;
  //margin-top: 10px;
  letter-spacing: 0.1px;
  font-size: 15px;
  //margin-bottom: 20px;
  margin: 10px 0 10px 0;
}

.esigncontfooter {
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.e-txt1 {
  //bottom: -3.5rem;
  position: relative;
  color: $gray; //$white;
  text-align: center;
  //margin-top: 10px;
  letter-spacing: 0.1px;
  font-size: 15px;
  // margin-bottom: 20px;
}
.mobile-blue {
  @media (max-width: 768px) {
    color: $blue;
  }
}
.link-txt {
  //bottom: -5rem;
  position: relative;
  color: $gray; //$white;
  //margin-top: 10px;
  letter-spacing: 0.1px;
  font-size: 15px;
  //margin-bottom: 20px;
  text-align: center;
  padding-top: 10px;

  a {
    border-bottom: 1px solid gray !important;
    color: $gray;
    text-decoration: underline;
  }

  a:hover {
    border-bottom: $gray;
    text-decoration: underline;
  }
}

.e-divider {
  border-top: 2px solid #aaa9a9;
  margin: 6%;
  width: 90%;
}
.esigncontact-box {
  margin-top: 0px;
  //margin-bottom:50px;
  margin-bottom: 25px;
  border-radius: 6px;
  max-width:700px;

  @media (max-width: 768px) {
    margin-bottom: 8%;
    margin-top: 40px;
  }
  @media (max-width: 720px) {
    margin-left: 6%;
    margin-right: 6%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .e-txt {
    text-align: center;

    a {
      color: $orange;
      font-weight: bold;
      border-bottom: 1px solid;
    }
  }
}
.button-float {
  left: 35%;
  top: 96%;
  padding: 1% 7%;
}
.reesign {
  color: $orange;
}

@media (max-width: 1023px) {
  .top {
    flex-flow: column nowrap;
    align-items: center;

    .subnote {
      margin: 0 10px 10px;
    }

    .call {
      margin: 20px auto;
    }

    .hours-small {
      width: 330px;
      padding: 0;
      margin: 10px auto 20px;
      background: $absolute-white;
      color: $green;
      font-size: 12px;
      font-weight: 700;

      .title {
        padding: 0;
        margin: 0;
        font-size: 12px;
        border-bottom: none;
      }

      .hour {
        width: 100px;
        margin: 10px 0;
      }
    }

    .hours-large {
      display: none;
    }
  }

  .button-float {
    top: 97.5%;
  }
}

@media (max-width: 768px) {
  .esigncontfooter {
    padding-bottom: 50px;
  }
}

@media (max-width: 720px) {
  .e-imgcontainer {
    -webkit-box-orient: vertical;
    align-content: center;
    flex-flow: column;
    display: flex;
    text-align: center;

    .left {
      width: 100%;
      text-align: center;
    }

    .right {
      width: 100%;
      text-align: center;
    }
  }
}

@media (max-width: 580px) {
  .hidesmall {
    display: none;
  }
  .hidelarge {
    display: block;
  }
  .button-float {
    top: 98%;
    left: 30%;
  }
  .e-txt1 {
    //bottom: -4.5rem;
    text-align: center;
  }
  .link-txt {
    //bottom: -7.5rem;
    text-align: center;
  }
}

@media (max-width: 520px) {
  .esigncontact-box {
    margin-bottom: 10%;
  }
  .esigncontfooter {
    padding-left: 10%;
    padding-right: 10%;
  }
}
</style>