import { configureCompat, createApp } from "vue";
import { createHead } from "@unhead/vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { titleMixin, MetaMixin } from "./utils/mixins/";
import { Form, Field, ErrorMessage } from "vee-validate";
import {
  BCalendar,
  BFormDatepicker,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import mitt from "mitt";

configureCompat({
  //MODE: 3,
  COMPONENT_V_MODEL: false,
  ATTR_ENUMERATED_COERCION: false,
  WATCH_ARRAY: false,
  ATTR_FALSE_VALUE: false,
  CUSTOM_DIR: 'suppress-warning',
  INSTANCE_EVENT_EMITTER: 'suppress-warning',
  GLOBAL_EXTEND: 'suppress-warning',
  GLOBAL_MOUNT: 'suppress-warning',
});

const emitter = mitt();

const head = createHead();
const appvue = createApp(App)
  .use(store)
  .use(router)
  // Make BootstrapVue available throughout your project
  //Vue.use(BootstrapVue);
  // Optionally install the BootstrapVue icon components plugin
  //Vue.use(IconsPlugin);
  .component("b-calendar", BCalendar)
  .component("b-form-datepicker", BFormDatepicker)
  .component("b-form-input", BFormInput)
  .component("b-input-group", BInputGroup)
  .component("b-input-group-append", BInputGroupAppend)
  .component("v-form", Form)
  .component("v-field", Field)
  .component("v-error", ErrorMessage);

appvue.use(head);
appvue.mixin(titleMixin);
appvue.mixin(MetaMixin);
appvue.provide("emitter", emitter);
//appvue.config.productionTip = false;
appvue.config.globalProperties.$gMiddleLayerApi =
  process.env.VUE_APP_GLOBAL_ENDPOINT;
appvue.config.warnHandler = function (msg, vm, trace) {
  //warnhandler to be removed once app moved to Vue 3 completely

  if (
    trace.toString().includes("BaseTransition") ||
    trace.toString().includes("BFormDatepicker") ||
    trace.toString().includes("BInputGroup") ||
    msg.toString().includes("BInputGroup")
  ) {
    msg = "";
  } else {
    // console.log(msg);
    // console.log(trace);
    // console.log(vm);
  }
  return msg;
};
// appvue.config.errorHandler = (err, instance, info) => {

//   // Handle the error globally
//   console.log("Global error:", err);
//   console.log("Vue instance:", instance);
//   console.log("Error info:", info);

//   // Add code for UI notifications, reporting or other error handling logic
// };
appvue.mount("#app");

// Nav Guards
router.beforeEach((to, from, next) => {
  if (to.meta.portal && !store.getters.loggedIn) {
    next({ name: "login" });
  } else {
    next();
  }
});