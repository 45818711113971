<template>
  <main class="maincontent">
   
    <PortalInterrupter v-if="finalizeCredit">
      <template v-slot:title>
          Welcome back, {{ firstName }}
      </template>
      <template v-slot:para1>
        You never finalized your <XactFlexBrandedText /> Line of Credit.
      </template>
      <template v-slot:para2>
        If you’d like to have an <XactFlexBrandedText /> Line of Credit,
      </template>
      <template v-slot:para3>
        please call Customer Service at
        <a>{{ companyInfo.customerServicePhone }}</a
        >.
      </template>
      <template v-slot:button1>       
        <button
          class="button button-float"
          alt="callnow"
        >
         <a :href="companyInfo.customerServicePhone">Call Now</a>
        </button>
      </template>
    </PortalInterrupter>
  </main>
</template>

<script>
import { PortalInterrupter, XactFlexBrandedText } from "@/components";
import companyInfo from "@/config/text/company";
export default {
  name: "AccountInterrupterLOC",
  title: "Your Account",
  components: {
    PortalInterrupter,
    XactFlexBrandedText
  },
  computed: {
    finalizeCredit: {
      get() { return this.$store.state.login.finalizeCredit },
    },
     firstName: {
      get() { return this.$store.state.login.firstName }
    }, 
  },
  data() {
    return {
      companyInfo: companyInfo
    };
  },
};
</script>

<style lang="scss" scoped>
#content {
  margin: 0;
}

.button a{
  border:0;
}

.button a:hover{
    color:white;
  }
</style>