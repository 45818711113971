const nullCheck = (input) => {
    if(input === null){
        return "Not Collected"
    } else {
        return input;
    }
}

const dateToString = (input) => {
    let date = new Date(input)
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
}

const checkDistanceBetweenDates = (date1, date2) => {
    let d1 = new Date(date1)
    let d2 = new Date(date2)
    return Math.abs((d1.getTime() - d2.getTime())/(1000 * 3600 * 24))  //date1 minus date 2 in milliseconds, divided by number of milliseconds in a day, absolute value
}

const getFormattedState = (state) => {
    switch(state){
        case "Alaska":
            return "AK";
        case "Alabama":
            return "AL";
        case "Arkansas":
            return "AR";
        case "Arizona":
            return "AZ";
        case "California":
            return "CA";
        case "Colorado":
            return "CO";
        case "Connecticut":
            return "CT";
        case "District of Columbia":
            return "DC";
        case "Delaware":
            return "DE";
        case "Florida":
            return "FL";
        case "Georgia":
            return "GA";
        case "Hawaii":
            return "HI";
        case "Iowa":
            return "IA";
        case "Idaho":
            return "ID";
        case "Illinois":
            return "IL";
        case "Indiana":
            return "IN";
        case "Kansas":
            return "KS";
        case "Kentucky":
            return "KY";
        case "Louisiana":
            return "LA";
        case "Massachusetts":
            return "MA";
        case "Maryland":
            return "MD";
        case "Maine":
            return "ME";
        case "Michigan":
            return "MI";
        case "Minnesota":
            return "MN";
        case "Missouri":
            return "MO";
        case "Mississippi":
            return "MS";
        case "Montana":
            return "MT";
        case "North Carolina":
            return "NC";
        case "North Dakota":
            return "ND";
        case "Nebraska":
            return "NE";
        case "New Hampshire":
            return "NH";
        case "New Jersey":
            return "NJ";
        case "New Mexico":
            return "NM";
        case "Nevada":
            return "NV";
        case "New York":
            return "NY";
        case "Ohio":
            return "OH";
        case "Oklahoma":
            return "OK";
        case "Oregon":
            return "OR";
        case "Pennsylvania":
            return "PA";
        case "Rhode Island":
            return "RI";
        case "South Carolina":
            return "SC";
        case "South Dakota":
            return "SD";
        case "Tennessee":
            return "TN";
        case "Texas":
            return "TX";
        case "Utah":
            return "UT";
        case "Virginia":
            return "VA";
        case "Vermont":
            return "VT";
        case "Washington":
            return "WA";
        case "Wisconsin":
            return "WI";
        case "West Virginia":
            return "WV";
        case "Wyoming":
            return "WY";
        default:
            return "error"
    }
}

const getFormattedIncomeSource = (paymentMethod) => {
    switch(paymentMethod){
        case "Disability Income":
            return "D"
        case "Social Security":
            return "G"
        case "Employed":
            return "P"
        case "Pension":
            return "S"
        case "Unemployment":
            return "U"
        case "Welfare":
            return "W";
        default:
            return "O"
    }
}

export const SoapXmlGenerator = {

    makeLoanApplicationXmlString: (store, siteData) =>
    {
        let homePhone = (store.mainIsCell) ? "Not Collected" : store.mainPhone
        let cellPhone = (store.mainIsCell) ? store.mainPhone : store.cellPhone
        let currentDate = dateToString(new Date())
        let birthdate = dateToString(store.birthdate)
        let dueDate = dateToString((checkDistanceBetweenDates(currentDate, store.firstPayDate) >= 4) ? store.firstPayDate : store.secondPayDate)
        let xml = `
            <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tem="http://tempuri.org/">
               <soapenv:Body>
                  <tem:ProcessApplication>
                     <tem:vStoreID>${siteData.clientId}</tem:vStoreID>
                     <tem:vUserID>${siteData.userId}</tem:vUserID>
                     <tem:vPassword>str4t3g1c</tem:vPassword>
                     <tem:xmlString>
                        <![CDATA[
                            <EXTPOSTTRANSACTION>
                              <STLTRANSACTIONINFO>
                                <TRANSACTIONTYPE>100</TRANSACTIONTYPE>
                                <STLTRANSACTIONID />
                                <EXTTRANSACTIONID />
                                <USERID>webuser</USERID>
                                <PASSWORD>T3st!ng101</PASSWORD>
                                <STOREID>57802000001</STOREID>
                                <MESSAGENUMBER />
                                <MESSAGEDESC />
                                <STLTRANSACTIONDATE />
                              </STLTRANSACTIONINFO>
                              <EXTTRANSACTIONDATA>
                                <CUSTOMER>
                                  <CUSTSSN>${nullCheck(store.social.replaceAll("-", ""))}</CUSTSSN>
                                  <CUSTFNAME>${nullCheck(store.firstName)}</CUSTFNAME>
                                  <CUSTMNAME />
                                  <CUSTLNAME>${nullCheck(store.lastName)}</CUSTLNAME>
                                  <CUSTADD1>${nullCheck(store.streetAddress)}</CUSTADD1>
                                  <CUSTADD2>${nullCheck(store.apt)}</CUSTADD2>
                                  <CUSTCITY>${nullCheck(store.city)}</CUSTCITY>
                                  <CUSTSTATE>${getFormattedState(store.state)}</CUSTSTATE>
                                  <CUSTZIP>${nullCheck(store.zip)}</CUSTZIP>
                                  <CUSTZIP4 />
                                  <CUSTHOMEPHONE>${homePhone.replaceAll("-", "")}</CUSTHOMEPHONE>
                                  <CUSTMOBILEPHONE>${cellPhone.replaceAll("-", "")}</CUSTMOBILEPHONE>
                                  <CUSTMSGPHONE />
                                  <CUSTFAX />
                                  <CUSTWORKPHONE>${nullCheck(store.workPhone.replaceAll("-", ""))}</CUSTWORKPHONE>
                                  <CUSTWORKPHONEEXT />
                                  <CUSTEMAIL>${nullCheck(store.email)}</CUSTEMAIL>
                                  <CUSTMOMMAIDNAME/>
                                  <CUSTDOB>${nullCheck(birthdate)}</CUSTDOB>
                                  <CUST18YRSOLD>Y</CUST18YRSOLD>
                                  <CUSTDLSTATE />
                                  <CUSTDLNO />
                                  <UTILBILLVERIFIED>0</UTILBILLVERIFIED>
                                  <YRSATCURRADD />
                                  <MNTHSATCURRADD />
                                  <YRSATPREVADD />
                                  <MNTHSATPREVADD />
                                  <LANDLORDNAME />
                                  <LANDLORDPHONE />
                                  <HOMESTATUS />
                                  <DISTFRMSTORE />
                                  <CUSTEDUCATION />
                                  <GROSSINCOME />
                                  <MKTCODES>19</MKTCODES>
                                  <PDLOANRCVDFROM>LOCO</PDLOANRCVDFROM>
                                  <LEADPRICE>120</LEADPRICE>
                                  <USERAGENT>Mozilla/5.0 (iPhone; CPU iPhone OS 12_3_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/12.1.1 Mobile/15E148 Safari/604.1</USERAGENT>
                                  <SOURCEURL>https://www.xactflex.com</SOURCEURL>
                                  <CEILINGPRICE />
                                  <FLOORPRICE />
                                  <CEILINGPRICE />
                                  <PUBLISHERNAME />
                                  <PREVIOUSCUST />
                                  <SPOUSESSN />
                                  <SPOUSEFNAME />
                                  <SPOUSEMNAME />
                                  <SPOUSELNAME />
                                  <SPOUSEDOB />
                                  <SPOUSEPHONE />
                                  <SPOUSEEMPLOYER />
                                  <SPOUSEWORKPHONE />
                                  <SPOUSEWORKPHONEEXT />
                                  <IDVERIFIED>N</IDVERIFIED>
                                  <LANGPREF>Not Collected</LANGPREF>
                                </CUSTOMER>
                                <REFERENCE>
                                  <REFFNAME>Not Collected</REFFNAME>
                                  <REFMNAME />
                                  <REFLNAME>Not Collected</REFLNAME>
                                  <REFADD1 />
                                  <REFADD2 />
                                  <REFCITY />
                                  <REFSTATE />
                                  <REFZIP />
                                  <REFZIP4 />
                                  <REFHOMEPHONE>1111111111</REFHOMEPHONE>
                                  <REFMOBILEPHONE />
                                  <REFMSGPHONE />
                                  <REFFAX />
                                  <REFWORKPHONE />
                                  <REFWORKPHONEEXT />
                                  <REFEMAIL />
                                  <REFRELATION>Not Collected</REFRELATION>
                                  <REFACTIVEFLAG>P</REFACTIVEFLAG>
                                </REFERENCE>
                                <REFERENCE>
                                  <REFFNAME>Not Collected</REFFNAME>
                                  <REFMNAME />
                                  <REFLNAME>Not Collected</REFLNAME>
                                  <REFADD1 />
                                  <REFADD2 />
                                  <REFCITY />
                                  <REFSTATE />
                                  <REFZIP />
                                  <REFZIP4 />
                                  <REFHOMEPHONE>1111111111</REFHOMEPHONE>
                                  <REFMOBILEPHONE />
                                  <REFMSGPHONE />
                                  <REFFAX />
                                  <REFWORKPHONE />
                                  <REFWORKPHONEEXT />
                                  <REFEMAIL />
                                  <REFRELATION>Not Collected</REFRELATION>
                                  <REFACTIVEFLAG>1</REFACTIVEFLAG>
                                </REFERENCE>
                                <BANK>
                                  <CUSTABANO>${nullCheck(store.routing)}</CUSTABANO>
                                  <CUSTBANKNAME />
                                  <CUSTACCTNO>${nullCheck(store.account)}</CUSTACCTNO>
                                  <CUSTACCTTYPE>C</CUSTACCTTYPE>
                                  <CUSTBANKADD1 />
                                  <CUSTBANKADD2 />
                                  <CUSTBANKCITY />
                                  <CUSTBANKSTATE />
                                  <CUSTBANKZIP />
                                  <CUSTBANKZIP4 />
                                  <CUSTBANKPHONE />
                                  <CUSTBANKFAX />
                                  <VOIDEDCHECKNO />
                                  <ACCTOPENDATE />
                                  <ACCTEXPDATE />
                                  <ACCT30DAYSOLD />
                                  <RECBANKSTMT />
                                  <NOOFNSF />
                                  <NOOFTRAN />
                                  <ENDINGSTMTBAL />
                                  <BANKACTIVEFLAG>P</BANKACTIVEFLAG>
                                </BANK>
                                <EMPLOYER>
                                  <TYPEOFINCOME>${getFormattedIncomeSource(store.source)}</TYPEOFINCOME>
                                  <EMPNAME>${nullCheck(store.employerName)}</EMPNAME>
                                  <EMPADD1 />
                                  <EMPADD2 />
                                  <EMPCITY />
                                  <EMPSTATE />
                                  <EMPZIP />
                                  <EMPZIP4 />
                                  <EMPPHONE>${nullCheck(store.workPhone.replaceAll("-", ""))}</EMPPHONE>
                                  <EMPPHONEEXT />
                                  <EMPFAX />
                                  <CONTACTNAME />
                                  <CONTACTPHONE />
                                  <CONTACTPHONEEXT />
                                  <CONTACTFAX />
                                  <BENEFITSTARTDATE />
                                  <BENEFITENDDATE />
                                  <EMPLTYPE />
                                  <JOBTITLE />
                                  <WORKSHIFT />
                                  <AVGSALARY>${nullCheck(store.takeHomePay)}</AVGSALARY>
                                  <TYPEOFPAYROLL>${nullCheck(store.paymentMethod)}</TYPEOFPAYROLL>
                                  <PERIODICITY>${nullCheck(store.paymentInterval)}</PERIODICITY>
                                  <INCOMEVERIFIED />
                                  <PAYGARNISHMENT />
                                  <PAYBANKRUPTCY>0</PAYBANKRUPTCY>
                                  <LASTPAYDATE />
                                  <NEXTPAYDATE>${dateToString(store.firstPayDate)}</NEXTPAYDATE>
                                  <SECONDPAYDATE>${dateToString(store.secondPayDate)}</SECONDPAYDATE>
                                  <PAYROLLACTIVEFLAG>P</PAYROLLACTIVEFLAG>
                                </EMPLOYER>
                                <APPLICATION>
                                  <REQUESTEDAMOUNT>3000</REQUESTEDAMOUNT>
                                  <REQUESTEDDUEDATE>${dueDate}</REQUESTEDDUEDATE>
                                  <REQUESTEDEFFECTIVEDATE />
                                  <FINANCECHARGE>0</FINANCECHARGE>
                                  <APPLICATIONDATE>${currentDate}</APPLICATIONDATE>
                                  <LOANTYPE>S</LOANTYPE>
                                  <CLNVLOANREP />
                                </APPLICATION>
                              </EXTTRANSACTIONDATA>
                            </EXTPOSTTRANSACTION>
                            ]]>
                     </tem:xmlString>
                  </tem:ProcessApplication>
               </soapenv:Body>
            </soapenv:Envelope>
        `
        //console.log("Sending Request: " + xml)
        return xml
    },

    makeEsigDocRequestXmlString: (store, siteData) =>{
        let xml =  `
        <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://services.tdclms.com" xmlns:xget="http://schemas.tdclms.com/2010/06/xGetApplicationDocumentsRequest">
           <soapenv:Header/>
           <soapenv:Body>
              <ser:xGetApplicationDocuments>
              <ser:Request xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
                    <ser:LoginData>
                       <ser:ClientID>${siteData.clientId}</ser:ClientID>
                       <ser:UserID>${siteData.userId}</ser:UserID>
                       <ser:Password>T3st!ng101</ser:Password>
                       <ser:RequestID>${store.loanRequestTransactionId}</ser:RequestID>
                    <SecurityToken i:nil="true" />
                    <Version i:nil="true" />
                       <ser:IPAddress />
                    </ser:LoginData>
                    <xget:ApplNo>${store.applicationId}</xget:ApplNo>
                    <xget:OnlyESig>false</xget:OnlyESig>
                    <xget:OnlyURL>false</xget:OnlyURL>
                 </ser:Request>
              </ser:xGetApplicationDocuments>
           </soapenv:Body>
        </soapenv:Envelope>
    `
       // console.log("Document reqeust: " + xml)
        return xml
    },


    makeSaveEsignSignitureXmlString: (store, siteData) =>{
        return `
            <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
              <s:Body>
                <xSaveDocumentESignature xmlns="http://services.tdclms.com">
                  <Request xmlns:a="http://schemas.tdclms.com/2011/05/xSaveDocumentESignatureRequest" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
                    <LoginData>
                       <ser:ClientID>${siteData.clientId}</ser:ClientID>
                       <ser:UserID>${siteData.userId}</ser:UserID>
                       <ser:Password>T3st!ng101</ser:Password>
                      <RequestID>${store.loanRequestTransactionId}</RequestID>
                      <SecurityToken i:nil="true" />
                      <Version i:nil="true" />
                      <IPAddress />
                    </LoginData>
                    <a:ApplNo>${store.applicationId}</a:ApplNo>
                    <a:IsQuickAppl>false</a:IsQuickAppl>
                    <a:ESignaturesData xmlns:b="http://schemas.tdclms.com/2011/05/ESignatureData">
                      <b:ESignatureData>
                        <b:ESignatureID>1</b:ESignatureID>
                        <b:DocumentID>${store.loanAppDocumentID}</b:DocumentID>
                        <b:ESignatureText>${store.borrowerName}</b:ESignatureText>
                        <b:ESignatureIPAddress>323.33.33.33</b:ESignatureIPAddress>
                        <b:ESignatureDate>${new Date()}</b:ESignatureDate>
                        <b:ESignatureOrderNo>1</b:ESignatureOrderNo>
                        <b:RecordStatusData i:nil="true" xmlns:c="http://schemas.tdclms.com/2010/08/RecordStatusData" />
                      </b:ESignatureData>
                    </a:ESignaturesData>
                  </Request>
                </xSaveDocumentESignature>
              </s:Body>
            </s:Envelope>
        `
    }
}