// import { Validator } from 'vee-validate';
// import moment from "moment";

// Validator.extend('validpaydate', {
//     getMessage: 'Please enter the most recent date you were paid',
//     validate: value => {
//         if (value == null || value.length < 10) {
//             return false;
//         }
//         var inp_date = moment(value).format("MM/DD/YYYY");
//         if (!moment(inp_date).isValid()) {
//             return false
//         }
//         else
//             return true
//     }
// });

// Validator.extend('valid1stpaydate', {
//     getMessage: 'Please enter your 1ˢᵗ upcoming pay date',
//     validate: value => {
//         if (value == null || value.length < 10) {
//             return false;
//         }
//         var inp_date = moment(value).format("MM/DD/YYYY");
//         if (!moment(inp_date).isValid()) {
//             return false
//         }
//         else
//             return true
//     }
// });

// Validator.extend('valid2ndpaydate', {
//     getMessage: 'Please enter your 2ⁿᵈ upcoming pay date',
//     validate: value => {
//         if (value == null || value.length < 10) {
//             return false;
//         }
//         var inp_date = moment(value).format("MM/DD/YYYY");
//         if (!moment(inp_date).isValid()) {
//             return false
//         }
//         else
//             return true
//     }
// });

// Validator.extend('selectotherthanfirstvalue', {
//     getMessage: 'Please select other option',
//     validate: value => {
//         if (value == null || value == 0) {
//             return false;
//         }
//         return true;
//     }
// });

export const passwordRegexs = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{7,19}$/;
