<template>
  <div class="bgcontainer">
    <div class="bglayout">
      <div class="helloAcct">
        <div class="clsh6">Welcome back, {{ firstName }}</div>
      </div>

      <main class="portal-wrapper">
        <PortalNav page="payment" />

        <div class="portal cardPortal card">
          <h1 class="visually-hidden">Your Account</h1>
          <PortalLoader />
          <div class="portal-body">
            <PortalMakePayment />
            <div class="vertBar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1"
                height="600"
                viewBox="0 0 1 450"
              >
                <path d="M1,75H0V600H1Z" fill="#ededed" />
              </svg>
            </div>
            <PortalAccountSum />
          </div>

          <div class="portal-footer">
            <div class="portal-call-button cardNew">
              <img
                src="../../assets/icons/questions.png"
                alt="help icon"
                class="call-icon"
              />
              <div v-if="!chargeoff && !delinquent">
                <div class="clsh3">Questions?</div>
                <div class="clsh5">
                  Call Us:
                  <a
                    class="no-border no-hover de-border"
                    :href="'tel:' + companyInfo.contactPhone"
                    >{{ companyInfo.contactPhone }}</a
                  >
                </div>
              </div>
              <div v-else>
                <div class="clsh3">Questions</div>
                <div class="clsh5 acctSpec">
                  Talk to an Account <br />
                  Specialist:
                  <a
                    class="no-border no-hover de-border"
                    :href="'tel:' + companyInfo.advancedCustomerServicePhone"
                    >{{ companyInfo.advancedCustomerServicePhone }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import {
  PortalNav,
  PortalMakePayment,
  PortalLoader,
  PortalAccountSum,
} from "@/components";
import companyInfo from "@/config/text/company";
/*import {
lvxScript,
lvxURL
} from "@/config/amounts";*/

export default {
  name: "PortalPayment",
  title: "Make A Payment On Your Line Of Credit",
  components: {
    PortalNav,
    PortalMakePayment,
    PortalLoader,
    PortalAccountSum,
  },
  async created() {
    this.showPartialSummary = true;

    /*if (document.head.children["lvxChatScript"] == null && lvxScript != null && lvxScript != "") {
let startScript = document.createElement('script');
startScript.setAttribute('src', lvxURL);
startScript.setAttribute('id', 'lvxChatInclude');
startScript.onload = () => {
let widgetScript = document.createElement('script');
widgetScript.setAttribute('id', 'lvxChatScript');
widgetScript.append(lvxScript);
document.head.appendChild(widgetScript);
};
document.head.appendChild(startScript);
}*/
  },
  computed: {
    firstName: {
      get() {
        return this.$store.state.account.firstName;
      },
    },
    showPartialSummary: {
      get() {
        return this.$store.state.portal.showPartialSummary;
      },
      set(value) {
        this.$store.commit("updateShowPartialSummary", value);
      },
    },
    chargeoff: {
      get() {
        return this.$store.state.account.chargeoff;
      },
    },
    delinquent: {
      get() {
        return this.$store.state.account.delinquent;
      },
    },
  },
  data() {
    return {
      companyInfo: companyInfo,
      /*lvxScript: lvxScript,
lvxURL: lvxURL,*/
    };
  },
};
</script>

<style scoped lang="scss">
.portal-wrapper {
  @media (max-width: 960px) {
    flex-flow: column nowrap !important;
    margin-top: 25px;
    .portal {
      margin: 0px 0px 20px 0px;
    }
  }
}

.portal-call-button {
  @media (min-width: 768px) {
    display: none;
  }
}

.helloAcct {
  display: none;

  @media (max-width: 1023px) {
    margin-top: 20px;
    text-align: center;
    display: block;
  }
}
.portal-body {
  height: max-content;
}

.cardPortal {
  background: $absolute-white;

  @media (max-width: 428px) {
    margin: 50px -25px 20px 10px;
    width: 95%;
  }
}

.portal-wrapper {
  .account-summary {
    margin: 33px -28px 0 0;
    padding: 30px 40px;
  }
}

.pair {
  width: 95%;

  .portal-call-button {
    .call-icon {
      width: 36px;
      height: 36px;
      float: left;
      position: relative;
      margin-right: 7px;
    }
  }
}

.portal-wrapper .portal .cardNew.portal-call-button {
  padding-right: 0px;
  margin-right: 0px;
  margin-top: 80px;
  @media (max-width: 768px) {
    margin: 20px auto;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .clsh3 {
    font-size: 20px;
  }
  .clsh5 {
    font-size: 18px;

    @media (min-width: 961px) and (max-width: 971px) {
      font-size: 16px;
    }

    @media (min-width: 784px) and (max-width: 842px) {
      font-size: 16px;
    }

    @media (min-width: 769px) and (max-width: 783px) {
      font-size: 15px;
    }
  }
}

.pair .left {
  width: 65%;
  float: left;
}

.portal-footer {
  width: 30%;
  float: right;
  @media (max-width: 768px) {
    width: 100%;
    float: none;
  }
}

.call-icon {
  width: 40px;
  height: 40px;
  float: left;
  position: relative;
  top: 8px;
  margin-right: 10px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.bgcontainer {
  background-image: url(../../assets/backgrounds/xactflex-hero-background@2x.jpg);
  background-size: cover;
}

.vertBar {
  display: block;

  @media (max-width: 768px) {
    display: none;
  }
}

.bglayout {
  width: 85%;
  margin: 0px auto;

  @media (min-width: 414px) and (max-width: 428px) {
    width: 95%;
  }
  @media (min-width: 360px) and (max-width: 413px) {
    width: 95%;
  }
}
</style>