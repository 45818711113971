<template>
  <div class="document-modal" v-if="showDocumentModal"
    aria-modal="true"
    role="alertdialog"
    aria-describedby="paperCheckTitle"
  >
    <div class="document-wrapper" >
      <div class="document" >

  <div class="row papercheck" id="divPaperCheckValidation">
      <br/>
      <br/>
      <div style="padding-left:5%; padding-right:5%;">
        <div style="text-align:left;" id="paperCheckTitle">
          <div class="papercheck-header" >You've opted to repay any draws on your Line of Credit with paper checks.</div>
          <div class="papercheck-subheader">Selecting this option may extend the processing time of your application by 7 to 10 business days.</div>
        </div>
          <div class="papercheck-contents">
                  <p class="papercheck-contents-header"><b>TO PROCEED:</b></p>
                  <ul style="padding-left:15px;">
                      <li class="papercheck-contents-subheader">Please call Customer Service at <a id="lnkpaperchkagtno" @keydown.shift.tab="focuspreviouseleinpopup($event);"  href="tel:1.833.546.2135" style="color:#53575A;text-decoration-line: underline; border-bottom:none;">1.833.546.2135</a> to complete your application confirming that you want to pay by paper check.</li>
                      <li class="papercheck-contents-subheader">After we confirm that you want to pay by paper check, we’ll send you a Check Confirmation form, an eSign Consent form, an Arbitration Agreement and Privacy Notice.</li>
                      <li class="papercheck-contents-subheader">You’ll need to complete and sign the forms and then email them back to us.</li>
                      <li class="papercheck-contents-subheader">We’ll review the forms you send in and then notify you about your line of credit status.</li>
                      <li class="papercheck-contents-subheader">If your line of credit is approved, we’ll provide you with additional instructions to complete the process online.</li>
                  </ul>
          </div>
          <br/>
          <div >
             <div class="papercheck-footer">
              <a id="lnkpaperchkcallagtno" href="tel:1.833.546.2135" class="callanagent"><i>Call An Agent: 1.833.546.2135</i></a>
              <button class="button-papercheck" id="btnGoBackEft"  @keydown.tab.exact="focusnexteleinpopup($event);"  @click="repaymentMethod='direct-deposit'; closeModal();">
                      GO BACK TO EFT AND FINISH ESIGN
                      </button>
                    </div> 
                    </div>
          <br/><br/>
      </div>
    </div>
      </div>
  

    </div>
  </div>
</template>

<script>
export default {
  name: 'PaperCheckModal',
  computed: {
    showDocumentModal: {
      get() { 
        return this.$store.state.esign.showPaperCheckModal 
        },
      set(value) { this.$store.state.esign.showPaperCheckModal = value }
    },
    repaymentMethod: {
      get() { return this.$store.state.esign.paymentMethod },
      set(value) { this.$store.commit('updatePaymentMethod', value) }
    },

  },
  
  methods: {
    
    onKeydown(event) {
      switch (event.key) {        
        case "Tab":
          this.focusnexteleinpopup(event);
          break;
        case "Escape":
          this.repaymentMethod='direct-deposit'; 
          this.closeModal();
          break;

        default:
          break;
      }
      
    },
    focusnexteleinpopup(event) {
      document.getElementById("lnkpaperchkagtno").focus();
      event.stopPropagation();
      event.preventDefault();
    },
    focuspreviouseleinpopup(event) {
      document.getElementById("btnGoBackEft").focus();
      event.stopPropagation();
      event.preventDefault();
    },
    closeModal() {
      document.getElementById("spanPaymentMethodPaperChk").focus();
      this.showDocumentModal = false;
    },
    OutDivClick(){
      this.closeModal();
    },
    InDivClick(event){
      event.stopPropagation()
    },
  }
}
</script>

<style scoped lang="scss">

.document-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
  background-color: rgba(0,0,0,.5);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  .document-wrapper {
    min-width: 600px;
    max-width: 850px;
    //max-height: 80%;
    //min-height: 500px;
    //border-radius: 10px;
    background-color: $white;
    box-shadow: 0 3px 6px 1px rgba(0, 0, 0, .5);

    .title {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-bottom: 1px solid $black;
      font-size: 24px;
      font-weight: 700;
      color: $black;
    }

    .inputs
    {
      display:flex;
    }

    .document{
      max-height: inherit;
    overflow-y: scroll;
    }
  }


      .papercheck{
          color:#53575A; 

          .papercheck-header{
              font-size: 28px;
              font-weight: bold;
              //padding-left: 10%;
              //padding-right: 10%;
              margin-bottom: 20px;

          }
          .papercheck-subheader{
              font-size: 14px;
              //padding-left: 10%;
              //padding-right: 10%;
              font-style: italic;
              margin-top: 5px;
              font-weight: bold;;
          }
          .papercheck-contents{
            //padding-left: 24px;
            //padding-right: 24px;

              .papercheck-contents-header{
                padding-left: 0px;
              }

              .papercheck-contents-subheader{
                    margin-bottom: 15px;
                    font-size:12px;
              }

          }

          .papercheck-footer{
            font-size: 13px;
            text-align: center;
          }

          .callanagent{
            margin-right: 30px;color:#EE6323;text-decoration-line: underline; border-bottom:none;font-size: 18px;
          }

          .button-papercheck{
            color: #FFFFFF;
            background: $orange;
            font-size: 18px;
            font-weight: 800;
            padding: 6px 30px;
            padding-top: 10px;
            padding-bottom: 10px;
           
        }

        button:focus {
        outline: 2px solid #1e4388;
        filter: drop-shadow(0px 0px 4px grey);
    }

    }

  @media (max-width: 1023px) {
    .document-wrapper {
      min-width: 310px;
      max-width: 96%;

      .title {
        font-size: 20px;
      }
    }
  }

@media (max-width: 768px) {
  .button-papercheck{margin-top:20px;}
   .callanagent{
            padding-left: 30px;
          }
  }

    @media (max-width: 720px) {

    .inputs
    {
      flex-flow: column nowrap;
    }

      .agreementwidth
        {
          width:100%;
          text-align:center;
          padding-bottom: 15px;
        }
    }

    @media (max-width: 580px) {
          .document-wrapper{
            max-height: 98%;
          }

        .papercheck 
        {
           .papercheck-header
            {
              font-size:25px;
            }

                .button-papercheck
                {
              font-size: 16px;
              }
          }
    }

  @media (max-width: 480px) {
   .callanagent{
            padding-left: 10px;
            padding-right: 10px;
          }
  }
  
    @media (max-width: 420px) {
          .document-wrapper{
            max-height: 98%;
          }

        .papercheck 
        {

          .papercheck-header
          {
            font-size:24px;
          }
                .button-papercheck
                {
              font-size: 14px;
              }
          }
    }


       @media (max-width: 376px) {

        .papercheck 
        {

            .papercheck-header
            {
              font-size:23px;
            }

            .papercheck-subheader
            {
              font-size:12px;
            }
            .button-papercheck
            {
                font-size: 12px;
                padding: 6px 18px;
            }
          }
      }

       @media (max-width: 360px) {

        .papercheck 
        {

            .button-papercheck
            {
                font-size: 11px;
            }
          }
    }


       @media (max-width: 330px) {

        .document 
        {
            height:400px;
        }
    }



}
</style>