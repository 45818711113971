export default `
<!DOCTYPE html>
<html lang="en">
<body>
<div class="container">
<div class="gray"><h1>Information on the California Consumer Privacy Act</h1>
<h2>Your Rights Under the CCPA</h2>
<p>California consumers have the right to request a disclosure of what categories and specific pieces of information were collected on them, the categories of sources from which the information was collected, the business purposes for collecting or selling
    the information, and the categories of third parties to whom the information was shared. If we sell personal information for a business purpose, California consumers have the right to request a disclosure of the categories of personal information
    collected on them, the categories of personal information sold, the categories of information disclosed for a business purpose, the categories of buyers of the information, and what categories of personal information were sold to which categories
    of buyers. California consumers have the right not to be discriminated against by a business on account of their exercise of any of these rights. We provide a webpage with information and a pathway for California consumers to exercise their rights
    under the CCPA, follow <a href="https://www.xact.com/legal/ca/ccpa-form" title="CCPA Form" target="_blank">this link</a> to the
    request form.<br>
    </p>
    <h2>What Information Is Collected</h2>
    <div><p>The categories of personal information collected on California consumers in the past 12 months includes: name, address, signature, telephone number, internet protocol address, email address, account name, social security number, driver’s license
        or state identification information, bank information, credit information, employment-related information, internet and network information, geolocation data, and inferences drawn from this information. The categories of personal information of
        California consumers sold in the past 12 months includes: name, address, telephone number, email address, bank information, credit information, employment-related information, and inferences drawn from this information. The categories of personal
        information of California consumers disclosed for a business purpose in the past 12 months includes: name, address, signature, telephone number, internet protocol address, email address, account name, social security number, driver’s license
        or state identification information, bank information, credit information, employment-related information, internet and network information, geo-location data, product preferences, and inferences drawn from this information.</p>
        <h2>Submit a Request for Information</h2>
        <p>To submit a request for information, California consumers may contact us at <a>1-877-508-8742</a>; or electronically by <a href="https://www.xact.com/legal/ca/ccpa-form" title="CCPA Form" target="_blank">following this link</a> to the request form. <br>
        </p>
        <h2>Submit a Request to Delete Your Information</h2>
        <p>The CCPA grants California consumers the right to request a business delete their personal information. However, that right subject to several exceptions. Personal information that is collected, processed, sold, or disclosed pursuant to the federal
        Gramm-Leach-Bliley Act, and implementing regulations, or the California Financial Information Privacy Act is exempt from a deletion request under the CCPA. In addition, a business is not required to comply with a request for deletion of personal
        information where it is necessary for certain business and legal purposes, or where its retention is reasonably expected based on the relationship between a business and a consumer. Regardless, we understand the interest consumers have concerning
        the collection and use of their personal information. To receive more information on how to submit a request to delete personal information, California consumers may contact us at <a>1-877-508-8742</a>; or electronically by following <a href="https://www.xact.com/legal/ca/ccpa-form" title="CCPA Form" target="_blank">this link</a> to request deletion of information.<br>
        </p>
        <h3></h3>
        <h2>Do Not Sell My Personal Information</h2>
        <h3>Your Right to Opt-Out of Sale of Your Personal Information</h3>
        <p>California consumers have the right to opt out of the sale of their personal information. We provide a webpage with information and a pathway for California consumers to exercise this right to opt-out under the CCPA at the following <a href="https://www.xact.com/legal/ca/ccpa-form" title="CCPA Request Form" target="_blank">this link</a> to the opt-out form. <br>
        </p>
        <h3>Submit a Request to Opt-Out of Sale of Your Information</h3>
        <p>If you wish to control the sharing of your personal information, you can find our Gramm-Leach-Bliley Act privacy disclosure by following  <a href="https://www.xact.com/docs/xact/legal-documents/glba-privacy-notice84e418b2a5654191b848c5bda58f6451.pdf?sfvrsn=e3489c4a_31" title="CCPA Policy" target="_blank">this link</a>. For California consumers only, you can additionally submit a request to opt-out of the sale of your information by following <a href="https://www.xact.com/legal/ca/ccpa-form" title="CCPA Request Form" target="_blank"> this link</a> to the form. 
        <br>
    </p>
</div>
</div>
</div>
</body>
</html>
`