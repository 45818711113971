<template>
  <div class="bglayout">
    <PageHeaderApplication> Line Of Credit Application </PageHeaderApplication>

    <main>
      <section>
        <div class="form">
          <span class="contentheadline">We're sorry.</span>
          <span class="contentText">
            We have reviewed your application and cannot approve a line of
            credit for you at this time. We’ll send an email with further
            details.
          </span>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import { PageHeaderApplication } from "@/components";
export default {
  name: "AppDenied",
  title: "Your Line of Credit Decision",
  components: { PageHeaderApplication },
};
</script>

<style scoped lang="scss">
#content {
  @media (max-width: 767px) {
    padding-bottom: 20px;
  }
}
main {
  width: 100%;

  section {
    display: flex;
    flex-flow: column nowrap;
    margin: 60px 15px;
    max-width: 1023px !important;
    align-items: center;

    @media (max-width: 1023px) {
      max-width: 93% !important;
    }
    @media (max-width: 767px) {
      margin: 0px auto;
    }
  }
  .form {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    padding: 30px 30px 50px 30px;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid $dark-gray;
    justify-content: space-evenly;
    align-items: left;
    @media (max-width: 767px) {
      max-width: 720px;
      margin: 5% 0px !important;
      padding: 20px !important;
    }
    @media (max-width: 385px) {
      padding: 15px !important;
    }
  }
}

.contentheadline {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.contentText {
  text-align: left;

  @media (max-width: 767px) {
    padding-right: 6%;
  }

  @media (max-width: 385px) {
    padding-right: 0%;
  }
}
</style>