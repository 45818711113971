<template>
  <div class="donut" :class="delinquent || chargeoff ? 'delinquent' : null">
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 40 40"
      class="donut"
      :alt="availableCreditValue"
    >
      <defs>
        <filter
          id="drop-shadow"
          v-if="!delinquent && !chargeoff"
          x="-100%"
          y="-100%"
          width="300%"
          height="300%"
        >
          <feGaussianBlur in="SourceAlpha" stdDeviation=".7" />
          <feOffset dx="0" dy="0" result="offsetblur" />
          <feFlood flood-color="#000000" />
          <feComposite in2="offsetblur" operator="in" />
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <circle
        class="donut-hole"
        cx="20"
        cy="20"
        r="15.91549430918954"
        :fill="colors['export-absolute-white']"
      />
      <circle
        class="donut-ring"
        cx="20"
        cy="20"
        r="15.91549430918954"
        fill="white"
        stroke-width="3"
      />
      <circle
        class="donut-segment"
        cx="20"
        cy="20"
        r="15.91549430918954"
        fill="white"
        stroke-width="3"
        :stroke-dasharray="[percent, empty]"
        filter="url(#drop-shadow)"
        stroke-dashoffset="25"
      />
      <g class="donut-text">
        <text
          v-if="!delinquent && !chargeoff"
          y="50%"
          transform="translate(0, 1.5)"
        >
          <tspan x="50%" text-anchor="middle" class="donut-percent">
            {{ percent }}%
          </tspan>
        </text>
        <text v-else y="42%" transform="translate(0, 1.5)">
          <tspan x="50%" text-anchor="middle" class="donut-percent">
            YOUR ACCOUNT IS
          </tspan>
        </text>
        <text
          v-if="!delinquent && !chargeoff"
          y="50%"
          transform="translate(0, 5)"
        >
          <tspan x="50%" text-anchor="middle" class="donut-credit-used">
            AVAILABLE CREDIT
          </tspan>
        </text>
        <text v-else y="45%" transform="translate(0, 5)">
          <tspan x="50%" text-anchor="middle" class="donut-credit-used bold">
            Past Due
          </tspan>
        </text>
      </g>
    </svg>
  </div>
</template>

<script>
import Colors from "@/styles/colors.scss";

export default {
  name: "PortalTableDonut",
  props: ["percent", "isDelinquent", "isChargeOff", "availableCredit"],
  computed: {
    empty: function () {
      return this.chargeoff || this.delinquent ? 0 : 100 - this.percent;
    },
    chargeoff: {
      get() {
        return this.isChargeOff;
        //this.$store.state.account.chargeoff
      },
    },
    delinquent: {
      get() {
        return this.isDelinquent;
      },
    },
    availableCreditValue: {
      get() {
        return (
          "available credit chart- " +
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(this.availableCredit) +
          " available"
        );
      },
    },
  },
  data() {
    return {
      colors: Colors,
    };
  },
};
</script>

<style scoped lang="scss">
.donut {
  height: 200px;
  width: 220px;

  @keyframes donut-chart-fill {
    to {
      stroke-dasharray: 0 100;
    }
  }
  .donut-ring {
    stroke: $light-blue;
  }

  .donut-segment {
    // animation: donut-chart-fill 1s reverse ease-in;
    transform-origin: center;
    stroke: $blue;
  }
  .donut-text {
    font-family: $font-primary; //$font-secondary;
    fill: $blue;
    font-size: 8px;
  }
  .donut-percent {
    font-size: 10px;
    line-height: 1;
    font-weight: 600;
    color: $blue;
  }

  .donut-credit-used {
    font-size: 2.5px;
    line-height: 1;
    font-weight: 700;
  }

  @media (max-width: 1023px) {
    height: 160px;
    width: 160px;
  }
}

.donut.delinquent {
  .donut-ring {
    stroke: $delinq-dark-red;
  }

  .donut-segment {
    stroke: $delinq-dark-red;
  }

  .donut-text {
    fill: $delinq-red;
    position: relative;
    top: -20px;
  }

  .bold {
    font-weight: bold;
  }

  .donut-percent {
    font-size: 2.5px;
  }

  .donut-credit-used {
    font-size: 5px;
  }
}
</style>