<template>
  <div class="bgcontainer">
    <div class="bglayout">
      <PageHeaderApplication>
        Line of Credit Application - Pay Frequency
        <template v-slot:subtitle>
          <span>Find out if you qualify in these simple steps</span>
        </template>
      </PageHeaderApplication>

      <main>
        <v-form
       :validation-schema="schema"
        keep-values
        @invalid-submit="onIncompleteSection"
        @submit="completeSection"
        v-slot="{ errors }"
        >
        <section>
          <AppNav :navselected="4" />
          <fieldset class="form">
             <legend class="visually-hidden">
              Line of Credit Application - PAYFREQUENCY
            </legend>
            <h3 class="contentheader" tabindex="-1" id="tittlepayfrequency">
              How often do you get paid?
            </h3>
            <div
              class="inputs"
              role="radiogroup"
              aria-label="How often do you get paid?"
            >
              <div class="frequency">
                <div class="row interval">
                  <div class="radio-input">
                    <div class="radio-top-label">
                      <span>Every 2 Weeks</span>
                    </div>
                    <div class="radio-wrapper" @click="paymentInterval = 'B'">
                      <v-field
                      v-model.trim="paymentInterval"        
                      v-slot="{ field }"
                      name="paymentInterval"
                      value="B"
                      type="radio"
                      >
                      <input
                        v-bind="field"
                        type="radio"
                        Id="B"
                        name="paymentInterval"
                        v-model="paymentInterval"
                        value="B"
                        aria-describedby="paymentInterval-errormsg"
                        tabindex="-1"
                        aria-hidden="true"
                        @keydown.space="radiobuttonselection('B',$event)"
                      />
                      </v-field>
                      <span id="span2weeks" class="radio-checkmark"
                       role="radio" 
                       aria-label="Every 2 Weeks" 
                       tabindex="0"
                       @keydown.space="radiobuttonselection('B',$event)"
                        :aria-checked="paymentInterval2weeeks"
                        aria-describedby="lblpaymentInterval2weeeks"/>
                      <span class="radio-label">
                        <label id="lblpaymentInterval2weeeks" for="B">
                        <img
                          src="../../assets/icons/cal-2-weeks.png"
                          alt="Every 2 Weeks"
                        />
                        </label>
                      </span>
                    </div>
                  </div>
                  <div class="radio-input">
                    <div class="radio-top-label">
                      <span>Twice Monthly</span>
                    </div>
                    <div class="radio-wrapper" @click="paymentInterval = 'S'">
                      <v-field
                      v-model.trim="paymentInterval" 
                      name="paymentInterval"            
                      v-slot="{ field }"
                      value="S"
                      type="radio"
                      >
                      <input
                        v-bind="field"
                        type="radio"
                        Id="S"
                        name="paymentInterval"
                        v-model="paymentInterval"
                        value="S"
                        aria-describedby="paymentInterval-errormsg"
                        tabindex="-1"
                        aria-hidden="true"
                        @keydown.space="radiobuttonselection('S',$event)"
                      />
                      </v-field>
                      <span class="radio-checkmark"
                       aria-label="Twice Monthly"
                        role="radio" 
                        tabindex="0"
                        @keydown.space="radiobuttonselection('S',$event)"
                        :aria-checked="paymentIntervalTwiceMonthly"
                        aria-describedby="lblpaymentIntervalTwiceMonthly" />
                      <span class="radio-label">
                        <label id="lblpaymentIntervalTwiceMonthly" for="S">
                        <img
                          src="../../assets/icons/cal-2-monthly.png"
                          alt="Twice Monthly"
                        />
                         </label>
                      </span>
                    </div>
                  </div>
                  <div class="radio-input">
                    <div class="radio-top-label">
                      <span>Monthly</span>
                    </div>
                    <div class="radio-wrapper" @click="paymentInterval = 'M'">
                      <v-field
                      v-model.trim="paymentInterval"  
                       name="paymentInterval"           
                      v-slot="{ field }"
                      value="M"
                      type="radio"
                      >
                      <input
                        v-bind="field"
                        type="radio"
                        Id="M"
                        name="paymentInterval"
                        v-model="paymentInterval"
                        value="M"
                        aria-describedby="paymentInterval-errormsg"
                        tabindex="-1"
                        aria-hidden="true"
                        @keydown.space="radiobuttonselection('M',$event)"
                      />
                      </v-field>
                      <span class="radio-checkmark" 
                      aria-label="Monthly" role="radio" tabindex="0"
                      @keydown.space="radiobuttonselection('M',$event)"
                       :aria-checked="paymentIntervalMonthly"
                        aria-describedby="lblpaymentIntervalMonthly"/>
                      <span class="radio-label">
                        <label id="lblpaymentIntervalMonthly" for="M">
                        <img
                          src="../../assets/icons/cal-monthly.png"
                          alt="Monthly"
                        />
                         </label>
                      </span>
                     
                    </div>
                  </div>
                  <div class="radio-input">
                    <div class="radio-top-label">
                      <span>Weekly</span>
                    </div>
                    <div class="radio-wrapper" @click="paymentInterval = 'W'">
                      <v-field
                      v-model.trim="paymentInterval"
                      name="paymentInterval"               
                      v-slot="{ field }"
                      value="W"
                      type="radio"
                      >
                      <input
                        v-bind="field"
                        type="radio"
                        Id="W"
                        name="paymentInterval"
                        v-model="paymentInterval"
                        value="W"
                        aria-describedby="paymentInterval-errormsg"
                        tabindex="-1"
                        aria-hidden="true"
                        @keydown.space="radiobuttonselection('W',$event)"
                      />
                      <span v-bind="field" class="radio-checkmark" aria-label="Weekly" role="radio" tabindex="0"
                        
                       :aria-checked="paymentIntervalWeekly"
                        aria-describedby="paymentInterval-errormsg"
                        @keydown.space="radiobuttonselection('W',$event)"
                        @blur="validatepaymentinterval()"/>
                      <span class="radio-label">
                         <label id="lblpaymentIntervalWeekly" for="W">
                        <img
                          src="../../assets/icons/cal-weekly.png"
                          alt="Weekly"
                        />
                        </label>
                      </span>
                      </v-field>
                    </div>
                  </div>
                </div>
                <div
                  class="error paymentIntervalerror"
                  style="text-align: left"
                >
                  <v-error id="paymentInterval-errormsg" name="paymentInterval" />
                </div>
              </div>
            </div>
            <div class="inputs">
              <div class="row">
                <h3 class="contentheader2">Pay date information</h3>
                <div class="subnote">
                  Please enter the last date you were paid and your next two pay
                  dates. These MUST be accurate to calculate your payments.
                </div>
              </div>
            </div>
            <div class="inputs" v-show="paydateperiodicitymismatch" >
              <div class="errorhandler" role="alert">
                <span
                  >Your pay frequency and pay dates don’t seem to match up.
                  Please check to make sure they’re correct.</span
                >
                <span style="margin-top: 10px"
                  >You may need to clarify this information in your confirmation
                  call.</span
                >
              </div>
            </div>
            <div class="inputs">
              <div class="date-select">
                <H4>
                  <span STYLE="text-transform:none;margin-left: 5px;"
                    >LAST</span
                  >
                </H4>
                <div class="lastdates">
                  <FormField :errormsg="errors['lastpaydate']">
                    <template v-slot:label
                      ><label for="lastpaydate" 
                        class="applabel"
                        STYLE="text-transform:none;font-weight:700;"
                        >Pay Date mm/dd/yyyy</label
                      ></template
                    >
                 <b-input-group class="inputgroupdatewrapper">
                  <v-field  v-model="lastPayDate" name="lastpaydate" v-slot="{ field }">
                  <b-form-input
                   v-bind="field"
                    id="lastpaydate"
                    :value="date(lastPayDate)"
                    type="text"
                    autocomplete="off"
                    maxLength="10"
                    aria-required="true"                    
                    aria-describedby="lastpaydate-errormsg"
                    @keypress="allowonlynumbersandbackslash"
                    @blur="lastPayDateValidation()"
                    aria-invalid="false"
                  ></b-form-input>
                </v-field>
                  <b-input-group-append>
                    <v-field
                    v-model="lastPayDatenewformat"
                    name="lastPayDateformat"
                    v-slot="{ field }"
                  >
                    <b-form-datepicker
                     v-bind="field"
                      button-only
                      right
                      locale="en-US"
                      aria-controls="lastpaydate"
                      :max="disabledFuturePayDate.from"
                      @context="onContextLastDate"
                    ></b-form-datepicker>
                    </v-field>
                  </b-input-group-append>
                </b-input-group>
                    <template v-slot:error>
                      <v-error id="lastpaydate-errormsg" name="lastpaydate" />
                    </template>
                  </FormField>
                </div>
              </div>
              <div class="divider" />
              <div class="date-select">
                <h4>
                  <span STYLE="text-transform:none;margin-left: 5px;"
                    >UPCOMING</span
                  >
                </h4>
                <div class="upcomingdates">
                  <FormField :errormsg="errors['firstpaydate']">
                    <template v-slot:label
                      ><label for="firstpaydate" 
                        class="applabel"
                        STYLE="text-transform:none;font-weight:700;"
                      >
                        1<sup>st</sup> Pay Date mm/dd/yyyy</label
                      ></template
                    >
                <b-input-group class="inputgroupdatewrapper">
                  <v-field v-model="firstPayDate" name="firstpaydate" v-slot="{ field }">
                  <b-form-input
                    v-bind="field"
                    id="firstpaydate"
                    :value="date(firstPayDate)"
                    type="text"
                    autocomplete="off"
                    maxLength="10"
                    aria-describedby="firstpaydate-errormsg"
                    aria-required="true"
                    @keypress="allowonlynumbersandbackslash"
                    @blur="firstPayDateValidation()"
                    aria-invalid="false"
                  ></b-form-input></v-field>
                  <v-field
                    v-model="firstPayDatenewformat"
                    name="firstPayDateformat"
                    v-slot="{ field }"
                  >
                  <b-input-group-append>
                    <b-form-datepicker
                    v-bind="field"
                      button-only
                      right
                      locale="en-US"
                      aria-controls="firstpaydate"
                      :min="disabledDatesPastDate.to"
                      @context="onContextFirstDate"
                    ></b-form-datepicker>
                  </b-input-group-append>
                  </v-field>
                </b-input-group>
                    <template v-slot:error>
                    <!-- <span id="firstpaydate-errormsg" 
                     v-text="(errors.first('firstpaydate') ? errors.first('firstpaydate'): firstpaydateerror)"></span> -->
                     <v-error id="firstpaydate-errormsg" name="firstpaydate" />
                    </template>
                  </FormField>

                  <FormField :errormsg="errors['secondpaydate']" class="second">
                    <template v-slot:label>
                      <label for="secondpaydate"
                        class="applabel"
                        STYLE="text-transform:none;font-weight:600;"
                        >2<sup>nd</sup> Pay Date mm/dd/yyyy</label
                      ></template
                    >
                    <b-input-group class="inputgroupdatewrapper">
                  <v-field v-model="secondPayDate" name="secondpaydate" v-slot="{ field }">
                  <b-form-input
                  v-bind="field"
                    id="secondpaydate"
                    :value="date(secondPayDate)"
                    
                    type="text"
                    autocomplete="off"
                    
                    maxLength="10"
                    aria-describedby="secondpaydate-errormsg"
                    aria-required="true"
                    @keypress="allowonlynumbersandbackslash"
                    @blur="secondPayDateValidation()"
                  
                    aria-invalid="false"
                    
                   
                  ></b-form-input>
                </v-field>
                  <b-input-group-append>
                    <v-field
                    v-model="secondPayDatenewformat"
                    name="secondPayDateformat"
                    v-slot="{ field }"
                  >
                    <b-form-datepicker
                    v-bind="field"
                      button-only
                      right
                      locale="en-US"
                      aria-controls="secondpaydate"
                      :min="disabledDatesPastDate.to"
                      @context="onContextSecondDate"
                    ></b-form-datepicker>
                    </v-field>
                  </b-input-group-append>
                </b-input-group>
                    <template v-slot:error>
                     <v-error id="secondpaydate-errormsg" name="secondpaydate" />
                    </template>
                  </FormField>
                </div>
              </div>
            </div>
          </fieldset>
          <div class="upperbottom" role="alert" v-show="showcommonerrormsg">
            {{ help.commonapperror }}
          </div>
          <div class="bottom">
            <button class="button-orange">
              CONTINUE
            </button>
          </div>
        </section>
        </v-form>
      </main>
      <div style="display: none">
        <img
          src="../../assets/backgrounds/check_sample.png"
          alt="Complete an Application"
          class="check-sample"
        />
      </div>
    </div>
  </div>
</template>

<script>
import legal from "@/config/text/legal";
import help from "@/config/text/help";
import { FormField } from "@/components";
//import DatePicker from "vuejs-datepicker";
//import { Validator } from "vee-validate";
import { passwordRegexs } from "@/config/app_validation";
import moment from "moment";
import AppNav from "@/components/application/AppNav.vue";
import { limitNums, limitAlphabates } from "@/utils/plugins/inputs";
import PageHeaderApplication from "@/components/global/PageHeaderApplication";
import { birthdateStartDate, birthdateDisabledDates } from "@/config/dates";
import { date } from '@/utils/filters/dates';
import * as yup from "yup";
export default {
  name: "AppPayFrequency",
  title: "Line of Credit Application",
  components: {
    PageHeaderApplication,
    FormField,
    AppNav,
  },
  data() {

    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);
    maxDate.setFullYear(maxDate.getFullYear());

    let paymentIntervalErrormsg = "Please select one of the options";
    let lastpaydateErrormsg="Please enter the most recent date you were paid"
    let firstpaydateErrormsg = "Please enter your 1ˢᵗ upcoming pay date";
    let secondPayDateErrormsg = "Please enter your 2ⁿᵈ upcoming pay date";

    let lastpaydateInvalidmsg="Last pay date cannot be in the future. Please enter the most recent date you were paid.";
    let firstpaydateInvalidmsg="Your 1ˢᵗ upcoming pay date must be in the future. Please enter your next pay date.";
    let secondpaydateInvalidmsg="Your 2ⁿᵈ upcoming pay date must be in the future, after your 1ˢᵗ upcoming pay date";

    const schema = yup.object().shape({
      paymentInterval: yup.string().required(paymentIntervalErrormsg),
      
      lastpaydate:yup.date()
      .nullable()
      .transform((curr, orig) => orig === '' ? null : curr)
      .required(lastpaydateErrormsg)
      .min(new Date("1900-01-01T00:00:00"), lastpaydateErrormsg)
      .max(maxDate,lastpaydateInvalidmsg),
      
      firstpaydate:yup.date()
      .nullable()
      .transform((curr, orig) => orig === '' ? null : curr)
      .required(firstpaydateErrormsg)
      .min(maxDate,firstpaydateInvalidmsg),
     
      secondpaydate:yup.date()
      .nullable()
      .transform((curr, orig) => orig === '' ? null : curr)
      .required(secondPayDateErrormsg)
      .min(yup.ref('firstpaydate'),secondpaydateInvalidmsg),
    });


    return {
      schema,
      legal: legal,
      help: help,
      showcommonerrormsg: false,
      lastpaydateerror: "",
      firstpaydateerror: "",
      secondpaydateerror: "",
      paydateperiodicitymismatch: false,
      birthdateStartDate: birthdateStartDate,
      birthdateDisabledDates: birthdateDisabledDates,
      passwordRegexs: passwordRegexs,
      date: date,
      disabledFuturePayDate: {
        from: new Date(Date.now())
      },
      disabledDatesPastDate: {
        to: new Date(Date.now() - 8640000)
      },
    };
  },
  created() {
    if (!this.$store.state.application.incomeComplete) {
      this.$router.replace({ name: "app-income" });
    }
    if (this.ip == null) {
      this.$store.dispatch("findIPAddress");
    }
  },
  computed: {
    isFormValid: function () {
      return !Object.values(this.fields).filter((field) => !field.valid).length;
    },
    paymentInterval: {
      get() {
        return this.$store.state.application.paymentInterval;
      },
      set(value) {
        this.$store.commit("updatePaymentInterval", value);
        this.periodicitymismatchcheck();
      },
    },
    paymentInterval2weeeks: {
        get() {
       
        if(this.$store.state.application.paymentInterval == "B")
        {
          return true;
        }
        else
        {
          return false;
        }
       
      },
    },
    paymentIntervalTwiceMonthly: {
        get() {
       
        if(this.$store.state.application.paymentInterval == "S")
        {
          return true;
        }
        else
        {
          return false;
        }
       
      },
    },
    paymentIntervalMonthly: {
        get() {
       
        if(this.$store.state.application.paymentInterval == "M")
        {
          return true;
        }
        else
        {
          return false;
        }
       
      },
    },
    paymentIntervalWeekly: {
        get() {
       
        if(this.$store.state.application.paymentInterval == "W")
        {
          return true;
        }
        else
        {
          return false;
        }
       
      },
    },
    lastPayDate: {
      get() {
        if (
          this.$store.state.application.lastPayDate != null &&
          this.$store.state.application.lastPayDate.toString().includes("-") &&
          this.$store.state.application.lastPayDate.length == 10
        ) {
          return moment(
            String(this.$store.state.application.lastPayDate),
            "YYYY-MM-DD"
          ).format("MM/DD/YYYY");
        }

        return this.$store.state.application.lastPayDate;
      },
      set(value) {        
        if (value.toString().length > 10) {
          value = moment(value, "MM/DD/YYYY").format("MM/DD/YYYY");
        }
        this.$store.commit("updateLastPayDate", value);
        this.lastpaydateerror = "";
      },
    },
    lastPayDatenewformat: {
      get() {
        if (String(this.lastPayDate).length == 10) {
          var val = moment(String(this.lastPayDate), "MM/DD/YYYY").format(
            "YYYY-MM-DD"
          );
          return val;
        } else {
          return this.lastPayDate;
        }
      },
      set(value) {        
        this.lastPayDate = value;
        this.lastPayDateValidation();
      },
    },
    firstPayDate: {
      get() {
        if (
          this.$store.state.application.firstPayDate != null &&
          this.$store.state.application.firstPayDate.toString().includes("-") &&
          this.$store.state.application.firstPayDate.length == 10
        ) {
          return moment(
            String(this.$store.state.application.firstPayDate),
            "YYYY-MM-DD"
          ).format("MM/DD/YYYY");
        }
        return this.$store.state.application.firstPayDate;
      },
      set(value) {
        if (value.toString().length > 10) {
          value = moment(value, "MM/DD/YYYY").format("MM/DD/YYYY");
        }
        this.$store.commit("updateFirstPayDate", value);
        this.firstpaydateerror = "";
      },
    },
    firstPayDatenewformat: {
      get() {
        if (String(this.firstPayDate).length == 10) {
          var val = moment(String(this.firstPayDate), "MM/DD/YYYY").format(
            "YYYY-MM-DD"
          );
          return val;
        } else {
          return this.firstPayDate;
        }
      },
      set(value) {
        this.firstPayDate = value;
      },
    },
    secondPayDate: {
      get() {
        if (
          this.$store.state.application.secondPayDate != null &&
          this.$store.state.application.secondPayDate.toString().includes("-") &&
          this.$store.state.application.secondPayDate.length == 10
        ) {
          return moment(
            String(this.$store.state.application.secondPayDate),
            "YYYY-MM-DD"
          ).format("MM/DD/YYYY");
        }
        return this.$store.state.application.secondPayDate;
      },
      set(value) {
        if (value.toString().length > 10) {
          value = moment(value, "MM/DD/YYYY").format("MM/DD/YYYY");
        }
        this.$store.commit("updateSecondPayDate", value);
        this.secondpaydateerror = "";
      },
    },
    secondPayDatenewformat: {
      get() {
        if (String(this.secondPayDate).length == 10) {
          var val = moment(String(this.secondPayDate), "MM/DD/YYYY").format(
            "YYYY-MM-DD"
          );
          return val;
        } else {
          return this.secondPayDate;
        }
      },
      set(value) {
        this.secondPayDate = value;
      },
    },
  },
  mounted() {
    this.focusfirstelement();
    this.periodicitymismatchcheck();
    document.getElementById("divpayfrequency").setAttribute("aria-current","step");
  },
  methods: {
    onContextLastDate(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      //this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      //this.selected = ctx.selectedYMD;
      if (
        ctx.selectedYMD != null &&
        ctx.selectedYMD != "" &&
        ctx.selectedYMD != "2001-01-01" &&
        ctx.selectedYMD.length == 10
      ) {
        this.lastPayDateValidation();
      }
    },
    onContextFirstDate(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      //this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      //this.selected = ctx.selectedYMD;
      if (
        ctx.selectedYMD != null &&
        ctx.selectedYMD != "" &&
        ctx.selectedYMD != "2001-01-01" &&
        ctx.selectedYMD.length == 10
      ) {
        this.commonForfirstPaydate();
      }
    },
    onContextSecondDate(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      //this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      //this.selected = ctx.selectedYMD;
      if (
        ctx.selectedYMD != null &&
        ctx.selectedYMD != "" &&
        ctx.selectedYMD != "2001-01-01" &&
        ctx.selectedYMD.length == 10
      ) {
        this.secondPayDateValidation();
      }
    },
    focusfirstelement() {
      document.getElementById("tittlepayfrequency").focus();
    },
     radiobuttonselection(val,event) {      
      this.paymentInterval = val;
      event.preventDefault();
    },
     validatepaymentinterval() {
      if (
        this.paymentInterval == null ||
        this.paymentInterval == undefined ||
        this.paymentInterval == ""
      ) {
        //this.$validator.validate("paymentInterval");
        return true;
      }
    },
    completeSection() {
      // this.$validator.validate().then((valid) => {
      //   if (
      //     valid &&
      //     this.lastpaydateerror == "" &&
      //     this.firstpaydateerror == "" &&
      //     this.secondpaydateerror == ""
      //   ) {
      //     this.showcommonerrormsg = false;
      //     this.$store.commit("updatecompletePayFrequency", true);
      //     this.$router.push({ name: "app-banking" });
      //   } else {
      //     this.showcommonerrormsg = true;
      //   }
      // });
      if (
        this.schema.isValid &&
          this.lastpaydateerror == "" &&
          this.firstpaydateerror == "" &&
          this.secondpaydateerror == ""
        ) {
          this.showcommonerrormsg = false;
          this.$store.commit("updatecompletePayFrequency", true);
          this.$router.push({ name: "app-banking" });
        } else {
          this.showcommonerrormsg = true;
        }

    },
    onIncompleteSection()
    {
      this.showcommonerrormsg = true;
    },
    updatepaydate(eventval, type) {
      var inval = eventval;
      var inputvalue = "";
      if (inval == "" || inval == null) {
        inputvalue = "";
      } else {
        if (!String(inval).includes("/")) {
          inval = moment(inval, "MM/DD/YYYY").format("MM/DD/YYYY");
        }
        var dateArray = inval.split("/");
        if (dateArray != null && dateArray.length == 3) {
          if (dateArray[0].length == 1) {
            dateArray[0] = "0" + dateArray[0];
          }
          if (dateArray[1].length == 1) {
            dateArray[1] = "0" + dateArray[1];
          }
          if (
            dateArray[0].length == 2 &&
            dateArray[1].length == 2 &&
            dateArray[2].length == 4
          ) {
            inputvalue = dateArray.toString().replaceAll(",", "/");
          } else {
            inputvalue = "";
          }
        } else {
          inputvalue = "";
        }
      }

      if (type == "L") {
        this.lastPayDate = inputvalue;
      } else if (type == "F") {
        this.firstPayDate = inputvalue;
      } else if (type == "S") {
        this.secondPayDate = inputvalue;
      }
    },
    openLastPayDate() {
      this.$refs.lastPayDate.showCalendar();
      this.$refs.firstPayDate.close();
      this.$refs.secondPayDate.close();
      document.getElementById("lastpaydate").focus();
    },
    openFirstPayDate() {
      this.$refs.firstPayDate.showCalendar();
      this.$refs.lastPayDate.close();
      this.$refs.secondPayDate.close();
      document.getElementById("firstpaydate").focus();
    },
    openSecondPayDate() {
      this.$refs.secondPayDate.showCalendar();
      this.$refs.firstPayDate.close();
      this.$refs.lastPayDate.close();
      document.getElementById("secondpaydate").focus();
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    allowonlynumbersandbackslash(event) {
      var keyCode = event.keyCode;
      var isShift = false;
      if (keyCode == 16) {
        isShift = true;
      }
      //Allow only Numeric Keys.
      if (
        ((keyCode >= 47 && keyCode <= 57) ||
          keyCode == 8 ||
          keyCode <= 37 ||
          keyCode <= 39) &&
        isShift == false
      ) {
        if (keyCode == 47) {
          if (
            event.target.value != null &&
            event.target.value.length >= 1 &&
            event.target.value.length <= 5 &&
            keyCode != 8
          ) {
            return true;
          } else {
            event.preventDefault();
          }
        } else {
          var inval = event.target.value;
          if (
            inval != null &&
            (inval.length == 2 || inval.length == 5) &&
            keyCode != 8 &&
            keyCode != 47
          ) {
            let length = inval.length - 1;
            let count = inval.split("/").length;
            if (inval[length] != "/" && count <= 2)
              event.target.value = inval + "/";
          }
        }
      } else {
        event.preventDefault();
      }
    },
    periodicitymismatchcheck() {
      if (
        this.lastPayDate != "" &&
        this.lastPayDate != null &&
        this.firstPayDate != "" &&
        this.firstPayDate != null &&
        this.secondPayDate != "" &&
        this.secondPayDate != null
      ) {
        try {          
          var last_date = moment(this.lastPayDate, "MM/DD/YYYY");
          var first_date = moment(this.firstPayDate, "MM/DD/YYYY");
          var second_date = moment(this.secondPayDate, "MM/DD/YYYY");
          let firstpaydatediff = first_date.diff(last_date, "days");
          let secondpaydatediff = second_date.diff(first_date, "days");
          if (this.paymentInterval == "W") {
            if (firstpaydatediff != 7 || secondpaydatediff != 7) {
              this.paydateperiodicitymismatch = true;
              return;
            }
          }
          if (this.paymentInterval == "B") {
            if (firstpaydatediff != 14 || secondpaydatediff != 14) {
              this.paydateperiodicitymismatch = true;
              return;
            }
          }
          if (this.paymentInterval == "M") {
            if (firstpaydatediff != 31 || secondpaydatediff != 31) {
              this.paydateperiodicitymismatch = true;
              return;
            }
          }
          this.paydateperiodicitymismatch = false;
        } catch {
          //console.log(error);
          this.paydateperiodicitymismatch = false;
        }
      }
    },
    commonForLastPaydate() {      
      if (this.lastPayDate != "" && this.lastPayDate != null) {
        var paymentinterval = 0;
        if (document.getElementById("W").checked) {
          paymentinterval = 7;
        } else if (document.getElementById("B").checked) {
          paymentinterval = 14;
        } else if (document.getElementById("M").checked) {
          paymentinterval = 31;
        }
        if (paymentinterval != 0) {
          this.firstPaydatePaymentInterval(paymentinterval);
          this.firstPayDateValidation();
        }
       // this.$validator.validate();
      }
    },
    commonForfirstPaydate() {
      var paymentInterval = 0;
      if (document.getElementById("W").checked) {
        paymentInterval = 7;
      }
      if (document.getElementById("B").checked) {
        paymentInterval = 14;
      }
      if (document.getElementById("M").checked) {
        paymentInterval = 31;
      }
      if (paymentInterval != 0) {
        this.secondPaydatePaymentInterval(paymentInterval);
        this.secondPayDateValidation();
      }
      //this.$validator.validate();
    },
    firstPaydatePaymentInterval(paymentInterval) {
      if (this.lastPayDate != "" && this.lastPayDate != null) {
        this.firstPayDate = moment(this.lastPayDate, "MM/DD/YYYY")
          .add(paymentInterval, "days");
      } else {
        this.firstPayDate = "";
      }
    },
    secondPaydatePaymentInterval(paymentInterval) {
      if (this.firstPayDate != "" && this.firstPayDate != null) {
        this.secondPayDate = moment(this.firstPayDate, "MM/DD/YYYY")
          .add(paymentInterval, "days");
      } else {
        this.secondPayDate = "";
      }
    },

    firstPayDateValidation() {
      if (
        this.firstPayDate == null ||
        this.firstPayDate == undefined ||
        this.firstPayDate == "" ||
        this.firstPayDate.length < 10
      ) {
        //this.$validator.validate("firstpaydate");
        return true;
      }
      var inp_date = moment(this.firstPayDate, "MM/DD/YYYY").format(
        "MM/DD/YYYY"
      );
      if (inp_date == "Invalid date") {
        this.firstPayDate = "";
        this.firstpaydateerror = "";
        //this.$validator.validate("firstpaydate");
        return true;
      }
      if (
        this.firstPayDate != "" &&
        this.firstPayDate != null &&
        this.firstPayDate.length == 10
      ) {
        var cur_date = moment(new Date(), "MM/DD/YYYY").format("MM/DD/YYYY");

        if (moment(inp_date, "MM/DD/YYYY").isValid()) {
          //if (this.disablePastDates(this.firstPayDate) == true) {
          if (moment(inp_date, "MM/DD/YYYY").isBefore(cur_date, "MM/DD/YYYY")) {
            this.firstpaydateerror =
              "Your 1ˢᵗ upcoming pay date must be in the future. Please enter your next pay date.";
          } else this.firstpaydateerror = "";
          //} else this.firstpaydateerror = "";

          this.commonForfirstPaydate();
          this.periodicitymismatchcheck();
        }
      }
    },
    secondPayDateValidation() {
      if (
        this.secondPayDate == null ||
        this.secondPayDate == undefined ||
        this.secondPayDate == "" ||
        this.secondPayDate.length < 10
      ) {
       // this.$validator.validate("secondpaydate");
        return true;
      }
      var inp_date = moment(this.secondPayDate, "MM/DD/YYYY").format(
        "MM/DD/YYYY"
      );
      if (inp_date == "Invalid date") {
        this.secondPayDate = "";
        this.secondpaydateerror = "";
       // this.$validator.validate("secondpaydate");
        return true;
      }
      if (
        this.secondPayDate != "" &&
        this.secondPayDate != null &&
        this.secondPayDate.length == 10
      ) {
        var cur_date = moment(new Date(), "MM/DD/YYYY").format("MM/DD/YYYY");
        var first_inp_date = moment(this.firstPayDate, "MM/DD/YYYY").format(
          "MM/DD/YYYY"
        );
        if (moment(inp_date, "MM/DD/YYYY").isValid()) {
          //if (this.disablePastDates(this.secondPayDate) == true) {
          if (moment(inp_date, "MM/DD/YYYY").isBefore(cur_date, "MM/DD/YYYY"))
            this.secondpaydateerror =
              "Your 2ⁿᵈ upcoming pay date must be in the future, after your 1ˢᵗ upcoming pay date";
          //}
          else if (
            moment(inp_date, "MM/DD/YYYY").isBefore(
              first_inp_date,
              "MM/DD/YYYY"
            )
          )
            this.secondpaydateerror =
              "Your 2ⁿᵈ upcoming pay date must be in the future, after your 1ˢᵗ upcoming pay date";
          else this.secondpaydateerror = "";

          this.periodicitymismatchcheck();
        }
      }
    },
    lastPayDateValidation() {
      if (
        this.lastPayDate == null ||
        this.lastPayDate == undefined ||
        this.lastPayDate == "" ||
        this.lastPayDate.length < 10
      ) {
       // this.$validator.validate("lastpaydate");
        //document.getElementById("lastpaydate").setAttribute('aria-invalid', true);
        return true;
      }
      
      var inp_date = moment(this.lastPayDate, "MM/DD/YYYY").format(
        "MM/DD/YYYY"
      );
      //document.getElementById("lastpaydate").setAttribute('aria-invalid', false);
      if (inp_date == "Invalid date") {
        this.lastPayDate = "";
        this.lastpaydateerror = "";
        //this.$validator.validate("lastpaydate");
        //document.getElementById("lastpaydate").setAttribute('aria-invalid', true);
        return true;
      }

      var cur_date = moment(new Date(), "MM/DD/YYYY").format("MM/DD/YYYY");

      if (moment(inp_date, "MM/DD/YYYY").isValid()) {
        if (moment(inp_date, "MM/DD/YYYY").isAfter(cur_date, "MM/DD/YYYY"))
          this.lastpaydateerror =
            "Last pay date cannot be in the future. Please enter the most recent date you were paid.";
        else {
          this.lastpaydateerror = "";

          if (this.paymentInterval != 0) {
            this.commonForLastPaydate();
          }
        }

        this.periodicitymismatchcheck();
      }
    },

    disablePastDates(pastdate) {
      var date = new Date(pastdate);
      var nthWeekDay = Math.ceil(date.getDate() / 7);
      // disables the date if it is federal holiday
      if (date.getDay() == 0 || date.getDay() == 6) {
        return true;
      }
      // New Years Day (Jan 1, or preceding Friday/following Monday if weekend) and MLK Day(3rd monday in January)
      var cur_date = moment(new Date(), "MM/DD/YYYY").format("MM/DD/YYYY");
      var inp_date = moment(pastdate, "MM/DD/YYYY").format("MM/DD/YYYY");
      if (moment(inp_date, "MM/DD/YYYY").isBefore(cur_date, "MM/DD/YYYY")) {
        return true;
      }

      if (date.getMonth() == 0) {
        if (
          date.getDate() == 1 &&
          !(date.getDay() == 6 || date.getDay() == 0)
        ) {
          return true;
        }
        if (date.getDate() == 2 && date.getDay() == 2) {
          return true;
        }
        if (nthWeekDay == 3 && date.getDay() == 1) {
          return true;
        }
      }
      //President’s Day (3rd Monday in February)
      if (nthWeekDay == 3 && date.getDay() == 1 && date.getMonth() == 1) {
        return true;
      }
      // Memorial Day (Last Monday in May)
      if (date.getMonth() == 4) {
        var newDate = new Date(date.getYear(), date.getMonth(), date.getDate());
        newDate.setDate(newDate.getDate() + 7);
        if (date.getDay() == 1 && newDate.getMonth() == 5) {
          return true;
        }
      }
      // Independence Day (July 4, or prededing friday/following monday if weekend
      if (date.getMonth() == 6) {
        if (date.getDate() == 3 && date.getDay() == 5) {
          return true;
        }
        if (
          date.getDate() == 4 &&
          !(date.getDay() == 6 || date.getDay() == 0)
        ) {
          return true;
        }
        if (date.getDate() == 5 && date.getDay() == 1) {
          return true;
        }
      }
      //Labor Day (1st Monday in September)
      if (nthWeekDay == 1 && date.getDay() == 1 && date.getMonth() == 8) {
        return true;
      }
      // Columbus Day (2nd Monday in October)
      if (nthWeekDay == 2 && date.getDay() == 1 && date.getMonth() == 9) {
        return true;
      }
      //Veteran's Day (November 11, or preceding Friday/following Monday if weekend
      // Thanksgiving Day (4th Thursday in November)
      if (date.getMonth() == 10) {
        if (date.getDate() == 10 && date.getDay() == 5) {
          return true;
        }
        if (
          date.getDate() == 11 &&
          !(date.getDay() == 6 || date.getDay() == 0)
        ) {
          return true;
        }
        if (date.getDate() == 12 && date.getDay() == 1) {
          return true;
        }
        if (nthWeekDay == 4 && date.getDay() == 4) {
          return true;
        }
      }
      //Christmas Day (December 25, or preceding Friday/following Monday if weekend)
      if (date.getMonth() == 11) {
        if (date.getDate() == 24 && date.getDay() == 5) {
          return true;
        }
        if (
          date.getDate() == 25 &&
          !(date.getDay() == 6 || date.getDay() == 0)
        ) {
          return true;
        }
        if (date.getDate() == 26 && date.getDay() == 1) {
          return true;
        }
      }
    },
    limitNums,
    limitAlphabates,
  },
};
// Validator.localize({
//   en: {
//     custom: {
//       paymentInterval: {
//         required: "Please select one of the options",
//       },
//       lastpaydate: {
//         required: "Please enter the most recent date you were paid",
//         date_format: "Please enter the most recent date you were paid",
//       },
//       firstpaydate: {
//         required: "Please enter your 1ˢᵗ upcoming pay date",
//         date_format: "Please enter your 1ˢᵗ upcoming pay date",
//       },
//       secondpaydate: {
//         required: "Please enter your 2ⁿᵈ upcoming pay date",
//         date_format: "Please enter your 2ⁿᵈ upcoming pay date",
//       },
//     },
//   },
// });
</script>

<style scoped lang="scss">
sup {
  font-size: 10px;
  margin-top: 3px;
  margin-right: 2px;
}
main {
  width: 100%;

  section {
    display: flex;
    flex-flow: column nowrap;
    max-width: 1023px !important;

    @media (max-width: 767px) {
      margin-left: 15px !important;
      margin-right: 15px !important;
    }
  }
  .form {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    padding: 30px 50px 30px 80px;
    margin-top: 23px;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid $gray;
    justify-content: space-evenly;
    align-items: left;
    @media (max-width: 767px) {
      max-width: 720px;
      margin: 5% 0px !important;
      padding: 20px !important;
    }
    .inputs {
      display: flex;
      flex-flow: row nowrap;
      margin-top: 15px;
      margin-bottom: 15px;
      justify-content: space-between;

      @media (max-width: 767px) {
        flex-flow: column nowrap;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .upcomingdates {
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-flow: row nowrap;
    }
  }

  .interval {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    justify-content: space-evenly;

    .radio-input {
      @media (max-width: 767px) {
        flex-basis: 30%;
      }
       @media (max-width: 430px) {
        flex-basis: 40%;
      }
    }
  }

  .contentheader,
  .contentheader2 {
    font-weight: 600;
    margin-bottom: 10px;
    @media (max-width: 767px) {
      margin-left: 0px;
      margin-right: 15px;
      font-size: 20px;
    }

    @media (max-width: 428px) {
      margin-right: 5px;
      font-size: 18px;
    }

    @media (max-width: 380px) {
      font-size: 15px;
    }
  }

  .contentheader {
    @media (max-width: 767px) {
      margin-bottom: 0px;
    }
  }

  input {
    border: solid 1px $fieldborder-gray;
    padding: 3px 5px 3px 5px;
  }

  .field {
    margin-left: 75px;
    margin-right: 75px;
    @media (min-width: 768px) and (max-width: 1023px) {
      margin-left: 10px;
      margin-right: 20px;
    }
    @media (max-width: 767px) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .errorhandler {
    display: inline-flex;
    flex-flow: column nowrap;
    color: $error;
  }

  .radio-input {
    margin: 5px 80px 0px 0px;

    @media (max-width: 767px) {
      margin: 15px 20px 0 0;
    }

    @media (max-width: 380px) {
      margin: 15px 10px 0 0;
    }

    .radio-top-label {
      margin-left: 25px;
      margin-bottom: 15px;
      font-weight: 600;

      @media (max-width: 767px) {
        margin-left: 10px;
      }
    }
  }

  .radio-label img {
    height: 60px;
    width: 100px;
  }

  .frequency {
    display: flex;
    flex-flow: column nowrap;
  }
  .bottom {
    display: block;
    text-align: right;
    margin-top: 15px;
    @media (max-width: 767px) {
      margin-top: 0px;
      text-align: center;
    }
  }
  .upperbottom {
    text-align: right;
    color: $error;
    margin: 15px 0 10px;
    @media (max-width: 767px) {
      text-align: center;
      margin-top: 0px;
    }
  }
  .privacy {
    padding-right: 80px;
    a {
      color: $blue;
      border-color: $blue;
    }

    @media (max-width: 767px) {
      flex-flow: column nowrap;
      padding: 0px;
    }
  }

  .subnote {
    font-size: 14px;
    margin-left: 0px;
    margin-top: 2px;
    text-transform: math-auto;
    font-style: normal;
  }

  .error {
    height: 15px;
    max-width: 312px;
    margin: 0 5px;
    font-size: 13px;
    color: #bc403a;
  }

  .radio-input .radio-checkmark {
    width: 15px;
    margin-top: 15px;
  }

  .repaymentMethod {
    position: relative;
    margin-top: 20px;
  }
  .repaymentMethodSpan {
    width: 350px;
    position: absolute;
  }
  @media (max-width: 1023px) {
    .banking-info {
      flex-flow: column nowrap;
      align-items: center;
    }

    .radio-input {
      margin: 20px 20px 0 0;
    }

    .bottom {
      .inputs {
        flex-flow: column-reverse nowrap;
        align-items: center;

        button {
          margin-bottom: 10px;
        }
      }
    }
  }

  .button-orange {
    @media (min-width: 768px) and (max-width: 1023px) {
      width: 30%;
    }
    @media (max-width: 767px) {
      width: 50%;
      max-width: inherit;
    }
    @media (max-width: 420px) {
      padding: 6px 33px;
    }
    @media (max-width: 380px) {
      padding: 6px 15px;
    }
  }
  .field .input-wrapper input {
    @media (max-width: 767px) {
      width: 100%;
    }
  }
}

.applabel {
  font-weight: 600;
}

.radio-input .radio-top-label {
  font-size: 14px;
}

.date-select {
  align-self: flex-start;
  margin: 10px 0 10px 0;

  @media (max-width: 767px) {
    align-self: auto;
    width: 100%;
  }

  .field {
    min-width: 190px;
    width: 270px;
    margin-left: 0px;
    margin-right: 0px;

    @media (max-width: 767px) {
      width: auto;
      min-width: auto;
    }
  }

  .second {
    margin-left: 20px;
    @media (max-width: 767px) {
      margin-left: 0px;
    }
  }

  .datepicker-icon {
    margin-right: 0px;
    &.short {
      width: 30px;
      min-width: 30px;
      margin-left: 160px;
    }
  }
}

.divider {
  height: 108px;
  width: 0;
  border-left: 1px solid $blue;
  margin: 0px 30px;

  @media (max-width: 767px) {
    display: none;
  }
}

.lastdates {
  @media (max-width: 767px) {
    width: 50%;
  }
}
</style>