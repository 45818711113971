<template>
  <main>
    <PageHeaderApplication>
      Line of Credit Application - Banking
      <template v-slot:subtitle>
        <span>Find out if you qualify in these simple steps.</span>
      </template>
    </PageHeaderApplication>
    <v-form
       :validation-schema="schema"
        keep-values
        @invalid-submit="onIncompleteSection"
        @submit="submitApplication"
        v-slot="{ errors }"
        >
    <section>
      <AppNav :navselected="5" />
      <fieldset class="form">
        <legend class="visually-hidden">Line of Credit Application - BANKING</legend>
        <h3 class="contentheader" tabindex="-1" id="tittlebanking">
          Tell us about your bank & repayment preference.
        </h3>
        <div class="inputs">
          <div>
            <FormField :errormsg="errors['ABAroutingnumber']">
              <template v-slot:label
                >
                <label for="abaroute" class="applabel">ABA Routing Number</label></template
              >
              <v-field
                  v-model="routing"
                  name="ABAroutingnumber"
                  v-slot="{ field }"
                >
              <input
                v-bind="field"
                id="abaroute"
                @keydown="limitNums"
                v-on:keypress="isNumber($event)"
                maxLength="9"
                type="text"
                aria-required="true"
                aria-invalid="true"
                aria-describedby="ABAroutingnumber-errormsg"
              />
              </v-field>
              <template v-slot:error>
                <v-error id="ABAroutingnumber-errormsg" name="ABAroutingnumber" />
              </template>
            </FormField>

            <FormField :errormsg="errors['bankaccountnumber']">
              <template v-slot:label
                >
                <label for="bankaccountnumber" class="applabel">Bank Account Number</label></template
              >
              <v-field
                 v-model="account"
                  name="bankaccountnumber"
                  v-slot="{ field }"
                >
              <input
                v-bind="field"
                id="bankaccountnumber"
                @keydown="limitNums"
                v-on:keypress="isNumber($event)"
                maxLength="15"
                aria-required="true"
                aria-invalid="true"
                aria-describedby="bankaccountnumber-errormsg"
              />
              </v-field>
              <template v-slot:error>
               <v-error id="ABAroutingnumber-errormsg" name="bankaccountnumber" />
              </template>
            </FormField>
          </div>
          <img
            src="../../assets/backgrounds/check_sample.png"
            alt="Complete an Application"
            class="check-sample"
          />
        </div>
        <div class="inputs" role="radiogroup" aria-label="How would you like to repay any cash advances">
          <div class="bankcheck">
            <h4 class="contentheader2">
              How would you like to repay any cash advances?
            </h4>
            <div class="radiolist" >
              <div class="radio-input">
                <div class="radio-wrapper" @click="repaymentMethod = 'A'">
                  <v-field
                      v-model="repaymentMethod"
                      name="repaymentMethod"
                      v-slot="{ field }"
                      value="A"
                      type="radio"
                      >
                  <input
                    v-bind="field"
                    type="radio"
                    value="A"
                    aria-describedby="repaymentMethod-errormsg"
                    tabindex="-1"
                    aria-hidden="true"
                    @keydown.space="radiobuttonselection('A',$event)"
                  />
                  </v-field>
                  <span class="radio-checkmark" aria-label="Electronic Funds Transfer (EFT)"
                   tabindex="0"
                   role="radio"
                   :aria-checked="repaymentMethodEFT"
                   aria-describedby="lblrepaymentMethodEFT"
                   @keydown.space="radiobuttonselection('A',$event)" 
                   />
                  <span class="radio-label">
                      <label id="lblrepaymentMethodEFT" for="A">
                    Electronic Funds Transfer (EFT)
                     </label>
                  </span>
                </div>
              </div>
              <div class="radio-input">
                <div class="radio-wrapper" @click="repaymentMethod = 'K'">
                  <v-field
                      v-model="repaymentMethod"
                      name="repaymentMethod"
                      v-slot="{ field }"
                      value="K"
                      type="radio"
                      >
                  <input
                    v-bind="field"
                    type="radio"
                    value="K"
                   
                    aria-describedby="repaymentMethod-errormsg"
                    tabindex="-1"
                    aria-hidden="true"
                    @keydown.space="radiobuttonselection('K',$event)"
                  />
                  <span   v-bind="field" class="radio-checkmark" aria-label="Paper check" tabindex="0"
                   role="radio"
                   @keydown.space="radiobuttonselection('K',$event)"
                   :aria-checked="repaymentMethodpapercheck"
                   aria-describedby="lblrepaymentMethodpapercheck" />
                  <span class="radio-label"> 
                     <label id="lblrepaymentMethodpapercheck" for="K">Paper check  </label></span>
                    </v-field>
                </div>
              </div>
            </div>
            <div class="error repaymentMethod" style="text-align: left">
              <v-error id="repaymentMethod-errormsg"  name="repaymentMethod" />
            </div>
          </div>
        </div>
      </fieldset>
      <div class="upperbottom" role="alert" v-show="showcommonerrormsg">
        {{ help.commonapperror }}
      </div>
      <div class="bottom">
        <button
          class="button-orange"
          v-show="!submitting"
        >
          SUBMIT APPLICATION
        </button>
        <div role="alert" class="subnote" v-show="submitting">
          <span class="subnoteheader" v-show="submitting"
            >We are processing your application
          </span>
          <span
            >Please do not refresh the page, try to resubmit or page back.<br />
            You will automatically be forwarded to the next step.
          </span>
        </div>
      </div>
      <div role="alert" class="subnote-error" v-show="showservererror">
        <span class="header"
          ><b>We’re sorry,</b> an error occurred and your application was not
          processed. Please tap to submit again.
        </span>
        <span>
          If the issue continues, please contact Customer Service at
          <a :href="'tel:' + companyInfo.customerServicePhone">{{
            companyInfo.customerServicePhone
          }}</a
          >.
        </span>
      </div>
    </section>
    </v-form>
  </main>
</template>

<script>
import companyInfo from "@/config/text/company";
import help from "@/config/text/help";
import AppNav from "@/components/application/AppNav.vue";
import { PageHeaderApplication, FormField } from "@/components";
import { limitNums } from "@/utils/plugins/inputs";
//import { Validator } from "vee-validate";
import * as yup from "yup";
export default {
  name: "AppBanking",
  title: "Line of Credit Application",
  components: {
    PageHeaderApplication,
    FormField,
    AppNav,
  },
  created() {
    if (!this.$store.state.application.payfrequencyComplete) {
      this.$router.replace({ name: "app-payfrequency" });
    }
    if (this.ip == null) {
      this.$store.dispatch("findIPAddress");
    }
    this.$store.state.application.repaymentMethod = "A";
  },
  data() {
    let NumberRegex="^[0-9]*$";
    let repaymentMethodErrormsg = "Please select a payment method";
    let ABAroutingnumberErrormsg = "Please enter your bank’s 9-digit ABA routing number. Only numbers are allowed";
    let bankaccountnumberErrormsg ="Please enter your bank account number";

    const schema = yup.object().shape({
      repaymentMethod: yup.string().required(repaymentMethodErrormsg),

      ABAroutingnumber:yup.string()
      .required(ABAroutingnumberErrormsg)
      .max(9,ABAroutingnumberErrormsg)
      .min(9,ABAroutingnumberErrormsg)
      .matches(NumberRegex,ABAroutingnumberErrormsg),

      bankaccountnumber:yup.string()
      .required(bankaccountnumberErrormsg)
      .max(15,bankaccountnumberErrormsg)
      .matches(NumberRegex,bankaccountnumberErrormsg),

    });

    return {
      schema,
      companyInfo: companyInfo,
      submitting: false,
      help: help,
      showcommonerrormsg: false,
      showservererror: false,
    };
  },
  computed: {
    isFormValid: function () {
      const invalidFields = !!Object.values(this.fields).filter(
        (field) => !field.valid
      ).length;
      const invalidAuxFields = this.repaymentMethod === null;
      return !invalidFields && !invalidAuxFields;
    },
    routing: {
      get() {
        return this.$store.state.application.routing;
      },
      set(value) {
        this.$store.commit("updateRouting", value);
      },
    },
    account: {
      get() {
        return this.$store.state.application.account;
      },
      set(value) {
        this.$store.commit("updateAccount", value);
      },
    },
    repaymentMethod: {
      get() {
        return this.$store.state.application.repaymentMethod;
      },
      set(value) {
        this.$store.commit("updateRepaymentMethod", value);
      },
    },
    repaymentMethodEFT: {
       get() {
       
        if(this.$store.state.application.repaymentMethod == "A")
        {
          return true;
        }
        else
        {
          return false;
        }
      },
    
    },
     repaymentMethodpapercheck: {
       get() {
       
        if(this.$store.state.application.repaymentMethod == "K")
        {
          return true;
        }
        else
        {
          return false;
        }
      },
      
    },
  },
  mounted() {
    this.focusfirstelement();
    document.getElementById("divbanking").setAttribute("aria-current","step");
  },
  methods: {
    focusfirstelement() {
      document.getElementById("tittlebanking").focus();
    },
    radiobuttonselection(val,event) {      
      this.repaymentMethod = val;
      event.preventDefault();
    },    
    async submitApplication() {
      this.showservererror = false;
      await this.$store.dispatch("createToken");
      this.$store.state.application.tokenID = this.$store.state.login.token;
      //this.$validator.validate().then(async (valid) => {
       // if (valid) {
          this.showcommonerrormsg = false;
          this.submitting = true;
          try {
            let result;
            result = await this.$store.dispatch("ProcessApplication");
            if (result != undefined) {
              this.$store.state.application.tokenID = result.tokenToUse;
              this.$store.state.esign.tokenID = result.tokenToUse;
              this.$store.commit("updatecompleteBanking", true);
              if (result && result.appStatus == "A") {
                this.$store.commit("updateCreditLimit", result.approvedAmount);
                this.$store.state.esign.creditLimit = result.approvedAmount;
                this.$store.state.esign.tokenID =
                  this.$store.state.application.tokenID;
                this.$router.push({ name: "app-approved" });
              } else if (result && result.appStatus == "D") {
                this.$router.push({ name: "app-denied" });
              }
            } else {
              this.submitting = false;
              this.showservererror = true;
            }
          } catch  {
            //console.log(error);
            this.submitting = false;
            this.showservererror = true;
          }
      //   } else {
      //     this.showcommonerrormsg = true;
      //   }
      //   this.submitting = false;
      // //});
    },
    onIncompleteSection()
    {
      this.showcommonerrormsg = true;
    },

    isNumber(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    limitNums,
  },
};
// Validator.localize({
//   en: {
//     custom: {
//       ABAroutingnumber: {
//         required:
//           "Please enter your bank’s 9-digit ABA routing number. Only numbers are allowed",
//       },
//       bankaccountnumber: {
//         required: "Please enter your bank account number",
//       },
//       repaymentMethod: {
//         required: "Please select a payment method",
//       },
//     },
//   },
// });
</script>

<style scoped lang="scss">
main {
  width: 100%;

  .field {
    @media (max-width: 767px) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  section {
    display: flex;
    flex-flow: column nowrap;
    max-width: 1023px !important;

    @media (max-width: 767px) {
      margin-left: 15px !important;
      margin-right: 15px !important;
    }
  }
  .form {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    padding: 30px 50px 30px 80px;
    margin-top: 23px;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid $gray;
    justify-content: space-evenly;
    align-items: left;
    min-width: 885px;
    @media (min-width: 768px) and (max-width: 1023px) {
      min-width: 700px;
      padding: 30px 20px;
    }
    @media (max-width: 767px) {
      min-width: 100px;
      max-width: 720px;
      margin: 5% 0px !important;
      padding: 20px !important;
    }
    .inputs {
      display: flex;
      flex-flow: row nowrap;

      @media (max-width: 767px) {
        flex-flow: column nowrap;
      }
    }
  }

  .radiolist {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
  }

  .contentheader,
  .contentheader2 {
    font-weight: 600;
    margin-bottom: 10px;
    @media (max-width: 767px) {
      margin-left: 0px;
      margin-right: 0px;
      font-size: 18px;
    }

    @media (max-width: 428px) {
      margin-right: 5px;
      font-size: 18px;
    }

    @media (max-width: 380px) {
      font-size: 15px;
    }
  }

  .contentheader2 {
    margin-top: 15px;
  }

  .radio-input {
    margin: 20px 120px 0 0;
    @media (max-width: 767px) {
      width: 50%;
    }
  }

  .banking-info {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 40px;
  }

  .check-sample {
    margin: 15px;
    margin-left: 136px;

    @media (min-width: 768px) and (max-width: 1023px) {
      margin-left: 30px;
      max-width: 360px;
    }
    @media (max-width: 767px) {
      margin-left: 0px;
    }
  }

  input {
    border: solid 1px $fieldborder-gray;
    padding: 3px 5px 3px 5px;
  }

  .applabel {
    font-weight: 600;
  }

  .radio-input .radio-checkmark {
    min-width: 15px;
    width: auto;
    font-weight: 600;
    @media (max-width: 767px) {
      margin-top: 3px;
    }
  }

  .radio-label {
    @media (max-width: 380px) {
      font-size: 13px;
    }
  }

  .bottom {
    display: block;
    text-align: right;
    margin-top: 15px;
    @media (min-width: 768px) and (max-width: 1023px) {
      margin-left: 18px;
      margin-right: 18px;
    }
    @media (max-width: 767px) {
      margin-top: 0px;
      text-align: center;
    }
  }
  .upperbottom {
    text-align: right;
    color: $error;
    margin: 15px 0 10px;
    @media (max-width: 767px) {
      text-align: center;
      margin-top: 0px;
    }
  }
  .subnote {
    display: inline-flex;
    flex-flow: column nowrap;
    float: right;
    @media (max-width: 767px) {
      float: none;
    }
  }

  .subnoteheader {
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    margin-bottom: 15px;
  }

  .subnote-error {
    display: inline-flex;
    flex-flow: column nowrap;
    text-align: right;
    float: right;
    margin-top: 15px;
    color: $error;
    @media (max-width: 767px) {
      text-align: center;
    }
    .header {
      margin-bottom: 10px;
    }

    a {
      color: $light-blue;
      border-bottom: 1px solid $light-blue;
    }
  }

  .bankcheck {
    display: flex;
    flex-flow: column nowrap;
  }

  .error {
    height: 15px;
    max-width: 312px;
    margin: 0 5px;
    font-size: 13px;
    color: #bc403a;
  }

  .repaymentMethod {
    position: relative;
    margin-top: 20px;
  }
  .repaymentMethodSpan {
    width: 350px;
    position: absolute;
  }
  @media (max-width: 1023px) {
    .banking-info {
      flex-flow: column nowrap;
      align-items: center;
    }

    .radio-input {
      margin: 20px 20px 0 0;
    }

    .bottom {
      .inputs {
        flex-flow: column-reverse nowrap;
        align-items: center;

        button {
          margin-bottom: 10px;
        }
      }
    }
  }
  .button-orange {
    @media (min-width: 768px) and (max-width: 1023px) {
      width: 40%;
    }
    @media (max-width: 767px) {
      width: 80%;
      max-width: inherit;
      padding: 6px 40px;
    }
    @media (max-width: 420px) {
      padding: 6px 33px;
    }
    @media (max-width: 380px) {
      padding: 6px 15px;
    }
  }
}
</style>