import { createStore as _createStore } from 'vuex';
import VuexPersistence from "vuex-persist";

import application from '@/store/application';
import esign from '@/store/esign';
import iovation from '@/store/iovation';
import documents from '@/store/documents';
import login from '@/store/login';
import account from '@/store/account';
import portal from '@/store/portal';
import contact from "@/store/contact";
import sendemailsms from "@/store/send_email_sms";
import proveapihelper from "@/store/proveapihelper";

//Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage,
  reducer: (state) => ({
    application: state.application,
    esign: state.esign,
    iovation:state.iovation,
    account: state.account,
    login: state.login,
    //contact us
    contact: state.contact,
    sendemailsms : state.sendemailsms,
    proveapihelper : state.proveapihelper    
  })
});

export default _createStore({
  modules: {
    application,
    esign,
    iovation,
    documents,
    login,
    account,
    portal,
    contact,
    sendemailsms,
    proveapihelper
  },
  plugins: [
    vuexLocal.plugin
  ]
});

