import { SoapXmlGenerator } from "./soapXmlGenerator"

const proxyRootUrl = "https://www.xactflex.com"      //replace with DNS name used for proxy
const uwsPath = "api/uws"
const tdcPath = "api/docu"
//const loginPath = "api/login" //has not been integrated

const siteData = {
    userId: 'webuser',
    clientId: '57802000001',
}
/**
 * Primary API call method. Convenience accessors are provided, see below.
 *
 * @param {string} method - The method to use, e.g. GET, POST, PUT, DELETE, PATCH
 * @param {string} path - The API path to call. Will be appended to the base URL. Do not include a preceding slash.
 * @param {string} [data] - Optional body to include in the request. Should be already stringified.
 */
const REQUEST = async (method, path, data) => {


    let response = fetch(`${proxyRootUrl}/${path}`, {     //for now just testing api hitting endpoint, will need to change this later to accept paths
        method: method,
        body: data,
        headers: {
            'Content-Type': 'text/xml; charset=utf-8',
        },

    })
        .then(reply => reply.text())
       // .catch(e => handleErrors(e))
    return response

}

/*const handleErrors = code =>{
    console.log("Error Code " + code)
}*/


/**
 * Convenience accessor for GET requests. See REQUEST() for parameter descriptions.
 */
const GET = (path) => REQUEST('GET', path, null);

/**
 * Convenience accessor for POST requests. See REQUEST() for parameter descriptions.
 */
const POST = (path, data) => REQUEST('POST', path, data);


const Index = {

    parseXml: (xmlString) =>{
        let parser = new DOMParser();
        return parser.parseFromString(xmlString, "text/xml")
    },

    testQAEndpoint: () => GET(uwsPath),
    postLoanApplication: (data) => POST(
        uwsPath,
        SoapXmlGenerator.makeLoanApplicationXmlString(data, siteData),
        new Headers({})
    ),

    postRequestForEsigDocuments: (data) => POST(tdcPath, SoapXmlGenerator.makeEsigDocRequestXmlString(data, siteData), null),

    //inProgress
    postSaveDocumentEsig: (data) => POST(tdcPath,SoapXmlGenerator.makeSaveEsignSignitureXmlString(data, siteData))

}

export default Index