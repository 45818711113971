<template>
  <main>

    <PageHeader>
      Privacy Policy
    </PageHeader>

    <section>
      <div class="policy" v-html="privacyPolicy"/>
    </section>

  </main>
</template>

<script>
import { PageHeader } from '@/components';

export default {
  name: 'PrivacyPolicy',
  title: 'Privacy Policy',
  components: { PageHeader },
  computed: {
    privacyPolicy: {
      get() { return this.$store.state.documents.privacyPolicy }
    }
  },
  async created() {
    if (!this.privacyPolicy) {
      this.$store.dispatch('getPrivacyPolicy');
    }
  },
}
</script>

<style scoped lang="scss">
#content{
    margin: 0 auto !important; 
  }
main {
  width: 100%;
  background: white;
  section {
    margin-top: 20px;
    padding: 0 0 70px 0;
    .policy {
      max-width: 1100px;
      //max-width: 980px; Previous px
      margin: 0 auto;
      padding: 20px;
      @media (max-width: 1023px) {
        margin: 0 10px;
      }
    }

    .caPolicy{
      max-width: 1100px;
      padding: 30px;
      color: #53575A;
      margin: 0 auto;

      @media (max-width: 1023px) {
        margin: 0 10px;
      }
    }
    .subTitle{
      color: #1e4488
    }
  }
}
@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
</style>