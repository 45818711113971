<template>
  <div class="document-modal" v-if="showPopup" 
          aria-modal="true"
          role="alertdialog"
          aria-describedby="doc-title">
    <div class="document-wrapper" >
      <div>
        <div class="row" id="divPrefSaveConfirmation">
              <div  class="popHeader">
                  <div id="doc-title" class="visually-hidden">Confirmation after Email/Phone Number Updated</div>
                  <div  style="text-align:right">
                    <a class="no-border" @click="closeModal()" @keyup.esc="closeModal()" tabindex="0" 
                    @keydown.space.exact="closeModalKeySpace($event);"
                    @keydown.shift.tab="focuscross($event);"  
                    @keydown.tab.exact="focuscross($event);"
                    style="margin-right:20px;" 
                    id="pref-confirm-close"
                    aria-label="Close Modal"
                    >
                      <span style="color:white; padding:8px;">X</span>
                    </a>
                  </div>
                  <div style="margin-top:-20px;">
                      <!-- <i class="fa fa-check-circle-o" aria-hidden="true" style="font-size:60px; color:white;"></i>-->
                      <img src="../../assets/icons/white_recieved_icon@2x.png" style="width:55px;" />
                  </div>
              </div>

            <div class="popBody">
                <div class="" v-if="MessageFor=='EMAIL'">
                        <p class="msgheader"><b>Email Address Updated</b></p>
                        <p class="msgtext">{{preferences.textEmailUpdate}}</p>
                </div>
                <div v-if ="MessageFor=='SMSPHONE'">
                        <p class="msgheader"><b>Phone Number Updated</b></p>
                        <p class="msgtext">{{preferences.textPhoneUpdate}}</p>
                </div>
                <div v-if ="MessageFor=='EMAILPHONE'">
                        <p class="msgheader"><b>Phone Number Updated</b></p>
                        <p class="msgtext">{{preferences.textPhoneUpdateCellBlank}}</p>
                </div>
                <div v-else-if ="MessageFor=='EMAIL&PHONE'">
                        <p class="msgheader"><b>Your Information Is Updated</b></p>
                        <p class="msgtextBothUpdated">{{preferences.textEmailPhoneUpdate}}</p>
                </div>
            </div>
            <br>
          </div>
      </div> 
    </div>
  </div>
</template>

<script>
import preferences from '@/config/text/preferences';

export default {
  name: 'PrefSaveConfirm',
  props: ["showmessageFor","isCellBlank","invokedFrom"],
  data() {
    return {
            preferences: preferences
    }
  },
  computed: {
      MessageFor:{
        get(){
          return this.showmessageFor;
          }
      },
      isCellNoBlank:{
        get(){
          return this.isCellBlank;
        }
      },
    showPopup: {
      get() { 
        //return true;
        return this.$store.state.account.prefShowSavedMessage;
        },
        set(value){this.$store.commit("updatePrefShowSavedMessage", value);}
    }
  },
  
  
  methods: {
    focuscross(event) {
      event.stopPropagation();  
      event.preventDefault();
    },
    setfocustoinvokebtn()
    {
      if (this.invokedFrom=="PREFSAVEBOTTOM"){
        document.getElementById("btnSaveChangesBottom").focus();   
      }
      else if (this.invokedFrom=="PREFSAVETOP"){
        document.getElementById("btnSaveChangesTop").focus();   
      }
    },
    closeModalKeySpace(event) {
      event.preventDefault();
      this.closeModal();
    },
    closeModal() {
      this.showPopup = false;
      //this.setfocustoinvokebtn();
      this.$nextTick(function(){
        document.getElementById("navaccount").focus();
       });
      //this.$router.push({ name: "portal-account" });
    },

    OutDivClick(){
      this.closeModal();
    },
    InDivClick(event){
      event.stopPropagation()
    },
  }
}
</script>

<style scoped lang="scss">

.document-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
  background-color: rgba(0,0,0,.5);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  .document-wrapper {
    min-width: 300px;
    max-width: 405px;
    background-color: $white;
    box-shadow: 0 3px 6px 1px rgba(0, 0, 0, .5);
    border-radius: 10px;

    @media (max-width: 410px) {
        max-width: 90%;
    }

    /*
        .document{
          max-height: inherit;
          //overflow-y: scroll;
        }
    */
    .popHeader{
      background-color:#1e4488; text-align:center; border-top-left-radius:10px; border-top-right-radius:10px; 
      padding-bottom:10px;
      padding-top:20px;
    }

    .popBody{
        padding-left:14%; padding-right:14%; padding-top:10px;
    }
    .msgheader{
        text-align:center; 
        margin-bottom: 4px;
        font-size: 16px;
        color:black;
      }

    .msgtext{
      text-align:center; 
      margin-top:0; 
      font-size:16px;
      }

      .msgtextBothUpdated{
      text-align:center; 
      margin-top:0; 
      font-size:15px;
      }



  }

}
</style>