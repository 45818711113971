<template>
  <div class="document-modal" v-if="showDocumentModal" @click="OutDivClick"
    aria-modal="true"
    role="alertdialog"
    aria-describedby="doctitle"
  >
    <div class="document-wrapper"  @click="InDivClick">
      <div class="title" >
        <span id="doctitle">{{ documentTitle }}</span>
        <a  ref="refLinkClose" id="linkClose" class="no-border" 
        @click="closeModal" tabindex="0" aria-label="Close Modal"
        @keydown.shift.tab="focuspreviouseleinpopupcross($event);"  
        @keyup.esc="closeModal"
        @keydown.space.exact="closeModalKeySpace($event)"
        @keydown.tab.exact="focusnexteleinpopupcross($event);"
        @keypress.enter="closeModal" 
        >
          <i class="fas fa-times"/>
        </a>
      </div>
      <div class="document" >
          <div v-html="documentText"></div>
          <div style="padding:45px;" v-if="showbuttons"> 
              <div class="inputs">
                  <div class="agreementwidth">
                        <button id="disagree"  class="agreementdeselect" @click="agrmentDisagree();"
                        >
                        Disagree
                        </button>
                  </div>
                  <div  class="agreementwidth" @click="agrmentAgree();">
                    <button  id="agree" class="agreementselect" @keydown.tab.exact="focusnexteleinpopup($event);" 
                    >
                    Agree
                    </button>
                  </div>

              </div>
          </div>

      </div>
      <div style="padding-left: 20px; color: #625E82; padding-top: 20px; font-size: 14px; padding-right: 30px;">{{showbuttons==true ? "You must scroll down to review the agreement and select “Agree” or “Disagree” at the bottom.": "You must scroll down to review the document."}}</div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'DocumentModal',
  computed: {
    showDocumentModal: {
      get() { 
        
        return this.$store.state.documents.showDocumentModal },
      set(value) { 
        
        this.$store.commit('updateShowDocumentModal', value) ;
        
       
      }
    },
    documentTitle: {
      get() { return this.$store.state.documents.documentTitle;}
    },
    documentText: {
      get() { return this.$store.state.documents.documentText; }
    },
    currentAgreement: {
      get() { return this.$store.state.esign.agreementdocname },
      set(value) { this.$store.state.esign.agreementdocname = value }
    },
    showbuttons:{
      get() {
        if(this.currentAgreement == 'arbitrationAgreement'
            || this.currentAgreement == 'agrprivacypolicy' 
            || this.currentAgreement == 'agreleconsent' 
            || this.currentAgreement == 'agrcalprivacyact' 
            || this.currentAgreement == 'agrcredit' 
        ) 
            {return true;}
            else{return false;}
    }
    }

  },
  methods: {
    focuspreviouseleinpopupcross(event) {
      if(this.showbuttons){
      document.getElementById("agree").focus();
      event.stopPropagation();
      event.preventDefault();
      }
      else
      {
      event.stopPropagation();  
      event.preventDefault();

      }

    },
    focusnexteleinpopupcross(event) {
      if(this.showbuttons==false){
      event.stopPropagation();
      event.preventDefault();
      } 
    },
    focusnexteleinpopup(event) {
      document.getElementById("linkClose").focus();
      event.stopPropagation();
      event.preventDefault();
    },
    closeModal() {
     this.setfocustoinvokebtn();
     this.currentAgreement = '';
      this.showDocumentModal = false;
    },
    closeModalKeySpace(event) {
      event.preventDefault();
      this.setfocustoinvokebtn();
      this.currentAgreement = '';
      this.showDocumentModal = false;
    },
    OutDivClick(){
      this.closeModal();
    },
    InDivClick(event){
      event.stopPropagation()
    },
    openDocument(doc) {
      this.selectedDocument = doc;
      this.$store.dispatch('getDocument');
    },
    agrmentDisagree(){
       if(this.currentAgreement == 'arbitrationAgreement') 
            {this.$store.state.esign.IsAgreeArbdocterms = false;
            this.$store.state.esign.IsAgreeArbdoctermsDisabled = true;
            }
       else if(this.currentAgreement == 'agrprivacypolicy') 
            {this.$store.state.esign.IsAgreePriPolicydocterms = false;
            this.$store.state.esign.IsAgreePriPolicydoctermsDisabled = true;
            }
       else if(this.currentAgreement == 'agreleconsent') 
            {this.$store.state.esign.IsAgreeEleConsentdocterms = false;
            this.$store.state.esign.IsAgreeEleConsentdoctermsDisabled = true;
            }
       else if(this.currentAgreement == 'agrcalprivacyact') 
            {this.$store.state.esign.IsAgreeCalPrivacyActdocterms = false;
            this.$store.state.esign.IsAgreeCalPrivacyActdoctermsDisabled = true;
            }
       else if(this.currentAgreement == 'agrcredit') 
            {this.$store.state.esign.IsAgreeCreditdocterms = false;
            this.$store.state.esign.IsAgreeCreditdoctermsDisabled = true;
            }
            
     
     this.closeModal();

    },
    agrmentAgree(){

       if(this.currentAgreement == 'arbitrationAgreement') 
            {this.$store.state.esign.IsAgreeArbdocterms = true;
            this.$store.state.esign.IsAgreeArbdoctermsDisabled = false;}
       else if(this.currentAgreement == 'agrprivacypolicy') 
            {this.$store.state.esign.IsAgreePriPolicydocterms = true;
            this.$store.state.esign.IsAgreePriPolicydoctermsDisabled = false;
            }
       else if(this.currentAgreement == 'agreleconsent') 
            {this.$store.state.esign.IsAgreeEleConsentdocterms = true;
            this.$store.state.esign.IsAgreeEleConsentdoctermsDisabled = false;}
       else if(this.currentAgreement == 'agrcalprivacyact') 
            {this.$store.state.esign.IsAgreeCalPrivacyActdocterms = true;
            this.$store.state.esign.IsAgreeEleConsentdoctermsDisabled = false;}
       else if(this.currentAgreement == 'agrcredit') 
            {this.$store.state.esign.IsAgreeCreditdocterms = true;
            this.$store.state.esign.IsAgreeCreditdoctermsDisabled = false;}

        this.closeModal();
    },
    setfocustoinvokebtn()
    {
       if(this.currentAgreement == 'arbitrationAgreement') 
            {
              document.getElementById("btnAgrArbitration").focus();            
            }
      else if(this.currentAgreement == 'agreleconsent') 
            {
              document.getElementById("btnAgrEleConsent").focus();            
            }
       else if(this.currentAgreement == 'agrcredit') 
            {
              document.getElementById("btnAgrCredit").focus();            
            }
       else if(this.currentAgreement == 'privacy') 
            {
              document.getElementById("lnkPrivacyPolicy").focus();            
            }
       else if(this.currentAgreement == 'tc') 
            {
              document.getElementById("lnkTermsCondition").focus();            
            }
            else
            {
                if( this.documentTitle == 'Privacy Policy'){
                    document.getElementById("lnkPrivacyPolicy").focus();    
                }
                if( this.documentTitle == 'Terms and Conditions'){
                    document.getElementById("lnkTermsCondition").focus();    
                }

            }
    }
  }
}
</script>

<style scoped lang="scss">

    .agreementwidth
    {
      width:50%;
      text-align:center;
      padding-bottom: 0px;;
    }
    .agreementselect{
        color: #FFFFFF;
        background: #e75300;
        font-size: 16pt;
        font-weight: 700;
        padding: 10px 50px;
        border: 2px solid #e75300;
        width:200px;
    }

    .agreementdeselect{
        color: #e75300;
        background: #FFFFFF;
        font-size: 16pt;
        font-weight: 700;
        padding: 10px 50px;
        border: 2px solid #e75300;
        width:200px;
    }

.document-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
  background-color: rgba(0,0,0,.5);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  #linkClose:focus{
        outline: 2px solid #1e4388;
        filter: drop-shadow(0px 0px 4px grey);      
  }

  .document-wrapper {
    min-width: 600px;
    max-width: 700px;
    max-height: 82%;
    min-height: 530px;
    border-radius: 10px;
    background-color: $white;
    box-shadow: 0 3px 6px 1px rgba(0, 0, 0, .5);

    .title {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-bottom: 1px solid $black;
      font-size: 24px;
      font-weight: 700;
      color: $black;
    }

    .document {
      max-height: inherit;
      overflow-y: scroll;
      padding: 20px 20px 0px;
      height: 380px;
    }

    .inputs
    {
      display:flex;
    }
  }

  @media (max-width: 1023px) {
    .document-wrapper {
      min-width: 340px;
      max-width: 96%;

      .title {
        font-size: 20px;
      }
    }
  }

    @media (max-width: 720px) {

    .inputs
    {
      flex-flow: column nowrap;
    }

      .agreementwidth
        {
          width:100%;
          text-align:center;
          padding-bottom: 15px;
        }
    }
}
</style>