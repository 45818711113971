<template>
  <nav>
    <div v-bind:class="{ linksHidden: navHidden, links: !navHidden }">
      <router-link
        id="navaccount"
        class="link"
        :class="{
          'active-link':
            page === 'account' ||
            page === 'transactions' ||
            page === 'documents',
        }"
        :to="{ name: page === 'account' ? null : 'portal-account' }"
      >
        <img
          src="../../assets/icons/account.png"
          alt="view account icon"
          v-bind:style="{ display: navHidden ? 'none' : '' }"
          v-if="
            page === 'account' ||
            page === 'transactions' ||
            page === 'documents'
          "
        />
        <img
          alt="view account icon"
          src="../../assets/icons/account_inactive.png"
          v-bind:style="{ display: navHidden ? 'none' : '' }"
          v-else
        />
        <span v-if="!navHidden">Account</span>
      </router-link>
      <router-link
        class="link"
         :class="
              validatestate() == false ? { 'active-link': page === 'draw' } : 'disabledrawforwashinton'
            "
       @click="
        page === 'draw'
        ? null
        : validatestate() == false
        ? $router.push({ name: 'portal-draw' })
        : openwashingtonPopup()

        "
        :to="{
        name:
        page === 'draw'
       ? null
       :validatestate() == false
       ? 'portal-draw'
       : null,
      }"
       
      >
        <img
          alt="make a draw icon"
          src="../../assets/icons/draws.png"
          v-bind:style="{ display: navHidden ? 'none' : '' }"
          v-if="page === 'draw'"
        />
        <img
          alt="make a draw icon"
          src="../../assets/icons/draws_inactive.png"
          v-bind:style="{ display: navHidden ? 'none' : '' }"
          v-else
        />
        <span v-if="!navHidden">Draw</span>
      </router-link>
      <router-link
        class="link"
        :class="{ 'active-link': page === 'payment' }"
        @click="
          page === 'payment'
            ? null
            : openMakePayment() == 1
            ? $router.push({ name: 'portal-payments' })
            : null
        "
        :to="{
          name:
            page === 'payment'
              ? null
              : openMakePayment() == 1
              ? 'portal-payments'
              : null,
        }"
      >
        <img
          src="../../assets/icons/payments.png"
          alt="make a payment icon"
          v-bind:style="{ display: navHidden ? 'none' : '' }"
          v-if="page === 'payment'"
        />
        <img
          src="../../assets/icons/payments_inactive.png"
          alt="make a payment icon"
          v-bind:style="{ display: navHidden ? 'none' : '' }"
          v-else
        />
        <span v-if="!navHidden">Payments</span>
      </router-link>
      <router-link
        class="link"
        :class="{ 'active-link': page === 'preferences' }"
        :to="{ name: page === 'preferences' ? null : 'portal-preferences' }"
      >
        <img
          src="../../assets/icons/preferences.png"
          alt="update preference icon"
          v-bind:style="{ display: navHidden ? 'none' : '' }"
          v-if="page === 'preferences'"
        />
        <img
          src="../../assets/icons/preferences_inactive.png"
          alt="update preference icon"
          v-bind:style="{ display: navHidden ? 'none' : '' }"
          v-else
        />
        <span v-if="!navHidden">Preferences</span>
      </router-link>
    </div>
    <div>
      <span class="arrow-icon-left"
        ><img
          width="15px"
          v-bind:style="{ display: navHidden ? 'none' : '' }"
          v-on:click="setNavHidden"
          src="../../assets/icons/arrow-left.svg"
      /></span>
      <span class="arrow-icon-right"
        ><img
          width="15px"
          v-bind:style="{ display: !navHidden ? 'none' : '' }"
          v-on:click="setNavHidden"
          src="../../assets/icons/arrow-right.svg"
      /></span>
    </div>
    <div v-if="this.showMessage" class="helpGlossary">
      <transition name="fade">
        <div class="message fade z-index-top" v-if="showMessage">
          <div
            class="title"
            role="alertdialog"
            aria-modal="true"
            aria-describedby="modaltitleGlosary"
          >
            <span class="icon">?</span
            ><span id="modaltitleGlosary" class="helpGlossary" tabindex="-1"
              >Glossary of Terms</span
            >
            <div
              @click="toggleMessage"
              @keydown.esc.exact="toggleMessage"
              @keydown.space.exact="toggleMessageKeySpace($event)"
              class="close"
              tabindex="0"
              @keydown.shift.tab="focuscustomereleinpopup($event)"
              id="cross_icon"
              aria-label="Close modal"
            >
              X
            </div>
          </div>
          <div class="innerMessage">
            <span class="header">Account Balance</span>
            <span
              >Your Account Balance includes the accrued fees that have been
              billed to your account and all outstanding principal.
            </span>
            <span class="header">Automatic EFT Payment</span>
            <span
              >We electronically withdraw the minimum payment from your checking
              account on the due date for your billing statement.</span
            >
            <span class="header">Available Credit</span>
            <span
              >Available credit is the amount of money you may draw through your
              line of credit. If your account is past due, you will not be able
              to draw additional funds.</span
            >
            <span class="header">Carried Balance Fee</span>
            <span
              >A fee based on the balance carried into the next billing cycle,
              also called the “Carried Balance.” The Carried Balance is the
              previous statement balance minus any past due Carried Balance
              fees, payments and Other Credits<sup>1</sup> made during the
              billing cycle, plus any Debits during the billing cycle.</span
            >
            <span class="header">Cash Advance Fee</span>
            <span
              >You pay a cash advance fee whenever you make a draw from your
              credit line. The Cash Advance Fee is calculated based on a
              percentage of your requested draw. This fee is deducted from your
              total draw amount, and the remaining amount is the amount you will
              receive in your banking account.</span
            >
            <!--<span class="header">Check Payment</span>
                <span>You can opt to make your payments by paper check if you request that payment method when you're eSigning your credit line documents.</span>-->
            <span class="header">Credit Limit</span>
            <span
              >Your credit limit is the maximum amount of money that can be
              borrowed on your line of credit.</span
            >
            <span class="header">Debit Card Payment</span>
            <span
              >You may use a Debit Card to make one-time payments. Please call
              Customer Support at 1.833.546.2135.</span
            >
            <span class="header">Deposit Amount</span>
            <span
              >When you request a draw on your credit line, the Deposit Amount
              is the money we will transfer into your bank account after we
              deduct the Cash Advance Fee from your total draw.</span
            >
            <span class="header">Draw</span>
            <span
              >A draw is when you take funds – a cash advance – from your credit
              line.</span
            >
            <span class="header">Electronic Funds Transfer</span>
            <span
              >When you select Electronic Funds Transfer as your payment method,
              you are authorizing the lender to automatically deduct line of
              credit payments from your bank account through the Automated
              Clearing House (ACH) Network. You may revoke this authorization at
              any time.
            </span>
            <!--<span class="header">Late Fee</span>
                <span>This fee is charged when a payment is late.</span>
                <span class="header">Next Minimum Due</span>
                <span>Next minimum due is the minimum payment that you owe on your next payment date.</span>-->
            <span class="header">Current Cycle Minimum Payment</span>
            <span
              >The minimum payment you must make by your next payment
              date.</span
            >
            <span class="header">Next Payment Due Date</span>
            <span
              >This is the date your next regular payment will occur. If you are
              paying with Electronic Funds Transfer, these payments will be
              automatically deducted from your account.</span
            >
            <!--<span class="header">NSF Fee</span>
                <span>This fee is charged when there are insufficient funds in the banking account to cover a payment.</span>-->
            <span class="header">One-Time EFT Payment</span>
            <span
              >This is a single Electronic Funds Transfer payment from your bank
              account you can set up individually through the payment portal or
              by phone with Customer Support at
              <a
                id="customer_support"
                @keydown.tab.exact="focuscrosseleinpopup($event)"
                class="no-border no-hover de-border"
                :href="'tel:' + 8335642135"
                >1.833.546.2135</a
              >.</span
            >
          </div>
          <div class="footer">
            <hr />
            <span
              ><i
                ><sup>1</sup>Other Credits (except the amount of any cancelled
                Cash Advance that occurs in the same Billing Cycle in which it
                was requested).</i
              ></span
            >
          </div>
        </div>
      </transition>
    </div>
    <div
      id="divGlossaryTerms"
      class="pop-uplink"
      @click="toggleMessage"
      @keypress.enter="openGlossaryTermsPopup()"
      tabindex="0"
    >
      <span alt="help icon" class="help-icon">
        <span class="icon">?</span>
      </span>
      <div class="helpGlossary1">Glossary of Terms</div>
    </div>
    <div v-if="this.washingtonshowMessage" class="washingtonhelpGlossary">
      <transition name="fade">
        <div class="message fade z-index-top" v-if="washingtonshowMessage">
          <div
            class="title"
            role="alertdialog"
          >
            <span class="icon"><img src="../../assets/icons/draws_inactive.png"></span
            >
            <div
              @click="washintontoggleMessage"
              @keydown.esc.exact="washintontoggleMessage"
              @keydown.space.exact="washingtontoggleMessageKeySpace($event)"
              class="close"
              tabindex="0"
              id="washintoncross_icon"
              aria-label="Close modal"
            >
              X
            </div>
          </div>
          <div class="innerMessage">
            <span class="header">Draw Unavailable</span>
            <span
              >Draws are unavailble in your state.
            </span>
            
            
          </div>
         
        </div>
      </transition>
    </div>
  </nav>
</template>

<script>
export default {
  name: "PortalNav",
  props: ["page"],
  computed: {
    accountId: {
      get() {
        return this.$store.state.account.accountId;
      },
    },
    firstName: {
      get() {
        return this.$store.state.account.firstName;
      },
    },
    delinquentOrchargeoff: {
      get() {
        if (
          this.$store.state.account.delinquent ||
          this.$store.state.account.chargeoff
        )
          return true;
        else return false;
      },
    },
    enableDraw: {
      get() {
        return this.$store.state.account.isEligibleForDraw;
      },
    },
  },
  data() {
    return {
      showMessage: false,
      navHidden: false,
      washingtonshowMessage:false,
    };
  },
  methods: {
    openGlossaryTermsPopup() {
      this.toggleMessage();
      setTimeout(() => {
        document.getElementById("cross_icon").focus();
      }, 100);
    },
    focuscrosseleinpopup(event) {
      document.getElementById("cross_icon").focus();
      event.stopPropagation();
      event.preventDefault();
    },
    focuscustomereleinpopup(event) {
      document.getElementById("customer_support").focus();
      event.stopPropagation();
      event.preventDefault();
    },
    openMakePayment() {
      if (this.$store.state.account.creditLimit > 0) {
        this.$store.commit("updatePaymentAmount", null);
        this.$store.commit("updatePaymentMade", null);
        this.$store.commit("updatePaymentDate", null);
        this.$store.commit("updateSelectedAmount", null);
        this.$store.commit("updateOtherAmount", 0);
        return 1;
      }
      return 0;
    },
    toggleMessageKeySpace(event) {
      event.preventDefault();
      this.toggleMessage();
    },
    toggleMessage() {
      this.showMessage = !this.showMessage;
      if (!this.showMessage) {
        document.getElementById("divGlossaryTerms").focus();
      } else {
        setTimeout(() => {
          document.getElementById("cross_icon").focus();
        }, 100);
      }
    },
    setNavHidden() {
      this.navHidden = !this.navHidden;
    },
    validatestate() {
     if ( this.$store.state.account.state =="WA") {
       return true;
     }
      return false;
    },
   openwashingtonPopup() {
      this.washintontoggleMessage();
      setTimeout(() => {
        document.getElementById("washintoncross_icon").focus();
      }, 100);
    },
    washintontoggleMessage() {
      this.washingtonshowMessage = !this.washingtonshowMessage
      if (!this.washingtonshowMessage) {
        //document.getElementById("divGlossaryTerms").focus();
      } else {
        setTimeout(() => {
          document.getElementById("washintoncross_icon").focus();
        }, 100);
      }
    },
    washingtontoggleMessageKeySpace(event) {
      event.preventDefault();
      this.washintontoggleMessage();
    },
  },
  // mounted() {
  //  this.openwashingtonPopup();
  // },
};
</script>

<style scoped lang="scss">
//--ADA focus
#divGlossaryTerms:focus {
  outline: 1px solid;
}

//--ADA fous

header {
  @media (max-width: 960px) {
    z-index: 1 !important;
  }
}
nav {
  width: 18%;
  margin: 100px 0 0;
  position: relative;
  left: 10px;
  @media (max-width: 960px) {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    left: 0px;
    margin-top: 3px;
    position: relative;
  }

  .top {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 6px;
    margin: 0 30px 10px 10px;
    border-bottom: 1px solid $border;
    font-weight: 700;

    img {
      height: 50px;
    }

    .hello {
      text-transform: uppercase;
      font-size: 16px;
    }

    .account-number {
      font-size: 10px;
      margin-top: 8px;
    }
  }

  a.link:focus {
    outline: 2px solid black;
  }
  .arrow-icon-left {
    color: #ee6323;
    position: absolute;
    top: 130px;
    left: 80px;
    display: none;
  }
  .arrow-icon-right {
    color: #ee6323;
    position: absolute;
    top: 130px;
    left: 40px;
    display: none;
  }
  .link {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 8px;
    cursor: pointer;
    color: $blue;
    font-size: 18px;
    font-weight: bold;
    border-radius: 6px;
    border-bottom: none;
    text-transform: uppercase;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: $light-gray;
    @media (max-width: 960px) {
      flex-flow: column nowrap;
      font-size: 10px;
      width: 100%;
      height: 52px;
      margin-top: 0px;
      margin-bottom: 0px;
      border-radius: 0px;
    }
    img {
      height: 34px;
      margin-right: 3%;
      @media (max-width: 960px) {
        margin-right: 3px;
      }
    }

    &.active-link {
      background: $blue;
      color: $absolute-white;
      box-shadow: none;
    }
  }

  div.pop-uplink {
    display: flex;
    color: $blue;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;

    @media (max-width: 960px) {
      display: block;
      margin-top: 9px;
      margin-left: 2px;
      width: 60px;
      text-align: center;
    }

    @media (max-width: 500px) {
      font-size: 13px;
    }

    .help-icon {
      background: $orange;
      color: $absolute-white;
      border: none;
      border-radius: 50%;
      //padding: 2px 6px;
      padding: 1px 6px;
      margin-left: 4px;
      margin-right: 4px;
      font-size: 14px;
      font-weight: 700;
      @media (max-width: 460px) {
        width: 10px;
      }
    }
  }

  .close {
    cursor: pointer;
  }

  div.helpGlossary {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100% !important;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    overflow: hidden;
    z-index: 10001;

    @media (max-width: 960px) {
      //margin-top: 12px;
      //margin-top: 9px;
      //margin-left: 10px;
      //margin-left: 2px;
      width: 60px;
      text-align: center;
    }

    @media (max-width: 500px) {
      font-size: 13px;
    }

    //12/29
    .helpGlossary1 {
      color: $orange;
      font-size: 14px;
      font-weight: 600;
      margin-left: 5px;
      @media (max-width: 960px) {
        //margin-top: 12px;
        margin-top: 0px;
        //margin-left: 10px;
        margin-left: 2px;
        //width: 60px;
        width: 60px;
        font-size: 13px;
        text-align: center;
      }
    }

    .z-index-top {
      z-index: 5;
    }
    .message {
      position: absolute;
      top: 30px;
      bottom: auto;
      left: auto;
      width: 290px;
      border-radius: 0px;
      background: $absolute-white;
      color: $gray;

      @media (max-width: 767px) {
        top: 120px;
      }

      .innerMessage {
        padding: 4px 10px;
        max-height: 450px;
        overflow-y: scroll;
        text-align: left;

        span {
          display: block;
          font-size: 12px;
          font-weight: 500;
        }

        .header {
          margin-top: 15px;
          font-size: 16px;
          font-weight: bold;
        }
      }

      .footer {
        font-size: 11px;
        font-weight: 500;
        cursor: pointer;
        padding: 8px 4px 8px 4px;
        text-align: left;
      }

      .title {
        background-color: $blue;
        color: $absolute-white;
        font-size: 16px;
        font-weight: 500;
        padding: 8px 4px 8px 4px;
        text-align: left;

        .icon {
          background: $absolute-white;
          color: $orange;
          border: none;
          border-radius: 50%;
          padding: 2px 6px;
          margin-left: 4px;
          margin-right: 4px;
          font-size: 10px;
          font-weight: 700;
        }

        div {
          float: right;
          margin-right: 10px;
        }
      }
    }
  }
  div.washingtonhelpGlossary {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100% !important;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    overflow: hidden;
    z-index: 10001;

    @media (max-width: 960px) {
      //margin-top: 12px;
      //margin-top: 9px;
      //margin-left: 10px;
      //margin-left: 2px;
      width: 60px;
      text-align: center;
    }

    @media (max-width: 500px) {
      font-size: 13px;
    }

    //12/29
    // .helpGlossary1 {
    //   color: $orange;
    //   font-size: 14px;
    //   font-weight: 600;
    //   margin-left: 5px;
    //   @media (max-width: 960px) {
    //     //margin-top: 12px;
    //     margin-top: 0px;
    //     //margin-left: 10px;
    //     margin-left: 2px;
    //     //width: 60px;
    //     width: 60px;
    //     font-size: 13px;
    //     text-align: center;
    //   }
    // }

    .z-index-top {
      z-index: 5;
    }
    .message {
      position: absolute;
      top: 208px;
      bottom: auto;
      left: auto;
      width: 290px;
      border-radius: 0px;
      background: $absolute-white;
      color: $gray;

      @media (max-width: 767px) {
        top: 120px;
      }

      .innerMessage {
        padding: 4px 10px 10px 10px;
        max-height: 450px;
       // overflow-y: scroll;
        text-align: center;

        span {
          display: block;
          font-size: 12px;
          font-weight: 500;
          margin-bottom: 10px;
        }

        .header {
          margin-top: 15px;
          font-size: 16px;
          font-weight: bold;
        }
      }

      // .footer {
      //   font-size: 11px;
      //   font-weight: 500;
      //   cursor: pointer;
      //   padding: 8px 4px 8px 4px;
      //   text-align: left;
      // }

      .title {
        background-color: #990000;
        color: $absolute-white;
        font-size: 16px;
        font-weight: 500;
        padding: 8px 4px 8px 4px;
        text-align: center;

        .icon {
          background: $absolute-white;
          color: $orange;
          border: none;
          border-radius: 50%;
          padding: 2px 6px;
          margin-left: 4px;
          margin-right: 4px;
          font-size: 10px;
          font-weight: 700;
        }

        div {
          float: right;
          margin-right: 10px;
        }
      }
    }
  }

  .links {
    margin-bottom: 10px;
    @media (max-width: 960px) {
      display: flex;
      flex-flow: row nowrap;
      margin-bottom: 1px;
      width: 100%;
    }
  }

  @media (min-width: 961px) and (max-width: 1150px) {
    width: 150px;

    .top {
      margin: 0 10px 10px 10px;

      img {
        height: 30px;
      }

      .hello {
        font-size: 12px;
        margin-left: 6px;
      }

      .account-number {
        margin-left: 6px;
      }
    }

    .link {
      padding: 6px;
      font-size: 12px;

      img {
        height: 30px;
        margin-right: 10px;
      }

      &.active-link {
        font-size: 11px;
      }
    }

    .helpGlossary,
    .helpGlossary1 {
      width: 150px;
    }
  }

  .linksHidden {
    width: 2%;
  }
  @media (min-width: 360px) and (max-width: 960px) {
    nav {
      .link {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
        padding: 8px 10px;
        cursor: pointer;
        color: $blue;
        font-size: 12px;
        font-weight: bold;
        border-radius: 6px;
        text-transform: uppercase;
        margin-top: 5px;
        margin-bottom: 5px;
        background-color: $light-gray;

        img {
          height: 34px;
          margin-right: 20px;
        }

        &.active-link {
          background: $orange;
          color: $absolute-white;
          box-shadow: 4px 6px 12px 0px rgb(0 0 0 / 50%);
        }
      }
    }
  }
  .disabledrawforwashinton {
        color: #FFFFFF !important;
        background-color: $disabled-gray;
        // pointer-events: none;
        // cursor: not-allowed;
      
      }
}
 
</style>