<template>
  <!-- <span
    class="help-icon"
    @click="toggleMessage"> -->
  <!-- <span class="icon">?</span>
 
    <transition name="fade">     
      <span class="message fade" v-if="showMessage"> 
         <div style="text-align:right ;  cursor:pointer; padding-bottom:3px" > <b>X</b> </div>         
       <div> {{ message }}</div>
        </span>
    </transition> -->
  <!-- Tooltip changed to fix overlapping issue-->
  <span class="help-icon">
    <div class="tooltip">
      <label style="display: none"></label>
      <button
        class="icon"
        tabindex="0"
        :aria-labelledby="id"
        name="tooltipbtn"
        @focus="toggleMessage($event.target)"
        @blur="hidetooltip($event.target)"
        @mouseover="toggleMessage($event.target)"
        @mouseleave="hidetooltip($event.target)"
      >
        ?
      </button>
      <div role="tooltip" :id="id" class="message hidetooltip" aria-hidden="true">
        {{ message }}
      </div>
    </div>
  </span>
</template>

<script>
export default {
  name: "HelpIcon",
  props: { message: String, id: String },
  created() {
    window.addEventListener("keydown", (e) => {
      if (e.keyCode == 27) {
        var el = document.activeElement;
        el.parentElement
          .querySelectorAll('[role="tooltip"]')
          .forEach(function (subel) {            
            if (subel.classList.value == "message") {
              subel.classList.add("hidetooltip");
              subel.setAttribute("aria-hidden", "true")
            }
          });
      }
    });
  },
  data() {
    return {
      showMessage: false,
    };
  },
  methods: {
    hidetooltip(e) {
      e.parentElement.querySelectorAll('[role="tooltip"]').forEach(function (subel) {
        if (subel.classList.value == "message") {
          subel.classList.add("hidetooltip");
          subel.setAttribute("aria-hidden", "true")
        }
      });
    },
    toggleMessage(el) {
      var tooltip = el.parentElement.querySelectorAll('[role="tooltip"]')[0];
      tooltip.classList.remove("hidetooltip");
      tooltip.setAttribute("aria-hidden", "false")
    },
  },
};
</script>

<style scoped lang="scss">
.help-icon {
  position: relative;

  .icon {
    position: relative;
    top: -3px;
    left: 4px;
    background: $blue;
    color: $absolute-white;
    border: none;
    border-radius: 50%;
    padding: 2px 5px;
    margin-left: 4px;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
    font-style: normal !important;

    &:hover {
      font-weight: 700;
      background: transparent;
      color: $blue;
      padding: 1px 4px;
      border: 1px solid $blue;
      transition: opacity 1.5s 1.5s ease-in-out;
    }
  }

  .message {
    position: absolute;
    top: auto;
    bottom: auto;
    left: 25px;
    visibility: visible;
    text-transform: none;
    font-weight: 500;
    @media (max-width: 1023px) {
      left: -30px;
    }
    @media (max-width: 768px) {
      left: -250px;
      width: 300px;
      font-size: 14px;
      margin-top: -3px;
    }
    @media (max-width: 590px) {
      left: -325px;
    }
    width: 200px;
    padding: 10px 10px;
    border-radius: 0px;
    background: $absolute-white;
    color: $gray;
    border: 1px solid $light-gray;
    font-size: 10px;
    box-shadow: 0px 5px 5px 0px $light-gray;
    z-index: 4;

    // &:after {
    //   content: "";
    //   position: absolute;
    //   top: 100%;
    //   left: 98px;
    //   border-top: 4px solid $gray;
    //   border-left: 4px solid transparent;
    //   border-right: 4px solid transparent;
    // }
  }
}

.amountwidth .cashfeehelpicon .help-icon span.message,
.amountwidth .depositamthelpicon .help-icon span.message {
  @media (max-width: 1265px) {
    left: -50px;
  }
  @media (max-width: 1105px) {
    left: -100px;
  }
  @media (max-width: 768px) {
    left: -200px;
  }
  @media (max-width: 590px) {
    left: -325px;
  }
  @media (max-width: 429px) {
    left: -272px;
    width: 250px;
  }
}

.amountwidth .cashfeehelpicon .help-icon span.message {
  @media (max-width: 590px) {
    margin-top: -20px;
  }
  @media (max-width: 429px) {
    left: -272px;
    width: 250px;
  }
}

.cashfeehelpicon .help-icon span.message {
  @media (max-width: 429px) {
    left: -272px;
    margin-top: -56px;
    width: 250px;
  }
  @media (max-width: 378px) {
    left: -172px;
  }
}

@media (max-width: 1023px) {
  .cashfeehelpicon .icon,
  .depositamthelpicon .icon {
    position: relative;
    left: 0px;
    top: -2px;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

// .tooltip .message {
//   visibility: hidden;
// }

// .tooltip:hover .message {
//   visibility: visible;
// }

// .icon:focus {
//   visibility: visible;
// }

.hidetooltip {
  visibility: hidden !important;
  position: absolute;
}

.icon:focus {
  font-weight: 700;
  background: transparent;
  color: $blue;
  padding: 1px 4px;
  border: 1px solid $blue;
  filter: none;
  outline: none;
}

//app-address tooltip
.helpiconcust .help-icon .tooltip > .message {
  @media (max-width: 500px) {
    left: -290px;
    top: 22px;
  }
  @media (max-width: 400px) {
    left: -180px;
  }
}

[role="tooltip"] {
  transition: opacity 1.5s 1.5s ease-in-out;
}
</style>