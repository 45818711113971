<template>
  <div class="portal-table cardNew">
    <div class="title">
      <slot></slot>
    </div>
    <div class="dScrollPortTable">
      <table :class="data.length === 0 ? 'hidden' : null">

        <thead>
          <tr class="header">
            <th v-for="(column, index) in columns" :key="index" >
              <span v-if="column === 'trans. id'" class="trans-id" :class="column">
                Trans. ID
              </span>
              <span v-else-if="column === 'billing statements'" :class="column">
                Date
              </span>
               <span v-else-if="column === 'download/view'" style="text-align:center;">
                 {{ column }}
              </span>
              <span v-else :class="column">
                {{ column }}
              </span>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, index) in data" :key="index">
            <td v-for="(column, index) in columns" :key="index" >
              <span v-if="column !== 'download/view'" :class="column">
                <span v-if="column === 'amount'">
                  <span v-if="row[column] > 0"> {{ currencyLong(row[column]) }} </span>
                  <span v-if="row[column] < 0"> ({{ currencyLong((-1) * row[column]) }}) </span>
                </span>
                <span v-else-if="column === 'date' || column === 'billing statements'">
                  {{ date(row[column]) }}
                </span>
                <span v-else-if="column === 'line of credit agreement'" class="loca">
                  {{ row[column] }}
                </span>
                <span v-else>
                  {{ row[column] }}
                </span>
              </span>
                  <span v-else @click="downloadFile(row[column])"  >
                    <!-- <a id="pdf" target="_blank"> -->
                      <span class="download-icon">
                      <i class="fas fa-file-pdf"/> </span><br/>
                     <a :aria-label="'Download/View statement '+stringToDateString((row['date']===undefined?row['billing statements']:row['date']))" @click="downloadFile(row[column])" tabindex="0" style="color:#EE6323; border-bottom: 1px solid #EE6323" >Download/View</a>
                    <!-- </a> -->
              </span>
            </td>
          </tr>
        </tbody>

      </table>
    </div>
  </div>
</template>

<script>
import { currencyLong } from "@/utils/filters/currency";
import { date } from '@/utils/filters/dates';
export default {
  name: 'PortalTable',
  props: ['columns', 'data'],
  data() {
    return {
      date: date,
      currencyLong : currencyLong
    };
  },
  methods: {
    downloadFile(file) {
     // console.log(file);  // TODO: Add in an action in the store to complete this
      let pdfName = file//.innerHTML; 
  
      window.open(pdfName, "_blank");
//  var link = document.getElementById('pdf');
//   link.href = pdfName//pdfName;
//   console.log(link)

    },   
    stringToDateString(datez) {
      try {
      var parts = datez.toString().replaceAll("T00:00:00","").split("-");  
      return parts[1] + "/" + parts[2] + "/" + parts[0];
      } catch (error) {
        return;
      }
      
      
    },
  }
}
</script>

<style scoped lang="scss">

div.wide .portal-table table {
  width:100%;
}

div.portal-details .portal-table table {
  width:100%;
}

div.portal-details .billing {
    width:5%;
}

div.portal-table th.download\/view {
    padding-right:15px;
}

div.portal-details .dScrollPortTable {
  max-height:340px;
  overflow: hidden;
  overflow-y: auto;
  width:100%;
}

.portal-table table.hidden {
  visibility: hidden;
}

.cardNew .table-title {
    @media (max-width: 768px) {
      width: 300px;
      margin-left: auto;
      margin-right: auto;      
    }
  }

  .portal-table td.billing {
    padding-left:5px;
  }
</style>

