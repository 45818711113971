<template>
  <main>
    <section class="form">
      <PortalLoader />
      <div class="row form-layout">
        <div>
          <div class="approvedheader">
            <h2>
              {{ firstName }}, you've been conditionally
              <br class="hidden-xs" />
              approved for a
              <span class="blue">
                {{ currencyShort(creditLimit) }} line of credit.<sup
                  >2</sup
                ></span
              >
            </h2>
          </div>
          <div
            style="
              border-top: 1px solid grey;
              margin-left: 20px;
              margin-right: 20px;
            "
          ></div>
          <div
            style="text-align: center; padding-bottom: 30px; padding-top: 30px"
          >
            <div class="approvedsubheader">
              <h1>
                Welcome to
                <span style="white-space: nowrap"
                  ><span id="xact">Xact</span
                  ><span style="font-weight: 100" id="flex"><i>Flex</i></span
                  ><sup
                    style="font-size: 9px; margin-left: 3px; font-weight: 500"
                    ><span class="trademark">&reg;</span></sup
                  ></span
                >
              </h1>
            </div>
            <br /><br />
            <div class="approvedcontents">
              To finalize your line of credit and request your first cash
              advance, <br class="hidesmall" />just eSign your documents and
              complete a confirmation call.
            </div>
            <div>
              <img
                class="loadergifwidth"
                src="../../assets/icons/loader.gif"
                alt=""
              />
            </div>
            <div class="approvedfooter">
              We’re preparing your line of credit documents.
              <br class="hidelarge" />Thanks for your patience.
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { PortalLoader } from "@/components";
import companyInfo from "@/config/text/company";
import { lvxWidgetID } from "@/config/amounts";
import { currencyShort } from "@/utils/filters/currency";
export default {
  name: "AppApproved",
  title: "eSign",
  components: { PortalLoader },
  async created() {
    /* Removing until API functions
    if (!this.$store.state.account.creditLimit) {
      this.$router.replace({ name: 'app-banking' });
    }
    */
    this.$store.state.esign.reloadpage = true;
    this.$store.state.account.isLoading = true;
    try {
      if (document.head.children["lvxChatScript"] != null) {
        document.head.removeChild(document.head.children["lvxChatInclude"]);
        document.head.removeChild(document.head.children["lvxChatScript"]);
        document.body.removeChild(document.body.children[lvxWidgetID]);
      }
    } catch (ex) {
      // console.log(ex);
    }

    var tokenResult;
    var parameters = this.$route.query;

    this.DisableLogOut();

    if (Object.keys(parameters).length == 0) {
      this.isFromLeadPost = false;
      tokenResult = null;

      if (this.loggedIn) {
        this.$store.state.esign.tokenID = this.$store.state.login.token;
      } else {
        this.$store.state.esign.tokenID = this.$store.state.application.tokenID;
      }


      if ( this.$store.state.esign.tokenID == null) {
        this.$router.push({ name: "login" });
      }


      tokenResult = await this.$store.dispatch("getAccountSummary");

      //console.log(tokenResult);
      if (tokenResult.status) {
        this.$store.state.esign.tokenID = tokenResult.tokenToUse;
        this.$store.state.account.isLoading = false;
        tokenResult = await this.$store.dispatch("getDrawAmount");
        tokenResult = null;
        tokenResult = await this.$store.dispatch("getEsignLoanDocument");
        this.EnableLogOut();

        if (tokenResult.status) {
          this.$router.push({ name: "esign-consent" });
        }
      }
    } else {
      this.isFromLeadPost = true;
      const guidTran = this.$route.query.ID;
      if (guidTran != undefined) {
        tokenResult = await this.$store.dispatch("createToken");

        this.$store.state.esign.tokenID = tokenResult.token.writtenToken;
        tokenResult = null;
        tokenResult = await this.$store.dispatch(
          "getUWSTransactionInformation",
          guidTran
        );

        if (
          tokenResult.tokenToUse != null &&
          tokenResult.transactionRecords.length > 0
        ) {
          this.$store.state.esign.tokenID = tokenResult.tokenToUse;
          tokenResult = null;

          tokenResult = await this.$store.dispatch("getAccountSummary");

          if (tokenResult.status) {
            tokenResult = null;
            tokenResult = await this.$store.dispatch("getEsignLoanDocument");

            this.EnableLogOut();

            if (tokenResult.status) {
              this.$router.push({ name: "esign-consent" });
            }
          }
        } else {
          this.EnableLogOut();
          this.$router.push({
            name: "leadpost-error",
            query: { appid: guidTran },
          });
        }
      } else {
        this.EnableLogOut();
        this.$router.push({
          name: "leadpost-error",
          query: { appid: guidTran },
        });
      }
    }
    this.EnableLogOut();
  },
  computed: {
    firstName: {
      get() {
        return this.$store.state.account.firstName;
      },
    },
    creditLimit: {
      get() {
        return this.$store.state.esign.creditLimit;
      },
    },
    loggedIn: {
      get() {
        return this.$store.state.login.loggedIn;
      },
    },
  },
  data() {
    return {
      company: companyInfo,
      isFromLeadPost: false,
      currencyShort: currencyShort
    };
  },
  methods: {
    DisableLogOut() {
      if (this.loggedIn) {
        this.$store.state.login.disabledLogOut = true;
      }
    },
    EnableLogOut() {
      if (this.loggedIn) {
        this.$store.state.login.disabledLogOut = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
#content {
  margin-bottom: 0px !important;
}

main {
  width: 100%;
  section.form {
    margin-bottom: 0px;
  }

  .form-layout {
    border: 1px solid $gray;
    padding: 10px;
    border-radius: 6px;

    @media (max-width: 767px) {
      padding-left:4px;
      padding-right: 4px;
    }
  }
  .hidelarge {
    display: none;
  }
  .sectionsubtitle {
    font-size: 18px;
  }
  .hidesmall {
    display: inline;
  }
  .form {
    .approvedheader {
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: center;

      h2  {
      @media (max-width: 520px) {
          font-size: 27px;
         }

      }

      sup {
        font-size: 18px;
      }
    }

    .approvedsubheader {
      text-align: center;
      //font-size:30pt;
      //font-weight:bold;
      color: $blue;
    }

    .approvedsubheader > h1 > sup {
      font-size: 18px;
    }

    .approvedcontents {
      text-align: center;
      color: $gray;
      font-size: 22px;
    }
    .approvedfooter {
      text-align: center;
      font-weight: bold;
    }
  }

  .todos {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 50px 140px;

    .todo {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      .desc {
        width: 230px;
        margin-left: 16px;

        .bold {
          font-weight: 700;
        }
      }
    }
  }

  .trademark {
    font-size: 10px;
    // vertical-align: center;
    font-weight: 500;
  }

  .loader {
    display: flex;
    justify-content: center;
    margin: 120px auto 10px;
  }

  .note {
    text-align: center;
    font-size: 16px;
    padding: 30px 0 200px;
  }

  .loadergifwidth {
    width: 15%;
  }

  @media (min-width: 1023px) {
    section.form {
      width: 900px;
      padding: 30px 50px;
    }
  }

  @media (max-width: 1023px) {
    .todos {
      flex-flow: column nowrap;
      margin: 30px 10px 30px;

      .todo {
        margin-bottom: 20px;
      }
    }

    .loader {
      margin: 40px auto 10px;
    }

    .note {
      padding: 30px 0 30px;
    }
  }

  @media (max-width: 720px) {
    .hidesmall {
      display: none;
    }
  }

  @media (max-width: 520px) {
    .loadergifwidth {
      width: 40%;
    }

    .hidelarge {
      display: block;
    }
  }
}

 .hidden-xs {
   @media (max-width: 768px) {
     display: none;
   }
 }
</style>