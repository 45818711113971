<template>
  <div class="page-header-wrapper">
    <div class="page-header">
      <h1 class="text">
        <slot></slot>
      </h1>
      <div class="subtitle">
        <h2>
          <slot name="subtitle"></slot>
        </h2>
      </div>
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageHeaderApplication",
};
</script>

<style scoped lang="scss">
.page-header-wrapper {
  width: 100%;
  color: $absolute-white;
  padding: 10px 0;
  background-image: url("../../assets/backgrounds/xactflex-hero-background@2x.jpg");
  background-repeat: no-repeat;
  background-position: 0 80%;
  background-size: cover;
  background-origin: border-box;

  .page-header {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    max-width: 1324px;
    // margin: 0px auto ;
    margin: auto;

    .text {
      // font-weight: 500;
      // font-size: 25px;
      color: $blue;
    }

    .subtitle > h2 {
      font-size: 16px;
      color: $black;
    }

    .divider {
      width: 300px;
      margin: 20px 0 0 80px;
      border-bottom: 1px solid $absolute-white;
    }
  }

  @media (max-width: 1023px) {
    .page-header {
      margin: 0px 0px 0px 0px;
      .text {
        // margin-left: 20px;
        font-size: 30px;
      }

      .subtitle {
        margin: 10px 0 0 20px;
      }

      .divider {
        margin: 20px 0 0 20px;
      }
    }
  }

  @media (max-width: 385px) {
    .page-header {
      .text {
        margin-left: 0px;
        font-size: 28px;
      }

      .subtitle {
        margin: 5px 0 0 0px;

        h2 {
          font-size: 14px;
        }
      }
    }
  }
}
</style>