<template>
  <div class="form-steps">
    <slot></slot>
    <div class="fill"/>
  </div>
</template>

<script>
export default {
  name: 'FormSteps'
}
</script>

<style scoped lang="scss">
.form-steps {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 1000px;
  margin: 60px auto 0 auto;

  div {
    flex: none;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-family: $font-primary;//$font-secondary;
    color: $purple;
    padding: 0 20px;
    font-size: 16px;

    &:first-child {
      padding-left: 140px;
    }
  }

  .previous {
    font-family: $font-primary;
    color: $gray;
  }

  .next {
    font-family: $font-primary;
    background: $purple;
    color: $absolute-white;
    padding: 6px 20px 5px;
  }

  .checked {
    margin-right: 8px;
    color: $purple;
  }

  .fill {
    flex: 0 1 100%;
    background: $purple;
    height: 30px;
    padding: 0;
  }

  @media (max-width: 1023px) {
    width: 98%;
    margin: 20px auto 0 auto;
    justify-content: center;

    div {
      flex: 1 1 100%;
      padding: 0 10px;
      font-size: 12px;

      &:first-child {
        padding-left: 20px;
      }
    }

    .next {
      padding: 5px 10px 6px;
    }

    .fill {
      display: none;
    }

    .checked {
      margin: 0 8px 2px 0;
    }
  }
}
</style>