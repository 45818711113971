<template>
  <div class="maincontent">
    <div class="login-box">
      <ContentSectionTitle> Check Your Inbox </ContentSectionTitle>
      <!--  <div class="level1section">
        <span class="label">Email</span>
        <span class="emailcontent">{{ email }}</span>
      </div> -->
      <div role="alert">
        <p class="content">
          {{ ContentText.forgotPasswordSuccessMsgLine1 }}
        </p>
        <br />
        <p class="content">
          {{ ContentText.forgotPasswordSuccessMsgLine2 }}
        </p>
      </div>
      <br />
      <div class="bottom">
        <router-link
          class="button button-primary nofill"
          :to="{ name: 'home' }"
        >
          Back to home
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { ContentSectionTitle } from "@/components";
import ContentText from "@/config/text/login";

export default {
  name: "ForgotSuccessMessage",
  title: "Forgot Password",
  components: { ContentSectionTitle },
  data() {
    return {
      ContentText: ContentText,
    };
  },
  props: {
    email: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
#content {
  @media (max-width: 922px) {
    margin: 100px 0 0 0;
  }
}
.maincontent::before {
  background-image: url("../../assets/backgrounds/xactflex-hero-background@2x.jpg");
}
.login-box {
  align-items: normal;
}
.level1section {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}
.label {
  padding: 10px 10px 10px 0px;
  font-size: 18px;
  opacity: 0.7;
  color: $gray;
  font-weight: 700;
}
.emailcontent {
  padding: 10px;
  width: 100%;
  background-color: #fff;
  border: 1px solid whitesmoke;
  font-size: 18px;
  color: $gray;
  opacity: 0.7;
  font-weight: 700;
}

.content {
  margin-top: 2px;
  margin-left: 3px;
  font-weight: 700;
  color: $gray;
  font-size: 15px;
  line-height: 1.8;
  padding-right: 10px;
  opacity: 0.7;
}
.bottom {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  font-size: 18px;

  @media (max-width: 767px) {
    justify-content: center;
  }
}
main {
  .section-title {
    align-self: flex-start;
    margin-left: 0px !important;
    margin-bottom: 15px !important;
    font-weight: 700;

    @media (max-width: 992px) {
      padding: 0px !important;
    }
  }
}
</style>