import companyInfo from '../company';
import { maximumCreditAmount } from "@/config/amounts";
import { currencyShort } from "@/utils/filters/currency";

export default [
  {
    title: `Availability and Restrictions`,
    value: `You must be at least 18 years old to apply. Uetsa Tsakits Inc. d/b/a XactFlex ("Tribe") is a lender. There ` +
      `is no cost to use this website. Not all applicants will be eligible for a ${ currencyShort(maximumCreditAmount) } line of credit or any line ` +
      `of credit at all. Qualifying for final approval of a line of credit depends on various factors, including ` +
      `income and the state in which you reside. XactFlex does not extend lines of credit to Active Duty Military, ` +
      `their spouses or their dependents. Your supplied information may be verified with any number of independent ` +
      `verification and/or credit reporting companies. The Tribe has elected not to conduct business in the ` +
      `following states: Arkansas, California, Connecticut, Georgia, Massachusetts, Minnesota, New York, ` +
      `North Dakota, Pennsylvania, Vermont, Virginia, Washington, or West Virginia. The availability of lines of ` +
      `credit in any state is subject to change at any time and at the sole discretion of the Tribe.`
  },
  {
    title: `Important Information about Your Lender`,
    value: `We encourage responsible borrowing. The use of a line of credit should be done with full consideration ` +
      `of the costs involved. Review your line of credit documents carefully before you agree to the terms. This ` +
      `site is owned and operated by the Mandan, Hidatsa, and Arikara Nation — the Three Affiliated Tribes of the ` +
      `Fort Berthold Reservation. Our management team has more than 20 years of experience in online lending and we ` +
      `are members of the Online Lenders Alliance (OLA), which supports the review and establishment of best ` +
      `practice standards in this industry. We can be reached by email at ` +
      `<a class="no-border" href="mailto:${ companyInfo.customerServiceEmail }">${ companyInfo.customerServiceEmail }</a> ` +
      `or you can call Customer Service at ` +
       `<a class="no-border" href="tel:${ companyInfo.contactPhone }">${ companyInfo.contactPhone }.</a>
       \n\nOLA’s consumer hotline: 866-299-7585.`
  },
  {
    title: `About XactFlex’s Lines of Credit`,
    value: `Customers may significantly reduce the finance charges they pay by paying off their draws early. There ` +
      `are no penalties for early payoff. Our Line of Credit product is not designed as a solution to long-term ` +
      `financial problems and should not be used in that way. Customers with credit difficulties should consider ` +
      `seeking credit counseling or meeting with a nonprofit counseling service in their community.`
  }
]