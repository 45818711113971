export default `
    <div class="container">      
    <div id="ArbitrationAgreement" data-docsection="Arbitration Agreement">
            <p align="center">
                <font face="Arial" align="center" size="3">
                    <b>Arbitration Agreement</b><br>
                </font>
            </p>
            <p align="justify" style="margin-top: 0pt; margin-bottom: 0pt;">
                <font face="Arial" align="left" size="2">
                    <p style="margin-top: 0pt; margin-bottom: 0pt;" align="justify"><b>1. Definition of Claim.</b> Claim means any claim,
                        dispute, or controversy arising from or relating to this Agreement, this Transaction, any other
                        agreement or transaction that you and we have ever entered into or completed, or any other
                        conduct or dealing between you and us. A court or arbitrator interpreting the scope of this
                        Arbitration Agreement should broadly construe the meaning of Claim so as to give effect to your
                        and our intention to arbitrate any and all claims, disputes, or controversies that may arise
                        between you and us. Consistent with this broad construction, Claim includes (but is not limited
                        to) each of the claims, disputes, or controversies listed below.</p>
                    <ul type="disc" style="margin-top: 0pt; margin-bottom: 0pt;" align="justify">
                        <li>A Claim includes any dispute or controversy regarding the scope, validity, or
                            enforceability of this Arbitration Agreement. For example, a Claim includes any assertion by you or us that this Arbitration Agreement is unenforceable because applicable usury, lending, or consumer protection laws render the underlying Transaction void or unenforceable. A Claim also includes any assertion by you or us that this Arbitration Agreement is unenforceable because it lacks fairness or mutuality of obligations, conflicts
                            with bankruptcy or other federal laws, improperly limits your or our remedies for the
                            other's violation of laws, or unduly restricts your or our access to the court system.
                            Finally, a Claim includes any assertion by you or us that this Arbitration Agreement is
                            unenforceable because you or we did not receive notice of or understand its provisions, you
                            or we need to discover the filing fees or administrative costs associated with commencing an
                            arbitration proceeding, or you or we believe the arbitration firm or the arbitrator will be
                            unfair or biased.</li>
                        <li>A Claim includes any claim that you assert against a person or entity related to us
                            - including our parent company, servicing company, affiliated companies, directors, officers, employees,
                            agents, and representatives - and any claim that we assert against a person or entity
                            related to you. For the purpose of this Arbitration Agreement, references to we, our, and us
                            and references to you and your include such related persons or entities. You and we agree
                            that these related persons and entities may elect to arbitrate any Claim asserted against
                            them even though they have not signed this Arbitration Agreement.</li>
                        <li>A Claim includes any statutory, tort, contractual, or equitable (i.e., non-monetary)
                            claim. For example, a Claim includes any claim arising under the following: a federal or
                            state statute, act, or legislative enactment; a federal or state administrative regulation
                            or rule; common law (i.e., non-statutory law based on court cases); a local ordinance or
                            zoning code; this Agreement or another contract; a judicial or regulatory decree, order, or
                            consent agreement; or any other type of law.</li>
                        <li>Claim includes (but is not limited to) any claim based on your or our conduct before
                            you and we consummated this Transaction. For example, a Claim includes any dispute or
                            controversy regarding our advertising, application processing, or underwriting practices,
                            our communication of credit decisions, or our provision of cost-of-credit or other consumer
                            protection disclosures.</li>
                        <li>A Claim includes any request for monetary damages or equitable remedies, whether
                            such request is asserted as a claim, counterclaim, or cross-claim.</li>
                    </ul>
                </font>
                <font face="Arial" align="left" size="2">
                    <p align="justify"><b>2. Mandatory Arbitration Upon Election. </b> Subject
                        to your right to reject arbitration (explained on page 1 of this Agreement) and subject to the
                        small claims court exception (explained below), you and we agree to arbitrate any Claim if the
                        person or entity against whom a Claim is asserted elects to arbitrate the Claim.
                        <b>Consequently, if the person or entity against whom a Claim is asserted elects to arbitrate
                            the Claim, then neither you nor we may file or maintain a lawsuit in any court except a
                            small claims court and neither you nor we may join or participate in a class action, act as
                            a class representative or a private attorney general or consolidate a Claim with the claims
                            of others.</b> A person or entity against whom a Claim is asserted may elect to arbitrate
                        the Claim by providing oral or written notice to the person asserting the Claim (i.e., the
                        claimant). Such notice need not follow any particular format but must reasonably inform the
                        claimant that arbitration has been elected. For example, if you or we file a lawsuit against the
                        other, then the other provides sufficient notice if the other orally informs the claimant that
                        the other elects to arbitrate the Claim or if the other files a pleading (i.e., a document filed
                        in court) requesting the court to stay (i.e., freeze) the court case and refer the Claim to
                        arbitration.</p>
                </font>
                <font face="Arial" align="left" size="2">
                    <p align="justify"><b>3. Small Claims Court Exception. </b> You and we may
                        ask a small claims court to decide a Claim so long as no party to the small claims court lawsuit
                        seeks to certify a class, consolidate the claims of multiple persons, or recover damages beyond
                        the jurisdiction of the small claims court. If you file a small claims court lawsuit against us,
                        then we lose the right to elect arbitration of your Claim (but not of other persons' Claims). In
                        contrast, if we file a small claims court lawsuit against you, then you retain the right to
                        elect arbitration of our Claim.</p>
                </font>
                <font face="Arial" align="left" size="2">
                    <p align="justify"><b>4. Arbitration Firm. </b> The American Arbitration
                        Association ("AAA") (1-800-778-7879, www.adr.org) will administer the arbitration of Claims. The
                        AAA will normally apply its Consumer Arbitration Rules then in effect to a Claim but may apply
                        other types of procedural rules - such as the AAA's Commercial Arbitration Rules then in effect
                        - if a party to the arbitration proceeding demonstrates that the application of such other
                        procedural rules is appropriate. No matter what the arbitration firm's procedural rules provide,
                        you and we agree that the arbitrator must issue a written decision and may award any type of
                        remedy - including punitive damages and equitable relief - that a court or jury could award if
                        the Claim were litigated. <b> You and we also agree that an arbitration firm may not arbitrate a
                            Claim as a class action or otherwise consolidate the Claims of multiple persons.</b> You may
                        request a copy of the AAA's Consumer Arbitration Rules and other procedural rules at the
                        toll-free phone number or URL (universal resource locator) identified above. If you object to
                        the AAA as the arbitration firm or if the AAA is unavailable, then the parties may agree to select a local arbitrator who is
                        a retired judge or a registered arbitrator in good standing with an arbitration firm, provided
                        that such local arbitrator must enforce all the terms of this arbitration agreement, including
                        the class-action waiver. <b>The parties may not select a local arbitrator who refuses to enforce
                            this arbitration agreement, including the class action waiver, because you and we waive any
                            right to arbitrate a Claim on a class-action, representative-action, or consolidated
                            basis.</b>When attempting to contact AAA or another arbitration firm, please recognize that
                        phone numbers and URLs change frequently; you may need to update the contact information
                        provided above with your own research.</p>
                </font>
            </p>
            <p>
                <font face="Arial" align="left" size="2">
                    <p align="justify"><b>5. Payment of Arbitration Fees; Selection of Forum
                        </b> If you file a Claim with the AAA or another arbitration firm, the firm will usually ask you
                        to pay a filing fee and may also ask you to pay in advance for some of the expenses the firm
                        will incur when administering the arbitration proceeding. Upon your written request, we will pay
                        to the arbitration firm any fees or advance administrative expenses that the arbitration firm
                        requires you to pay as a condition to your filing a Claim with the firm. Additionally, we will
                        pay any fees or expenses the arbitration firm charges for administering the arbitration
                        proceeding, any fees or expenses the individual arbitrator or arbitrators charge for attending
                        the arbitration hearing, and any fees a court charges you to file a lawsuit appealing the
                        arbitration decision. We will pay these fees and expenses whether or not you prevail in the
                        arbitration proceeding. Finally, we agree to hold the arbitration proceedings in the county of
                        your residence or in any different location in the United States of your choice.</p>
                </font>
                <font face="Arial" align="left" size="2">
                    <p align="justify"><b>6. Governing Law. </b> You and we acknowledge that this
                        Transaction involves interstate commerce. Accordingly, you and we agree that both the procedural
                        and the substantive provisions of the Federal Arbitration Act, 9 USC §§ 1-16, govern the
                        enforcement, interpretation, and performance of this Arbitration Agreement. Any court with
                        jurisdiction may enforce this Arbitration Agreement. Additionally, any court with jurisdiction
                        may enforce an arbitration decision rendered under this Arbitration Agreement if that
                        arbitration decision has been properly registered as a judgment.</p>
                </font>
                <font face="Arial" align="left" size="2">
                    <p align="justify"><b>7. Survival; Binding Effect; Severability.</b> You
                        and we retain the right to invoke this Arbitration Agreement and to compel the arbitration of
                        Claims even after your and our respective obligations under this Agreement have been completed,
                        defaulted, rescinded, or discharged in bankruptcy. This Arbitration Agreement binds the heirs,
                        successors, and assigns - including any bankruptcy trustee - of both you and us. Finally, if a
                        court or arbitrator determines that any part of this Arbitration Agreement is unenforceable,
                        then you and we agree that the court or arbitrator must fully enforce the remaining provisions
                        that have not been invalidated.</p>
                </font>
            </p>
            <p align="center">
                <font face="Arial" size="3">
                    <b>Notice-of-Grievance Agreement</b><br></font>
            </p>
            <p align="justify">
                If the person or entity against whom a Claim is asserted declines to
                arbitrate the Claim or if a court or arbitrator determines that the above Arbitration Agreement is
                unenforceable, then you and we agree that neither you nor we may commence, join, or be joined to any
                judicial action (as either an individual litigant or the member of a class) that arises from or relates
                to a Claim until the claimant has provided the other party written notice of the asserted Claim and
                afforded the other party a reasonable period after the giving of the written notice to take corrective
                action. If applicable law provides a time period which must elapse before certain action can be taken,
                then that time period will be deemed reasonable for the purpose of the preceding sentence.
                <br>
                <br>
                By signing below, you and we agree to the Arbitration Agreement and the
                Notice-of-Grievance Agreement, each of which is set forth above.
                <br>
            </p>
            <table border="0" cellpadding="0" cellspacing="0" width="75%" align="right">
            <tbody>
               
                    
                       <tr><td align="right">
                        CONSUMER: {CUSTOMER_NAME}</td></tr>
                        <br><tr>
                         <td align="right">
                        Signature: /s/<u> {103442_ESIGNATURE_1}    </u>
                       </td> </tr> <br><tr><td align="right">
                        Date: <u> {103442_ESIGNATUREDATE_1}    </u></td>
                        </tr>
                    </td>
              
            </tbody>
        </table>
            <br/>
		</div>         
    </div>
`