export const pagesWithSameDay = [
  'home',
  'how-it-works',
  'faq',
  'esign-consent',
  'portal-account'
];

export const pagesWith3k = [
  'home',
  'how-it-works'
];