export default `

    <div class="container">
      
        <p align="center" class="aboutTitle">
            <b>Terms and Conditions</b>
        </p>
        <br>
        Last updated: January 24, 2024
        
        <br><br>
<p>
Thank you for visiting XactFlex’s website XactFlex at www.xactflex.com/ (the “Web Site”). XactFlex provides you with access to this Web Site subject to the terms and conditions contained in these Terms and Conditions of Use. These Terms and Conditions of Use were last updated in January 2024. The use of this Web Site constitutes an agreement to these Terms and Conditions of Use, which together with XactFlex’s Privacy Policy govern XactFlex’s relationship with you relating to this Web Site. If you do not agree to comply with and be bound to these Terms and Conditions of Use, please exit this site immediately. 
</p>
<b>Copyrights</b>
<p>
This Web Site contains material that is either owned by or licensed to Capital Community Bank (“CCBank”) or Axcess Financial Services, Inc. (“Axcess”) (collectively, the “Parties”). The service marks, trademarks, or copyrights of CCBank, and Axcess or its subsidiaries or affiliates include, but are not limited to Axcess, Axcess Financial, Check ‘n Go, and Allied Cash Advance. These and other copyrighted or trademarked materials are protected by applicable federal copyright laws or international treaties. By downloading any trademarked or copyrighted material, you agree that you do not obtain any ownership rights in such material, including the exclusive rights granted by U.S. Copyright law.
</p>
<b>Use of Site</b>
<p>
The Parties expressly authorize the personal use of this Web Site, which includes viewing its content and making a reasonable amount of copies for the sole purpose of retaining documents for your personal files. The distribution, transmission, display, or duplication of materials contained in this Web Site is prohibited without the prior written consent of the Parties.
</p>
<b>Disclaimer of Warranties</b>
<p>
This Web Site and its content are provided for your personal use only on an “as is” basis. The Parties, or any of its subsidiaries or affiliates, does not warrant the accuracy, completeness, timeliness, non-infringement, title, merchant-ability, or fitness for a particular purpose of the content of the Web Site itself, and the Parties hereby disclaim any such express or implied warranties to the extent permissible by applicable law. Further, the Parties do not warrant that the content of this Web Site is free from any viruses, worms, or other code that may result in harmful properties notwithstanding Parties efforts to implement reasonable safeguards to protect against these harmful properties. The Parties may amend or modify the content of this Web Site in any way without notice.
</p>
<b>Third Party Hyperlinks</b>
<p>
This Web Site may contain hyperlinks to other Web Sites that are not owned, operated, or maintained by XactFlex. These Terms and Conditions of Use apply only to this Web Site and not to the third party sites. The hyperlinks to the third party sites are provided for your reference and convenience only and do not imply any review or endorsement of any material contained on third party sites or any associations or affiliations with those represented in third party sites or its owners or operators. XactFlex is not responsible for the content contained in third party sites, which may contain information that is inaccurate, incomplete, or outdated. By viewing, using, or relying on information contained in third party sites, you do so solely at your own risk.
</p>
<b>Indemnification</b>
<p>
In the event that any legal action is taken resulting from your use of this Web Site or any unsolicited information provided by you, you agree to defend, indemnify, hold harmless, and pay any reasonable legal and accounting fees without limitation incurred by XactFlex, its affiliates, its and their directors, officers, employees, agents, investors, or licensers. XactFlex shall have the right, at its option and expense, to participate in the defense and /or settlement of any claim or action, or to assume the exclusive defense and control of any matter otherwise subject to indemnification by you without relieving your indemnification obligations. In no event shall you settle any suit or claim imposing any liability or other obligations on the Parties without its prior written consent.
</p>
<b>Choice of Law</b>
<br><br>
These Terms and Conditions of Use shall be construed in accordance with the laws of the State of Utah.
<br><br>
<b>Minors</b>
<br><br>
This Web Site is not directed at children under the age of thirteen years old. XactFlex does not knowingly collect personal information from children under thirteen years old from this Web Site.
<br><br>
<b>Web Site Security</b>
<p>
You are prohibited from violating or attempting to violate the security of this Web Site, including, but not limited to the following activities: (1) accessing data not intended for you or obtaining personal information about any XactFlex Web Site user or customer; (2) attempting to probe, test, or scan the vulnerability of a system or network or to breach security or authentication measures without proper authorization; (3) attempting to interfere with service to any user, host or network, or otherwise disrupt XactFlex business, including, without limitation, by submitting a virus to the Web Site, overloading, "flooding," "spamming," "mailbombing" or "crashing;", or sending unsolicited email, including promotions and/or advertising of products or services.
</p>
<b>Mobile Terms and Conditions</b>
<br><br>
Effective Date: January 24, 2024
<br><br>
<b>Transactional Texts</b>
<p>
The XactFlex application provides an opportunity to opt-in to Application Updates via text message. You can also receive Application Updates from XactFlex through your mobile device by texting JOIN to 513-992-6667. By doing so, you will receive text messages regarding your application through mobile text alerts using an automatic telephone dialing system. Message and data rates may apply. The number of messages you receive will vary based on account activity. Consent to receive Application Updates via text message is not required as a condition of purchasing any goods or services. By signing up, you are confirming you are over the age of 18. 
</p>
<b>STOP Information</b>
<br><br>
Text STOP to 513-992-6667 to stop receiving Application Updates from XactFlex (you will receive a confirmation text).
<br><br>
<b>HELP Information</b>
<br><br>
For additional information, text HELP to 513-992-6667 or call 833-546-2135.
<br><br>
<b>Supported Carriers</b>
<br>
<p>
Supported carriers are: <br><br>
AT&T, Sprint, T-Mobile®, Cricket, MetroPCS, U.S. Cellular, ACS Wireless, Appalachian Wireless, Bluegrass Cellular, Carolina West Wireless, Cellcom, C-Spire Wireless (formerly Cellsouth), Cellular One of East Central Illinois, Cincinnati Bell Wireless, Cross (dba Sprocket), Duet IP, Element Mobile, EpicTouch, GCI Communications, Golden State, Hawkeye (Chat Mobility), Hawkeye (NW Missouri Cellular), Illinois Valley Cellular, Immix (Keystone Wireless / PC Management), Inland Cellular, iWireless, Mobi PCS (Coral Wireless LLC), Mosaic, MTPCS / Cellular One (Cellone Nation), Nex-Tech Wireless, nTelos, Panhandle Telecommunications, Peoples Wireless, Pioneer, Plateau, Revol Wireless, Rina - Custer, Rina - All West, Rina - Cambridge Telecom Coop, Rina - Eagle Valley Comm, Rina - Farmers Mutual Telephone Co, Rina - Nucla Nutria Telephone Co, Rina - Silver Star, Rina - South Central Comm, Rina - Syringa, Rina - UBET, Rina - Manti, South Canaan / CellularOne of NEPA, Thumb Cellular, Union Wireless, United, Viaero Wireless, West Central Wireless, Leaco, Nemont/Sagebrush. Carriers are not liable for delayed or undelivered messages.
</p>

<b>Privacy Policy</b>
<p>
Your mobile telephone number, opt-in data and SMS messaging will not be shared with any unaffiliated third parties or otherwise sold, transferred, disclosed, or disseminated to any third party. We take your privacy seriously. Please review our privacy policy.
</p>

<b>Authorization for the Social Security Administration to Disclose Your Social Security</b>

<p>
I authorize the Social Security Administration (SSA) to verify my Social Security number (SSN) to match my name, SSN, and date of birth that I submitted for this application/transaction with information in SSA records and to disclose to:  XactFlex (Capital Community Bank) a match or no-match response concerning the results of the SSN verification for the following purpose of the other purpose.
</p>

<b>Contact Us</b>
<br><br>
833-546-2135
<br><br>
P.O. Box 965391
<br><br>
Marietta, GA 30066
<br><br>
XactFlex Service Hours:
<br><br>
All times Eastern Time (ET)
<br><br>
Monday–Thursday: 8 a.m.–9 p.m.
<br><br>
Friday 8 a.m. – 7 p.m.
<br><br>
Saturday 9 a.m. – 3 p.m
<br><br>

            
    </div>
`